import actionType from './actionType';

const initialState = {
  attributesList: {},
  attributesCount: 0,
  selectAll: false,
  isAttributeAddedOrModified: false,
  isAttributeAdded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_ATTRIBUTES_LIST:
      return {
        ...state,
        attributesList: action.attributes.attributesList,
        attributesCount: action.attributes.count,
        selectAll: false,
      };
    case actionType.TOGGLE_ALL_ATTRIBUTE_CHECKBOXES: {
      const attributesList = state.attributesList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAll;
        return obj;
      });
      return {
        ...state,
        selectAll: !state.selectAll,
        attributesList,
      };
    }
    case actionType.TOGGLE_ATTRIBUTE_CHECKBOX: {
      const attributesList = state.attributesList.map((item) => {
        const obj = item;
        if (obj.id === action.attribute.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAll = !(attributesList.filter(item => item.isChecked === false).length > 0);
      return {
        ...state,
        selectAll,
        attributesList,
      };
    }
    case actionType.CHANGE_ADDED_ATTRIBUTE_STATUS:
      return {
        ...state,
        isAttributeAdded: action.data,
      };
    case actionType.ADDED_ATTRIBUTE_STATUS:
      return {
        ...state,
        isAttributeAddedOrModified: action.data,
      };
    case actionType.NEW_ADDED_ATTRIBUTE_STATUS:
      return {
        ...state,
        isAttributeAdded: action.data,
      };

    default:
      return state;
  }
};
