import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  validateProjectLifecycleStageName,
  insertProjectLifeCycleStage,
  updateProjectLifeCycleStage,
} from '../actions/general';
import { getProjectLifeCycleSelector } from '../selectors/general';
import { SpinnerButton } from '../../../../ui-components/Loader';

const mapStateToProps = state => ({
  projectLifeCycleStage: getProjectLifeCycleSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});
const mapDispatchToProps = {
  validateProjectLifecycleStageName,
  insertProjectLifeCycleStage,
  updateProjectLifeCycleStage,
};

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}
function isValidateProjectLifecycleStageName(value, actions) {
  const errorMessage = 'A lifecycle stage with the same name already exists';
  const emptyStringErrorMessage = 'Please enter a valid name for lifecycle stage.';
  return new Promise((resolve) => {
    const title = value.title.trim().toLowerCase().replace(/  +/g, ' ');

    if (title && title !== '' && title !== value.initialTitle.toLowerCase()) {
      validateProjectLifecycleStageName({
        projectLifeCycleStage: title,
        questionModelId: value.questionModelId,
      }).then((val) => {
        if (val) {
          setError(actions, 'title', errorMessage);
          resolve(true);
        }
        resolve(false);
      });
    } else if (title === '') {
      setError(actions, 'title', emptyStringErrorMessage);
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

const BasicForm = ({
  values, errors, touched, isSubmitting,
}) => (
    <Form>
      <div className="r-s-body mt-3">
        <div className="r-s-body mt-3">
          <div className="form-group">
            <div className="a-form-label">lifecycle stage</div>
            <Field
              name="title"
              autoFocus={true}
              autoComplete="off"
              className={errors.title && touched.title ? 'error a-text-input' : 'a-text-input'}
            />
            <div className="error">
              {' '}
              <ErrorMessage name="title" />
            </div>
          </div>
        </div>
      </div>
      <div className="r-s-actions mt-4">
        <div className="mt-3">
          <SpinnerButton
            isLoading={isSubmitting}
            label={values.id === 0 ? 'CREATE' : 'SAVE CHANGES'}
          />
        </div>
      </div>
    </Form>
);

class AddLifeCycleStage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.selectedProjectLifeCycleStageId,
      title: '',
      isSubmitting: false,
    };
    this.saveProjectLifeCycleStage = this.saveProjectLifeCycleStage.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideAddProjectLifeCycleStage();
    }
  }

  showSpinner(value) {
    this.setState({ isSubmitting: value });
  }

  saveProjectLifeCycleStage(model) {
    if (model.id !== 0) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '), id: model.id, sort_order: model.sort_order, questionModelId: model.questionModelId,
      };
      this.props.updateProjectLifeCycleStage(data, this.props.questionModelId);
      this.props.hideAddProjectLifeCycleStage();
    } else if (model.title) {
      const data = { title: model.title.trim().replace(/  +/g, ' '), sort_order: model.sort_order, questionModelId: model.questionModelId };
      this.props.insertProjectLifeCycleStage(data, this.props.questionModelId).then(() => {
        this.showSpinner(false);
      });
      this.props.hideAddProjectLifeCycleStage();
    }
  }

  render() {
    const { selectedProjectLifeCycleStage, sortOrderForNew } = this.props;
    const projectLifeCycleStageModel = {
      initialTitle: selectedProjectLifeCycleStage.title ? selectedProjectLifeCycleStage.title : '',
      title: selectedProjectLifeCycleStage.title ? selectedProjectLifeCycleStage.title : '',
      id: selectedProjectLifeCycleStage.id ? selectedProjectLifeCycleStage.id : 0,
      sort_order: selectedProjectLifeCycleStage.id
        ? selectedProjectLifeCycleStage.sort_order
        : sortOrderForNew,
      questionModelId: this.props.questionModelId,
    };
    return (
      <div className="right-sidebar" ref={this.setWrapperRef}>
        <div className="r-s-header">
          <div className="r-s-title">
            <span>
              {' '}
              {selectedProjectLifeCycleStage.id === 0 ? 'Create a new' : 'Modify'} lifecycle stage{' '}
              <i
                className="appkiticon icon-close-outline"
                onClick={this.props.hideAddProjectLifeCycleStage}
              />
            </span>
          </div>
        </div>
        <div className="app">
          <Formik
            enableReinitialize
            initialValues={projectLifeCycleStageModel}
            onSubmit={(values, actions) => {
              this.showSpinner(true);
              isValidateProjectLifecycleStageName(values, actions).then((isExist) => {
                if (!isExist) {
                  this.saveProjectLifeCycleStage(values);
                } else {
                  this.showSpinner(false);
                }
              });
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().trim().required('Lifecycle Stage is required.')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
            })}
            render={formikProps => (
              <BasicForm {...formikProps} isSubmitting={this.state.isSubmitting} />
            )}
          />
        </div>
      </div>
    );
  }
}

AddLifeCycleStage.propTypes = {
  selectedProjectLifeCycleStageId: PropTypes.number,
  selectedProjectLifeCycleStage: PropTypes.object,
  sortOrderForNew: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddLifeCycleStage);
