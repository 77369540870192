import React, { Component } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SpinnerButton } from '../Loader';
import { showHideOverlay } from '../../modules/common/overlay/actions/overlay';
import {
  enableUnsavedChanges,
} from '../../modules/general/actions/general';

class UnsavedChangesConfirmation extends Component {
  constructor(props) {
    super(props);

    this.cancelClick = this.cancelClick.bind(this);
    this.confirmationClick = this.confirmationClick.bind(this);
    this.unsavedChangesWrapperRef = this.unsavedChangesWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      unsavedChangesMessage: 'Are you sure you want to close this pane without saving your changes?',
      unsavedChangesTitle: 'Unsaved Changes',
      label: 'CONTINUE',
    };
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#unsavedChangesConfirmation').modal('show');
  }

  unsavedChangesWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClick();
    }
  }

  cancelClick() {
    $('#unsavedChangesConfirmation').modal('hide');
    this.props.cancelClick();
  }

  confirmationClick() {
    $('#unsavedChangesConfirmation').modal('hide');
    this.props.confirmationClick();
    this.props.showHideOverlay(false);
  }

  render() {
    const { unsavedChangesMessage, unsavedChangesTitle, label } = this.state;
    const { modalMessage, modalTitle, submitButtonLabel } = this.props;
    return (
      <div aria-hidden="true" className="modal a-modal fade" id="unsavedChangesConfirmation"
        role="dialog" ref={this.unsavedChangesWrapperRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">{modalTitle || unsavedChangesTitle}</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  onClick={this.cancelClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div className="a-text-9e9 a-p4 a-mt-10">{modalMessage || unsavedChangesMessage}</div>
            </div>
            { !this.props.hideActions && <div className="a-modal-footer a-border-tp">
              <div className="a-flex-stretch text-left a-font-sm a-text-464" />
              <button
                aria-label="Close"
                onClick={this.cancelClick}
                className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10"
              >
                CANCEL
              </button>
              <SpinnerButton
                onClick={() => this.confirmationClick()}
                label={submitButtonLabel || label}
              />
            </div>
          }
          </div>
        </div>
      </div>
    );
  }
}

UnsavedChangesConfirmation.propTypes = {
  message: PropTypes.string,
  selectedId: PropTypes.number,
};

const mapStateToProps = state => ({
  unsavedChanges: state.general.unsavedChanges,
});

const mapDispatchToProps = {
  showHideOverlay,
  enableUnsavedChanges,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnsavedChangesConfirmation);
