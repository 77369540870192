import actionType from './actionType';

const initialState = {
  archiveModelList: {},
  archiveOrganisationList: {},
  archiveTopicList: {},
  archiveModelCount: 0,
  archiveOrganisationCount: 0,
  archiveTopicCount: 0,
  selectAllModels: false,
  selectAllOrganisations: false,
  selectAllTopics: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_ARCHIVED_QUESTION_MODEL_LIST: {
      return {
        ...state,
        archiveModelList: action.archiveModels.archiveModelList,
        archiveModelCount: action.archiveModels.count,
        selectAllModels: false,
      };
    }
    case actionType.GET_ARCHIVED_ORGANISATION_LIST: {
      return {
        ...state,
        archiveOrganisationList: action.archiveOrganisations.archiveOrganisationList,
        archiveOrganisationCount: action.archiveOrganisations.count,
        selectAllOrganisations: false,
      };
    }
    case actionType.GET_ARCHIVED_TOPIC_LIST: {
      return {
        ...state,
        archiveTopicList: action.archiveTopics.archiveTopicList,
        archiveTopicCount: action.archiveTopics.count,
        selectAllTopics: false,
      };
    }
    case actionType.TOGGLE_ALL_MODEL_CHECKBOXES: {
      const archiveModelList = state.archiveModelList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAllModels;
        return obj;
      });
      return {
        ...state,
        selectAllModels: !state.selectAllModels,
        archiveModelList,
      };
    }

    case actionType.TOGGLE_ALL_ORG_CHECKBOXES: {
      const archiveOrganisationList = state.archiveOrganisationList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAllOrganisations;
        return obj;
      });
      return {
        ...state,
        selectAllOrganisations: !state.selectAllOrganisations,
        archiveOrganisationList,
      };
    }

    case actionType.TOGGLE_ALL_TOPIC_CHECKBOXES: {
      const archiveTopicList = state.archiveTopicList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAllTopics;
        return obj;
      });
      return {
        ...state,
        selectAllTopics: !state.selectAllTopics,
        archiveTopicList,
      };
    }

    case actionType.TOGGLE_MODEL_CHECKBOX: {
      const archiveModelList = state.archiveModelList.map((item) => {
        const obj = item;
        if (obj.id === action.user.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAllModels = !(archiveModelList.filter(item => item.isChecked === false)
        .length > 0);
      return {
        ...state,
        selectAllModels,
        archiveModelList,
      };
    }

    case actionType.TOGGLE_ORG_CHECKBOX: {
      const archiveOrganisationList = state.archiveOrganisationList.map((item) => {
        const obj = item;
        if (obj.id === action.user.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAllOrganisations = !(
        archiveOrganisationList.filter(item => item.isChecked === false)
          .length > 0);
      return {
        ...state,
        selectAllOrganisations,
        archiveOrganisationList,
      };
    }

    case actionType.TOGGLE_TOPIC_CHECKBOX: {
      const archiveTopicList = state.archiveTopicList.map((item) => {
        const obj = item;
        if (obj.id === action.user.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAllTopics = !(archiveTopicList.filter(item => item.isChecked === false)
        .length > 0);
      return {
        ...state,
        selectAllTopics,
        archiveTopicList,
      };
    }
    default:
      return state;
  }
};
