const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const SHOW_RECORD_PROCESSING = 'SHOW_RECORD_PROCESSING';
const HIDE_RECORD_PROCESSING = 'HIDE_RECORD_PROCESSING';

export default {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  SHOW_RECORD_PROCESSING,
  HIDE_RECORD_PROCESSING,
};
