import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';

import dotSvg from '../../../assets/images/component/svg/dot.svg';

class FailureAlertWithTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFailureAlert: this.props.showFailureAlert,
    };
  }

  dismissFaliureAlert = () => {
    this.setState({ showFailureAlert: false });
  }

  render() {
    const { alertMesage, isRequestFromSegment } = this.props;
    if (!this.state.showFailureAlert) {
      return null;
    }
    return (
      <React.Fragment >
        {alertMesage && <div class="alert alert-success success-alert-with-timer a-alert custom-alert alert-timer error" role="alert"
        style={{
          left: '50%',
          top: '100px',
          transform: 'translate(-50%, -50%)',
          zIndex: 10,
          padding: 0,
        }}
        >
          <div className="a-alert-content">
            <div className="a-alert-badge error">
              <span className="appkiticon icon-alert-fill me-0"></span>
            </div>
            <span className="a-content me-0 preview-pane-font" style={{ flexBasis: 'unset' }}>
              {alertMesage}
            </span>
            {isRequestFromSegment ? (
              <span onClick={this.dismissFaliureAlert} class="close-btn a-countdown-wrapper alert-dismissible m-0 p-0" style={{ right: '0px', top: '20px' }}>
                <span class="appkiticon icon-close-fill p-0"></span>
              </span>
            ) : <div className="a-countdown-wrapper">
                <ReactSVG src={dotSvg} />
              </div>
            }
          </div>
        </div>}
      </React.Fragment>
    );
  }
}

export default FailureAlertWithTimer;
