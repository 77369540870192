import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  isProcessingRecords: false,
  showProcessingRecords: false,
  passedRecords: 0,
  failedRecords: 0,
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SHOW_PROCESSING_RECORDS:
      return {
        ...state,
        isProcessingRecords: true,
        showProcessingRecords: true,
        passedRecords: (state.passedRecords + action.data.passedRecords),
        failedRecords: (state.failedRecords + action.data.failedRecords),
      };
    case actionType.HIDE_PROCESSING_RECORDS:
      return {
        ...state,
        isProcessingRecords: false,
        showProcessingRecords: false,
        passedRecords: 0,
        failedRecords: 0,
      };
    case actionType.SHOW_PROCESSING:
      return {
        ...state,
        showProcessingRecords: true,
      };
    case actionType.HIDE_PROCESSING:
      return {
        ...state,
        showProcessingRecords: false,
      };
    default:
      return state;
  }
};
