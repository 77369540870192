import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  axiosData: { isLoading: false },
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SHOW_LOADER:
      return {
        ...state,
        axiosData: {
          ...state.axiosData,
          isLoading: true,
        },
      };
    case actionType.HIDE_LOADER:
      return {
        ...state,
        axiosData: {
          ...state.axiosData,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
