import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import RenderIf from '../../../ui-components/Common';
import { Spinner, SpinnerButton } from '../../../ui-components/Loader';
import constant from '../actions/constant';
import {
  validateUserByEmail,
  createNewUser,
} from '../../account/actions/iam';
import {
  addPortfolioStakeholders,
  getPortFolioStakeholders,
  hidePortfolioStakeholderRightPanel,
} from '../actions/portfolio';
import { RegisterEmail } from '../../../ui-components/Account';
import { getPortfolio, getSelectedPortfolio } from '../selectors/portfolio';
import authService from '../../../redux/services/authService';

import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelOneOrganizations, levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import UnsavedChangesConfirmation from '../../../ui-components/UnsavedChangesConfirmation/UnsavedChangesConfirmation.jsx';
import {
  disableUnsavedChangesModal, disableUnsavedChanges,
  enableUnsavedChanges, enableUnsavedChangesModal,
} from '../../general/actions/general';
import chunkedPromises from '../../../utils/utils';
import {
  showRecordProcessing,
  hideRecordProcessing,
} from '../../project/actions/project';
import { showHideOverlay, updateUnsavedChanges } from '../../common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  systemTerminology: getSystemTerminologySelector(state),
  selectedPortfolio: getSelectedPortfolio(state),
  unsavedChangesModal: state.general.unsavedChangesModal,
  unsavedChanges: state.general.unsavedChanges,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
  roles: state.portfolio.portfolioData.roles,
  isSysAdmin: state.login.isSysAdmin,
});

const mapDispatchToProps = {
  validateUserByEmail,
  createNewUser,
  addPortfolioStakeholders,
  getPortFolioStakeholders,
  hidePortfolioStakeholderRightPanel,
  getSystemTerminology,
  disableUnsavedChangesModal,
  disableUnsavedChanges,
  enableUnsavedChanges,
  enableUnsavedChangesModal,
  showRecordProcessing,
  hideRecordProcessing,
  showHideOverlay,
  updateUnsavedChanges,
};

class PortfolioStakeholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      loggedInUserId: 0,
      stakeholders: [],
      stakeholdersToBeAdded: [],
      stakeholdersToBeRemoved: [],
      isActive: true,
      isLoading: true,
      isSubmitting: false,
      showRegisterPortfolioStakeholdersModel: false,
      showRegisterPortfolioStakeholdersStep2Model: false,
      selectedStakeholdersEmailForDelete: '',
      showStakeholdersDelete: false,
      deleteStakeholdersTitle: 'Remove #portfolioReplaceText# Viewer',
      deleteStakeholdersConfirmationMessage: `Are you sure you want to remove this user 
            from #portfolioReplaceText# Viewers?`,
      totalEmails: [],
    };

    this.confirmStakeholdersDelete = this.confirmStakeholdersDelete.bind(this);
    this.cancelStakeholdersDelete = this.cancelStakeholdersDelete.bind(this);
    this.removePortfolioStakeholder = this.removePortfolioStakeholder.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.validateAndSave = this.validateAndSave.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.props.getSystemTerminology();
    const { portfolioData } = this.props;
    getPortFolioStakeholders({ id: portfolioData.selectedPortfolio }).then((res) => {
      this.setState({
        stakeholders: res,
        isLoading: false,
      });
    });
    const userInfo = authService.getUserInfo();
    this.setState({ loggedInUserId: userInfo.user_id });
  }


  toggleUnsavedChanges() {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChanges();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unsavedChanges && nextProps.unsavedChangesModal && !this.state.isSubmitting) {
      this.setState({ showUnsavedChangesModal: true });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }


  retainUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.setState({ showUnsavedChangesModal: false });
  }

  cancelUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.setState({ showUnsavedChangesModal: false });
    this.props.hidePortfolioStakeholderRightPanel();
    this.props.showHideOverlay(false);
  }

  hidePortfolioStakeholderRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.hidePortfolioStakeholderRightPanel();
      this.props.showHideOverlay(false);
    }
  }

  openRegisterPortfolioStakeholdersModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterPortfolioStakeholdersModel: true });
  }

  async registerPortfolioStakeholders(emails) {
    const formattedEmails = emails.map(email => email.toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewStakeholderToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...r);
    return response;
  }

  pushNewStakeholderToList(userData) {
    this.toggleUnsavedChanges();
    const { stakeholders, stakeholdersToBeAdded, stakeholdersToBeRemoved } = this.state;
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    const user = {
      id,
      first_name: FirstName,
      last_name: LastName,
      email_address: emailId.toLowerCase(),
      is_user_registered: isRegistered,
    };
    stakeholders.push(user);
    stakeholdersToBeAdded.push(user);
    const updateStakeholdersToBeRemoved = stakeholdersToBeRemoved
      .filter(stakeholder => stakeholder !== id);
    this.setState({
      stakeholders,
      stakeholdersToBeAdded,
      stakeholdersToBeRemoved: updateStakeholdersToBeRemoved,
    });
  }

  registerPortfolioStakeholdersStep2(formData) {
    const userModel = {
      email: this.state.RegisteringUserEmail,
      firstName: formData.firstName,
      lastName: formData.lastName,
      portfolioId: this.state.id,
      organisation: 'external', // This was required field So adding data as mentioned in wiki
      territory: 'AU', // This was required field So adding data as mentioned in wiki
      assuranceLevel: '', // This was required field So adding data as mentioned in wiki
    };


    return this.props.createNewUser(userModel).then((response) => {
      const { status, userData } = response.data;
      if (status === constant.Success || status === constant.UserAlreadyOnboarded) {
        this.pushNewStakeholderToList(userData);
      }
      return response;
    });
  }

  showSpinner(value) {
    this.setState({ isSubmitting: value });
  }

  // Confirm Delete
  confirmStakeholdersDelete(emailId) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      selectedStakeholdersEmailForDelete: emailId,
      showStakeholdersDelete: true,
    });
  }

  cancelStakeholdersDelete() {
    this.setState({
      selectedStakeholdersEmailForDelete: '',
      showStakeholdersDelete: false,
    });
  }

  // Delete Project Life Cycle Stages
  removePortfolioStakeholder() {
    const that = this;
    this.toggleUnsavedChanges();
    const {
      stakeholders, stakeholdersToBeRemoved, stakeholdersToBeAdded,
      selectedStakeholdersEmailForDelete,
    } = that.state;
    let updateStakeholdersToBeAdded = stakeholdersToBeAdded;
    let index = -1;
    this.state.stakeholders.map((item, idx) => {
      if (item.email_address === selectedStakeholdersEmailForDelete) { index = idx; }
      return item;
    });
    if (index >= 0) {
      const removedStakeholder = stakeholders.splice(index, 1);
      updateStakeholdersToBeAdded = stakeholdersToBeAdded
        .filter(stakeholder => stakeholder.email_address !== selectedStakeholdersEmailForDelete);
      if (updateStakeholdersToBeAdded.length === stakeholdersToBeAdded.length) {
        stakeholdersToBeRemoved.push(removedStakeholder[0].id);
      }
    }
    this.setState({
      stakeholders,
      stakeholdersToBeRemoved,
      stakeholdersToBeAdded: updateStakeholdersToBeAdded,
    });
    return new Promise(((resolve) => {
      resolve(true);
    }));
  }

  stakeholdersCount(stakeholdersToBeAdded, stakeholdersToBeRemoved) {
    if (stakeholdersToBeAdded.length > 0) {
      this.setState({ totalEmails: stakeholdersToBeAdded.length - stakeholdersToBeRemoved.length });
    } else {
      this.setState({ totalEmails: stakeholdersToBeAdded.length });
    }
  }

  async validateAndSave() {
    const { stakeholdersToBeAdded, stakeholdersToBeRemoved } = this.state;
    this.stakeholdersCount(stakeholdersToBeAdded, stakeholdersToBeRemoved);
    this.setState({ isSubmitted: true });
    this.props.disableUnsavedChanges();
    this.props.disableUnsavedChangesModal();
    this.showSpinner(true);
    this.props.showRecordProcessing(0, 0);
    if (stakeholdersToBeAdded.length > 0) {
      await chunkedPromises(stakeholdersToBeAdded, 10,
        async (c) => {
          const model = {
            stakeholdersToBeAdded: c,
            stakeholdersToBeRemoved,
            portfolioId: this.props.portfolioData.selectedPortfolio,
          };
          await this.props.addPortfolioStakeholders(model).then((res) => {
            this.props.showRecordProcessing(c.length, res);
          });
        }).then(() => {
          this.props.hideRecordProcessing();
          this.showSpinner(false);
          this.props.hidePortfolioStakeholderRightPanel();
          this.props.showHideOverlay(false);
        });
    } else {
      const model = {
        stakeholdersToBeAdded,
        stakeholdersToBeRemoved,
        portfolioId: this.props.portfolioData.selectedPortfolio,
      };
      this.props.addPortfolioStakeholders(model);
      this.props.hideRecordProcessing();
      this.showSpinner(false);
      this.props.hidePortfolioStakeholderRightPanel();
      this.props.showHideOverlay(false);
    }
  }

  render() {
    const {
      isLoading,
      stakeholders,
      showRegisterPortfolioStakeholdersModel,
      showStakeholdersDelete,
      deleteStakeholdersConfirmationMessage,
      deleteStakeholdersTitle,
      showUnsavedChangesModal,
      isSubmitting,
      isSubmitted,
      totalEmails,
    } = this.state;

    const {
      systemTerminology, isProcessingRecords, passedRecords, failedRecords,
      roles, isSysAdmin,
    } = this.props;

    const isAuthoriseToView = !!(roles.portfolioAdministrator || isSysAdmin);

    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;

    const projectReplace = levelTwoOrganizations.find(
      o => o.value === systemTerminology.level_two,
    );
    const projectReplaceText = projectReplace && projectReplace.text;

    const newDeleteStakeholdersTitle = deleteStakeholdersTitle.replace(/#portfolioReplaceText#/g, portfolioReplaceText);
    const newDeleteStakeholdersConfirmationMessage = deleteStakeholdersConfirmationMessage.replace(/#portfolioReplaceText#/g, portfolioReplaceText);


    const { title, message, placeholder } = constant.registerEmailStakeholderModel;
    const replaceWithPrefix = text => text.replace(/#projectReplaceTextWithPreFix#/g,
      ('aeiou'.indexOf(portfolioReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a ')
      + portfolioReplaceText);
    let newStakeHolderTitle = title.replace(/#portfolioReplaceText#/g, portfolioReplaceText);
    newStakeHolderTitle = replaceWithPrefix(newStakeHolderTitle);
    let newStakeHolderMessage = message.replace(/#portfolioReplaceText#/g, portfolioReplaceText);
    newStakeHolderMessage = replaceWithPrefix(newStakeHolderMessage);

    return (
      <React.Fragment >
        <RenderIf showComponent={showUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelUnsavedChanges.bind(this)}
            cancelClick={this.retainUnsavedChanges.bind(this)}
          />
        </RenderIf>
        {showStakeholdersDelete && (
          <div>
            <DeleteConfirmation
              title={newDeleteStakeholdersTitle}
              message={newDeleteStakeholdersConfirmationMessage}
              hideActions={false}
              confirmationClick={this.removePortfolioStakeholder.bind(this)}
              cancelClick={this.cancelStakeholdersDelete.bind(this)}
            />
          </div>
        )}

        <RenderIf showComponent={showRegisterPortfolioStakeholdersModel}>
          <RegisterEmail
            title={newStakeHolderTitle}
            message={newStakeHolderMessage}
            placeholder={placeholder}
            availableEmails={stakeholders.map(item => item.email_address)}
            onContinueButtonClick={this.registerPortfolioStakeholders.bind(this)}
            cancelClick={() => this.setState({
              showRegisterPortfolioStakeholdersModel: false,
            })}
            isRestrictedUser={this.props.selectedPortfolio.isRestrictedUser}
            isStakeholders={false}
            isTestEmail={false}
          />
        </RenderIf>

        <div className="right-sidebar add-stakeholder-sidebar" ref={this.setWrapperRef}>
          <Spinner isLoading={isLoading} />
          <RenderIf showComponent={!isLoading}>
            <div className="r-s-header">
              <div className="r-s-title">
                <span> {portfolioReplaceText} Viewers <i class="appkiticon icon-close-outline"
                  onClick={() => this.hidePortfolioStakeholderRightPanel()}></i></span>
              </div>
            </div>
            <div className="r-s-body mt-3">
              <p class="a-font-md m-0">
                The people listed below can view all the {`${projectReplaceText} `}
                results of this {portfolioReplaceText},
                however they won't receive any questions unless they are
                added as a stakeholder on a specific {projectReplaceText}.
              </p>
              <div class="mt-3 mb-4">
                <ul class="a-list cross-list portfolio-stakeholder">
                  {stakeholders.map((item, index) => (
                    <li className="a-list-item d-flex align-items-center" key={index}>
                      {item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.first_name} {item.last_name}</div>}
                      {!item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.email_address}</div>}
                      {isAuthoriseToView && <i className="appkiticon icon-close-fill a-font-xs" onClick={() => this.confirmStakeholdersDelete(item.email_address)} />}
                      {item.id > 0 && item.is_user_registered !== undefined
                        && !item.is_user_registered
                        && <div className={'a-badge a-badge-secondary'}>Pending Registration</div>}
                    </li>
                  ))}
                </ul>
              </div>
              {isAuthoriseToView && <a href="javascript:void(0);" onClick={this.openRegisterPortfolioStakeholdersModel.bind(this)}
                class="add-text-button mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray">
                <i class="appkiticon icon-plus-fill mr-2"> </i> Add a viewer</a>}
            </div>
            <div className="r-s-actions mt-4">
              <div className="mt-3 mb-4">
                {isProcessingRecords && isSubmitted && (
                  <span style={{
                    position: 'relative',
                    float: 'right',
                    fontSize: 10,
                    top: 10,
                    color: failedRecords > 0 ? 'red' : 'rgba(0, 0, 0, 0.6)',
                  }}>{passedRecords} of {totalEmails}
                    {' '}users assigned successfully</span>)}
                <div onClick={() => {
                  this.validateAndSave();
                }}>
                  {isAuthoriseToView && <SpinnerButton
                    isLoading={isSubmitting}
                    label={'SAVE CHANGES'}
                  />}
                </div>
              </div>
            </div>
            {/* for domains general page ends */}
          </RenderIf>
        </div>
      </React.Fragment>

    );
  }
}
PortfolioStakeholders.propTypes = {

};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortfolioStakeholders);
