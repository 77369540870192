const permissions = {
  systemAdministrator: {
    static: [
      'settings',
      'portfolio:create',
      'portfolio:modify',
      'portfolio:delete',
      'portfolio:add-administrator',
      'portfolio:add-stakeholder',
      'portfolio:view-dashboard',
      'project:create',
      'project:modify',
      'project:delete',
      'project:add-administrator',
      'project:add-stakeholder',
      'project:view-dashboard',
      'project:filter-project-dashboard',
      'model:create',
      'domain:customise-area',
      'portfolio:manage-portfolio',
      'question-model:manage-question-model',
    ],
  },
  portfolioAdministrator: {
    static: [
      'portfolio:modify',
      'portfolio:add-administrator',
      'portfolio:add-stakeholder',
      'portfolio:view-dashboard',
      'project:create',
      'project:modify',
      'project:delete',
      'project:add-administrator',
      'project:add-stakeholder',
      'domain:customise-area',
      'portfolio:filter-portfolio-dashboard',
      'project:filter-project-dashboard',
      'portfolio:manage-portfolio',
      'question-model:manage-question-model',
    ],
  },
  portfolioStakeholder: {
    static: [
      'portfolio:view-dashboard',
      'project:view-dashboard',
      'project:filter-project-dashboard',
      // This add-stakeholder permission is used to show stakeholder button
      // on topic dashboard and not for actually adding stakeholders.
      'project:add-stakeholder',
      'portfolio:add-stakeholder',
      'portfolio:manage-portfolio',
    ],
  },
  projectAdministrator: {
    static: [
      'project:modify',
      'project:add-administrator',
      'project:add-stakeholder',
      'project:view-dashboard',
      'domain:customise-area',
      'project:filter-project-dashboard',
      'portfolio:manage-portfolio',
    ],
  },
  projectViewer: {
    static: [
      'project:add-stakeholder',
      'project:view-dashboard',
      'project:filter-project-dashboard',
      'portfolio:manage-portfolio',
    ],
  },
  projectStakeholder: {
    static: [
      'personal-dashboard:view',
      'project-questions:respond',
      'project:filter-project-dashboard',
    ],

    dynamic: {
      'project:view-dashboard': ({ allowed }) => allowed,
    },
  },
  questionModelAuthor: {
    static: [
      'settings',
      'can-modify-settings',
      'question-model:manage-question-model',

    ],
  },

};

export default permissions;
