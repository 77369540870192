import React from 'react';
import ReactTooltip from 'react-tooltip';

const InsufficientResultInfoTooltip = () => (
  <span class="pop-out-help user-guidance">
    <i id="user" data-for="user"
      data-tip="result_tooltip"
      class="material-icons md-dark" currentitem="false">info</i>
    <ReactTooltip id='user' className='result_tooltip' place='bottom'
      effect='solid'>
      <span>There are not enough stakeholder responses received yet
        for us to show you meaningful insights.</span>
      <ul>
        <li>Each focus area requires 50% of the underlying attributes to pass the data
          integrity threshold in order to display a score.</li>
        <li>This overall topic requires 50% of the regular (non-custom) focus areas to
          pass the data integrity threshold in order to display an overall score for
          the topic.</li>
      </ul>
      <span>Please check back after more stakeholders have responded.</span>
    </ReactTooltip>
  </span>
);

export default InsufficientResultInfoTooltip;
