import $ from 'jquery';

$("body").on("click", ".question-dd-click", function (event) {
    event.stopPropagation();
    var divID = $(this).attr("data-target");
    $("#" + divID).addClass("show-dropdown");
    $("#" + divID).siblings().removeClass("show-dropdown");
  });
  var divToHide = document.getElementsByClassName('a-user-setting-panel-one');
  document.onclick = function(e){
      $('.show-dropdown').removeClass('show-dropdown')
    };