import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import Cryptr from 'cryptr';
import MaterialIcon from 'material-icons-react';
import { Formik, Field } from 'formik';
import SettingSumoSelect from '../../../../ui-components/SettingSumoSelect/SettingSumoSelect';
import AddQuestion from '../components/AddQuestion.jsx';
import AlertPopup from '../../../../ui-components/AlertPopup/AlertPopup.jsx';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import { getDomains, getDomainsForFilter } from '../actions/domain';
import { getAttributes, getAttributesForFilter } from '../actions/attribute';
import { getProjectLifeCycleStagesList } from '../actions/general';
import {
  setHeading,
  getQuestions,
  deleteQuestions,
  getQuestionStages,
  toggleAllCheckboxes,
  toggleCheckbox,
  canDelete,
  canBulkDelete,
  modifyAddedQuestionStatus,
  changeAddedQuestionStatus,
} from '../actions/question';
import {
  getQuestionModels,
  changeSelectedQuestionModel,
} from '../actions/questionModel';
import { questionSelector } from '../selectors/question';
import { getprojectLifeCycleStagesListSelector } from '../selectors/general';
import { domainSelector } from '../selectors/domain';
import { attributeSelector } from '../selectors/attribute';
import RenderIf from '../../../../ui-components/Common';
import { Spinner } from '../../../../ui-components/Loader';
import Pagination from '../../../../ui-components/Pagination/Pagination.jsx';
import { NO_OF_RECORDS, INITIAL_PAGINATED_DATA, FEEDBACK_PAGE_REF } from '../../../../Constants';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';
import archiveLockSvg from '../../../../../assets/images/component/svg/archive-lock-icon.svg';


const mapStateToProps = state => ({
  question: questionSelector(state),
  attributes: attributeSelector(state),
  domains: domainSelector(state),
  lifecycleStages: getprojectLifeCycleStagesListSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const mapDispatchToProps = {
  getQuestions,
  setHeading,
  getQuestionStages,
  getDomains,
  getAttributes,
  getProjectLifeCycleStagesList,
  toggleAllCheckboxes,
  toggleCheckbox,
  canDelete,
  canBulkDelete,
  deleteQuestions,
  modifyAddedQuestionStatus,
  changeAddedQuestionStatus,
  getQuestionModels,
  changeSelectedQuestionModel,
};

const types = { yesNo: 1, openText: 2 };
const unparentedAttribute = 'UNPARENTED ATTRIBUTES';
const messages = {
  cannotDeleteQuestionTitle: 'Cannot Delete Question',
  cannotDeleteQuestionMessage: `This question has one or more associated
    responses and it cannot be deleted.`,
  cannotDeleteMultipleQuestionsTitle: 'Cannot Delete Questions',
  cannotDeleteMultipleQuestionsMessage: `One or more of the selected questions have associated 
    responses and cannot be deleted.`,
  deleteQuestionTitle: 'Delete a Question',
  deleteQuestionMessage: `This question will be permanently deleted. 
    Are you sure you want to continue?`,
  deleteMultipleQuestionsTitle: 'Delete Selected Questions',
  deleteMultipleQuestionsMessage: `These questions will be permanently deleted. 
    Are you sure you want to continue?`,
};


const cryptr = new Cryptr(__CONFIG__.encryptSecret);

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQuestion: {},
      heading: 'Questions',
      animate: false,
      showAddQuestion: false,
      showAlertPopup: false,
      showDelete: false,
      selectedQuestionIdsForDelete: [],
      selectedDomains: [],
      selectedAttributes: [],
      selectedLifecycleStages: [],
      selectedTypes: [],
      selectedLifecycleStagesForAddEdit: [],
      alertPopupTitle: '',
      alertPopupMessage: '',
      deleteTitle: '',
      deleteConfirmationMessage: '',
      isLoading: true,
      questFilters: {
        selectedKeyword: '',
        selectedDomains: [],
        selectedAttributes: [],
        selectedLifecycleStages: [],
        selectedTypes: [],
      },
      currentPage: 1,
      totalPages: 1,
      listLoading: true,
      filteredAttributeList: [],
      filteredAttributeListdropdown: [],
      isFilterResetting: false,
      isEnableResetButton: true,
    };
    this.domainsList = [];
    this.domainNotFound = false;
    this.attributeNotFound = false;
    this.lifecycleStageNotFound = false;
    this.attributesList = [];
    this.attributesDomainList = [];
    this.lifecycleStagesList = [];
    this.typesList = [
      { text: 'Yes/No', value: 1 },
      { text: 'Open Text', value: 2 },
    ];
    this.showAddQuestionPanel = this.showAddQuestionPanel.bind(this);
    this.filterQuestions = this.filterQuestions.bind(this);
    this.hideAddQuestionPanel = this.hideAddQuestionPanel.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmBulkDelete = this.confirmBulkDelete.bind(this);
    this.alertPopupOkClick = this.alertPopupOkClick.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteQuestions = this.deleteQuestions.bind(this);
    this.filterAttributesBasedOnDomains = this.filterAttributesBasedOnDomains.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  componentDidMount() {
    this.props.setHeading(this.state.heading);
    this.props.setHeading(this.state.heading);
    const questionModelId = this.props.match.params.id;
    const decryptQuestionModelId = cryptr.decrypt(questionModelId);
    if (questionModelId || this.props.questionModelId) {
      this.props.changeSelectedQuestionModel(decryptQuestionModelId, 'questions');
      this.getQuestionsList(null, INITIAL_PAGINATED_DATA, parseInt(decryptQuestionModelId, 10));
    }

    const self = this;
    Promise.all([
      new Promise((resolve) => {
        getDomainsForFilter(parseInt(decryptQuestionModelId, 10)).then((data) => {
          self.domainNotFound = data.length === 0;
          data.map((item) => {
            const obj = {
              text: item.title,
              value: item.id,
            };
            self.domainsList.push(obj);
          });

          resolve(true);
        });
      }),
      new Promise((resolve) => {
        getAttributesForFilter(parseInt(decryptQuestionModelId, 10)).then((response) => {
          const res = response.map((item) => {
            const obj = {
              text: item.title,
              value: item.id,
            };
            return obj;
          });
          this.setState({
            filteredAttributeListdropdown: res,
          });
          resolve(true);
          self.attributeNotFound = response.length === 0;
          response.reverse()
            .reduce((data, { domainName, title, id }) => {
              const domainText = domainName != null ? domainName : unparentedAttribute;

              if (!self.attributesDomainList[domainText]) {
                self.attributesDomainList.push(
                  {
                    text: domainText,
                    value: domainText,
                    attributes: [],
                  },
                );
                self.attributesDomainList[domainText] = [];
              }
              const obj = {
                text: title,
                value: id,
              };

              self.attributesDomainList[domainText].push(obj);
            }, 0);

          response.map((item) => {
            const obj = {
              text: item.title,
              value: item.id,
            };
            self.attributesList.push(obj);
          });
          this.setState({
            filteredAttributeList: self.attributesList,
          });
          resolve(true);
        });
      }),
      new Promise((resolve) => {
        this.props.getProjectLifeCycleStagesList(parseInt(decryptQuestionModelId, 10)).then(() => {
          self.lifecycleStageNotFound = self.props.lifecycleStages.length === 0;
          self.props.lifecycleStages.map((data) => {
            const obj = {
              text: data.title,
              value: data.id,
            };
            self.lifecycleStagesList.push(obj);
          });
          resolve(true);
        });
      }),
    ]).then(() => {
      self.setState({ isLoading: false });
    });
  }

  getTotalNumOfPages() {
    return this.props.question.questionCount !== 0
      ? Math.ceil(this.props.question.questionCount / NO_OF_RECORDS) : 1;
  }

  getQuestionsList = async (filters, paginatedData, questionModelId) => {
    this.setState({
      listLoading: true,
    });
    await this.props.getQuestions(filters, paginatedData, questionModelId).then(() => {
      this.setState({
        totalPages: this.getTotalNumOfPages(),
        listLoading: false,
      });
    });
    this.setState({
      isLoading: false,
    });
    this.props.getQuestionModels().then((response) => {
      if (response.data && response.data.length
        && this.props.questionModelId === 0) {
        this.props.changeSelectedQuestionModel(questionModelId);
      }
    });
  }

  filterQuestions(filters) {
    const questionModel = filters;
    questionModel.selectedKeyword = questionModel.selectedKeyword;
    questionModel.selectedDomains = questionModel.selectedDomains.map(
      item => parseInt(item, 10),
    );
    questionModel.selectedAttributes = questionModel.selectedAttributes.map(
      item => parseInt(item, 10),
    );
    questionModel.selectedLifecycleStages = questionModel.selectedLifecycleStages.map(
      item => parseInt(item, 10),
    );
    questionModel.selectedTypes = questionModel.selectedTypes.map(item => parseInt(item, 10));
    this.setState({
      questFilters: questionModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (questionModel.selectedDomains.length
      || questionModel.selectedAttributes.length
      || questionModel.selectedLifecycleStages.length
      || questionModel.selectedTypes.length
      || questionModel.selectedKeyword
    ) {
      this.setState({ isEnableResetButton: false });
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getQuestionsList(filters,
        { noOfRecords: NO_OF_RECORDS, pageNo: 0 },
        this.props.questionModelId);
    }, 1000);
  }

  showAddQuestionPanel() {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddQuestion: true,
        animate: true,
        selectedQuestion: {},
        selectedLifecycleStagesForAddEdit: [],

      });
    }, 500);
  }

  editQuestion(obj) {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddQuestion: true,
        animate: true,
        selectedQuestion: obj,
        selectedLifecycleStagesForAddEdit: [],
      });
    }, 300);
  }

  hideAddQuestionPanel = async () => {
    const { isQuestionAddedOrModified, isQuestionAdded, questionsList } = this.props.question;
    const { questFilters, currentPage } = this.state;
    this.setState({
      showAddQuestion: false,
      animate: false,
      selectedQuestion: {},
    });
    if (isQuestionAddedOrModified) {
      await this.getQuestionsList(questFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: currentPage - 1 }, this.props.questionModelId);
      this.props.modifyAddedQuestionStatus(false);
    } else if (isQuestionAdded) {
      await this.getQuestionsList(questFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: currentPage - 1 }, this.props.questionModelId);
      const { totalPages } = this.state;
      if (questionsList && questionsList.length === 10) {
        if (currentPage < totalPages) {
          const diff = totalPages - currentPage;
          const newPage = currentPage + diff;
          this.updateCurrentPage(newPage, true);
        } else {
          const newPage = totalPages + 1;
          this.updateCurrentPage(newPage, true);
        }
      }
    }
    this.props.changeAddedQuestionStatus(false);
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  // Confirm Delete
  confirmDelete(questionId) {
    if (this.props.question.questionsList.length > 0) {
      this.props.canDelete(questionId).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedQuestionIdsForDelete: [questionId],
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteQuestionTitle,
            deleteConfirmationMessage: messages.deleteQuestionMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteQuestionTitle,
            alertPopupMessage: messages.cannotDeleteQuestionMessage,
          });
        }
      });
    }
  }

  confirmBulkDelete() {
    if (this.props.question.questionsList.length > 0) {
      const selectedIds = [];
      this.props.question.questionsList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });

      this.props.canBulkDelete(selectedIds).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedQuestionIdsForDelete: selectedIds,
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteMultipleQuestionsTitle,
            deleteConfirmationMessage: messages.deleteMultipleQuestionsMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteMultipleQuestionsTitle,
            alertPopupMessage: messages.cannotDeleteMultipleQuestionsMessage,
          });
        }
      });
    }
  }

  alertPopupOkClick() {
    this.setState({
      selectedQuestionIdsForDelete: [],
      showDelete: false,
      showAlertPopup: false,
    });
  }

  cancelDelete() {
    this.setState({
      selectedQuestionIdsForDelete: [],
      showDelete: false,
    });
  }

  truncate(s) {
    const n = s.length;
    if (n > 100) {
      const trimmedString = s.substring(0, 100);
      return `${trimmedString}...`;
    }

    return s;
  }

  // Delete Questions
  deleteQuestions() {
    return this.props.deleteQuestions({ selectedIds: this.state.selectedQuestionIdsForDelete },
      this.state.questFilters)
      .then((response) => {
        const { currentPage } = this.state;
        this.props.getQuestions(
          this.state.questFilters,
          INITIAL_PAGINATED_DATA,
          this.props.questionModelId,
        ).then(() => {
          if (this.state.currentPage > this.getTotalNumOfPages()) {
            this.goToPage(this.state.currentPage - 1);
          } else { this.goToPage(this.state.currentPage); }
          const totalNoOfPages = this.getTotalNumOfPages();
          this.setState({
            totalPages: totalNoOfPages,
            selectedQuestionIdsForDelete: [],
            currentPage: currentPage > totalNoOfPages ? totalNoOfPages : currentPage,
          });
        });
        return response;
      });
  }

  // paging code
  nextPage() {
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) this.updateCurrentPage((currentPage + 1), true);
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage((currentPage - 1), true);
  }

  updateCurrentPage(pageNum, status) {
    this.setState({
      currentPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getQuestionsList(this.state.questFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: (pageNum - 1) }, this.props.questionModelId);
    }
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value, true);
    } else {
      this.updateCurrentPage(1, true);
    }
  }

  pageOnChangeEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateCurrentPage(value, false);
  }

  filterAttributesBasedOnDomains = (values, setFieldValue) => {
    if (values.selectedDomains?.length > 0) {
      const filteredAttributeList = this.attributesList.filter((attribute) => {
        let isAttibuteInDomain = false;
        values.selectedDomains.forEach((selectedDomain) => {
          // eslint-disable-next-line
          const attibutesInSelectedDomain = this.attributesDomainList[this.domainsList.find(domain => domain.value == selectedDomain)?.text] || [];
          // eslint-disable-next-line
          if ((attibutesInSelectedDomain?.findIndex(attibuteInSelectedDomain => attibuteInSelectedDomain.value == attribute.value)) != -1) {
            isAttibuteInDomain = true;
          }
        });
        return isAttibuteInDomain;
      });
      // eslint-disable-next-line
      const updatedselectedAttributes = values.selectedAttributes.filter(selectedAttribute => (filteredAttributeList?.findIndex(filteredAttribute => filteredAttribute.value == selectedAttribute)) != -1);
      values.selectedAttributes = updatedselectedAttributes;
      setFieldValue('selectedAttributes', updatedselectedAttributes);
      this.filterQuestions(values);
      this.setState({ filteredAttributeList });
    } else {
      this.setState({ filteredAttributeList: this.attributesList });
    }
  }

  resetFilters() {
    const questionModel = {
      selectedDomains: [],
      selectedAttributes: [],
      selectedLifecycleStages: [],
      selectedTypes: [],
      selectedKeyword: '',
    };
    this.setState({
      questFilters: questionModel,
      isFilterResetting: true,
      filteredAttributeList: this.attributesList,
    });
    this.filterQuestions(questionModel);
    setTimeout(() => {
      this.setState({
        isFilterResetting: false,
      });
    }, 1);
  }

  render() {
    const {
      isLoading, totalPages, currentPage, listLoading, isFilterResetting, isEnableResetButton,
    } = this.state;
    const { question } = this.props;
    const {
      domainsList,
      attributesList,
      attributesDomainList,
      lifecycleStagesList,
      typesList,
      domainNotFound,
      attributeNotFound,
      lifecycleStageNotFound,
    } = this;
    const questionModel = {
      selectedKeyword: this.state.questFilters.selectedKeyword,
      selectedDomains: this.state.questFilters.selectedDomains,
      selectedAttributes: this.state.questFilters.selectedAttributes,
      selectedLifecycleStages: this.state.questFilters.selectedLifecycleStages,
      selectedTypes: this.state.questFilters.selectedTypes,
    };

    return (
      <div>
        <Spinner isLoading={isLoading} />
        <RenderIf showComponent={!isLoading}>
          <div className="users-container">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <p className="a-font-sm m-0 mb-3">Define a set of questions that will be distributed to your stakeholders.</p>
              </div>
            </div>

            <div className="a-table-wrapper row">
              <div className="a-selector-wrapper col-md-12  min-height">
                <div class="row justify-content-between align-items-center pb-1 pt-1 m-0 mb-3">
                  <div class="col">
                    {!isFilterResetting && <Formik
                      enableReinitialize={true}
                      initialValues={questionModel}>
                      {({ setFieldValue, values }) => (
                        <div class="row justify-content-center align-items-center">
                          <div class="col-md-6 col-lg-9 mr-auto">
                            {(domainsList.length || this.state.filteredAttributeListdropdown.length || lifecycleStagesList.length || typesList.length) && <div class="row">
                              <div className="col-auto">
                                <strong className="filter-label">Filter by:</strong>
                              </div>
                              <div class="col-auto">
                                <div class="a-dropdown">
                                  {(domainsList.length > 0 || domainNotFound)
                                    && <SettingSumoSelect
                                      multiple="multiple"
                                      placeholder={domainNotFound ? 'No Focus Area' : 'Focus Area'}
                                      name="selectedDomains"
                                      selectedValues={values.selectedDomains}
                                      options={domainsList}
                                      setFieldValue={setFieldValue}
                                      onSumoSelectChange={() => {
                                        this.filterQuestions(values);
                                        this.filterAttributesBasedOnDomains(values, setFieldValue);
                                      }}
                                      captionFormatAllSelected="All Domains"
                                    />
                                  }
                                </div>
                              </div>
                              <div class="col-auto">
                                <div class="a-dropdown">
                                  <SettingSumoSelect
                                    multiple="multiple"
                                    placeholder={
                                      (this.state?.filteredAttributeListdropdown?.length === 0
                                        || attributeNotFound)
                                        ? 'No Attribute'
                                        : 'Attributes'}
                                    name="selectedAttributes"
                                    selectedValues={
                                      values.selectedAttributes}
                                    options={this.state.filteredAttributeListdropdown}
                                    setFieldValue={setFieldValue}
                                    onSumoSelectChange={() => this.filterQuestions(values)}
                                    captionFormatAllSelected="All Attributes"
                                  />
                                </div>
                              </div>
                              <div class="col-auto">
                                <div class="a-dropdown">
                                  {(lifecycleStagesList.length > 0 || lifecycleStageNotFound)
                                    && <SettingSumoSelect
                                      multiple="multiple"
                                      placeholder={lifecycleStageNotFound ? 'No Stage' : 'Stages'}
                                      name="selectedLifecycleStages"
                                      selectedValues={
                                        values.selectedLifecycleStages}
                                      options={lifecycleStagesList}
                                      setFieldValue={setFieldValue}
                                      onSumoSelectChange={() => this.filterQuestions(values)}
                                      captionFormatAllSelected="All Stages"
                                    />
                                  }
                                </div>
                              </div>
                              <div class="col-auto">
                                <div class="a-dropdown">
                                  {typesList.length > 0
                                    && <SettingSumoSelect
                                      multiple="multiple"
                                      placeholder="Types"
                                      name="selectedTypes"
                                      selectedValues={
                                        values.selectedTypes}
                                      options={typesList}
                                      setFieldValue={setFieldValue}
                                      onSumoSelectChange={() => this.filterQuestions(values)}
                                      captionFormatAllSelected="All Types"
                                    />
                                  }
                                </div>
                              </div>
                              {!isEnableResetButton && <div class="col-auto">
                                <button href="javascript:void(0)"
                                  class="text-theme-color text-center d-block pl-2 pr-2"
                                  onClick={() => this.resetFilters()}
                                >
                                  CLEAR</button>
                              </div>}

                            </div>}
                          </div>
                          <div class="col-md-6 col-lg-3 ml-auto">
                            <div class="row justify-content-start">
                              <div class="col px-0">
                                <div class="input-group-with-icon">
                                  <Field name='selectedKeyword'
                                    placeholder="Search"
                                    class="w-100 custom-input-filter-icon"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setFieldValue('selectedKeyword', e.target.value);
                                      values.selectedKeyword = e.target.value;
                                      this.filterQuestions(values);
                                    }}
                                  />
                                  <MaterialIcon className="material-icons md-24 text-theme-color" icon="search" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>}
                  </div>
                </div>

              </div>
              <Spinner isLoading={listLoading} />
              <RenderIf showComponent={!listLoading}>
                <div className="a-table-scroller  question-table col-md-12 a-mb-10 pr-0">
                  <table className="a-table data-table a-lg min-w-1024 sortable selectable setting-table">
                    <thead className="a-thead">
                      <tr>
                        <th className="q-check-cell">
                          <label className="a-checkbox">
                            <input
                              type="checkbox"
                              onChange={this.toggleAllCheckboxes}
                              checked={question.questionsList
                                .filter(item => item.isChecked).length > 0}
                            />
                            <span className="a-checkbox-mark">
                              <span
                                className={`appkiticon   
                          ${question.questionsList.filter(item => item.isChecked).length > 0
                                    && !question.selectAll
                                    ? 'icon-minus-fill'
                                    : ''
                                  }  
                          ${question.questionsList.filter(item => item.isChecked).length > 0
                                    && question.selectAll
                                    ? 'icon-check-mark-fill'
                                    : ''
                                  }`}
                              />
                            </span>

                          </label>
                        </th>
                        <th className="q-question-cell">Question / Statement</th>
                        <th className="q-polarity-cell">Polarity</th>
                        <th className="q-state-cell">State</th>
                        <th className="q-attribute-cell">Attribute</th>
                        <th className="q-attribute-cell">Focus Area</th>
                        <th className="q-type-cell">Type</th>
                        <th className="t-cross-header q-cross-icon"></th>
                      </tr>
                    </thead>
                    <tbody className="a-tbody">
                      {question.questionsList.length > 0
                        ? question.questionsList.map((obj, index) => <tr key={index}>
                          <td className="q-check-cell">
                            <label className="a-checkbox">
                              <input type="checkbox" checked={obj.isChecked}
                                onChange={() => this.toggleCheckbox(obj)} />
                              <span className="a-checkbox-mark">
                                <span className="appkiticon icon-check-mark-fill" />
                              </span>
                            </label>
                          </td>
                          <td className="q-question-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            <span className='question-cell-conatiner preview-pane-font' title={obj.title}>
                              {this.truncate(obj.title)}
                            </span>
                          </td>
                          <td className="q-polarity-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            <span className='cell-container preview-pane-font'>
                              {obj.polarity}
                            </span></td>
                          <td className="q-state-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            <div className='d-flex align-items-center'>
                              <span className='cell-container preview-pane-font' style={{ width: 'auto', marginRight: '4px' }}>
                                {obj.is_active ? 'Active' : 'Inactive'}
                              </span>
                              {obj.isArchived
                                && <ReactSVG id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' wrapper="svg" width="12px" height="12px" src={archiveLockSvg} />
                              }
                              <ReactTooltip id='stakeHoldersCanView' className='archive_lock_tooltip' place='top'
                                effect='solid'>
                                This question is locked and cannot be edited as it relates
                                to prior topics. If you would like to re-use this question
                                but make changes, please create  a new question instead.
                              </ReactTooltip>
                            </div>

                          </td>
                          <td className="q-attribute-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            {obj.attribute_id
                              ? <span className='attribute-cell-container preview-pane-font'>
                                {obj.attributeName}
                              </span> : <span className='attribute-cell-container preview-pane-font'>-</span>
                            }
                          </td>
                          <td className="q-attribute-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            {obj.attribute_id
                              ? <span className='attribute-cell-container preview-pane-font'>
                                {obj.domainTitle}
                              </span> : <span className='attribute-cell-container preview-pane-font'>-</span>
                            }
                          </td>
                          <td className="q-type-cell cursor-pointer" onClick={this.editQuestion.bind(this, obj)}>
                            <span className='cell-container preview-pane-font' >
                              {obj.type === types.yesNo ? 'Yes/No' : 'Open Text'}
                            </span>
                          </td>
                          <td className="t-cross-icon q-cross-icon">
                            <i className="appkiticon icon-close-fill a-font-sm cursor-pointer"
                              onClick={() => this.confirmDelete(obj.id)}></i>
                          </td>
                        </tr>, this) : <tr>
                          <td colSpan={7} className="font-weight-medium">Click the button below to create your first question.</td>
                        </tr>}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-12 col-sm-12 col-12 pr-0 at-pagination">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPageEvent={this.nextPage.bind(this)}
                    prevPageEvent={this.prevPage.bind(this)}
                    goToPageEvent={this.goToPage.bind(this)}
                    pageOnChange={this.pageOnChangeEvent.bind(this)}
                  />
                </div>
              </RenderIf>
            </div>
            <a className="add-text-button mt-3  a-btn a-btn-transparent a-btn-lg a-btn-gray" href="javascript:void(0)" onClick={this.showAddQuestionPanel.bind(this)}>
              <i className="appkiticon icon-plus-fill mr-2"> </i> Create a new question</a>
          </div>
          <div>
            {
              this.state.showAlertPopup ? <AlertPopup
                title={this.state.alertPopupTitle}
                message={this.state.alertPopupMessage}
                alertPopupOkClick={this.alertPopupOkClick} />
                : ''
            }
            {
              this.state.showDelete ? <DeleteConfirmation
                title={this.state.deleteTitle}
                message={this.state.deleteConfirmationMessage}
                confirmationClick={this.deleteQuestions}
                cancelClick={this.cancelDelete} />
                : ''
            }
            <CSSTransitionGroup
              transitionName="pwcsidebar"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}>
              {
                this.state.showAddQuestion
                  ? <AddQuestion
                    attributesList={attributesList}
                    attributesDomainList={attributesDomainList}
                    lifecycleStagesList={lifecycleStagesList}
                    hideAddQuestionPanel={this.hideAddQuestionPanel}
                    selectedQuestion={this.state.selectedQuestion}
                    filters={this.state.questFilters}
                  />
                  : ''
              }
            </CSSTransitionGroup>
          </div>
          <div className="footer-dropdowns">
            <button
              className="a-btn a-btn-primary a-btn-lg dropdown-toggle"
              data-bs-toggle="dropdown"
              disabled={question.questionsList.filter(item => item.isChecked).length <= 0}
            >
              actions <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
            </button>
            <ul className="user-infor-list dropdown-menu a-shadow-sm border-0">
              <li className="user-infor-list-item" onClick={() => this.confirmBulkDelete()}>
                <a href="javascript:void(0)">
                  Delete Selected Items
                </a>
              </li>
            </ul>
          </div>
        </RenderIf>
        <Feedback pageRef={FEEDBACK_PAGE_REF.QUESTION_PAGE} isShowSupportBtn = {false}/>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Question);
