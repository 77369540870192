import permissions from './appRolePermissions';

const check = (allPermissions, role, action, data) => {
  const permission = allPermissions[role];
  if (!permission) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permission.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permission.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};
const Authorise = props => (check(permissions, props.role, props.perform, props.data)
  ? props.permit()
  : props.restrict());

Authorise.defaultProps = {
  permit: () => null,
  restrict: () => null,
};

export default Authorise;
