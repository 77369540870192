import { actionType } from '../../../../redux/modules/common/notification';

// Action creater for notification
export function showErrorActionCreater(errorMessage) {
  return { type: actionType.SHOW_NOTIFICATION, errorMessage };
}

export function hideErrorActionCreater() {
  return { type: actionType.HIDE_NOTIFICATION };
}

// Creating thunk action creaters which will be access from component
function showError() {
  return (dispatch) => {
    dispatch(showErrorActionCreater('error'));
  };
}

function hideError() {
  return (dispatch) => {
    dispatch(hideErrorActionCreater());
  };
}

export default { showError, hideError };
