import React, { Component } from 'react';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MaterialIcon from 'material-icons-react';
import { SpinnerButton } from '../../../../ui-components/Loader';
import {
  insertUser, validateEmailAddress, updateUser, deleteUsers, getSystemAdminUsersCount,
} from '../actions/user';
import { addUserSelector } from '../selectors/user';
import { isInternalPwcUser } from '../../../../utils/helper';
import { NO_OF_MINIMUM_ADMIN } from '../../../../Constants';
import accountService from '../../../../redux/services/accountService';
import authService from '../../../../redux/services/authService';


const mapStateToProps = state => ({
  addUser: addUserSelector(state),
  redirectUrl: state.brandwrapper.redirectUrl,
});

const mapDispatchToProps = {
  validateEmailAddress,
  insertUser,
  updateUser,
  deleteUsers,
};

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isValidateUserEmail(value, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'An user with the same email address already exists.';
    const emailId = value.emailId.trim().replace(/  +/g, ' ');
    if (emailId && !value.id) {
      validateEmailAddress({ user: emailId }).then((val) => {
        if (val.isInternalUser) {
          const internalUserDoesnotExist = 'No internal user with the email address exists.';
          if (val.userFound) {
            resolve(false);
          } else {
            setError(actions, 'emailId', internalUserDoesnotExist);
            resolve(true);
          }
        } else if (val) {
          setError(actions, 'emailId', errorMessage);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else {
      resolve(false);
    }
  });
}

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: '',
      firstName: '',
      lastName: '',
      isUserInternal: false,
      isAdmin: false,
      isUserRegistered: false,
      isSubmitting: false,
      selectedUserIdForDelete: [],
      isStateChanged: false,
      showAlertPopup: false,
      alertPopupTitle: '',
      alertPopupMessage: '',
    };
    this.isAdmin = '';
    this.hideAddUserPanel = this.hideAddUserPanel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.checkIfUserIsInternal = this.checkIfUserIsInternal.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.deleteUsers = this.deleteUsers.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  checkIfUserIsInternal(value, setFieldValue) {
    if (isInternalPwcUser(value)) {
      this.setState({ isUserInternal: true });
    } else {
      this.setState({ isUserInternal: false });
      setFieldValue('isAdmin', false);
    }
    setFieldValue('emailId', value);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  hideAddUserPanel() {
    this.props.hideAddUserPanel();
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideAddUserPanel();
    }
  }

  saveUser(model, actions) {
    if (model.id !== 0) {
      const loggedUserInfo = authService.getUserInfo();
      getSystemAdminUsersCount().then((response) => {
        const overallAdmins = parseInt(response.data, 10);
        const { selectedUser } = this.props;
        const data = {
          id: model.id,
          firstName: model.firstName.trim(),
          lastName: model.lastName.trim(),
          isAdmin: model.isAdmin,
        };
        if (overallAdmins > NO_OF_MINIMUM_ADMIN && selectedUser.is_admin === true) {
          this.props.updateUser(data).then((res) => {
            const { description } = res.data;
            this.showSpinner(false);
            if (description) {
              setError(actions, 'emailId', description);
            } else {
              this.props.hideAddUserPanel();
            }
          });
          if ((selectedUser.id === loggedUserInfo.user_id)
            && model.isAdmin === false) {
            setTimeout(() => {
              accountService.logUserSignOut(
                selectedUser.id,
                '',
              ).then(() => {
                window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
              });
            }, 1000);
          }
        } else if (selectedUser.is_admin && !model.isAdmin) {
          this.setState({ isSubmitting: false });
          this.props.minimumSystemAdminCountAlert('Cannot Update User', 'Cannot update selected System Admin as minimum two System Administrators should present in the system.');
        } else {
          this.props.updateUser(data).then((res) => {
            const { description } = res.data;
            this.showSpinner(false);
            if (description) {
              setError(actions, 'emailId', description);
            } else {
              this.props.hideAddUserPanel();
            }
          });
        }
      });
    } else {
      const data = {
        emailId: model.emailId.trim().replace(/  +/g, ' '),
        firstName: model.firstName.trim().replace(/  +/g, ' '),
        lastName: model.lastName.trim().replace(/  +/g, ' '),
        isAdmin: model.isAdmin,
      };
      this.props.insertUser(data).then((res) => {
        const { description } = res.data;
        this.showSpinner(false);
        if (description) {
          setError(actions, 'emailId', description);
        } else {
          this.props.hideAddUserPanel();
        }
      });
    }
  }

  isValidateUserEmail(values, actions) {
    this.showSpinner(true);
    isValidateUserEmail(values, actions).then((isExist) => {
      if (!isExist) {
        this.saveUser(values, actions);
      } else {
        this.showSpinner(false);
      }
    });
  }

  showSpinner(isSubmitting) {
    this.setState(prevState => ({
      ...prevState,
      isSubmitting,
    }));
  }

  // Confirm Delete
  confirmDelete(userId) {
    this.setState({
      selectedUserIdForDelete: [userId],
      showDelete: true,
      deleteTitle: 'Delete Account',
      deleteConfirmationMessage: 'Are you sure you want to delete this account?',
    });
  }

  cancelDelete() {
    this.setState({
      selectedUserIdForDelete: [],
      showDelete: false,
    });
  }

  // Delete Users
  deleteUsers() {
    return this.props
      .deleteUsers({ selectedIds: this.state.selectedUserIdForDelete })
      .then((response) => {
        this.setState({
          selectedUserIdForDelete: [],
        });
        this.props.hideAddUserPanel();
        return response;
      });
  }


  render() {
    const self = this;
    let userModel = {};
    const { isUserInternal } = this.state;

    const { selectedUser } = this.props;
    const selectedUserAdmin = (selectedUser.is_admin
      && !this.state.isStateChanged) ? selectedUser.is_admin : this.state.isAdmin;
    userModel = {
      id: selectedUser.id ? selectedUser.id : 0,
      emailId: selectedUser.email_address ? selectedUser.email_address : this.state.emailId,
      firstName: selectedUser.first_name ? selectedUser.first_name : this.state.firstName,
      lastName: selectedUser.last_name ? selectedUser.last_name : this.state.lastName,
      isAdmin: selectedUserAdmin,
      isInternal: selectedUser.is_external === false,
      initialEmailId: selectedUser.email_address ? selectedUser.email_address : this.state.emailId,
      isRegistered: selectedUser.is_user_registered
        ? selectedUser.is_user_registered : this.state.isUserRegistered,
    };


    return (
      <div className="right-sidebar user-sidebar-container" ref={this.setWrapperRef}>
        <div className="r-s-header">
          <div className="r-s-title">
            <span>
              {selectedUser.id ? 'Modify existing user' : 'Create a new user'}
              <i className="appkiticon icon-close-outline" onClick={this.hideAddUserPanel} />
            </span>
          </div>
          <div className="indicate_val">
            <span className="mandatory-icon">* </span>
            <span className="in_text preview-pane-font"> indicates required field</span>
          </div>
        </div>
        <div className="app">
          <Formik enableReinitialize={true}
            initialValues={userModel}
            onSubmit={(values, actions) => this.isValidateUserEmail(values, actions)}
            validationSchema={() => Yup.lazy(() => Yup.object().shape({
              emailId: Yup.string().trim().required('Email address is required.')
                .email('Email Address is not valid')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
              firstName: userModel.id !== 0 && userModel.isRegistered && Yup.string().trim().required('First Name is required.')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
              lastName: userModel.id !== 0 && userModel.isRegistered && Yup.string().trim().required('Last Name is required.')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
            }))}>
            {({
              errors,
              touched,
              handleSubmit,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="r-s-body mt-3">
                  <div className="r-s-body mt-3">
                    <div className="form-group">
                      <div className="a-form-label">Email Address<span className="required-star">*</span> </div>
                      <div className=" text-icon icon-right">
                        {values.id !== 0
                          && <MaterialIcon icon="lock" />
                        }
                        <Field
                          disabled={values.id !== 0}
                          name="emailId"
                          autoFocus={!selectedUser.id}
                          autoComplete="off"
                          value={values.emailId}
                          placeholder="Email Address"
                          onChange={(e) => {
                            this.checkIfUserIsInternal(e.target.value, setFieldValue);
                          }}
                          className={`${errors.emailId && touched.emailId ? 'error a-text-input' : 'a-text-input'}`}
                        />
                      </div>

                      <div className="error">
                        <ErrorMessage name="emailId" />
                      </div>

                    </div>
                    {(values.id !== 0) && (
                      <div className="form-group">
                        <div className="a-form-label">First Name <span className="required-star">*</span> </div>
                        <div className=" text-icon icon-right">
                          {(values.isInternal || !values.isRegistered)
                            && <MaterialIcon icon="lock" />
                          }
                          <Field
                            disabled={values.isInternal || !values.isRegistered}
                            name="firstName"
                            autoComplete="off"
                            value={values.firstName}
                            placeholder="First Name"
                            className={`${errors.firstName && touched.firstName ? 'error a-text-input' : 'a-text-input'}`}
                          />
                        </div>

                        <div className="error">
                          <ErrorMessage name="firstName" />
                        </div>
                      </div>
                    )}
                    {(values.id !== 0) && (
                      <div className="form-group">
                        <div className="a-form-label">Last Name <span className="required-star">*</span> </div>
                        <div className=" text-icon icon-right">
                          {(values.isInternal || !values.isRegistered)
                            && <MaterialIcon icon="lock" />
                          }
                          <Field
                            disabled={values.isInternal || !values.isRegistered}
                            name="lastName"
                            autoComplete="off"
                            value={values.lastName}
                            placeholder="Last Name"
                            className={`${errors.lastName && touched.lastName ? 'error a-text-input' : 'a-text-input'}`}
                          />
                        </div>

                        <div className="error">
                          <ErrorMessage name="lastName" />
                        </div>
                      </div>
                    )}
                    {isUserInternal || (values.emailId && (isInternalPwcUser(values.emailId)))
                      ? (<div className="form-group ">
                        <label className="a-toggle a-m-5 font-normal">
                          <input
                            type="checkbox"
                            name="toggle"
                            checked={values.isAdmin}
                            onChange={() => {
                              self.setState({
                                emailId: values.emailId,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                isAdmin: !values.isAdmin,
                                isStateChanged: true,
                              });
                            }}
                          />
                          <div className="a-toggle-mark">
                            <span className="on preview-pane-font"></span>
                            <span className="off preview-pane-font"></span>
                            <span className="switch preview-pane-font"></span>
                          </div>
                          <span className="a-toggle-text preview-pane-font">
                            System Administrator
                          </span>
                        </label>
                      </div>) : ''}
                  </div>
                </div>

                <div className="r-s-actions mt-4">

                  <div className="mt-3 mb-3">
                    <SpinnerButton isLoading={this.state.isSubmitting}
                      label={values.id === 0 ? 'CREATE' : 'SAVE CHANGES'} />
                  </div>

                  {values.id !== 0
                    && <a className="mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray" href="javascript:void(0)"
                      onClick={() => this.props.confirmDelete(values.id)}>
                      <span>DELETE ACCOUNT </span></a>
                  }

                </div>
              </form>
            )}

          </Formik>
        </div>

      </div >
    );
  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(AddUser);
