import React, { Component } from 'react';
import { connect } from 'react-redux';
import './LeftPanel.scss';
import $ from 'jquery';
import { push } from 'connected-react-router';
import Cryptr from 'cryptr';
import Authorise from '../../Authorise';
import { PwcLink } from '../Navigation';
import constant from '../../modules/account/actions/constant';
import { questionModelSelector } from '../../modules/settings/question-model/selectors/questionModel';
import {
  getQuestionModels,
  showQuestionModelRightPanel,
  hideQuestionModelRightPanel,
  changeSelectedQuestionModel,
} from '../../modules/settings/question-model/actions/questionModel';
import QuestionSetting from './SettingSetting/QuestionSetting.jsx';

const mapStateToProps = state => ({
  router: state.router,
  location: state.router.location,
  questionModel: questionModelSelector(state),
  isSysAdmin: state.login.isSysAdmin,
});


const mapDispatchToProps = {
  getQuestionModels,
  showQuestionModelRightPanel,
  hideQuestionModelRightPanel,
  changeSelectedQuestionModel,
  push,
};
const cryptr = new Cryptr(__CONFIG__.encryptSecret);
class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: '',
      initialLoad: true,
      isAnyUnsavedChanges: false,
    };
    this.showQuestionModelRightPanel = this.showQuestionModelRightPanel.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isAnyUnsavedChanges: nextProps.unsavedChanges });
  }

  componentDidMount() {
    this.props.getQuestionModels();
  }

  componentDidUpdate(prevProps) {
    // set toggle icon after rendering
    const openQuestionModels = $('.show.project-list');
    if (parseInt(this.props.questionModel.questionModelData.selectedQuestionModel, 10)
      !== parseInt(prevProps.questionModel.questionModelData.selectedQuestionModel, 10)) {
      for (let i = 0; i < openQuestionModels.length; i += 1) {
        $(`#${openQuestionModels[i].id}`).removeClass('show');
      }
    }
    this.expandSelectedQuestionModel();

    // TO SCROLL TO THE SELECTED QUESTION MODEL IN THE LEFT PANEL
    const { questionModelData } = this.props.questionModel;
    const selectedQuestionModel = questionModelData?.questionModelsData?.find(
      questionModel => questionModel.id
        === this.props.questionModel.questionModelData.selectedQuestionModel,
    );
    if (selectedQuestionModel?.title && document.getElementById(selectedQuestionModel.title)) {
      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById(selectedQuestionModel.title)?.scrollIntoView({
          behavior: 'smooth', block: 'center', inline: 'center',
        });
      }, 100);
    }
  }

  expandSelectedQuestionModel() {
    const { selectedQuestionModel } = this.props.questionModel.questionModelData;
    const { initialLoad } = this.state;
    if (initialLoad) {
      $(`.portfolio${selectedQuestionModel} .portfolio-title`).removeClass('collapsed');
      $(`.portfolio${selectedQuestionModel} .project-list`).addClass('show');
    }
  }

  changeSelectedQuestionModel(questionModel, page) {
    if (!this.state.isAnyUnsavedChanges) {
      this.props.changeSelectedQuestionModel(questionModel.id, page);
      this.props.hideQuestionModelRightPanel();
      this.navigate(`/settings/${cryptr.encrypt(questionModel.id)}/stage`);
      this.setState({ initialLoad: false });
    }
  }

  showQuestionModelRightPanel() {
    setTimeout(() => {
      this.props.showQuestionModelRightPanel();
    }, 300);
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  changeQuestionModel(selectedQuestionModel, page) {
    if (!this.state.isAnyUnsavedChanges) {
      this.props.changeSelectedQuestionModel(selectedQuestionModel, page);
      this.props.hideQuestionModelRightPanel();
      this.navigate(`/settings/${cryptr.encrypt(selectedQuestionModel)}/${page}`);
    }
  }


  render() {
    const {
      selectedQuestionModel,
      questionModelFirstPage,
    } = this.props.questionModel.questionModelData;
    const {
      questionModelsData,
    } = this.props.questionModel.questionModelData;
    const { isSysAdmin } = this.props;
    const sortedQuestionModelsData = questionModelsData.filter(obj => obj.showQuestionModal
      !== false);

    return (
      <div className="d-flex">
        <div className="text-left a-bg-gray d-flex">
          <div className="a-navigation">
             <Authorise role={isSysAdmin ? 'systemAdministrator' : 'questionModelAuthor'}
              perform='question-model:manage-question-model'
              permit={() => <QuestionSetting
                removeActivePortfoliosData={questionModelsData}
                selectedPortfolio={selectedQuestionModel}
                actualPortfolio={questionModelsData}
                portfolioReplaceText={'Question Model'}
              />
              }
            />
            <div className="a-accordion-wrapper icon-right settings-a-wrapper">
              <ul className="a-list">
                {sortedQuestionModelsData.length > 0
                  && sortedQuestionModelsData.map((questionModel, index) => (
                    <li className={`portfolio${questionModel.id}`} key={questionModel.id}>
                      <div className="a-list-control d-flex align-items-center justify-content-between">
                        <div>
                          <div id={questionModel.title}
                            data-bs-toggle="collapse"
                            href={`#collapsed${index}`}
                            aria-expanded="false"
                            className={`a-h6 portfolio-title collapsed collapsed${index}
                              ${selectedQuestionModel === questionModel.id ? ' active ' : ' '} `}
                            onClick={() => {
                              this.changeSelectedQuestionModel(questionModel, 'stage');
                            }}
                          >
                            {questionModel.title}
                            <i className="appkiticon icon-down-chevron-fill  a-control-icon" />
                          </div>
                        </div>
                      </div>
                      <ul className={'project-list collapse'}
                        id={`collapsed${index}`}>
                        <li
                          className="a-list-item d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={this.changeQuestionModel.bind(
                              this,
                              questionModel.id,
                              'stage',
                            )}
                            className={`cursor-pointer a-font-md ${questionModelFirstPage === 'stage' ? 'active' : ''
                              }`}
                          >
                            General
                          </div>
                        </li>
                        <li
                          className="a-list-item d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={this.changeQuestionModel.bind(
                              this,
                              questionModel.id,
                              'domains',
                            )}
                            className={`cursor-pointer a-font-md ${questionModelFirstPage === 'domains' ? 'active' : ''
                              }`}
                          >
                            {`Focus Area(${questionModel.domainsCount})`}
                          </div>
                        </li>
                        <li
                          className="a-list-item d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={this.changeQuestionModel.bind(
                              this,
                              questionModel.id,
                              'attributes',
                            )}
                            className={`cursor-pointer a-font-md ${questionModelFirstPage === 'attributes' ? 'active' : ''
                              }`}
                          >
                            {`Attributes(${questionModel.attributesCount})`}
                          </div>
                        </li>
                        <li
                          className="a-list-item d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={this.changeQuestionModel.bind(
                              this,
                              questionModel.id,
                              'questions',
                            )}
                            className={`cursor-pointer a-font-md ${questionModelFirstPage === 'questions' ? 'active' : ''
                              }`}
                          >
                            {`Questions(${questionModel.questionsCount})`}
                          </div>
                        </li>
                      </ul>
                    </li>

                  ))}
                {questionModelsData.length <= 0 && <div>No models found</div>}
              </ul>
            </div>
            <div className=" ">

              <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                perform="model:create"
                permit={() => (<a className="a-btn a-btn-secondary mr-cust1-2 q-m-button mb-1" href="javascript:void(0)" onClick={this.showQuestionModelRightPanel}><i className="appkiticon a-btn-icon icon-plus-fill"></i> Add a Question Model

                </a>
                )} />

              <hr className="mt-2 mb-2"></hr>
              <ul className="a-list side-list"><li className=" d-flex align-items-center justify-content-between">
                <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                  perform="model:create"
                  permit={() => (<PwcLink className="a-h6 portfolio-title" to="/settings/archive-question-models" label="Archive" />
                  )} />


              </li>
                <li className=" d-flex align-items-center justify-content-between">
                  <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                    perform="model:create"
                    permit={() => (<PwcLink className="a-h6 portfolio-title" to="/settings/users" label="Users" />
                    )} />


                </li>
              </ul>
            </div>
            <div className="a-footer">

              <div className="mt-4">
                <a href="/terms-of-use" target="_blank">Terms of Use</a>
              </div>
              <div>
                <a href="/information-collection-statement" target="_blank">Information Collection Statement</a>
              </div>
              <div className="copyright-text mt-3">
                © {constant.currentYear} PwC. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// exporting LeftPanel;
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftPanel);
