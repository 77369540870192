import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import csvParse from 'react-papaparse';
import Flow from '@flowjs/flow.js';
import moment from 'moment';
import { intersection } from 'lodash';
import { ReactSVG } from 'react-svg';
import { SpinnerButton } from '../Loader';
import CSVFilterDownloaderUI from './CSVFilterDownloaderUI.jsx';
import downloadCsvIcon from '../../../assets/images/component/svg/download-light-outline.svg';
import { validateUserByEmail } from '../../modules/account/actions/iam';
import { uploadImportStackholderErrorRecords, updateImportStackHolderFlag } from '../../modules/project/actions/project';
import './UserCsvStyle.scss';
import { CSV_CONSTANTS } from '../../Constants';
import { checkEmptyCSV, getCorrectEntries } from '../../utils/csvHelper';
import { isInternalPwcUser } from '../../utils/helper';


const {
  ERRORS, BUTTON_TEXT, ROWS_FOR_PREVIEW,
} = CSV_CONSTANTS;

const mapStateToProps = state => ({
  projectDetails: state.project.projectDetails,
  overAllWidget: state.project.overAllWidget,
  projectStakeholders: state.project.projectStakeholders,
  projectId: state.portfolio.portfolioData.selectedProjectId,
  userFilterGroups: state.project.userFilterGroups,
});
const mapDispatchToProps = {
  validateUserByEmail,
  uploadImportStackholderErrorRecords,
  updateImportStackHolderFlag,
};

class UserCSVFilterModel extends Component {
  state = {
    isLoading: false,
    showPreview: false,
    correctUsersForCsv: [],
    errorUsersCsv: [],
    errorDetect: false,
    allPwcUsers: [],
    keyNameOfEmailColumn: 'Email',
    disableSubmitButton: false,
    errorMessage: '',
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#userCsvModal').modal('show');
    this.multiUpload(false, '#multi-file-target1', '#multi-upload1');
  }

  multiUpload(isTrigger, fileTargetID, dom) {
    const self = this;
    const multiFlow = new Flow({
      target: '/',
      query: {},
    });
    const uploadArray = [];

    multiFlow.assignBrowse($(fileTargetID));
    multiFlow.assignDrop($(fileTargetID));
    function cancelBind(indexClass, file) {
      $(`${dom } .${ indexClass } .icon-circle-delete-outline`).on('click', () => {
        file.cancel();
        $(`${dom } .${ indexClass}`).remove();
        $('.tooltip').remove();
        if (multiFlow.files.length === 0) {
          $(`${dom } .trigger`).attr('disabled', 'true');
        }
      });
    }
    multiFlow.on('filesAdded', (array) => {
      const uploadArrayLength = uploadArray.length;
      $.each(array, (index, file) => {
        uploadArray.push(file);
        const indexNum = uploadArrayLength + index;
        const fileListTemplate = `<div class="fileList index${ indexNum }">`
          + `<div class="upload-success">
                    <span class="icon-span-lg ft-wrapper"><span class="fileType"></span></span>
                    <span class="fileSpan">
                        <span class="fileName multiple-filename"></span>
                        <span class="fileSize"></span>
                    </span>
                    <div class="progress hidden new">
                        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    <span class="date hidden"></span>
                    <span class="icon-wrapper">
                        <button class="appkiticon icon-circle-delete-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Delete" data-container="body"></button>
                    </span>
                </div>
                <div class="upload-error hidden">
                    <span class="label label-danger">Error</span>
                    <span class="message"></span>
                    <span class="icon-wrapper">
                        <button class="appkiticon icon-refresh-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Retry" data-container="body"></button>
                        <button class="appkiticon icon-circle-delete-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Delete" data-container="body"></button>
                    </span>
                </div>
            </div >`;

        const fileFullName = file.name;
        const flagIdx = fileFullName.lastIndexOf('.');
        const fileType = fileFullName.substring(flagIdx + 1);
        const fileName = file.name;

        let iconType = 'file';
        if (fileType === 'csv') {
          iconType = fileType;
        }

        const iconClass = `fileType appkiticon icon-${ iconType }-fill`;
        const indexClass = `index${ indexNum}`;

        $(dom).append(fileListTemplate);
        $(`${dom } .${ indexClass } .upload-success .fileType`).removeClass();
        $(`${dom } .${ indexClass } .upload-success .ft-wrapper span:first-child`).addClass(iconClass);
        $(`${dom } .${ indexClass } .upload-success span.fileName`).html(fileName);
        $(`${dom } .${ indexClass } .upload-success span.fileName`).attr('title', fileName);

        cancelBind(indexClass, file);
      });
    });

    multiFlow.on('filesSubmitted', (array) => {
      if (!isTrigger) {
        $(`${dom } .upload-success .progress.new`).removeClass('hidden new');
        multiFlow.upload();
      } else if (array.length !== 0) {
          $(`${dom } .trigger`).removeAttr('disabled');
        }
    });

    if (isTrigger) {
      $(`${dom } .trigger`).on('click', () => {
        $(`${dom } .upload-success .progress.new`).removeClass('hidden new');
        multiFlow.upload();
        $(`${dom } .trigger`).attr('disabled', 'true');
      });
    }

    multiFlow.on('fileProgress', (file) => {
      const date = moment().format('MMM DD,YYYY');
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${ index}`;
        }
      });
      const progressSize = `${file.progress() * 100 }%`;
      $(`${dom } .${ indexClass } .upload-success .progress-bar`).css('width', progressSize);

      const fileSizeMB = (file.size / 1024 / 1024).toFixed(1);
      const fileSizeKB = (file.size / 1024).toFixed(1);
      let fileSize;
      if (fileSizeMB < '1') {
        fileSize = `${fileSizeKB }KB`;
      } else {
        fileSize = `${fileSizeMB }MB`;
      }

      if (file.isComplete()) {
        setTimeout(() => {
          $(`${dom } .${ indexClass } .upload-success .progress`).addClass('hidden');
          $(`${dom } .${ indexClass } .upload-success span.date`).removeClass('hidden');
          $(`${dom } .${ indexClass } .upload-success span.date`).html(date);
          $(`${dom } .${ indexClass } .upload-success .icon-circle-delete-outline`).toggleClass('icon-circle-delete-outline icon-delete-outline');
          $(`${dom } .${ indexClass } .upload-success span.fileSize`).html(fileSize);
        }, 1000);
      }

      if ($(`${dom } .${ indexClass } .icon-circle-delete-outline`).length) {
        cancelBind(indexClass, file);
      }
    });

    multiFlow.on('fileSuccess', (file, message, value) => {
      const blob = new Blob([value.bytes], { type: 'text/csv' });
      csvParse.parse(blob, {
        header: true,
        skipEmptyLines: true,
        complete(results) {
          self.updateShowPreview({
            ...results,
            data: results.data.map((val) => {
              const obj = {};
              Object.keys(val).forEach((item) => {
                if (item) {
                  obj[item] = val[item];
                }
              });
              return obj;
            }),
          });
        },
      });
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${ index}`;
        }
      });
      setTimeout(() => {
        $(`${dom } .${ indexClass } .icon-delete-outline`).on('click', () => {
          file.cancel();
          $(`${dom } .${ indexClass}`).remove();
          $('.tooltip').remove();
        });
      }, 600);
    });

    multiFlow.on('fileError', (file) => {
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${ index}`;
        }
      });

      const errorMessage = `Failed to upload file “${ file.name }”`;
      $(`${dom } .${ indexClass } .upload-success`).addClass('hidden');
      $(`${dom } .${ indexClass } .upload-error`).removeClass('hidden');
      $(`${dom } .${ indexClass } .upload-error span.message`).html(errorMessage);

      $(`${dom } .${ indexClass } .icon-refresh-outline`).on('click', () => {
        file.retry();
      });
      cancelBind(indexClass, file);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  modalWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.cancelClick();
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return false;
    }
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
      return true;
    }
    return false;
  };

  cancelClick = () => {
    this.hideModal();
    this.props.cancelClick();
  };

  hideModal = () => {
    $('#userCsvModal').modal('hide');
  };

  showSpinner = (isLoading) => {
    this.setState({ isLoading });
  };

  getRemovedFalsyKeys = (csvData = []) => {
    const removedFalsyKeys = csvData.filter(
      obj => !Object.keys(obj).every(x => !!x),
    );
    let emptyKeysFor = [];
    if (removedFalsyKeys.length) {
      emptyKeysFor = removedFalsyKeys.map(item => ({
        ...item,
        [ERRORS.ErrorColumnName]: ERRORS.unknown,
      }));
    }
    return emptyKeysFor;
  }

  getEmptyEmailsRecords = (csvData = []) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const getEmptyEmailRecord = csvData.filter(obj => obj[keyNameOfEmailColumn].length === 0
      && Object.keys(obj).length > 1);
    return getEmptyEmailRecord.map(item => ({
      ...item,
      [ERRORS.ErrorColumnName]: ERRORS.emptyEmail,
    }));
  }

  getDuplicateEmailsErrorInDB = (csvData) => {
    const { availableEmails = [] } = this.props;
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);

    const existingEmails = filterOutEmptyEmails.map(
      item => item[keyNameOfEmailColumn],
    );
    const matchedEmails = intersection(existingEmails, availableEmails);
    const duplicateEmails = matchedEmails || [];
    const duplicateEmailsErrorsInDB = filterOutEmptyEmails
      .filter(item => duplicateEmails.indexOf(item[keyNameOfEmailColumn]) !== -1)
      .map(item => ({
        ...item,
        [ERRORS.ErrorColumnName]: ERRORS.duplicate,
      }));
    return duplicateEmailsErrorsInDB;
  }

  getUnknownIncorrectErrors = (csvData) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn].trim());
    const existingEmails = filterOutEmptyEmails.map(
      item => item[keyNameOfEmailColumn].trim(),
    );
    const invalidEmails = existingEmails
      .filter(email => !this.validateEmail(email.trim()));

    const updatedResponse = filterOutEmptyEmails
      .filter(item => invalidEmails.indexOf(item[keyNameOfEmailColumn].trim()) !== -1);
    const addErrorColumn = updatedResponse.map((item) => {
      const errorMessage = !item[keyNameOfEmailColumn] ? ERRORS.unknown : ERRORS.incorrect;
      return {
        ...item,
        [ERRORS.ErrorColumnName]: errorMessage,
      };
    });

    return addErrorColumn;
  }

  getAllPwcUsers = (csvData) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);
    return filterOutEmptyEmails
      .filter(item => isInternalPwcUser(item[keyNameOfEmailColumn]));
  }

  getDuplicateEmailsInCsv = (csvData) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);
    return filterOutEmptyEmails.filter((e, i, a) => a
      .map(item => item[keyNameOfEmailColumn]).indexOf(e[keyNameOfEmailColumn]) !== i)
      .map(item => ({
        ...item,
        [ERRORS.ErrorColumnName]: ERRORS.duplicate,
      }));
  };

  getInvalidStackHolder = (correctUsersForCsv) => {
    // eslint-disable-next-line max-len
    let invalidStakeHolder = correctUsersForCsv.filter(object1 => !this.props.projectStakeholders.some(object2 => (object1[this.state.keyNameOfEmailColumn].trim()).toLowerCase() === (object2.email_address.trim()).toLowerCase())).map(record => ({ ...record, [ERRORS.ErrorColumnName]: record[this.state.keyNameOfEmailColumn].length ? ERRORS.invalid : ERRORS.emptyEmail }));
    invalidStakeHolder = invalidStakeHolder.map((data) => {
      let stakeHolderObj = data;
      if (data[this.state.keyNameOfEmailColumn].trim().length === 0) {
        stakeHolderObj = { ...data, [ERRORS.ErrorColumnName]: ERRORS.emptyEmail };
      } else if (data[this.state.keyNameOfEmailColumn].length
        && !this.validateEmail(data[this.state.keyNameOfEmailColumn])) {
        stakeHolderObj = { ...data, [ERRORS.ErrorColumnName]: ERRORS.incorrect };
      }
      return stakeHolderObj;
    });
    return invalidStakeHolder;
  }

  getWrongEntries = (resultData = []) => {
    const emptyKeysFor = this.getRemovedFalsyKeys(resultData);
    const duplicateEmailsErrorsInDB = this.getDuplicateEmailsErrorInDB(resultData);
    // const unknownIncorrectErrors = this.getUnknownIncorrectErrors(resultData);
    const findDuplicateEmailsInCsv = this.getDuplicateEmailsInCsv(resultData);
    const getEmptyEmailsRecords = this.getEmptyEmailsRecords(resultData);
    return duplicateEmailsErrorsInDB
      // .concat(duplicateEmailsErrorsInDB)
      .concat(emptyKeysFor).concat(getEmptyEmailsRecords).concat(findDuplicateEmailsInCsv);
  }

  getFinalMatchingCsv = (finalCsv, keyNameOfEmailColumn) => {
    // eslint-disable-next-line max-len
    const existingEmail = this.props.projectStakeholders.map(email => email.email_address.trim().toLowerCase());
    // eslint-disable-next-line consistent-return
    return finalCsv.filter((csv) => {
      const csvEmail = csv[keyNameOfEmailColumn].trim().toLowerCase();
      if (existingEmail.includes(csvEmail)) {
        return csv;
      }
    });
  }

  updateShowPreview = (result) => {
    const resultData = [...result.data];
    this.setState({ disableSubmitButton: false });
    if (checkEmptyCSV(resultData)) {
      this.setState({ disableSubmitButton: true, errorMessage: ERRORS.emptyCSV }); return;
    }
    const keyNameOfEmailColumn = Object.keys(result.data[0])[0];
    this.setState({ keyNameOfEmailColumn });
    const allEmailsWithErrors = this.getWrongEntries(result.data);

    // eslint-disable-next-line max-len
    let finalCsv = resultData.filter((v, i, a) => a.findIndex(v2 => (v2[keyNameOfEmailColumn] === v[keyNameOfEmailColumn])) === i && v[keyNameOfEmailColumn].length);

    const getInvalidStackHolder = this.getInvalidStackHolder(finalCsv);
    finalCsv = this.getFinalMatchingCsv(finalCsv, keyNameOfEmailColumn);
    const allPwcUsers = this.getAllPwcUsers(result.data);
    this.setState({
      correctUsersForCsv: finalCsv,
      errorUsersCsv: allEmailsWithErrors.concat(getInvalidStackHolder),
      allPwcUsers,
      keyNameOfEmailColumn,
    });
  };

  defaultImportAndContinueBtn = () => {
    this.setState({
      showPreview: false,
      errorDetect: false,
      correctUsersForCsv: [],
      errorUsersCsv: [],
    });
  }

  onUploadNewCsv = () => {
    const activeState = this.renderFooterButtonText();
    if (activeState === BUTTON_TEXT.upload) {
      this.defaultImportAndContinueBtn();
    }
  }

  onImportAndContinue = () => {
    const { correctUsersForCsv, errorUsersCsv } = this.state;
    if (!correctUsersForCsv.length && errorUsersCsv.length) {
      this.setState({
        errorDetect: true,
      });
    } else if (correctUsersForCsv.length) {
      this.setState({
        showPreview: true,
      });
    }
  }

  onImportCsv = () => {
    const { correctUsersForCsv } = this.state;
    const keyNameOfColumn = Object.keys(correctUsersForCsv[0]);
    const csvData = this.validateAllEmail(correctUsersForCsv, keyNameOfColumn);
    this.props.uploadImportStackholderErrorRecords(csvData.invalidStackHolder);
    let csvHasErrors = false;
    if (csvData.invalidStackHolder.length) {
      csvHasErrors = true;
      this.setState({
        errorDetect: true,
      });
    }
    this.props.updateImportStackHolderFlag(true);
    this.onContinueButtonClick(csvData.validStackHolder, keyNameOfColumn, csvHasErrors);
  };

  validateAllEmail = (correctUsersForCsv) => {
    const validStackHolder = getCorrectEntries(correctUsersForCsv, this.props.projectStakeholders, this.state.keyNameOfEmailColumn, 'filter');
    // const allErrorRecords = [...this.state.errorUsersCsv, ...invalidStackHolder];
    this.setState({ errorUsersCsv: this.state.errorUsersCsv });
    return { validStackHolder, invalidStackHolder: this.state.errorUsersCsv };
  }

  capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  onContinueButtonClick(validStackHolder, keyNameOfColumn, csvHasErrors) {
    const {
      filterGroupName1,
      filterGroupName2,
      filterGroupName3,
      filterGroupName4,
    } = this.props.userFilterGroups;
    const data = {
      projectId: this.props.projectId,
      filtergroupname1: keyNameOfColumn[1] ? keyNameOfColumn[1] : filterGroupName1,
      filtergroupname2: keyNameOfColumn[2] ? keyNameOfColumn[2] : filterGroupName2,
      filtergroupname3: keyNameOfColumn[3] ? keyNameOfColumn[3] : filterGroupName3,
      filtergroupname4: keyNameOfColumn[4] ? keyNameOfColumn[4] : filterGroupName4,
      stakeHoldersGroupValue: {
        filtergroupvalue1: [],
        filtergroupvalue2: [],
        filtergroupvalue3: [],
        filtergroupvalue4: [],
      },
      csvHasErrors,
    };
    validStackHolder.map((item) => {
      data.stakeHoldersGroupValue.filtergroupvalue1.push({
        userId: item.id,
        value: item.filterGroupValue1
          ? this.capitalizeFirstLetter(item.filterGroupValue1) : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue2.push({
        userId: item.id,
        value: item.filterGroupValue2
          ? this.capitalizeFirstLetter(item.filterGroupValue2) : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue3.push({
        userId: item.id,
        value: item.filterGroupValue3
          ? this.capitalizeFirstLetter(item.filterGroupValue3) : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue4.push({
        userId: item.id,
        value: item.filterGroupValue4
          ? this.capitalizeFirstLetter(item.filterGroupValue4) : null,
      });
    });
    this.props.onImportCsvFormData(data);
  }

  onClickImport = () => {
    const activeState = this.renderFooterButtonText();
    if (activeState === BUTTON_TEXT.upload) {
      this.onUploadNewCsv();
      return;
    }
    if (activeState === BUTTON_TEXT.continue) {
      this.onImportAndContinue();
    } else {
      this.onImportCsv();
    }
  };

  renderHeading = () => {
    const { showPreview, errorDetect } = this.state;
    if (errorDetect) {
      return 'Users with errors';
    }
    if (!showPreview) {
      return 'Bulk assign filters via CSV';
    }
    return 'Preview your first user';
  };

  renderFooterButtonText = () => {
    const { showPreview, errorDetect } = this.state;
    if (errorDetect) {
      return BUTTON_TEXT.upload;
    }
    if (!showPreview) {
      return BUTTON_TEXT.continue;
    }
    return BUTTON_TEXT.import;
  };

  renderMainContent = () => {
    const {
      showPreview,
      correctUsersForCsv,
      errorDetect,
      errorUsersCsv,
      errorMessage,
      disableSubmitButton,
    } = this.state;
    const previewFilterUser = correctUsersForCsv[0] || {};

    if (errorDetect) {
      return (
        <div>
          <div>
            <div>
              <b>
                {errorUsersCsv.length} users could not be imported due to problems with your CSV
                file.
              </b>
            </div>
            <div>
              Review the errors in this exceptions list and amend your data before uploading.
            </div>
          </div>
          <div className="error-detect-footer">
            <ReactSVG wrapper="svg" width="16" height="16" src={downloadCsvIcon} />
            <CSVFilterDownloaderUI
              title="Download CSV file with errors"
              errorDetect={errorDetect}
              errorUsersCsv={errorUsersCsv}
            />
          </div>
        </div>
      );
    }

    if (!showPreview) {
      return (
        <div>
          <div>
            Download the{' '}
            <CSVFilterDownloaderUI
              title="Stakeholder Listing"
            />{' '}
            to add your filters.
          </div>
          <div className='note'>
            <b>Please note:</b> Importing filter tags using this template will <b>overwrite</b>
            &nbsp;any existing filter data that may already be configured for this topic.
            Please ensure your import template is correct and complete before you proceed.
          </div>
          <div class="a-p-20 d-table w-100 text-center">
            <div class="a-multi-upload-file" id="multi-upload1">
              <button aria-label="Press to select file" class="upload-drop multi" id="multi-file-target1" tabindex="0">
                <span class="drop-span">Drag and drop or</span>
                <span class="browse-span">&nbsp;choose your files <input aria-label="Hidden file upload input" type="file" /></span>
                <input aria-label="Hidden file upload input" style={{
 visibility: 'hidden', position: 'absolute', width: '1px', height: '1px',
}} type="file" />
              </button>
            </div>
          </div>
          <div className="microsoft-block">
            Note - Only csv file formats are supported.
          </div>
          {disableSubmitButton && <div className="microsoft-error">
            {errorMessage}
          </div>}
        </div>
      );
    }
    return (
      <div>
        <div>
          You will be importing{' '}
          <b>
            {correctUsersForCsv.length} user
            {correctUsersForCsv.length > 0 ? 's' : ''}.
          </b>
        </div>
        <div className="preview-list">
          {Object.keys(previewFilterUser).slice(0, ROWS_FOR_PREVIEW).map((key, index) => (
            <div className="preview-item" key={index}>
              <div className="key">{key}</div>
              <div className="value">{previewFilterUser[key]}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { isLoading, disableSubmitButton } = this.state;
    return (
      <div
        aria-hidden="true"
        className="modal a-modal fade user-csv-modal"
        id="userCsvModal"
        role="dialog"
        ref={this.modalWrapperRef}
        data-backdrop="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header ">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h4">{this.renderHeading()}</div>
              </div>
              <span className="icon-wrapper">
                <a className={this.state.isLoading ? 'disabled' : ''}>
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    data-bs-dismiss="modal"
                  />
                </a>
              </span>
            </div>
            <div className="a-modal-body pt-0 mt-1">
              {this.renderMainContent()}
            </div>
            <div className="a-modal-footer a-border-tp justify-content-end c-question-ask">
              <button
                disabled={isLoading}
                style={{ fontSize: '0.875rem' }}
                className="btn c-question-cancel a-btn a-btn-secondary a-btn-lg"
                onClick={() => this.cancelClick()}
              >
                CANCEL
              </button>
              <SpinnerButton
                isLoading={isLoading}
                disabled={isLoading}
                label={this.renderFooterButtonText()}
                onClick={this.onClickImport}
                disableSubmitButton={disableSubmitButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCSVFilterModel);
