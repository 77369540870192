import React, { Component } from 'react';
import './MultiColorProgressBar.scss';

class MultiColorProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentLoaded: false,
      userData: null,
      barData: null,
    };
  }

  componentDidMount(props) {
    this.initializeProgressBar(props);
  }

  initializeProgressBar() {
    const { total, readings } = this.props.progressBarData;
    const { showIcon } = this.props;
    /* eslint-disable */
    const maxCount = Math.max.apply(Math, readings.map(o => o.count));
    let maxCountArray = readings.filter(o => o.count === maxCount);
    const barData = total && total > 0 && readings.map((item, i) => {
      if (item.count > 0) {
        return (
          <div className={`bar ${item.count === maxCount && maxCountArray.length !== readings.length ? `${item.iconColor} bar-largest` : item.iconColor}`} style={{ width: `${(item.count * 100) / total}%` }} key={i}></div>
        );
      }
      return null;
    }, this);
    const userData = total && total > 0 && readings.map((item, i) => {
      if (item.count > 0) {
        return (
          <div className={`value ${item.count === maxCount && maxCountArray.length !== readings.length ? 'bar-largest' : ''}`} style={{ color: item.color, width: `${(item.count * 100) / total}%` }} key={i}>
            <div className={`data ${item.iconColor}`}>
              <span className="material-icons">
                {showIcon && item.icon}
              </span>
              <span class="thumb-title">{item.count}</span>
            </div>
          </div>
        );
      }
      return null;
    }, this);
    this.setState({
      userData, barData, componentLoaded: userData && barData,
    });
  }

  render() {
    const { componentLoaded, userData, barData } = this.state;
    const { readings } = this.props.progressBarData;
    return (
      <React.Fragment >
        {componentLoaded && (<div className="multicolor-bar">
          <div className={`values bar-${readings.length}`}>
            {userData}
          </div>
          <div className={`bars bar-${readings.length}`}>
            {barData}
          </div>
        </div>)}
      </React.Fragment>
    );
  }
}

export default MultiColorProgressBar;
