import { createSelector } from 'reselect';

const DomainSelector = state => state.domain;
const AddDomainSelector = state => state.addDomain;
const domainSelector = createSelector(
  [DomainSelector],
  domain => domain,
);
const addDomainSelector = createSelector(
  [AddDomainSelector],
  addDomain => addDomain,
);

export { domainSelector, addDomainSelector };
