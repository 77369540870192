import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import $ from 'jquery';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import UnsavedChangesConfirmation from '../../../ui-components/UnsavedChangesConfirmation/UnsavedChangesConfirmation.jsx';
import { SpinnerButton, Spinner } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import constant from '../actions/constant';
import {
  validateUserByEmail,
  createNewUser,
} from '../../account/actions/iam';
import {
  addProjectStakeholder,
  pauseOrResumeStakeholder,
  getProjectStakeholders,
  showRecordProcessing,
  hideRecordProcessing,
  projectAssociatedStakeholdersCount,
  saveFilterGroups,
  isShowingFilterSuccessMesage,
  getUserFilterGroupsForProject,
  updateProjectStakeholderState,
  resendQuesitonsEmailToStakeholder,
  openCloseQuestionUrlLinkAlert,
  updateProjectStackHoldersList,
  updateImportStackHolderFlag,
} from '../actions/project';
import { RegisterEmail } from '../../../ui-components/Account';
import { getPortfolio, getSelectedPortfolio } from '../../portfolio/selectors/portfolio';
import { hideProjectStakeholderRightPanel } from '../../portfolio/actions/portfolio';
import authService from '../../../redux/services/authService';
import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import {
  disableUnsavedChangesModal, disableUnsavedChanges,
  enableUnsavedChanges, enableUnsavedChangesModal,
} from '../../general/actions/general';
import chunkedPromises from '../../../utils/utils';
import { sortArrayValues } from '../../../utils/helper';
import FilterGroupModel from '../../../ui-components/FilterGroup/FilterGroupModel.jsx';
import filterIcon from '../../../../assets/images/component/filter-icon.svg';
import UserCsvModel from '../../../ui-components/UserCsv/UserCsvModel.jsx';
import {
  SORT_STAKEHOLDERS_ARRAY,
  // CSV_CONSTANTS,
} from '../../../Constants';
// eslint-disable-next-line max-len
// import ConfirmationDialog from '../../../ui-components/ConfirmationDialog/ConfirmationDialog.jsx';
import { showHideOverlay, updateUnsavedChanges } from '../../common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  systemTerminology: getSystemTerminologySelector(state),
  unsavedChangesModal: state.general.unsavedChangesModal,
  unsavedChanges: state.general.unsavedChanges,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
  roles: state.portfolio.portfolioData.roles,
  isSysAdmin: state.login.isSysAdmin,
  projectDetails: state.project?.projectDetails,
  portfolio: state?.portfolio,
  selectedPortfolio: getSelectedPortfolio(state),
  importStackholdersFlag: state.project.importStackholdersFlag,
  userFilterGroups: state.project.userFilterGroups,
});

const mapDispatchToProps = {
  validateUserByEmail,
  createNewUser,
  addProjectStakeholder,
  pauseOrResumeStakeholder,
  getProjectStakeholders,
  hideProjectStakeholderRightPanel,
  getSystemTerminology,
  disableUnsavedChangesModal,
  disableUnsavedChanges,
  enableUnsavedChanges,
  enableUnsavedChangesModal,
  showRecordProcessing,
  projectAssociatedStakeholdersCount,
  hideRecordProcessing,
  saveFilterGroups,
  isShowingFilterSuccessMesage,
  getUserFilterGroupsForProject,
  updateProjectStakeholderState,
  resendQuesitonsEmailToStakeholder,
  openCloseQuestionUrlLinkAlert,
  updateProjectStackHoldersList,
  updateImportStackHolderFlag,
  showHideOverlay,
  updateUnsavedChanges,
};

class ProjectStakeholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      loggedInUserId: 0,
      model: {
        id: 0,
        first_name: '',
        last_name: '',
        email_address: '',
      },
      stakeholders: [],
      filteredStakeholders: [],
      stakeholdersToBeAdded: [],
      stakeholdersToBeRemoved: [],
      stakeholdersToBePause: [],
      stakeholdersToBeResume: [],
      isActive: true,
      isLoading: true,
      showUnsavedChangesModal: false,
      isSubmitting: false,
      showRegisterProjectStakeholdersModel: false,
      showUserCsvModel: false,
      showRegisterProjectStakeholdersStep2Model: false,
      selectedStakeholdersIdForDelete: 0,
      selectedStakeholdersEmailForDelete: '',
      showStakeholdersDelete: false,
      deleteStakeholdersTitle: 'Remove #projectReplaceText# Stakeholders',
      deleteStakeholdersConfirmationMessage: `Are you sure you want to remove this user 
            from #projectReplaceText# Stakeholders?`,
      totalEmails: 0,
      showFilterModel: false,
      groupsDataModel: {},
      stakeholdersSearchValueEmpty: true,
      stakeholderWithFilterGroup: [],
      csvColumns: {
        column1: '',
        column2: '',
        column3: '',
        column4: '',
      },
      displaySaveConfirmationPopup: false,
      selectedStakeholdersToPauseOrResume: null,
      showStakeholdersPauseOrResume: false,
    };

    this.confirmStakeholdersDelete = this.confirmStakeholdersDelete.bind(this);
    this.cancelStakeholdersDelete = this.cancelStakeholdersDelete.bind(this);
    this.removeProjectStakeholder = this.removeProjectStakeholder.bind(this);
    this.validateAndSaveProjectStakeholders = this.validateAndSaveProjectStakeholders.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.hideProjectStakeholderRightPanel = this.hideProjectStakeholderRightPanel.bind(this);
    this.searchStakeholder = this.searchStakeholder.bind(this);
    this.resendQuestionsEmail = this.resendQuestionsEmail.bind(this);
    this.clearSearchbar = this.clearSearchbar.bind(this);
    this.copyQuestionUrlLink = this.copyQuestionUrlLink.bind(this);
    this.cancelStakeholdersSave = this.cancelStakeholdersSave.bind(this);
    this.pauseOrResumeStackholder = this.pauseOrResumeStackholder.bind(this);
    this.cancelStakeholdersPauseOrResume = this.cancelStakeholdersPauseOrResume.bind(this);
    this.confirmStakeholdersPauseOrResume = this.confirmStakeholdersPauseOrResume.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.props.getSystemTerminology();
    const { selectedProjectId } = this.props.portfolioData;
    getProjectStakeholders({ id: selectedProjectId }).then(async (res) => {
      const stakeholders = Array.isArray(res.stakeholders) ? res.stakeholders
        .map(val => ({ ...val, is_paused_copy: val.is_paused })) : [];
      await this.props.updateProjectStackHoldersList(stakeholders);
      this.setState({
        stakeholders,
        stakeholderWithFilterGroup: Array.isArray(res.filterGroupValues.filterData)
          ? res.filterGroupValues.filterData : [],
        isLoading: false,
        filteredStakeholders: Array.isArray(res.stakeholders) ? sortArrayValues(
          res.stakeholders
            .map(val => ({ ...val, is_paused_copy: val.is_paused })), SORT_STAKEHOLDERS_ARRAY,
        ) : [],
      });
    });
    const userInfo = authService.getUserInfo();
    this.setState({ loggedInUserId: userInfo.user_id });
  }

  toggleUnsavedChanges() {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChanges();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unsavedChanges && nextProps.unsavedChangesModal && !this.state.isSubmitting) {
      this.setState({ showUnsavedChangesModal: true });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  retainUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.setState({ showUnsavedChangesModal: false });
  }

  cancelUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.setState({ showUnsavedChangesModal: false });
    this.props.hideProjectStakeholderRightPanel();
  }

  hideProjectStakeholderRightPanel() {
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      this.props.hideProjectStakeholderRightPanel();
      this.props.showHideOverlay(false);
    }
  }


  openRegisterProjectStakeholdersModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterProjectStakeholdersModel: true });
  }

  openFilterGroupModel = () => {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showFilterModel: true });
  }

  clearSearchbar() {
    this.setState({ stakeholdersSearchValueEmpty: true });
    this.searchStakeholder('');
    $('#searchProjectStakeholders').val('');
  }

  async searchStakeholder(value) {
    const stakeholderData = this.state.stakeholders;
    const filteredData = stakeholderData.filter(
      s => s.email_address
        && (s.email_address.toLowerCase().includes(value.toLowerCase())
          || `${s.first_name.toLowerCase()} ${s.last_name.toLowerCase()}`.includes(value.toLowerCase())
          || `${s.first_name.toLowerCase()}${s.last_name.toLowerCase()}`.includes(value.toLowerCase())
        ),
    );
    this.setState({
      filteredStakeholders: filteredData,
      stakeholdersSearchValueEmpty: false,
    });
    if (value === '') {
      this.setState({
        filteredStakeholders: this.state.stakeholders,
        stakeholdersSearchValueEmpty: true,
      });
    }
  }

  showSpinner(value) {
    this.setState({ isSubmitting: value });
  }

  openFilterGroupModelImmediately() {
    const { stakeholderWithFilterGroup } = this.state;
    const checkAssignFilters = stakeholderWithFilterGroup?.some((item) => {
      const isFilterExists = item.filtergroupvalue1?.length > 0
        || item.filtergroupvalue2?.length > 0
        || item.filtergroupvalue3?.length > 0
        || item.filtergroupvalue4?.length > 0;
      return isFilterExists;
    });
    this.setState({ showFilterModel: !!checkAssignFilters });
  }

  async registerProjectStakeholders(emails) {
    const formattedEmails = emails.map(email => email.toLowerCase());
    this.setState({ totalEmails: formattedEmails });
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewStakeholderToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          this.openFilterGroupModelImmediately();
          return result;
        });
      });
    const response = [].concat(...r);
    return response;
  }

  async onImportCsv(emails, filterUsers) {
    const formattedEmails = emails.map(email => email.toLowerCase());
    this.setState({ totalEmails: formattedEmails });
    const userEmailResponse = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewStakeholderToList(item, filterUsers);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...userEmailResponse);
    if (response.length === 0) {
      this.props.showRecordProcessing(-this.props.passedRecords, 0);
    }
    return response;
  }

  async saveStakeHoldersFilterGroups(groupsData) {
    this.toggleUnsavedChanges();
    const { stakeholderWithFilterGroup } = this.state;
    stakeholderWithFilterGroup.map((item, index) => {
      const groupValue1 = groupsData.stakeHoldersGroupValue.filtergroupvalue1
        .find(user => user.userId === item.id);
      stakeholderWithFilterGroup[index].filtergroupname1 = groupsData.filtergroupname1;
      if (groupValue1) {
        stakeholderWithFilterGroup[index].filtergroupvalue1 = groupValue1.value;
      }
      const groupValue2 = groupsData.stakeHoldersGroupValue.filtergroupvalue2
        .find(user => user.userId === item.id);
      stakeholderWithFilterGroup[index].filtergroupname2 = groupsData.filtergroupname2;
      if (groupValue2) {
        stakeholderWithFilterGroup[index].filtergroupvalue2 = groupValue2.value;
      }
      const groupValue3 = groupsData.stakeHoldersGroupValue.filtergroupvalue3
        .find(user => user.userId === item.id);
      stakeholderWithFilterGroup[index].filtergroupname3 = groupsData.filtergroupname3;
      if (groupValue3) {
        stakeholderWithFilterGroup[index].filtergroupvalue3 = groupValue3.value;
      }
      const groupValue4 = groupsData.stakeHoldersGroupValue.filtergroupvalue4
        .find(user => user.userId === item.id);
      stakeholderWithFilterGroup[index].filtergroupname4 = groupsData.filtergroupname4;
      if (groupValue4) {
        stakeholderWithFilterGroup[index].filtergroupvalue4 = groupValue4.value;
      }
    });
    this.setState({ groupsDataModel: groupsData });
    this.props.showRecordProcessing(0, 0);
    this.props.isShowingFilterSuccessMesage(true);
    setTimeout(() => {
      this.props.isShowingFilterSuccessMesage(false);
    }, 10000);
  }

  pushNewStakeholderToList(userData, filterUsers = []) {
    this.toggleUnsavedChanges();
    let {
      stakeholderWithFilterGroup,
    } = this.state;
    const {
      stakeholders, stakeholdersToBeAdded, stakeholdersToBeRemoved,
    } = this.state;
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    let user = {};
    if (filterUsers.length) {
      const keyNameOfEmailColumn = Object.keys(filterUsers[0])[0];
      const userFound = filterUsers.find(item => item[keyNameOfEmailColumn] === emailId);
      const singleUser = filterUsers[0];
      const singleUserKeys = Object.keys(singleUser);
      user = {
        id,
        first_name: FirstName,
        last_name: LastName,
        email_address: emailId,
        is_user_registered: isRegistered,
        engagementColor: 'a-badge-secondary',
        engagementText: 'Pending',
        filtergroupname1: singleUserKeys[1] ? singleUserKeys[1] : null,
        filtergroupname2: singleUserKeys[2] ? singleUserKeys[2] : null,
        filtergroupname3: singleUserKeys[3] ? singleUserKeys[3] : null,
        filtergroupname4: singleUserKeys[4] ? singleUserKeys[4] : null,
        filtergroupvalue1: userFound && singleUserKeys[1] ? userFound[singleUserKeys[1]] : null,
        filtergroupvalue2: userFound && singleUserKeys[2] ? userFound[singleUserKeys[2]] : null,
        filtergroupvalue3: userFound && singleUserKeys[3] ? userFound[singleUserKeys[3]] : null,
        filtergroupvalue4: userFound && singleUserKeys[4] ? userFound[singleUserKeys[4]] : null,
      };
      this.setState({
        csvColumns: {
          column1: singleUserKeys[1] ? singleUserKeys[1] : null,
          column2: singleUserKeys[2] ? singleUserKeys[2] : null,
          column3: singleUserKeys[3] ? singleUserKeys[3] : null,
          column4: singleUserKeys[4] ? singleUserKeys[4] : null,
        },
      });
    } else {
      user = {
        id,
        first_name: FirstName,
        last_name: LastName,
        email_address: emailId,
        is_user_registered: isRegistered,
        engagementColor: 'a-badge-secondary',
        engagementText: 'Pending',
        filtergroupname1: null,
        filtergroupname2: null,
        filtergroupname3: null,
        filtergroupname4: null,
        filtergroupvalue1: null,
        filtergroupvalue2: null,
        filtergroupvalue3: null,
        filtergroupvalue4: null,
      };
    }
    stakeholders.push(user);
    stakeholderWithFilterGroup = [user, ...stakeholderWithFilterGroup];
    stakeholdersToBeAdded.push(user);
    const updateStakeholdersToBeRemoved = stakeholdersToBeRemoved
      .filter(stakeholder => stakeholder !== id);
    this.setState({
      stakeholders,
      filteredStakeholders: stakeholders,
      stakeholdersToBeAdded,
      stakeholdersToBeRemoved: updateStakeholdersToBeRemoved,
      stakeholderWithFilterGroup,
    });
  }

  stakeholdersCount(stakeholdersToBeAdded, stakeholdersToBeRemoved) {
    if (stakeholdersToBeAdded.length > 0) {
      this.setState({ totalEmails: stakeholdersToBeAdded.length - stakeholdersToBeRemoved.length });
    } else {
      this.setState({ totalEmails: stakeholdersToBeAdded.length });
    }
  }

  async validateAndSaveProjectStakeholders() {
    const { stakeholdersToBeAdded, stakeholdersToBeRemoved } = this.state;
    this.stakeholdersCount(stakeholdersToBeAdded, stakeholdersToBeRemoved);
    this.setState({ isSubmitted: true });
    this.props.disableUnsavedChanges();
    this.props.disableUnsavedChangesModal();
    this.showSpinner(true);
    this.props.showRecordProcessing(0, 0);
    // pause or resume questions
    const { stakeholdersToBePause, stakeholdersToBeResume } = this.state;
    if (stakeholdersToBePause.length > 0 || stakeholdersToBeResume.length > 0) {
      this.props.pauseOrResumeStakeholder({
        projectId: this.props.portfolioData.selectedProjectId,
        stakeholdersToBePaused: stakeholdersToBePause,
        stakeholdersToBeResume,
      }).then((res) => {
        console.log(res);
      });
    }
    if (stakeholdersToBeAdded.length > 0) {
      await chunkedPromises(stakeholdersToBeAdded, 10,
        async (c) => {
          const model = {
            stakeholdersToBeAdded: c,
            stakeholdersToBeRemoved,
            projectId: this.props.portfolioData.selectedProjectId,
            groupsDataModel: this.state.groupsDataModel,
          };
          await this.props.addProjectStakeholder(model).then((res) => {
            this.props.showRecordProcessing(c.length, res);
          });
          return c;
        }).then(() => {
          this.props.hideRecordProcessing();
          this.setState({ isSubmitted: false });
          this.showSpinner(false);
          this.props.hideProjectStakeholderRightPanel();
          this.props.updateProjectStakeholderState(true);
        });
      getProjectStakeholders({ id: this.props.portfolioData.selectedProjectId }).then((res) => {
        this.props.projectAssociatedStakeholdersCount(res.stakeholders.length);
      });
    } else {
      const model = {
        stakeholdersToBeAdded,
        stakeholdersToBeRemoved,
        projectId: this.props.portfolioData.selectedProjectId,
        groupsDataModel: this.state.groupsDataModel,
      };
      await this.props.addProjectStakeholder(model);
      getProjectStakeholders({ id: this.props.portfolioData.selectedProjectId }).then((res) => {
        this.props.projectAssociatedStakeholdersCount(res.stakeholders.length);
        this.props.updateProjectStakeholderState(true);
      });
      this.props.hideRecordProcessing();
      this.showSpinner(false);
      this.props.hideProjectStakeholderRightPanel();
    }
  }

  registerProjectStakeholdersStep2(formData) {
    const userModel = {
      email: this.state.RegisteringUserEmail,
      firstName: formData.firstName,
      lastName: formData.lastName,
      projectId: this.state.id,
      organisation: 'external', // This was required field So adding data as mentioned in wiki
      territory: 'AU', // This was required field So adding data as mentioned in wiki
      assuranceLevel: '', // This was required field So adding data as mentioned in wiki
    };


    return this.props.createNewUser(userModel).then((response) => {
      const { status, userData } = response.data;
      if (status === constant.Success || status === constant.UserAlreadyOnboarded) {
        this.pushNewStakeholderToList(userData);
      }
      return response;
    });
  }

  // Confirm Delete
  confirmStakeholdersDelete(userEmail) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      selectedStakeholdersEmailForDelete: userEmail,
      showStakeholdersDelete: true,
    });
  }

  // Confirm Delete
  confirmStakeholdersPauseOrResume(userId, isAlreadyPaused) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({
      selectedStakeholdersToPauseOrResume: { userId, isAlreadyPaused },
      showStakeholdersPauseOrResume: true,
    });
  }

  resendQuestionsEmail(id, firstName, lastName, email) {
    if (this.props.showProcessingRecords) {
      return;
    }
    const data = {
      StakeholderUserId: id,
      projectId: this.props.portfolioData.selectedProjectId,
      stakeholdersToBeRemoved: [],
      stakeholdersToBeAdded: [],
      stakeHolderFirstName: firstName,
      stakeHolderLastName: lastName,
      stakeHolderEmail: email,
    };
    this.props
      .resendQuesitonsEmailToStakeholder(data);
  }

  cancelStakeholdersDelete() {
    this.setState({
      selectedStakeholdersEmailForDelete: '',
      showStakeholdersDelete: false,
    });
  }

  cancelStakeholdersPauseOrResume() {
    this.setState({
      selectedStakeholdersToPauseOrResume: null,
      showStakeholdersPauseOrResume: false,
    });
  }

  cancelStakeholdersSave() {
    this.setState({
      displaySaveConfirmationPopup: false,
    });
    this.props.updateImportStackHolderFlag(false);
  }

  // Delete Project Life Cycle Stages
  removeProjectStakeholder() {
    this.toggleUnsavedChanges();
    const {
      stakeholders, stakeholdersToBeRemoved, stakeholdersToBeAdded,
      selectedStakeholdersEmailForDelete,
      filteredStakeholders,
      stakeholdersToBePause,
      stakeholdersToBeResume,
    } = this.state;
    const clonedPausedArray = [...stakeholdersToBePause];
    const clonedResumeArray = [...stakeholdersToBeResume];
    let updateStakeholdersToBeAdded = stakeholdersToBeAdded;
    let index = -1;
    this.state.stakeholders.map((item, idx) => {
      if (item.email_address === selectedStakeholdersEmailForDelete) { index = idx; }
      return item;
    });
    const removedFilterStakeholder = filteredStakeholders
      .filter(item => item.email_address !== selectedStakeholdersEmailForDelete);
    if (index >= 0) {
      const [removedStakeholder] = stakeholders.splice(index, 1);
      updateStakeholdersToBeAdded = stakeholdersToBeAdded
        .filter(stakeholder => stakeholder.email_address !== selectedStakeholdersEmailForDelete);
      if (updateStakeholdersToBeAdded.length === stakeholdersToBeAdded.length) {
        stakeholdersToBeRemoved.push(removedStakeholder.id);
      }
      const foundInPaused = clonedPausedArray.findIndex(val => val === removedStakeholder.id);
      if (foundInPaused !== -1) {
        clonedPausedArray.splice(foundInPaused, 1);
      }
      const foundInresume = clonedResumeArray.findIndex(val => val === removedStakeholder.id);
      if (foundInresume !== -1) {
        clonedResumeArray.splice(foundInresume, 1);
      }
    }
    this.setState({
      stakeholders,
      filteredStakeholders: removedFilterStakeholder,
      stakeholdersToBeRemoved,
      stakeholdersToBeAdded: updateStakeholdersToBeAdded,
      stakeholdersToBePause: clonedPausedArray,
      stakeholdersToBeResume: clonedResumeArray,
    });
    return new Promise(((resolve) => {
      resolve(true);
    }));
  }

  pauseOrResumeStackholder() {
    this.toggleUnsavedChanges();
    const {
      stakeholdersToBePause,
      stakeholdersToBeResume,
      selectedStakeholdersToPauseOrResume,
      stakeholders,
      filteredStakeholders,
    } = this.state;
    const { userId, isAlreadyPaused } = selectedStakeholdersToPauseOrResume;
    const clonedStackholderToPause = [...stakeholdersToBePause];
    const clonedStackholderToResume = [...stakeholdersToBeResume];
    const stackholdersData = [...stakeholders];
    const filteredData = [...filteredStakeholders];

    if (isAlreadyPaused) {
      // need to resume
      const stackholderToBeResumed = stackholdersData.find(val => val.id === userId);
      stackholderToBeResumed.is_paused = false;
      const filterStackholderToBeResumed = filteredData.find(val => val.id === userId);
      filterStackholderToBeResumed.is_paused = false;
      // check if exists in pause array
      const foundIndex = clonedStackholderToPause.findIndex(val => val === userId);
      if (foundIndex !== -1) {
        clonedStackholderToPause.splice(foundIndex, 1);
      }
      if (filterStackholderToBeResumed.is_paused_copy) {
        clonedStackholderToResume.push(userId);
      }
    } else {
      // need to pause
      const stackholderToBeResumed = stackholdersData.find(val => val.id === userId);
      stackholderToBeResumed.is_paused = true;
      const filterStackholderToBeResumed = filteredData.find(val => val.id === userId);
      filterStackholderToBeResumed.is_paused = true;
      // check if exists in resume array
      const foundIndex = clonedStackholderToResume.findIndex(val => val === userId);
      if (foundIndex !== -1) {
        clonedStackholderToResume.splice(foundIndex, 1);
      }
      if (!filterStackholderToBeResumed.is_paused_copy) {
        clonedStackholderToPause.push(userId);
      }
    }
    this.setState({
      stakeholdersToBePause: clonedStackholderToPause,
      stakeholdersToBeResume: clonedStackholderToResume,
      stakeholders: stackholdersData,
      showStakeholdersPauseOrResume: false,
      filteredStakeholders: filteredData,
      selectedStakeholdersToPauseOrResume: null,
    });
    return new Promise(((resolve) => {
      resolve(true);
    }));
  }

  copyQuestionUrlLink(item) {
    const stakeholderData = {
      stakeHolderFirstName: item.first_name,
      stakeHolderLastName: item.last_name,
      stakeHolderEmail: item.email_address,
    };
    navigator.clipboard.writeText(item.projectUrl);
    this.props.openCloseQuestionUrlLinkAlert(stakeholderData);
  }

  updateGroupModel = () => {
    const { selectedProjectId } = this.props.portfolioData;
    const { csvColumns, stakeholderWithFilterGroup } = this.state;
    const {
      column1, column2, column3, column4,
    } = csvColumns;
    const {
      filterGroupName1,
      filterGroupName2,
      filterGroupName3,
      filterGroupName4,
    } = this.props.userFilterGroups;

    const groupsDataModel = {
      filtergroupname1: column1 || filterGroupName1,
      filtergroupname2: column2 || filterGroupName2,
      filtergroupname3: column3 || filterGroupName3,
      filtergroupname4: column4 || filterGroupName4,
      projectId: selectedProjectId,
      stakeHoldersGroupValue: {
        filtergroupvalue1: [],
        filtergroupvalue2: [],
        filtergroupvalue3: [],
        filtergroupvalue4: [],
      },
    };

    stakeholderWithFilterGroup.map((item) => {
      groupsDataModel.stakeHoldersGroupValue.filtergroupvalue1.push({
        userId: item.id,
        value: item.filtergroupvalue1
          ? item.filtergroupvalue1.trim() : null,
      });
      groupsDataModel.stakeHoldersGroupValue.filtergroupvalue2.push({
        userId: item.id,
        value: item.filtergroupvalue2
          ? item.filtergroupvalue2.trim() : null,
      });
      groupsDataModel.stakeHoldersGroupValue.filtergroupvalue3.push({
        userId: item.id,
        value: item.filtergroupvalue3
          ? item.filtergroupvalue3.trim() : null,
      });
      groupsDataModel.stakeHoldersGroupValue.filtergroupvalue4.push({
        userId: item.id,
        value: item.filtergroupvalue4
          ? item.filtergroupvalue4.trim() : null,
      });
    });

    this.setState({
      groupsDataModel,
    });
  }

  onSaveStackHolderClick = () => {
    this.props.updateImportStackHolderFlag(false);
    this.validateAndSaveProjectStakeholders();
    this.props.showHideOverlay(false);
  }

  render() {
    const {
      stakeholders,
      stakeholderWithFilterGroup,
      showRegisterProjectStakeholdersModel,
      showUserCsvModel,
      showStakeholdersDelete,
      showStakeholdersPauseOrResume,
      deleteStakeholdersConfirmationMessage,
      deleteStakeholdersTitle,
      isLoading,
      isSubmitting,
      showUnsavedChangesModal,
      isSubmitted,
      totalEmails,
      showFilterModel,
      stakeholdersSearchValueEmpty,
      filteredStakeholders,
      csvColumns,
      selectedStakeholdersToPauseOrResume,
      // displaySaveConfirmationPopup, //Might be removed in future
    } = this.state;

    const {
      systemTerminology, selectedProject, isProcessingRecords, passedRecords, failedRecords,
      roles, isSysAdmin,
    } = this.props;
    const isAuthoriseToView = !!(roles.projectAdministrator
      || roles.portfolioAdministrator || isSysAdmin);
    const projectReplace = levelTwoOrganizations.find(o => o.value === systemTerminology.level_two);
    const projectReplaceText = projectReplace && projectReplace.text;

    const newDeleteStakeholdersTitle = deleteStakeholdersTitle.replace(/#projectReplaceText#/g, projectReplaceText);
    const newDeleteStakeholdersConfirmationMessage = deleteStakeholdersConfirmationMessage.replace(/#projectReplaceText#/g, projectReplaceText);
    const { title, message, placeholder } = constant.registerEmailStakeholderModel;
    const replaceWithPrefix = text => text.replace(/#projectReplaceTextWithPreFix#/g,
      ('aeiou'.indexOf(projectReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a ')
      + projectReplaceText);
    let newStakeHolderTitle = title.replace(/#projectReplaceText#/g, projectReplaceText);
    newStakeHolderTitle = replaceWithPrefix(newStakeHolderTitle);
    let newStakeHolderMessage = message.replace(/#projectReplaceText#/g, projectReplaceText);
    newStakeHolderMessage = replaceWithPrefix(newStakeHolderMessage);
    newStakeHolderMessage = newStakeHolderMessage.replace(/#projectNameReplaceText#/g, selectedProject.ProjectTitle);
    newStakeHolderMessage = `${newStakeHolderMessage[0].toUpperCase()} ${newStakeHolderMessage.slice(1)}`;

    return (
      <React.Fragment >
        <RenderIf showComponent={showUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelUnsavedChanges.bind(this)}
            cancelClick={this.retainUnsavedChanges.bind(this)}
          />
        </RenderIf>
        {showStakeholdersDelete && (
          <div>
            <DeleteConfirmation
              title={newDeleteStakeholdersTitle}
              message={newDeleteStakeholdersConfirmationMessage}
              hideActions={false}
              confirmationClick={this.removeProjectStakeholder.bind(this)}
              cancelClick={this.cancelStakeholdersDelete.bind(this)}
            />
          </div>
        )}
        {showStakeholdersPauseOrResume && (
          <div>
            <DeleteConfirmation
              title={`${selectedStakeholdersToPauseOrResume.isAlreadyPaused ? 'Resume' : 'Pause'} Questions for Stackholder`}
              message={`Are you sure you want to ${selectedStakeholdersToPauseOrResume.isAlreadyPaused ? 'resume' : 'pause'} questions for this user?`}
              hideActions={false}
              deleteButtomLabel="Confirm"
              confirmationClick={this.pauseOrResumeStackholder.bind(this)}
              cancelClick={this.cancelStakeholdersPauseOrResume.bind(this)}
            />
          </div>
        )}


        <RenderIf showComponent={showRegisterProjectStakeholdersModel}>
          <RegisterEmail
            title={newStakeHolderTitle}
            message={newStakeHolderMessage}
            placeholder={placeholder}
            availableEmails={stakeholders.map(item => item.email_address)}
            onContinueButtonClick={this.registerProjectStakeholders.bind(this)}
            cancelClick={() => this.setState({
              showRegisterProjectStakeholdersModel: false,
            })}
            isRestrictedUser={this.props.selectedPortfolio.isRestrictedUser}
            isStakeholders={true}
            isTestEmail={false}
          />
        </RenderIf>

        <RenderIf showComponent={showFilterModel}>
          <FilterGroupModel
            title={newStakeHolderTitle}
            message={newStakeHolderMessage}
            placeholder={placeholder}
            stakeholders={stakeholderWithFilterGroup}
            onContinueButtonClick={this.saveStakeHoldersFilterGroups.bind(this)}
            cancelClick={() => this.setState({
              showFilterModel: false,
            })}
            csvColumns={csvColumns}
          />
        </RenderIf>

        <div className="right-sidebar add-stakeholder-sidebar" ref={this.setWrapperRef}>
          <Spinner isLoading={isLoading} />
          <RenderIf showComponent={!isLoading}>
            <div className="r-s-header">
              <div className="r-s-title">
                <span> {projectReplaceText} Stakeholders<i class="appkiticon icon-close-outline"
                  onClick={() => this.hideProjectStakeholderRightPanel()}></i></span>
              </div>
            </div>
            <div className="r-s-body mt-3">
              <p class="a-font-md m-0" style={{ textAlign: 'justify' }}>
                The users listed below will receive requests for feedback on this topic.
                <br />
                Users who are yet to activate their account in Perspectives will be displayed
                with their email address rather than First and Last Name.
                <br />
                Users without activated
                accounts are still able to share feedback using the web questionnaire, however they
                will not have access to other features such as personal results dashboards as these
                are contained only in the mobile app.
              </p>
              <div className="cust-stake-buttons d-flex justify-content-between">
                {isAuthoriseToView && !roles.projectViewer && <a href="javascript:void(0)" onClick={this.openRegisterProjectStakeholdersModel.bind(this)}
                  class="add-text-button mt-3 a-btn a-btn-gray">
                  <i class="appkiticon icon-plus-fill mr-2"> </i><span style={{ fontSize: 14 }}> Add a Topic Stakeholder</span></a>}
                {stakeholderWithFilterGroup.length > 0 && isAuthoriseToView && !roles.projectViewer
                  && <a href="javascript:void(0)" onClick={() => this.openFilterGroupModel()
                }
                    class={'add-text-button mt-3 a-btn a-btn-secondary'}>
                    <img style={{ width: 16 }} class="img-fluid" src={filterIcon} /><span style={{ fontSize: 14 }} className='assign-filters'>Assign Filters</span>
                  </a>}
                {
                  isAuthoriseToView && !roles.projectViewer && <div className="cursor-pointer import-csv-icon mt-3">
                    <MaterialIcon className="material-icons cursor-pointer" icon="more_horiz" />
                    <div class="a-shadow-sm border-0 import-csv-drop-down">
                      <ul class="user-infor-list">
                        <li class="user-infor-list-item"
                          onClick={() => this.setState({ showUserCsvModel: true })}>
                          Import users from CSV
                        </li>
                      </ul>
                    </div>
                  </div>
                }
              </div>
              <div className="text-left a-bg-gray d-flex justify-content-between">
                <div className="d-inline-block text-icon">
                  <i style={{ marginTop: '9px', marginLeft: '5px', paddingRight: '5px' }} className="appkiticon icon-search-outline" />
                  {!stakeholdersSearchValueEmpty && <MaterialIcon onClick={this.clearSearchbar} className="material-icons cursor-pointer close-icon-search" icon="close" />}
                  <input
                    style={{ width: '353px', marginTop: '15px', paddingLeft: '35px' }}
                    // disabled={showProcessingRecords}
                    id="searchProjectStakeholders"
                    className="a-text-input"
                    onChange={e => this.searchStakeholder(e.target.value)}
                    placeholder='Search...'
                    ref="searchBar"
                  />{' '}
                </div>
              </div>
              <div class="mt-3 mb-4">
                <ul style={{ overflow: 'visible' }} class="a-list cross-list project-stakeholder overflow">
                  {filteredStakeholders.map((item, index) => (
                    <li className="a-list-item d-flex align-content-between" key={index}>
                      {item.is_user_registered && <div className={`a-font-md font-weight-medium stakeholder-name ${item.is_paused ? 'stackholder-paused' : ''}`}>
                        {item.first_name && item.last_name
                          ? (<> {item.first_name} {item.last_name}</>)
                          : (<>{item.email_address}</>)}</div>}
                      {!item.is_user_registered && <div className={`a-font-md font-weight-medium stakeholder-name ${item.is_paused ? 'stackholder-paused' : ''}`}>{item.email_address}</div>}
                      {isAuthoriseToView && !roles.projectViewer && <div style={{ marginLeft: '140px' }} className="dropdown-icon-stakeholder">
                        <div class="dropstart footer-dropdowns cursor-pointer more-horiz-icon">
                          <MaterialIcon className="material-icons cursor-pointer" data-bs-toggle="dropdown" icon="more_horiz" />
                          <div class="dropdown-menu a-shadow-sm border-0 a-user-setting-panel-one dropdown-menu-stakeholder">
                            <ul class="user-infor-list">
                              <li class="user-infor-list-item" onClick={() => this.confirmStakeholdersDelete(item.email_address)}>
                                <a href="javascript:void(0);">Remove</a>
                              </li>
                              {item.projectUrl && <li class="user-infor-list-item" onClick={() => this.confirmStakeholdersPauseOrResume(item.id, item.is_paused)}>
                                <a href="javascript:void(0);">{item.is_paused ? 'Resume' : 'Pause'} Questions</a>
                              </li>}
                              {(this.props?.projectDetails?.isActive
                                && this.props?.selectedPortfolio?.protfolioActive
                                && !this.props?.projectDetails?.isDrafted
                                && !(item.engagementText))
                                && !item.is_paused
                                && <li class="user-infor-list-item" onClick={() => this.resendQuestionsEmail(item.id, item.first_name, item.last_name, item.email_address)}>
                                  <a href="javascript:void(0)">Resend questions</a>
                                </li>}
                              {(this.props?.projectDetails?.isActive
                                && this.props?.selectedPortfolio?.protfolioActive
                                && !this.props?.projectDetails?.isDrafted
                                && !(item.engagementText))
                                && <li class="user-infor-list-item" onClick={() => this.copyQuestionUrlLink(item)}>
                                  <a href="javascript:void(0)">Copy question link</a>
                                </li>}
                            </ul>
                          </div>
                        </div>
                      </div>}
                    </li>
                  ))}
                </ul>
              </div>

            </div>
            <div className="r-s-actions mt-4">
              <div className="mt-3 mb-4">
                {isProcessingRecords && isSubmitted && (
                  <span className='preview-pane-font' style={{
                    position: 'relative',
                    float: 'right',
                    fontSize: 10,
                    top: 10,
                    color: failedRecords > 0 ? 'red' : 'rgba(0, 0, 0, 0.6)',
                  }}>{passedRecords} of {totalEmails}
                    {' '}users assigned successfully</span>)}
                <div onClick={() => this.onSaveStackHolderClick()}>
                  {isAuthoriseToView && !roles.projectViewer && <SpinnerButton
                    isLoading={isSubmitting}
                    label={'SAVE CHANGES'}
                  />}
                </div>
              </div>
            </div>
            {/* for domains general page ends */}
          </RenderIf>
          <RenderIf showComponent={showUserCsvModel}>
            <UserCsvModel
              title={newStakeHolderTitle}
              message={newStakeHolderMessage}
              placeholder={placeholder}
              onContinueButtonClick={this.onImportCsv.bind(this)}
              cancelClick={() => this.setState({
                showUserCsvModel: false,
              })}
              availableEmails={stakeholders.map(item => item.email_address)}
              updateGroupModel={this.updateGroupModel}
            />
          </RenderIf>
        </div>
      </React.Fragment>

    );
  }
}
ProjectStakeholders.propTypes = {

};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectStakeholders);
