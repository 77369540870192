const NO_OF_RECORDS = 10;
const NO_OF_MINIMUM_ADMIN = 2;
const INITIAL_PAGINATED_DATA = {
  noOfRecords: NO_OF_RECORDS,
  pageNo: 0,
};
const YESNO_QUESTION = 1;
const OPENTYPE_QUESTION = 2;
// const RAG_MID_SCORE_INCREMENT_DECREMENT_VALUE = 1;
const IS_GROUP_1_CLEAR = 1;
const IS_GROUP_2_CLEAR = 2;
const IS_GROUP_3_CLEAR = 3;
const IS_GROUP_4_CLEAR = 4;
const IS_CHANGE_1_TEXT = 1;
const IS_CHANGE_2_TEXT = 2;
const IS_CHANGE_3_TEXT = 3;
const IS_CHANGE_4_TEXT = 4;
const SORT_PORTFOLIOS_ARRAY = 'portfolios';
const SORT_DOMAINS_ARRAY = 'domainsArray';
const SORT_SEGMENT_ARRAY = 'segments';
const SORT_STAKEHOLDERS_ARRAY = 'stakeholders';
const INITIAL_HTML_RENDER = {
  icon: 'icon',
  progressBarSection: 'progressBar',
  commentSection: 'commentSection',
  openTextQuestionSection: 'openTextQuestion',
  yesNoSection: 'yesNoSection',
  openTextSection: 'openTextSection',
};
const DEFAULT_SAMPLE_CSV = [
  {
    Email: 'example.xyz@abc.com',
    'Label Filter 1': 'Sydney',
    'Label Filter 2': 'Perspectives',
    'Label Filter 3': 'Project Manager',
    'Label Filter 4': 'Manager',
  },
];
const CSV_CONSTANTS = {
  DEFAULT_SAMPLE_CSV,
  ERRORS: {
    unknown: 'Unknown Error',
    duplicate: 'Duplicate email address',
    incorrect: 'Failed - Incorrect email address',
    invalid: 'Invalid - User not found in perspectives',
    emptyEmail: 'Email Address is missing',
    emptyCSV: 'Error - CSV file is empty, please upload correct csv file.',
    missingFilterColumn: 'Error - One or more filter column name is missing, please upload correct csv file.',
    noNewUsers: 'All stackholder\\s already exists in the list, no new stackholder available inside the list.',
    ErrorColumnName: 'Error Message',
  },
  BUTTON_TEXT: {
    upload: 'UPLOAD NEW CSV',
    continue: 'IMPORT AND CONTINUE',
    import: 'IMPORT',
  },
  SAMPLE_FILTER_NAME: {
    filter_1: 'Label Filter 1',
    filter_2: 'Label Filter 2',
    filter_3: 'Label Filter 3',
    filter_4: 'Label Filter 4',
  },
  ROWS_FOR_PREVIEW: 5,
  saveCSVStackholderTitle: 'Confirmation Alert',
  saveCSVStackholderMessage: 'Importing filter tags using this template will overwrite any existing tag data that may already be configured for this topic. Please ensure your import template is correct and complete before you proceed.',
};
const STAKEHOLDER_CONSTANTS = {
  MIN_STAKEHOLDER_VALUE: 3,
  STAKEHOLDER_MAX_PERCENTAGE: 100,
};

const FEEDBACK_PAGE_REF = {
  GENERAL_SETTINGS: '/settings/generals',
  ATTRIBUTE_PAGE: '/settings/attributes',
  DOMAIN_PAGE: '/settings/domains',
  QUESTION_PAGE: '/settings/questions',
  USER_PAGE: '/settings/users',
};

const REGEX_EXRESSION = {
  PWC_CLOUD_REGEX: /^[-+.0-9A-Z_a-z]+@pwc\.com$/,
  AU_PWC_CLOUD_REGEX: /^[-+.0-9A-Z_a-z]+@([A-Za-z]+(.)?)*pwc.com$/,
  SCYNE_DOMAIN_REGEX: /^[-+.0-9A-Z_a-z]+@scyne\.com\.au$/,
};

/** Remove all code associatiod to these constant
 * when code is ready for the production or
 * will be asked by someone
 * from testing or client team
 */
const TEMPORARY_CONSTANT = {
  hidePreviewButton: false,
  hideReportOption: true,
};

export {
  NO_OF_RECORDS,
  INITIAL_PAGINATED_DATA,
  NO_OF_MINIMUM_ADMIN,
  YESNO_QUESTION,
  OPENTYPE_QUESTION,
  IS_GROUP_1_CLEAR,
  IS_GROUP_2_CLEAR,
  IS_GROUP_3_CLEAR,
  IS_GROUP_4_CLEAR,
  IS_CHANGE_1_TEXT,
  IS_CHANGE_2_TEXT,
  IS_CHANGE_3_TEXT,
  IS_CHANGE_4_TEXT,
  SORT_PORTFOLIOS_ARRAY,
  SORT_DOMAINS_ARRAY,
  SORT_SEGMENT_ARRAY,
  SORT_STAKEHOLDERS_ARRAY,
  INITIAL_HTML_RENDER,
  // RAG_MID_SCORE_INCREMENT_DECREMENT_VALUE,
  CSV_CONSTANTS,
  STAKEHOLDER_CONSTANTS,
  FEEDBACK_PAGE_REF,
  TEMPORARY_CONSTANT,
  REGEX_EXRESSION,
};
