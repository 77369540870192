import * as React from 'react';

const PdfSvg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
    style={{ marginRight: '8px' }}
  >
    <path
      fill="#2D2D2D"
      fillRule="evenodd"
      d="M15 2.5a1 1 0 0 1 1 1v11H0v-12h15Zm-14 11h14v-10H1v10Zm11.718-4.61h-1.473v1.61h-.935v-4H13v.714h-1.755v.962h1.473v.714ZM6.611 10.5v-4h1.474c.473 0 .87.153 1.189.46.318.307.478.7.478 1.18v.72c0 .482-.16.875-.478 1.181-.32.306-.716.459-1.19.459H6.612Zm.934-3.286v2.572h.54a.642.642 0 0 0 .524-.259c.137-.172.205-.394.205-.667v-.725c0-.27-.068-.49-.205-.662a.642.642 0 0 0-.524-.259h-.54ZM3.935 9.13V10.5H3v-4h1.537c.478 0 .856.12 1.136.363.28.241.42.56.42.953 0 .394-.14.711-.42.952-.28.24-.658.361-1.136.361h-.603Zm0-.714h.602c.203 0 .358-.056.465-.166a.59.59 0 0 0 .16-.428.615.615 0 0 0-.159-.436c-.106-.114-.261-.17-.466-.17h-.603v1.2Z"
      clipRule="evenodd"
    />
  </svg>
);
export default PdfSvg;
