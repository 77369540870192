import { actionType } from '../../../../redux/modules/common/overlay';

// Action creater for notification
export function showHideOverlay(overlayFlag) {
  return { type: actionType.SHOW_OVERLAY_FLAG, overlayFlag };
}

export function updateUnsavedChanges(unsavedChangesFlag) {
  return { type: actionType.UNSAVED_CHANGES_FLAG, unsavedChangesFlag };
}

export function updatePhoneRightPanelFlag(isPhoneRightPanelActive) {
  return { type: actionType.PHONE_RIGHT_PANEL_FLAG, isPhoneRightPanelActive };
}


export default {
  showHideOverlay,
  updateUnsavedChanges,
  updatePhoneRightPanelFlag,
};
