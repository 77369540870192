import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Approval from './QuestionPaneComp/Approval.jsx';
import HomeHeader from '../../../ui-components/Header/HomeHeader';
import './Projects.css';
import './question-pane.css';
import Questions from './QuestionPaneComp/Questions.jsx';
import StakeHolders from './QuestionPaneComp/StakeHolders.jsx';
import {
  getProjectPreviewPaneDetails,
  addProjectStakeholder,
  testedQuestionUserFirstCycle,
} from '../actions/project';
import Spinner from '../../../ui-components/Loader/Spinner';
import GoBack from '../../../ui-components/GoBack/GoBack';
import Feedback from '../../../ui-components/Feedback/Feedback.jsx';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import RenderIf from '../../../ui-components/Common/RenderIf.jsx';
import RegisterEmail from '../../../ui-components/Account/RegisterEmail.jsx';
import constant from '../actions/constant';
import {
  validateUserByEmail,
} from '../../account/actions/iam';

import chunkedPromises from '../../../utils/utils';
import SuccessAlertWithTimer from '../../../ui-components/SuccessAlertWithTimer/SuccessAlertWithTimer';


class QuestionPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuesTabActive: true,
      isStackTabActive: false,
      approver: {},
      projectDetails: {},
      orgTopicAdmins: [],
      questionSetList: [],
      stackholderList: [],
      isLoading: false,
      customQuestionsSuccessMessage: null,
      showRegisterProjectStakeholdersModel: false,
      customQuestionsAddedToTopic: false,
      testStakeHolderList: [],
    };
  }

  componentDidMount() {
    this.getProjectPreviewPaneDetails();
  }

  async registerProjectStakeholders(emails, onCloseCallback) {
     this.setState({
              showRegisterProjectStakeholdersModel: false,
            });
    const formattedEmails = emails.map(email => email.toLowerCase());
    this.setState({ totalEmails: formattedEmails });
    const r = await chunkedPromises(formattedEmails, 10,
      c => this.props.validateUserByEmail({ emails: c }).then((res) => {
        setTimeout(() => {
          this.setState({
            customQuestionsAddedToTopic: true,
            customQuestionsSuccessMessage: 'Test succesfully sent',
          });
        }, 1000);
        setTimeout(() => {
          this.setState({
            customQuestionsAddedToTopic: false,
            customQuestionsSuccessMessage: null,
          });
        }, 5000);
        const { data } = res;
        const stakeholdersToBeAdded = data.map(({
          FirstName,
          isRegistered: IsUserRegistered,
          LastName,
          emailId: EmailAddress,
          id,
        }) => ({
          IsUserRegistered, FirstName, LastName, EmailAddress, id,
        }));
        stakeholdersToBeAdded.forEach((element) => {
          element.engagementColor = 'a-badge-secondary';
          element.engagementText = 'pending';
          element.filtergroupname1 = 'null';
          element.filtergroupname2 = 'null';
          element.filtergroupname3 = 'null';
          element.filtergroupname4 = 'null';
          element.filtergroupvalue1 = 'null';
          element.filtergroupvalue2 = 'null';
          element.filtergroupvalue3 = 'null';
          element.filtergroupvalue4 = 'null';
        });
        const projectId = this.state.projectDetails;
        const projectData = {
          projectId: projectId.id,
          stakeholdersToBeAdded,
          stakeholdersToBeRemoved: [],
          groupsDataModel: {},
          is_topic_tester: true,
          is_stakeholder: false,
        };
        return new Promise(resolve => this.props
          .testedQuestionUserFirstCycle(projectData)
          .then((response) => {
            onCloseCallback();
        // window.location.reload(false);
            resolve(response);
          }));
      }));

    const response = [].concat(...r);
    return response;
  }

  getProjectPreviewPaneDetails = async () => {
    this.setState({ isLoading: true });
    const { portfolioId, projectId } = this.props.match.params;
    if (portfolioId && projectId) {
      this.props.getProjectPreviewPaneDetails(portfolioId, projectId).then((resp) => {
        if (resp) {
          const {
            projectDetails, orgTopicAdmins, questionSetList, stackholderList,
            testStakeHolderList,
          } = resp;
          this.setState({
            projectDetails: { ...projectDetails },
            orgTopicAdmins,
            questionSetList,
            stackholderList,
            isLoading: false,
            testStakeHolderList,
          });
        }
      });
    }
  }

  navigate = (path) => {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  toggleTab = (newState) => {
    this.setState({ ...newState });
  }

  openRegisterProjectStakeholdersModel() {
    this.setState({ showRegisterProjectStakeholdersModel: false });
  }

  render() {
    const {
      isQuesTabActive,
      isStackTabActive,
      isLoading,
      approver,
      projectDetails,
      orgTopicAdmins,
      questionSetList,
      stackholderList,
      showRegisterProjectStakeholdersModel, customQuestionsSuccessMessage,
      customQuestionsAddedToTopic,
      // testStakeHolderList,
    } = this.state;
    const { portfolioId, projectId } = this.props.match.params;

    const { systemTerminology } = this.props;
    const projectReplace = levelTwoOrganizations.find(
      o => o.value === systemTerminology.level_two,
    );
    const projectReplaceText = projectReplace && projectReplace.text;

    const { title, message, placeholder } = constant.registerEmailTestTopic;
const { isDrafted } = this.props.projectDetails;
    return (
      <React.Fragment>
        <HomeHeader />
        <Spinner isLoading={isLoading} />
        <RenderIf showComponent={showRegisterProjectStakeholdersModel}>
          <RegisterEmail
            title={title}
            message={message}
            placeholder={placeholder}
            // availableEmails={testStakeHolderList.map(item => item.email_address)}
            onContinueButtonClick={this.registerProjectStakeholders.bind(this)}
            cancelClick={() => this.setState({
              showRegisterProjectStakeholdersModel: false,
            })}
            isTestEmail={true}
            // isRestrictedUser={this.props.selectedPortfolio.isRestrictedUser}
            isStakeholders={true}
          />
        </RenderIf>
        {!isLoading && <div className="content-container ml-0">
          <div className="question_pane">
            <div className="top-title">
              <div className='row align-items-center'>
                <div className="col-md-5">
                  <div className='title'><a onClick={() => this.navigate(`/project-dashboard/${portfolioId}/${projectId}`)} style={{ cursor: 'pointer' }}><GoBack /></a> Preview: {projectDetails.title}</div>
                </div>
                <div className="col-md-7">
                  <div className="tabs_wrap">
                    <a className={isQuesTabActive ? 'active' : ''} onClick={() => this.toggleTab({
                      isQuesTabActive: true,
                      isStackTabActive: false,
                    })}>Questions ({questionSetList?.length ? questionSetList.length : 0})</a>
                    <a className={isStackTabActive ? 'active' : ''} onClick={() => this.toggleTab({
                      isQuesTabActive: false,
                      isStackTabActive: true,
                    })}>Stakeholders ({stackholderList?.length ? stackholderList.length : 0})</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">

              <div className="col-lg-2">
                <Approval
                  navigate={this.navigate}
                  portfolioId={portfolioId}
                  projectId={projectId}
                  approver={approver}
                  projectDetails={projectDetails}
                  orgTopicAdmins={orgTopicAdmins}
                  sendingTestEmail={() => this.openRegisterProjectStakeholdersModel({
                    showRegisterProjectStakeholdersModel: true,
                  })}
                  isDrafted={isDrafted}
                />
              </div>
              <div className="col-lg-10">

                {isQuesTabActive && <Questions
                  questionSetList={questionSetList}
                />}
                {isStackTabActive && <StakeHolders
                  projectDetails={projectDetails}
                  stackholderList={stackholderList}
                />}
              </div>
            </div>
          </div>
          {
          customQuestionsAddedToTopic && (<SuccessAlertWithTimer
            alertMesage={customQuestionsSuccessMessage}
            isFromProjectLaunch={false}
            showSuccessAlert = {true}
          />)
        }
        </div>}
        <Feedback pageRef={`/${projectReplaceText && projectReplaceText.toLowerCase()}-question-pane`} selectedProjectTitle ={projectDetails.title} isShowSupportBtn = {true} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
    projectDetails: state.project.projectDetails,
    systemTerminology: getSystemTerminologySelector(state),
  });
const mapDispatchToProps = {
  getProjectPreviewPaneDetails,
  push,
  validateUserByEmail,
  addProjectStakeholder,
  testedQuestionUserFirstCycle,

};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPane);
