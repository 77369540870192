import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { getPortfolio } from '../../portfolio/selectors/portfolio';
import {
  hideOpenTextNonMatchRightPanel,
} from '../../portfolio/actions/portfolio';
import {
  getOpenTextNonMatchResponses,
} from '../actions/project';
import { Spinner } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import { showHideOverlay } from '../../common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  openTextNonMatchRightPanel: state.portfolio.portfolioData.openTextNonMatchRightPanel,
});

const mapDispatchToProps = {
  hideOpenTextNonMatchRightPanel,
  getOpenTextNonMatchResponses,
  showHideOverlay,
};

class OpenTextNonMatchResponsesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserId: 0,
      stakeholders: [],
      isLoading: true,
      projectTitle: '',
      keyPhrases: [],
      textResponses: [],
      openTextObj: {},
      totalKeyPhrases: 0,
      futureOpenTextQuestions: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    const { openTextNonMatchRightPanel } = this.props.portfolioData;
    this.getOpenText(openTextNonMatchRightPanel);
  }

  componentWillReceiveProps(nextProps) {
    const { openTextNonMatchRightPanel } = nextProps.portfolioData;
    if (this.state.keyPhrases !== openTextNonMatchRightPanel.keyPhrases) {
      this.setState({ isLoading: true });
      this.getOpenText(openTextNonMatchRightPanel);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  getOpenText(openTextNonMatchRightPanel) {
    this.props.getOpenTextNonMatchResponses(openTextNonMatchRightPanel).then((res) => {
      const {
        futureOpenTextQuestions,
        keyPhrases, title, textResponses, totalKeyPhrases, userTagFilters,
      } = res;
      const obj = {
        keyPhrases: this.props.portfolioData.openTextNonMatchRightPanel.keyPhrases,
        projectTitle: this.props.portfolioData.openTextNonMatchRightPanel.projectTitle,
        projectId: this.props.portfolioData.openTextNonMatchRightPanel.projectId,
        offset: this.state.textResponses.length + textResponses.length,
        userTagFilters,
      };
      if (keyPhrases) {
        this.setState({
          keyPhrases,
          projectTitle: title,
          textResponses,
          openTextObj: obj,
          isLoading: false,
          totalKeyPhrases,
          futureOpenTextQuestions,
        });
      }
    });
  }

  sortResponse(array) {
    array.sort((a, b) => new Date(b.response_received) - new Date(a.response_received));
  }

  hideOpenTextNonMatchRightPanel = () => {
    this.props.hideOpenTextNonMatchRightPanel();
    this.props.showHideOverlay(false);
  }

  render() {
    const {
      projectTitle, textResponses, isLoading, futureOpenTextQuestions,
    } = this.state;
    const timzone = moment.tz.guess();
    return (
      <React.Fragment >
          <div className="right-sidebar add-project-sidebar open-text-container" ref={this.setWrapperRef}>
            <Spinner isLoading={isLoading} />
            <RenderIf showComponent={!isLoading}>
              <div className="right-bar">
                <div className="right-bar-head d-flex justify-content-between align-items-star">
                  <div className="right-bar-title">
                    <h4 className="mb-0 title">{projectTitle}</h4>
                  </div>
                  <div className="right-bar-close">
                    <button type="button" className="btn btn-sm bg-transparent close" onClick={() => this.hideOpenTextNonMatchRightPanel()}><span class="material-icons">
                      close
                    </span>
                    </button>
                  </div>
                </div>
                <div className="header-subline"><span className="text-uppercase phrase-color">ALL RESPONSES</span></div>


                <div className="right-bar-body pt-4">
                  <div id="accordion" className="accordion right-bar-accordion">
                    {textResponses.map((data, index) => (

                      <div key={`parent-${index}`} className="card border-bottom">
                        <div id={`collapsed-header-${index}`} className={textResponses.length === 1 ? 'card-header' : 'card-header collapsed'} data-bs-toggle="collapse"
                          href={`#collapsed-question-${index}`}>
                          <a className="card-title">
                            <span className="mr-2 preview-pane-font">Q: </span> <span className='preview-pane-font'> {data.QuestionTitle} </span>
                          </a>
                        </div>
                        <div id={`collapsed-question-${index}`} className={textResponses.length === 1 ? 'card-body pt-2 show' : 'card-body collapse pt-2'}>
                          {this.sortResponse(data.response)}
                          {data.response.map(res => (
                            <div className={`right-bar-item mb-3 ${res.bgColor}`}>
                              <div className="d-flex align-items-center">
                                <div className="message-avatar">
                                  <span className="material-icons">
                                    person
                                  </span>
                                </div>
                                <div className="message-box">
                                  <p dangerouslySetInnerHTML={{ __html: res.response_text }}></p>
                                </div>
                              </div>
                              <small className="message-info">{moment(res.response_received).tz(timzone).format('h:mm A, D MMMM YYYY')}</small>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}

                    {(futureOpenTextQuestions
                      && futureOpenTextQuestions.length > 0)
                      && futureOpenTextQuestions.map((item, index) => {
                        index = `tooltip-${item.question_id}`;
                        return (
                          <React.Fragment>
                            <div key={index} id={`collapsed-header-3${index}`} className="right-bar-accordion card border-bottom">
                              <a className="card-title custom-question-wrapper">
                                <div className="custom-question-container">
                                  <span className="custom-question fq-font preview-pane-font"><i>Q: {item.title} </i></span>
                                  <div className='cq-header-action'>
                                    <span data-for={index} data-tip='tooltip' className="material-icons custom-question-icon cm-color orange-color ml-2 custom-question-icon">
                                      schedule_send
                                    </span>
                                  </div>
                                </div>
                              </a>
                            </div><ReactTooltip id={index} className='next-cycle-tooltip' place='top'
                              effect='solid'>
                              Question will be sent in the next question cycle.
                            </ReactTooltip>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>

              </div>
              <div className="show-title" >
                <h4 className="mb-0 title" style={{
                  color: ' #c5c5c5',
                  fontSize: '14px',
                  textAlign: 'center',
                }}>The views expressed in Perspectives are those provided by topic
                  stakeholders and are not the views of PwC.</h4>
              </div>
            </RenderIf>

          </div>
      </React.Fragment >
    );
  }
}
OpenTextNonMatchResponsesPanel.propTypes = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenTextNonMatchResponsesPanel);
