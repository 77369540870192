const GET_SYSTEM_TERMINOLOGY = 'GET_SYSTEM_TERMINOLOGY';
const UPDATE_SYSTEM_TERMINOLOGY = 'UPDATE_SYSTEM_TERMINOLOGY';
const GET_PROJECT_LIFECYCLE_STAGES_LIST = 'GET_PROJECT_LIFECYCLE_STAGES_LIST';
const EMPTY_PROJECT_LIFECYCLE_STAGES_LIST = 'EMPTY_PROJECT_LIFECYCLE_STAGES_LIST';
const GET_PROJECT_LIFECYCLE_STAGE = 'GET_PROJECT_LIFECYCLE_STAGE';
const INSERT_PROJECT_LIFECYCLE_STAGE = 'INSERT_PROJECT_LIFECYCLE_STAGE';
const UPDATE_PROJECT_LIFECYCLE_STAGE = 'UPDATE_PROJECT_LIFECYCLE_STAGE';
const DELETE_PROJECT_LIFECYCLE_STAGE = 'DELETE_PROJECT_LIFECYCLE_STAGE';
const CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER = 'CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER';
const GET_LIFECYCLE_STAGES = 'GET_LIFECYCLE_STAGES';
const GET_DOMAINS_LIST = 'GET_DOMAINS_LIST';
const GET_DOMAIN = 'GET_DOMAIN';
const TOGGLE_ALL_CHECKBOXES = 'TOGGLE_ALL_CHECKBOXES';
const TOGGLE_CHECKBOX = 'TOGGLE_CHECKBOX';
const INSERT_DOMAIN = 'INSERT_DOMAIN';
const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
const DELETE_DOMAIN = 'DELETE_DOMAIN';
const SET_HEADING = 'SET_HEADING';
const ATTRIBUTES = 'ATTRIBUTES';
const GET_ATTRIBUTES_LIST = 'GET_ATTRIBUTES_LIST';
const TOGGLE_ALL_ATTRIBUTE_CHECKBOXES = 'TOGGLE_ALL_ATTRIBUTE_CHECKBOXES';
const TOGGLE_ATTRIBUTE_CHECKBOX = 'TOGGLE_ATTRIBUTE_CHECKBOX';
const GET_QUESTIONS_LIST = 'GET_QUESTIONS_LIST';
const TOGGLE_ALL_QUESTIONS_CHECKBOXES = 'TOGGLE_ALL_QUESTIONS_CHECKBOXES';
const TOGGLE_QUESTIONS_CHECKBOX = 'TOGGLE_QUESTIONS_CHECKBOX';
const GET_USERS_LIST = 'GET_USERS_LIST';
const TOGGLE_USER_CHECKBOX = 'TOGGLE_USER_CHECKBOX';
const TOGGLE_ALL_USER_CHECKBOXES = 'TOGGLE_ALL_USER_CHECKBOXES';
const CHANGE_ADDED_QUESTION_STATUS = 'CHANGE_ADDED_QUESTION_STATUS';
const ADDED_QUESTION_STATUS = 'ADDED_QUESTION_STATUS';
const NEW_ADDED_QUESTION_STATUS = 'NEW_ADDED_QUESTION_STATUS';
const ADDED_USER_STATUS = 'ADDED_USER_STATUS';
const NEW_ADDED_USER_STATUS = 'NEW_ADDED_USER_STATUS';
const ADDED_DOMAIN_STATUS = 'ADDED_DOMAIN_STATUS';
const NEW_ADDED_DOMAIN_STATUS = 'NEW_ADDED_DOMAIN_STATUS';
const CHANGE_ADDED_DOMAIN_STATUS = 'CHANGE_ADDED_DOMAIN_STATUS';
const ADDED_ATTRIBUTE_STATUS = 'ADDED_ATTRIBUTE_STATUS';
const NEW_ADDED_ATTRIBUTE_STATUS = 'NEW_ADDED_ATTRIBUTE_STATUS';
const CHANGE_ADDED_ATTRIBUTE_STATUS = 'CHANGE_ADDED_ATTRIBUTE_STATUS';
const ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL = 'ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL';
const DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL = 'DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL';
const ENABLE_UNSAVED_CHANGES = 'ENABLE_UNSAVED_CHANGES';
const DISABLE_UNSAVED_CHANGES = 'DISABLE_UNSAVED_CHANGES';
const GET_QUESTION_MODEL_LIST = 'GET_QUESTION_MODEL_LIST';
const SHOW_QUESTION_MODEL_RIGHTPANEL = 'SHOW_QUESTION_MODEL_RIGHTPANEL';
const HIDE_QUESTION_MODEL_RIGHTPANEL = 'HIDE_QUESTION_MODEL_RIGHTPANEL';
const CHANGE_QUESTION_MODELID = 'CHANGE_QUESTION_MODELID';
const USER_ACCESSIBLE_ROLE = 'USER_ACCESSIBLE_ROLE';
const SET_HEADING_RIGHT_END_TEXT = 'SET_HEADING_RIGHT_END_TEXT';
const ARCHIVE_QUESTION_MODEL = 'ARCHIVE_QUESTION_MODEL';
const LIST_ARCHIVE_QUESTION_MODEL = 'LIST_ARCHIVE_QUESTION_MODEL';
const UN_ARCHIVE_QUESTION_MODEL = 'UN_ARCHIVE_QUESTION_MODEL';
const GET_ARCHIVED_QUESTION_MODEL_LIST = 'GET_ARCHIVED_QUESTION_MODEL_LIST';
const TOGGLE_ALL_MODEL_CHECKBOXES = 'TOGGLE_ALL_MODEL_CHECKBOXES';
const TOGGLE_MODEL_CHECKBOX = 'TOGGLE_MODEL_CHECKBOX';
const GET_ARCHIVED_ORGANISATION_LIST = 'GET_ARCHIVED_ORGANISATION_LIST';
const GET_ARCHIVED_TOPIC_LIST = 'GET_ARCHIVED_TOPIC_LIST';
const TOGGLE_ALL_TOPIC_CHECKBOXES = 'TOGGLE_ALL_TOPIC_CHECKBOXES';
const TOGGLE_ALL_ORG_CHECKBOXES = 'TOGGLE_ALL_ORG_CHECKBOXES';
const TOGGLE_ORG_CHECKBOX = 'TOGGLE_ORG_CHECKBOX';
const TOGGLE_TOPIC_CHECKBOX = 'TOGGLE_TOPIC_CHECKBOX';

export default {
  GET_SYSTEM_TERMINOLOGY,
  UPDATE_SYSTEM_TERMINOLOGY,
  GET_PROJECT_LIFECYCLE_STAGES_LIST,
  EMPTY_PROJECT_LIFECYCLE_STAGES_LIST,
  GET_PROJECT_LIFECYCLE_STAGE,
  INSERT_PROJECT_LIFECYCLE_STAGE,
  UPDATE_PROJECT_LIFECYCLE_STAGE,
  DELETE_PROJECT_LIFECYCLE_STAGE,
  CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER,
  GET_LIFECYCLE_STAGES,
  GET_DOMAINS_LIST,
  GET_DOMAIN,
  TOGGLE_ALL_CHECKBOXES,
  TOGGLE_CHECKBOX,
  INSERT_DOMAIN,
  UPDATE_DOMAIN,
  DELETE_DOMAIN,
  SET_HEADING,
  ATTRIBUTES,
  GET_ATTRIBUTES_LIST,
  TOGGLE_ATTRIBUTE_CHECKBOX,
  TOGGLE_ALL_ATTRIBUTE_CHECKBOXES,
  GET_QUESTIONS_LIST,
  TOGGLE_QUESTIONS_CHECKBOX,
  TOGGLE_ALL_QUESTIONS_CHECKBOXES,
  GET_USERS_LIST,
  TOGGLE_ALL_USER_CHECKBOXES,
  TOGGLE_USER_CHECKBOX,
  CHANGE_ADDED_QUESTION_STATUS,
  ADDED_QUESTION_STATUS,
  NEW_ADDED_QUESTION_STATUS,
  ADDED_USER_STATUS,
  NEW_ADDED_USER_STATUS,
  ADDED_DOMAIN_STATUS,
  NEW_ADDED_DOMAIN_STATUS,
  CHANGE_ADDED_DOMAIN_STATUS,
  ADDED_ATTRIBUTE_STATUS,
  CHANGE_ADDED_ATTRIBUTE_STATUS,
  NEW_ADDED_ATTRIBUTE_STATUS,
  ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL,
  DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL,
  ENABLE_UNSAVED_CHANGES,
  DISABLE_UNSAVED_CHANGES,
  GET_QUESTION_MODEL_LIST,
  SHOW_QUESTION_MODEL_RIGHTPANEL,
  HIDE_QUESTION_MODEL_RIGHTPANEL,
  CHANGE_QUESTION_MODELID,
  USER_ACCESSIBLE_ROLE,
  SET_HEADING_RIGHT_END_TEXT,
  ARCHIVE_QUESTION_MODEL,
  LIST_ARCHIVE_QUESTION_MODEL,
  UN_ARCHIVE_QUESTION_MODEL,
  GET_ARCHIVED_QUESTION_MODEL_LIST,
  TOGGLE_ALL_MODEL_CHECKBOXES,
  TOGGLE_MODEL_CHECKBOX,
  GET_ARCHIVED_ORGANISATION_LIST,
  GET_ARCHIVED_TOPIC_LIST,
  TOGGLE_ALL_ORG_CHECKBOXES,
  TOGGLE_ALL_TOPIC_CHECKBOXES,
  TOGGLE_ORG_CHECKBOX,
  TOGGLE_TOPIC_CHECKBOX,
};
