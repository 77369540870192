import React, { Component } from 'react';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { SpinnerButton } from '../Loader';

class RegisterEmailStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      firstName: '',
      lastName: '',
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
    this.registerEmailStep2ModalWrapperRef = this.registerEmailStep2ModalWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    $(document).on('shown.bs.modal', '.modal', () => {
      $('input[name="firstName"]').focus();
    });
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#registerEmailStep2Modal').modal('show');
  }

  registerEmailStep2ModalWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.cancelClick();
    }
  }

  cancelClick() {
    this.hideModal();
    this.props.cancelClick();
  }

  hideModal() {
    $('#registerEmailStep2Modal').modal('hide');
  }

  onContinueButtonClick(formData) {
    this.showSpinner(true);
    this.props.onContinueButtonClick(formData).then(() => {
      this.showSpinner(false);
      this.hideModal();
    });
  }

  showSpinner(isCreating) {
    this.setState({ isCreating });
  }

  render() {
    const { title, message } = this.props;
    const { isCreating } = this.state;
    return (
      <div
        aria-hidden="true"
        className="modal a-modal fade add-stakeholder-modal"
        id="registerEmailStep2Modal"
        role="dialog"
        ref={this.registerEmailStep2ModalWrapperRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header ">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h4">{title}</div>
              </div>
              <span className="icon-wrapper">
                <a className={this.state.isCreating ? 'disabled' : ''}>
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    data-bs-dismiss="modal"
                  />
                </a>
              </span>
            </div>
            <Formik
              initialValues={this.state}
              validateOnBlur={true}
              onSubmit={(values) => {
                this.onContinueButtonClick(values);
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .trim()
                  .required('First Name is required.'),
                lastName: Yup.string()
                  .trim()
                  .required('Last Name is required.'),
              })}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="a-modal-body pt-0 mt-1">
                    <div className=" a-font-md mb-4">{message}</div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-0">
                          <Field
                            autoComplete="off"
                            name="firstName"
                            placeholder="First Name"
                            disabled={isCreating}
                            className={
                              errors.firstName && touched.firstName ? 'error a-text-input' : 'a-text-input'
                            }
                          />
                          <div className="error">
                            <ErrorMessage name="firstName" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-0">
                          <Field
                            autoComplete="off"
                            name="lastName"
                            placeholder="Last Name"
                            disabled={isCreating}
                            className={
                              errors.lastName && touched.lastName ? 'error a-text-input' : 'a-text-input'
                            }
                          />
                          <div className="error">
                            <ErrorMessage name="lastName" />
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                  <div className="a-modal-footer a-border-tp justify-content-end">
                    <SpinnerButton isLoading={isCreating} type="transparent" label="Create Account" />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

RegisterEmailStep2.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  onContinueButtonClick: PropTypes.func.isRequired,
  cancelClick: PropTypes.func.isRequired,
};

export default RegisterEmailStep2;
