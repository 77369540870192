import actionType from '../../../../redux/modules/settings/actionType';
import settingsService from '../../../../redux/services/settingsService';
import { INITIAL_PAGINATED_DATA } from '../../../../Constants';


// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

// change status of added/updated user
export const modifyAddedUserStatus = status => (dispatch) => {
  dispatch({ type: actionType.ADDED_USER_STATUS, data: status });
};

export const getAllProjectsForFilters = user => () => settingsService
  .getAllProjectsForFilters(user)
  .then(res => res)
  .catch(error => error);

export const getAllPortfoliosForFilters = user => () => settingsService
  .getAllPortfoliosForFilters(user)
  .then(res => res)
  .catch(error => error);

export const getUsers = (filters, paginatedData) => dispatch => settingsService
  .getUsersList(filters, paginatedData)
  .then((res) => {
    const users = {
      usersList: [],
      count: 0,
    };
    users.count = res.data.count;
    res.data.users.map((item) => {
      const data = item;
      data.isChecked = false;
      users.usersList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_USERS_LIST, users });
  })
  .catch(() => {
  });

export function getSystemAdminUsersCount() {
  return settingsService
    .getSystemAdminUsersCount()
    .then(res => res)
    .catch(() => {
    });
}


export const insertUser = user => dispatch => settingsService
  .insertUser(user)
  .then((res) => {
    dispatch(modifyAddedUserStatus(true));
    return res;
  })
  .catch(error => error);

export function validateEmailAddress(user) {
  return settingsService
    .validateEmailAddress(user)
    .then(res => res.data)
    .catch({});
}

export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_USER_CHECKBOXES });
};


export const toggleCheckbox = user => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_USER_CHECKBOX, user });
};

export const deleteUsers = selectedIds => dispatch => settingsService
  .deleteUsers(selectedIds)
  .then((response) => {
    dispatch(getUsers(null, INITIAL_PAGINATED_DATA));
    return response;
  })
  .catch(error => error);

export const deleteMyAccount = () => () => settingsService
  .deleteMyAccount()
  .then(response => response)
  .catch(error => error);

export const updateUser = user => dispatch => settingsService
  .updateUser(user)
  .then((res) => {
    dispatch(modifyAddedUserStatus(true));
    return res;
  })
  .catch(error => error);

export const reInviteUser = emails => () => settingsService
  .resendInviations(emails)
  .then(response => response)
  .catch(error => error);


// check logged in user able to change the application mode
export function isUserAbleToChangeAppModeOptions() {
  return dispatch => settingsService
    .checkUserAbleToChangeModeOptions()
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.USER_ACCESSIBLE_ROLE, data: res.data });
      }
      return res;
    })
    .catch({});
}

// set default mode selection
export const setUserDefaultMode = selection => () => settingsService
  .setUserDefaultMode(selection)
  .then(response => response)
  .catch(error => error);

// set default mode selection
export const setDefaultMode = selection => () => settingsService
  .setDefaultMode(selection)
  .then(response => response)
  .catch(error => error);

  export const getUserDefaultMode = () => () => settingsService
  .getUserDefaultMode()
  .then(response => response)
  .catch(error => error);
