import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import {
  Formik,
} from 'formik';
import CustomSelectDropDown from '../../../ui-components/CustomSelectDropDown/CustomSelectDropDown';

const SegmentsGroupToolbar = ({
 segmentGroups, applySegmentGroup, isSegmentGroupApplied, initialSegmentGroupFormValues,
}) => {
  const {
    segmentGroup1, segmentGroup2, segmentGroup3, segmentGroup4,
  } = segmentGroups;
  const applySegment = (name, value) => {
    switch (name) {
      case 'segmentGroup1':
        applySegmentGroup(name, value);

        break;
      case 'segmentGroup2':
        applySegmentGroup(name, value);

        break;
      case 'segmentGroup3':
        applySegmentGroup(name, value);
        break;
      case 'segmentGroup4':
        applySegmentGroup(name, value);
        break;
      default:
    }
  };
  return (
    <div className="col-lg-12 col-md-12 col-xl-12 d-flex align-items-end sgmnt-insufcnt-dta row">
      <Formik
        enableReinitialize={true}
        initialValues={initialSegmentGroupFormValues}
      >
        {({ setFieldValue, values }) => (
          <React.Fragment>
            {
              segmentGroup1 && segmentGroup1.length > 0
              && (<div className='col-lg-3 sgmnt-grp'><p>Group A</p><CustomSelectDropDown
                placeholder="Choose a segment"
                name="selectedSegmentGroupValue1"
                selectedValue={values.segmentGroup1}
                onSelectDropDownChange={v => applySegment('segmentGroup1', v)}
                baseClass="selectedSegmentGroupValue1"
                options={segmentGroup1}
                setFieldValue={setFieldValue}
                className="lightgrey"
              />
                 </div>)
            }
            {
              segmentGroup2 && segmentGroup2.length > 0
              && (<div className='col-lg-3 sgmnt-grp'>
              <p>Group B</p><CustomSelectDropDown
                placeholder="Choose a segment"
                name="selectedSegmentGroupValue2"
                selectedValue={values.segmentGroup2}
                onSelectDropDownChange={v => applySegment('segmentGroup2', v)}
                baseClass="selectedSegmentGroupValue2"
                options={segmentGroup2}
                setFieldValue={setFieldValue}
                className="lightgrey"
              />
                </div>)
            }
            {
              segmentGroup3 && segmentGroup3.length > 0
              && (<div className='col-lg-3 sgmnt-grp'><p>Group C</p>
              <CustomSelectDropDown
                placeholder="Choose a segment"
                name="selectedSegmentGroupValue3"
                selectedValue={values.segmentGroup3}
                onSelectDropDownChange={data => applySegment('segmentGroup3', data)}
                baseClass="selectedSegmentGroupValue3"
                setFieldValue={setFieldValue}
                className="selectedSegmentGroupValue"
              /></div>)
            }
            {
              segmentGroup4 && segmentGroup4.length > 0
              && (<div className='col-lg-3 sgmnt-grp'><p>Group D</p><CustomSelectDropDown
                placeholder="Choose a segment"
                name="selectedSegmentGroupValue4"
                selectedValue={values.segmentGroup4}
                onSelectDropDownChange={data => applySegment('segmentGroup4', data)}
                baseClass="selectedSegmentGroupValue4"
                setFieldValue={setFieldValue}
                className="selectedSegmentGroupValue"
              /></div>)
            }
            {isSegmentGroupApplied && !segmentGroup4 && <button onClick={() => applySegmentGroup(!segmentGroup3 ? 'segmentGroup3' : 'segmentGroup4')} className="a-btn a-btn-lg a-btn-primary ml-2 square-btn primary-outline"
            data-for={'segment-dd-label-icon'} data-tip={'segment-dd-label_tooltip'}
            >
              <i className="appkiticon mr-0 a-btn-icon icon-plus-outline" />
            </button >}
            <ReactTooltip id={'segment-dd-label-icon'} className={'segment-dd-label_tooltip'} place='right'
            effect='solid' multiline={true} backgroundColor='#6b6b6b'> Add a segment</ReactTooltip>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  projectSegments: state.project.projectSegments,
});


export default connect(mapStateToProps,
  null)(SegmentsGroupToolbar);
