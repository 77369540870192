/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-multi-email/style.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  Formik, Form, FieldArray, Field, ErrorMessage,
} from 'formik';

import { getSystemTerminology } from '../../modules/settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../modules/settings/question-model/selectors/general';
import {
  hideRecordProcessing,
  hideProcessing,
  showRecordProcessing,
} from '../../modules/project/actions/project';
import { SpinnerButton, Spinner } from '../Loader';
import ClearConfirmation from '../ClearConfirmation/ClearConfirmation.jsx';
import RenderIf from '../Common';
import {
  IS_GROUP_1_CLEAR,
  IS_GROUP_2_CLEAR,
  IS_GROUP_3_CLEAR,
  IS_GROUP_4_CLEAR,
  IS_CHANGE_1_TEXT,
  IS_CHANGE_2_TEXT,
  IS_CHANGE_3_TEXT,
  IS_CHANGE_4_TEXT,
  NO_OF_RECORDS, INITIAL_PAGINATED_DATA,
} from '../../Constants';

import Pagination from '../Pagination/Pagination.jsx';
import UserCSVFilterModel from '../UserCsv/UserCSVFilterModel.jsx';
import FilterDropDown from '../Dropdown/FilterDropDown.jsx';


const mapStateToProps = state => ({
  isSysAdmin: state.login.isSysAdmin,
  systemTerminology: getSystemTerminologySelector(state),
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
  portfolioData: state.portfolio.portfolioData,
  filterGroupModel: state.project.filterGroupModel,
  filterUsersCount: state.project.filterUsersCount,
});
const mapDispatchToProps = {
  getSystemTerminology,
  hideRecordProcessing,
  hideProcessing,
  showRecordProcessing,
};
function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}
function isValidateUserFilter(value, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'Filter group value required.';
    const isHavefiltergroupvalue1 = value.stakeholders.filter(item => item.filtergroupvalue1);
    const isHavefiltergroupvalue2 = value.stakeholders.filter(item => item.filtergroupvalue2);
    const isHavefiltergroupvalue3 = value.stakeholders.filter(item => item.filtergroupvalue3);
    const isHavefiltergroupvalue4 = value.stakeholders.filter(item => item.filtergroupvalue4);
    const isValidGroupvalue1 = isHavefiltergroupvalue1.length > 0;
    const isValidGroupvalue2 = isHavefiltergroupvalue2.length > 0;
    const isValidGroupvalue3 = isHavefiltergroupvalue3.length > 0;
    const isValidGroupvalue4 = isHavefiltergroupvalue4.length > 0;
    if (isValidGroupvalue1 && !value.filtergroupname1) {
      setError(actions, 'filtergroupname1', errorMessage);
      resolve(true);
    } if (isValidGroupvalue2 && !value.filtergroupname2) {
      setError(actions, 'filtergroupname2', errorMessage);
      resolve(true);
    } if (isValidGroupvalue3 && !value.filtergroupname3) {
      setError(actions, 'filtergroupname3', errorMessage);
      resolve(true);
    } if (isValidGroupvalue4 && !value.filtergroupname4) {
      setError(actions, 'filtergroupname4', errorMessage);
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

class FilterGroupModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      email: '',
      emails: [],
      totalEmails: [],
      errorMessages: [],
      errorEmails: [],
      responseEmails: [],
      setFieldValue: null,
      isDisabled: false,
      showDeleteModal: false,
      clearGroup: 0,
      currentPage: 1,
      totalPages: 1,
      isLoaded: false,
      filterGroupModel: [],
      filtergroupname: '',
      startIndex: 0,
      endIndex: 9,
      showUserCsvModel: false,
      stakeholders: [],
      filtervalues: '',
      filtervalues2: '',
      filtervalues3: '',
      filtervalues4: '',
      selectedinx: '',
      selectedinx2: '',
      selectedinx3: '',
      selectedinx4: '',
      FilterGroupValue1: [],
      FilterGroupValue1copy: [],
      FilterCopyRow1: [],
      FilterGroupValue2: [],
      FilterGroupValue2copy: [],
      FilterCopyRow2: [],
      FilterGroupValue3: [],
      FilterGroupValue3copy: [],
      FilterCopyRow3: [],
      FilterGroupValue4: [],
      FilterGroupValue4copy: [],
      FilterCopyRow4: [],
      isSuggestionsShow: false,

    };

    this.cancelClick = this.cancelClick.bind(this);
    this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
    this.modalWrapperRef = this.modalWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideUserCSVModal = this.hideUserCSVModal.bind(this);
    this.enteredvalues = this.enteredvalues.bind(this);
    this.typingTimeout = null;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#filterGroupModal').modal('show');
    const { selectedProjectId } = this.props.portfolioData;
    this.getFilterData(selectedProjectId, INITIAL_PAGINATED_DATA);
    const {
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
    } = this.state;
    this.setState({
      FilterGroupValue1copy: FilterGroupValue1,
      FilterCopyRow1: FilterGroupValue1,
      FilterGroupValue2copy: FilterGroupValue2,
      FilterCopyRow2: FilterGroupValue2,
      FilterGroupValue4copy: FilterGroupValue4,
      FilterCopyRow4: FilterGroupValue4,
      FilterGroupValue3copy: FilterGroupValue3,
      FilterCopyRow3: FilterGroupValue3,
    });
  }

  getTotalNumOfPages(number) {
    return number !== 0
      ? Math.ceil(number / NO_OF_RECORDS) : 1;
  }

  getFilterData() {
    const projectStakeholders = this.props.stakeholders;
    const { csvColumns = {} } = this.props;
    const filterGroup = {
      projectName: '',
      filtergroupname1: '',
      filtergroupname2: '',
      filtergroupname3: '',
      filtergroupname4: '',
      stakeholders: [],
    };
    const filterGroup1 = projectStakeholders.find(item => item.filtergroupname1 !== null);
    const filterGroup2 = projectStakeholders.find(item => item.filtergroupname2 !== null);
    const filterGroup3 = projectStakeholders.find(item => item.filtergroupname3 !== null);
    const filterGroup4 = projectStakeholders.find(item => item.filtergroupname4 !== null);
    filterGroup.projectName = projectStakeholders[0].title;
    filterGroup.filtergroupname1 = csvColumns.column1 ? csvColumns.column1 : (filterGroup1 ? filterGroup1.filtergroupname1 : '');
    filterGroup.filtergroupname2 = csvColumns.column2 ? csvColumns.column2 : (filterGroup2 ? filterGroup2.filtergroupname2 : '');
    filterGroup.filtergroupname3 = csvColumns.column3 ? csvColumns.column3 : (filterGroup3 ? filterGroup3.filtergroupname3 : '');
    filterGroup.filtergroupname4 = csvColumns.column4 ? csvColumns.column4 : (filterGroup4 ? filterGroup4.filtergroupname4 : '');
    filterGroup.stakeholders = projectStakeholders;
    projectStakeholders.map((item) => {
      this.state.FilterGroupValue1.push(item.filtergroupvalue1);
      this.state.FilterGroupValue2.push(item.filtergroupvalue2);
      this.state.FilterGroupValue3.push(item.filtergroupvalue3);
      this.state.FilterGroupValue4.push(item.filtergroupvalue4);
    });

    this.setState({
      filterGroupModel: filterGroup,
      isLoaded: true,
      totalPages: this.getTotalNumOfPages(filterGroup.stakeholders.length),
    });
  }

  modalWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // this.cancelClick();
      const {
        FilterGroupValue1copy, FilterGroupValue2copy, FilterGroupValue3copy, FilterGroupValue4copy,
      } = this.state;
      this.setState({
        selectedinx: '',
        selectedinx2: '',
        selectedinx3: '',
        selectedinx4: '',
        FilterGroupValue1: FilterGroupValue1copy,
        FilterGroupValue2: FilterGroupValue2copy,
        FilterGroupValue3: FilterGroupValue3copy,
        FilterGroupValue4: FilterGroupValue4copy,
      });
    }
  }

  cancelClick() {
    this.hideModal();
    this.hideUserCSVModal();
    this.props.cancelClick();
  }

  hideModal() {
    $('#filterGroupModal').modal('hide');
  }

  hideUserCSVModal() {
    $('#userCsvModal').modal('hide');
  }

  onContinueButtonClick(formData) {
    const data = {
      projectId: this.props.portfolioData.selectedProjectId,
      filtergroupname1: formData.filtergroupname1 ? formData.filtergroupname1.trim() : '',
      filtergroupname2: formData.filtergroupname2 ? formData.filtergroupname2.trim() : '',
      filtergroupname3: formData.filtergroupname3 ? formData.filtergroupname3.trim() : '',
      filtergroupname4: formData.filtergroupname4 ? formData.filtergroupname4.trim() : '',
      stakeHoldersGroupValue: {
        filtergroupvalue1: [],
        filtergroupvalue2: [],
        filtergroupvalue3: [],
        filtergroupvalue4: [],
      },
    };
    formData.stakeholders.map((item) => {
      data.stakeHoldersGroupValue.filtergroupvalue1.push({
        userId: item.id,
        value: item.filtergroupvalue1
          ? item.filtergroupvalue1.trim() : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue2.push({
        userId: item.id,
        value: item.filtergroupvalue2
          ? item.filtergroupvalue2.trim() : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue3.push({
        userId: item.id,
        value: item.filtergroupvalue3
          ? item.filtergroupvalue3.trim() : null,
      });
      data.stakeHoldersGroupValue.filtergroupvalue4.push({
        userId: item.id,
        value: item.filtergroupvalue4
          ? item.filtergroupvalue4.trim() : null,
      });
    });

    this.updateStackHolderFilterData(data);
  }

  updateStackHolderFilterData = (data) => {
    this.showSpinner(true);
    this.setState({
      filterGroupModel: {
        ...this.state.filterGroupModel,
        filtergroupname1: data.filtergroupname1,
        filtergroupname2: data.filtergroupname2,
        filtergroupname3: data.filtergroupname3,
        filtergroupname4: data.filtergroupname4,
      },
    });
    this.props.onContinueButtonClick(data).then(() => {
      this.showSpinner(false);
      this.props.hideRecordProcessing();
      if (!data.csvHasErrors) {
        this.cancelClick();
        this.hideUserCSVModal();
      }
    });
  }

  showSpinner(value) {
    this.setState({ isCreating: value });
  }

  setFieldValueOnBlur(field, e, setFieldValue) {
    setFieldValue(field, e.target.value);
  }

  removeFilterGroup1Value(setFieldValue) {
    setFieldValue('filtergroupname1', '');
    this.state.filterGroupModel.stakeholders.map((item, index) => {
      setFieldValue(`stakeholders[${index}].filtergroupvalue1`, '');
      return item;
    });
  }

  removeFilterGroup2Value(setFieldValue) {
    setFieldValue('filtergroupname2', '');
    this.state.filterGroupModel.stakeholders.map((item, index) => {
      setFieldValue(`stakeholders[${index}].filtergroupvalue2`, '');
      return item;
    });
  }

  removeFilterGroup3Value(setFieldValue) {
    setFieldValue('filtergroupname3', '');
    this.state.filterGroupModel.stakeholders.map((item, index) => {
      setFieldValue(`stakeholders[${index}].filtergroupvalue3`, '');
      return item;
    });
  }

  removeFilterGroup4Value(setFieldValue) {
    setFieldValue('filtergroupname4', '');
    this.state.filterGroupModel.stakeholders.map((item, index) => {
      setFieldValue(`stakeholders[${index}].filtergroupvalue4`, '');
      return item;
    });
  }

  clearFilterGroup(setFieldValue, clearGroup, filtergroupname) {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (filtergroupname) {
      this.setState({
        showDeleteModal: true,
        setFieldValue,
        clearGroup,
        filtergroupname,
      });
    }
  }

  clearFilters() {
    const { setFieldValue } = this.state;
    const { clearGroup } = this.state;
    if (clearGroup === IS_GROUP_1_CLEAR) {
      this.removeFilterGroup1Value(setFieldValue);
    }
    if (clearGroup === IS_GROUP_2_CLEAR) {
      this.removeFilterGroup2Value(setFieldValue);
    }
    if (clearGroup === IS_GROUP_3_CLEAR) {
      this.removeFilterGroup3Value(setFieldValue);
    }
    if (clearGroup === IS_GROUP_4_CLEAR) {
      this.removeFilterGroup4Value(setFieldValue);
    }
    this.setState({
      setFieldValue: null,
      clearGroup: 0,
    });
  }

  cancelClear() {
    this.setState({ showDeleteModal: false });
  }

  nextPage() {
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) this.updateCurrentPage((currentPage + 1));
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage((currentPage - 1));
  }

  updateCurrentPage(pageNum) {
    const end = (NO_OF_RECORDS * pageNum) - 1;
    const start = pageNum === 1 ? 0 : (end - NO_OF_RECORDS) + 1;
    this.setState({
      currentPage: parseInt(pageNum, 10),
      startIndex: start,
      endIndex: end,
    });
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value);
    } else {
      this.updateCurrentPage(1);
    }
  }

  pageOnChangeEvent(value) {
    const currentValue = parseInt(value, 10);
    const { totalPages } = this.state;
    if (!isNaN(value) && currentValue && currentValue <= totalPages) {
      this.updateCurrentPage(value);
    }
  }

  checkValidFilterGroupValue(value, action) {
    this.showSpinner(true);
    isValidateUserFilter(value, action).then((isExist) => {
      if (!isExist) {
        this.onContinueButtonClick(value);
      } else {
        this.showSpinner(false);
      }
    });
  }

  capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  enteredvalues(values, isSelected, val, fieldName, setFieldValue) {
    const enterval = this.capitalizeFirstLetter(values);
    const {
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
      FilterGroupValue1copy, FilterGroupValue3copy, FilterGroupValue4copy, FilterGroupValue2copy,
      FilterCopyRow1, FilterCopyRow2, FilterCopyRow3, FilterCopyRow4,
    } = this.state;
    if (!isSelected) {
      switch (val) {
        case IS_CHANGE_1_TEXT:
          setFieldValue(fieldName, enterval);
          FilterGroupValue1.push(enterval, ...FilterGroupValue1copy);
          FilterCopyRow1.push(enterval, ...FilterGroupValue1copy);
          this.CallSortFun(val);
          this.setState({ selectedinx: '' });
          break;
        case IS_CHANGE_2_TEXT:
          setFieldValue(fieldName, enterval);
          FilterGroupValue2.push(enterval, ...FilterGroupValue2copy);
          FilterCopyRow2.push(enterval, ...FilterGroupValue2copy);
          this.CallSortFun(val);
          this.setState({ selectedinx2: '' });
          break;
        case IS_CHANGE_3_TEXT:
          setFieldValue(fieldName, enterval);
          FilterGroupValue3.push(enterval, ...FilterGroupValue3copy);
          FilterCopyRow3.push(enterval, ...FilterGroupValue3copy);

          this.CallSortFun(val);
          this.setState({ selectedinx3: '' });
          break;
        case IS_CHANGE_4_TEXT:
          setFieldValue(fieldName, enterval);
          FilterGroupValue4.push(enterval, ...FilterGroupValue4copy);
          FilterCopyRow4.push(enterval, ...FilterGroupValue4copy);

          this.CallSortFun(val);
          this.setState({ selectedinx4: '' });
          break;
        default:
          break;
      }
    }
  }

  loppfun(val, e) {
    const {
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
    } = this.state;
    const resultArr = [];
    const resultArr2 = [];
    const resultArr3 = [];
    const resultArr4 = [];
    const findUniqueVal = [];
    const findUniqueVal2 = [];
    const findUniqueVal3 = [];
    const findUniqueVal4 = [];

    //  // getting values on the basis of enter and delte
    switch (val) {
      case IS_CHANGE_1_TEXT:
        FilterGroupValue1.forEach((element) => {
          if (!findUniqueVal.includes(element)) {
            findUniqueVal.push(element);
          }
        });
        findUniqueVal.forEach((x) => {
          if (x && x.indexOf(e.target.value.toUpperCase()) !== -1) {
            resultArr.push(x);
          }
        });
        this.setState({
          FilterGroupValue1: resultArr,
        });
        break;
      case IS_CHANGE_2_TEXT:
        FilterGroupValue2.forEach((element) => {
          if (!findUniqueVal2.includes(element)) {
            findUniqueVal2.push(element);
          }
        });
        findUniqueVal2.forEach((x) => {
          if (x && x.indexOf(e.target.value.toUpperCase()) !== -1) {
            resultArr2.push(x);
          }
        });
        this.setState({
          FilterGroupValue2: resultArr2,
        });

        break;
      case IS_CHANGE_3_TEXT:
        FilterGroupValue3.forEach((element) => {
          if (!findUniqueVal3.includes(element)) {
            findUniqueVal3.push(element);
          }
        });
        findUniqueVal3.forEach((x) => {
          if (x && x.indexOf(e.target.value.toUpperCase()) !== -1) {
            resultArr3.push(x);
          }
        });
        this.setState({
          FilterGroupValue3: resultArr3,
        });
        break;
      case IS_CHANGE_4_TEXT:
        FilterGroupValue4.forEach((element) => {
          if (!findUniqueVal4.includes(element)) {
            findUniqueVal4.push(element);
          }
        });
        findUniqueVal4.forEach((x) => {
          if (x && x.indexOf(e.target.value.toUpperCase()) !== -1) {
            resultArr4.push(x);
          }
        });
        this.setState({
          FilterGroupValue4: resultArr4,
        });
        break;
      default:
        break;
    }
  }

  sortCheckfunc(val, e) {
    setTimeout(() => {
      if (e.target.value.length > 0) {
        this.loppfun(val, e);
      }
    }, 100);
  }

  onGroupValue1Change(e, index, val, fieldName, setFieldValue) {
    setFieldValue(fieldName, this.capitalizeFirstLetter(e.target.value));
    const {
      FilterGroupValue1copy, FilterGroupValue2copy, FilterGroupValue3copy, FilterGroupValue4copy,
      FilterCopyRow1, FilterCopyRow2, FilterCopyRow3, FilterCopyRow4,
    } = this.state;
    const newtarget = e.nativeEvent.inputType === 'insertText';
    const targetLength = e.nativeEvent.inputType === 'deleteContentBackward';
    const target = e.target.value === '';
    // getting values on the basis of enter and delte
    switch (val) {
      case IS_CHANGE_1_TEXT:
        this.setState({
          selectedinx: index, selectedinx2: '', selectedinx3: '', selectedinx4: '', filtervalues: this.capitalizeFirstLetter(e.target.value),
        });
        if (target) {
          this.setState({
            FilterGroupValue1: FilterCopyRow1,
          });
          setTimeout(() => {
            this.CallSortFun(val);
          }, 500);
        } else if (targetLength) {
          this.setState({
            FilterGroupValue1: FilterGroupValue1copy,
          });
          this.CallSortFun(val);
          this.sortCheckfunc(val, e);
        } else if (newtarget) {
          this.loppfun(val, e);
        }
        break;
      case IS_CHANGE_2_TEXT:
        this.setState({
          selectedinx2: index, selectedinx: '', selectedinx3: '', selectedinx4: '', filtervalues2: this.capitalizeFirstLetter(e.target.value),
        });
        if (target) {
          this.setState({
            FilterGroupValue2: FilterCopyRow2,
          });
          setTimeout(() => {
            this.CallSortFun(val);
          }, 500);
        } else if (targetLength) {
          this.setState({
            FilterGroupValue2: FilterGroupValue2copy,
          });
          this.CallSortFun(val);
          this.sortCheckfunc(val, e);
        } else if (newtarget) {
          this.loppfun(val, e);
        }
        break;
      case IS_CHANGE_3_TEXT:
        this.setState({
          selectedinx3: index, selectedinx: '', selectedinx2: '', selectedinx4: '', filtervalues3: this.capitalizeFirstLetter(e.target.value),
        });
        if (target) {
          this.setState({
            FilterGroupValue3: FilterCopyRow3,
          });
          setTimeout(() => {
            this.CallSortFun(val);
          }, 500);
        } else if (targetLength) {
          this.setState({
            FilterGroupValue3: FilterGroupValue3copy,
          });
          this.CallSortFun(val);
          this.sortCheckfunc(val, e);
        } else if (newtarget) {
          this.loppfun(val, e);
        }
        break;
      case IS_CHANGE_4_TEXT:
        this.setState({
          selectedinx4: index, selectedinx: '', selectedinx2: '', selectedinx3: '', filtervalues4: this.capitalizeFirstLetter(e.target.value),
        });
        if (target) {
          this.setState({
            FilterGroupValue4: FilterCopyRow4,
          });
          setTimeout(() => {
            this.CallSortFun(val);
          }, 500);
        } else if (targetLength) {
          this.setState({
            FilterGroupValue4: FilterGroupValue4copy,
          });
          this.CallSortFun(val);
          this.sortCheckfunc(val, e);
        } else if (newtarget) {
          this.loppfun(val, e);
        }
        break;
      default:
        break;
    }
  }

  removeDuplicates(arr, val, e) {
    const resultArr = [];
    const resultArr2 = [];
    const resultArr3 = [];
    const resultArr4 = [];
    switch (val) {
      case IS_CHANGE_1_TEXT:
        arr.forEach((x) => {
          if (x && x.indexOf(e.target.value) !== -1) {
            resultArr.push(x);
          }
        });
        this.setState({
          FilterGroupValue1: resultArr,
        });
        break;
      case IS_CHANGE_2_TEXT:
        arr.forEach((x) => {
          if (x && x.indexOf(e.target.value) !== -1) {
            resultArr2.push(x);
          }
        });
        this.setState({
          FilterGroupValue2: resultArr2,
        });
        break;
      case IS_CHANGE_3_TEXT:
        arr.forEach((x) => {
          if (x && x.indexOf(e.target.value) !== -1) {
            resultArr3.push(x);
          }
        });
        this.setState({
          FilterGroupValue3: resultArr3,
        });
        break;
      case IS_CHANGE_4_TEXT:
        arr.forEach((x) => {
          if (x && x.indexOf(e.target.value) !== -1) {
            resultArr4.push(x);
          }
        });
        this.setState({
          FilterGroupValue4: resultArr4,
        });
        break;
      default:
        break;
    }
  }

  async onClosing(e, index, val) {
    const {
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
      FilterCopyRow1, FilterCopyRow2, FilterCopyRow3, FilterCopyRow4,
    } = this.state;
    if (e) {
      e.stopPropagation();
    } else {
      this.setState({
        selectedinx: '',
        selectedinx2: '',
        selectedinx3: '',
        selectedinx4: '',
      });
      const { selectedProjectId } = this.props.portfolioData;
      this.getFilterData(selectedProjectId, INITIAL_PAGINATED_DATA);
      return;
    }
    switch (val) {
      case IS_CHANGE_1_TEXT:
        FilterGroupValue1.push(...FilterCopyRow1);
        await this.removeDuplicates(FilterGroupValue1, val, e);
        this.setState({
          selectedinx: index,
          selectedinx2: '',
          selectedinx3: '',
          selectedinx4: '',
          filtervalues: e.target.value,
          FilterGroupValue1copy: FilterGroupValue1,
          FilterCopyRow1,
        });
        this.CallSortFun(val);
        break;
      case IS_CHANGE_2_TEXT:
        FilterGroupValue2.push(...FilterCopyRow2);
        await this.removeDuplicates(FilterGroupValue2, val, e);
        this.setState({
          selectedinx2: index,
          selectedinx: '',
          selectedinx3: '',
          selectedinx4: '',
          filtervalues2: e.target.value,
          FilterGroupValue2copy: FilterGroupValue2,
          FilterCopyRow2,
        });
        this.CallSortFun(val);
        break;
      case IS_CHANGE_3_TEXT:
        FilterGroupValue3.push(...FilterCopyRow3);
        await this.removeDuplicates(FilterGroupValue3, val, e);
        this.setState({
          selectedinx3: index,
          selectedinx: '',
          selectedinx2: '',
          selectedinx4: '',
          filtervalues3: e.target.value,
          FilterGroupValue3copy: FilterGroupValue3,
          FilterCopyRow3,
        });
        this.CallSortFun(val);
        break;
      case IS_CHANGE_4_TEXT:
        FilterGroupValue4.push(...FilterCopyRow4);
        await this.removeDuplicates(FilterGroupValue4, val, e);
        this.setState({
          selectedinx4: index,
          selectedinx: '',
          selectedinx2: '',
          selectedinx3: '',
          filtervalues4: e.target.value,
          FilterGroupValue4copy: FilterGroupValue4,
          FilterCopyRow4,
        });
        this.CallSortFun(val);
        break;
      default:
        break;
    }
  }

  CallSortFun(val) {
    const {
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
    } = this.state;
    switch (val) {
      case IS_CHANGE_1_TEXT:
        if (FilterGroupValue1.length > 1) {
          const sortarry = FilterGroupValue1.filter((item,
            idx) => FilterGroupValue1.indexOf(item) === idx);
          this.setState({
            FilterGroupValue1: sortarry.sort(),
          });
        }
        break;
      case IS_CHANGE_2_TEXT:
        if (FilterGroupValue2.length > 1) {
          const sortarry = FilterGroupValue2.filter((item,
            idx) => FilterGroupValue2.indexOf(item) === idx);
          this.setState({
            FilterGroupValue2: sortarry.sort(),
          });
        }
        break;
      case IS_CHANGE_3_TEXT:
        if (FilterGroupValue3.length > 1) {
          const sortarry = FilterGroupValue3.filter((item,
            idx) => FilterGroupValue3.indexOf(item) === idx);
          this.setState({
            FilterGroupValue3: sortarry.sort(),
          });
        }
        break;
      case IS_CHANGE_4_TEXT:
        if (FilterGroupValue4.length > 1) {
          const sortarry = FilterGroupValue4.filter((item,
            idx) => FilterGroupValue4.indexOf(item) === idx);
          this.setState({
            FilterGroupValue4: sortarry.sort(),
          });
        }
        break;
      default:
        break;
    }
  }

  render() {
    const {
      isCreating, showDeleteModal, totalPages, currentPage, isLoaded, filterGroupModel,
      filtergroupname, startIndex, endIndex, showUserCsvModel, stakeholders,
      FilterGroupValue1, FilterGroupValue2, FilterGroupValue3, FilterGroupValue4,
    } = this.state;

    return (
      <React.Fragment>

        <div
          aria-hidden="true"
          className="modal custom-tags-modal a-modal fade add-stakeholder-modal stakeholder-modal-filters"
          id="filterGroupModal"
          role="dialog"
          data-backdrop="static"
        >
          <RenderIf showComponent={showDeleteModal}>
            <ClearConfirmation
              confirmationClick={this.clearFilters.bind(this)}
              cancelClear={this.cancelClear.bind(this)}
              filterGroupName={filtergroupname}
            />
          </RenderIf>
          <div className="modal-dialog " role="document">
            <div className="modal-content">
              <div className="a-modal-header p-0">
                <div className="a-flex-stretch d-flex align-items-center" style={{ width: '100%', justifyContent: 'space-between' }}>
                  <div className=" ">
                    <p className="m-0 text-muted"><small dangerouslySetInnerHTML={{
                      __html: filterGroupModel.projectName,
                    }}></small></p>
                    <strong className='assign-filters'>Assign filters to Topic Stakeholders</strong>
                  </div>
                  <div className="">
                    <a href="javascript:void(0)" onClick={() => {
                      this.setState({ showUserCsvModel: true });
                    }}
                      class={'add-text-button mt-3 a-btn a-btn-secondary'}>
                      <span class="Appkit4-icon icon-upload-light-outline"></span><span style={{ fontSize: 14, marginLeft: '10px' }}>Bulk Assign via CSV</span>
                    </a>
                  </div>
                </div>
                <span className="icon-wrapper">
                  <a className={isCreating ? 'disabled' : ''} onClick={() => this.cancelClick()}>
                    <i
                      aria-label="Close"
                      className="appkiticon icon-close-fill a-close-btn"
                      data-bs-dismiss="modal"
                    />
                  </a>
                </span>
              </div>
              <Spinner isLoading={!isLoaded} />
              <div className="app">
                {isLoaded
                  && (<Formik
                    initialValues={filterGroupModel}
                    onSubmit={(values, actions) => {
                      this.checkValidFilterGroupValue(values, actions);
                    }}
                    enableReinitialize={true}
                    render={({
                      values, errors, touched, setFieldValue,
                    }) => (
                      <Form>
                        <FieldArray
                          name="filterGroupModel"
                          render={() => (
                            <div>
                              <div className="r-s-body mt-3 a-table-scroller">
                                <table class="filter-stakeholder-table table">
                                  <thead>
                                    <tr>
                                      <td className="column">Name</td>
                                      <td className="column">Email</td>
                                      <td><div className="form-group">
                                        <Field
                                          name="filtergroupname1"
                                          placeholder="Filter group 1"
                                          autoComplete="off"
                                          className={
                                            errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                                          }
                                        />
                                        <i data-for='deleteIcon1' data-tip='tooltip' onClick={() => this.clearFilterGroup(setFieldValue, IS_GROUP_1_CLEAR, values.filtergroupname1)} class={`a-icon appkiticon icon-delete-fill ${values.filtergroupname1 ? '' : 'icon-fade'}`} icon-fade></i>
                                        <ReactTooltip id="deleteIcon1" className='custom-tag-group-tooltip' place='top'
                                          effect='solid'>
                                          clear all values for this filter group
                                        </ReactTooltip>

                                      </div>
                                        <div className="error float-none">
                                          {' '}
                                          <ErrorMessage name="filtergroupname1" />
                                        </div>
                                      </td>
                                      <td><div className="form-group">
                                        <Field
                                          name="filtergroupname2"
                                          placeholder="Filter group 2"
                                          autoFocus=''
                                          autoComplete="off"
                                          className='a-text-input'
                                        />
                                        <i data-for='deleteIcon2' data-tip='tooltip' onClick={() => this.clearFilterGroup(setFieldValue, IS_GROUP_2_CLEAR, values.filtergroupname2)} class={`a-icon appkiticon icon-delete-fill ${values.filtergroupname2 ? '' : 'icon-fade'}`}></i>
                                        <ReactTooltip id="deleteIcon2" className='custom-tag-group-tooltip' place='top'
                                          effect='solid'>
                                          clear all values for this filter group
                                        </ReactTooltip>
                                      </div>
                                        <div className="error float-none">
                                          {' '}
                                          <ErrorMessage name="filtergroupname2" />
                                        </div></td>
                                      <td><div className="form-group">
                                        <Field
                                          name="filtergroupname3"
                                          placeholder="Filter group 3"
                                          autoFocus=''
                                          autoComplete="off"
                                          className='a-text-input'
                                        /><i data-for='deleteIcon3' data-tip='tooltip' onClick={() => this.clearFilterGroup(setFieldValue, IS_GROUP_3_CLEAR, values.filtergroupname3)} class={`a-icon appkiticon icon-delete-fill ${values.filtergroupname3 ? '' : 'icon-fade'}`}></i>
                                        <ReactTooltip id="deleteIcon3" className='custom-tag-group-tooltip' place='top'
                                          effect='solid'>
                                          clear all values for this filter group
                                        </ReactTooltip>
                                      </div>
                                        <div className="error float-none">
                                          {' '}
                                          <ErrorMessage name="filtergroupname3" />
                                        </div></td>
                                      <td><div className="form-group">
                                        <Field
                                          name="filtergroupname4"
                                          placeholder="Filter group 4"
                                          autoFocus=''
                                          autoComplete="off"
                                          className='a-text-input'
                                        /><i data-for='deleteIcon4' data-tip='tooltip' onClick={() => this.clearFilterGroup(setFieldValue, IS_GROUP_4_CLEAR, values.filtergroupname4)} class={`a-icon appkiticon icon-delete-fill ${values.filtergroupname4 ? '' : 'icon-fade'}`}></i>
                                        <ReactTooltip id="deleteIcon4" className='custom-tag-group-tooltip' place='top'
                                          effect='solid'>
                                          clear all values for this filter group
                                        </ReactTooltip>

                                      </div>
                                        <div className="error float-none">
                                          {' '}
                                          <ErrorMessage name="filtergroupname4" />
                                        </div></td>
                                    </tr>
                                  </thead>
                                  <tbody ref={this.modalWrapperRef}>
                                    {values.stakeholders
                                      && values.stakeholders.map((item, index) => (
                                        <tr onClick={() => this.onClosing(null)}
                                          key={index} className={(index >= startIndex && index <= endIndex) ? null : 'hide-element'}>
                                          {item.is_user_registered
                                            && <td>{item.first_name} {item.last_name}</td>}
                                          {!item.is_user_registered
                                            && <td>{item.email_address}</td>}
                                          <td>{item.email_address}</td>
                                          <td><div className="form-group position-relative">
                                            <Field
                                              name={`stakeholders[${index}].filtergroupvalue1`}
                                              placeholder="Enter a value"
                                              autoFocus=''
                                              autoComplete="off"
                                              className='a-text-input'
                                              onChange={(e) => {
                                                this.onGroupValue1Change(
                                                  e, index, IS_CHANGE_1_TEXT, `stakeholders[${index}].filtergroupvalue1`, setFieldValue,
                                                );
                                              }}
                                              onClick={(e) => {
                                                this.onClosing(
                                                  e, index, IS_CHANGE_1_TEXT,
                                                );
                                              }}
                                              onKeyUp={(e) => {
                                                if (e.key === 'Tab') {
                                                  this.onClosing(
                                                    e, index, IS_CHANGE_1_TEXT,
                                                  );
                                                }
                                              }}
                                            />
                                            {this.state.selectedinx === index
                                              && <>
                                                <FilterDropDown value={this.state.filtervalues}
                                                  isSelected={false} changeText={IS_CHANGE_1_TEXT}
                                                  fieldName={`stakeholders[${index}].filtergroupvalue1`}
                                                  setFieldValue={setFieldValue}
                                                  enteredvalues={this.enteredvalues}
                                                  filterGroup={FilterGroupValue1}
                                                />
                                              </>
                                            }
                                          </div></td>
                                          <td><div className="form-group position-relative">
                                            <Field
                                              name={`stakeholders[${index}].filtergroupvalue2`}
                                              autoFocus=''
                                              placeholder="Enter a value"
                                              autoComplete="off"
                                              className='a-text-input'
                                              onChange={(e) => {
                                                this.onGroupValue1Change(
                                                  e, index, IS_CHANGE_2_TEXT, `stakeholders[${index}].filtergroupvalue2`, setFieldValue,
                                                );
                                              }}
                                              onClick={(e) => {
                                                this.onClosing(
                                                  e, index, IS_CHANGE_2_TEXT,
                                                );
                                              }}
                                              onKeyUp={(e) => {
                                                if (e.key === 'Tab') {
                                                  this.onClosing(
                                                    e, index, IS_CHANGE_2_TEXT,
                                                  );
                                                }
                                              }}
                                            />
                                            {this.state.selectedinx2 === index
                                              && <FilterDropDown value={this.state.filtervalues2}
                                                isSelected={false} changeText={IS_CHANGE_2_TEXT}
                                                fieldName={`stakeholders[${index}].filtergroupvalue2`}
                                                setFieldValue={setFieldValue}
                                                enteredvalues={this.enteredvalues}
                                                filterGroup={FilterGroupValue2}
                                              />
                                            }

                                          </div></td>
                                          <td><div className="form-group position-relative">
                                            <Field
                                              name={`stakeholders[${index}].filtergroupvalue3`}
                                              autoFocus=''
                                              placeholder="Enter a value"
                                              autoComplete="off"
                                              className='a-text-input'
                                              onChange={(e) => {
                                                this.onGroupValue1Change(
                                                  e, index, IS_CHANGE_3_TEXT, `stakeholders[${index}].filtergroupvalue3`, setFieldValue,
                                                );
                                              }}
                                              onClick={(e) => {
                                                this.onClosing(
                                                  e, index, IS_CHANGE_3_TEXT,
                                                );
                                              }}
                                              onKeyUp={(e) => {
                                                if (e.key === 'Tab') {
                                                  this.onClosing(
                                                    e, index, IS_CHANGE_3_TEXT,
                                                  );
                                                }
                                              }}
                                            />
                                            {this.state.selectedinx3 === index
                                              && <FilterDropDown value={this.state.filtervalues3}
                                                isSelected={false} changeText={IS_CHANGE_3_TEXT}
                                                fieldName={`stakeholders[${index}].filtergroupvalue3`}
                                                setFieldValue={setFieldValue}
                                                enteredvalues={this.enteredvalues}
                                                filterGroup={FilterGroupValue3}
                                              />
                                            }
                                          </div></td>
                                          <td><div className="form-group position-relative">
                                            <Field
                                              name={`stakeholders[${index}].filtergroupvalue4`}
                                              placeholder="Enter a value"
                                              autoFocus=''
                                              autoComplete="off"
                                              className='a-text-input'
                                              onChange={(e) => {
                                                this.onGroupValue1Change(
                                                  e, index, IS_CHANGE_4_TEXT, `stakeholders[${index}].filtergroupvalue4`, setFieldValue,
                                                );
                                              }}
                                              onClick={(e) => {
                                                this.onClosing(
                                                  e, index, IS_CHANGE_4_TEXT,
                                                );
                                              }}
                                              onKeyUp={(e) => {
                                                if (e.key === 'Tab') {
                                                  this.onClosing(
                                                    e, index, IS_CHANGE_4_TEXT,
                                                  );
                                                }
                                              }}
                                            />
                                            {this.state.selectedinx4 === index
                                              && <FilterDropDown value={this.state.filtervalues4}
                                                isSelected={false} changeText={IS_CHANGE_4_TEXT}
                                                fieldName={`stakeholders[${index}].filtergroupvalue4`}
                                                setFieldValue={setFieldValue}
                                                enteredvalues={this.enteredvalues}
                                                filterGroup={FilterGroupValue4}
                                              />
                                            }
                                          </div></td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>

                              </div>
                              <div class="modal-footer p-0">
                                <div class="col-md-12 col-sm-12 col-12 pr-0 mb-3 at-pagination">
                                  <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    nextPageEvent={this.nextPage.bind(this)}
                                    prevPageEvent={this.prevPage.bind(this)}
                                    goToPageEvent={this.goToPage.bind(this)}
                                    pageOnChange={this.pageOnChangeEvent.bind(this)}
                                  />
                                </div>
                                <button
                                  aria-label="Close"
                                  onClick={this.cancelClick}
                                  className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10"
                                >
                                  CANCEL
                                </button>
                                <SpinnerButton isLoading={isCreating} label="Save" />


                              </div>
                            </div>
                          )}
                        />
                      </Form>
                    )
                    }
                  />)}
              </div>
            </div>

          </div>
        </div>
        <RenderIf showComponent={showUserCsvModel}>
          <UserCSVFilterModel
            onImportCsvFormData={this.updateStackHolderFilterData}
            cancelClick={() => this.setState({
              showUserCsvModel: false,
            })}
            availableEmails={stakeholders.map(item => item.email_address)}
            updateGroupModel={this.updateGroupModel}
          />
        </RenderIf>
      </React.Fragment>);
  }
}

FilterGroupModel.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  onContinueButtonClick: PropTypes.func.isRequired,
  cancelClick: PropTypes.func.isRequired,
  csvColumns: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterGroupModel);
