import actionType from './actionType';

const initialState = {
  question: {},
  questionsList: [],
  questionCount: 0,
  selectedQuestions: [],
  selectAll: false,
  isQuestionAddedOrModified: false,
  isQuestionAdded: false,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.question.questionsList,
        questionCount: action.question.count,
        selectAll: false,
      };
    case actionType.TOGGLE_ALL_QUESTIONS_CHECKBOXES: {
      const questionsList = state.questionsList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAll;
        return obj;
      });
      return {
        ...state,
        selectAll: !state.selectAll,
        questionsList,
      };
    }
    case actionType.TOGGLE_QUESTIONS_CHECKBOX: {
      const questionsList = state.questionsList.map((item) => {
        const obj = item;
        if (obj.id === action.question.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAll = !(questionsList.filter(item => item.isChecked === false).length > 0);
      return {
        ...state,
        selectAll,
        questionsList,
      };
    }
    case actionType.CHANGE_ADDED_QUESTION_STATUS:
      return {
        ...state,
        isQuestionAdded: action.data,
      };
    case actionType.ADDED_QUESTION_STATUS:
      return {
        ...state,
        isQuestionAddedOrModified: action.data,
      };
    case actionType.NEW_ADDED_QUESTION_STATUS:
      return {
        ...state,
        isQuestionAdded: action.data,
      };
    default:
      return state;
  }
};
