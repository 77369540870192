import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

export default class StakeHolders extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleEvent = this.handleEvent.bind(this);
    this.truncate = this.truncate.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  // Prototype methods, Bind in Constructor (ES2015)
  handleEvent() {}

  // Class Properties (Stage 3 Proposal)
  handler = () => {
    this.setState();
  };

  truncate(s) {
    const n = s?.length;
    if (!s || s.length === 0) {
      return '';
    }
    if (n > 25) {
      const trimmedString = s.substring(0, 25);
      return `${trimmedString}...`;
    }

    return s;
  }

  render() {
    const { projectDetails, stackholderList } = this.props;
    stackholderList.sort((a, b) => a.email_address.localeCompare(b.email_address));
    return (
      <>
        <div className="card_bg" style={{ height: stackholderList.length === 0 ? '100%' : 'auto' }}>
          <span className="d-block mb-3 table_title preview-pane-font">Who will receive the questions?</span>
          {stackholderList.length === 0 && (
            <div className='message-0-stakeholder'>This topic does not yet have assigned stakeholders.</div>
          )}
          {stackholderList.length > 0
          && (<div className="preview-table-scroller question-table a-mb-10">
            <table className="preview-a-table data-table a-lg min-w-1024 sortable selectable setting-table preview-table w-100">
              <thead className="a-thead">
                <tr>
                  <th className="q-question-cell">{stackholderList && stackholderList.length ? 'Email' : 'Please add Stackholder'}</th>

                    <th className="q-polarity-cell">{projectDetails.filter_group_name_1 && <span className='stackholder-table-header preview-pane-font' data-for={'preview-lable-header1'} data-tip={''}>{this.truncate(projectDetails.filter_group_name_1)}</span>}</th>
                    <th className="q-polarity-cell">{projectDetails.filter_group_name_2 && <span className='stackholder-table-header preview-pane-font' data-for={'preview-lable-header2'} data-tip={''}>{this.truncate(projectDetails.filter_group_name_2)}</span>}</th>
                    <th className="q-polarity-cell">{projectDetails.filter_group_name_3 && <span className='stackholder-table-header preview-pane-font' data-for={'preview-lable-header3'} data-tip={''}>{this.truncate(projectDetails.filter_group_name_3)}</span>}</th>
                    <th className="q-polarity-cell">{projectDetails.filter_group_name_4 && <span className='stackholder-table-header preview-pane-font' data-for={'preview-lable-header4'} data-tip={''}>{this.truncate(projectDetails.filter_group_name_4)}</span>}</th>
                      <ReactTooltip id={'preview-lable-header1'} className={'tooltip'} effect='solid' place='top' backgroundColor='#6b6b6b'>
                        {projectDetails.filter_group_name_1}
                      </ReactTooltip>

                      <ReactTooltip id={'preview-lable-header2'} className={'tooltip'} effect='solid' place='top' backgroundColor='#6b6b6b'>
                        {projectDetails.filter_group_name_2}
                      </ReactTooltip>

                      <ReactTooltip id={'preview-lable-header3'} className={'tooltip'} effect='solid' place='top' backgroundColor='#6b6b6b'>
                        {projectDetails.filter_group_name_3}
                      </ReactTooltip>

                      <ReactTooltip id={'preview-lable-header4'} className={'tooltip'} effect='solid' place='top' backgroundColor='#6b6b6b'>
                        {projectDetails.filter_group_name_4}
                      </ReactTooltip>
                </tr>
              </thead>
              <tbody className="a-tbody">
                {stackholderList.map((stackholder, i) => (
                  <tr>
                    <td className="q-polarity-cell cursor-pointer" >
                      <span className="preview-pane-font" data-for={`preview-email-lable${i}`} data-tip={''}>{this.truncate(stackholder.email_address)}</span>
                      <ReactTooltip id={`preview-email-lable${i}`} className={'tooltip'} effect='solid' place='right' backgroundColor='#6b6b6b'>
                        {stackholder.email_address}
                      </ReactTooltip>
                    </td>
                    <td className="q-state-cell cursor-pointer" >
                      {projectDetails.filter_group_name_1 && <span className="stackholder-background preview-pane-font" data-for={`preview-filtergroupvalue1-lable${i}`} data-tip={''} >
                      {this.truncate(stackholder.filtergroupvalue1)}
                      </span>}
                      <ReactTooltip id={`preview-filtergroupvalue1-lable${i}`} className={'tooltip'} effect='solid' place='right' backgroundColor='#6b6b6b'>
                        {stackholder.filtergroupvalue1}
                      </ReactTooltip>
                    </td>
                    <td className="q-state-cell cursor-pointer" >
                      {projectDetails.filter_group_name_2 && <span className="stackholder-background" data-for={`preview-filtergroupvalue2-lable${i}`} data-tip={''}>
                      {this.truncate(stackholder.filtergroupvalue2)}
                      </span>}
                      <ReactTooltip id={`preview-filtergroupvalue2-lable${i}`} className={'tooltip'} effect='solid' place='right' backgroundColor='#6b6b6b'>
                        {stackholder.filtergroupvalue2}
                      </ReactTooltip>
                    </td>
                    <td className="q-state-cell cursor-pointer" >
                      {projectDetails.filter_group_name_3 && <span className="stackholder-background" data-for={`preview-filtergroupvalue3-lable${i}`} data-tip={''}>
                      {this.truncate(stackholder.filtergroupvalue3)}
                      </span>}
                      <ReactTooltip id={`preview-filtergroupvalue3-lable${i}`} className={'tooltip'} effect='solid' place='right' backgroundColor='#6b6b6b'>
                        {stackholder.filtergroupvalue3}
                      </ReactTooltip>
                    </td>
                    <td className="q-state-cell cursor-pointer" >
                      {projectDetails.filter_group_name_4 && <span className="stackholder-background" data-for={`preview-filtergroupvalue4-lable${i}`} data-tip={''}>
                      {this.truncate(stackholder.filtergroupvalue4)}
                      </span>}
                      <ReactTooltip id={`preview-filtergroupvalue4-lable${i}`} className={'tooltip'} effect='solid' place='right' backgroundColor='#6b6b6b'>
                        {stackholder.filtergroupvalue4}
                      </ReactTooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>)}
        </div>
      </>
    );
  }
}
