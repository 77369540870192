import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { updateShowPortfolio } from '../../../modules/portfolio/actions/portfolio';
import authService from '../../../redux/services/authService';

import RenderIf from '../../Common';
import ManagePortfolioModal from './ManagePortfolioModal.jsx';

export class PortfolioSetting extends Component {
  state = {
    isManagePortfolioModal: false,
    portfoliosData: [],
  };

  componentWillReceiveProps(props) {
    this.setState({
      portfoliosData: props.removeActivePortfoliosData.map(port => ({
        PortfolioTitle: port.items[0].PortfolioTitle,
        PortfolioId: port.PortfolioId,
        showDashboard: port.show_portfolio,
      })),
    });
  }

  cancelManagePortfolioModal = () => this.setState({ isManagePortfolioModal: false });

  openManagePortfolioModal = () => this.setState({ isManagePortfolioModal: true });

  onClickApply = (closeModal) => {
    const { user_id: userId } = authService.getUserInfo();
    const { portfoliosData = [] } = this.state;
    const { actualPortfolio = [] } = this.props;
    const allPortfolio = actualPortfolio.map((port) => {
      const newPort = { ...port };
      portfoliosData.forEach((item) => {
        if (item.PortfolioId === port.PortfolioId) {
          newPort.show_portfolio = item.showDashboard;
        }
      });
      return newPort;
    });
    const sendOnlyChangedPort = allPortfolio
      .filter(port => port.show_portfolio === false || port.user_manage_port_id != null);
    const payload = {
      portfolios: sendOnlyChangedPort,
      userId,
    };
    if (!sendOnlyChangedPort.length) {
      closeModal();
    } else {
      this.props.updateShowPortfolio(payload, allPortfolio).then(() => {
        closeModal();
      });
    }
  };


  render() {
    const { portfolioReplaceText } = this.props;
    const { isManagePortfolioModal } = this.state;

    return (
      <div className="port-sec">
        <span className="port-title">{portfolioReplaceText}</span>
        <span className="port-icon" onClick={this.openManagePortfolioModal}>
          <i
            className="appkiticon a-font-20 icon-setting-outline"
            data-for="port"
            data-tip=""
          />
        </span>
        <ReactTooltip
          id="port"
          place="bottom"
          effect="solid"
          className="tooltip"
        >
          Show/Hide {portfolioReplaceText}
        </ReactTooltip>
        <RenderIf showComponent={isManagePortfolioModal}>
          <ManagePortfolioModal
            cancelClick={this.cancelManagePortfolioModal.bind(this)}
            submitButtonLabel="SEND NOW"
            modalTitle={`Manage ${portfolioReplaceText}`}
            removeActivePortfoliosData={this.props.removeActivePortfoliosData}
            selectedPortfolio={this.props.selectedPortfolio}
            actualPortfolio={this.props.actualPortfolio}
            portfolioReplaceText={this.props.portfolioReplaceText}
          />
        </RenderIf>
      </div>
    );
  }
}

const mapDispatchToProps = {
  updateShowPortfolio,
};

export default connect(null, mapDispatchToProps)(PortfolioSetting);
