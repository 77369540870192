import React, { Component } from 'react';
import { connect } from 'react-redux';
import notify from '../../modules/common/notification/actions';

const mapStateToProps = state => ({
  notification: state.notification.notificationData,
});

const mapDispatchToProps = {
  hideError: notify.hideError,
};

class Notification extends Component {
  constructor(props) {
    super(props);
    this.hideError = this.hideError.bind(this);
  }

  hideError() {
    this.props.hideError();
  }

  render() {
    const { notification } = this.props;
    return (
      <div>
        {notification.showError && (
          <div className="a-notification a-transition-500ms negative a-notification-show">
            <span className="notification-content">
              <span className="text-left">
                {' '}
                Sorry, something went wrong when we were trying to save your changes. Please check
                your connection and try again.
              </span>
            </span>
            <span onClick={this.hideError} data-hide="a-notification" className="close-btn">
              <i className="appkiticon icon-close-fill" />
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
