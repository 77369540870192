import { createSelector } from 'reselect';

const QuestionModelSelector = state => state.questionModel;
const AddQuestionModelSelector = state => state.addQuestion;
const questionModelSelector = createSelector(
  [QuestionModelSelector],
  questionModel => questionModel,
);

const addQuestionModelSelector = createSelector(
  [AddQuestionModelSelector],
  addQuestionModel => addQuestionModel,
);

export { questionModelSelector, addQuestionModelSelector };
