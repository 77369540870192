const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const accountConstant = {
  requestType: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
  },
  httpStatus: {
    Ok: 200,
    NotAuthenticated: 401,
    Forbidden: 403,
    NotFound: 404,
  },
  authStatus: {
    OK: 'OK',
    IAM_AUTH_ERROR: 'AUTHENTICATION_ERROR',
    PERSPECTIVE_AUTH_ERROR: 'INVALID_USER_ERROR',
    MOBILE_VERIFICATION_ERROR: 'MOBILE_VERIFICATION_ERROR',
    REGISTER_MOBILE_ERROR: 'REGISTER_MOBILE_ERROR',
    SUCCESS: 'SUCCESS',
    USER_REGISTRATION_ERROR: 'USER_REGISTRATION_ERROR',
  },
  authMessage: {
    IAM_AUTH_FAIL: 'We were unable to sign you in using the information you provided.',
    USER_REGISTRATION_FAIL: 'Please complete the account registration process before signing in.',
    REGISTER_MOBILE_FAIL: 'An error occurred while registering phone number',
    PERSPECTIVE_AUTH_FAIL: 'Your Perspectives account does not currently have access to view results for an active topic. Please reach out to your PwC contact if you believe this is an error.',
  },
  deleteModel: {
    title: 'DELETE USER',
    message:
      'By continuing, you will lose access to all projects. Are you sure you want to continue?',
  },
  signInPage: '/sign-in',
  homePage: '/',
  gettingStartedPage: '/getting-started',
  SplashScreen: '/splash-screen',
  accountActivationPage: '/account-activation',
  userRestrictedPage: '/user-restricted',
  accountConfirmationPage: '/account-confirmation',
  mobileAppUrl: 'com.mobile.perspectives.pwc://signin',
  tokenContainer: 'auth-token',
  userContainer: 'user-container',
  emailContainer: 'email-container',
  signoutUserEmailContainer: 'signout-user-email-container',
  redirectUrl: 'redirectUrl',
  redirectUserEmail: 'redirectUserEmail',
  tokenType: 'Bearer',
  currentYear,
  logSignOutMessages: {
    inactivityThresholdMessage: 'Web app session expired due to inactivity threshold',
    maxSessionAgeMessage: 'Web app session expired due to maximum session duration',
    manualSignOutMessage: 'Web app session expired due to manual sign out',
    accountDeleteMessage: 'Web app session expired due to account deletion',
  },
  requestFor: 'dashboard',
  frontendAppMode: 1,
  questionnaireAppMode: 2,
};

export default accountConstant;
