const portfolioConstant = {
  registerEmailAdministratorModel: {
    title: 'Add #portfolioReplaceTextWithPreFix# Administrators',
    message:
      `#portfolioReplaceTextWithPreFix# Administrator will have full,
      unrestricted access to Topics in this #portfolioNameReplaceText#.
      Add #portfolioReplaceTextWithPreFix# Administrators by email address, seperated with comma.`,
    placeholder: 'admin1@example.com, admin2@example.com',
  },

  registerEmailStakeholderModel: {
    title: 'Add #portfolioReplaceText# Viewers',
    message: 'Add #portfolioReplaceText# Viewers by email address, seperated with comma.',
    placeholder: 'viewer1@example.com, viewer2@example.com',
  },

  UserNotFound: 'USER_NOT_FOUND',
  InvalidUser: 'INVALID_USER',
  InvalidRequestingUser: 'INVALID_REQUESTING_USER',
  Success: 'SUCCESS',
  UserAlreadyOnboarded: 'USER_ALREADY_ONBOARDED',
  type: 'portfolio',
  roleAdministrator: 'administrator',
  rolestakeholder: 'stakeholder',
  minProjectRowsLength: 1,
  maxProjectRowsLength: 2,
};

export default portfolioConstant;
