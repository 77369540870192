import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { login } from './modules/account';
import {
  general, domain, settings, attribute, question, user, questionModel, archiveQuestionModel,
} from './modules/settings';
import axiosReducer from './axios/axiosReducer';
import { notificationReducer } from './modules/common/notification';
import { portfolio } from './modules/portfolio';
import { project } from './modules/project';
import { brandwrapper } from './modules/brandwrapper';
import { processingReducer } from './modules/common/processing';
import { csrftokenwrapper } from './modules/csrftokenwrapper';
import { overlayReducer } from './modules/common/overlay';


// Combine all the application's reducers
// The key must be router for routing
const rootReducer = history => combineReducers({
  router: connectRouter(history),
  login,
  general,
  settings,
  axios: axiosReducer,
  notification: notificationReducer,
  domain,
  attribute,
  question,
  portfolio,
  user,
  project,
  questionModel,
  brandwrapper,
  processingReducer,
  csrftokenwrapper,
  overlay: overlayReducer,
  archiveQuestionModel,
});

export default rootReducer;
