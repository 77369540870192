import { createSelector } from 'reselect';

const projectSelector = state => state.project.projectData;

const portfolioSelector = state => state.project.portfolios;

const stagesSelector = state => state.project.stages;

const OpenTextQuestionSelector = state => state.project.openTextQuestions;

// Created selector project
const getProject = createSelector(
  [projectSelector],
  project => project,
);

// Created selector project
const getPortfolios = createSelector(
  [portfolioSelector],
  portfolios => portfolios.map(portfolio => ({ text: portfolio.title, value: portfolio.id })),
);


const getStages = createSelector(
  [stagesSelector],
  stages => stages.map(stage => ({ text: stage.title, value: stage.id })),
);

const openTextQuestionSelector = createSelector(
  [OpenTextQuestionSelector],
  question => question,
);

export {
  getProject, getPortfolios, getStages, openTextQuestionSelector,
};
