import actionType from './actionType';

const initialState = {
  domain: {},
  domainsList: [],
  domainsCount: 0,
  selectedDomains: [],
  selectAll: false,
  isDomainAddedOrModified: false,
  isDomainAdded: false,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_DOMAINS_LIST:
      return {
        ...state,
        domainsList: action.domains.domainsList,
        domainsCount: action.domains.count,
        selectAll: false,
      };
    case actionType.TOGGLE_ALL_CHECKBOXES: {
      const domainsList = state.domainsList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAll;
        return obj;
      });
      return {
        ...state,
        selectAll: !state.selectAll,
        domainsList,
      };
    }
    case actionType.TOGGLE_CHECKBOX: {
      const domainsList = state.domainsList.map((item) => {
        const obj = item;
        if (obj.id === action.domain.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAll = !(domainsList.filter(item => item.isChecked === false).length > 0);
      return {
        ...state,
        selectAll,
        domainsList,
      };
    }
    case actionType.ADDED_DOMAIN_STATUS:
      return {
        ...state,
        isDomainAddedOrModified: action.data,
      };
    case actionType.NEW_ADDED_DOMAIN_STATUS:
      return {
        ...state,
        isDomainAdded: action.data,
      };
    case actionType.CHANGE_ADDED_DOMAIN_STATUS:
      return {
        ...state,
        isDomainAdded: action.data,
      };
    default:
      return state;
  }
};
