import actionType from './actionType';

// Configured initial state for Brand
const initialState = {
  csrfToken: null,
};

// Creating project reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_CSRF_TOKEN:
      return {
        ...state,
        csrfToken: action.data,
      };
    default:
      return state;
  }
};
