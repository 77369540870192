import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import './Content.scss';
import { connect } from 'react-redux';
import $ from 'jquery';
import HomeRoutes from '../../pages/home/homeRoutes.jsx';
import AddPortfolio from '../../modules/portfolio/components/AddPortfolio.jsx';
import UserPhoneNumberUpdate from '../../modules/account/components/UserPhoneNumberUpdate';
import UserAccount from '../../modules/account/components/UserAccount';
import PortfolioStakeholders from '../../modules/portfolio/components/PortfolioStakeholders.jsx';
import AddProject from '../../modules/project/components/AddProject.jsx';
import ProjectStakeholders from '../../modules/project/components/ProjectStakeholders.jsx';
import PreviewCustomQuestions from '../../modules/project/components/PreviewCustomQuestions.jsx';
import {
  hidePortfolioRightPanel,
  hidePortfolioStakeholderRightPanel,
  showProjectRightPanel,
  hideProjectRightPanel,
  showProjectStakeholderRightPanel,
  hideProjectStakeholderRightPanel,
  showOpenTextRightPanel,
  hideOpenTextRightPanel,
  showOptionalCommentRightPanel,
  showOpenTextNonMatchRightPanel,
  hideOpenTextNonMatchRightPanel,
  hideOptionalCommentRightPanel,
  hideCustomQuestionsDetailRightPanel,
  showCustomQuestionsDetailRightPanel,
} from '../../modules/portfolio/actions/portfolio';
import {
  hideCustomQuestionRightPanel,
  openDomainFocusPanel,
  showOpenTextPanel,
  disableUnsavedChangesModalFocusDomain,
  disableUnsavedChangesFocusDomain,
  enableUnsavedChangesFocusDomain,
  enableUnsavedChangesModalFocusDomain,
  enableUnsavedChangesForCustomDomain,
  enableUnsavedChangesModalForCustomDomain,
  disableUnsavedChangesForCustomDomain,
  disableUnsavedChangesModalForCustomDomain,
} from '../../modules/project/actions/project';
import {
  showAccountRightPanel,
  hideChangePhoneNumberRightPanel,
} from '../../modules/account/actions/login';
import {
  enableUnsavedChangesModal, disableUnsavedChanges,
  disableUnsavedChangesModal,
} from '../../modules/general/actions/general';
import isLoggedInUserSystemAdministrator from './action';
import OpenTextResponsesPanel from '../../modules/project/components/OpenTextResponsesPanel.jsx';
import OpenTextNonMatchResponsesPanel from '../../modules/project/components/OpenTextNonMatchResponsePanel.jsx';
import OptionalCommentsRightPanel from '../../modules/project/components/OptionalCommentsRightPanel.jsx';
import CustomQuestionsResponsePanel from '../../modules/project/components/customQuestionsResponsePanel.jsx';
import { checkDeviceType } from '../../utils/helper';
import OverlayBackdrop from '../OverlayBackdrop/OverlayBackdrop';
import OpenTextDropQuesResponsePanel from '../../modules/project/components/OpenTextDropQuesResponsePanel.jsx';

const mapStateToProps = state => ({
  portfolioData: state.portfolio.portfolioData,
  portfolioRightPanel: state.portfolio.portfolioData.portfolioRightPanel,
  portfolioStakeholderRightPanel: state.portfolio.portfolioData.portfolioStakeholderRightPanel,
  projectRightPanel: state.portfolio.portfolioData.projectRightPanel,
  projectStakeholderRightPanel: state.portfolio.portfolioData.projectStakeholderRightPanel,
  openTextDropQuesRightPanel: state.portfolio.portfolioData.openTextDropQuesRightPanel,
  accountRightPanel: state.login.accountRightPanel,
  changePhoneNumberRightPanel: state.login.changePhoneNumberRightPanel,
  openTextRightPanel: state.portfolio.portfolioData.openTextRightPanel,
  optionalCommentRightPanel: state.portfolio.portfolioData.optionalCommentRightPanel,
  openTextNonMatchRightPanel: state.portfolio.portfolioData.openTextNonMatchRightPanel,
  customQuestionsDetailRightPanel: state.portfolio.portfolioData.customQuestionsDetailRightPanel,
  unsavedChanges: state.general.unsavedChanges,
  unsavedChangesModal: state.general.unsavedChangesModal,
  previewRightPannel: state.project.previewRightPannel,
  isOpenDomainFocusPanel: state.project.isOpenDomainFocusPanel,
  showOpenTextPanelBool: state.project.showOpenTextPanelBool,
  unsavedChangesFocusDomain: state.project.unsavedChangesFocusDomain,
  unsavedChangesModalFocusDomain: state.project.unsavedChangesModalFocusDomain,
  unsavedChangesCustomDomain: state.project.unsavedChangesCustomDomain,
  unsavedChangesModalCustomDomain: state.project.unsavedChangesModalCustomDomain,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
});

const mapDispatchToProps = {
  isLoggedInUserSystemAdministrator,
  hidePortfolioRightPanel,
  hidePortfolioStakeholderRightPanel,
  showProjectRightPanel,
  hideProjectRightPanel,
  showProjectStakeholderRightPanel,
  hideProjectStakeholderRightPanel,
  showAccountRightPanel,
  showOpenTextRightPanel,
  hideOptionalCommentRightPanel,
  hideOpenTextRightPanel,
  showOptionalCommentRightPanel,
  showOpenTextNonMatchRightPanel,
  hideOpenTextNonMatchRightPanel,
  hideChangePhoneNumberRightPanel,
  enableUnsavedChangesModal,
  disableUnsavedChanges,
  disableUnsavedChangesModal,
  hideCustomQuestionRightPanel,
  openDomainFocusPanel,
  showOpenTextPanel,
  disableUnsavedChangesModalFocusDomain,
  disableUnsavedChangesFocusDomain,
  enableUnsavedChangesFocusDomain,
  enableUnsavedChangesModalFocusDomain,
  showCustomQuestionsDetailRightPanel,
  hideCustomQuestionsDetailRightPanel,
  enableUnsavedChangesForCustomDomain,
  enableUnsavedChangesModalForCustomDomain,
  disableUnsavedChangesForCustomDomain,
  disableUnsavedChangesModalForCustomDomain,
};

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.setProjectWrapperRef = this.setProjectWrapperRef.bind(this);
    this.setAccountWrapperRef = this.setAccountWrapperRef.bind(this);
    this.setPortfolioWrapperRef = this.setPortfolioWrapperRef.bind(this);
    this.hidePortfolioRightPanel = this.hidePortfolioRightPanel.bind(this);
    this.setPortfolioStakeholderWrapperRef = this.setPortfolioStakeholderWrapperRef.bind(this);
    this.setOpenTextNonMatchWrapperRef = this.setOpenTextNonMatchWrapperRef.bind(this);
    this.hidePortfolioStakeholderRightPanel = this.hidePortfolioStakeholderRightPanel.bind(this);
    this.showProjectRightPanel = this.showProjectRightPanel.bind(this);
    this.hideProjectRightPanel = this.hideProjectRightPanel.bind(this);
    this.setProjectStakeholderWrapperRef = this.setProjectStakeholderWrapperRef.bind(this);
    this.hideProjectStakeholderRightPanel = this.hideProjectStakeholderRightPanel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showAccountRightPanel = this.showAccountRightPanel.bind(this);
    this.showOpenTextRightPanel = this.showOpenTextRightPanel.bind(this);
    this.showOptionalCommentRightPanel = this.showOptionalCommentRightPanel.bind(this);
    this.hideOpenTextRightPanel = this.hideOpenTextRightPanel.bind(this);
    this.hideOptionalCommentRightPanel = this.hideOptionalCommentRightPanel.bind(this);
    this.showOpenTextNonMatchRightPanel = this.showOpenTextNonMatchRightPanel.bind(this);
    this.hideOpenTextNonMatchRightPanel = this.hideOpenTextNonMatchRightPanel.bind(this);
    this.setOpenTextWrapperRef = this.setOpenTextWrapperRef.bind(this);
    this.setOptionalCommentWrapperRef = this.setOptionalCommentWrapperRef.bind(this);
    this.setCustomQuestionsWrapperRef = this.setCustomQuestionsWrapperRef.bind(this);
    this.setChangePhoneNumberWrapperRef = this.setChangePhoneNumberWrapperRef.bind(this);
    this.hideChangePhoneNumberRightPanel = this.hideChangePhoneNumberRightPanel.bind(this);
    this.setPreviewQuestionWrapperRef = this.setPreviewQuestionWrapperRef.bind(this);
    this.hideCustomQuestionRightPanel = this.hideCustomQuestionRightPanel.bind(this);
    this.hideCustomQuestionsDetailRightPanel = this.hideCustomQuestionsDetailRightPanel.bind(this);
    this.showCustomQuestionsDetailRightPanel = this.showCustomQuestionsDetailRightPanel.bind(this);
this.setOpenTextDropQuesResponseWrapperRef = this.setOpenTextDropQuesResponseWrapperRef.bind(this);
  }

  componentDidMount() {
    this.props.isLoggedInUserSystemAdministrator().then(() => {
      this.setState({ isLoading: false });
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setPortfolioWrapperRef(node) {
    this.portfolioWrapperRef = node;
  }

  setPortfolioStakeholderWrapperRef(node) {
    this.portfolioStakeholderWrapperRef = node;
  }

  setProjectWrapperRef(node) {
    this.projectWrapperRef = node;
  }

  setAccountWrapperRef(node) {
    this.accountWrapperRef = node;
  }

  setChangePhoneNumberWrapperRef(node) {
    this.changePhoneNumberRef = node;
  }

  setProjectStakeholderWrapperRef(node) {
    this.projectStakeholderWrapperRef = node;
  }

  setOpenTextWrapperRef(node) {
    this.openTextRef = node;
    if (node) {
      $('body').addClass('sidebar-opend');
    } else {
      $('body').removeClass('sidebar-opend');
    }
  }

  setOpenTextDropQuesResponseWrapperRef(node) {
    this.openTextDropQuesResponseRef = node;
    if (node) {
      $('body').addClass('sidebar-opend');
    } else {
      $('body').removeClass('sidebar-opend');
    }
  }


  setOptionalCommentWrapperRef(node) {
    this.optionalCommentRef = node;
    if (node) {
      $('body').addClass('sidebar-opend');
      $('body').addClass('optional-comment');
    } else {
      $('body').removeClass('sidebar-opend');
      $('body').removeClass('optional-comment');
    }
  }

  setCustomQuestionsWrapperRef(node) {
    this.customQuestionsRef = node;
    if (node) {
      $('body').addClass('sidebar-opend');
    } else {
      $('body').removeClass('sidebar-opend');
    }
  }

  setPreviewQuestionWrapperRef(node) {
    this.previewQuestionRef = node;
  }

  setOpenTextNonMatchWrapperRef(node) {
    this.openTextNonMatchRef = node;
    if (node) {
      $('body').addClass('sidebar-opend');
    } else {
      $('body').removeClass('sidebar-opend');
    }
  }

  handleClickOutside(event) {
    if (
      !this.isBootstrapModalOpen('deleteConfirmation')
      && !this.isBootstrapModalOpen('unsavedChangesConfirmation')
      && !this.isBootstrapModalOpen('registerEmailModal')
      && !this.isBootstrapModalOpen('filterGroupModal')
      && !this.isBootstrapModalOpen('registerEmailStep2Modal')
      && !this.isBootstrapModalOpen('customQuestionModal')
      && !this.isBootstrapModalOpen('clearConfirmation')
      && !this.isBootstrapModalOpen('previewWelcomeMessage')
      && !this.isBootstrapModalOpen('userCsvModal')
    ) {
      setTimeout(() => {
        if (this.props.showProcessingRecords) {
          event.preventDefault();
          event.stopPropagation();
          return;
        }


        if (this.previewQuestionRef && !this.previewQuestionRef.contains(event.target)) {
          this.hideCustomQuestionRightPanel();
        }

        if (this.changePhoneNumberRef && !this.changePhoneNumberRef.contains(event.target)) {
          this.props.hideChangePhoneNumberRightPanel();
        }

        if (this.optionalCommentRef && !this.optionalCommentRef.contains(event.target)) {
          this.hideOptionalCommentRightPanel();
        }

        if (this.openTextNonMatchRef && !this.openTextNonMatchRef.contains(event.target)) {
          this.hideOpenTextNonMatchRightPanel();
        }
        if (this.props.isOpenDomainFocusPanel && !this.props.showOpenTextPanelBool) {
          if (this.props.unsavedChangesFocusDomain && !this.props.unsavedChangesModalFocusDomain
            && !$.Event(event).target.closest('.customise-modal-wrap')
          ) {
            this.props.enableUnsavedChangesModalFocusDomain();
          } else if (!this.props.unsavedChangesModalFocusDomain
            && !$.Event(event).target.closest('.customise-modal-wrap')
          ) {
            this.props.disableUnsavedChangesFocusDomain();
            this.props.disableUnsavedChangesModalFocusDomain();
            $('.custom-btn').removeClass('active');
            this.props.openDomainFocusPanel(false);
          }
        }

        if (this.props.unsavedChangesCustomDomain) {
          if (!this.props.unsavedChangesModalCustomDomain && !$.Event(event).target.closest('.customise-domain-box-wrap')) {
            this.props.enableUnsavedChangesModalForCustomDomain();
          }
        }
      });
    }
  }

  isBootstrapModalOpen(modelId) {
    /* eslint-disable no-underscore-dangle */
    const isModelOpen = $(`#${modelId}`).hasClass('show');
    /* eslint-enable no-underscore-dangle */
    return isModelOpen;
  }

  hideProjectRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideProjectRightPanel();
    }
  }

  hidePortfolioRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hidePortfolioRightPanel();
    }
  }

  hidePortfolioStakeholderRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hidePortfolioStakeholderRightPanel();
    }
  }

  hideProjectStakeholderRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideProjectStakeholderRightPanel();
    }
  }

  showProjectRightPanel() {
    this.props.showProjectRightPanel();
  }

  showAccountRightPanel() {
    this.props.showAccountRightPanel();
  }

  showOpenTextRightPanel() {
    this.props.showOpenTextRightPanel();
  }

  showOptionalCommentRightPanel() {
    this.props.showOptionalCommentRightPanel();
  }

  showCustomQuestionsDetailRightPanel() {
    this.props.showCustomQuestionsDetailRightPanel();
  }

  hideOpenTextRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideOpenTextRightPanel();
    }
  }

  hideOptionalCommentRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideOptionalCommentRightPanel();
    }
  }

  hideCustomQuestionsDetailRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideCustomQuestionsDetailRightPanel();
    }
  }

  showOpenTextNonMatchRightPanel() {
    this.props.showOpenTextNonMatchRightPanel();
  }

  hideOpenTextNonMatchRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideOpenTextNonMatchRightPanel();
    }
  }

  hideChangePhoneNumberRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideChangePhoneNumberRightPanel();
      this.props.showAccountRightPanel();
    }
  }

  hideCustomQuestionRightPanel() {
    const isMobileDevice = checkDeviceType();
    if (!isMobileDevice) {
      this.props.hideCustomQuestionRightPanel();
    }
  }

  render() {
    const {
      portfolioRightPanel,
      portfolioStakeholderRightPanel,
      projectRightPanel,
      projectStakeholderRightPanel,
      accountRightPanel,
      openTextRightPanel,
      openTextNonMatchRightPanel,
      optionalCommentRightPanel,
      changePhoneNumberRightPanel,
      previewRightPannel,
      customQuestionsDetailRightPanel,
      openTextDropQuesRightPanel,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        <div className="content-container">
          {!isLoading
            && <div>
              <OverlayBackdrop />
              <div className="a-main-content">
                <HomeRoutes />
              </div>

              <CSSTransitionGroup
                transitionName="pwcsidebar"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
              >
                {portfolioRightPanel.showPanel && (
                  <AddPortfolio portfolioRef={this.setPortfolioWrapperRef} />
                )}

                {portfolioStakeholderRightPanel.showPanel && (
                  <PortfolioStakeholders
                    portfolioStakeholderRef={this.setPortfolioStakeholderWrapperRef}
                  />
                )}

              {projectRightPanel.showPanel && <AddProject projectRef={this.setProjectWrapperRef} />}

                {accountRightPanel.showPanel
                  && <UserAccount accountRef={this.setAccountWrapperRef} />
                }

                {changePhoneNumberRightPanel.showPanel
                  && <UserPhoneNumberUpdate
                    changePhoneNumberRef=
                    {this.setChangePhoneNumberWrapperRef} />
                }

                {projectStakeholderRightPanel.showPanel && (
                  <ProjectStakeholders
                    projectStakeholderRef={this.setProjectStakeholderWrapperRef}
                    selectedProject={projectStakeholderRightPanel.selectedProject} />
                )}

                {openTextRightPanel.showPanel
                  && <OpenTextResponsesPanel openTextRef={this.setOpenTextWrapperRef} />
                }

                {
                  // eslint-disable-next-line max-len
                  openTextDropQuesRightPanel.showPanel && <OpenTextDropQuesResponsePanel openTextDropQuesRef = {this.setOpenTextDropQuesResponseWrapperRef} />
                }

                {openTextNonMatchRightPanel.showPanel
                  && <OpenTextNonMatchResponsesPanel
                    openTextNonMatchRef={this.setOpenTextNonMatchWrapperRef} />
                }

                {optionalCommentRightPanel.showPanel
                  && <OptionalCommentsRightPanel
                    optionalCommentRef={this.setOptionalCommentWrapperRef} />
                }
                {previewRightPannel.showPanel
                  && <PreviewCustomQuestions
                    previewCustomQuestionRef={this.setPreviewQuestionWrapperRef} />
                }
                {customQuestionsDetailRightPanel.showPanel
                  && <CustomQuestionsResponsePanel
                    customQuestionsRef={this.setCustomQuestionsWrapperRef} />
                }
              </CSSTransitionGroup>
            </div>}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Content);
