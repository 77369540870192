import axios from 'axios';
import $ from 'jquery';
import {
  TITLE_EXIST,
  GET_USER_FILTERS_FOR_PROJECT,
  PROJECTS,
  ADD_PROJECT_STAKEHOLDER,
  GET_PROJECT_STAKEHOLDER,
  PROJECT_DASHBOARD,
  UPDATE_STAGE,
  OPEN_TEXT_RESPONSES,
  OPEN_TEXT_NON_MATCH_RESPONSES,
  PROJECT_DETAILS,
  GET_SELECTED_PORTFOLIO_ADMINS,
  GET_OPEN_TEXT_QUESTIONS,
  APPLY_USER_TAG_FILTERS,
  OPTIONAL_COMMENT_TEXT_RESPONSES,
  AUTOMATED_QUESTIONS,
  SEND_CUSTOM_QUESTION_DATA,
  SWAP_AUTOMATED_QUESTIONS,
  SAVE_OPEN_TEXT_TYPE_QUESTIONS_FOR_TOPIC,
  PROJECT_DOMAINS,
  SAVE_PROJECT_DOMAINS,
  CUSTOM_QUESTION_RESPONSE_DATA,
  UPDATE_CUSTOM_QUESTION_DATA,
  DELETE_CUSTOM_QUESTION_DATA,
  DELETE_SEGEMENT_DATA,
  IS_DROP_EXECUTABLE,
  SAVE_FILTER_GROUPS,
  GET_FILETR_STAKEHOLDER_COUNT,
  CREATE_NEW_SEGMENT,
  CHECK_FILTERS_EXISTS_IN_ALREADY_CREATED_SEGMENT,
  APPLY_USER_SEGMENT_FILTERS,
  GET_SEGMENTS_FOR_PROJECT,
  ASSIGN_SEGMENTS_TO_DOMAIN,
  LAUNCH_PROJECT_TO_FIRST_QUESTION_CYCLE,
  TESTED_QUESTION_FOR_USER,
  PROJECT_DASHBOARD_TOOLBAR,
  GET_SEGMENTS_STAKEHOLDER_COUNT,
  SEND_AGED_QUESTION_REMINDER_NOTIFICATION,
  RESEND_QUESTION_EMAIL_TO_STAKEHOLDER,
  GENERATE_REPORTING,
  PAUSE_RESUME_STAKEHOLDER,
  CANCEL_NEXT_ADHOC_DROP,
  OPEN_TEXT_DROP_QUES_RESPONSES,
  SUBMIT_USER_SUGGESTION,
  PROJECT_PREVIEW_PANE_DETAILS,
  GENERATE_EXCEL_REPORT,
  SUBMIT_USER_REQUEST_SUPPORT,
} from '../constants/project';

import constants from '../constants/settings';

class projectService {
  static getUserFilterGroupsForProject(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_USER_FILTERS_FOR_PROJECT}`,
        { params: data })
      .then(res => res)
      .catch(err => err);
  }

  static getUserTagFiltersData(projectId, portfolioId, selectedUserFilterValues, dates) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${APPLY_USER_TAG_FILTERS}`, {
        ProjectId: projectId,
        PortfolioId: portfolioId,
        selectedUserFilterValues,
        CustomDates: dates,
      })
      .then(res => res)
      .catch(err => err);
  }

  static getProjectDashboardDetails(projectId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PROJECT_DASHBOARD}${projectId}`)
      .then(res => res)
      .catch(err => err);
  }

  static getProjectDashboardToolbarDetails(projectId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PROJECT_DASHBOARD_TOOLBAR}${projectId}`)
      .then(res => res)
      .catch(err => err);
  }

  static getAutomatedQuestions(projectId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${AUTOMATED_QUESTIONS}`, { params: projectId })
      .then(res => res)
      .catch(err => err);
  }

  static swapAutomatedCustomQuestion(value) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${SWAP_AUTOMATED_QUESTIONS}`, { params: value })
      .then(res => res)
      .catch(err => err);
  }


  static saveProjectStage(projectModel) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${UPDATE_STAGE}`, projectModel)
      .then(res => res)
      .catch(err => err);
  }


  static saveProject(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${PROJECTS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static saveProjectDomains(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SAVE_PROJECT_DOMAINS}`, data)
      .then(res => res)
      .catch(err => err);
  }


  static sendCustomQuestionData(customQuestionsData) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SEND_CUSTOM_QUESTION_DATA}`, customQuestionsData)
      .then(res => res)
      .catch(err => err);
  }

  static updateProject(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${PROJECTS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static saveFilterGroups(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${SAVE_FILTER_GROUPS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static checkTitleExistence(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${TITLE_EXIST}`, { params: data })
      .then(res => res)
      .catch(err => err);
  }

  static getOpenTexts(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${OPEN_TEXT_RESPONSES}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getOpenTextsDropResp(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${OPEN_TEXT_DROP_QUES_RESPONSES}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getOptionalCommentTexts(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${OPTIONAL_COMMENT_TEXT_RESPONSES}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getCustomQuestionsData(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${CUSTOM_QUESTION_RESPONSE_DATA}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getNonMatchOpenTexts(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${OPEN_TEXT_NON_MATCH_RESPONSES}`, data)
      .then(res => res)
      .catch(err => err);
  }


  static getProjectDetails(projectdata) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PROJECT_DETAILS}`, { params: projectdata })
      .then(res => res)
      .catch(err => err);
  }

  static getProjectDomains(projectId, questionModelId) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${PROJECT_DOMAINS}`, { projectId, questionModelId })
      .then(res => res)
      .catch(err => err);
  }

  static deleteProject(projectId) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${PROJECTS}${projectId}`)
      .then(res => res)
      .catch(err => err);
  }

  // Administrator
  static getSelectedPortfolioAdmins(portfolioId) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${GET_SELECTED_PORTFOLIO_ADMINS}`, { portfolioId })
      .then(res => res)
      .catch(err => err);
  }

  // Stakeholders
  static getProjectStakeholders(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_PROJECT_STAKEHOLDER}${data.id ? data.id : 0}`)
      .then(res => res)
      .catch(err => err);
  }

  static getFilterStakeholdersCount(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${GET_FILETR_STAKEHOLDER_COUNT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // Stakeholders
  static addProjectStakeholder(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${ADD_PROJECT_STAKEHOLDER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static pauseOrResumeStakeholder(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${PAUSE_RESUME_STAKEHOLDER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static resendQuestionsEmailToStakeholder(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${RESEND_QUESTION_EMAIL_TO_STAKEHOLDER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // launch project for first question cycle
  static launchProjectToFirstQuestionCycle(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${LAUNCH_PROJECT_TO_FIRST_QUESTION_CYCLE}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static testedQuestionUserCycle(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${TESTED_QUESTION_FOR_USER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static cancelNextAdhocDrop(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${CANCEL_NEXT_ADHOC_DROP}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // Send aged question reminder notification to user
  static sendAgedQuestionReminderNotification(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SEND_AGED_QUESTION_REMINDER_NOTIFICATION}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getOpenTextQuestionsList(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${GET_OPEN_TEXT_QUESTIONS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static saveOpenTextTypeQuestionsForTopic(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SAVE_OPEN_TEXT_TYPE_QUESTIONS_FOR_TOPIC}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static saveTopicDomain(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${PROJECTS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static updateCustomQuestionData(customQuestionsData) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${UPDATE_CUSTOM_QUESTION_DATA}`, customQuestionsData)
      .then(res => res)
      .catch(err => err);
  }

  static deleteCustomQuestionData(customQuestionsData) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${DELETE_CUSTOM_QUESTION_DATA}`, customQuestionsData)
      .then(res => res)
      .catch(err => err);
  }

  static deleteSegementData(deleteSegements) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${DELETE_SEGEMENT_DATA}${deleteSegements}`)
      .then(res => res)
      .catch(err => err);
  }

  static checkIfDropShouldRunImmediately(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${IS_DROP_EXECUTABLE}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static deleteCustomDomain(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.DELETE_CUSTOM_DOMAIN}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // Create New Segment
  static createNewSegment(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${CREATE_NEW_SEGMENT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static checkFiltersExistsInAlreadyCreatedSegment(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${CHECK_FILTERS_EXISTS_IN_ALREADY_CREATED_SEGMENT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getUserSegmentFiltersData(projectId, portfolioId, selectedSegmentValue, dates) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${APPLY_USER_SEGMENT_FILTERS}`, {
        ProjectId: projectId,
        PortfolioId: portfolioId,
        selectedSegmentValue,
        CustomDates: dates,
      })
      .then(res => res)
      .catch(err => err);
  }

  static getProjectSegments(projectId, portfolioId, customDates) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${GET_SEGMENTS_FOR_PROJECT}`, {
        projectId,
        portfolioId,
        customDates,
      })
      .then(res => res)
      .catch(err => err);
  }

  static assignSegmentsToDomain(projectId, domainId, segments) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${ASSIGN_SEGMENTS_TO_DOMAIN}`, {
        projectId,
        domainId,
        segments,
      })
      .then(res => res)
      .catch(err => err);
  }

  static getSegmentsStakeholderCounts(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${GET_SEGMENTS_STAKEHOLDER_COUNT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static generateReportingPdf(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GENERATE_REPORTING}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
        params: data,
      })
      .then(res => res)
      .catch(err => err);
  }

  static generateExcelReport(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GENERATE_EXCEL_REPORT}`, {
        responseType: 'blob',
        headers: {
          Accept: 'application/xlsx',
        },
        params: data,
      })
      .then(res => res)
      .catch(err => err);
  }

  static submitUserSuggestionFeedback(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SUBMIT_USER_SUGGESTION}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getProjectPreviewPaneDetails(portfolioId, projectId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PROJECT_PREVIEW_PANE_DETAILS}${portfolioId}/${projectId}`)
      .then(res => res)
      .catch(err => err);
  }

  static submitUserRequestSupportFeedback(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${SUBMIT_USER_REQUEST_SUPPORT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static showAlert() {
    $('.success-alert-with-timer').removeClass('hide');
    $('.success-alert-with-timer').removeClass('a-alert-hide');
    $('.success-alert-with-timer').addClass('show');
  }

  static hideAlert() {
    $('.success-alert-with-timer').removeClass('show');
    $('.success-alert-with-timer').addClass('hide-element');
  }
}


export default projectService;
