import React, { Component } from 'react';
import * as Yup from 'yup';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import successSubmit from '../../../assets/images/component/success-submit.png';
import { submitUserSuggestionFeedback, submitUserRequestSupportFeedback } from '../../modules/project/actions/project';
import projectConstant from '../../modules/project/actions/constant';

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowCancelButton: false,
      isShowFeedbackButton: true,
      isShowFeedBackOptions: false,
      isShowSuggestionForm: false,
      isSubmitSuggestion: false,
      isDisabled: true,
      isLoading: false,
      feedBackForm: {
        describeIdea: '',
        suggestionBenefit: '',
        ideaDescriptionLength: 0,
        suggestionBenefitLength: 0,
        pageRef: '',
      },
      requestSupportForm: {
        howCanWeHelp: '',
        howCanWeHelpLength: 0,
        pageRef: '',
      },
      isShowRequestSupportForm: false,
      isSubmitRequestSupport: false,

    };
    this.showCloseButton = this.showCloseButton.bind(this);
    this.openSuggestionForm = this.openSuggestionForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
  }

  showCloseButton() {
    this.setState({
      isShowCancelButton: true,
      isShowFeedbackButton: false,
      isShowFeedBackOptions: true,
    });
  }

  openSuggestionForm() {
    this.setState({
      isShowSuggestionForm: true,
      isShowFeedBackOptions: false,
    });
  }

  openRequestSupportForm() {
    if (this.props.isComingFromBanner) {
      this.props.showCloseButton();
    }
    this.setState({
      isShowRequestSupportForm: true,
      isShowFeedBackOptions: false,
    });
  }

  closeForm() {
    if (this.props.isComingFromBanner) {
      this.props.closeForm();
      this.setState({ isShowFeedBackOptions: false });
    }
    this.setState({
      isShowCancelButton: false,
      isShowFeedbackButton: true,
      isShowSuggestionForm: false,
      isShowFeedBackOptions: false,
      isSubmitSuggestion: false,
      isShowRequestSupportForm: false,
      isSubmitRequestSupport: false,
    });
  }

  checkDescribeIdeaLength(e, setFieldTouched, setFieldError, setFieldValue) {
    setFieldValue('describeIdea', e.target.value);
    setFieldValue('ideaDescriptionLength', e.target.value.length);
    const errorMessage = 'Maximum character limit exceeded';
    if (e.target.value.length > 256) {
      setFieldTouched('describeIdea', true);
      window.setTimeout(() => {
        setFieldError('describeIdea', errorMessage);
      }, 100);
    }
  }

  checkSuggestionBenefitLength(e, setFieldTouched, setFieldError, setFieldValue) {
    setFieldValue('suggestionBenefit', e.target.value);
    setFieldValue('suggestionBenefitLength', e.target.value.length);
    const errorMessage = 'Maximum character limit exceeded';
    if (e.target.value.length > 500) {
      setFieldTouched('suggestionBenefit', true);
      window.setTimeout(() => {
        setFieldError('suggestionBenefit', errorMessage);
      }, 100);
    }
  }

  checkHowCanWeHelpLength(e, setFieldTouched, setFieldError, setFieldValue) {
    setFieldValue('howCanWeHelp', e.target.value);
    setFieldValue('howCanWeHelpLength', e.target.value.length);

    const errorMessage = 'Maximum character limit exceeded';
    if (e.target.value.length > 500) {
      setFieldTouched('howCanWeHelp', true);
      this.setState({ isDisabled: true });
      window.setTimeout(() => {
        setFieldError('howCanWeHelp', errorMessage);
      }, 100);
    } else {
      this.setState({
        isDisabled: !(e.target.value.length > 0),
      });
    }
  }

  onSubmitFeedback(formData) {
    formData.pageRef = this.props.pageRef;
    this.setState({ isLoading: true });
    submitUserSuggestionFeedback(formData).then((res) => {
      if (res.id) {
        this.setState({
          isSubmitSuggestion: true,
          isShowSuggestionForm: false,
          isLoading: false,
        });
      }
    });
  }

  onSubmitRequestSupportFeedback(formData) {
    formData.pageRef = this.props.pageRef;
    formData.selectedPortfolioTitle = this.props.selectedPortfolioTitle;
    formData.selectedProjectTitle = this.props.selectedProjectTitle;
    this.setState({ isLoading: true });
    submitUserRequestSupportFeedback(formData).then((res) => {
      if (res.id) {
        this.setState({
          isSubmitRequestSupport: true,
          isShowRequestSupportForm: false,
          isLoading: false,
          isDisabled: true,
        });
        if (this.props.isComingFromBanner) {
          this.props.closeRequestSupportForm();
        }
      }
    });
  }

  render() {
    const {
      isShowCancelButton,
      isShowFeedbackButton,
      isShowFeedBackOptions,
      isShowSuggestionForm,
      isSubmitSuggestion,
      feedBackForm,
      isLoading,
      isShowRequestSupportForm,
      requestSupportForm,
      isSubmitRequestSupport,
      isDisabled,
    } = this.state;
    const { isShowSupportBtn } = this.props;
    return (
      <div className='feedback-form'>
        {isSubmitSuggestion && !this.props.isComingFromBanner && <div className='success-msg'>
          <img width="150" src={successSubmit} alt="app-store" class="CToWUd"></img>
          <h2>Thank you for your feedback!</h2>
          <p>Your feedback helps to improve the Perspectives experience.</p>
          <div className="close-icon">
            <span>
              <a>
                <i
                  className="appkiticon icon-close-outline"
                  onClick={() => this.closeForm()}
                />
              </a>
            </span>
          </div>
        </div>}
        {isShowSuggestionForm && !this.props.isComingFromBanner && <Formik
          enableReinitialize
          initialValues={feedBackForm}
          onSubmit={(values, actions) => {
            this.onSubmitFeedback(values, actions);
          }}
          validationSchema={Yup.object().shape({
            describeIdea: Yup.string().max(256, 'Maximum character limit exceeded.').required('This field is required.'),
            suggestionBenefit: Yup.string().max(500, 'Maximum character limit exceeded.').required('This field is required.'),
          })}
        >
          {({
            values, setFieldTouched, setFieldError, setFieldValue,
          }) => <Form>
              <div className="form-fields">
                <span className='heading'>Suggest an Idea</span>
                <div className="form-group">
                  <Field
                    component='textarea'
                    name="describeIdea"
                    placeholder="Describe your idea"
                    autoComplete="off"
                    className='a-text-input'
                    onChange={
                      value => this.checkDescribeIdeaLength(
                        value, setFieldTouched, setFieldError, setFieldValue, values,
                      )}
                  />
                  <div className="feedback-error">
                    <ErrorMessage name="describeIdea" />
                  </div>
                  <span id={values.ideaDescriptionLength > 256
                    ? 'red' : ''}>
                    {values.ideaDescriptionLength}/256</span>
                </div>
                <div className="form-group">
                  <Field
                    component='textarea'
                    name="suggestionBenefit"
                    placeholder="How does it benefit you?"
                    autoComplete="off"
                    className='a-text-input'
                    onChange={
                      value => this.checkSuggestionBenefitLength(
                        value, setFieldTouched, setFieldError, setFieldValue, values,
                      )}
                  />
                  <div className="feedback-error">
                    <ErrorMessage name="suggestionBenefit" />
                  </div>
                  <span id={values.suggestionBenefitLength > 500
                    ? 'red' : ''}>
                    {values.suggestionBenefitLength}/500</span>
                </div>
                {!isLoading && <button className='a-btn a-btn-primary a-btn-lg custom-btn w-100'>
                  Submit
                </button>}
                {isLoading && <button disabled className={'a-btn a-btn-primary a-btn-lg custom-btn w-100 loader-button'}>
                  <i className='a-loading-one a-primary' />
                </button>}
              </div>
            </Form>}
        </Formik>
        }

        {isSubmitRequestSupport && <div className='success-msg'>
          <img width="150" src={successSubmit} alt="app-store" class="CToWUd"></img>
          <h2>Support request submitted!</h2>
          <p>A representative from our Perspectives team will be in touch with you soon.</p>
          <div className="close-icon">
            <span>
              <a>
                <i
                  className="appkiticon icon-close-outline"
                  onClick={() => this.closeForm()}
                />
              </a>
            </span>
          </div>
        </div>}
        {(isShowRequestSupportForm || this.props.isShowRequestSupportForm) && (
          <Formik
            enableReinitialize
            initialValues={requestSupportForm}
            onSubmit={(values, actions) => {
              this.onSubmitRequestSupportFeedback(values, actions);
            }}
            validationSchema={Yup.object().shape({
              howCanWeHelp: Yup.string().max(500, 'Maximum character limit exceeded.'),
            })}
          >
            {({
              values, setFieldTouched, setFieldError, setFieldValue,
            }) => <Form>
                <div className="form-fields">
                  <span className='heading'>Request support</span>
                  <div className="form-group">
                    <Field
                      component='textarea'
                      name="howCanWeHelp"
                      placeholder="How can we help?"
                      autoComplete="off"
                      className='a-text-input'
                      onChange={
                        value => this.checkHowCanWeHelpLength(
                          value, setFieldTouched, setFieldError, setFieldValue, values,
                        )}
                    />
                    <div className="feedback-error">
                      <ErrorMessage name="howCanWeHelp" />
                    </div>
                    <span id={values.howCanWeHelpLength > 500
                      ? 'red' : ''}>
                      {values.howCanWeHelpLength}/500</span>
                  </div>
                  {!isLoading && <button disabled={isDisabled || (values.howCanWeHelp.length === 0)} className='a-btn a-btn-primary a-btn-lg custom-btn w-100'>
                    Submit
                  </button>}
                  {isLoading && <button disabled className={'a-btn a-btn-primary a-btn-lg custom-btn w-100 loader-button'}>
                    <i className='a-loading-one a-primary' />
                  </button>}
                </div>
              </Form>}
          </Formik>
        )}

        {
          isShowFeedBackOptions && !this.props.isComingFromBanner && <div className="options">
            <button className='a-btn a-btn-lg cstm-align hover-black-btn new_cus_filter_btn w-100 mb-2'
              onClick={() => this.openSuggestionForm()}>
              Suggest an Idea
            </button>

            {isShowSupportBtn && <button className='a-btn a-btn-lg cstm-align hover-black-btn new_cus_filter_btn w-100 mb-2'
              onClick={() => this.openRequestSupportForm()}>
              Request support
            </button>}

            <button onClick={() => window.open(projectConstant.reportAnIssueFormUrl, '_blank', 'noreferrer')}
              className='a-btn a-btn-lg cstm-align hover-black-btn new_cus_filter_btn w-100'>
              Report an Issue
            </button>
          </div>
        }
        <div className='icon'>
          {(isShowFeedbackButton && this.props.isShowFeedbackButton) && <div className="open pill" onClick={() => this.showCloseButton()}>
            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2.18493 11.5866C2.18493 11.5866 2.54752 13.0566 0.960938 14.2541C0.960938 14.2541 3.11385 14.9335 4.96929 12.6947L4.51042 10.8438L2.18493 11.5866Z" fill="#FBF9FA" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9849 7.83466C11.9849 10.6019 9.40527 12.8451 6.22294 12.8451C3.04073 12.8451 0.460938 10.6019 0.460938 7.83466C0.460938 5.06745 3.04073 2.82422 6.22294 2.82422C9.40527 2.82422 11.9849 5.06745 11.9849 7.83466Z" fill="#FBF9FA" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47266 1.94145C5.47266 1.94145 11.4374 1.18335 12.9132 6.7028C12.9132 6.7028 13.3751 8.59343 11.9757 10.8628C11.9757 10.8628 13.2206 12.374 14.8501 11.6461C14.8501 11.6461 13.8048 10.9487 13.6511 9.40192C13.6511 9.40192 13.7837 9.3375 13.9869 9.20627C15.631 8.14431 16.3628 6.13397 15.8149 4.2723C15.5853 3.49242 15.1428 2.60833 14.2967 1.90621C14.2967 1.90621 12.4856 0.326167 10.2016 0.330499C10.0987 0.330727 9.99587 0.330727 9.89311 0.326053C9.30625 0.299601 6.87166 0.29618 5.47266 1.94145Z" fill="#FBF9FA" />
            </svg>
            <span className='close-feedback-form'>Send Feedback</span>
          </div>}
          {(isShowCancelButton || this.props.isShowCancelButton) && <div className='cancel pill' onClick={() => this.closeForm()}>
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.01086 5.9506L11.4545 0.511832L11.999 1.05581L6.55532 6.49458L11.9891 11.9235L11.4437 12.4684L6.00987 7.03954L0.544468 12.5L0 11.956L5.4654 6.49556L0.00986933 1.04496L0.555324 0.5L6.01086 5.9506Z" fill="white" />
            </svg>
            <span className='close-feedback-form'>Close Form</span>
          </div>}
        </div>
      </div >
    );
  }
}

export default Feedback;
