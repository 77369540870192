import { createSelector } from 'reselect';

const AttributeSelector = state => state.attribute;
const AddAttributeSelector = state => state.addAttribute;

const attributeSelector = createSelector(
  [AttributeSelector],
  attribute => attribute,
);

const addAttributeSelector = createSelector(
  [AddAttributeSelector],
  addAttribute => addAttribute,
);

export { attributeSelector, addAttributeSelector };
