import actionType from '../../../redux/modules/settings/actionType';

// Show Unsaved Changes Modal
export function enableUnsavedChangesModal() {
  return dispatch => dispatch({ type: actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL });
}
// Hide Unsaved Changes Modal
export function disableUnsavedChangesModal() {
  return dispatch => dispatch({ type: actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL });
}

// Enable Unsaved Changes
export function enableUnsavedChanges() {
  return dispatch => dispatch({ type: actionType.ENABLE_UNSAVED_CHANGES });
}
// Disable Unsaved Changes
export function disableUnsavedChanges() {
  return dispatch => dispatch({ type: actionType.DISABLE_UNSAVED_CHANGES });
}
