import React from 'react';
import { connect } from 'react-redux';
import './OverlayBackdrop.css';
import { showHideOverlay, updateUnsavedChanges, updatePhoneRightPanelFlag } from '../../modules/common/overlay/actions/overlay';
import {
    disableUnsavedChangesModal, disableUnsavedChanges,
    enableUnsavedChanges, enableUnsavedChangesModal,
  } from '../../modules/general/actions/general';
import {
    hidePortfolioRightPanel,
    hideProjectRightPanel,
    hidePortfolioStakeholderRightPanel,
    hideOptionalCommentRightPanel,
    hideCustomQuestionsDetailRightPanel,
    hideProjectStakeholderRightPanel,
    hideOpenTextNonMatchRightPanel,
    hideOpenTextRightPanel,
    hideOpenTextDropQuesRightPanel,
} from '../../modules/portfolio/actions/portfolio';
import { hideChangePhoneNumberRightPanel, showAccountRightPanel, hideAccountRightPanel } from '../../modules/account/actions/login';

// eslint-disable-next-line no-unused-vars
class OverlayBackdrop extends React.Component {
    handleClick = () => {
           if (this.props.unsavedChanges
             && !this.props.unsavedChangesModal
            && !this.props.isPhoneRightPanelActive) {
              this.props.enableUnsavedChangesModal();
            } else if (!this.props.unsavedChangesModal) {
              this.props.disableUnsavedChanges();
              this.props.disableUnsavedChangesModal();
              this.props.hidePortfolioRightPanel();
              this.props.hideProjectRightPanel();
              this.props.hidePortfolioStakeholderRightPanel();
              this.props.hideOptionalCommentRightPanel();
              this.props.hideCustomQuestionsDetailRightPanel();
              this.props.hideProjectStakeholderRightPanel();
              this.props.hideOpenTextNonMatchRightPanel();
              this.props.hideOpenTextRightPanel();
              this.props.hideOpenTextDropQuesRightPanel();
              this.props.showHideOverlay(false);
              this.props.hideAccountRightPanel();
            }
            if (this.props.isPhoneRightPanelActive) {
                this.props.hideChangePhoneNumberRightPanel();
                this.props.showHideOverlay(true);
                this.props.updatePhoneRightPanelFlag(false);
                this.props.showAccountRightPanel();
            } else {
                this.props.hideAccountRightPanel();
            }
    }

    render() {
        return (
            <div className="overlay-backdorp"
            style={{ display: this.props.showOverlay ? 'block' : 'none' }}
            onClick = {this.handleClick}
            ></div>
        );
    }
}


const mapStateToProps = state => ({
    showOverlay: state.overlay.showOverlay,
    formChanges: state.overlay.unsavedChanges,
    unsavedChangesModal: state.general.unsavedChangesModal,
    unsavedChanges: state.general.unsavedChanges,
    isPhoneRightPanelActive: state.overlay.isPhoneRightPanelActive,
});

const mapDispatchToProps = {
    showHideOverlay,
    updateUnsavedChanges,
    disableUnsavedChangesModal,
    disableUnsavedChanges,
    enableUnsavedChanges,
    enableUnsavedChangesModal,
    hidePortfolioRightPanel,
    hideProjectRightPanel,
    hidePortfolioStakeholderRightPanel,
    hideOptionalCommentRightPanel,
    hideCustomQuestionsDetailRightPanel,
    hideProjectStakeholderRightPanel,
    hideOpenTextNonMatchRightPanel,
    hideOpenTextRightPanel,
    hideChangePhoneNumberRightPanel,
    showAccountRightPanel,
    updatePhoneRightPanelFlag,
    hideAccountRightPanel,
    hideOpenTextDropQuesRightPanel,
};

export default connect(mapStateToProps, mapDispatchToProps)(OverlayBackdrop);
