import actionType from './actionType';


function getSelectedQuestionModel(data, selectedQuestionModel) {
  if (selectedQuestionModel > 0) {
    return selectedQuestionModel;
  }
  const filterdata = data.sort((a, b) => Number(b.showQuestionModal) - Number(a.showQuestionModal));
  return filterdata.length > 0 ? filterdata[0].id : 0;
}
const initialState = {
  questionModelData: {
    selectedQuestionModel: 0,
    selectedQuestionModelId: null,
    isSaved: false,
    questionModelRightPanel: { showPanel: false },
    questionModelsData: [],
    questionModelFirstPage: 'stage',
  },
};


export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_QUESTION_MODEL_LIST: {
      const selectedQuestionModel = getSelectedQuestionModel(
        action.modelData,
        state.questionModelData.selectedQuestionModel,
      );
      return {
        ...state,
        questionModelData: {
          ...state.questionModelData,
          questionModelsData: action.modelData,
          questionModelRightPanel: {
            ...state.questionModelData.questionModelRightPanel,
            showPanel: false,
          },
          selectedQuestionModel,
        },
      };
    }
    case actionType.CHANGE_QUESTION_MODELID:
      return {
        ...state,
        questionModelData: {
          ...state.questionModelData,
          selectedQuestionModel: action.questionModelId,
          questionModelFirstPage: action.page,
        },
      };
    case actionType.SHOW_QUESTION_MODEL_RIGHTPANEL:
      return {
        ...state,
        questionModelData: {
          ...state.questionModelData,
          questionModelRightPanel: {
            ...state.questionModelData.questionModelRightPanel,
            showPanel: true,
          },
        },
      };
    case actionType.HIDE_QUESTION_MODEL_RIGHTPANEL:
      return {
        ...state,
        questionModelData: {
          ...state.questionModelData,
          questionModelRightPanel: {
            ...state.questionModelData.questionModelRightPanel,
            showPanel: false,
          },
        },
      };
    default:
      return state;
  }
};
