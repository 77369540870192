import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import store from '../../App';
import { actionType as notificationActionType } from '../modules/common/notification';
import constant from '../../modules/account/actions/constant';
import authService from '../services/authService';
import accountService from '../services/accountService';

function handleUnAuthenticateResponse(response) {
  if (response) {
    const { status } = response;
    const { NotAuthenticated, NotFound, Forbidden } = constant.httpStatus;
    if (status === NotAuthenticated || status === Forbidden) {
      const { email, user_id: userId } = authService.getUserInfo();
      authService.setSignoutUserEmail(email);

      localStorage.setItem('isMaxSessionReached', true);
      accountService
        .logUserSignOut(userId, constant.logSignOutMessages.maxSessionAgeMessage)
        .then(() => {
          const rState = store.getState();
          const { brandwrapper } = rState;
          document.cookie = 'ppid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          // eslint-disable-next-line no-undef
          window.location = `${__CONFIG__.iamSignoutUri}${brandwrapper.redirectUrl}`;
        });
    } else if (status === NotFound) {
      store.dispatch({ type: notificationActionType.HIDE_NOTIFICATION });
    } else {
      store.dispatch({ type: notificationActionType.SHOW_NOTIFICATION });
    }
  }
}

function errorHandler(error) {
  handleUnAuthenticateResponse(error.response);

  return Promise.reject(error);
}

axios.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  return config;
}, errorHandler);

axios.interceptors.response.use(response => response, errorHandler);
