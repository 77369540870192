import actionType from './actionType';

const initialState = {
  usersList: {},
  usersCount: 0,
  selectAll: false,
  isUserAddedOrModified: false,
  isUserAbleToChangeMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_USERS_LIST: {
      return {
        ...state,
        usersList: action.users.usersList,
        usersCount: action.users.count,
        selectAll: false,
      };
    }
    case actionType.TOGGLE_ALL_USER_CHECKBOXES: {
      const usersList = state.usersList.map((item) => {
        const obj = item;
        obj.isChecked = !state.selectAll;
        return obj;
      });
      return {
        ...state,
        selectAll: !state.selectAll,
        usersList,
      };
    }
    case actionType.TOGGLE_USER_CHECKBOX: {
      const usersList = state.usersList.map((item) => {
        const obj = item;
        if (obj.id === action.user.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAll = !(usersList.filter(item => item.isChecked === false).length > 0);
      return {
        ...state,
        selectAll,
        usersList,
      };
    }
    case actionType.ADDED_USER_STATUS:
      return {
        ...state,
        isUserAddedOrModified: action.data,
      };
    case actionType.USER_ACCESSIBLE_ROLE:
      return {
        ...state,
        isUserAbleToChangeMode: action.data,
      };
    default:
      return state;
  }
};
