const projectConstant = {

  MINIMUM_NEW_QUESTIONS_DROP_FREQUENCY: 1,
  MAX_CUSTOM_ATTRIBUTES: 10,
  deleteModel: {
    title: 'ARCHIVE #projectReplaceText#',
    message:
    'By continuing, you will be archiving this topic. Results will no longer be accessible within Perspectives. Do you want to continue?',
  },
  projectBudget: [
    { text: 'No Selection', value: 0 },
    { text: 'Less than AUD$1M', value: 1 },
    { text: 'Between AUD$1M and AUD$3M', value: 2 },
    { text: 'Between AUD$3M and AUD$5M', value: 3 },
    { text: 'Between AUD$5M and AUD$7M', value: 4 },
    { text: 'Between AUD$7M and AUD$10M', value: 5 },
    { text: 'More than AUD$10M', value: 6 },
  ],
  registerEmailAdministratorModel: {
    title: 'Add #projectReplaceText# Administrator',
    message:
      `A #projectReplaceText# Administrator will have full, unrestricted access to this #projectNameReplaceText#.
      Add a #projectReplaceText# Administrator by email address, seperated with a comma.`,
    placeholder: 'admin1@example.com, admin2@example.com',
  },

  registerEmailViewerModel: {
    viewerModelTitle: 'Add a #projectReplaceText# Viewer',
    viewerModelMessage:
      `A #projectReplaceText# Viewer will have full access to view results, including open text responses and ability to operate filters.
      Add a #projectReplaceText# Viewer by email address, separated with comma.`,
    viewerModelPlaceholder: 'viewer1@example.com, viewer2@example.com',
  },

  registerEmailStakeholderModel: {
    title: 'Add #projectReplaceText# Stakeholders',
    message:
      `A #projectReplaceText# Stakeholder will receive feedback prompts for this #projectNameReplaceText#.
      Add #projectReplaceText# Stakeholders by email address, seperated with a comma.`,
    placeholder: 'stakeholder1@example.com, stakeholder2@example.com',
  },

  registerEmailStep2StakeholderModel: {
    title: 'Add a Stakeholder',
    message:
      `We can't find an user account for the email address you specified. 
        Create a new account for this person by specifying their first name and last name:`,
  },
  registerEmailTestTopic: {
    title: 'Test this Topic',
    message:
      `Add up to 5 email addresses, Separated by a comma. These users will receive the topic 
      test email and be able to preview the stakeholder experience in Perspectives.`,
    placeholder: 'viewer1@example.com, viewer2@example.com',

  },

  tooltipArea: {
    domain: 'Domain',
    attribute: 'Attribute',
  },
  UserNotFound: 'USER_NOT_FOUND',
  InvalidUser: 'INVALID_USER',
  InvalidRequestingUser: 'INVALID_REQUESTING_USER',
  Success: 'SUCCESS',
  UserAlreadyOnboarded: 'USER_ALREADY_ONBOARDED',
  type: 'project',
  roleAdministrator: 'administrator',
  rolestakeholder: 'stakeholder',
  timeConstant: [
    { text: '00:00 AM', value: '0' },
    { text: '01:00 AM', value: '1' },
    { text: '02:00 AM', value: '2' },
    { text: '03:00 AM', value: '3' },
    { text: '04:00 AM', value: '4' },
    { text: '05:00 AM', value: '5' },
    { text: '06:00 AM', value: '6' },
    { text: '07:00 AM', value: '7' },
    { text: '08:00 AM', value: '8' },
    { text: '9:00 AM', value: '9' },
    { text: '10:00 AM', value: '10' },
    { text: '11:00 AM', value: '11' },
    { text: '12:00 PM', value: '12' },
    { text: '01:00 PM', value: '13' },
    { text: '02:00 PM', value: '14' },
    { text: '03:00 PM', value: '15' },
    { text: '04:00 PM', value: '16' },
    { text: '05:00 PM', value: '17' },
    { text: '06:00 PM', value: '18' },
    { text: '07:00 PM', value: '19' },
    { text: '08:00 PM', value: '20' },
    { text: '09:00 PM', value: '21' },
    { text: '10:00 PM', value: '22' },
    { text: '11:00 PM', value: '23' },
  ],
  LastPeriodRange: 'Last Period',
  CurrentPeriodRange: 'Current Period',
  Last7Days: 'Last 7 Days',
  Last14Days: 'Last 14 Days',
  LastMonth: 'Last Month',
  Last3Month: 'Last 3 Months',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  NEXTBUSINESSDAY: 'next business day',
  reportAnIssueFormUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSfrctFFBNkycY-3q9o94fSB_GZNhZjdBVhu1ZXPJMXfdZkJaA/viewform?usp=sf_link',
  MINIMUMSEGMENTUSERS: 3,
};

export default projectConstant;
