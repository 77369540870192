import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import $ from 'jquery';
import Cryptr from 'cryptr';
import * as Yup from 'yup';

import { push } from 'connected-react-router';
import AddProjectLifeCycleStage from '../components/AddLifeCycleStage.jsx';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import {
  setHeading,
  getSystemTerminology,
  updateSystemTerminology,
  getProjectLifeCycleStagesList,
  deleteProjectLifeCycleStage,
  changeProjectLifeCycleStagesOrder,
  updateMeasuringMetric,
  addQuestionModelAuthors,
  getQuestionModelAuthors,
  deleteQuestionModelAuthor,
  updateAnonymityRule,
  setHeadingRightEndText,
  archiveQuestionModel,
} from '../actions/general';
import {
  getSystemTerminologySelector,
  getprojectLifeCycleStagesListSelector,
} from '../selectors/general';
import {
  getQuestionModels,
  changeSelectedQuestionModel,
} from '../actions/questionModel';
import 'jquery-ui/ui/disable-selection';
import 'jquery-ui/ui/widgets/sortable';
import { Spinner } from '../../../../ui-components/Loader';
import SumoSelect from '../../../../ui-components/SumoSelect/SumoSelect';
import RenderIf from '../../../../ui-components/Common';
import { registerEmailAuthorModel } from '../actions/levelconstants';
import { RegisterEmail } from '../../../../ui-components/Account';
import ArchiveAlertPopup from '../../../../ui-components/ArchiveAlertPopup/ArchiveAlertPopup.jsx';
import {
  showRecordProcessing,
  hideRecordProcessing,
} from '../../../project/actions/project';
import {
  validateUserByEmail,
} from '../../../account/actions/iam';
import chunkedPromises from '../../../../utils/utils';
import {
  disableUnsavedChangesModal, disableUnsavedChanges,
  enableUnsavedChanges, enableUnsavedChangesModal,
} from '../../../general/actions/general';
import { checkIfUserCanChangeSettings } from '../../../portfolio/actions/portfolio';
import Authorise from '../../../../Authorise';
import { STAKEHOLDER_CONSTANTS, FEEDBACK_PAGE_REF } from '../../../../Constants';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';
// import { levelOneOrganizations, levelTwoOrganizations } from '../actions/levelconstants';

const mapStateToProps = state => ({
  systemTerminology: getSystemTerminologySelector(state),
  projectLifeCycleStagesList: getprojectLifeCycleStagesListSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
  unsavedChangesModal: state.general.unsavedChangesModal,
  unsavedChanges: state.general.unsavedChanges,
  isSysAdmin: state.login.isSysAdmin,
  portfolioData: state.portfolio.portfolioData,
});

const mapDispatchToProps = {
  setHeading,
  getSystemTerminology,
  updateSystemTerminology,
  getProjectLifeCycleStagesList,
  deleteProjectLifeCycleStage,
  changeProjectLifeCycleStagesOrder,
  updateMeasuringMetric,
  changeSelectedQuestionModel,
  showRecordProcessing,
  hideRecordProcessing,
  validateUserByEmail,
  addQuestionModelAuthors,
  disableUnsavedChangesModal,
  disableUnsavedChanges,
  enableUnsavedChanges,
  enableUnsavedChangesModal,
  checkIfUserCanChangeSettings,
  setHeadingRightEndText,
  getQuestionModels,
  push,
};

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

function setError(setFieldError, setFieldTouched, fieldName, error) {
  setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    setFieldError(fieldName, error);
  }, 100);
}

function isValidateAnonymityValue(value, setFieldError, setFieldTouched) {
  return new Promise((resolve) => {
    const errorMessage = 'Left hand side value must be greater than or equal to right hand side value.';
    const minimumStakeholder = parseInt(value.minimumStakeholder, 10);
    const maximumStakeholder = parseInt(value.maximumStakeholder, 10);
    const minimumPercentage = parseInt(value.minimumStakeholderPercentage, 10);
    if (minimumStakeholder < STAKEHOLDER_CONSTANTS.MIN_STAKEHOLDER_VALUE) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', 'Minimum Stakeholder value should not be less than 3');
      $('#minimumStakeholder').addClass('active');
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(false);
    } else if (maximumStakeholder < minimumStakeholder && minimumPercentage === 0) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', errorMessage);
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholder').addClass('active');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(true);
    } else if (maximumStakeholder < minimumStakeholder
      && minimumPercentage !== 0
      && minimumPercentage <= 100) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', errorMessage);
      $('#minimumStakeholder').addClass('active');
      $('#minimumStakeholderPercentage').removeClass('active');
      resolve(false);
    } else if (maximumStakeholder < minimumStakeholder && minimumPercentage > 100) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', errorMessage);
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholder').addClass('active');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(true);
    } else if (maximumStakeholder && !minimumStakeholder && !minimumPercentage
      && minimumPercentage !== 0) {
      setError(setFieldError, setFieldTouched, 'maximumStakeholder', errorMessage);
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#maximumStakeholder').addClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (maximumStakeholder && !minimumStakeholder
      && minimumPercentage !== 0 && minimumPercentage <= 100) {
      setError(setFieldError, setFieldTouched, 'maximumStakeholder', errorMessage);
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#maximumStakeholder').addClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(false);
    } else if (maximumStakeholder && !minimumStakeholder
      && minimumPercentage !== 0 && minimumPercentage > 100) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (!maximumStakeholder && !minimumStakeholder && minimumPercentage === 0) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (!maximumStakeholder && minimumStakeholder && minimumPercentage === 0) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (maximumStakeholder && !minimumStakeholder && minimumPercentage === 0) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', errorMessage);
      $('#minimumStakeholderPercentage').addClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (maximumStakeholder < minimumStakeholder && !minimumPercentage) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', errorMessage);
      $('#minimumStakeholder').addClass('active');
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(true);
    } else if (!maximumStakeholder && minimumStakeholder && !minimumPercentage) {
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      resolve(true);
    } else if (!maximumStakeholder) {
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholderPercentage').removeClass('active');
      resolve(false);
    } else if (!minimumStakeholder) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholder', '');
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      $('#minimumStakeholderPercentage').removeClass('active');
      resolve(true);
    } else if (minimumPercentage === 0) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(true);
    } else if (minimumPercentage > 100) {
      setError(setFieldError, setFieldTouched, 'minimumStakeholderPercentage', 'Percentage value must be less than or equal to 100.');
      $('#minimumStakeholderPercentage').addClass('active');
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(false);
    } else if (!minimumPercentage) {
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
      resolve(false);
    } else {
      resolve(false);
      $('#minimumStakeholderPercentage').removeClass('active');
      $('#minimumStakeholder').removeClass('active');
      $('#maximumStakeholder').removeClass('active');
    }
  });
}

const messages = {
  archiveQuestionModelTitle: 'Archive Question Model',
  cannotArchiveQuestionModelMessage: `This question model has one or more associated
    topics and it cannot be archived.`,
  archivedQuestionModelMessage: `By continuing, you will be archiving this Question Model and
   all questions within will be locked and no longer editable. Do you wish to continue?`,
};

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: 'General Settings',
      animate: false,
      showAddProjectLifeCycleStage: false,
      showDelete: false,
      showAuthorDelete: false,
      showModelArchive: false,
      selectedProjectLifeCycleStageIdForDelete: 0,
      isProjectLifeCycleLoaded: false,
      isChangingLevel: false,
      deleteTitle: 'Delete a lifecycle stage',
      deleteConfirmationMessage: `Are you sure you want to delete this lifecycle stage? 
        Any projects that are currently assigned to this lifecycle stage will be reverted 
        back to the first lifecycle stage.`,
      deleteAuthorTitle: 'Remove this Question Model Author',
      deleteAuthorConfirmationMessage: 'Are you sure you want to remove this author from the Question Model?',
      spinner: { isLoading: true },
      measuring: '',
      showRegisterQuestionModelAuthorModel: false,
      stakeholders: [],
      stakeholdersToBeAdded: [],
      stakeholdersToBeRemoved: [],
      authors: [],
      selectedQuestionModelAuhtorId: 0,
      selectedQuestionModelId: 0,
      minimumStakeholder: 0,
      maximumStakeholder: 0,
      minimumStakeholderPercentage: 0,
      isAnyRestrictedPortfolio: false,
      setHeadingRightEndText: ' Archive question model',
      archiveQMTitle: '',
      archiveQMConfirmationMessage: '',
      archiveAlertPopupMessage: '',
      isModelAssociatedWithTopics: false,
      showArchiveAlertPopup: false,
      successfullArchivedMessage: '',
    };
    this.showAddProjectLifeCycleStage = this.showAddProjectLifeCycleStage.bind(this);
    this.hideAddProjectLifeCycleStage = this.hideAddProjectLifeCycleStage.bind(this);
    this.changeMeasuringMetric = this.changeMeasuringMetric.bind(this);
    this.changeLevelOne = this.changeLevelOne.bind(this);
    this.changeLevelTwo = this.changeLevelTwo.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteProjectLifeCycleStage = this.deleteProjectLifeCycleStage.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.confirmAuhtorDelete = this.confirmAuhtorDelete.bind(this);
    this.cancelAuthorDelete = this.cancelAuthorDelete.bind(this);
    this.deleteAuthor = this.deleteAuthor.bind(this);
    this.addArchiveQuestionModel = this.addArchiveQuestionModel.bind(this);
    this.cancelQMArchive = this.cancelQMArchive.bind(this);
  }

  componentDidMount() {
    this.props.setHeading(this.state.heading);
    this.props.getSystemTerminology();
    const questionModelId = this.props.match.params.id;
    if (questionModelId) {
      const decryptQuestionModelId = cryptr.decrypt(questionModelId);
      this.props.changeSelectedQuestionModel(decryptQuestionModelId, 'stage');
      this.getProjectLifeCycleStagesList(parseInt(decryptQuestionModelId, 10));
    } else {
      this.getProjectLifeCycleStagesList(this.props.questionModelId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.questionModelId !== prevProps.questionModelId) {
      this.getProjectLifeCycleStagesList(this.props.questionModelId);
    }
    this.enableSorting();
  }

  getProjectLifeCycleStagesList(questionModelId) {
    this.showLoadingSpinner(true);
    this.props.getProjectLifeCycleStagesList(questionModelId).then((res) => {
      if (res.data.measuringMetric.length > 0) {
        this.setState({
          measuring: res.data.measuringMetric[0].measuring,
          minimumStakeholder: res.data.measuringMetric[0].minimum_stakeholder,
          maximumStakeholder: res.data.measuringMetric[0].maximum_stakeholder,
          minimumStakeholderPercentage: res.data.measuringMetric[0].minimum_stakeholder_percentage,
          isModelAssociatedWithTopics: res.data.isModelAssociatedWithAnyTopic,
        });
      }
      this.setState({
        isProjectLifeCycleLoaded: true,
        showArchiveAlertPopup: false,
        showModelArchive: false,
      });
    });
    this.getquestionModelAuthor(questionModelId);
    this.props.checkIfUserCanChangeSettings();
  }

  getquestionModelAuthor(questionModelId) {
    getQuestionModelAuthors({ id: questionModelId }).then((res) => {
      const { response, isAnyRestrictedPortfolio } = res;
      this.setState({
        authors: response,
        isAnyRestrictedPortfolio,
        stakeholdersToBeAdded: [],
        stakeholdersToBeRemoved: [],
      });
      this.showLoadingSpinner(false);
    });
  }

  showLoadingSpinner(isLoading) {
    this.setState(state => ({
      ...state,
      spinner: {
        ...state.spinner,
        isLoading,
      },
    }));
  }

  enableSorting() {
    $('#project-lifecycle-stages').sortable({
      start: (event, ui) => this.onSortStart(ui, event, this),
      stop: (event, ui) => this.onSortStop(ui, event, this),
    });
    $('#project-lifecycle-stages').disableSelection();
  }

  onSortStart(ui) {
    ui.item.addClass('cursor-move');
  }

  onSortStop(ui) {
    ui.item.removeClass('cursor-move');

    const itemOrder = $('#project-lifecycle-stages').sortable('toArray');
    const sort = [];
    const projectLifeCycleStages = this.props.projectLifeCycleStagesList;

    itemOrder.map((item, index) => {
      const stageIndex = projectLifeCycleStages.findIndex(x => x.id === parseInt(item, 10));
      projectLifeCycleStages[stageIndex].sort_order = index + 1;

      sort.push({ id: item, sort_order: index + 1, questionModelId: this.props.questionModelId });
      return item;
    });
    $('#project-lifecycle-stages').sortable('destroy');
    const height = ui.item.parent()[0].offsetHeight;
    $('#project-lifecycle-stages').height(height);

    projectLifeCycleStages.sort((a, b) => a.sort_order - b.sort_order);
    this.props.changeProjectLifeCycleStagesOrder(sort, projectLifeCycleStages).then(() => {
      $('#project-lifecycle-stages').height('auto');
      this.setState({
        isProjectLifeCycleLoaded: true,
      });
    });
  }

  changeMeasuringMetric(value) {
    this.showSpinner(true);

    updateMeasuringMetric(this.props.questionModelId, value)
      .then(() => {
        this.showSpinner(false);
        this.setState({ measuring: value });
      });
  }

  changeLevelOne(value) {
    this.showSpinner(true);
    this.props
      .updateSystemTerminology(this.props.systemTerminology, 'level_one', value)
      .then(() => {
        this.showSpinner(false);
      });
  }

  changeLevelTwo(value) {
    this.showSpinner(true);
    this.props
      .updateSystemTerminology(this.props.systemTerminology, 'level_two', value)
      .then(() => {
        this.showSpinner(false);
      });
  }

  showSpinner(isLoading) {
    this.setState({ isChangingLevel: isLoading });
  }

  // Show Project Life Cycle Stages Fly-in pane
  showAddProjectLifeCycleStage() {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddProjectLifeCycleStage: true,
        animate: true,
        selectedProjectLifeCycleStage: {
          id: 0,
        },
      });
    }, 300);
  }

  // Hide Project Life Cycle Stages Fly-in pane
  hideAddProjectLifeCycleStage() {
    this.setState({
      showAddProjectLifeCycleStage: false,
      animate: false,
      selectedProjectLifeCycleStage: { sort_order: 1 },
    });
  }

  // Open Project Life Cycle Stages Fly-in pane with Existing Life Cycle Stage
  editProjectLifeCycleStage(projectLifeCycleStage) {
    this.setState({
      selectedProjectLifeCycleStage: projectLifeCycleStage,
      showAddProjectLifeCycleStage: true,
      animate: true,
    });
  }

  // Confirm Delete
  confirmDelete(projectLifeCycleStageId) {
    if (this.props.projectLifeCycleStagesList.length > 1) {
      this.setState({
        selectedProjectLifeCycleStageIdForDelete: projectLifeCycleStageId,
        showDelete: true,
      });
    }
  }

  // Confirm author Delete
  confirmAuhtorDelete(authorId) {
    if (this.state.authors.length > 0) {
      this.setState({
        selectedQuestionModelAuhtorId: authorId,
        showAuthorDelete: true,
      });
    }
  }

  cancelAuthorDelete() {
    this.setState({
      selectedQuestionModelAuhtorId: 0,
      showAuthorDelete: false,
    });
  }

  cancelDelete() {
    this.setState({
      selectedProjectLifeCycleStageIdForDelete: 0,
      showDelete: false,
    });
  }

  // Delete Project Life Cycle Stages
  deleteProjectLifeCycleStage() {
    return this.props
      .deleteProjectLifeCycleStage({
        id: this.state.selectedProjectLifeCycleStageIdForDelete,
        questionModelId: this.props.questionModelId,
      }, this.props.questionModelId)
      .then((response) => {
        this.setState({
          selectedProjectLifeCycleStageIdForDelete: 0,
        });
        return response;
      });
  }

  // Delete Question model author
  deleteAuthor() {
    return deleteQuestionModelAuthor({
      id: this.state.selectedQuestionModelAuhtorId,
      questionModelId: this.props.questionModelId,
    })
      .then(() => {
        this.setState({
          selectedQuestionModelAuhtorId: 0,
        });
        this.getquestionModelAuthor(this.props.questionModelId);
      });
  }

  toggleUnsavedChanges() {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChanges();
    }
  }

  openRegisterQuestionModelAuthorsModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterQuestionModelAuthorModel: true });
  }

  async registerPortfolioStakeholders(emails) {
    const formattedEmails = emails.map(email => email.toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewStakeholderToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...r);
    this.validateAndSave();
    setTimeout(() => {
      this.getquestionModelAuthor(this.props.questionModelId);
    }, 500);
    return response;
  }

  pushNewStakeholderToList(userData) {
    this.toggleUnsavedChanges();
    const { stakeholders, stakeholdersToBeAdded, stakeholdersToBeRemoved } = this.state;
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    const user = {
      id,
      first_name: FirstName,
      last_name: LastName,
      email_address: emailId.toLowerCase(),
      is_user_registered: isRegistered,
    };
    stakeholders.push(user);
    stakeholdersToBeAdded.push(user);
    const updateStakeholdersToBeRemoved = stakeholdersToBeRemoved
      .filter(stakeholder => stakeholder !== id);
    this.setState({
      stakeholders,
      stakeholdersToBeAdded,
      stakeholdersToBeRemoved: updateStakeholdersToBeRemoved,
    });
  }

  async validateAndSave() {
    this.props.disableUnsavedChanges();
    this.props.disableUnsavedChangesModal();
    const { stakeholdersToBeAdded, stakeholdersToBeRemoved } = this.state;
    this.props.showRecordProcessing(0, 0);
    if (stakeholdersToBeAdded.length > 0) {
      await chunkedPromises(stakeholdersToBeAdded, 10,
        async (c) => {
          const model = {
            stakeholdersToBeAdded: c,
            stakeholdersToBeRemoved,
            questionModelId: this.props.questionModelId,
          };
          await this.props.addQuestionModelAuthors(model).then((res) => {
            if (res > 0) {
              this.props.showRecordProcessing(c.length, res);
            }
          });
        }).then(() => {
          this.props.hideRecordProcessing();
        });
    } else {
      const model = {
        stakeholdersToBeAdded,
        stakeholdersToBeRemoved,
        questionModelId: this.props.questionModelId,
      };
      this.props.addQuestionModelAuthors(model);
      this.props.hideRecordProcessing();
    }
  }

  updateAnonimityRulesValue(value, setFieldError, setFieldTouched) {
    const minStakeholderValue = parseInt(value.minimumStakeholder, 10);
    const maxStakeholderValue = parseInt(value.maximumStakeholder, 10);
    const minStakeholderPercentage = parseInt(value.minimumStakeholderPercentage, 10);

    const anonymityData = {
      id: this.props.questionModelId,
      minimumStakeholder: minStakeholderValue
        && minStakeholderValue >= STAKEHOLDER_CONSTANTS.MIN_STAKEHOLDER_VALUE
        && minStakeholderValue <= maxStakeholderValue
        ? value.minimumStakeholder : this.state.minimumStakeholder,
      maximumStakeholder: maxStakeholderValue && minStakeholderValue <= maxStakeholderValue
        ? value.maximumStakeholder : this.state.maximumStakeholder,
      minimumStakeholderPercentage: minStakeholderPercentage
        && minStakeholderValue >= STAKEHOLDER_CONSTANTS.MIN_STAKEHOLDER_VALUE
        && minStakeholderPercentage <= STAKEHOLDER_CONSTANTS.STAKEHOLDER_MAX_PERCENTAGE
        ? value.minimumStakeholderPercentage : this.state.minimumStakeholderPercentage,
    };
    this.setState({
      minimumStakeholder: anonymityData.minimumStakeholder,
      maximumStakeholder: anonymityData.maximumStakeholder,
      minimumStakeholderPercentage: anonymityData.minimumStakeholderPercentage,
    });
    isValidateAnonymityValue(value, setFieldError, setFieldTouched).then((isExist) => {
      if (!isExist) {
        updateAnonymityRule(anonymityData)
          .then(() => {
            this.showSpinner(false);
          });
      } else {
        this.showSpinner(false);
      }
    });
  }

  canUserChangeSettings() {
    const { canChangeSettings } = this.props.portfolioData;
    let role = '';
    if (canChangeSettings) {
      role = 'questionModelAuthor';
    }
    return role;
  }

  addArchiveQuestionModel() {
    const data = {
      questionModelId: this.props.questionModelId,
      isArchive: true,
    };
    return archiveQuestionModel(data).then((resp) => {
      if (resp && resp.length > 0) {
        this.setState({ successfullArchivedMessage: `${resp[0].title} has been successfully archived.` });
        $('.a-alert').removeClass('a-alert-hide');
        $('.a-alert').addClass('show');
        setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        this.props.getQuestionModels().then((response) => {
          if (response.data && response.data.length) {
            this.props.changeSelectedQuestionModel(response.data[0].id, 'stage');
            this.props.push(`/settings/${cryptr.encrypt(response.data[0].id)}/stage`);
            this.getProjectLifeCycleStagesList(response.data[0].id);
            this.setState({ showModelArchive: false });
          }
        });
      }
    });
  }

  cancelQMArchive() {
    this.setState({ showModelArchive: false });
    return true;
  }

  alertPopupOkClick() {
    this.setState({
      showModelArchive: false,
      showArchiveAlertPopup: false,
    });
  }

  canArchivedQuestionModel() {
    const { isModelAssociatedWithTopics } = this.state;
    if (isModelAssociatedWithTopics) {
      this.setState({
        showArchiveAlertPopup: true,
        showModelArchive: false,
        archiveAlertPopupMessage: messages.cannotArchiveQuestionModelMessage,
        archiveQMTitle: messages.archiveQuestionModelTitle,
      });
    } else {
      this.setState({
        showArchiveAlertPopup: false,
        showModelArchive: true,
        archiveQMConfirmationMessage: messages.archivedQuestionModelMessage,
        archiveQMTitle: messages.archiveQuestionModelTitle,
      });
    }
  }

  render() {
    const {
      spinner, measuring, showRegisterQuestionModelAuthorModel, authors,
      minimumStakeholder, maximumStakeholder, minimumStakeholderPercentage,
      isAnyRestrictedPortfolio,
    } = this.state;
    const { projectLifeCycleStagesList, systemTerminology, isSysAdmin } = this.props;
    const measuringMetricModel = {
      measuring,
    };
    const anonymityRuleModel = {
      minimumStakeholder,
      maximumStakeholder,
      minimumStakeholderPercentage,
    };
    const { title, message, placeholder } = registerEmailAuthorModel;
    const replaceWithPrefix = text => text.replace(/#projectReplaceTextWithPreFix#/g,
      ('aeiou')
      + 'portfolioReplaceText');
    let newStakeHolderTitle = title.replace(/#portfolioReplaceText#/g, 'portfolioReplaceText');
    newStakeHolderTitle = replaceWithPrefix(newStakeHolderTitle);
    let newStakeHolderMessage = message.replace(/#portfolioReplaceText#/g, 'portfolioReplaceText');
    newStakeHolderMessage = replaceWithPrefix(newStakeHolderMessage);
    return (
      <div>
        <div className="row general-row">
          <Spinner isLoading={spinner.isLoading} />
          <RenderIf showComponent={!spinner.isLoading}>
            <Spinner isLoading={this.state.isChangingLevel} />
            <div className="col-md-12 col-sm-12 col-12 col-lg-12">
              <div className="col-md-6 col-sm-12 col-12 col-lg-3">
                <p className="section-title m-0 mb-1 mt-3">Measuring Metric </p>
                <p className="a-font-sm m-0">Select what are you measuring for this question model.</p>
                {measuring !== '' && <Formik
                  initialValues={measuringMetricModel}
                  enableReinitialize>
                  {({
                    setFieldValue, values,
                  }) => (
                    <Form>
                      <div className="settings-forms mt-4">
                        <div class="form-group">
                          <SumoSelect
                            name="measuring"
                            placeholder="Choose a metric"
                            selectedValues={values.measuring}
                            options={systemTerminology.project_measuring}
                            setFieldValue={setFieldValue}
                            onSumoSelectChange={this.changeMeasuringMetric}
                          />
                        </div>
                      </div>

                    </Form>
                  )}
                </Formik>}
              </div>
              <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                perform="settings"
                permit={() => (
                  <div className="col-md-12 col-sm-12 col-12 col-lg-12">
                    <p className="section-title m-0 mb-1 mt-3">Anonymity Rules</p>
                    <p className="a-font-sm m-0">Specify the minimum response threshold that needs to be met before results are displayed.</p>
                    <Formik
                      enableReinitialize={true}
                      initialValues={anonymityRuleModel}
                      validationSchema={Yup.object().shape({
                        maximumStakeholder: Yup.number()
                          .required('')
                          .min(Yup.ref('minimumStakeholder'), 'Left hand side value must be greater than or equal to right hand side value.'),
                        minimumStakeholder: Yup.number()
                          .required('')
                          .moreThan(3, 'Minimum Stakeholder value should not be less than 3')
                          .max(Yup.ref('maximumStakeholder'), 'Left hand side value must be greater than or equal to right hand side value.'),
                        minimumStakeholderPercentage: Yup.number().required('')
                          .min(1, 'Percentage value must be less than or equal to 100.')
                          .max(100, 'Percentage value must be less than or equal to 100.'),
                      })}
                    >
                      {({
                        setFieldValue, values, errors, touched, setFieldError, setFieldTouched,
                      }) => (
                        <Form>
                          <div className="settings-forms mt-4">
                            <div class="form-group">
                              <p className="custom-fg a-font-sm m-0 d-flex">For topics with less than
                                <div>
                                  <Field
                                    name="maximumStakeholder"
                                    autoComplete="off"
                                    type="number"
                                    className={
                                      errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                                    }
                                    onChange={(e) => {
                                      setFieldValue('maximumStakeholder', e.target.value);
                                      values.maximumStakeholder = e.target.value;
                                      this.updateAnonimityRulesValue(values,
                                        setFieldError,
                                        setFieldTouched);
                                    }}
                                  />
                                </div>
                                stakeholders, display results after at least
                                <div>
                                  <Field
                                    type="number"
                                    name="minimumStakeholder"
                                    autoComplete="off"
                                    className={
                                      errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                                    }
                                    onChange={(e) => {
                                      setFieldValue('minimumStakeholder', e.target.value);
                                      values.minimumStakeholder = e.target.value;
                                      this.updateAnonimityRulesValue(values,
                                        setFieldError,
                                        setFieldTouched);
                                    }}
                                  />
                                </div>
                                stakeholders have responded.
                              </p>
                              <p className="custom-fg a-font-sm m-0  d-flex">For topics with {values.maximumStakeholder} or more{' '}
                                stakeholders, display results after at least
                                <div>
                                  <span>
                                    <Field
                                      type="number"
                                      name="minimumStakeholderPercentage"
                                      autoComplete="off"
                                      className={
                                        errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                                      }
                                      onChange={(e) => {
                                        setFieldValue('minimumStakeholderPercentage', e.target.value);
                                        values.minimumStakeholderPercentage = e.target.value;
                                        this.updateAnonimityRulesValue(values,
                                          setFieldError,
                                          setFieldTouched);
                                      }}
                                    />
                                    <i className="preview-pane-font">%</i></span>
                                </div>
                                stakeholders have responded.
                              </p>

                            </div>
                            <div id="maximumStakeholder" className="error error-bg">
                              {' '}
                              <ErrorMessage name="maximumStakeholder" />
                            </div>
                            <div id="minimumStakeholder" className="error error-bg">
                              {' '}
                              <ErrorMessage name="minimumStakeholder" />
                            </div>
                            <div id="minimumStakeholderPercentage" className="error error-bg">
                              {' '}
                              <ErrorMessage name="minimumStakeholderPercentage" />
                            </div>
                          </div>

                        </Form>
                      )}
                    </Formik>
                  </div>)} />
              <div className="col-md-6 col-sm-12 col-12 col-lg-3">
                <p className="section-title m-0 mb-1 mt-5">Lifecycle Stages</p>
                <p className="a-font-sm m-0 mb-3">
                  Drag items in the list below to change their order, click the 'X' to remove a
                  lifecycle stage or the edit icon to change the title of an existing lifecycle
                  stage. Click the add button to create a new lifecycle stage.{' '}
                </p>
                <ul
                  className="a-list hover-effect drag-list lifestyle-stages"
                  id="project-lifecycle-stages"
                >
                  {this.state.isProjectLifeCycleLoaded === true
                    && projectLifeCycleStagesList.length > 0
                    && projectLifeCycleStagesList.map((item, index) => (
                      <li
                        id={item.id}
                        className="a-list-item d-flex align-items-center justify-content-between cursor-pointer"
                        key={index}
                      >
                        <div
                          className="a-font-md font-weight-medium"
                          onClick={() => this.editProjectLifeCycleStage(item)}
                        >
                          {item.title}
                        </div>
                        {projectLifeCycleStagesList.length > 1 ? (
                          <i
                            className="appkiticon icon-close-fill a-font-xs"
                            onClick={() => this.confirmDelete(item.id)}
                          />
                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                </ul>
                {projectLifeCycleStagesList.length < 8 ? (
                  <a
                    href="javascript:void(0)"
                    className="add-text-button mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray"
                    onClick={this.showAddProjectLifeCycleStage}
                  >
                    <i className="appkiticon icon-plus-fill mr-2"> </i> Create a new stage
                  </a>
                ) : (
                  ''
                )}
                {this.state.showDelete ? (
                  <DeleteConfirmation
                    title={this.state.deleteTitle}
                    message={this.state.deleteConfirmationMessage}
                    confirmationClick={this.deleteProjectLifeCycleStage}
                    cancelClick={this.cancelDelete}
                  />
                ) : (
                  ''
                )}
                <CSSTransitionGroup
                  transitionName="pwcsidebar"
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={300}
                >
                  {this.state.showAddProjectLifeCycleStage ? (
                    <AddProjectLifeCycleStage
                      selectedProjectLifeCycleStage={this.state.selectedProjectLifeCycleStage}
                      sortOrderForNew={projectLifeCycleStagesList.length + 1}
                      hideAddProjectLifeCycleStage={this.hideAddProjectLifeCycleStage.bind(this)}
                    />
                  ) : (
                    ''
                  )}
                </CSSTransitionGroup>
                <p className="section-title m-0 mb-1 mt-5">Question Model Authors</p>
                <p className="a-font-sm m-0 mb-3">
                  Specify the users who will have full and unrestricted access to this
                  question model, including the ability to create, modify and delete
                  its associated focus areas, attributes, questions and life cycle stages.
                  There must be at least one Question Model Author.{' '}
                </p>
                <ul
                  className="a-list hover-effect drag-list lifestyle-stages"
                  id="project-lifecycle-stages"
                >
                  {this.state.isProjectLifeCycleLoaded === true
                    && authors.map((item, index) => (
                      <li
                        id={item.id}
                        className="a-list-item d-flex align-items-center justify-content-between cursor-pointer"
                        key={index}
                      >
                        {!item.is_user_registered && <div
                          className="a-font-md font-weight-medium"
                        >
                          {item.email_address}
                        </div>}
                        {item.is_user_registered && <div
                          className="a-font-md font-weight-medium"
                        >
                          {item.first_name} {item.last_name}
                        </div>}
                        {authors.length > 1 ? (
                          <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                            perform="settings"
                            permit={() => (
                              <span className="link-icon ml-3">
                                <i
                                  className="appkiticon icon-close-fill a-font-xs"
                                  onClick={() => this.confirmAuhtorDelete(item.author_id)}
                                />
                              </span>)} />

                        ) : (
                          ''
                        )}
                      </li>
                    ))}
                </ul>

                <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                  perform="settings"
                  permit={() => (
                    <a
                      href="javascript:void(0)"
                      className="add-text-button mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray"
                      onClick={this.openRegisterQuestionModelAuthorsModel.bind(this)}
                    >
                      <i className="appkiticon icon-plus-fill mr-2"> </i> Add Question Model Authors
                    </a>)} />

                {this.state.showAuthorDelete ? (
                  <DeleteConfirmation
                    title={this.state.deleteAuthorTitle}
                    message={this.state.deleteAuthorConfirmationMessage}
                    confirmationClick={this.deleteAuthor}
                    cancelClick={this.cancelAuthorDelete}
                  />
                ) : (
                  ''
                )}
              </div>
              <RenderIf showComponent={this.props.isSysAdmin}>
                <div className="col-md-6 col-sm-12 col-12 col-lg-3">
                  <p className="section-title m-0 mb-1 mt-4">Archive</p>
                  <p className="float-left"><a className='archive-model a-btn a-btn-transparent a-btn-gray' href='javascript:void(0)' onClick={() => this.canArchivedQuestionModel()}>
                    <span class="Appkit4-icon icon-delete-outline mr-2" style={{ color: '#eb590b' }}></span> Archive question model</a></p>
                  {
                    this.state.showArchiveAlertPopup ? <ArchiveAlertPopup
                      title={this.state.archiveQMTitle}
                      message={this.state.archiveAlertPopupMessage}
                      alertPopupOkClick={() => this.alertPopupOkClick().bind(this)} />
                      : ''
                  }
                  {
                    this.state.showModelArchive ? <DeleteConfirmation
                      title={this.state.archiveQMTitle}
                      message={this.state.archiveQMConfirmationMessage}
                      confirmationClick={this.addArchiveQuestionModel}
                      cancelClick={this.cancelQMArchive}
                      label='Archive'
                    />
                      : ''
                  }
                </div>
              </RenderIf>
            </div>
          </RenderIf >
          <RenderIf showComponent={showRegisterQuestionModelAuthorModel}>
            <RegisterEmail
              title={newStakeHolderTitle}
              message={newStakeHolderMessage}
              placeholder={placeholder}
              availableEmails={authors.map(item => item.email_address)}
              onContinueButtonClick={this.registerPortfolioStakeholders.bind(this)}
              cancelClick={() => this.setState({
                showRegisterQuestionModelAuthorModel: false,
              })}
              isRestrictedUser={isAnyRestrictedPortfolio}
              isStakeholders={false}
              isTestEmail={false}
            />
          </RenderIf>
          <div className="alert alert-success success-alert-with-timer  a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success"
            style={{
              left: '50%',
              top: '100px',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              padding: 0,
            }}
            data-hide="10">
            <div className="a-alert-content">
              <div className="a-alert-badge success">
                <span className="appkiticon icon-confirmed-fill me-0"></span>
              </div>
              <span className="a-content me-0 preview-pane-font" style={{ flexBasis: 'unset' }}>
                {this.state.successfullArchivedMessage}
              </span>
              <div className="">
                <svg width="23" height="23" class="chart">
                  <circle r="12.5" cx="12.5" cy="12.5" class="pie" />
                </svg>
              </div>
            </div>
          </div>
        </div >
        <Feedback pageRef={FEEDBACK_PAGE_REF.GENERAL_SETTINGS} isShowSupportBtn={false} />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(General);
