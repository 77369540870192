import React from 'react';
import ReactTooltip from 'react-tooltip';

const GoBack = () => (
    <span
    id="arrow_left_outline" data-for="arrow_left_outline"
    data-tip="result_arrow_left_outline"
    class="Appkit4-icon icon-arrow-left-outline">
    <ReactTooltip id='arrow_left_outline' className='report-btn-tooltip' place='bottom'
      effect='solid'>
      <span style={{ color: 'white' }}>Go Back</span>
    </ReactTooltip>
  </span>
);

export default GoBack;
