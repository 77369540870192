import React, { Component } from 'react';
import $ from 'jquery';


class ReportSuccess extends Component {
  componentDidMount(props) {
    this.initializeSuccessAlert(props);
  }

  initializeSuccessAlert() {
    $('.success-alert').removeClass('hide');
    $('.success-alert').removeClass('a-alert-hide');
    $('.success-alert').addClass('show');
  }

  render() {
    return (
      <React.Fragment >
        <div className="success-alert  download-btn-spinner a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success" data-hide="10">
          <div className="a-alert-content">
            <div className="a-alert-badge success">
              <button className={'a-btn a-btn-transparent a-btn-lg a-no-interaction theme-loader}'}>
                <i className="a-loading-one a-primary" />
              </button>
            </div>
            <span className="a-content preview-pane-font">
              Downloading file...
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportSuccess;
