const SAVE_SUCCESS = 'SAVE_SUCCESS';
const SAVE_FAILURE = 'SAVE_FAILURE';
const TITLE_EXIST = 'TITLE_EXIST';
const PORTFOLIOS_DATA = 'PORTFOLIOS_DATA';
const SEARCH_PROJECTS = 'SEARCH_PROJECTS';
const CHANGE_PROJECTID_PORTFOLIOID = 'CHANGE_PROJECTID_PORTFOLIOID';
const SET_ACCESS_LEVEL_ROLES = 'SET_ACCESS_LEVEL_ROLES';
const CAN_ADD_PROJECT = 'CAN_ADD_PROJECT';
const SHOW_PORTFOLIO_RIGHTPANEL = 'SHOW_PORTFOLIO_RIGHTPANEL';
const HIDE_PORTFOLIO_RIGHTPANEL = 'HIDE_PORTFOLIO_RIGHTPANEL';
const SHOW_PORTFOLIO_STAKEHOLDER_RIGHTPANEL = 'SHOW_PORTFOLIO_STAKEHOLDER_RIGHTPANEL';
const HIDE_PORTFOLIO_STAKEHOLDER_RIGHTPANEL = 'HIDE_PORTFOLIO_STAKEHOLDER_RIGHTPANEL';
const SHOW_PROJECT_RIGHTPANEL = 'SHOW_PROJECT_RIGHTPANEL';
const HIDE_PROJECT_RIGHTPANEL = 'HIDE_PROJECT_RIGHTPANEL';
const SHOW_PROJECT_STAKEHOLDER_RIGHTPANEL = 'SHOW_PROJECT_STAKEHOLDER_RIGHTPANEL';
const HIDE_PROJECT_STAKEHOLDER_RIGHTPANEL = 'HIDE_PROJECT_STAKEHOLDER_RIGHTPANEL';
const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
const SHOW_OPEN_TEXT_RIGHTPANEL = 'SHOW_OPEN_TEXT_RIGHTPANEL';
const HIDE_OPEN_TEXT_RIGHTPANEL = 'HIDE_OPEN_TEXT_RIGHTPANEL';
const SHOW_OPEN_TEXT_NON_MATCH_RIGHTPANEL = 'SHOW_OPEN_TEXT_NON_MATCH_RIGHTPANEL';
const HIDE_OPEN_TEXT_NON_MATCH_RIGHTPANEL = 'HIDE_OPEN_TEXT_NON_MATCH_RIGHTPANEL';
const EMPTY_PRE_SELECTED_NEW_PORTFOLIO_ID = 'EMPTY_PRE_SELECTED_NEW_PORTFOLIO_ID';
const USER_FILTERS_FOR_PORTFOLIO = 'USER_FILTERS_FOR_PORTFOLIO';
const GET_QUESTION_MODELS = 'GET_QUESTION_MODELS';
const GET_SELECTED_MODEL_ID = 'GET_SELECTED_MODEL_ID';
const SHOW_OPTIONAL_COMMENT_RIGHTPANEL = 'SHOW_OPTIONAL_COMMENT_RIGHTPANEL';
const HIDE_OPTIONAL_COMMENT_RIGHTPANEL = 'HIDE_OPTIONAL_COMMENT_RIGHTPANEL';
const SHOW_CUSTOM_QUESTION_DETAIL_PANEL = 'SHOW_CUSTOM_QUESTION_DETAIL_PANEL';
const HIDE_CUSTOM_QUESTION_DETAIL_PANEL = 'HIDE_CUSTOM_QUESTION_DETAIL_PANEL';
const CAN_CHANGE_SETTINGS = 'CAN_CHANGE_SETTINGS';
const UPDATE_OPTIONAL_COMMENT_RIGHT_PANEL = 'UPDATE_OPTIONAL_COMMENT_RIGHT_PANEL';
const UPDATE_PORTFOLIO_DATA = 'UPDATE_PORTFOLIO_DATA';
const SHOW_OPEN_TEXT_DROP_QUES_RIGHTPANEL = 'SHOW_OPEN_TEXT_DROP_QUES_RIGHTPANEL';
const HIDE_OPEN_TEXT_DROP_QUES_RIGHTPANEL = 'HIDE_OPEN_TEXT_DROP_QUES_RIGHTPANEL';

export default {
  SAVE_SUCCESS,
  SAVE_FAILURE,
  TITLE_EXIST,
  PORTFOLIOS_DATA,
  SEARCH_PROJECTS,
  SET_ACCESS_LEVEL_ROLES,
  CAN_ADD_PROJECT,
  CHANGE_PROJECTID_PORTFOLIOID,
  SHOW_PORTFOLIO_RIGHTPANEL,
  HIDE_PORTFOLIO_RIGHTPANEL,
  SHOW_PORTFOLIO_STAKEHOLDER_RIGHTPANEL,
  HIDE_PORTFOLIO_STAKEHOLDER_RIGHTPANEL,
  SHOW_PROJECT_RIGHTPANEL,
  HIDE_PROJECT_RIGHTPANEL,
  SHOW_PROJECT_STAKEHOLDER_RIGHTPANEL,
  HIDE_PROJECT_STAKEHOLDER_RIGHTPANEL,
  GET_PROJECT_DETAILS,
  SHOW_OPEN_TEXT_RIGHTPANEL,
  HIDE_OPEN_TEXT_RIGHTPANEL,
  SHOW_OPEN_TEXT_NON_MATCH_RIGHTPANEL,
  HIDE_OPEN_TEXT_NON_MATCH_RIGHTPANEL,
  EMPTY_PRE_SELECTED_NEW_PORTFOLIO_ID,
  USER_FILTERS_FOR_PORTFOLIO,
  GET_QUESTION_MODELS,
  GET_SELECTED_MODEL_ID,
  SHOW_OPTIONAL_COMMENT_RIGHTPANEL,
  HIDE_OPTIONAL_COMMENT_RIGHTPANEL,
  SHOW_CUSTOM_QUESTION_DETAIL_PANEL,
  HIDE_CUSTOM_QUESTION_DETAIL_PANEL,
  CAN_CHANGE_SETTINGS,
  UPDATE_OPTIONAL_COMMENT_RIGHT_PANEL,
  UPDATE_PORTFOLIO_DATA,
  SHOW_OPEN_TEXT_DROP_QUES_RIGHTPANEL,
  HIDE_OPEN_TEXT_DROP_QUES_RIGHTPANEL,
};
