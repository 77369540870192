import React, { Component } from 'react';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  insertAttribute, validateAttributeName, updateAttribute, canChangeDomain,
} from '../actions/attribute';
import { getDomainsForFilter } from '../actions/domain';
import { addAttributeSelector } from '../selectors/attribute';
import { domainSelector } from '../selectors/domain';
import SumoSelect from '../../../../ui-components/SumoSelect/SumoSelect';
import { SpinnerButton, Spinner } from '../../../../ui-components/Loader';
import RenderIf from '../../../../ui-components/Common';

const mapStateToProps = state => ({
  domains: domainSelector(state),
  addAttribute: addAttributeSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const mapDispatchToProps = {
  insertAttribute,
  updateAttribute,
  validateAttributeName,
  canChangeDomain,
};

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isValidateAttributeName(value, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'An attribute with the same title already exists.';
    const emptyStringErrorMessage = 'Please enter a valid title for attribute.';

    const title = value.title.trim().toLowerCase().replace(/  +/g, ' ');
    const { questionModelId } = value;
    if (title && title !== '' && title !== value.initialTitle.toLowerCase()) {
      validateAttributeName({ attribute: title, questionModelId }).then((val) => {
        if (val) {
          setError(actions, 'title', errorMessage);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else if (title === '') {
      setError(actions, 'title', emptyStringErrorMessage);
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

function checkDescriptionLength(e, setFieldTouched, setFieldError, setFieldValue) {
  setFieldValue('description', e.target.value);
  setFieldValue('descriptionCharacter', e.target.value.length);
  const errorMessage = 'You have exceeded the maximum character limit';
  if (e.target.value !== '') {
    if (e.target.value.length > 250) {
      setFieldTouched('description', true);
      window.setTimeout(() => {
        setFieldError('description', errorMessage);
      }, 100);
    }
  }
}

class AddAttribute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      selectedDomain: '',
      isSubmitting: false,
      isLoading: true,
      errorDomain: '',
      description: '',
    };
    this.domainsList = [];
    this.saveAttribute = this.saveAttribute.bind(this);
    this.hideAddAttributePanel = this.hideAddAttributePanel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const self = this;
    getDomainsForFilter(self.props.questionModelId).then((data) => {
      self.domainNotFound = data.length === 0;
      data.map((item) => {
        const obj = {
          text: item.title,
          value: item.id,
        };
        self.domainsList.push(obj);
      });

      self.setState({ isLoading: false });
    });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onChange(e) {
    this.setState({
      title: e.target.value,
    });
  }


  saveAttribute(model) {
    if (model.id !== 0) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '),
        domainId: model.selectedDomain,
        id: model.id,
        description: model.description,
        questionModelId: model.questionModelId,
      };

      this.props.canChangeDomain(data.id, data.domainId).then((res) => {
        if (!res.isDomainChanged) {
          this.props.updateAttribute(data).then(() => {
            this.props.hideAddAttributePanel();
          });
        } else {
          const attributeQuestionResponses = [];
          attributeQuestionResponses.push(res.response
            .filter(result => result.response_received !== null));
          if (attributeQuestionResponses[0].length === 0) {
            this.props.updateAttribute(data).then(() => {
              this.props.hideAddAttributePanel();
            });
          } else {
            const errorMessage = 'This attribute is associated to some question which has one or more responses so it cannot be moved from this domain.';
            this.setState({ errorDomain: errorMessage, isSubmitting: false });
          }
        }
      });
    } else if (model.title && model.selectedDomain) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '),
        domainId: model.selectedDomain,
        description: model.description,
        questionModelId: model.questionModelId,
      };
      this.props.insertAttribute(data).then(() => {
        this.showSpinner(false);
        this.props.hideAddAttributePanel();
      });
    }
  }

  hideAddAttributePanel() {
    this.props.hideAddAttributePanel();
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideAddAttributePanel();
    }
  }

  isValidateAttributeName(values, actions) {
    this.showSpinner(true);
    isValidateAttributeName(values, actions).then((isExist) => {
      if (!isExist) {
        this.saveAttribute(values);
      } else {
        this.showSpinner(false);
      }
    });
  }

  showSpinner(isSubmitting) {
    this.setState(prevState => ({
      ...prevState,
      isSubmitting,
    }));
  }

  render() {
    const { isLoading } = this.state;
    const { domainsList } = this;
    const { selectedAttribute } = this.props;
    let attributeModel = {};
    attributeModel = {
      id: selectedAttribute.id ? selectedAttribute.id : 0,
      title: selectedAttribute.title ? selectedAttribute.title : this.state.title,
      initialTitle: selectedAttribute.title
        ? selectedAttribute.title : this.state.title,
      selectedDomain: selectedAttribute.domain_id
        ? selectedAttribute.domain_id
        : this.state.selectedDomain,
      description: selectedAttribute.description
        ? selectedAttribute.description
        : this.state.description,
      descriptionCharacter:
        selectedAttribute.description
          ? selectedAttribute.description.length : 0,
      questionModelId: this.props.questionModelId,
    };

    return (
      <div className="right-sidebar user-sidebar-container" ref={this.setWrapperRef}>
        <Spinner isLoading={isLoading} />
        <RenderIf showComponent={!isLoading}>
          <div className="r-s-header">
            <div className="r-s-title">
              <span>
                {selectedAttribute.id ? 'Modify existing attribute' : 'Create a new attribute'}
                <i className="appkiticon icon-close-outline" onClick={this.hideAddAttributePanel} />
              </span>
            </div>
            <div className="indicate_val">
              <span className="mandatory-icon">*</span>
              <span className="in_text preview-pane-font"> indicates required field</span>
            </div>
          </div>

          <div className="app">
            <Formik
              enableReinitialize
              initialValues={attributeModel}
              onSubmit={(values, actions) => this.isValidateAttributeName(values, actions)}
              validationSchema={Yup.object().shape({
                title: Yup.string().trim().required('Attribute is required.'),
                selectedDomain: Yup.string().required('Domain is required.'),
                description: Yup.string().max(250, 'You have exceeded the maximum character limit'),
              })}
            >
              {({
                errors,
                touched,
                handleSubmit,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
                setFieldTouched,
                setFieldError,
              }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="r-s-body mt-3">
                      <div className="r-s-body mt-3">
                        <div className="form-group">
                          <div className="a-form-label">Attribute Title <span className="mandatory-icon">*</span></div>
                          <input
                            name="title"
                            autoFocus={!selectedAttribute.id}
                            autoComplete="off"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`${errors.title && touched.title ? 'error a-text-input' : 'a-text-input'
                            }`}
                          />
                          {errors.title && touched.title && (
                            <div className="error">{errors.title}</div>
                          )}
                        </div>

                        <div className="form-group">
                          <div className="a-form-label">Associated Focus Area <span className="mandatory-icon">*</span></div>

                          <SumoSelect
                            name="selectedDomain"
                            placeholder="Choose a focus area"
                            error={touched.selectedDomain && errors.selectedDomain}
                            selectedValues={values.selectedDomain}
                            options={domainsList}
                            setFieldValue={setFieldValue}
                            searchfield = {true}
                            searchText = {'Choose a focus area'}
                          />
                          {(this.state.errorDomain && this.state.errorDomain.length) ? <div className="error">{this.state.errorDomain}</div> : ''}
                        </div>
                        <div className="form-group">
                          <div className="a-form-label">
                            {values.descriptionCharacter > 0 && <span className={values.descriptionCharacter > 250 ? 'pull-right red lower-case preview-pane-font' : 'pull-right lower-case preview-pane-font'}>
                              {values.descriptionCharacter}/250 characters</span>}
                            <span className='preview-pane-font'>Attribute Description</span>
                          </div>
                          <Field
                            component='textarea'
                            name="description"
                            placeholder="Enter description"
                            autoComplete="off"
                            className='a-text-input'
                            onChange={
                              value => checkDescriptionLength(
                                value, setFieldTouched, setFieldError, setFieldValue,
                              )}
                          />
                          <div className="error">
                            <ErrorMessage name="description" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="r-s-actions mt-4">
                      <div className="mt-3">
                        <SpinnerButton
                          isLoading={this.state.isSubmitting}
                          label={values.id === 0 ? 'CREATE' : 'SAVE CHANGES'}
                        />
                      </div>
                    </div>
                  </form>
              )}
            </Formik>
          </div>
        </RenderIf>
      </div>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddAttribute);
