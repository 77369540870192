import actionType from '../../../../redux/modules/settings/actionType';
import settingsService from '../../../../redux/services/settingsService';
import { INITIAL_PAGINATED_DATA } from '../../../../Constants';

// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

// change status of added/updated question
export const modifyAddedQuestionStatus = status => (dispatch) => {
  dispatch({ type: actionType.ADDED_QUESTION_STATUS, data: status });
};

export const getAddedQuestionStatus = status => (dispatch) => {
  dispatch({ type: actionType.NEW_ADDED_QUESTION_STATUS, data: status });
};

export const changeAddedQuestionStatus = status => (dispatch) => {
  dispatch({ type: actionType.CHANGE_ADDED_QUESTION_STATUS, data: status });
};

export const getQuestions = (filters, paginatedData, questionModelId) => dispatch => settingsService
  .getQuestionsList(filters, paginatedData, questionModelId)
  .then((res) => {
    const question = {
      questionsList: [],
      count: 0,
    };
    question.count = res.data.count;
    res.data.questions.map((item) => {
      const data = item;
      data.isChecked = false;
      if (data.is_inverted === null) data.polarity = '-';
      if (data.is_inverted === false) data.polarity = 'Positive';
      if (data.is_inverted === true) data.polarity = 'Negative';
      question.questionsList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_QUESTIONS_LIST, question });
  })
  .catch(() => { });

export const getQuestionStages = id => () => settingsService
  .getQuestionStages(id)
  .then(res => res.data)
  .catch(() => { });

export const checkAttributeEligibility = id => settingsService
  .checkAttributeEligibility(id)
  .then(res => res.data)
  .catch(() => { });

export const insertQuestion = question => dispatch => settingsService
  .insertQuestion(question)
  .then((res) => {
    dispatch(getAddedQuestionStatus(true));
    return res;
  })
  .catch(error => error);

export const updateQuestion = question => dispatch => settingsService
  .updateQuestion(question)
  .then((res) => {
    dispatch(modifyAddedQuestionStatus(true));
    return res;
  })
  .catch(() => { });

export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_QUESTIONS_CHECKBOXES });
};

export const toggleCheckbox = question => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_QUESTIONS_CHECKBOX, question });
};

export const canBulkDelete = selectedIds => () => settingsService
  .canBulkDeleteQuestions(selectedIds)
  .then(res => res.data)
  .catch(() => { });

export const canDelete = id => () => settingsService
  .canDeleteQuestion(id)
  .then(res => res.data)
  .catch(() => { });


export const deleteQuestions = (selectedIds, filters) => dispatch => settingsService
  .deleteQuestions(selectedIds)
  .then((res) => {
    dispatch(getQuestions(filters, INITIAL_PAGINATED_DATA));
    return res.data;
  })
  .catch(() => { });
