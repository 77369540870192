import React, { Component } from 'react';
import './svgtimer.css';
// import $ from 'jquery';


class SuccessAlertWithTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessAlert: this.props.showSuccessAlert,
    };
  }

  componentDidMount() {
    this.checkIfFromResendQuestionsEmail = this.checkIfFromResendQuestionsEmail.bind(this);
  }

  checkIfFromResendQuestionsEmail() {
    if (this.props.isFromResentQuestions) {
      this.props.closeResentQuestions();
    }
    if (this.props.isFromQuestionUrlLink) {
      this.props.closeQuestionurlLinkAlert();
    }
    this.setState({ showSuccessAlert: false });
  }

  render() {
    const { alertMesage, isFromProjectLaunch } = this.props;
    if (!this.state.showSuccessAlert) {
      return null;
    }
    return (
      <React.Fragment >
        {alertMesage && <div class="alert alert-success success-alert-with-timer a-alert custom-alert alert-timer success" role="alert"
          style={{
            left: '50%',
            top: '100px',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            padding: 0,
          }}
        >
          <div className="a-alert-content">
            <div className="a-alert-badge success">
              <span className="appkiticon icon-confirmed-fill me-0"></span>
            </div>
            <span className="a-content me-0 preview-pane-font" style={{ flexBasis: 'unset' }}>
              {alertMesage}
            </span>

            {isFromProjectLaunch ? (
              <div className="">
                <svg width="23" height="23" class="chart">
                  <circle r="12.5" cx="12.5" cy="12.5" class="pie" />
                </svg>
                {/* <svg height="16" width="16">
                  <circle className="a-circle a-countdown" cx="8" cy="8" r="4"></circle>
                </svg> */}
              </div>
            ) : <span onClick={this.checkIfFromResendQuestionsEmail} class="close-btn a-countdown-wrapper alert-dismissible m-0 p-0" style={{ right: '0px' }}>
              <span class="appkiticon icon-close-fill p-0"></span>
            </span>
            }
          </div>
        </div>}
      </React.Fragment>
    );
  }
}

export default SuccessAlertWithTimer;
