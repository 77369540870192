import actionType from './actionType';

const initialState = {
  heading: '',
  headingRightEndText: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_HEADING:
      return {
        ...state,
        heading: action.heading,
      };
    case actionType.SET_HEADING_RIGHT_END_TEXT:
      return {
        ...state,
        heading: action.headingRightEndText,
      };
    default:
      return state;
  }
};
