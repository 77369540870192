import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import { push } from 'connected-react-router';
import { CSSTransitionGroup } from 'react-transition-group';
import SettingsRoutes from '../../modules/settings/settingsRoutes.jsx';
import UserAccount from '../../modules/account/components/UserAccount';
import UserPhoneNumberUpdate from '../../modules/account/components/UserPhoneNumberUpdate';
import AddQuestionModel from '../../modules/settings/question-model/components/AddQuestionModel.jsx';

// import authService from '../../redux/services/authService';
import Authorise from '../../Authorise';
import isLoggedInUserSystemAdministrator from './action';
import {
  showAccountRightPanel,
  hideChangePhoneNumberRightPanel,
} from '../../modules/account/actions/login';

import { showQuestionModelRightPanel } from '../../modules/settings/question-model/actions/questionModel';
import { checkIfUserCanChangeSettings } from '../../modules/portfolio/actions/portfolio';
import OverlayBackdrop from '../OverlayBackdrop/OverlayBackdrop';

const mapStateToProps = state => ({
  heading: state.settings.heading,
  isSysAdmin: state.login.isSysAdmin,
  accountRightPanel: state.login.accountRightPanel,
  changePhoneNumberRightPanel: state.login.changePhoneNumberRightPanel,
  changeQuestionModelRightPanel: state.questionModel.questionModelData.questionModelRightPanel,
  portfolioData: state.portfolio.portfolioData,
});
const mapDispatchToProps = {
  push,
  isLoggedInUserSystemAdministrator,
  showAccountRightPanel,
  hideChangePhoneNumberRightPanel,
  showQuestionModelRightPanel,
  checkIfUserCanChangeSettings,
};

class SettingsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.showAccountRightPanel = this.showAccountRightPanel.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setAccountWrapperRef = this.setAccountWrapperRef.bind(this);
    this.setChangePhoneNumberWrapperRef = this.setChangePhoneNumberWrapperRef.bind(this);
    this.setChangeQuestionModelWrapperRef = this.setChangeQuestionModelWrapperRef.bind(this);
    this.hideChangePhoneNumberRightPanel = this.hideChangePhoneNumberRightPanel.bind(this);
  }

  componentDidMount() {
    this.props.isLoggedInUserSystemAdministrator().then(() => {
      this.setState({ isLoading: false });
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (!this.isBootstrapModalOpen('deleteConfirmation')) {
      if (this.changePhoneNumberRef && !this.changePhoneNumberRef.contains(event.target)) {
        this.hideChangePhoneNumberRightPanel();
      }
    }
  }

  canUserChangeSettings() {
    const { canChangeSettings } = this.props.portfolioData;
    let role = '';
    if (canChangeSettings) {
      role = 'questionModelAuthor';
    }
    return role;
  }

  isBootstrapModalOpen(modelId) {
    /* eslint-disable no-underscore-dangle */
    const isModelOpen = ($(`#${modelId}`).data('bs.modal') || {})._isShown;
    /* eslint-enable no-underscore-dangle */

    return isModelOpen;
  }

  setAccountWrapperRef(node) {
    this.accountWrapperRef = node;
  }

  setChangeQuestionModelWrapperRef(node) {
    this.changeQuestionModelRef = node;
  }

  setChangePhoneNumberWrapperRef(node) {
    this.changePhoneNumberRef = node;
  }

  showAccountRightPanel() {
    this.props.showAccountRightPanel();
  }

  hideChangePhoneNumberRightPanel() {
    this.props.hideChangePhoneNumberRightPanel();
    this.props.showAccountRightPanel();
  }

  render() {
    const {
      heading, isSysAdmin, accountRightPanel,
      changePhoneNumberRightPanel, changeQuestionModelRightPanel,
    } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="content-container">
        <OverlayBackdrop />
        {!isLoading
          && <Authorise role={isSysAdmin ? 'systemAdministrator' : this.canUserChangeSettings()}
            perform="settings"
            permit={() => (
              <div>
                <div className="a-page-header">
                  <div className="row align-items-center settings-title">
                    <div className="col-md-6">
                      <p className="a-page-title m-0 mb-2">{heading}</p>
                    </div>
                  </div>
                </div>
                <div className="a-main-content">
                  <SettingsRoutes />
                </div>
              </div>)}
          />

        }
        <CSSTransitionGroup
          transitionName="pwcsidebar"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {accountRightPanel.showPanel
            && <UserAccount accountRef={this.setAccountWrapperRef} />
          }

          {changePhoneNumberRightPanel.showPanel
            && <UserPhoneNumberUpdate
              changePhoneNumberRef=
              {this.setChangePhoneNumberWrapperRef} />
          }
          {changePhoneNumberRightPanel.showPanel
            && <UserPhoneNumberUpdate
              changePhoneNumberRef=
              {this.setChangePhoneNumberWrapperRef} />
          }
          {changeQuestionModelRightPanel.showPanel
            && <AddQuestionModel
              changeQuestionModelRef=
              {this.setChangeQuestionModelWrapperRef} />
          }

        </CSSTransitionGroup>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsContent);
