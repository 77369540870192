import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Formik, Form, Field,
} from 'formik';
import * as Yup from 'yup';
import MaterialIcon from 'material-icons-react';
import { Spinner, SpinnerButton } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import authService from '../../../redux/services/authService';
import accountService from '../../../redux/services/accountService';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import constant from '../actions/constant';
import { deleteMyAccount } from '../../settings/user/actions/user';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { levelTwoOrganizations } from '../../settings/question-model/actions/levelconstants';
import { isInternalPwcUser } from '../../../utils/helper';
import {
  hideAccountRightPanel,
  showAccountRightPanel,
  showChangePhoneNumberRightPanel,
  updateUserName,
} from '../actions/login';
import { showHideOverlay, updateUnsavedChanges } from '../../common/overlay/actions/overlay';

class UserAccount extends Component {
  constructor(props) {
    super(props);
    const {
      email, user_id: userId,
    } = authService.getUserInfo();
    this.state = {
      isLoading: true,
      showDeleteModal: false,
      firstName: null,
      lastName: null,
      email,
      userId,
      notifyToggle: [],
      isSubmitting: false,
    };
    this.showAccountRightPanel = this.showAccountRightPanel.bind(this);
    this.hideAccountRightPanel = this.hideAccountRightPanel.bind(this);
  }

  componentDidMount() {
    accountService.getLoggedInUser().then((res) => {
      if (res) {
        const { first_name: firstName, last_name: lastName } = res.data[0];
        this.setState({
          notifyToggle: res.data && res.data[0] ? res.data[0] : {},
          isLoading: false,
          firstName,
          lastName,
        });
      }
    });
  }

  showAccountRightPanel() {
    this.props.showAccountRightPanel();
  }

  hideAccountRightPanel() {
    this.props.hideAccountRightPanel();
  }

  setNewProjectNotificationForUser() {
    const toggleInfo = this.state.notifyToggle;
    toggleInfo.email_new_projects = !toggleInfo.email_new_projects;
    accountService.toggleEmailNotification(toggleInfo);
    this.setState({ notifyToggle: toggleInfo });
  }

  setNewQuestionNotificationForUser() {
    const toggleInfo = this.state.notifyToggle;
    toggleInfo.email_new_questions = !toggleInfo.email_new_questions;
    accountService.toggleEmailNotification(toggleInfo);
    this.setState({ notifyToggle: toggleInfo });
  }

  setOverdueQuestionNotificationForUser() {
    const toggleInfo = this.state.notifyToggle;
    toggleInfo.email_overdue_questions = !toggleInfo.email_overdue_questions;
    accountService.toggleEmailNotification(toggleInfo);
    this.setState({ notifyToggle: toggleInfo });
  }

  deleteModal() {
    this.setState({ showDeleteModal: true });
  }

  cancelDelete() {
    this.setState({ showDeleteModal: false });
  }

  userSignOut() {
    accountService.logUserSignOut(
      this.state.userId,
      constant.logSignOutMessages.accountDeleteMessage,
    ).then(() => {
      // eslint-disable-next-line no-undef
      window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
    });
  }

  deleteUser() {
    return this.props
      .deleteMyAccount()
      .then((res) => {
        this.userSignOut();
        return res;
      });
  }

  // change phone number methods
  openChangePhoneNumberPanel() {
    this.hideAccountRightPanel();
    this.props.showHideOverlay(true);
    this.props.showChangePhoneNumberRightPanel();
  }

  updateUserName(userModel) {
    this.setState({
      isSubmitting: true,
    });
    this.props.updateUserName(userModel).then(() => {
      this.setState({
        isSubmitting: false,
      });
      this.hideAccountRightPanel();
    });
  }

  render() {
    const {
      firstName, lastName, email, notifyToggle, showDeleteModal,
      isSubmitting,
    } = this.state;
    const isExternalUser = !isInternalPwcUser(email.toLowerCase());
    const model = {
      firstName, lastName, email, notifyToggle,
    };
    const { systemTerminology } = this.props;
    const projectReplace = levelTwoOrganizations.find(o => o.value === systemTerminology.level_two);
    const projectReplaceText = projectReplace && projectReplace.text;

    return (
      <React.Fragment>
        <RenderIf showComponent={showDeleteModal}>
          <DeleteConfirmation
            title={constant.deleteModel.title}
            message={constant.deleteModel.message}
            confirmationClick={this.deleteUser.bind(this)}
            cancelClick={this.cancelDelete.bind(this)}
          />
        </RenderIf>
        <div className="right-sidebar add-project-sidebar" ref={this.props.accountRef}>

          <div className="r-s-header">
            <div className="r-s-title">
              <span>
                {'Account Settings'}
                <a>
                  <i
                    className="appkiticon icon-close-outline"
                    onClick={() => {
                      this.props.showHideOverlay(false);
                      this.hideAccountRightPanel();
                    } }
                  />
                </a>{' '}
              </span>
            </div>
          </div>
          <div className="app">
            <Formik
              initialValues={model}
              enableReinitialize
              validationSchema={ Yup.object().shape({
                firstName: Yup.string().trim().required('First Name is required.')
                  .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                lastName: Yup.string().trim().required('Last Name is required.')
                  .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                email: Yup.string().trim().required('Email is required.')
                  .email('Email Address is not valid')
                  .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
              })}
              onSubmit={(values) => {
                this.updateUserName(values);
              }}
            >
              {() => (
                <Form>
                  <div className="r-s-body mt-3">
                    <div className="form-group">
                      <div className="a-form-label">First Name</div>
                      <div className=" text-icon icon-right">
                        {!isExternalUser && <MaterialIcon icon="lock" /> }
                        <Field
                          name="firstName"
                          autoComplete="off"
                          className="a-text-input"
                          setFieldValue={model.firstName}
                          disabled={!isExternalUser}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="a-form-label">Last Name</div>
                      <div className=" text-icon icon-right">
                      {!isExternalUser && <MaterialIcon icon="lock" /> }
                        <Field
                          name="lastName"
                          autoComplete="off"
                          className="a-text-input"
                          setFieldValue={model.lastName}
                          disabled={!isExternalUser}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className="a-form-label">Email Address</div>
                      <div className=" text-icon icon-right">
                        <MaterialIcon icon="lock" />
                        <Field
                          name="email"
                          autoComplete="off"
                          className="a-text-input"
                          setFieldValue={model.email}
                          disabled
                        />
                      </div>
                    </div>
                    <Spinner isLoading={this.state.isLoading} />
                    <RenderIf showComponent={model && model.notifyToggle && !this.state.isLoading}>
                      <div>
                        <div className="form-group mt-3">
                          <p className="section-title m-0 mb-1">Email Notifications</p>
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              type="checkbox"
                              name="toggle"
                              checked={model.notifyToggle.email_new_projects}
                              onChange={this.setNewProjectNotificationForUser
                                .bind(this)}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font" />
                              <span className="off preview-pane-font" />
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">
                              <b>New {projectReplaceText}</b> - Notify me when I am assigned
                               as a stakeholder of a new {projectReplaceText}.
                          </span>
                          </label>
                        </div>

                        <div className="form-group mt-3">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              type="checkbox"
                              name="toggle"
                              checked={model.notifyToggle.email_new_questions}
                              onChange={this.setNewQuestionNotificationForUser
                                .bind(this)}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font" />
                              <span className="off preview-pane-font" />
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">
                              <b>New Questions </b> - Notify me when new questions are added to
                               my backlog that require my response.
                          </span>
                          </label>
                        </div>

                        <div className="form-group mt-3">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              type="checkbox"
                              name="toggle"
                              checked={model.notifyToggle.email_overdue_questions}
                              onChange={this.setOverdueQuestionNotificationForUser
                                .bind(this)}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font" />
                              <span className="off preview-pane-font" />
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">
                              <b>Overdue Questions </b> - Notify me when I have overdue questions
                               in my backlog that I still need to respond to.
                          </span>
                          </label>
                        </div>
                      </div>
                    </RenderIf>
                  </div>
                  <RenderIf showComponent={isExternalUser}>
                    <div className="mb-1">
                      <a href="javascript:;" className="a-btn a-btn-transparent a-btn-lg a-btn-gray" onClick={this.openChangePhoneNumberPanel.bind(this)}> CHANGE PHONE NUMBER </a>
                    </div>
                  </RenderIf>
                  <RenderIf showComponent={!this.state.isLoading}>
                    <a href="javascript:;" className="a-btn a-btn-transparent a-btn-lg a-btn-gray" onClick={this.deleteModal.bind(this)}> DELETE USER </a>
                  </RenderIf>
                  <RenderIf showComponent={isExternalUser}>
                  <div className="r-s-actions mt-4">
                    <div className="mt-3 mb-4">
                        <SpinnerButton
                            isLoading={isSubmitting}
                            label='SAVE'
                        />
                    </div>
                  </div>
                  </RenderIf>
                </Form>
              )}
            </Formik>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userProp: state.login,
  systemTerminology: getSystemTerminologySelector(state),
  redirectUrl: state.brandwrapper.redirectUrl,
});

const mapDispatchToProps = {
  hideAccountRightPanel,
  showAccountRightPanel,
  deleteMyAccount,
  showChangePhoneNumberRightPanel,
  getSystemTerminology,
  updateUserName,
  showHideOverlay,
  updateUnsavedChanges,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAccount);
