import React from 'react';
import ReactTooltip from 'react-tooltip';
import './segment.css';
import { addEveryoneToFirst } from '../../utils/helper';

const SumoSelectSegmentDropDown = ({
  projectSegments, selectedSegments, updateAssignedSegments,
  domainSegments, val, checked,
}) => {
  const segmentOptions = addEveryoneToFirst(projectSegments);
  const toggleSegment = (id, e) => {
    e.stopPropagation();
    const EVERYONE = 0;
    let filteredSegments = [];
    if (id === EVERYONE) {
      filteredSegments.push(EVERYONE);
    } else if (selectedSegments?.includes(id)) {
      filteredSegments = selectedSegments.filter(s => (s !== id && s !== EVERYONE));
    } else {
      filteredSegments = selectedSegments.filter(s => (s !== EVERYONE));
      filteredSegments.push(id);
    }
    if (filteredSegments.length === 0 && domainSegments?.includes(EVERYONE)) {
      filteredSegments.push(EVERYONE);
    }
    filteredSegments.forEach((value) => {
      checked(value);
    });
    updateAssignedSegments(filteredSegments);
  };

  return (<div>
    <div class="badge-dropdown sumo-select cstm-slct-drp-dwn">
      <div class="dropdown">
        <div class="dropdown-select entypo-down-open-big" type="button" id="dropdownMenuButton"
          data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
          <span className="cstm-sgmntdd" style={{ fontSize: '12px' }}>
            {
              (selectedSegments.length !== 1) ? `${selectedSegments.length} segments selected`
                : (
                  segmentOptions && segmentOptions.length > 0
                  && segmentOptions.map(
                    s => (selectedSegments?.includes(parseInt(s.value, 10))
                      ? (
                        <div className="chkbx-sgmnt d-flex align-items-center"><i class="circle-badge" style={{ background: s.indicatorColor }}></i> <span className="ifCustomQuestion">{s.text}</span></div>
                      ) : ''),
                  )
                )
            }
          </span>
          <i class="arrow-badge"></i>
        </div>
        <div class="dropdown-menu p-3" style={{ overflow: 'inherit' }} aria-labelledby="dropdownMenuButton" onClick={e => e.stopPropagation()}>
          <div class="dd-body data-scroller-container">
            {segmentOptions && segmentOptions.length > 0
              && segmentOptions.map(
                (s, index) => (domainSegments?.includes(parseInt(s.value, 10))
                  ? (<React.Fragment >
                    <div data-for={`SegmentChk${s.value}${index}`} data-tip={`SegmentChk${s.value}${index}_tooltip`} onClick={(e) => { e.preventDefault(); toggleSegment(parseInt(s.value, 10), e); }} key={index} className="body-input-container" >
                      <label class="a-checkbox" ><input type="checkbox" checked={selectedSegments?.includes(parseInt(s.value, 10))} /><span class="a-checkbox-mark" onClick={e => e.stopPropagation()} ><span class="appkiticon icon-check-mark-fill"></span></span></label>
                      <a style={{ backgroundColor: s.value === val ? '#fdf5f2' : '' }} class="dropdown-item" href="#">
                        <div className="chkbx-sgmnt"><i class="circle-badge" style={{ background: s.indicatorColor }}></i> <span className="ifCustomQuestion preview-pane-font">{s.text}</span> </div>
                        {(typeof s.stakeholders !== 'undefined')
                          && <span className='preview-pane-font' style={{ fontSize: '12px' }}>({s.stakeholders > 1 ? `${s.stakeholders} stakeholders` : `${s.stakeholders} stakeholder`})</span>}
                      </a>
                    </div>
                    {s.text.length > 20 && <ReactTooltip id={`SegmentChk${s.value}${index}`}
                      className={`SegmentChk${s.value}${index}_tooltip`} place='right'
                      effect='solid' multiline={true} backgroundColor='#6b6b6b'>
                      {s.text}
                    </ReactTooltip>}
                  </React.Fragment>
                  ) : ''),
              )}
          </div>
        </div>
      </div>
    </div>
  </div >);
};
export default SumoSelectSegmentDropDown;
