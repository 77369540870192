import React, { Fragment, useState } from 'react';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import './segment.css';
import segmentIconSvg from '../../../assets/images/component/svg/segment-icon.svg';

const CreateToolTipData = ({ projectSegments, selectedSegments }) => {
  if (selectedSegments && !selectedSegments.includes(0)) {
    return (< Fragment >
      <span>Send to: </span>
      <ul>
        {projectSegments.map((segment) => {
          if (selectedSegments && selectedSegments.includes(parseInt(segment.value, 10))) {
            return (<li>{segment.text}</li>);
          }
          return '';
        })}
      </ul>
    </Fragment>);
  }
  return ('');
};

const SegmentToolTipViewAll = (props) => {
  const { selectedSegments } = props;
  const [tooltipIdPostFix] = useState(Math.random());

  const getNoOfSegments = () => {
    if (selectedSegments && selectedSegments.length === 1 && selectedSegments[0] === 0) {
      return 0;
    }
    if (selectedSegments && !selectedSegments.includes(0)) {
      return selectedSegments.length;
    }
    return 0;
  };

  if (selectedSegments && selectedSegments.length === 1 && selectedSegments[0] === 0) {
    return <></>;
  }

  return (<div class="dropdown-toggle">
    <span style={{ color: getNoOfSegments() > 0 ? '#eb590b' : 'transparent' }}>
      <ReactSVG wrapper="svg" id={`segmentMessage${tooltipIdPostFix}`} width="14" height="9" src={segmentIconSvg} data-for={`segmentMessage${tooltipIdPostFix}`} data-tip={`segmentMessage${tooltipIdPostFix}`} fill={getNoOfSegments() > 0 ? '#eb590b' : '#808080'} />
    </span>

    <ReactTooltip id={`segmentMessage${tooltipIdPostFix}`} className={`segmentMessage${tooltipIdPostFix}_tooltip ${selectedSegments && selectedSegments?.length === 1 && selectedSegments[0] === 0 ? 'every-one' : ''}`} multiline={true} place='left'
      effect='solid'>
      {<CreateToolTipData {...props} />}
    </ReactTooltip>
  </div>);
};
export default SegmentToolTipViewAll;
