import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { getPortfolio } from '../../portfolio/selectors/portfolio';
import {
  hideOpenTextDropQuesRightPanel,
} from '../../portfolio/actions/portfolio';
import {
  getOpenTextDropResponses,
} from '../actions/project';
import { Spinner } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import { showHideOverlay } from '../../common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  openTextDropQuesRightPanel: state.portfolio.portfolioData.openTextDropQuesRightPanel,
  projectDetails: state.project.projectDetails,

});

const mapDispatchToProps = {
  hideOpenTextDropQuesRightPanel,
  getOpenTextDropResponses,
  showHideOverlay,
};

class OpenTextDropQuesResponsePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserId: 0,
      stakeholders: [],
      isLoading: true,
      projectTitle: '',
      keyPhrase: '',
      textResponses: [],
      futureOpenTextQuestions: [],
    };
    this.hideOpenTextRightPanel = this.hideOpenTextRightPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    const { openTextDropQuesRightPanel } = this.props.portfolioData;
    this.getOpenTextDropResponses(openTextDropQuesRightPanel);
  }

  getOpenTextDropResponses(openTextDropQuesRightPanel) {
    this.props.getOpenTextDropResponses(openTextDropQuesRightPanel).then((res) => {
      const {
        keyPhrase, title, textResponses, userTagFilters, futureOpenTextQuestions,
      } = res;
      if (keyPhrase) {
        this.setState({
          keyPhrase,
          projectTitle: title,
          textResponses,
          userTagFilters,
          isLoading: false,
          futureOpenTextQuestions,
        });
      }
    });
  }

  hideOpenTextRightPanel() {
    this.props.showHideOverlay(false);
    this.props.hideOpenTextDropQuesRightPanel();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  sortResponse(array) {
    array.sort((a, b) => new Date(b.response_received) - new Date(a.response_received));
  }

  render() {
    const {
      textResponses, isLoading, futureOpenTextQuestions, projectTitle,
    } = this.state;
    return (
      <React.Fragment >
        <div className="right-sidebar-wrap">
          <div className="right-sidebar add-project-sidebar" ref={this.setWrapperRef}>
            <Spinner isLoading={isLoading} />
            <RenderIf showComponent={!isLoading}>
              <div className="right-bar custom-animation">
                <div className="right-bar-head d-flex justify-content-between align-items-star">
                  <div className="right-bar-title">
                    <h4 className="mb-0 title">{projectTitle}</h4>
                  </div>
                  <div className="right-bar-close">
                    <button type="button" className="btn btn-sm bg-transparent close" onClick={this.hideOpenTextRightPanel}>
                      <span className="material-icons">close</span>
                    </button>
                  </div>
                </div>


                <div className="right-bar-body pt-4">
                  <div id="accordion" className="accordion right-bar-accordion">
                    {textResponses.map((data, index) => (
                      <div key={index} className="card border-bottom">
                        <div>
                          <a className="card-title">
                            <span className="mr-2 preview-pane-font">Q: </span> <span className='preview-pane-font'> {data.QuestionTitle} </span>
                          </a>
                        </div>
                      </div>
                    ))}

                          {(futureOpenTextQuestions
                          && futureOpenTextQuestions.length > 0)
                          && futureOpenTextQuestions.map((item, index) => {
                            index = `tooltip-${item.question_id}`;
                              return (
                                <React.Fragment>
                                  <div key={index} id={`collapsed-header-3${index}`} className="right-bar-accordion card border-bottom">
                                      <a className="card-title custom-question-wrapper">
                                        <div className="custom-question-container">
                                          <span className="custom-question fq-font"><i>Q: {item.title} </i></span>
                                          <div className='cq-header-action'>
                                            <span data-for={index} data-tip='tooltip' className="material-icons custom-question-icon cm-color orange-color ml-2 custom-question-icon">
                                              schedule_send
                                            </span>
                                          </div>

                                        </div>
                                      </a>

                                  </div><ReactTooltip id={index} className='next-cycle-tooltip' place='top'
                                        effect='solid'>
                                        Question will be sent in the next question cycle.
                                      </ReactTooltip>
                                </React.Fragment>
                              );
                          })}
                  </div>
                </div>
              </div>
            </RenderIf>
          </div>
        </div>
      </React.Fragment >
    );
  }
}
OpenTextDropQuesResponsePanel.propTypes = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenTextDropQuesResponsePanel);
