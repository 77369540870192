import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import Home from './pages/home';
import Settings from './pages/settings';
import { Login, IamSignIn } from './modules/account';
import NotFound from './ui-components/Error';
import Notification from './ui-components/Notification';
import PrivacyPolicy from './modules/account/containers/PrivacyPolicy';
import TermsOfUse from './modules/account/containers/TermsOfUse';
import PrivateRoute from './PrivateRoute.jsx';
import AccountConfirmation from './modules/account/containers/AccountConfirmation.jsx';
import AccountActivation from './modules/account/containers/AccountActivation.jsx';
import RestrictedUser from './modules/account/containers/RestrictedUser';
import authService from './redux/services/authService';
import accountService from './redux/services/accountService';
import constant from './modules/account/actions/constant';
import BrandWrapper from './modules/brandwrapper/component/brandwrapper';
import QuestionPane from './modules/project/containers/QuestionPane.jsx';
import GettingStarted from './pages/GettingStarted/containers/GettingStarted';
import SplashScreen from './pages/splash/containers/SplashScreen';

const routes = ({ redirectUrl }) => <React.Fragment>
    <Notification />
    <BrandWrapper />
    <Switch>
      <Route exact path="/sign-in" component={Login} />
      <Route exact path="/iam-sign-in" component={IamSignIn} />
      <Route path="/information-collection-statement" component={PrivacyPolicy} />
      <Route path="/account-activation" component={AccountActivation} />
      <Route path="/user-restricted" component={RestrictedUser} />
      <Route path="/account-confirmation" component={AccountConfirmation} />
      <Route path="/terms-of-use" component={TermsOfUse} />
      <PrivateRoute path="/splash-screen" component={SplashScreen} />
      <PrivateRoute path="/getting-started" component={GettingStarted} />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRoute path="/project-question-pane/:portfolioId/:projectId" component={QuestionPane} />
      <PrivateRoute path="/" component={Home} />
      <Route component={NotFound} />
    </Switch>
    <IdleTimer
      element={document}
      onIdle={() => {
        if (authService.isAuthenticated()) {
          const { email, user_id: userId } = authService.getUserInfo();
          authService.setSignoutUserEmail(email);

          accountService
            .logUserSignOut(userId, constant.logSignOutMessages.inactivityThresholdMessage)
            .then(() => {
              authService.clearAllStorage();
              // eslint-disable-next-line no-undef
              window.location = `${__CONFIG__.iamSignoutUri}${redirectUrl}`;
            });
        }
      }}
      debounce={250}
      timeout={15 * 60 * 1000}
    />
  </React.Fragment>;

const mapStateToProps = state => ({
  redirectUrl: state.brandwrapper.redirectUrl,
});
export default withRouter(
  connect(
    mapStateToProps,
    null,
  )(routes),
);
// export default connect(
//   mapStateToProps,
//   null,
// )(routes);
