
import jQuery from 'jquery';

!(function (a) { typeof define === 'function' && define.amd ? define(['jquery'], a) : typeof exports === 'object' ? module.exports = a : a(jQuery); }((a) => {
  function b(b) {
    let g = b || window.event,
      h = i.call(arguments, 1),
      j = 0,
      l = 0,
      m = 0,
      n = 0,
      o = 0,
      p = 0; if (b = a.event.fix(g), b.type = 'mousewheel', 'detail' in g && (m = -1 * g.detail), 'wheelDelta' in g && (m = g.wheelDelta), 'wheelDeltaY' in g && (m = g.wheelDeltaY), 'wheelDeltaX' in g && (l = -1 * g.wheelDeltaX), 'axis' in g && g.axis === g.HORIZONTAL_AXIS && (l = -1 * m, m = 0), j = m === 0 ? l : m, 'deltaY' in g && (m = -1 * g.deltaY, j = m), 'deltaX' in g && (l = g.deltaX, m === 0 && (j = -1 * l)), m !== 0 || l !== 0) { if (g.deltaMode === 1) { const q = a.data(this, 'mousewheel-line-height'); j *= q, m *= q, l *= q; } else if (g.deltaMode === 2) { const r = a.data(this, 'mousewheel-page-height'); j *= r, m *= r, l *= r; } if (n = Math.max(Math.abs(m), Math.abs(l)), (!f || f > n) && (f = n, d(g, n) && (f /= 40)), d(g, n) && (j /= 40, l /= 40, m /= 40), j = Math[j >= 1 ? 'floor' : 'ceil'](j / f), l = Math[l >= 1 ? 'floor' : 'ceil'](l / f), m = Math[m >= 1 ? 'floor' : 'ceil'](m / f), k.settings.normalizeOffset && this.getBoundingClientRect) { const s = this.getBoundingClientRect(); o = b.clientX - s.left, p = b.clientY - s.top; } return b.deltaX = l, b.deltaY = m, b.deltaFactor = f, b.offsetX = o, b.offsetY = p, b.deltaMode = 0, h.unshift(b, j, l, m), e && clearTimeout(e), e = setTimeout(c, 200), (a.event.dispatch || a.event.handle).apply(this, h); }
  } function c() { f = null; } function d(a, b) { return k.settings.adjustOldDeltas && a.type === 'mousewheel' && b % 120 === 0; } var e,
    f,
    g = ['wheel', 'mousewheel', 'DOMMouseScroll', 'MozMousePixelScroll'],
    h = 'onwheel' in document || document.documentMode >= 9 ? ['wheel'] : ['mousewheel', 'DomMouseScroll', 'MozMousePixelScroll'],
    i = Array.prototype.slice; if (a.event.fixHooks) for (let j = g.length; j;)a.event.fixHooks[g[--j]] = a.event.mouseHooks; var k = a.event.special.mousewheel = {
    version: '3.1.12',
    setup() { if (this.addEventListener) for (let c = h.length; c;) this.addEventListener(h[--c], b, !1); else this.onmousewheel = b; a.data(this, 'mousewheel-line-height', k.getLineHeight(this)), a.data(this, 'mousewheel-page-height', k.getPageHeight(this)); },
    teardown() { if (this.removeEventListener) for (let c = h.length; c;) this.removeEventListener(h[--c], b, !1); else this.onmousewheel = null; a.removeData(this, 'mousewheel-line-height'), a.removeData(this, 'mousewheel-page-height'); },
    getLineHeight(b) {
      let c = a(b),
        d = c['offsetParent' in a.fn ? 'offsetParent' : 'parent'](); return d.length || (d = a('body')), parseInt(d.css('fontSize'), 10) || parseInt(c.css('fontSize'), 10) || 16;
    },
    getPageHeight(b) { return a(b).height(); },
    settings: { adjustOldDeltas: !0, normalizeOffset: !0 },
  }; a.fn.extend({ mousewheel(a) { return a ? this.bind('mousewheel', a) : this.trigger('mousewheel'); }, unmousewheel(a) { return this.unbind('mousewheel', a); } });
}));
