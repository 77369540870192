import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { ReactSVG } from 'react-svg';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import constant from '../actions/constant';
import authService from '../../../redux/services/authService';
import { login, isLoggedInUserAdmin } from '../actions/login';
import accountConfirmSvg from '../../../../assets/images/component/svg/account-confirm.svg';

class AccountConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authResponse: {},
      hasAccess: false,
    };
    this.redirectToHomePage = this.redirectToHomePage.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      const { authResponse } = this.props.location.state;
      this.setState({ authResponse, hasAccess: authResponse.data.stakeholderHasAccessToDashboard });
    }
  }


  redirectToHomePage() {
    const { homePage } = constant;
    authService.removeSignoutUserEmail();
    this.props.isLoggedInUserAdmin(this.state.authResponse.data.userInfo.isSysAdmin);
    this.props.push(homePage);
  }


  render() {
    const {
      brandName,
    } = this.props;
    return (
      <React.Fragment>
        <div className="public-container">
          <div className="login-page row no-gutters p-0 m-0">
            <div className="left-side">
              <div className="bg-block text-center center-block">
                <div className="logo-box-wrapper center-block">
                  <div className="logo-box" />
                </div>
                <div className="notice-text">
                  <h2>{brandName}</h2>
                  <h3>Capture sentiment and uncover insights</h3>
                </div>
              </div>
            </div>
            <div className="right-side">
              <div className="content center-block justify-content-start align-items-center">
                <div className="activation-wrap">
                  <div className="activation-inner">
                    <div className="activation-icon text-center">
                      <ReactSVG wrapper="svg" width="91px" height="83px" src={accountConfirmSvg} />
                    </div>
                    <h5 className="mt-4 mb-2 text-center">Thanks - your account is ready to use!</h5>
                    <small className="text-center d-block">
                      Please continue the sign-in process on your mobile device.
                    </small>
                  </div>
                  <div className="at-footer">
                    <p>
                      © {constant.currentYear} PwC. All rights reserved.
                      PwC refers to the Australia member firm of
                      the PwC network or one of its subsidiaries or affiliates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  login,
  isLoggedInUserAdmin,
  push,
};

const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
  playStoreUrl: state.brandwrapper.playStoreUrl,
  appStoreUrl: state.brandwrapper.appStoreUrl,
  baseUrl: state.brandwrapper.baseUrl,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountConfirmation);
