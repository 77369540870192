import * as React from 'react';

const ExcelSvg = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={17}
    fill="none"
    {...props}
    style={{ marginRight: '8px' }}
  >
    <path
      fill="#2D2D2D"
      fillRule="evenodd"
      d="M14.438 3.5H1v10.75h14.333V4.396a.896.896 0 0 0-.896-.896ZM1.896 13.354h12.541V4.396H1.896v8.958Zm3.941-6.22-.59 1.174-.58-1.175h-.952L4.74 8.86l-1.053 1.756h.96l.61-1.198.61 1.198h1.008L5.771 8.86l1.026-1.727h-.96Zm2.148 2.86h1.537v.622H7.153V7.133h.832v2.861Zm3.704-.587a.343.343 0 0 1 .126.286.319.319 0 0 1-.128.263c-.086.067-.215.1-.389.1-.212 0-.368-.042-.471-.127-.102-.086-.154-.23-.154-.432h-.809l-.005.014c-.008.399.128.691.41.877.28.186.624.279 1.029.279.407 0 .733-.087.979-.26a.83.83 0 0 0 .369-.719c0-.3-.107-.536-.32-.708-.213-.172-.527-.318-.942-.438a2.648 2.648 0 0 1-.5-.23.284.284 0 0 1-.135-.246c0-.105.044-.193.13-.265a.544.544 0 0 1 .357-.108c.186 0 .324.037.416.112.092.074.138.193.138.357h.807l.005-.014c.008-.338-.113-.6-.362-.786-.25-.186-.578-.279-.986-.279-.387 0-.706.09-.957.27a.833.833 0 0 0-.376.709c0 .298.107.526.323.685.216.159.546.308.99.449.22.074.371.145.455.211Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ExcelSvg;
