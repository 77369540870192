import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import csvParse from 'papaparse';
import Flow from '@flowjs/flow.js';
import moment from 'moment';
import { ReactSVG } from 'react-svg';
import { SpinnerButton } from '../Loader';
import CSVDownloaderUI from './CSVDownloaderUI.jsx';
import downloadCsvIcon from '../../../assets/images/component/svg/download-light-outline.svg';
import { validateUserByEmail } from '../../modules/account/actions/iam';
import './UserCsvStyle.scss';
import { CSV_CONSTANTS } from '../../Constants';
import { checkEmptyCSV, generateFinalStackHolderWithPwc } from '../../utils/csvHelper';
import { isInternalPwcUser } from '../../utils/helper';
import { hideRecordProcessing } from '../../modules/project/actions/project';

const {
  DEFAULT_SAMPLE_CSV, ERRORS, BUTTON_TEXT, ROWS_FOR_PREVIEW,
} = CSV_CONSTANTS;

// const mapStateToProps = state => ({ state });
const mapStateToProps = state => ({
  state,
  passedRecords: state.processingReducer.passedRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
});
const mapDispatchToProps = {
  validateUserByEmail,
  hideRecordProcessing,
};
class UserCsvModel extends Component {
  state = {
    isLoading: false,
    showPreview: false,
    correctUsersForCsv: [],
    errorUsersCsv: [],
    errorDetect: false,
    allPwcUsers: [],
    keyNameOfEmailColumn: 'Email',
    disableSubmitButton: false,
    errorMessage: '',
    isSubmitted: false,
    totalEmails: [],
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#userCsvModal').modal('show');
    this.multiUpload(false, '#multi-file-target1', '#multi-upload1');
  }

  multiUpload(isTrigger, fileTargetID, dom) {
    const self = this;
    const multiFlow = new Flow({
      target: '/',
      query: {},
    });
    const uploadArray = [];

    multiFlow.assignBrowse($(fileTargetID));
    multiFlow.assignDrop($(fileTargetID));
    function cancelBind(indexClass, file) {
      $(`${dom} .${indexClass} .icon-circle-delete-outline`).on('click', () => {
        file.cancel();
        $(`${dom} .${indexClass}`).remove();
        $('.tooltip').remove();
        if (multiFlow.files.length === 0) {
          $(`${dom} .trigger`).attr('disabled', 'true');
        }
      });
    }
    multiFlow.on('filesAdded', (array) => {
      const uploadArrayLength = uploadArray.length;
      $.each(array, (index, file) => {
        uploadArray.push(file);
        const indexNum = uploadArrayLength + index;
        const fileListTemplate = `<div class="fileList index${indexNum}">`
          + `<div class="upload-success">
                    <span class="icon-span-lg ft-wrapper"><span class="fileType"></span></span>
                    <span class="fileSpan">
                        <span class="fileName multiple-filename"></span>
                        <span class="fileSize"></span>
                    </span>
                    <div class="progress hidden new">
                        <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    <span class="date hidden"></span>
                    <span class="icon-wrapper">
                        <button class="appkiticon icon-circle-delete-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Delete" data-container="body"></button>
                    </span>
                </div>
                <div class="upload-error hidden">
                    <span class="label label-danger">Error</span>
                    <span class="message"></span>
                    <span class="icon-wrapper">
                        <button class="appkiticon icon-refresh-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Retry" data-container="body"></button>
                        <button class="appkiticon icon-circle-delete-outline tooltip-link" data-placement="top" data-toggle="tooltip" data-original-title="Delete" data-container="body"></button>
                    </span>
                </div>
            </div >`;

        const fileFullName = file.name;
        const flagIdx = fileFullName.lastIndexOf('.');
        const fileType = fileFullName.substring(flagIdx + 1);
        const fileName = file.name;

        let iconType = 'file';
        if (fileType === 'csv') {
          iconType = fileType;
        }

        const iconClass = `fileType appkiticon icon-${iconType}-fill`;
        const indexClass = `index${indexNum}`;

        $(dom).append(fileListTemplate);
        $(`${dom} .${indexClass} .upload-success .fileType`).removeClass();
        $(`${dom} .${indexClass} .upload-success .ft-wrapper span:first-child`).addClass(iconClass);
        $(`${dom} .${indexClass} .upload-success span.fileName`).html(fileName);
        $(`${dom} .${indexClass} .upload-success span.fileName`).attr('title', fileName);

        cancelBind(indexClass, file);
      });
    });

    multiFlow.on('filesSubmitted', (array) => {
      if (!isTrigger) {
        $(`${dom} .upload-success .progress.new`).removeClass('hidden new');
        multiFlow.upload();
      } else if (array.length !== 0) {
        $(`${dom} .trigger`).removeAttr('disabled');
      }
    });

    if (isTrigger) {
      $(`${dom} .trigger`).on('click', () => {
        $(`${dom} .upload-success .progress.new`).removeClass('hidden new');
        multiFlow.upload();
        $(`${dom} .trigger`).attr('disabled', 'true');
      });
    }

    multiFlow.on('fileProgress', (file) => {
      const date = moment().format('MMM DD,YYYY');
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${index}`;
        }
      });
      const progressSize = `${file.progress() * 100}%`;
      $(`${dom} .${indexClass} .upload-success .progress-bar`).css('width', progressSize);

      const fileSizeMB = (file.size / 1024 / 1024).toFixed(1);
      const fileSizeKB = (file.size / 1024).toFixed(1);
      let fileSize;
      if (fileSizeMB < '1') {
        fileSize = `${fileSizeKB}KB`;
      } else {
        fileSize = `${fileSizeMB}MB`;
      }

      if (file.isComplete()) {
        setTimeout(() => {
          $(`${dom} .${indexClass} .upload-success .progress`).addClass('hidden');
          $(`${dom} .${indexClass} .upload-success span.date`).removeClass('hidden');
          $(`${dom} .${indexClass} .upload-success span.date`).html(date);
          $(`${dom} .${indexClass} .upload-success .icon-circle-delete-outline`).toggleClass('icon-circle-delete-outline icon-delete-outline');
          $(`${dom} .${indexClass} .upload-success span.fileSize`).html(fileSize);
        }, 1000);
      }

      if ($(`${dom} .${indexClass} .icon-circle-delete-outline`).length) {
        cancelBind(indexClass, file);
      }
    });

    multiFlow.on('fileSuccess', (file, message, value) => {
      const blob = new Blob([value.bytes], { type: 'text/csv' });
      csvParse.parse(blob, {
        header: true,
        skipEmptyLines: true,
        complete(results) {
          self.updateShowPreview({
            ...results,
            data: results.data.map((val) => {
              const obj = {};
              Object.keys(val).forEach((item) => {
                if (item) {
                  obj[item] = val[item];
                }
              });
              return obj;
            }),
          });
        },
      });
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${index}`;
        }
      });
      setTimeout(() => {
        $(`${dom} .${indexClass} .icon-delete-outline`).on('click', () => {
          file.cancel();
          $(`${dom} .${indexClass}`).remove();
          $('.tooltip').remove();
        });
      }, 600);
    });

    multiFlow.on('fileError', (file) => {
      let indexClass;
      $.each(uploadArray, (index, flowFile) => {
        if (file === flowFile) {
          indexClass = `index${index}`;
        }
      });

      const errorMessage = `Failed to upload file “${file.name}”`;
      $(`${dom} .${indexClass} .upload-success`).addClass('hidden');
      $(`${dom} .${indexClass} .upload-error`).removeClass('hidden');
      $(`${dom} .${indexClass} .upload-error span.message`).html(errorMessage);

      $(`${dom} .${indexClass} .icon-refresh-outline`).on('click', () => {
        file.retry();
      });
      cancelBind(indexClass, file);
    });
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  modalWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.cancelClick();
    }
  };

  validateEmail = (email) => {
    if (!email) {
      return false;
    }
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email.trim())) {
      return true;
    }
    return false;
  };

  cancelClick = () => {
    this.hideModal();
    this.props.cancelClick();
  };

  hideModal = () => {
    $('#userCsvModal').modal('hide');
  };

  showSpinner = (isLoading) => {
    this.setState({ isLoading });
  };

  getRemovedFalsyKeys = (csvData = []) => {
    const removedFalsyKeys = csvData.filter(
      obj => !Object.keys(obj).every(x => !!x),
    );
    let emptyKeysFor = [];
    if (removedFalsyKeys.length) {
      emptyKeysFor = removedFalsyKeys.map(item => ({
        ...item,
        [ERRORS.ErrorColumnName]: ERRORS.unknown,
      }));
    }
    return emptyKeysFor;
  }

  getEmptyEmailsRecords = (csvData = []) => {
    const isLastRowEmpty = Object.values(csvData[csvData.length - 1]).every(x => x === null || x === '');
    if (isLastRowEmpty) {
      csvData.pop();
    }
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const getEmptyEmailRecord = csvData.filter(obj => obj[keyNameOfEmailColumn].length === 0);
    return getEmptyEmailRecord.map(item => ({
      ...item,
      [ERRORS.ErrorColumnName]: ERRORS.emptyEmail,
    }));
  }

  getUnknownIncorrectErrors = (csvData) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);
    const existingEmails = filterOutEmptyEmails.map(
      item => item[keyNameOfEmailColumn],
    );
    const invalidEmails = existingEmails
      .filter(email => !this.validateEmail(email));

    const updatedResponse = filterOutEmptyEmails
      .filter(item => invalidEmails.indexOf(item[keyNameOfEmailColumn]) !== -1);
    const addErrorColumn = updatedResponse.map((item) => {
      const errorMessage = !item[keyNameOfEmailColumn] ? ERRORS.unknown : ERRORS.incorrect;
      return {
        ...item,
        [ERRORS.ErrorColumnName]: errorMessage,
      };
    });

    return addErrorColumn;
  }

  getAllPwcUsers = (csvData) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);
    return filterOutEmptyEmails
      .filter(item => isInternalPwcUser(item[keyNameOfEmailColumn]));
  }

  removeAllDuplicateEmailsInCsv = (csvData) => {
    const { availableEmails = [] } = this.props;
    const existingEmail = availableEmails.map(email => email.trim().toLowerCase());
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    // eslint-disable-next-line consistent-return
    const duplicate = csvData.filter((csv) => {
      const csvEmail = csv[keyNameOfEmailColumn].trim().toLowerCase();
      if (!existingEmail.includes(csvEmail)) {
        return csv;
      }
    });
    return duplicate;
  };

  getDuplicateEmailsInCsv = (csvData, finalErrors) => {
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    const allErrorEmails = finalErrors.map(obj => obj[keyNameOfEmailColumn].trim().toLowerCase());
    const csvDataWithEmail = csvData
      .map(csv => ({
        ...csv,
        keyNameOfEmailColumn: csv[keyNameOfEmailColumn].trim().toLowerCase(),
      }));
    const duplicateUsers = csvDataWithEmail.filter((e, i, a) => a
      .map(item => item[keyNameOfEmailColumn]).indexOf(e[keyNameOfEmailColumn]) !== i)
      .map(item => ({
        ...item,
        [ERRORS.ErrorColumnName]: ERRORS.duplicate,
      }));
    const finalDuplicate = duplicateUsers.filter((csv) => {
      const csvEmail = csv[keyNameOfEmailColumn].trim().toLowerCase();
      return !allErrorEmails.includes(csvEmail);
    });
    return finalDuplicate;
  };

  getCorrectCsvEmails = (csvData, allEmailsWithErrors, keyNameOfEmailColumn) => {
    const filterOutEmptyEmails = csvData.filter(item => item[keyNameOfEmailColumn]);

    return filterOutEmptyEmails.filter(
      item => !allEmailsWithErrors.filter(
        errorEmail => errorEmail[keyNameOfEmailColumn] === item[keyNameOfEmailColumn],
      ).length,
    );
  }

  getAllOccuranceOfDuplicateExistingEmail = (csvData = []) => {
    const { availableEmails = [] } = this.props;
    const existingEmail = availableEmails.map(email => email.trim().toLowerCase());
    const keyNameOfEmailColumn = Object.keys(csvData[0])[0];
    // eslint-disable-next-line consistent-return
    const existingPropsEmail = csvData.filter((csv) => {
      const csvEmail = csv[keyNameOfEmailColumn].trim().toLowerCase();
      if (existingEmail.includes(csvEmail)) {
        return csv;
      }
    }).map(item => ({ ...item, [ERRORS.ErrorColumnName]: ERRORS.duplicate }));
    return existingPropsEmail;
  }

  getWrongEntries = (resultData = []) => {
    const emptyKeysFor = this.getRemovedFalsyKeys(resultData);
    const unknownIncorrectErrors = this.getUnknownIncorrectErrors(resultData);
    const getEmptyEmailsRecords = this.getEmptyEmailsRecords(resultData);
    const getDuplicateExistingRecords = this.getAllOccuranceOfDuplicateExistingEmail(resultData);

    return unknownIncorrectErrors
      .concat(emptyKeysFor).concat(getEmptyEmailsRecords).concat(getDuplicateExistingRecords);
  }

  updateShowPreview = async (result) => {
    const resultData = [...result.data];
    this.setState({ disableSubmitButton: false });
    if (checkEmptyCSV(resultData)) {
      this.setState({ disableSubmitButton: true, errorMessage: ERRORS.emptyCSV }); return;
    }
    const keyNameOfEmailColumn = Object.keys(result.data[0])[0];
    const finalErrors = this.getWrongEntries(resultData);
    // eslint-disable-next-line max-len
    const csvAfterRemovingLastDuplicate = result.data.filter((v, i, a) => a.findIndex(v2 => (v[keyNameOfEmailColumn] && v[keyNameOfEmailColumn].trim().toLowerCase().length && v2[keyNameOfEmailColumn].trim().toLowerCase() === v[keyNameOfEmailColumn].trim().toLowerCase())) === i);
    const preFinalCsv = this.getCorrectCsvEmails(
      csvAfterRemovingLastDuplicate,
      finalErrors,
      keyNameOfEmailColumn,
    );
    const getDuplicateEmailsInCsv = this.getDuplicateEmailsInCsv(resultData, finalErrors);
    if (preFinalCsv.length === 0 && finalErrors.length > 0) {
      this.setState({
        correctUsersForCsv: [],
        errorUsersCsv: finalErrors.concat(getDuplicateEmailsInCsv),
        allPwcUsers: [],
        keyNameOfEmailColumn,
      });
      return;
    }
    const finalCsv = this.removeAllDuplicateEmailsInCsv(preFinalCsv);
    if (finalCsv.length === 0 && finalErrors.length > 0) {
      this.setState({
        correctUsersForCsv: [],
        errorUsersCsv: finalErrors.concat(getDuplicateEmailsInCsv),
        allPwcUsers: [],
        keyNameOfEmailColumn,
      });
      return;
    }
    const allPwcUsers = this.getAllPwcUsers(finalCsv);
    this.setState({ isLoading: true });
    const finalCsvList = await generateFinalStackHolderWithPwc(
      finalCsv,
      allPwcUsers,
      keyNameOfEmailColumn,
      ERRORS,
    );
    this.setState({ isLoading: false });
    this.setState({
      correctUsersForCsv: finalCsvList.validStackHolderList,
      errorUsersCsv: finalErrors.concat(finalCsvList.invalidPwcUsers)
        .concat(getDuplicateEmailsInCsv),
      allPwcUsers,
      keyNameOfEmailColumn,
    });
  };

  defaultImportAndContinueBtn = () => {
    this.setState({
      showPreview: false,
      errorDetect: false,
      correctUsersForCsv: [],
      errorUsersCsv: [],
    });
  }

  onUploadNewCsv = () => {
    this.setState({ isSubmitted: false });
    const activeState = this.renderFooterButtonText();
    if (activeState === BUTTON_TEXT.upload) {
      this.defaultImportAndContinueBtn();
    }
  }

  onImportAndContinue = () => {
    this.setState({ isSubmitted: false });
    const { correctUsersForCsv, errorUsersCsv } = this.state;
    if (!correctUsersForCsv.length && errorUsersCsv.length) {
      this.setState({
        errorDetect: true,
      });
    } else if (correctUsersForCsv.length) {
      this.setState({
        showPreview: true,
      });
    }
  }

  capitalizeFirstLetter(key, value) {
    return key === this.state.keyNameOfEmailColumn
      ? value.toLowerCase() : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

  onImportCsv = () => {
    this.setState({ isSubmitted: true });
    const {
      correctUsersForCsv, errorUsersCsv, keyNameOfEmailColumn,
    } = this.state;
    const formattedEmails = correctUsersForCsv
      .map(user => user[keyNameOfEmailColumn].toLowerCase());
    this.setState({ totalEmails: formattedEmails });
    const formattedCsv = correctUsersForCsv.map(user => ({
      ...user,
      [keyNameOfEmailColumn]: user[keyNameOfEmailColumn].toLowerCase(),
    }));
    const lowerCaseData = formattedCsv.map(x => Object.fromEntries(Object.entries(x).map(
      ([key, value]) => [key, typeof value === 'string' ? this.capitalizeFirstLetter(key, value) : value],
    )));
    this.showSpinner(true);
    this.props
      .onContinueButtonClick(formattedEmails, lowerCaseData)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        if (response.length === 0) {
          if (errorUsersCsv.length) {
            this.setState({
              errorDetect: true,
              errorUsersCsv,
              isSubmitted: false,
            });
          }
          if (lowerCaseData.length) {
            this.props.updateGroupModel(keyNameOfEmailColumn);
          }
          this.showSpinner(false);
          this.props.hideRecordProcessing();
          if (errorUsersCsv.length) {
            this.setState({
              errorDetect: true,
              isSubmitted: false,
            });
          } else {
            this.hideModal();
          }
        }
      });
  };

  onClickImport = () => {
    const activeState = this.renderFooterButtonText();
    if (activeState === BUTTON_TEXT.upload) {
      this.onUploadNewCsv();
      return;
    }
    if (activeState === BUTTON_TEXT.continue) {
      this.onImportAndContinue();
    } else {
      this.onImportCsv();
    }
  };

  renderHeading = () => {
    const { showPreview, errorDetect } = this.state;
    if (errorDetect) {
      return 'Users with errors';
    }
    if (!showPreview) {
      return 'Import users from CSV';
    }
    return 'Preview your first user';
  };

  renderFooterButtonText = () => {
    const { showPreview, errorDetect } = this.state;
    if (errorDetect) {
      return BUTTON_TEXT.upload;
    }
    if (!showPreview) {
      return BUTTON_TEXT.continue;
    }
    return BUTTON_TEXT.import;
  };

  renderMainContent = () => {
    const {
      showPreview,
      correctUsersForCsv,
      errorDetect,
      errorUsersCsv,
      disableSubmitButton,
      errorMessage,
    } = this.state;
    const previewFilterUser = correctUsersForCsv[0] || {};
    if (errorDetect) {
      return (
        <div>
          <div>
            <div>
              <b>
                {errorUsersCsv.length} users could not be imported due to problems with your CSV
                file.
              </b>
            </div>
            <div>
              Review the errors in this exceptions list and amend your data before uploading.
            </div>
          </div>
          <div className="error-detect-footer">
            <ReactSVG wrapper="svg" width="16" height="16" src={downloadCsvIcon} />
            <CSVDownloaderUI
              title="Download CSV file with errors"
              data={errorUsersCsv}
              filename={'CSV_with_errors'}
            />
          </div>
        </div>
      );
    }

    if (!showPreview) {
      return (
        <div>
          <div>
            Download a{' '}
            <CSVDownloaderUI
              title="sample CSV template"
              data={DEFAULT_SAMPLE_CSV}
              filename={'sample_csv'}
            />{' '}
            to use as a template.
          </div>
          <div class="a-p-20 d-table w-100 text-center">
            <div class="a-multi-upload-file" id="multi-upload1">
              <button aria-label="Press to select file" class="upload-drop multi" id="multi-file-target1" tabindex="0">
                <span class="drop-span">Drag and drop or</span>
                <span class="browse-span">&nbsp;choose your files <input aria-label="Hidden file upload input" type="file" /></span>
                <input aria-label="Hidden file upload input" style={{
                  visibility: 'hidden', position: 'absolute', width: '1px', height: '1px',
                }} type="file" />
              </button>
            </div>
          </div>
          <div className="microsoft-block">
            Note - Only csv file formats are supported.
          </div>
          {disableSubmitButton && <div className="microsoft-error">
            {errorMessage}
          </div>}
        </div>
      );
    }
    return (
      <div>
        <div>
          You will be importing{' '}
          <b>
            {correctUsersForCsv.length} user
            {correctUsersForCsv.length > 0 ? 's' : ''}.
          </b>
        </div>
        <div className="preview-list">
          {Object.keys(previewFilterUser).slice(0, ROWS_FOR_PREVIEW).map((key, index) => (
            <div className="preview-item" key={index}>
              <div className="key">{key}</div>
              <div className="value">{previewFilterUser[key]}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    // const { isLoading, disableSubmitButton } = this.state;
    const {
      isLoading, disableSubmitButton, totalEmails, isSubmitted,
    } = this.state;
    const { passedRecords, isProcessingRecords } = this.props;
    return (
      <div
        aria-hidden="true"
        className="modal a-modal fade user-csv-modal add-stakeholder-modal "
        id="userCsvModal"
        role="dialog"
        ref={this.modalWrapperRef}
        data-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header ">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h4">{this.renderHeading()}</div>
              </div>
              <span className="icon-wrapper">
                <a className={this.state.isLoading ? 'disabled' : ''}>
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    data-bs-dismiss="modal"
                  />
                </a>
              </span>
            </div>
            <div className="a-modal-body pt-0 mt-1">
              {this.renderMainContent()}
            </div>
            <div className="a-modal-footer a-border-tp justify-content-end c-question-ask">
              {isProcessingRecords && isSubmitted && (<span className='preview-pane-font' style={{
                position: 'absolute', left: 19, bottom: 20, fontSize: 10,
              }}>
                {passedRecords} of  {`${totalEmails.length} `}
                users assigned successfully</span>

              )}
              <button
                disabled={isLoading}
                style={{ fontSize: '0.875rem' }}
                className="btn c-question-cancel a-btn a-btn-secondary a-btn-lg"
                onClick={() => this.cancelClick()}
              >
                Cancel
              </button>
              <SpinnerButton
                isLoading={isLoading}
                disabled={isLoading}
                label={this.renderFooterButtonText()}
                onClick={this.onClickImport}
                disableSubmitButton={disableSubmitButton}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCsvModel);
