import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../../ui-components/Error';
import { Projects } from '../../modules/project';
import Portfolio from '../../modules/portfolio';
import QuestionPane from '../../modules/project/containers/QuestionPane.jsx';

export default () => (
  <Switch>
    <Route exact path="/" component={Portfolio} />
    <Route exact path="/portfolio-dashboard/:id" component={Portfolio} />
    <Route exact path="/portfolio-dashboard/:id/:emailId" component={Portfolio} />
    <Route exact path="/project-dashboard/:portfolioId/:projectId" component={Projects} />
    <Route exact path="/project-dashboard/:portfolioId/:projectId/:emailId" component={Projects} />
    <Route exact path="/question-pane/:projectId" component={QuestionPane} />
    <Route component={NotFound} />
  </Switch>
);
