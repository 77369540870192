import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import Cryptr from 'cryptr';
import ReactTooltip from 'react-tooltip';
import { Formik, Field } from 'formik';
import MaterialIcon from 'material-icons-react';
import { setTimeout } from 'timers';
import AddDomain from '../components/AddDomain.jsx';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import AlertPopup from '../../../../ui-components/AlertPopup/AlertPopup.jsx';
import RenderIf from '../../../../ui-components/Common';
import { Spinner } from '../../../../ui-components/Loader';
import { NO_OF_RECORDS, INITIAL_PAGINATED_DATA, FEEDBACK_PAGE_REF } from '../../../../Constants';
import Pagination from '../../../../ui-components/Pagination/Pagination.jsx';
import {
  getQuestionModels,
  changeSelectedQuestionModel,
} from '../actions/questionModel';
import {
  setHeading, getDomains,
  toggleAllCheckboxes, toggleCheckbox,
  deleteDomains, canDelete, canBulkDelete,
  modifyAddedDomainStatus,
  changeAddedDomainStatus,
} from '../actions/domain';
import { domainSelector } from '../selectors/domain';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';

const mapStateToProps = state => ({
  domain: domainSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const cryptr = new Cryptr(__CONFIG__.encryptSecret);
const mapDispatchToProps = {
  getDomains,
  setHeading,
  toggleAllCheckboxes,
  toggleCheckbox,
  canDelete,
  canBulkDelete,
  deleteDomains,
  modifyAddedDomainStatus,
  changeAddedDomainStatus,
  getQuestionModels,
  changeSelectedQuestionModel,
};

const messages = {
  cannotDeleteDomainTitle: 'Cannot Delete Focus Area',
  cannotDeleteDomainMessage: `This focus area is associated to some question which has one or more associated
    responses and it cannot be deleted.`,
  cannotDeleteMultipleDomainsTitle: 'Cannot Delete Focus Area',
  cannotDeleteMultipleDomainsMessage: `One or more of the selected focus areas are associated to some 
    question which has one or more associated responses and it cannot be deleted.`,
  deleteDomainTitle: 'Delete a Focus area',
  deleteDomainMessage: `This domain will be permanently deleted, and all 
    associated attributes will be unassigned from this focus area. 
    Are you sure you want to continue?`,
  deleteMultipleDomainsTitle: 'Delete Selected Focus Areas',
  deleteMultipleDomainsMessage: `The selected focus areas will be permanently deleted and all 
    associated attributes will be unassigned from the focus areas. 
    Are you sure you want to continue?`,
};


class Domain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDomain: {},
      heading: 'Focus Area',
      animate: false,
      showAddDomain: false,
      showDelete: false,
      showAlertPopup: false,
      alertPopupTitle: '',
      alertPopupMessage: '',
      selectedDomainIdsForDelete: [],
      deleteTitle: '',
      deleteConfirmationMessage: '',
      spinner: { isLoading: true },
      currentPage: 1,
      totalPages: 1,
      showHideDescriptionTooltip: false,
      tooltipText: '',
      showIndexValue: '',
      showIndexFieldValue: '',
      questionModelId: 0,
      selectedKeyword: '',
      listLoading: true,
      isFilterResetting: false,
    };
    this.showAddDomainPanel = this.showAddDomainPanel.bind(this);
    this.hideAddDomainPanel = this.hideAddDomainPanel.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmBulkDelete = this.confirmBulkDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteDomains = this.deleteDomains.bind(this);
    this.alertPopupOkClick = this.alertPopupOkClick.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  componentDidMount() {
    const questionModelId = this.props.match.params.id;
    const decryptQuestionModelId = cryptr.decrypt(questionModelId);
    if (questionModelId || this.props.questionModelId) {
      this.props.changeSelectedQuestionModel(decryptQuestionModelId, 'domains');
      this.getDomainsList(null, INITIAL_PAGINATED_DATA, decryptQuestionModelId);
    }
    this.setState({ questionModelId: parseInt(decryptQuestionModelId, 10) });
    this.props.setHeading(this.state.heading);
  }

  getTotalNumOfPages() {
    return this.props.domain.domainsCount !== 0
      ? Math.ceil(this.props.domain.domainsCount / NO_OF_RECORDS) : 1;
  }

  getDomainsList = async (filters, paginationData, questionModelId) => {
    this.setState({ listLoading: true });
    await this.props.getDomains(filters, paginationData, questionModelId).then(() => {
      this.setState({
        listLoading: false,
        totalPages: this.getTotalNumOfPages(),
        isFilterResetting: false,
      });
      this.setState(prevState => ({
        spinner: {
          ...prevState.spinner,
          isLoading: false,
        },
      }));
    });
    this.props.getQuestionModels().then((response) => {
      if (response.data && response.data.length
        && this.props.questionModelId === 0) {
        this.props.changeSelectedQuestionModel(questionModelId);
      }
    });
  }

  filterDomain(filters) {
    this.setState({
      selectedKeyword: filters,
      currentPage: 1,
    });
    this.getDomainsList(filters, INITIAL_PAGINATED_DATA, this.props.questionModelId);
  }

  showAddDomainPanel() {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddDomain: true,
        animate: true,
        selectedDomain: {},
      });
    }, 300);
  }

  editDomain(obj) {
    this.setState({
      showAddDomain: true,
      animate: true,
      selectedDomain: obj,
    });
  }

  hideAddDomainPanel = async () => {
    const { isDomainAddedOrModified, isDomainAdded, domainsList } = this.props.domain;
    const { currentPage, selectedKeyword } = this.state;
    this.setState({
      showAddDomain: false,
      animate: false,
      selectedDomain: {},
    });
    if (isDomainAddedOrModified) {
      await this.getDomainsList(selectedKeyword, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: currentPage - 1,
      }, this.props.questionModelId);
      this.props.modifyAddedDomainStatus(false);
    } else if (isDomainAdded) {
      await this.getDomainsList(selectedKeyword, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: currentPage - 1,
      }, this.props.questionModelId);
      const { totalPages } = this.state;
      if (domainsList && domainsList.length === 10) {
        if (currentPage < totalPages) {
          const diff = totalPages - currentPage;
          const newPage = currentPage + diff;
          this.updateCurrentPage(newPage, true);
        } else {
          const newPage = totalPages + 1;
          this.updateCurrentPage(newPage, true);
        }
      }
    }
    this.props.changeAddedDomainStatus(false);
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  // Confirm Delete
  confirmDelete(domainId) {
    if (this.props.domain.domainsList.length > 0) {
      this.props.canDelete(domainId).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedDomainIdsForDelete: [domainId],
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteDomainTitle,
            deleteConfirmationMessage: messages.deleteDomainMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteDomainTitle,
            alertPopupMessage: messages.cannotDeleteDomainMessage,
          });
        }
      });
    }
  }

  confirmBulkDelete() {
    if (this.props.domain.domainsList.length > 0) {
      const selectedIds = [];
      this.props.domain.domainsList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });

      this.props.canBulkDelete(selectedIds).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedDomainIdsForDelete: selectedIds,
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteMultipleDomainsTitle,
            deleteConfirmationMessage: messages.deleteMultipleDomainsMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteMultipleDomainsTitle,
            alertPopupMessage: messages.cannotDeleteMultipleDomainsMessage,

          });
        }
      });
    }
  }

  cancelDelete() {
    this.setState({
      selectedDomainIdsForDelete: [],
      showDelete: false,
    });
  }

  // Delete Project Life Cycle Stages
  deleteDomains() {
    return this.props
      .deleteDomains({ selectedIds: this.state.selectedDomainIdsForDelete })
      .then((response) => {
        const { currentPage } = this.state;
        this.props.getDomains(INITIAL_PAGINATED_DATA).then(() => {
          if (this.state.currentPage > this.getTotalNumOfPages()) {
            this.goToPage(this.state.currentPage - 1);
          } else this.goToPage(this.state.currentPage);
          const totalNoOfPages = this.getTotalNumOfPages();
          this.setState({
            totalPages: totalNoOfPages,
            selectedDomainIdsForDelete: [],
            currentPage: currentPage > totalNoOfPages ? totalNoOfPages : currentPage,
          });
        });
        return response;
      });
  }

  alertPopupOkClick() {
    this.setState({
      selectedDomainIdsForDelete: [],
      showDelete: false,
      showAlertPopup: false,
    });
  }

  // paging code
  nextPage() {
    this.setState({ showDelete: false });
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) this.updateCurrentPage((currentPage + 1), true);
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage((currentPage - 1), true);
  }

  updateCurrentPage(pageNum, status) {
    this.setState({
      currentPage: parseInt(pageNum, 10),
      isLoading: false,
      showDelete: false,
    });
    if (status) {
      this.getDomainsList(null, { noOfRecords: NO_OF_RECORDS, pageNo: (pageNum - 1) },
        this.props.questionModelId);
    }
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value, true);
    } else {
      this.updateCurrentPage(1, true);
    }
  }

  pageOnChangeEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateCurrentPage(value, false);
  }

  hideTooltip() {
    this.setState({
      showHideDescriptionTooltip: false,
      tooltipText: null,
      showIndexValue: null,
      showIndexFieldValue: '',
    });
  }

  showTooltip(obj, indexValue, fieldType) {
    this.setState({
      showHideDescriptionTooltip: true,
      tooltipText: obj,
      showIndexValue: indexValue,
      showIndexFieldValue: fieldType,
    });
  }

  truncate(s) {
    if (s) {
      const n = s.length;
      if (n > 100) {
        const trimmedString = s.substring(0, 100);
        return `${trimmedString}...`;
      }
    }
    return s;
  }

  resetFilters() {
    this.setState({
      selectedKeyword: '',
      isFilterResetting: true,
    });
    this.filterDomain('');
    setTimeout(() => {
      this.setState({
        isFilterResetting: false,
      });
    }, 1);
  }

  render() {
    const { domain } = this.props;
    const {
      spinner, totalPages, currentPage, listLoading, isFilterResetting,
    } = this.state;
    const domainModel = {
      selectedKeyword: this.state.selectedKeyword,
    };
    return (
      <div>
        <Spinner isLoading={spinner.isLoading} />
        <RenderIf showComponent={!spinner.isLoading}>
          <div className="attributes-container">
            <div className="row domain-heading-row">
              <div className="col-md-6 col-sm-12 col-12 col-lg-3">
                <p className="a-font-sm m-0 mb-3">
                  Focus Areas are top-level categories for questions. Once you have defined a set of
                  focus areas, you can configure associated attributes and a set of questions for
                  evaluating the focus area.
                </p>
              </div>
            </div>
            <div class="row justify-content-center align-items-center pb-1 pt-1 m-0 mb-3">
              <div class="col">
                {!isFilterResetting && <Formik
                  enableReinitialize={true}
                  initialValues={{ domainModel }}
                >
                  {({ setFieldValue, values }) => (
                    <div class="row align-items-center">
                      <div class="col-md-6 col-lg-3 ml-auto">
                        <div class="row align-items-center">
                          <div class="col">
                            <div class="input-group-with-icon">
                              <Field
                                name='selectedKeyword'
                                placeholder="Search"
                                class="w-100 custom-input-filter-icon"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue('selectedKeyword', e.target.value);
                                  values.selectedKeyword = e.target.value;
                                  this.filterDomain(e.target.value);
                                }}
                              />
                              <MaterialIcon className="material-icons md-24 text-theme-color" icon="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>}
              </div>
              {/* <div class="col-auto">
                <button disabled={isEnableResetButton} href="javascript:void(0)"
                  class="text-theme-color text-center d-block pl-2 pr-2"
                  onClick={() => this.resetFilters()}
                >
                  RESET</button>
              </div> */}
            </div>
            <div className="">
              <Spinner isLoading={listLoading} />
              <RenderIf showComponent={!listLoading}>
                <div className=" a-table-scroller domain-table col-md-12 col-sm-12 col-12 col-lg-12 a-mb-10 pr-0 pl-0">
                  <table className="a-table data-table a-lg min-w-1024 sortable selectable setting-table">
                    <thead className="a-thead">
                      <tr>
                        {domain.domainsList.length > 0 ? (
                          <th className="a-checkbox-cell">
                            <label className="a-checkbox black-checkbox">
                              <input
                                type="checkbox"
                                onChange={this.toggleAllCheckboxes}
                                checked={
                                  domain.domainsList.filter(item => item.isChecked).length > 0
                                }
                              />
                              <span className="a-checkbox-mark">
                                <span
                                  className={`appkiticon   
                        ${domain.domainsList
                                      .filter(item => item.isChecked).length > 0
                                      && !domain.selectAll
                                      ? 'icon-minus-fill'
                                      : ''
                                    }  
                        ${domain.domainsList
                                      .filter(item => item.isChecked).length > 0
                                      && domain.selectAll
                                      ? 'icon-check-mark-fill'
                                      : ''
                                    }`}
                                />
                              </span>
                            </label>
                          </th>
                        ) : (
                          ''
                        )}
                        <th className="a-title-cell">Focus Area Title</th>
                        <th className="a-domain-cell">Focus Area Description</th>
                        <th className="a-domain-cell">Enforce Anonymity Rules</th>
                        <th className="t-cross-header a-cross">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="a-tbody">
                      {domain.domainsList.length > 0 ? (
                        domain.domainsList.map(
                          (obj, index) => (

                            <tr key={index}>
                              <td className="a-checkbox-cell">
                                {' '}
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={obj.isChecked}
                                    onChange={() => this.toggleCheckbox(obj)}
                                  />
                                  <span className="a-checkbox-mark">
                                    <span className="appkiticon icon-check-mark-fill" />
                                  </span>
                                </label>
                              </td>
                              <td
                                className="a-title-cell cursor-pointer"
                                onClick={this.editDomain.bind(this, obj)}
                              >
                                {obj.title}
                              </td>
                              <td
                                data-for={obj.title} data-tip
                                className="a-domain-cell cursor-pointer"
                                onClick={this.editDomain.bind(this, obj)}
                              >
                                {obj.description && this.truncate(obj.description)}
                                {(obj.description && obj.description.length > 100) && <ReactTooltip id={obj.title} className='tooltip' place="bottom"
                                  effect='solid' >
                                  {obj.description}
                                </ReactTooltip>}
                              </td>
                              <td
                                className="a-title-cell cursor-pointer"
                                onClick={this.editDomain.bind(this, obj)}
                              >

                                {obj.is_enforce_anonymity_rule ? 'Yes' : 'No'}
                              </td>
                              <td className="t-cross-icon a-cross cursor-pointer">
                                <i
                                  className="appkiticon icon-close-fill a-font-sm"
                                  onClick={() => this.confirmDelete(obj.id)}
                                />
                              </td>
                            </tr>
                          ),
                          this,
                        )
                      ) : (
                        <tr>
                          <td colSpan="3" class="font-weight-medium">Click the button below to create your first domain.</td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                </div>
                <div className="col-md-12  col-sm-12 col-12 pr-0  at-pagination ">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPageEvent={this.nextPage.bind(this)}
                    prevPageEvent={this.prevPage.bind(this)}
                    goToPageEvent={this.goToPage.bind(this)}
                    pageOnChange={this.pageOnChangeEvent.bind(this)}
                  />
                </div>
              </RenderIf>
            </div>
            <a
              className="add-text-button mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray"
              href="javascript:void(0)"
              onClick={this.showAddDomainPanel.bind(this)}
            >
              <i className="appkiticon icon-plus-fill mr-2"> </i> Create a new focus area
            </a>
          </div>
          <div>
            {
              this.state.showAlertPopup ? <AlertPopup
                title={this.state.alertPopupTitle}
                message={this.state.alertPopupMessage}
                alertPopupOkClick={this.alertPopupOkClick} />
                : ''
            }
            {this.state.showDelete ? (
              <DeleteConfirmation
                title={this.state.deleteTitle}
                message={this.state.deleteConfirmationMessage}
                confirmationClick={this.deleteDomains}
                cancelClick={this.cancelDelete}
              />
            ) : (
              ''
            )}
            <CSSTransitionGroup
              transitionName="pwcsidebar"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
            >
              {this.state.showAddDomain ? (
                <AddDomain
                  hideAddDomainPanel={this.hideAddDomainPanel}
                  selectedDomain={this.state.selectedDomain}
                />
              ) : (
                ''
              )}
            </CSSTransitionGroup>
          </div>
          <div className="footer-dropdowns">
            <button
              className="a-btn a-btn-primary a-btn-lg dropdown-toggle"
              data-bs-toggle="dropdown"
              disabled={domain.domainsList.filter(item => item.isChecked).length <= 0}
            >
              actions <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
            </button>
            <ul className="user-infor-list dropdown-menu a-shadow-sm border-0">
              <li className="user-infor-list-item" onClick={() => this.confirmBulkDelete()}>
                <a href="javascript:void(0)" >
                  Delete Selected Items
                </a>
              </li>
            </ul>
          </div>
        </RenderIf>
        <Feedback pageRef={FEEDBACK_PAGE_REF.DOMAIN_PAGE} isShowSupportBtn = {false}/>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Domain);
