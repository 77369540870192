import actionType from '../../../../redux/modules/settings/actionType';
import settingsService from '../../../../redux/services/settingsService';

const systemTerminologyDefaultId = 0;
const systemTerminologyDefaultLevelOne = 'Portfolio';
const systemTerminologyDefaultLevelTwo = 'Project';

// Action creater
export function loadSystemTerminology(systemTerminology) {
  return { type: actionType.GET_SYSTEM_TERMINOLOGY, systemTerminology };
}

export function loadLifeCycleStages(lifeCycleStage) {
  return { type: actionType.GET_PROJECT_LIFECYCLE_STAGES_LIST, lifeCycleStage };
}

// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

export const setHeadingRightEndText = headingRightEndText => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING_RIGHT_END_TEXT, headingRightEndText });
};

// Creating thunk action creaters which will be access from component
// System Terminology
export const getSystemTerminology = () => (dispatch) => {
  settingsService
    .getSystemTerminology()
    .then((res) => {
      const systemTerminology = res.data.length > 0
        ? res.data[0]
        : {
          id: systemTerminologyDefaultId,
          level_one: systemTerminologyDefaultLevelOne,
          level_two: systemTerminologyDefaultLevelTwo,
        };
      dispatch(loadSystemTerminology(systemTerminology));
    })
    .catch(() => { });
};

export const updateSystemTerminology = (sysTerminology, level, value) => dispatch => settingsService
  .updateSystemTerminology({ id: sysTerminology.id, level, value })
  .then((res) => {
    const systemTerminology = sysTerminology;
    if (systemTerminology.id === 0) {
      const systemTerminologyId = res.data[0];
      systemTerminology.id = systemTerminologyId;
    }
    if (level === 'level_one') {
      systemTerminology.level_one = value ? parseInt(value, 10) : 1;
    } else if (level === 'level_two') {
      systemTerminology.level_two = value ? parseInt(value, 10) : 1;
    }
    dispatch({ type: actionType.UPDATE_SYSTEM_TERMINOLOGY, systemTerminology });

    return res;
  })
  .catch(error => error);

export const updateMeasuringMetric = (questionModelId, value) => settingsService
  .updateMeasuringMetric({ id: questionModelId, value })
  .then(res => res.data)
  .catch(error => error);

export const updateAnonymityRule = data => settingsService
  .updateAnonymityRule(data)
  .then(res => res.data)
  .catch(error => error);

// Project Life Cycle Stages
export const getProjectLifeCycleStagesList = questionModelId => dispatch => settingsService
  .getProjectLifeCycleStagesList(questionModelId)
  .then((res) => {
    const projectLifeCycleStagesList = res.data.response;
    dispatch({ type: actionType.GET_PROJECT_LIFECYCLE_STAGES_LIST, projectLifeCycleStagesList });
    return res;
  })
  .catch(() => { });

export function validateProjectLifecycleStageName(projectLifeCycleStage, questionModelId) {
  return settingsService
    .validateProjectLifecycleStageName(projectLifeCycleStage, questionModelId)
    .then(res => res.data)
    .catch({});
}

export const getProjectLifeCycleStage = projectLifeCycleStage => () => settingsService
  .getProjectLifeCycleStage(projectLifeCycleStage)
  .then(res => res)
  .catch(() => { });

export const insertProjectLifeCycleStage = (projectLifeCycleStage,
  questionModelId) => dispatch => settingsService
  .insertProjectLifeCycleStage(projectLifeCycleStage)
  .then((response) => {
    dispatch(getProjectLifeCycleStagesList(questionModelId));

    return response;
  })
  .catch(error => error);

export const updateProjectLifeCycleStage = (projectLifeCycleStage,
  questionModelId) => (dispatch) => {
  settingsService
    .updateProjectLifeCycleStage(projectLifeCycleStage)
    .then(() => {
      dispatch(getProjectLifeCycleStagesList(questionModelId));
    })
    .catch(() => { });
};
export const deleteProjectLifeCycleStage = (projectLifeCycleStage,
  questionModelId) => dispatch => settingsService
  .deleteProjectLifeCycleStages(projectLifeCycleStage)
  .then((response) => {
    dispatch(getProjectLifeCycleStagesList(questionModelId));

    return response;
  })
  .catch(error => error);

export const deleteQuestionModelAuthor = authorId => settingsService
  .deleteQuestionModelAuthor(authorId)
  .then(response => response)
  .catch(error => error);

export const changeProjectLifeCycleStagesOrder = (sortOrder,
  projectLifeCycleStagesList) => (dispatch) => {
  dispatch({ type: actionType.EMPTY_PROJECT_LIFECYCLE_STAGES_LIST, projectLifeCycleStagesList });
  dispatch({ type: actionType.GET_PROJECT_LIFECYCLE_STAGES_LIST, projectLifeCycleStagesList });
  return settingsService
    .changeProjectLifeCycleStagesOrder(sortOrder)
    .then(res => res)
    .catch(() => { });
};

export function addQuestionModelAuthors(authorsData) {
  return settingsService
    .addQuestionModelAuthors(authorsData)
    .then((res) => {
      if (res.data !== undefined) {
        const { failedRecords } = res.data;
        return failedRecords;
      }
      return res;
    })
    .catch({});
}

export const getQuestionModelAuthors = authorData => settingsService
  .getQuestionModelAuthors(authorData)
  .then(res => (res.data ? res.data : {}))
  .catch({});

  export const archiveQuestionModel = data => settingsService
  .archiveQuestionModel(data)
  .then(res => res.data)
  .catch(error => error);
