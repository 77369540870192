import React, { Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import './segment.css';
import segmentIconSvg from '../../../assets/images/component/svg/segment-icon.svg';

const CreateToolTipData = ({ projectSegments, selectedSegments }) => {
  if (selectedSegments && selectedSegments.length === 1 && selectedSegments[0] === 0) {
    return projectSegments.map((segment) => {
      if (selectedSegments.includes(parseInt(segment.value, 10))) {
        return (<span>Send to: {segment.text}</span>);
      }
      return '';
    });
  }
  if (!selectedSegments.includes(0)) {
    return (< Fragment >
      <span>Send to: </span>
      <ul>
        {projectSegments.map((segment) => {
          if (selectedSegments.includes(parseInt(segment.value, 10))) {
            return (<li>{segment.text}</li>);
          }
          return '';
        })}
      </ul>
    </Fragment>);
  }
  return ('');
};

const SegmentIcon = (props) => {
  const { selectedSegments, tooltipIdPostfix, toggleDropDown } = props;
  const getNoOfSegments = () => {
    if (selectedSegments.length === 1 && selectedSegments[0] === 0) {
      return 0;
    }
    if (!selectedSegments.includes(0)) {
      return selectedSegments.length;
    }
    return 0;
  };

  return (<div class="dropdown-toggle" type="button" id="dropdownMenuButton"
  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={() => toggleDropDown(true)} >
    <span style={{ color: getNoOfSegments() > 0 ? '#eb590b' : 'transparent' }}>
      <ReactSVG wrapper="svg" id={`segmentMessage${tooltipIdPostfix}`} width="14" height="9" src={segmentIconSvg} data-for={`segmentMessage${tooltipIdPostfix}`} data-tip={`segmentMessage${tooltipIdPostfix}`} fill={getNoOfSegments() > 0 ? '#eb590b' : '#808080'} />
      <span className="slctd-sgmnts preview-pane-font">{getNoOfSegments() > 0 ? getNoOfSegments() : ''}</span></span>

    <ReactTooltip id={`segmentMessage${tooltipIdPostfix}`} className={`segmentMessage${tooltipIdPostfix}_tooltip ${selectedSegments.length === 1 && selectedSegments[0] === 0 ? 'every-one' : ''}`} multiline={true} place='top'
      effect='solid'>
      {<CreateToolTipData {...props} />}
    </ReactTooltip>
  </div>);
};
export default SegmentIcon;
