import React, { Component } from 'react';
import $ from 'jquery';
import { sortArrayValues } from '../../utils/helper';
import {
  SORT_DOMAINS_ARRAY,
} from '../../Constants';

const SumoSelectError = ({ error }) => (error ? <div className="error">{error}</div> : null);

const RenderOptions = props => props.options.map(item => (
  <option className="sumoOption" key={item.value} value={item.value} selected={item.selected}>
    {item.text}
  </option>
));

const RenderGroupOptions = props => props.options.map((item, index) => (
  <optgroup label={item.text} key={index}>
    {item.attributes.map((itemAtt, idx) => (
      <option className="sumoOption" key={idx} value={itemAtt.value} selected={itemAtt.selected}>
        {itemAtt.text}
      </option>
    ))}
  </optgroup>
));

class SumoSelect extends Component {
  componentWillMount() {
    this.optionsWithSelectedData = this.getOptionsWithSelectedData();
  }

  componentDidUpdate() {
    if (this.props.name === 'selectedAttributes') {
      this.optionsWithSelectedData = this.getOptionsWithSelectedData();
      // eslint-disable-next-line no-unused-expressions
      $(`#${this.props.name}`)[0].sumo?.reload();
    }
    if (this.props.name === 'selectedGroupValue1'
      || this.props.name === 'selectedGroupValue2'
      || this.props.name === 'selectedGroupValue3'
      || this.props.name === 'selectedGroupValue4') {
      const { options, selectedValues } = this.props;
      const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
      const itemValue = this.props.name === 'selectedGroupValue1'
        || this.props.name === 'selectedGroupValue2'
        || this.props.name === 'selectedGroupValue3'
        || this.props.name === 'selectedGroupValue4'
        ? values[0] : parseInt(values[0], 10);

      const optionText = this.props.name === 'selectedTypes'
        ? options.filter(item => item.value.toString() === itemValue)
        : options.filter(item => item.value === itemValue);
      if (values.length === 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(optionText[0].text);
      else if (values.length > 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(`${optionText[0].text} (+${values.length - 1})`);
      else $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.props.captionFormatAllSelected ? this.props.captionFormatAllSelected : '-- ALL --');
    }
  }

  componentDidMount() {
    this.init();
    // show placeholer for single select
    if (!this.isMultiSelect()) {
      this.showHidePlaceHolderSingleSelect();
    }
  }

  showHidePlaceHolderSingleSelect() {
    const {
      name, placeholder, selectedValues, grouping,
    } = this.props;
    if (selectedValues === '') {
      $(`.sumo_${name} >  p:eq(0)`)[0].title = '';
      $(`.sumo_${name} >  p:eq(0)`).addClass('placeholder-text');
      $(`.sumo_${name} >  p:eq(0) > span:eq(0)`)[0].innerText = placeholder;
      if (grouping) {
        $(`.sumo_${name} >  div:eq(0) > ul:eq(0) > li:eq(0) >ul:eq(0) > li:eq(0) `).removeClass('selected');
      } else {
        $(`.sumo_${name} >  div:eq(0) > ul:eq(0) > li:eq(0) `).removeClass('selected');
      }
    } else {
      $(`.sumo_${name} >  p:eq(0)`).removeClass('placeholder-text');
    }
  }

  init() {
    const {
 name, captionFormatAllSelected, searchfield, searchText,
} = this.props;
    const $sumoSelector = $(`#${name}`);
    $sumoSelector.SumoSelect({
      captionFormatAllSelected,
      csvDispCount: 1,
      search: !!searchfield,
      searchText,
      triggerChangeCombined: true,
      forceCustomRendering: true,
    });

    $sumoSelector.on('change', () => {
      const selectedOptions = $(`#${name} option:selected`);
      const selectedOptionsValues = [];
      selectedOptions.each((index, item) => {
        selectedOptionsValues.push($(item).val());
      });

      const value = this.isMultiSelect() ? selectedOptionsValues : selectedOptionsValues[0];
      this.setValue(value);


      if (this.hasOnSumoSelectChangeEvent()) {
        this.props.onSumoSelectChange(value);
      }
    });
  }

  getOptionsWithSelectedData = () => {
    const { options, selectedValues } = this.props;
    const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    let optionsData = options.map((item) => {
      const processedItem = Object.assign({}, item);
      if (values.indexOf(processedItem.value) >= 0) {
        processedItem.selected = true;
      }
      return processedItem;
    });
    if (this.props.grouping) {
      const optionsDataContainingValue = optionsData.filter(o => o.value);
      optionsData = optionsDataContainingValue;
      optionsData.map((item) => {
        item.attributes = options[item.value] || [];
      });
      optionsData = sortArrayValues(optionsData, SORT_DOMAINS_ARRAY);
    }
    return optionsData;
  };

  setValue = (value) => {
    const { options } = this.props;
    if (this.props.multiple) {
      const itemValue = this.props.name === 'selectedIndustries'
        || this.props.name === 'selectedGroupValue1'
        || this.props.name === 'selectedGroupValue2'
        || this.props.name === 'selectedGroupValue3'
        || this.props.name === 'selectedGroupValue4'
        || this.props.name === 'selectedTypes'
        ? value[0] : parseInt(value[0], 10);

      const optionText = this.props.name === 'selectedTypes'
        ? options.filter(item => item.value.toString() === itemValue)
        : options.filter(item => item.value === itemValue);
      if (value.length === 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(optionText[0].text);
      else if (value.length > 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(`${optionText[0].text} (+${value.length - 1})`);
      else $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.props.captionFormatAllSelected ? this.props.captionFormatAllSelected : '-- ALL --');
    }

    this.props.setFieldValue(this.props.name, value);
    this.showHidePlaceHolderSingleSelect();
  };

  isMultiSelect() {
    return this.props.multiple;
  }

  hasOnSumoSelectChangeEvent() {
    return this.props.onSumoSelectChange;
  }

  render() {
    const {
      options,
      setFieldValue,
      selectedValues,
      error,
      name,
      onSumoSelectChange,
      captionFormatAllSelected,
      ...props
    } = this.props;
    return (
      <div>
        {this.props.multiple !== 'multiple'
          && this.props.grouping !== undefined
          && selectedValues !== undefined && (
            <div>
              <select
                id={name}
                name={name}
                defaultValue={selectedValues}
                className="a-selector3 a-select"
                {...props}
              >
                <RenderGroupOptions options={this.optionsWithSelectedData} />
              </select>
            </div>
          )}
        {this.props.multiple !== 'multiple'
          && this.props.grouping === undefined
          && selectedValues !== undefined && (
            <div>
              <select
                id={name}
                name={name}
                defaultValue={selectedValues}
                className="a-selector3 a-select"
                {...props}
              >

                <RenderOptions options={this.optionsWithSelectedData} />
              </select>
            </div>
          )}
        {this.props.multiple !== 'multiple' && selectedValues === undefined && (
          <div>
            <select
              id={name}
              name={name}
              defaultValue={''}
              className="a-selector3 a-select"
              {...props}
            >
              <RenderOptions options={this.optionsWithSelectedData} />
            </select>
          </div>
        )}
        {this.props.multiple && (
          <div>
            <select id={name} name={name} className="a-selector3 a-select" {...props}>
              <RenderOptions options={this.optionsWithSelectedData} />
            </select>
          </div>
        )}
        <SumoSelectError error={error} />
      </div>
    );
  }
}

export default SumoSelect;
