import React from 'react';
import { connect } from 'react-redux';
import QueryString from 'query-string';
import '../styles/getting-started.scss';
import authService from '../../../redux/services/authService';
import FooterContent from '../Footer/FooterContent.jsx';
import { setUserDefaultMode, getUserDefaultMode } from '../../../modules/settings/user/actions/user';
import { Spinner } from '../../../ui-components/Loader';

const FRONTEND_APP_MODE = 1;
const QUESTIONNAIRE_APP_MODE = 2;

class GettingStarted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isQuestionnaireMode: false,
      isAppMode: false,
      isDefaultSelected: false,
      isShowBackButton: false,
      isSpinnerLoading: true,
      data: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryStringData = QueryString.parse(location.search);
    if (queryStringData.back === 'true') {
      this.setState({ isShowBackButton: true });
    }
    this.props.getUserDefaultMode().then((res) => {
      if (res) {
        this.setState({ data: true, isSpinnerLoading: false });
      }
      if (res.data) {
        if (res.data === FRONTEND_APP_MODE) {
          this.setState({
            isQuestionnaireMode: false,
            isAppMode: true,
            isDefaultSelected: true,
          });
        }
        if (res.data === QUESTIONNAIRE_APP_MODE) {
          this.setState({
            isQuestionnaireMode: true,
            isAppMode: false,
            isDefaultSelected: true,
          });
        }
      }
    });
  }

  onQuestionnaireModeSelection() {
    this.setState({
      isQuestionnaireMode: true,
      isAppMode: false,
    });
  }

  onAppModeSelection() {
    this.setState({
      isQuestionnaireMode: false,
      isAppMode: true,
    });
  }

  onChangeDefaultValue(value) {
    this.setState({ isDefaultSelected: !value });
  }

  onContinueClick() {
    const { isQuestionnaireMode, isAppMode, isDefaultSelected } = this.state;
    if (isQuestionnaireMode) {
      if (isDefaultSelected) {
        return this.props.setUserDefaultMode({ data: QUESTIONNAIRE_APP_MODE }).then((res) => {
          if (res) {
            window.location.replace(__CONFIG__.questionnaireRedirectUrl);
          }
        });
      }
      return this.props.setUserDefaultMode({ data: null }).then((res) => {
        if (res) {
          window.location.replace(__CONFIG__.questionnaireRedirectUrl);
        }
      });
    }
    if (isAppMode) {
      if (isDefaultSelected) {
        return this.props.setUserDefaultMode({ data: FRONTEND_APP_MODE }).then((res) => {
          if (res) {
            this.redirectToUrl(__CONFIG__.url, true);
          }
        });
      }
      return this.props.setUserDefaultMode({ data: null }).then((res) => {
        if (res) {
          this.redirectToUrl(__CONFIG__.url, true);
        }
      });
    }
    return '';
  }

  redirectToUrl(url, isUrlSaved) {
    const savedUrl = authService.getRedirectUrl();
    if (isUrlSaved) {
      authService.removeRedirectUrl();
    }
    const redirectUrl = isUrlSaved ? savedUrl || url : url;
    window.location.replace(redirectUrl);
  }

  onBackClick() {
    window.location.replace(__CONFIG__.url);
  }

  render() {
    const { isQuestionnaireMode, isAppMode, isShowBackButton } = this.state;
    return (
      <React.Fragment>
        <Spinner isLoading={this.state.isSpinnerLoading} />
        <div className='d-block' >
          <div className='position-set'>
            <div class="bg-orange">
              <span class="bg-title mb-resp">What would you like to do today?</span>
            </div>
          </div>
          {this.state.data === true && <div className='cards-wrap'>
            <div >
              <div className='cards-block d-flex justify-content-center'>
                <div onClick={() => this.onQuestionnaireModeSelection()} className={`card-border d-flex align-items-center ${isQuestionnaireMode ? 'active' : ''}`}>
                  <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_3584_23170)">
                        <mask id="mask0_3584_23170" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                          <path d="M30 0H0V30H30V0Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_3584_23170)">
                          <path d="M16.875 0H13.125C5.87499 0 0 5.9375 0 13.2583C0 16.8167 1.39583 20.0417 3.65417 22.4208L3.63751 29.9958L9.70001 26.0417C10.7958 26.3417 11.9417 26.5125 13.1292 26.5125H16.8792C24.1292 26.5125 30.0042 20.575 30.0042 13.2542C30.0042 5.93334 24.125 0 16.875 0ZM16.875 24.625H13.125C12.1625 24.625 11.175 24.4875 10.1875 24.2167L9.38333 23.9958L8.68333 24.4542L5.52083 26.5167L5.52917 22.4292V21.6667L5.00833 21.1167C2.98751 18.9875 1.87501 16.1958 1.87501 13.2625C1.87501 6.99584 6.92083 1.89584 13.125 1.89584H16.875C23.0792 1.89584 28.125 6.99584 28.125 13.2625C28.125 19.5292 23.0792 24.6292 16.875 24.6292V24.625Z" fill="#2D2D2D" />
                          <path d="M16.4146 20.3014H14.6813V18.093H16.4146V20.3014ZM18.9646 12.3472C18.6438 12.8598 18.2396 13.3555 17.7521 13.818C17.4104 14.1598 17.1229 14.4473 16.9104 14.6514C16.7437 14.8098 16.6646 14.9305 16.6229 15.0055C16.5979 15.0555 16.5521 15.1805 16.5271 15.3889C16.4979 15.643 16.4771 16.0139 16.4688 16.5014L14.5938 16.4598C14.6063 15.893 14.6312 15.4598 14.6687 15.1598C14.7188 14.7639 14.8188 14.4139 14.9729 14.1223C15.1313 13.8223 15.3521 13.543 15.6271 13.2805C15.8312 13.0848 16.1062 12.8139 16.4479 12.4639C16.8354 12.0931 17.1438 11.7181 17.3813 11.3431C17.5604 11.0514 17.6521 10.6722 17.6521 10.2181C17.6521 9.85975 17.5896 9.54308 17.4688 9.28058C17.3437 9.01391 17.1729 8.78891 16.9563 8.59725C16.7312 8.40141 16.4729 8.25558 16.1687 8.15141C15.4396 7.90141 14.4938 7.95141 13.8604 8.20975C13.5021 8.35557 13.2104 8.55141 12.9646 8.80557C12.7271 9.05557 12.5479 9.35975 12.4229 9.73057C12.2896 10.1264 12.2188 10.5806 12.2188 11.0764H10.3438C10.3438 10.3722 10.4479 9.70975 10.6479 9.11391C10.8646 8.48057 11.1896 7.93891 11.6188 7.49307C12.0438 7.04725 12.5604 6.70141 13.1562 6.45557C14.1979 6.02641 15.6188 5.95975 16.7771 6.35975C17.3063 6.54308 17.7771 6.81391 18.1813 7.16391C18.5979 7.52641 18.9271 7.96808 19.1646 8.47641C19.4062 8.99308 19.5271 9.58058 19.5271 10.2222C19.5271 11.0431 19.3396 11.7597 18.9646 12.3597V12.3472Z" fill="#2D2D2D" />
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_3584_23170">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <h3>Go to Questionnaire</h3>
                    <p>Access your questions for topics where
                      you’ve been invited to share your insights.</p>
                  </div>
                </div>
                <div onClick={() => this.onAppModeSelection()} className={`card-border d-flex align-items-center ${isAppMode ? 'active' : ''}`}>
                  <div>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <mask id="mask0_3584_23181" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
                        <path d="M30 0H0V30H30V0Z" fill="white" />
                      </mask>
                      <g mask="url(#mask0_3584_23181)">
                        <path d="M27.5 0H2.5C1.12083 0 0 1.12083 0 2.5V27.5C0 28.8791 1.12083 30 2.5 30H27.5C28.8791 30 30 28.8791 30 27.5V2.5C30 1.12083 28.8791 0 27.5 0ZM28.125 28.125H17.5L15 23.75H1.86666V1.875H28.125V28.125Z" fill="#2D2D2D" />
                        <path d="M8.75 15H6.25V20H8.75V15Z" fill="#2D2D2D" />
                        <path d="M13.75 10H11.25V20H13.75V10Z" fill="#2D2D2D" />
                        <path d="M18.75 12.5H16.25V20H18.75V12.5Z" fill="#2D2D2D" />
                        <path d="M23.75 7.5H21.25V20H23.75V7.5Z" fill="#2D2D2D" />
                      </g>
                    </svg>
                    <h3>View Dashboard</h3>
                    <p>Access existing results dashboard and additional features.</p>
                  </div>
                </div>
              </div>
              <div class="form-check">
                <label class="a-checkbox a-mx-10">
                  <input
                    type="checkbox"
                    checked={this.state.isDefaultSelected}
                    onChange={() => this.onChangeDefaultValue(this.state.isDefaultSelected)}
                    disabled={!(isQuestionnaireMode || isAppMode)}
                  />
                  <span class="a-checkbox-mark">
                    <span class="appkiticon icon-check-mark-fill center-tick-mark"></span>
                  </span>
                </label>
                <label class="form-check-label" for="flexCheckDefault">
                  Make this my default selection and don't ask me again
                </label>
              </div>
              <div className='text-center'>
                <button onClick={() => this.onContinueClick()} class={!(isQuestionnaireMode || isAppMode) ? 'disable-btn-continue' : 'enable-btn-continue'} >Continue</button>
              </div>
              {isShowBackButton && <div className='text-center mr-back'>
                <a onClick={() => this.onBackClick()} class='back-btn-continue' >Back</a>
              </div>}
            </div>
          </div>}
        </div>
        <FooterContent></FooterContent>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  setUserDefaultMode,
  getUserDefaultMode,
};
const mapStateToProps = state => ({
  isUserAbleToChangeMode: state.login.isUserAbleToChangeMode,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GettingStarted);
