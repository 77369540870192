import React, { Component } from 'react';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import { ReactSVG } from 'react-svg';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import {
  insertQuestion, updateQuestion, getQuestionStages,
  checkAttributeEligibility,
} from '../actions/question';
import { addQuestionSelector } from '../selectors/question';
import { getprojectLifeCycleStagesListSelector } from '../selectors/general';
import { attributeSelector } from '../selectors/attribute';
import SumoSelect from '../../../../ui-components/SumoSelect/SumoSelect';
import { SpinnerButton, Spinner } from '../../../../ui-components/Loader';
import RenderIf from '../../../../ui-components/Common';
import archiveEditLockSvg from '../../../../../assets/images/component/svg/archive-edit-lock-icon.svg';

const mapStateToProps = state => ({
  addQuestion: addQuestionSelector(state),
  attributes: attributeSelector(state),
  projectLifeCycleStages: getprojectLifeCycleStagesListSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const mapDispatchToProps = {
  insertQuestion,
  updateQuestion,
  getQuestionStages,
  checkAttributeEligibility,
};

const types = { yesNo: 1, openText: 2 };

function selectType(e, setFieldValue) {
  const value = parseInt($(e.currentTarget).attr('value'), 10);

  if (value === types.openText) {
    setFieldValue('is_inverted', null);
  }
  if (value === types.yesNo) {
    setFieldValue('is_inverted', false);
  }
  setFieldValue('type', value);
}
function selectPolarity(e, setFieldValue) {
  setFieldValue('is_inverted', $(e.currentTarget).val() === 'true');
}
function setActive(e, setFieldValue) {
  setFieldValue('is_active', $(e.currentTarget).is(':checked'));
}

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function checkAttrAssociatedWithMoreThanThirtyTwoQuestions(values, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'This Attribute has already been associated to max questions limit. Please choose another attribute.';
    checkAttributeEligibility(parseInt(values.selectedAttribute, 10)).then((val) => {
      if (!val) {
        setError(actions, 'selectedAttribute', errorMessage);
        return resolve(true);
      }
      return resolve(false);
    });
  });
}

const BasicForm = ({ values, setFieldValue, isSubmitting }) => (
  <Form>
    <div className="r-s-body mt-3">
      <div class="form-group">
        <div class="a-btn-group">
          <button
            class={`a-btn-group-item ${values.type === types.yesNo ? 'active' : ''}`}
            toggle-active="active"
            type="button"
            value={types.yesNo}
            onClick={e => selectType(e, setFieldValue)}
            disabled={values.isArchived}
          >
            <span class="a-ellipsis preview-pane-font">Yes/No</span>
          </button>
          <button
            class={`a-btn-group-item ${values.type === types.openText ? 'active' : ''}`}
            toggle-active="active"
            type="button"
            value={types.openText}
            onClick={e => selectType(e, setFieldValue)}
            disabled={values.isArchived}
          >
            <span class="a-ellipsis preview-pane-font">Open Text</span>
          </button>
        </div>
      </div>
      <div className="r-s-body mt-3">
        <div class="form-group">
          <div class="a-form-label">Question Title </div>
          <Field
            component="textarea"
            name="title"
            autoFocus={!values.id}
            placeholder="Type your question / statement here..."
            autoComplete="off"
            className="a-text-input"
            disabled={values.isArchived}
          />
          <div className="error">
            <ErrorMessage name="title" />
          </div>
        </div>
      </div>

      {values.type === types.yesNo ? (<div class="form-group">
        <div class="a-form-label">Associated Attribute </div>
        <SumoSelect
          name="selectedAttribute"
          placeholder="Select an attribute"
          selectedValues={values.selectedAttribute}
          grouping={true}
          options={values.attributesDomains}
          setFieldValue={setFieldValue}
          searchfield={true}
          disabled={values.isArchived}
        />
        <div className="error">
          <ErrorMessage name="selectedAttribute" />
        </div>
      </div>) : ''}
      < div className="lifecycle-stages mt-4">
        <p className="section-title m-0 mb-1">Lifecycle Stages</p>
        <p className="a-font-sm m-0">
          Select the lifecycle stages that are relevant for this question:
        </p>
        <div class="mt-3 mb-4">
          <ul className="a-list" name="lifecycleStagesList">
            {values.lifecycleStages.map((stage, index) => (
              <li key={index} className="a-list-item d-flex align-items-center ">
                <label className="a-checkbox a-mx-10">
                  <input
                    checked={stage.isSelected}
                    type="checkbox"
                    onChange={() => {
                      stage.isSelected = !stage.isSelected;
                      if (stage.isSelected) values.selectedLifecycleStages.push(stage.value);
                      if (!stage.isSelected) {
                        const idx = values.selectedLifecycleStages.indexOf(stage.value);
                        values.selectedLifecycleStages.splice(idx, 1);
                      }
                      setFieldValue(values.lifecycleStages, values.lifecycleStages);
                    }}
                  />
                  <span className="a-checkbox-mark">
                    <span className="appkiticon icon-check-mark-fill" />
                  </span>
                  <span className="a-checkbox-text preview-pane-font">{stage.text}</span>
                </label>
              </li>
            ))}
          </ul>
          <div className="error">
            <ErrorMessage name="selectedLifecycleStages" />
          </div>
        </div>
      </div>
      {values.type === types.yesNo ? (
        <div className="lifecycle-stages mt-4">
          <p className="section-title m-0 mb-1">Question Polarity</p>
          <div class="mt-3 mb-4">
            <ul class="a-list">
              <li class="a-list-item d-flex align-items-center ">
                <label class="a-radio a-mx-10">
                  <input
                    name="radio-group-1"
                    type="radio"
                    value="false"
                    checked={values.is_inverted === false}
                    onClick={e => selectPolarity(e, setFieldValue)}
                    disabled={values.isArchived}
                  />
                  <span class="a-radio-mark">
                    <span class="a-radio-inner" />
                  </span>
                  <span class="a-radio-text preview-pane-font">Positive</span>
                </label>
              </li>
              <li class="a-list-item d-flex align-items-center ">
                <label class="a-radio a-mx-10">
                  <input
                    name="radio-group-1"
                    type="radio"
                    value="true"
                    checked={values.is_inverted === true}
                    onClick={e => selectPolarity(e, setFieldValue)}
                    disabled={values.isArchived}
                  />
                  <span class="a-radio-mark">
                    <span class="a-radio-inner" />
                  </span>
                  <span class="a-radio-text preview-pane-font">Negative</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="form-group ">
        <label class="a-toggle a-m-5">
          <input
            type="checkbox"
            checked={values.is_active}
            onClick={e => setActive(e, setFieldValue)}
          />
          <div class="a-toggle-mark">
            <span class="on" />
            <span class="off" />
            <span class="switch" />
          </div>
          <span class="a-toggle-text preview-pane-font">Active</span>
        </label>
      </div>
    </div>

    <div className="r-s-actions mt-4">
      <div className="mt-3">
        <SpinnerButton
          isLoading={isSubmitting}
          label={values.id === 0 ? 'CREATE' : 'SAVE CHANGES'}
        />
      </div>
    </div>
  </Form >
);

class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'AddQuestion',
      title: '',
      type: 1,
      is_inverted: false,
      is_active: true,
      attributeId: '',
      lifecycleStages: [],
      isSubmitting: false,
      saveCompleted: false,
      selectedLifecycleStagesForAddEdit: [],
      isLoading: true,
    };
    this.saveQuestion = this.saveQuestion.bind(this);
    this.hideAddQuestionPanel = this.hideAddQuestionPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillMount() {
    const { selectedQuestion } = this.props;
    if (!selectedQuestion.id) {
      this.setState({ isLoading: false });
      return;
    }

    this.props.getQuestionStages(selectedQuestion.id).then((res) => {
      const selectedLifecycleStages = [];
      res.map((item) => {
        selectedLifecycleStages.push(item.stage_id);
        return item;
      });

      this.setState({
        selectedLifecycleStagesForAddEdit: selectedLifecycleStages,
        isLoading: false,
      });
    });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideAddQuestionPanel();
    }
  }

  saveQuestion(values, actions) {
    this.showSpinner(true);
    const self = this;
    const selectedLifecycleStages = [];
    values.lifecycleStages.map((stage) => {
      if (stage.isSelected) selectedLifecycleStages.push(stage.value);
      return stage;
    });
    const data = {
      type: values.type,
      title: values.title ? values.title.trim().replace(/  +/g, ' ') : null,
      attribute_id: values.type === types.yesNo ? values.selectedAttribute : this.state.attributeId,
      lifecycleStages: selectedLifecycleStages,
      is_inverted: values.is_inverted,
      is_active: values.is_active,
      questionModelId: this.props.questionModelId,
    };

    if (values.id !== 0) {
      data.id = values.id;
      this.props.updateQuestion(data).then(() => {
        this.setState({ saveCompleted: true });
        this.showSpinner(false);
        this.props.hideAddQuestionPanel();
      });
    } else {
      checkAttrAssociatedWithMoreThanThirtyTwoQuestions(values, actions).then((status) => {
        if (status) {
          self.showSpinner(false);
        } else {
          this.props.insertQuestion(data).then(() => {
            this.setState({ saveCompleted: true });
            this.showSpinner(false);
            this.props.hideAddQuestionPanel();
          });
        }
      });
    }
  }

  hideAddQuestionPanel() {
    this.props.hideAddQuestionPanel();
  }

  showSpinner(isSubmitting) {
    this.setState(prevState => ({
      ...prevState,
      isSubmitting,
    }));
  }

  render() {
    const {
      selectedQuestion,
      attributesList,
      attributesDomainList,
      lifecycleStagesList,
    } = this.props;
    const {
      selectedLifecycleStagesForAddEdit, isLoading, isSubmitting, saveCompleted,
    } = this.state;
    const selectedStageIds = selectedLifecycleStagesForAddEdit;
    lifecycleStagesList.map((stage) => {
      if (!selectedQuestion.id && !isSubmitting && !saveCompleted) {
        stage.isSelected = true;
        selectedStageIds.push(stage.value);
      } else if (selectedStageIds.indexOf(stage.value) >= 0) stage.isSelected = true;
      else stage.isSelected = false;
      return stage;
    });
    let questionModel = {};

    questionModel = {
      id: selectedQuestion.id ? selectedQuestion.id : 0,
      title: selectedQuestion.title
        ? selectedQuestion.title : this.state.Title,
      initialTitle: selectedQuestion.title
        ? selectedQuestion.title : this.state.Title,
      type: selectedQuestion.type ? selectedQuestion.type : this.state.type,
      is_inverted:
        selectedQuestion.is_inverted !== undefined
          ? selectedQuestion.is_inverted
          : this.state.is_inverted,
      is_active:
        selectedQuestion.is_active !== undefined
          ? selectedQuestion.is_active
          : this.state.is_active,
      attributes: attributesList,
      attributesDomains: attributesDomainList,
      selectedAttribute: selectedQuestion.attribute_id
        ? selectedQuestion.attribute_id
        : this.state.attributeId,
      lifecycleStages: lifecycleStagesList,
      selectedLifecycleStages: selectedStageIds,
      isArchived: !!selectedQuestion.isArchived,
    };
    return (
      <div className="right-sidebar-overlay">
        <div className="right-sidebar question-sidebar-container" ref={this.setWrapperRef}>
          <Spinner isLoading={isLoading} />
          <RenderIf showComponent={!isLoading}>
            <div className="r-s-header">
              <div className="r-s-title">
                <span>
                  {selectedQuestion.id ? 'Modify existing question' : 'Create a new question'}
                  <i className="appkiticon icon-close-outline" onClick={this.hideAddQuestionPanel} />
                </span>
              </div>
            </div>
            {selectedQuestion.isArchived && <div className='question-msg-box'>
              <div className='d-flex align-items-baseline'>
                <ReactSVG wrapper="svg" width="12px" height="12px" src={archiveEditLockSvg} />
                <span className='preview-pane-font'>This question is locked and cannot be edited as it relates to prior topics.
                  If you would like to re-use this question but make changes,
                  please create a new question instead.</span>
              </div>
            </div>}
            <div className="app">
              <Formik
                enableReinitialize
                initialValues={questionModel}
                onSubmit={(values, actions) => this.saveQuestion(values, actions)}
                validationSchema={() => Yup.lazy(values => Yup.object().shape({
                  title: Yup.string()
                    .trim()
                    .required('Title is required.')
                    .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                  selectedAttribute: values.type === types.yesNo && Yup.string().required('An attribute must be selected.'),
                  selectedLifecycleStages: Yup.array().min(1, 'At least one lifecycle stage must be selected'),
                }))}
                render={formikProps => (
                  <BasicForm {...formikProps} isSubmitting={this.state.isSubmitting} />
                )}
              />
            </div>
          </RenderIf>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddQuestion);
