import React from 'react';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import ReactTooltip from 'react-tooltip';

const SegmentGroupOverallConfidenceCard = ({
  segmentGroupDashboardData, previousDaysCount,
  pastDays, getKeyPhrases, showOpenTextNonMatchRightPanel,
  expandWidgets,
  showOpenTextRightPanel,
  isShowPercentageChange,
  showHideOverlay,
  showOpenTextDropQuesRightPanel,
}) => {
  const {
    segmentGroupData1, segmentGroupData2,
    segmentGroupData3, segmentGroupData4,
  } = segmentGroupDashboardData;

  const overallWidget1 = segmentGroupData1?.OverallWidget;
  const overallWidget2 = segmentGroupData2?.OverallWidget;
  const overallWidget3 = segmentGroupData3?.OverallWidget;
  const overallWidget4 = segmentGroupData4?.OverallWidget;

  const randomIds = () => (Math.random() + 1).toString(36).substring(7);


  const calculateSurveyEngagementDisplayArray = (surveyEngagements) => {
    // Return type will be in the format
    // [
    //   { key: 'A', value: 90 },
    //   { key: 'B,C', value: 70 },        //if the value is same
    //   { key: 'C=90,b=80', value: 90 },  //if the values are different and near
    //   { key: 'D', value: 40 }           //if the values are different and far
    //   { key: 'C,D=90,b=80', value: 90 } //if the values are difrnt and near & 2 values are same
    // ]
    const cloneSurveryEngagements = JSON.parse(JSON.stringify(surveyEngagements));
    const alteredArray = [];

    const surveys = cloneSurveryEngagements.filter(survey => survey.value !== undefined);
    surveys.forEach((d) => {
      let pushed = false;
      for (let i = 0; i < alteredArray.length; i += 1) {
        // IF VALUES ARE SAME
        if (alteredArray[i].value?.project === d.value?.project) {
          alteredArray[i].key = `${alteredArray[i].key},${d.key}`;
          if (!alteredArray[i].type) {
            pushed = true;
          } else {
            let keyPushed = false;
            for (let j = 0; j < alteredArray[i]?.keys?.length; j += 1) {
              const currentKey = alteredArray[i]?.keys[j];
              const currentValue = currentKey.split(':')[1];
              if (currentValue === `${d.value?.project}%`) {
                alteredArray[i].keys[j] = `${currentKey.split(':')[0]},${d.key}:${currentKey.split(':')[1]}`;
                keyPushed = true;
                break;
              }
            }
            if (!keyPushed) {
              alteredArray[i].keys.push(`${d.key}:${d.value.project}%`);
            }
            pushed = true;
          }
          break;
        } else if ( // IF VALUES ARE DIFFERENT AND NEAR
          (alteredArray[i].value?.project <= d.value?.project && d.value?.project
            <= (alteredArray[i].value?.project + 20))
          || (alteredArray[i].value?.project >= d.value?.project && d.value?.project
            >= (alteredArray[i].value?.project - 20))
        ) {
          if (!alteredArray[i].type) {
            alteredArray[i].keys = [`${alteredArray[i].key}:${alteredArray[i].value.project}%`, `${d.key}:${d.value.project}%`];
            alteredArray[i].type = 'STR';
          } else {
            let keyPushed = false;
            for (let j = 0; j < alteredArray[i]?.keys?.length; j += 1) {
              const currentKey = alteredArray[i]?.keys[j];
              const currentValue = currentKey.split(':')[1];
              if (currentValue === `${d.value?.project}%`) {
                alteredArray[i].keys[j] = `${currentKey.split(':')[0]},${d.key}:${currentKey.split(':')[1]}`;
                keyPushed = true;
                break;
              }
            }
            if (!keyPushed) {
              alteredArray[i].keys.push(`${d.key}:${d.value.project}%`);
            }
          }
          d.disableLabel = true;
          break;
        }
      }
      // IF VALUES ARE DIFFERENT AND FAR
      if (!pushed) {
        d.values = [d.value.project];
        alteredArray.push(d);
      }
    });
    return alteredArray;
  };
  const surveyLines = calculateSurveyEngagementDisplayArray([
    { key: 'A', value: overallWidget1?.surveyEngagement },
    { key: 'B', value: overallWidget2?.surveyEngagement },
    { key: 'C', value: overallWidget3?.surveyEngagement },
    { key: 'D', value: overallWidget4?.surveyEngagement },
  ]);

  return (
    <React.Fragment>
      <div className="col-t1 confidence-compare-card fix-width">
        <div className="data-item-box project-box">
          <div className="a-panel a-shadow-sm text-center">
            <div className="project-title">
              <p className="m-0 capitalize-text" style={{ color: '#7d7d7c' }}>{overallWidget1.title}</p>
            </div>
            <div className="confidence-compare">
              {overallWidget1
                && <div className="d-flex align-items-center">
                  <div className="project-percentage cg-content">
                    <span class="cc-group-number">Group A</span>
                    <h3 className={`a-dropdown-item ${overallWidget1.showScore ? '' : 'no-oc-data'} `} >
                      <span style={overallWidget1.scoreColor || 'color: rgb(222, 180, 80)'}>
                        {overallWidget1.overallScore}%</span>
                    </h3>
                    {isShowPercentageChange && <span class="cc-growth">
                      <span class="material-icons">
                        {/* eslint-disable no-nested-ternary */}
                        {overallWidget1.percentageChange === 0
                          || overallWidget1.percentageChange === null
                          ? ''
                          : overallWidget1.percentageChange < 0
                            ? 'arrow_downward' : ' arrow_upward'}
                      </span>
                      {Math.abs(overallWidget1.percentageChange)}%{' '}
                      {overallWidget1.percentageChange ? '' : 'change'}
                    </span>}
                  </div>
                </div>}

              {overallWidget2
                && <div className="d-flex align-items-center">
                  <div class="cg-center px-4">
                    <span class="cgc-text">VS</span>
                  </div>
                  <div className="project-percentage cg-content">
                    <span class="cc-group-number">Group B</span>
                    <h3 className={`a-dropdown-item ${overallWidget2.showScore ? '' : 'no-oc-data'} `} >
                      <span style={overallWidget2.scoreColor || 'color: rgb(222, 180, 80)'}>
                        {overallWidget2.overallScore}%
                      </span>
                    </h3>
                    {isShowPercentageChange && <span class="cc-growth">
                      <span class="material-icons">
                        {overallWidget2.percentageChange === 0
                          || overallWidget2.percentageChange === null
                          ? ''
                          : overallWidget2.percentageChange < 0
                            ? 'arrow_downward' : ' arrow_upward'}
                      </span>
                      {Math.abs(overallWidget2.percentageChange)}%{' '}
                      {overallWidget2.percentageChange ? '' : 'change'}
                    </span>}
                  </div>
                </div>}


              {overallWidget3
                && <div className="d-flex align-items-center">
                  <div class="cg-center px-4">
                    <span class="cgc-text">VS</span>
                  </div>
                  <div className="project-percentage cg-content">
                    <span class="cc-group-number">Group C</span>
                    <h3 className={`a-dropdown-item ${overallWidget3.showScore ? '' : 'no-oc-data'} `} >
                      <span style={overallWidget3.scoreColor || 'color: rgb(222, 180, 80)'}>
                        {overallWidget3.overallScore}%
                      </span>
                    </h3>
                    {isShowPercentageChange && <span class="cc-growth">
                      <span class="material-icons">
                        {overallWidget3.percentageChange === 0
                          || overallWidget3.percentageChange === null
                          ? ''
                          : overallWidget3.percentageChange < 0
                            ? 'arrow_downward' : ' arrow_upward'}
                      </span>
                      {Math.abs(overallWidget3.percentageChange)}%{' '}
                      {overallWidget3.percentageChange ? '' : 'change'}
                    </span>}
                  </div>
                </div>}


              {overallWidget4
                && <div className="d-flex align-items-center">
                  <div class="cg-center px-4">
                    <span class="cgc-text">VS</span>
                  </div>
                  <div className="project-percentage cg-content">
                    <span class="cc-group-number">Group D</span>
                    <h3 className={`a-dropdown-item ${overallWidget4.showScore ? '' : 'no-oc-data'} `} >
                      <span style={overallWidget4.scoreColor || 'color: rgb(222, 180, 80)'}>
                        {overallWidget4.overallScore}%</span>
                    </h3>
                    {isShowPercentageChange && <span class="cc-growth">
                      <span class="material-icons">
                        {overallWidget4.percentageChange === 0
                          || overallWidget4.percentageChange === null
                          ? ''
                          : overallWidget4.percentageChange < 0
                            ? 'arrow_downward' : ' arrow_upward'}
                      </span>
                      {Math.abs(overallWidget4.percentageChange)}%{' '}
                      {overallWidget4.percentageChange ? '' : 'change'}
                    </span>}
                  </div>
                </div>}
            </div>
            {isShowPercentageChange && <p className={'m-0 ccd-label-text'} >in past {previousDaysCount} {pastDays}</p>}
            <div className="a-accordion-wrapper " style={{ marginBottom: '33px' }}>
              <div className="a-accordion">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    expandWidgets(null, overallWidget1);
                  }}
                  className="a-accordion-title bg-transparent panel-expander" >
                  <i className={`appkiticon
                                    ${overallWidget1.isExpanded ? ' icon-up-chevron-circle-outline'
                      : ' icon-down-chevron-circle-outline'}
                                    `}
                  ></i></div>
                {overallWidget1.isExpanded && <div className="a-accordion-content bg-transparent show d-block">
                  {overallWidget1.noOfOpenTextQuestionsAssociated !== 0 ? <div>
                    <p className="project-section-title d-flex align-items-center font-weight-normal">
                      <i className="mb-1 mr-1 fa fa-comments-o"></i>
                      Based on &nbsp;<a style={{ cursor: 'pointer', color: '#eb590b' }} onClick={showOpenTextDropQuesRightPanel}> {overallWidget1.noOfOpenTextQuestionsAssociated} open text questions</a>
                    </p>
                    {overallWidget1.totalKeyPhraseCount > 0 || overallWidget1.totalEmptyKeyPhraseCount > 0 ? null : <p className="text-center no-response">
                      No responses yet
                    </p>}
                  </div>
                    : <div className="no-resp-data" style={{
                      fontSize: '13px',
                      textAlign: 'center',
                      color: ' #a7a7a7',
                    }}>
                      There are no open text questions assigned to this topic
                    </div>
                  }
                  <div className="people-comments-section d-flex  justify-content-between">

                    <div className="cc-badge-group ">
                      { /* Group A */}
                      {overallWidget1 && ((overallWidget1.talkAbout
                        && overallWidget1.talkAbout.length > 0)
                        || (overallWidget1.totalKeyPhraseCount > 0
                          || overallWidget1.totalEmptyKeyPhraseCount > 0))
                        && <div className="group--badge-wrap"><span className=" ccq-tag-number preview-pane-font">A:</span>
                          {overallWidget1 && overallWidget1.talkAbout
                            && overallWidget1.talkAbout.length > 0
                            && overallWidget1.talkAbout.map((keyPhrase, index) => {
                              if (index < 3) {
                                return (
                                  <div title={keyPhrase.phrase} key={index} className={'a-badge badge-general'}
                                    onClick={() => {
                                      showOpenTextRightPanel(keyPhrase.phrase,
                                        overallWidget1.selectedSegment);
                                    }}
                                  >{keyPhrase.phrase}
                                  </div>);
                              } return '';
                            })}
                          {overallWidget1 && (overallWidget1.totalKeyPhraseCount > 0
                            || overallWidget1.totalEmptyKeyPhraseCount > 0)
                            && <div key={randomIds()} className={'a-badge a-badge badge-general'}
                              onClick={(event) => {
                                event.stopPropagation();
                                showHideOverlay(true);
                                showOpenTextNonMatchRightPanel(
                                  getKeyPhrases(overallWidget1.talkAbout),
                                  overallWidget1.selectedSegment,
                                );
                              }}
                            >...
                            </div>
                          }
                        </div>}

                      { /* Group B */}
                      {overallWidget2
                        && ((
                          overallWidget2.talkAbout
                          && overallWidget2.talkAbout.length > 0)
                          || (overallWidget2.totalKeyPhraseCount > 0
                            || overallWidget2.totalEmptyKeyPhraseCount > 0
                          ))
                        && <div className="group--badge-wrap"><span className=" ccq-tag-number preview-pane-font">B:</span>
                          {overallWidget2 && overallWidget2.talkAbout
                            && overallWidget2.talkAbout.length > 0
                            && overallWidget2.talkAbout.map((keyPhrase, index) => {
                              if (index < 3) {
                                return (
                                  <div title={keyPhrase.phrase} key={index} className={'a-badge badge-general'}
                                    onClick={() => {
                                      showOpenTextRightPanel(keyPhrase.phrase,
                                        overallWidget2.selectedSegment);
                                    }}
                                  >{keyPhrase.phrase}
                                  </div>);
                              } return '';
                            })}
                          {overallWidget2 && (
                            overallWidget2.totalKeyPhraseCount > 0
                            || overallWidget2.totalEmptyKeyPhraseCount > 0
                          )
                            && <div key={randomIds()} className={'a-badge a-badge badge-general'}
                              onClick={(event) => {
                                event.stopPropagation();
                                showHideOverlay(true);
                                showOpenTextNonMatchRightPanel(
                                  getKeyPhrases(overallWidget2.talkAbout),
                                  overallWidget2.selectedSegment,
                                );
                              }}
                            >...
                            </div>
                          }
                        </div>}
                    </div>


                    <div className="cc-badge-group">
                      { /* Group C */}
                      {overallWidget3
                        && ((
                          overallWidget3.talkAbout
                          && overallWidget3.talkAbout.length > 0)
                          || (overallWidget3.totalKeyPhraseCount > 0
                            || overallWidget3.totalEmptyKeyPhraseCount > 0
                          ))
                        && <div className="group--badge-wrap"><span className=" ccq-tag-number preview-pane-font">C:</span>
                          {overallWidget3 && overallWidget3.talkAbout
                            && overallWidget3.talkAbout.length > 0
                            && overallWidget3.talkAbout.map((keyPhrase, index) => {
                              if (index < 3) {
                                return (
                                  <div title={keyPhrase.phrase} key={index} className={'a-badge badge-general'}
                                    onClick={() => {
                                      showOpenTextRightPanel(keyPhrase.phrase,
                                        overallWidget3.selectedSegment);
                                    }}
                                  >{keyPhrase.phrase}
                                  </div>);
                              } return '';
                            })
                          }
                          {overallWidget3 && (overallWidget3.totalKeyPhraseCount > 0
                            || overallWidget3.totalEmptyKeyPhraseCount > 0)
                            && <div key={randomIds()} className={'a-badge a-badge badge-general'}
                              onClick={(event) => {
                                event.stopPropagation();
                                showHideOverlay(true);
                                showOpenTextNonMatchRightPanel(
                                  getKeyPhrases(overallWidget3.talkAbout),
                                  overallWidget3.selectedSegment,
                                );
                              }}
                            >...
                            </div>
                          }
                        </div>}

                      { /* Group D */}
                      {overallWidget4
                        && ((
                          overallWidget4.talkAbout
                          && overallWidget4.talkAbout.length > 0)
                          || (overallWidget4.totalKeyPhraseCount > 0
                            || overallWidget4.totalEmptyKeyPhraseCount > 0
                          ))
                        && <div className="group--badge-wrap">
                          <span className=" ccq-tag-number preview-pane-font">D:</span>
                          {overallWidget4 && overallWidget4.talkAbout
                            && overallWidget4.talkAbout.length > 0
                            && overallWidget4.talkAbout.map((keyPhrase, index) => {
                              if (index < 3) {
                                return (
                                  <div title={keyPhrase.phrase} key={index} className={'a-badge badge-general'}
                                    onClick={() => {
                                      showOpenTextRightPanel(keyPhrase.phrase,
                                        overallWidget4.selectedSegment);
                                    }}
                                  >{keyPhrase.phrase}
                                  </div>);
                              } return '';
                            })
                          }
                          {overallWidget4
                            && (
                              overallWidget4.totalKeyPhraseCount > 0
                              || overallWidget4.totalEmptyKeyPhraseCount > 0
                            )
                            && <div key={randomIds()} className={'a-badge a-badge badge-general'}
                              onClick={(event) => {
                                event.stopPropagation();
                                showHideOverlay(true);
                                showOpenTextNonMatchRightPanel(
                                  getKeyPhrases(overallWidget4.talkAbout),
                                  overallWidget4.selectedSegment,
                                );
                              }}
                            >...
                            </div>
                          }
                        </div>}
                    </div>
                  </div>
                  {overallWidget1.surveyEngagement && (
                    <div className="survey-section">
                      <p className="project-section-title">
                        <MaterialIcon icon="person_outline" /> Stakeholder Response Rate
                        <span class="pop-out-help user-guid">
                          <i id="user_guidance" data-for="user_guidance"
                            data-tip="result_tip"
                            class="material-icons md-24 md-dark" currentitem="false">info</i>
                          <ReactTooltip id='user_guidance' className='result_tip' place='bottom'
                            effect='solid'>
                            <span>The Stakeholder Response Rate is determined
                              by dividing the total number of responses received by the
                              total number of questions asked to all topic stakeholders.
                              resulting in a % response rate.</span>
                          </ReactTooltip>
                        </span></p>
                      <div className="a-customized-ratings a-customized-8 mb-2">
                        {surveyLines.map(survey => (
                          <>
                            <div className="a-ratings-detail" style={{ marginBottom: '16px' }}>
                              <div className='d-flex align-items-end'>
                                <span className='widget-survey-engagement' style={{
                                  marginBottom: '-5px', marginRight: '10px', fontWeight: '700', color: '#BCBCBC',
                                }}>{survey.key} </span>
                                <div className="a-ratings-total">
                                  <div
                                    className="a-customized-ratings a-customized-primary a-customized-white-background a-customized-8 a-ratings-percent a-ratings-value"
                                    style={{ width: `${survey.value.project}%`, height: '7px' }}>

                                  </div>
                                  <div style={{ padding: '6px', textAlign: 'center' }}>

                                    <span className='widget-survey-engagement' style={{ color: '#828282' }}>{survey.value.project}%  (
                                      {survey?.value?.project
                                        > survey?.value?.allProjectsAvg
                                        ? ' (Above Average)' : ' (Below Average)'})
                                    </span>
                                    {survey?.value?.showRedLine
                                      && <div className="average-marker"
                                        data-for={survey.key}
                                        data-tip="all-projects-label-tooltip"
                                        style={{ left: `${survey?.value?.allProjectsAvg}%` }}
                                      >
                                        <ReactTooltip
                                          id={survey.key}
                                          className="all-projects-label-tooltip"
                                          effect='solid'
                                          multiline={true}
                                          backgroundColor='#6b6b6b'
                                        >
                                          {survey.key}: {survey?.value?.project}
                                          {survey?.value?.project ? '%' : ''}
                                        </ReactTooltip>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}


                      </div>

                    </div>
                  )}
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  projectSegments: state.project.projectSegments,
});

export default connect(mapStateToProps,
  null)(SegmentGroupOverallConfidenceCard);
