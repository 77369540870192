import React, { Component } from 'react';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import MaterialIcon from 'material-icons-react';
import { insertDomain, validateDomainName, updateDomain } from '../actions/domain';
import { addDomainSelector } from '../selectors/domain';
import { SpinnerButton } from '../../../../ui-components/Loader';
import Authorise from '../../../../Authorise';

const mapStateToProps = state => ({
  addDomain: addDomainSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
  isSysAdmin: state.login.isSysAdmin,
});

const mapDispatchToProps = {
  insertDomain,
  validateDomainName,
  updateDomain,
};

const domainIcons = [
  {
    id: 1,
    title: 'icon-airplane-outline',
    selected: false,
  },
  {
    id: 2,
    title: 'icon-audience-outline',
    selected: false,
  },
  {
    id: 3,
    title: 'icon-spark-line-outline',
    selected: false,
  },

  {
    id: 4,
    title: 'icon-time-outline',
    selected: false,
  },

  {
    id: 5,
    title: 'icon-rating-outline',
    selected: false,
  },
  {
    id: 6,
    title: 'icon-physician-outline',
    selected: false,
  },
  {
    id: 7,
    title: 'icon-center-map-outline',
    selected: false,
  },
  {
    id: 8,
    title: 'icon-bar-chart-outline',
    selected: false,
  },
  {
    id: 9,
    title: 'icon-calendar-outline',
    selected: false,
  },
  {
    id: 10,
    title: 'icon-globe-outline',
    selected: false,
  },

  {
    id: 11,
    title: 'icon-location-outline',
    selected: false,
  },
  {
    id: 12,
    title: 'icon-survey-outline',
    selected: false,
  },
  {
    id: 13,
    title: 'icon-notification-outline',
    selected: false,
  },
  {
    id: 14,
    title: 'icon-email-outline',
    selected: false,
  },
  {
    id: 15,
    title: 'icon-alert-outline',
    selected: false,
  },
  {
    id: 16,
    title: 'icon-brightness-outline',
    selected: false,
  },
  {
    id: 17,
    title: 'icon-cloud-outline',
    selected: false,
  },
  {
    id: 18,
    title: 'icon-news-outline',
    selected: false,
  },
  {
    id: 19,
    title: 'icon-person-outline',
    selected: false,
  },
  {
    id: 20,
    title: 'icon-target-outline',
    selected: false,
  },
  {
    id: 21,
    title: 'icon-uptrend-outline',
    selected: false,
  },
  {
    id: 22,
    title: 'icon-view-outline',
    selected: false,
  },
  {
    id: 23,
    title: 'icon-archive-outline',
    selected: false,
  },
  {
    id: 24,
    title: 'icon-setting-outline',
    selected: false,
  },
];

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isValidateDomainName(value, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'A domain with the same name already exists.';
    const emptyStringErrorMessage = 'Please enter a valid title for domain.';
    const title = value.title.trim().toLowerCase().replace(/  +/g, ' ');
    const { questionModelId } = value;
    if (title && title !== '' && title !== value.initialTitle.toLowerCase()) {
      validateDomainName({ domain: title, questionModelId }).then((val) => {
        if (val) {
          setError(actions, 'title', errorMessage);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else if (title === '') {
      setError(actions, 'title', emptyStringErrorMessage);
      resolve(true);
    } else {
      resolve(false);
    }
  });
}

function checkDescriptionLength(e, setFieldTouched, setFieldError, setFieldValue) {
  setFieldValue('description', e.target.value);
  setFieldValue('descriptionCharacter', e.target.value.length);
  const errorMessage = 'You have exceeded the maximum character limit';
  if (e.target.value !== '') {
    if (e.target.value.length > 250) {
      setFieldTouched('description', true);
      window.setTimeout(() => {
        setFieldError('description', errorMessage);
      }, 100);
    }
  }
}

function setEnforceAnonymityRule(e, setFieldValue) {
  setFieldValue('isEnforceAnonymityRule', $(e.currentTarget).is(':checked'));
}

function selectIcon(e, values, index, setFieldValue, title) {
  values.iconCode.map((icon) => {
    icon.selected = false;
  });
  $('.active-icon').removeClass('active-icon');
  setFieldValue('selectedIcon', title);
  values.iconCode[index].selected = true;
  e.currentTarget.className += ' active-icon';
}
const BasicForm = ({
  values, touched, errors, setFieldValue, isSubmitting, setFieldTouched, setFieldError, isSysAdmin,
}) => (
    <Form>
      <div className="r-s-body mt-3">
        <div className="r-s-body mt-3">
          <div className="form-group">
            <div className="a-form-label">Focus Area Title <span className="mandatory-icon">*</span></div>
            <Field
              name="title"
              autoFocus={!values.id}
              autoComplete="off"
              className={errors.title && touched.title ? 'error a-text-input' : 'a-text-input'}
            />
            <div className="error">
              <ErrorMessage name="title" />
            </div>
          </div>
        </div>
        <div className="r-s-body mt-3">
          <div className="form-group">
            <div className="a-form-label">
              {values.descriptionCharacter > 0 && <span className={values.descriptionCharacter > 250
                ? 'pull-right red lower-case preview-pane-font' : 'pull-right lower-case preview-pane-font'}>
                {values.descriptionCharacter}/250 characters</span>}
              <span className="preview-pane-font">Focus Area Description </span>
            </div>
            <Field
              component='textarea'
              name="description"
              placeholder="Enter description"
              autoComplete="off"
              className='a-text-input'
              onChange={
                value => checkDescriptionLength(
                  value, setFieldTouched, setFieldError, setFieldValue,
                )}
            />
            <div className="error">
              <ErrorMessage name="description" />
            </div>
          </div>
        </div>
        <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
          perform="settings"
          permit={() => (
            <div className="form-group mt-3">
              <label className="a-toggle a-m-5 font-normal">
                <input
                  type="checkbox"
                  name="toggle1"
                  checked={values.isEnforceAnonymityRule}
                  onClick={e => setEnforceAnonymityRule(e, setFieldValue)}
                />
                <div className="a-toggle-mark">
                  <span className="on preview-pane-font">Yes</span>
                  <span className="off preview-pane-font">No</span>
                  <span className="switch preview-pane-font" />
                </div>
                <span className="a-toggle-text preview-pane-font">Enforce anonymity rules for this focus area</span>
                <span class="pop-out-help mt-2">
                  <MaterialIcon id='isEnforceAnonymityRules' data-for='isEnforceAnonymityRules' data-tip='domain_setting_tooltip' icon="help"
                  />
                  <ReactTooltip id='isEnforceAnonymityRules' className='domain_setting_tooltip' place='top'
                    effect='solid'>
                    If you select Yes, results for this focus area will not appear until{' '}
              the anonymity criteria have been met.
              If you select No, results will appear immediately{' '}
              after at least one response has been received.
            </ReactTooltip>
                </span>
              </label>
            </div>)} />
        <div className="a-form-label preview-pane-font">Symbol <span className="mandatory-icon">*</span></div>
        <div className="a-panel a-margin-tp-50 a-padding-20 icon-section-wrapper code-wrapper">
          <ul className="appkit-icons clearfix" name="iconCode">
            {values.iconCode.map(
              (name, index) => (
                <li
                  key={`iconCode${index}`}
                  onClick={e => selectIcon(e, values, index, setFieldValue, name.title)}
                  className={`${name.selected === true ? 'active-icon ' : ''} icon-item-box`}
                >
                  <div className="icon-item-section-wrapper">
                    <div className="normal-layout">
                      <i className={`appkiticon ${name.title}`} />
                    </div>
                  </div>
                </li>
              ),
              this,
            )}
          </ul>
          <div className="error">
            <ErrorMessage name="selectedIcon" />
          </div>
        </div>
      </div>
      <div className="r-s-actions mt-4">
        <div className="mt-3">
          <SpinnerButton
            isLoading={isSubmitting}
            label={values.id === 0 ? 'CREATE' : 'SAVE CHANGES'}
          />
        </div>
      </div>
    </Form>
);

class AddDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'AddDomain',
      iconCode: domainIcons,
      Title: '',
      selectedIcon: '',
      isSubmitting: false,
      description: '',
      isEnforceAnonymityRule: true,
    };
    this.saveDomain = this.saveDomain.bind(this);
    this.hideAddDomainPanel = this.hideAddDomainPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideAddDomainPanel();
    }
  }

  removeActiveIcon() {
    this.state.iconCode.map((icon) => {
      icon.selected = false;
      return icon;
    });
  }

  selectedIcon(data) {
    this.state.iconCode.map((icon) => {
      icon.selected = false;
      if (icon.title === data.title) {
        icon.selected = true;
      }
    });
    this.setState({
      selectedIcon: data.title,
    });
  }

  saveDomain(model) {
    if (model.id !== 0) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '),
        icon_code: model.selectedIcon,
        id: model.id,
        description: model.description.trim().replace(/  +/g, ' '),
        questionModelId: model.questionModelId,
        isEnforceAnonymityRule: model.isEnforceAnonymityRule,
      };
      this.props.updateDomain(data).then(() => {
        this.props.hideAddDomainPanel();
      });
    } else if (model.title) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '), icon_code: model.selectedIcon, description: model.description.trim().replace(/  +/g, ' '), questionModelId: model.questionModelId, isEnforceAnonymityRule: model.isEnforceAnonymityRule,
      };
      this.props.insertDomain(data).then(() => {
        this.showSpinner(false);
        this.props.hideAddDomainPanel();
      });
    }
  }

  hideAddDomainPanel() {
    this.props.hideAddDomainPanel();
  }

  isValidateDomainName(values, actions) {
    this.showSpinner(true);
    isValidateDomainName(values, actions).then((isExist) => {
      if (!isExist) {
        this.saveDomain(values);
      } else {
        this.showSpinner(false);
      }
    });
  }

  showSpinner(isSubmitting) {
    this.setState(prevState => ({
      ...prevState,
      isSubmitting,
    }));
  }

  render() {
    this.removeActiveIcon();

    const { selectedDomain, isSysAdmin } = this.props;
    if (selectedDomain.id) {
      this.state.iconCode.map((icon) => {
        if (selectedDomain.icon_code === icon.title) icon.selected = true;
        return icon;
      });
    }
    let domainModel = {};
    domainModel = {
      id: selectedDomain.id ? selectedDomain.id : 0,
      title: selectedDomain.title ? selectedDomain.title : this.state.Title,
      initialTitle: selectedDomain.title ? selectedDomain.title : this.state.Title,
      selectedIcon: selectedDomain.icon_code ? selectedDomain.icon_code : this.state.selectedIcon,
      iconCode: this.state.iconCode,
      description: selectedDomain.description
        ? selectedDomain.description : this.state.description,
      descriptionCharacter: selectedDomain.description ? selectedDomain.description.length : 0,
      questionModelId: this.props.questionModelId,
      isEnforceAnonymityRule: selectedDomain.is_enforce_anonymity_rule !== undefined
        ? selectedDomain.is_enforce_anonymity_rule : this.state.isEnforceAnonymityRule,
    };

    return (
      <div className="right-sidebar" ref={this.setWrapperRef}>
        <div className="r-s-header">
          <div className="r-s-title">
            <span>
              {selectedDomain.id ? 'Modify existing focus area' : 'Create a new focus area'}
              <i className="appkiticon icon-close-outline" onClick={this.hideAddDomainPanel} />
            </span>
          </div>
          <div className="indicate_val">
            <span className="mandatory-icon">* </span>
            <span className="in_text preview-pane-font"> indicates required field</span>
          </div>
        </div>
        <div className="app">
          <Formik
            enableReinitialize
            initialValues={domainModel}
            onSubmit={(values, actions) => this.isValidateDomainName(values, actions)}
            validationSchema={Yup.object().shape({
              title: Yup.string().trim().required('focus area is required.')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
              selectedIcon: Yup.string().required('A symbol is required.'),
              description: Yup.string().max(250, 'You have exceeded the maximum character limit')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
            })}
            render={formikProps => (
              <BasicForm {...formikProps} isSubmitting={this.state.isSubmitting}
                isSysAdmin={isSysAdmin} />
            )}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddDomain);
