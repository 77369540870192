import actionType from '../../../../redux/modules/settings/actionType';
import questionModelService from '../../../../redux/services/questionModelService';

export const getQuestionModels = () => dispatch => questionModelService
  .getQuestionModels()
  .then((res) => {
    const modelData = res.data;
    dispatch({ type: actionType.GET_QUESTION_MODEL_LIST, modelData });
    return res;
  })
  .catch(error => error);

// action method for updating questionmodel list
export const
 UpdateQuestionModels = (portfolioData, allPortfolio) => dispatch => questionModelService
.updateQuestionModalList(portfolioData)
.then((res) => {
  if (res.data !== undefined) {
    const modelData = allPortfolio;
    dispatch({ type: actionType.GET_QUESTION_MODEL_LIST, modelData });
  }
  return res;
})
.catch(error => error);
// action method for title exist in portfolio
export function validateQuestionModelName(questionModel) {
  return questionModelService
    .validateQuestionModelName(questionModel)
    .then(res => res.data)
    .catch({});
}

export function insertQuestionModel(questionModel) {
  return questionModelService
    .insertQuestionModel(questionModel)
    .then(res => res.data)
    .catch(error => error);
}

export function showQuestionModelRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_QUESTION_MODEL_RIGHTPANEL });
  };
}

export function hideQuestionModelRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_QUESTION_MODEL_RIGHTPANEL });
  };
}

export const changeSelectedQuestionModel = (questionModelId, page) => (dispatch) => {
  dispatch({
    type: actionType.CHANGE_QUESTION_MODELID,
    questionModelId,
    page,
  });
};
