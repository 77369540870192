import actionType from './actionType';

// Configured initial state for login
const initialState = {
  systemTerminology: {},
  projectLifeCycleStage: {},
  projectLifeCycleStagesList: [],
  unsavedChangesModal: false,
  unsavedChanges: false,
};

// Creating login reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_SYSTEM_TERMINOLOGY:
      return {
        ...state,
        systemTerminology: action.systemTerminology,
      };
    case actionType.UPDATE_SYSTEM_TERMINOLOGY:
      return {
        ...state,
        systemTerminology: {
          ...state.systemTerminology,
          systemTerminology: action.systemTerminology,
        },
      };
    case actionType.GET_PROJECT_LIFECYCLE_STAGES_LIST:
      return {
        ...state,
        projectLifeCycleStagesList: action.projectLifeCycleStagesList,
      };
    case actionType.GET_PROJECT_LIFECYCLE_STAGE:
      return {
        ...state,
        projectLifeCycleStage: action.projectLifeCycleStage,
      };
    case actionType.CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER:
      return {
        ...state,
        projectLifeCycleStages: action.projectLifeCycleStages,
      };
    case actionType.EMPTY_PROJECT_LIFECYCLE_STAGES_LIST:
      return {
        ...state,
        projectLifeCycleStagesList: [],
      };

    case actionType.ENABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsavedChanges: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES:
      return {
        ...state,
        unsavedChanges: false,
      };
    case actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL:
      return {
        ...state,
        unsavedChangesModal: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL:
      return {
        ...state,
        unsavedChangesModal: false,
      };

    default:
      return state;
  }
};
