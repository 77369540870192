import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Formik, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import {
  intersection, concat, difference, includes, pull,
} from 'lodash';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { SpinnerButton } from '../Loader';
import { isInternalPwcUser } from '../../utils/helper';
import { getSystemTerminology } from '../../modules/settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../modules/settings/question-model/selectors/general';
import {
  hideRecordProcessing,
  hideProcessing,
} from '../../modules/project/actions/project';


const mapStateToProps = state => ({
  isSysAdmin: state.login.isSysAdmin,
  systemTerminology: getSystemTerminologySelector(state),
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
});
const mapDispatchToProps = {
  getSystemTerminology,
  hideRecordProcessing,
  hideProcessing,
};

class RegisterEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreating: false,
      email: '',
      emails: [],
      totalEmails: [],
      errorMessages: [],
      errorEmails: [],
      responseEmails: [],
      setFieldValue: null,
      isDisabled: false,
      isShowWarning: false,
      restrictedUserMessage: '',
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
    this.modalWrapperRef = this.modalWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.checkForError = this.checkForError.bind(this);
    this.removeEmail = this.removeEmail.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    this.setState({ availableEmails: this.props.availableEmails });
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#registerEmailModal').modal('show');
    $(document).ready(() => {
      $('.react-multi-email > input:text').keyup(() => {
        if ($('.react-multi-email > input:text').val().trim() === '') {
          this.setState({ isDisabled: false });
        } else if (this.state.emails) {
            this.setState({ isDisabled: false });
          } else {
            this.setState({ isDisabled: true });
          }
      });
    });
  }

  modalWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)
      && !this.props.showProcessingRecords) {
      this.cancelClick();
    }
  }

  checkIfUserIsInternal(emails) {
    return emails.some(email => !isInternalPwcUser(email));
  }

  cancelClick() {
    this.hideModal();
    this.props.cancelClick();
  }

  hideModal() {
    $('#registerEmailModal').modal('hide');
  }

  onContinueButtonClick(formData) {
    this.setState({ responseEmails: [] });
    let { emails } = formData;
    const { errorEmails, errorMessage, setFieldValue } = this.state;
    const tempEmails = [];
    this.setState({ totalEmails: emails });
    if (emails.length > 0 && !errorMessage) {
      $('.react-multi-email > input:text').prop('disabled', true);
      this.showSpinner(true);
      this.props.onContinueButtonClick(emails).then((response) => {
        $('.react-multi-email > input:text').prop('disabled', false);
        this.showSpinner(false);
        if (response.length > 0) {
          this.props.hideProcessing();
          response.map((email) => {
            if (email.errorMessage) {
              errorEmails.push(email.emailId);
              tempEmails.push(email.emailId);
            }
          });
          if (tempEmails.length > 0) {
            emails = tempEmails;
          }
          this.setState({
            errorEmails, responseEmails: response, emails,
          });
          setFieldValue('emails', emails);
        } else {
          this.props.hideRecordProcessing();
          this.hideModal();
        }
      });
    }
  }

  onContinueTestButtonClick(formData) {
    this.setState({ responseEmails: [] });
    let { emails } = formData;
    const { errorEmails, errorMessage, setFieldValue } = this.state;
    const tempEmails = [];
    this.setState({ totalEmails: emails });
    if (emails.length > 0 && !errorMessage) {
      $('.react-multi-email > input:text').prop('disabled', true);
      this.showSpinner(true);
      this.props.onContinueButtonClick(emails, this.cancelClick()).then((response) => {
        $('.react-multi-email > input:text').prop('disabled', false);
        this.showSpinner(false);
        if (response.length > 0) {
          this.props.hideProcessing();
          response.map((email) => {
            if (email.errorMessage) {
              errorEmails.push(email.emailId);
              tempEmails.push(email.emailId);
            }
          });
          if (tempEmails.length > 0) {
            emails = tempEmails;
          }
          this.setState({
            errorEmails, responseEmails: response, emails,
          });
          setFieldValue('emails', emails);
        } else {
          this.props.hideRecordProcessing();
          this.hideModal();
        }
      });
    }
  }

  showSpinner(isCreating) {
    this.setState({ isCreating });
  }

  removeEmail(index) {
    if (!this.props.showProcessingRecords) {
      const { emails } = this.state;
      const updatedEmails = emails.filter((e, i) => i !== index);
      this.setState({ emails: updatedEmails });
    }
  }

  checkDuplicateEmails(emails) {
    const unique = [];
    const duplicates = emails.filter((email) => {
      if (unique.find(uniqueEmail => uniqueEmail === email)) {
        return true;
      }
      unique.push(email);
      return false;
    });
    return duplicates;
  }

  checkForError(emails, setFieldValue) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.props.hideRecordProcessing();
    const {
      availableEmails, availableAdmins, availableViewersEmails,
      isRestrictedUser, isStakeholders, isTestEmail,
    } = this.props;
    const { responseEmails } = this.state;
    const matchedInputEmails = this.checkDuplicateEmails(emails);
    const matchedtestInputEmails = intersection(emails);
    const tempEmails = [];
    if (responseEmails.length > 0) {
      responseEmails.map((email) => {
        if (email.errorMessage && includes(emails, email.emailId)) {
          tempEmails.push(email.emailId);
          pull(emails, email.emailId);
        }
      });
      if (tempEmails.length > 0) {
        emails = concat(tempEmails, emails);
        this.setState({ errorEmails: tempEmails });
      }
    }
    if (emails.length > 0 && isTestEmail) {
      const inputemails = matchedtestInputEmails.filter((x, index) => index > 4);
      emails = difference(emails, matchedtestInputEmails);
      emails = concat(matchedtestInputEmails, emails);
      const errorMessages = inputemails.map(i => ({ errorMessage: 'You can only send the test to a maximum of 5 Stakeholders.', emailId: i }));
      this.setState({
        errorEmails: tempEmails.concat(inputemails),
        errorMessages,
      });
    }
    const matchedEmails = intersection(emails, availableEmails);
    if (matchedEmails.length > 0) {
      emails = difference(emails, matchedEmails);
      emails = concat(matchedEmails, emails);
      const errorMessages = matchedEmails.map(i => ({ errorMessage: 'User with email address already exists. Please add a different user.', emailId: i }));
      this.setState({
        errorEmails: tempEmails.concat(matchedEmails),
        errorMessages,
      });
    }
    if (matchedInputEmails.length > 0) {
      const errorMessages = matchedInputEmails.map(i => ({ errorMessage: 'User with email address already exists. Please add a different user.', emailId: i }));
      this.setState({
        errorEmails: tempEmails.concat(matchedInputEmails),
        errorMessages,
      });
    }
    const matchedAdminEmails = intersection(emails, availableAdmins);
    if (matchedAdminEmails.length > 0) {
      emails = difference(emails, matchedAdminEmails);
      emails = concat(matchedAdminEmails, emails);
      const errorMessages = matchedAdminEmails.map(i => ({ errorMessage: 'You can\'t add topic administrators as topic viewers.', emailId: i }));
      this.setState({
        errorEmails: tempEmails.concat(matchedAdminEmails),
        errorMessages,
      });
    }
    const matchedViewerEmails = intersection(emails, availableViewersEmails);
    if (matchedViewerEmails.length > 0) {
      emails = difference(emails, matchedViewerEmails);
      emails = concat(matchedViewerEmails, emails);
      const errorMessages = matchedViewerEmails.map(i => ({ errorMessage: 'You can\'t add topic viewers as topic administrators.', emailId: i }));
      this.setState({
        errorEmails: tempEmails.concat(matchedViewerEmails),
        errorMessages,
      });
    }
    if (!isStakeholders) {
      const isExternalUser = this.checkIfUserIsInternal(emails);
      if (emails.length === 0 && isRestrictedUser) {
        this.setState({ restrictedUserMessage: '', isShowWarning: false });
      } else if (isExternalUser && isRestrictedUser) {
        this.setState({
          restrictedUserMessage: 'This organisation prevents external users from being added to this role.',
          isShowWarning: true,
        });
      } else {
        this.setState({
          restrictedUserMessage: '',
          isShowWarning: false,
        });
      }
    }

    if (matchedEmails.length === 0 && matchedAdminEmails.length === 0
      && matchedViewerEmails.length === 0 && tempEmails.length === 0
      && matchedInputEmails.length === 0 && matchedtestInputEmails.length > 0) {
      this.setState({ errorMessages: [], errorEmails: [] });
    }
    setFieldValue('emails', emails);
    this.setState({ emails, setFieldValue });
  }

  render() {
    const {
      title, message, placeholder, isProcessingRecords, passedRecords, failedRecords,
      showProcessingRecords, isTestEmail,
    } = this.props;
    const {
      isCreating, emails, errorMessages, errorEmails, isDisabled, responseEmails, totalEmails,
      isShowWarning, restrictedUserMessage,
    } = this.state;
    return (
      <div
        aria-hidden="true"
        className="modal a-modal fade add-stakeholder-modal"
        id="registerEmailModal"
        role="dialog"
        ref={this.modalWrapperRef}
        data-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header ">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h4">{title}</div>
              </div>
              <span className="icon-wrapper">
                <a className={this.state.isCreating ? 'disabled' : ''}>
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    data-bs-dismiss="modal"
                  />
                </a>
              </span>
            </div>
            <Formik
              initialValues={{ emails }}
              validateOnBlur={true}
              onSubmit={(values, actions) => {
                if (isTestEmail) this.onContinueTestButtonClick(values, actions);
                else this.onContinueButtonClick(values, actions);
              }}
              validationSchema={Yup.object().shape({
                emails: Yup.array()
                  .required('Email is required.')
                  .min(1, 'Email Address is not valid'),
              })}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="a-modal-body pt-0 mt-1">
                    <div className=" a-font-md mb-4">{message}</div>
                    <div className="form-group">
                      <span class="pull-right lower-case rml-count preview-pane-font">{emails.length} User{emails.length > 1 ? 's' : ''}</span>
                    </div>
                    {isTestEmail
                      ? <div className="form-group">
                        <ReactMultiEmail
                          placeholder={placeholder}
                          emails={emails}
                          className={`data-scroller-container react-multi-email-test preview-pane-font ${errorMessages.length > 0 ? 'react-multi-email-error preview-pane-font' : ''}`}
                          onChange={(_emails) => {
                            const data = _emails.map(e => e.toLowerCase());
                            this.setState({ isDisabled: false });
                            this.checkForError(data, setFieldValue);
                          }}
                          validateEmail={email => isEmail(email) // return boolean
                          }
                          getLabel={(
                            email,
                            index,
                            removeEmail,
                          ) => (
                            <div data-tag key={index} className={includes(errorEmails, email) ? 'react-multi-email-tag-error' : ''}>
                              <span className='preview-pane-font'>{email}</span>
                              <span data-tag-handle onClick={
                                (e) => {
                                  if (this.props.showProcessingRecords) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  } else {
                                    removeEmail(index);
                                  }
                                }}>
                                ×
                              </span>
                            </div>
                          )}
                        />
                        <div className="error data-scroller-container react-multi-email-error-message-test">
                          {isShowWarning ? (<React.Fragment>
                            {restrictedUserMessage}
                            <br /></React.Fragment>) : ''}
                          <ErrorMessage name="emails" />
                          {errorMessages.map(e => (includes(emails, e.emailId) ? (<React.Fragment>{e.errorMessage}<br /></React.Fragment>) : ''))}
                          {responseEmails.map(e => (includes(emails, e.emailId) ? (<React.Fragment>{e.errorMessage}<br /></React.Fragment>) : ''))}
                        </div>
                      </div>
                      : <div className="form-group">
                        <ReactMultiEmail
                          placeholder={placeholder}
                          emails={emails}
                          className={`data-scroller-container preview-pane-font ${errorMessages.length > 0 ? 'react-multi-email-error' : ''}`}
                          onChange={(_emails) => {
                          const data = _emails.map(e => e.toLowerCase());
                            this.setState({ isDisabled: false });
                            this.checkForError(data, setFieldValue);
                          }}
                          validateEmail={email => isEmail(email) // return boolean
                          }
                          getLabel={(
                            email,
                            index,
                            removeEmail,
                          ) => (
                            <div data-tag key={index} className={includes(errorEmails, email) ? 'react-multi-email-tag-error' : ''}>
                              <span className='preview-pane-font'>{email}</span>
                              <span data-tag-handle onClick={
                                (e) => {
                                  if (this.props.showProcessingRecords) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  } else {
                                    removeEmail(index);
                                  }
                                }}>
                                ×
                              </span>
                            </div>
                          )}
                        />
                        <div className="error data-scroller-container react-multi-email-error-message">
                          {isShowWarning ? (<React.Fragment>
                            {restrictedUserMessage}
                            <br /></React.Fragment>) : ''}
                          <ErrorMessage name="emails" />
                          {errorMessages.map(e => (includes(emails, e.emailId) ? (<React.Fragment>{e.errorMessage}<br /></React.Fragment>) : ''))}
                          {responseEmails.map(e => (includes(emails, e.emailId) ? (<React.Fragment>{e.errorMessage}<br /></React.Fragment>) : ''))}
                        </div>
                      </div>
                    }

                  </div>
                  <div className="a-modal-footer a-border-tp justify-content-end c-question-ask">
                    {isProcessingRecords && (
                      <span className='preview-pane-font' style={{
                        position: 'absolute', left: 25, fontSize: 10, color: failedRecords > 0 ? 'red' : 'rgba(0, 0, 0, 0.6)',
                      }}>
                        {/* eslint-disable-next-line no-undef */}
                        {passedRecords} of  {`${totalEmails.length} `}
                        users assigned successfully</span>)}
                    <button disabled={showProcessingRecords} style={{ fontSize: '0.875rem' }}
                      className="btn c-question-cancel" onClick={() => this.cancelClick()}>Cancel</button>
                    <SpinnerButton isLoading={isCreating} disabled={errorEmails.length > 0 || errorMessages.length > 0 || emails.length === 0 || isDisabled || isShowWarning} type="transparent" label={isTestEmail ? 'SEND' : 'Add'} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

RegisterEmail.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  onContinueButtonClick: PropTypes.func.isRequired,
  cancelClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegisterEmail);
