import {
  difference, isEqual,
} from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import actionType from './actionType';
import { sortArrayValues } from '../../../utils/helper';
import {
  SORT_PORTFOLIOS_ARRAY,
} from '../../../Constants';

function getFilteredQuestions(questionData, searchQuery) {
  const filteredData = questionData.filter(
    s => s.title && s.title.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  return filteredData;
}

// Configured initial state for projectResponse
const initialState = {
  projectData: {
    isSaved: false,
    newProjectId: 0,
    selectedProjectId: 30,
    isProjectInEditMode: true,
  },
  portfolios: [],
  domains: [],
  relevantDomains: [],
  stages: [],
  projectDetails: {
    id: 0,
    selectedPortfolio: '',
    selectedPortfolioName: '',
    selectedQuestionModelName: '',
    selectedQuestionSet: '',
    name: '',
    refCode: '',
    selectedBudget: '',
    lifecycleStage: '',
    stakeHoldersCanViewMobile: true,
    stakeHoldersCanView: false,
    isActive: true,
    analysisPeriod: 7,
    selectedDropHour: 9,
    selectedDropTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
    projectOpenTextQuestions: [],
    questionModels: [],
    projectAdmins: [],
    projectViewers: [],
    completionMessage: '',
    completionMessageCharacter: 0,
    isSaved: false,
    isDrafted: true,
    welcomeMessage: '',
    isDropRecurring: true,
    adHocDropDate: '',
    isNextAdhocDropScheduled: false,
  },
  showDeleteModal: false,
  isSpinnerLoading: false,
  currentCheckUncheckDomain: {},
  openTextQuestions: {
    question: {},
    questionsList: [],
    questionsCount: 0,
    selectQuestionCount: 0,
    selectAll: false,
  },
  questionsForFilter: {
    question: {},
    questionsList: [],
    questionsCount: 0,
    selectAll: false,
  },
  userFilterGroups: {
    filterGroupName1: '',
    filterGroupName2: '',
    filterGroupName3: '',
    filterGroupName4: '',
    filterGroupValue1: [],
    filterGroupValue2: [],
    filterGroupValue3: [],
    filterGroupValue4: [],
  },
  customQuestionBasket: {
    projectId: 0,
    isSent: false,
    customQuestions: [],
    automatedQuestions: [],
    questionCount: 0,
    laterQuestionSaveMessage: '',
    disableDomainAttribute: [],
    associatedStakeholderCountWithFilter: 0,
    filterGroupValues: [],
    isSentToAllStakeHolders: false,
  },
  isProjectCheckValue: false,
  previewRightPannel: {
    showPanel: false,
    disableSendBtn: false,
    userFilterGroupValues: {
      selectedGroupValue1: [],
      selectedGroupValue2: [],
      selectedGroupValue3: [],
      selectedGroupValue4: [],
    },
  },
  isOpenDomainFocusPanel: false,
  showOpenTextPanelBool: false,
  unsavedChangesModalFocusDomain: false,
  unsavedChangesFocusDomain: false,
  domainWidgets: [],
  unsavedChangesModalCustomDomain: false,
  unsavedChangesCustomDomain: false,
  projectStakeholdersCount: 0,
  filterGroupModel: {
    projectName: '',
    filterGroupName1: '',
    filterGroupName2: '',
    filterGroupName3: '',
    filterGroupName4: '',
    stakeholders: [],
  },
  filterGroupValues: {
    values: [],
    userId: [],
  },
  isFilterGroupSaveSuccessfully: false,
  filterUsersCount: 0,
  projectSegments: [],
  isProjectSegmentsLoaded: false,
  isProjectStakeholderAssociated: false,
  // disable project crash due to segment dropdown on topic change
  currentlySelectedProjectId: 0,
  isProjectInDraftedState: false,
  isProjectActive: true,
  nextAdhocDropDate: '',
  adhocDropOffset: '',
  dropTimeZone: '',
  isProjectAdhoc: true,
  projectUpdatedStatus: {
    selectedStage: '',
    nextDropdate: '',
    nextFullDropDate: '',
  },


  questionSentToFutureDrop: false,
  questionSentToFutureDropMessage: null,
  overAllWidget: {},
  segmentGroupDashboardData: {
    segmentGroupData1: null,
    segmentGroupData2: null,
    segmentGroupData3: null,
    segmentGroupData4: null,
  },
  segmentGroupValue: {
    segmentGroup1: 0,
    segmentGroup2: -1,
    segmentGroup3: -1,
    segmentGroup4: -1,
  },
  segmentGroupOverall: {
    segmentGroup1: {},
    segmentGroup2: {},
    segmentGroup3: {},
    segmentGroup4: {},
  },
  duplicateSegment: {
    isSegmentAlreadyCreated: false,
    duplicateMessage: '',
  },
  selectedSegment: '',
  isQuestionEmailResent: false,
  resendStakeHolderFirstName: null,
  resendStakeHolderLastName: null,
  resendStakeHolderEmail: null,
  userSelectedfilterGroupValues: null,
  isQuestionUrlCopied: false,
  copiedLinkStakeHolderFirstName: null,
  copiedLinkStakeHolderLastName: null,
  copiedLinkStakeHolderEmail: null,
  projectStakeholders: [],
  errorStackholders: [],
  importStackholdersFlag: false,
  isTopicReadyForArchive: false,
};

function getProjectDetails(
  projectDetails,
  projectAdmins,
  projectViewers,
  projectOpenTextQuestions,
  isInEditMode,
  state,
  response,
) {
  if (isInEditMode) {
    const { lifecycleStage, portfolios } = response;
    return {
      id: projectDetails.id,
      selectedPortfolio: projectDetails.portfolio_id,
      selectedQuestionSet: projectDetails.question_model_id,
      name: projectDetails.title,
      refCode: projectDetails.ref_code,
      completionMessage: projectDetails.completion_message,
      completionMessageCharacter: projectDetails.completion_message
        ? projectDetails.completion_message.length : 0,
      selectedBudget: projectDetails.budget,
      selectedDropHour: projectDetails.drop_hour,
      selectedDropTimezone: projectDetails.drop_timezone,
      stakeHoldersCanView: projectDetails.ps_dashboard,
      stakeHoldersCanViewMobile: projectDetails.ps_dashboard_mobile,
      isActive: projectDetails.is_active,
      lifecycleStage: lifecycleStage.stage_id,
      selectedPortfolioName:
        portfolios.find(x => x.id === projectDetails.portfolio_id).title,
      selectedQuestionModelName: response.questionModels.title,
      analysisPeriod: projectDetails.period_length || '',
      projectAdmins,
      projectViewers,
      projectOpenTextQuestions,
      lastDropDate: projectDetails.lastDropDate,
      isDrafted: projectDetails.is_drafted,
      welcomeMessage: projectDetails.welcome_message,
      isDropRecurring: projectDetails.is_drop_recurring,
      adHocDropDate: projectDetails.adhocDropDate || '',
      isNextAdhocDropScheduled: projectDetails.isNextAdhocDropScheduled,
    };
  }

  const projDetails = state.projectDetails;
  projDetails.projectAdmins = projectAdmins;
  projDetails.projectViewers = projectViewers;
  return projDetails;
}

function isDomainSelected(domain, relevantDomains) {
  return relevantDomains.findIndex(x => x.domain_id === domain.id) >= 0;
}

function getRelevantDomains(domains, releventDomains, projectRightPanel) {
  return domains.map(domain => ({
    id: domain.id,
    title: domain.title,
    iconCode: domain.icon_code,
    description: domain.description,
    isCustomDomain: domain.is_custom_domain,
    isSelected: isDomainSelected(domain, releventDomains, projectRightPanel),
  }));
}

function checkUncheckedDomains(domains, selectedDomain) {
  return domains.map((item) => {
    if (item.id === selectedDomain.id) {
      item.isSelected = !item.isSelected;
    }
    return item;
  });
}

function isSegmentPresent(a, b) {
  const result = b.filter(item => !a.includes(item));
  return result.length === 0;
}

function getQuestionObjectArray(qa, cqo) {
  const {
    attributeId, attributeName, type, question,
    domainId, domainName, sendQuestionNow, isCustomDomain,
    associatedStakeholdersCount, filterGroupValues,
    createSegment, selectedSegmentValue,
    userFilterGroupValues, segmentFiltersGroup,
    segments, dSegments,
  } = cqo;
  let result = [];
  let totalQuestions = 0;
  let isQuestionInserted = false;
  const objUniqId = uuidv1();
  const customQuestionModel = {
    attributeId,
    attributeName,
    type,
    title: question,
    domainId,
    domainName,
    sendQuestionImmediately: isCustomDomain ? sendQuestionNow : true,
    isCustomDomain,
    index: 1,
    segmentFiltersGroup,
    segments,
    dSegments,
  };
  if (qa.length > 0) {
    result = qa.map((so) => {
      if (createSegment === 'yes') {
        const {
          selectedGroupValue1, selectedGroupValue2,
          selectedGroupValue3, selectedGroupValue4,
        } = userFilterGroupValues;
        if (so.userFilterGroupValues && so.createSegment === createSegment) {
          const s1 = difference(so.userFilterGroupValues.selectedGroupValue1, selectedGroupValue1);
          const s2 = difference(so.userFilterGroupValues.selectedGroupValue2, selectedGroupValue2);
          const s3 = difference(so.userFilterGroupValues.selectedGroupValue3, selectedGroupValue3);
          const s4 = difference(so.userFilterGroupValues.selectedGroupValue4, selectedGroupValue4);
          const s1LengthDiff = so.userFilterGroupValues
            .selectedGroupValue1.length === selectedGroupValue1.length;
          const s2LengthDiff = so.userFilterGroupValues
            .selectedGroupValue2.length === selectedGroupValue2.length;
          const s3LengthDiff = so.userFilterGroupValues
            .selectedGroupValue3.length === selectedGroupValue3.length;
          const s4LengthDiff = so.userFilterGroupValues
            .selectedGroupValue4.length === selectedGroupValue4.length;
          if (s1.length === 0 && s1LengthDiff
            && s2.length === 0 && s2LengthDiff
            && s3.length === 0 && s3LengthDiff
            && s4.length === 0 && s4LengthDiff) {
            customQuestionModel.index = so.questions.length + 1;
            so.questions.push(customQuestionModel);
            isQuestionInserted = true;
          }
        }
      } else if (so.createSegment === createSegment
        && isSegmentPresent(so.segments, segments)
        && so.segments.length === segments.length) {
        customQuestionModel.index = so.questions.length + 1;
        so.questions.push(customQuestionModel);
        isQuestionInserted = true;
      }
      return so;
    });
  } else {
    result.push({
      id: objUniqId,
      createSegment,
      selectedSegmentValue,
      filterGroupValues,
      userFilterGroupValues: { ...userFilterGroupValues },
      associatedStakeholdersCount,
      segmentFiltersGroup,
      segments,
      dSegments,
      questions: [customQuestionModel],
    });
  }

  if (qa.length > 0 && !isQuestionInserted) {
    result.push({
      id: objUniqId,
      createSegment,
      selectedSegmentValue,
      filterGroupValues,
      userFilterGroupValues: { ...userFilterGroupValues },
      associatedStakeholdersCount,
      segmentFiltersGroup,
      segments,
      dSegments,
      questions: [customQuestionModel],
    });
  }

  result.map((r) => {
    totalQuestions += r.questions.length;
    return r;
  });
  return { result, totalQuestions };
}

function getPendingQuestionObjectArray(qa) {
  const result = qa;
  let totalQuestions = 0;

  result.map((r) => {
    totalQuestions += r.questions.length;
    return r;
  });
  return { result, totalQuestions };
}

function isFilterGroupPresent(sFilterGroup, cFilterGroup) {
  let result = false;
  if (cFilterGroup && Object.keys(sFilterGroup).length > 0) {
    const {
      selectedGroupValue1, selectedGroupValue2,
      selectedGroupValue3, selectedGroupValue4,
    } = cFilterGroup;
    const s1 = difference(sFilterGroup.selectedGroupValue1, selectedGroupValue1);
    const s2 = difference(sFilterGroup.selectedGroupValue2, selectedGroupValue2);
    const s3 = difference(sFilterGroup.selectedGroupValue3, selectedGroupValue3);
    const s4 = difference(sFilterGroup.selectedGroupValue4, selectedGroupValue4);
    const s1LengthDiff = sFilterGroup
      .selectedGroupValue1.length === selectedGroupValue1.length;
    const s2LengthDiff = sFilterGroup
      .selectedGroupValue2.length === selectedGroupValue2.length;
    const s3LengthDiff = sFilterGroup
      .selectedGroupValue3.length === selectedGroupValue3.length;
    const s4LengthDiff = sFilterGroup
      .selectedGroupValue4.length === selectedGroupValue4.length;
    result = s1.length === 0 && s1LengthDiff
      && s2.length === 0 && s2LengthDiff
      && s3.length === 0 && s3LengthDiff
      && s4.length === 0 && s4LengthDiff;
  }

  return result;
}

function getUpdatedQuestionObjectArray(questionsArray, customQuestionObject) {
  const {
    attributeId, attributeName, type, question,
    domainId, domainName, isCustomDomain,
    associatedStakeholdersCount, filterGroupValues,
    createSegment, selectedSegmentValue,
    userFilterGroupValues, segmentFiltersGroup,
    segments, dSegments, id, index, isExchangeCreateToExist,
    isExchangeExistToCreate,
  } = customQuestionObject;
  let result = [];
  let totalQuestions = 0;
  let isQuestionInserted = false;
  const objUniqId = uuidv1();
  const customQuestionModel = {
    attributeId,
    attributeName,
    type,
    title: question,
    domainId,
    domainName,
    sendQuestionImmediately: true, // sendQuestionImmediately(key) should always true
    isCustomDomain,
    index: 1,
    segmentFiltersGroup,
    segments,
    dSegments,
  };

  // For checking whether a question is needed to other group when segmentFiltersGroup is edited
  let needSeperateGroup = false;
  let needSeperateId = false;
  let questionIndexToEdit = false;
  let iscustomQuestionModelToPush = false;
  if (questionsArray.length > 0) {
    result = questionsArray.map((questionGroupObject) => {
      if (questionGroupObject.id === id) {
        if (createSegment === 'yes' && !isExchangeCreateToExist && !isExchangeExistToCreate) {
          const {
            selectedGroupValue1, selectedGroupValue2,
            selectedGroupValue3, selectedGroupValue4,
          } = userFilterGroupValues;
          if (questionGroupObject.userFilterGroupValues
            && createSegment === 'yes') {
            const s1 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue1,
              selectedGroupValue1,
            );
            const s2 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue2,
              selectedGroupValue2,
            );
            const s3 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue3,
              selectedGroupValue3,
            );
            const s4 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue4,
              selectedGroupValue4,
            );
            const s1LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue1.length === selectedGroupValue1.length;
            const s2LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue2.length === selectedGroupValue2.length;
            const s3LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue3.length === selectedGroupValue3.length;
            const s4LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue4.length === selectedGroupValue4.length;
            if (s1.length === 0 && s1LengthDiff
              && s2.length === 0 && s2LengthDiff
              && s3.length === 0 && s3LengthDiff
              && s4.length === 0 && s4LengthDiff) {
              questionGroupObject.questions.map((q, i) => {
                if (q.index === index) {
                  questionIndexToEdit = i;
                  q.type = type;
                  q.title = question;
                  q.segmentFiltersGroup = segmentFiltersGroup;
                  q.segments = segments;
                  q.dSegments = dSegments;
                }
              });
              isQuestionInserted = true;
            }
          }
        } else if (questionGroupObject.createSegment === createSegment
          && isSegmentPresent(questionGroupObject.segments, segments)
          && questionGroupObject.segments.length >= segments.length) {
          questionGroupObject.questions = questionGroupObject.questions.map((q, i) => {
            if (q.index === index) {
              questionIndexToEdit = i;
              q.type = type;
              q.title = question;
              q.segmentFiltersGroup = segmentFiltersGroup;
              q.segments = segments;
              q.dSegments = dSegments;
            }
            return q;
          });
          isQuestionInserted = true;
        }

        if (createSegment === 'no') {
          const isQuestionHavingSameFilters = isEqual(
            questionGroupObject.segmentFiltersGroup, segmentFiltersGroup,
          );
          if (isQuestionHavingSameFilters) {
            questionGroupObject.segmentFiltersGroup = segmentFiltersGroup;
            questionGroupObject.segments = segments;
            questionGroupObject.associatedStakeholdersCount = associatedStakeholdersCount;
            isQuestionInserted = true;
          } else {
            needSeperateGroup = true;
            // To find to which group it needs to be moved when segmentFiltersGroup is edited
            needSeperateId = questionsArray.findIndex(
              group => isEqual(group.segmentFiltersGroup, segmentFiltersGroup),
            );
            isQuestionInserted = true;
          }
          let questionMovedToOtherGroup = false;
          if (needSeperateGroup && needSeperateId && needSeperateId !== -1) {
            if (questionIndexToEdit) {
              result[needSeperateId].questions.push(
                questionGroupObject.question[questionIndexToEdit],
              );
              questionMovedToOtherGroup = true;
            } else if (needSeperateGroup) {
              iscustomQuestionModelToPush = true;
              questionMovedToOtherGroup = true;
            }
          } else if (needSeperateGroup) {
            iscustomQuestionModelToPush = true;
            questionMovedToOtherGroup = true;
          }
          if (questionMovedToOtherGroup) {
            // Removing the question if moved to other group
            questionGroupObject.questions = questionGroupObject.questions.map((q, i) => {
              if (q.index === index) {
                questionIndexToEdit = i;
                q.type = type;
                q.title = question;
                q.segmentFiltersGroup = segmentFiltersGroup;
                q.segments = segments;
                q.dSegments = dSegments;
              }
              return q;
            });
            questionGroupObject.questions.splice(questionIndexToEdit, 1);
          }
          // eslint-disable-next-line max-len
          questionGroupObject.segmentFiltersGroup = questionGroupObject.segmentFiltersGroup?.sort();
          questionGroupObject.segments = questionGroupObject.segments;
        }
        if (createSegment === 'yes' && !isExchangeCreateToExist && isExchangeExistToCreate) {
          if (questionGroupObject.id !== id) {
            questionGroupObject.createSegment = 'no';
            questionGroupObject.associatedStakeholdersCount = associatedStakeholdersCount;
          }
        }
      }
      return questionGroupObject;
    });
    if (iscustomQuestionModelToPush) {
      result.push(
        {
          id: objUniqId,
          createSegment,
          selectedSegmentValue,
          filterGroupValues,
          userFilterGroupValues: { ...userFilterGroupValues },
          associatedStakeholdersCount,
          segmentFiltersGroup,
          segments,
          dSegments,
          questions: [customQuestionModel],
        },
      );
    }
  }
  // TO filter out the duplicate groups created
  const duplicateGroups = result.filter(
    group => isEqual(group.segmentFiltersGroup?.sort(), segmentFiltersGroup?.sort()),
  );
  if (duplicateGroups.length > 1) {
    const firstIndex = result.findIndex(group => group.id === duplicateGroups[0].id);
    const secondIndex = result.findIndex(group => group.id === duplicateGroups[1].id);
    result[firstIndex].questions = [
      ...result[firstIndex].questions,
      ...result[secondIndex].questions,
    ];
    result[firstIndex].questions = result[firstIndex].questions.map(
      (questin, idx) => ({ ...questin, index: idx }),
    );
    result[firstIndex].totalQuestions = result[firstIndex].questions.length;
    result.splice(secondIndex, 1);
  }
  if (questionsArray.length > 0 && !isQuestionInserted) {
    result = questionsArray.map((questionGroupObject) => {
      if (questionGroupObject.id === id) {
        if (createSegment === 'yes' && isExchangeCreateToExist) {
          const {
            selectedGroupValue1, selectedGroupValue2,
            selectedGroupValue3, selectedGroupValue4,
          } = userFilterGroupValues;
          if (questionGroupObject.userFilterGroupValues
            && questionGroupObject.createSegment === createSegment) {
            const s1 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue1,
              selectedGroupValue1,
            );
            const s2 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue2,
              selectedGroupValue2,
            );
            const s3 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue3,
              selectedGroupValue3,
            );
            const s4 = difference(
              questionGroupObject.userFilterGroupValues.selectedGroupValue4,
              selectedGroupValue4,
            );
            const s1LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue1.length === selectedGroupValue1.length;
            const s2LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue2.length === selectedGroupValue2.length;
            const s3LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue3.length === selectedGroupValue3.length;
            const s4LengthDiff = questionGroupObject.userFilterGroupValues
              .selectedGroupValue4.length === selectedGroupValue4.length;
            if (s1.length === 0 && s1LengthDiff
              && s2.length === 0 && s2LengthDiff
              && s3.length === 0 && s3LengthDiff
              && s4.length === 0 && s4LengthDiff) {
              customQuestionModel.index = questionGroupObject.questions.length + 1;
              questionGroupObject.questions.push(customQuestionModel);
            }
          }
        } else if (questionGroupObject.createSegment === createSegment
          && !isSegmentPresent(questionGroupObject.segments, segments)) {
          questionGroupObject.questions.map((q, qindex) => {
            if (q.index === index) {
              questionGroupObject.questions.splice(qindex, 1);
            }
          });
        } else if (!isExchangeExistToCreate && isExchangeCreateToExist) {
          questionGroupObject.questions.map((q, qindex) => {
            if (q.index === index) {
              questionGroupObject.questions.splice(qindex, 1);
            }
          });
        } else if (isExchangeExistToCreate && !isExchangeCreateToExist) {
          questionGroupObject.questions.map((q, qindex) => {
            if (q.index === index) {
              questionGroupObject.questions.splice(qindex, 1);
            }
          });
        }
      }
      if (!isExchangeExistToCreate && isExchangeCreateToExist
        && questionGroupObject.createSegment === createSegment
        && isSegmentPresent(questionGroupObject.segments, segments)) {
        customQuestionModel.index = index + 1;
        questionGroupObject.questions.push(customQuestionModel);
        isQuestionInserted = true;
      }
      if (!isExchangeCreateToExist && isExchangeExistToCreate
        && isFilterGroupPresent(questionGroupObject.userFilterGroupValues, userFilterGroupValues)) {
        customQuestionModel.index = index + 1;
        questionGroupObject.questions.push(customQuestionModel);
        isQuestionInserted = true;
      }
      if (!isExchangeCreateToExist && !isExchangeExistToCreate
        && questionGroupObject.createSegment === createSegment
        && isSegmentPresent(questionGroupObject.segments, segments)) {
        customQuestionModel.index = index + 1;
        questionGroupObject.questions.push(customQuestionModel);
        isQuestionInserted = true;
      }
      return questionGroupObject;
    });
    if (!isQuestionInserted) {
      result.push({
        id: objUniqId,
        createSegment,
        selectedSegmentValue,
        filterGroupValues,
        userFilterGroupValues: { ...userFilterGroupValues },
        associatedStakeholdersCount,
        segmentFiltersGroup,
        segments,
        dSegments,
        questions: [customQuestionModel],
      });
    }
  }
  result.map((r) => {
    totalQuestions += r.questions.length;
    return r;
  });
  return { result, totalQuestions };
}

function getAutomatedQuestionsObjectArray(qa, qs, cqo) {
  const {
    associatedStakeholdersCount, filterGroupValues,
    createSegment, selectedSegmentValue,
    userFilterGroupValues, segmentFiltersGroup,
    segments, dSegments,
  } = cqo;
  let result = [];
  let totalQuestions = 0;
  let isQuestionInserted = false;
  const objUniqId = uuidv1();
  if (qa.length > 0) {
    result = qa.map((so) => {
      if (createSegment === 'yes' && so.createSegment === createSegment) {
        const {
          selectedGroupValue1, selectedGroupValue2,
          selectedGroupValue3, selectedGroupValue4,
        } = userFilterGroupValues;
        const s1 = difference(so.userFilterGroupValues.selectedGroupValue1, selectedGroupValue1);
        const s2 = difference(so.userFilterGroupValues.selectedGroupValue2, selectedGroupValue2);
        const s3 = difference(so.userFilterGroupValues.selectedGroupValue3, selectedGroupValue3);
        const s4 = difference(so.userFilterGroupValues.selectedGroupValue4, selectedGroupValue4);
        if (s1.length === 0 && s2.length === 0 && s3.length === 0 && s4.length === 0) {
          so.questions = [...so.questions || [], ...qs];
          isQuestionInserted = true;
        }
      } else if (so.createSegment === createSegment
        && isSegmentPresent(so.segments, segments)
        && so.segments.length === segments.length) {
        so.questions = [...so.questions || [], ...qs];
        isQuestionInserted = true;
      }
      return so;
    });
  } else {
    result.push({
      id: objUniqId,
      createSegment,
      selectedSegmentValue,
      filterGroupValues,
      userFilterGroupValues: { ...userFilterGroupValues },
      associatedStakeholdersCount,
      questions: qs,
      segmentFiltersGroup,
      segments,
      dSegments,
    });
  }

  if (qa.length > 0 && !isQuestionInserted) {
    result.push({
      id: objUniqId,
      createSegment,
      selectedSegmentValue,
      filterGroupValues,
      userFilterGroupValues: { ...userFilterGroupValues },
      associatedStakeholdersCount,
      questions: qs,
      segmentFiltersGroup,
      segments,
      dSegments,
    });
  }

  result.map((r) => {
    totalQuestions += r.questions.length;
    return r;
  });
  return { result, totalQuestions };
}


// Creating project reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.UPDATE_PROJECT_STAKEHOLDERS_LIST: {
      return {
        ...state,
        projectStakeholders: action.stakeholders,
      };
    }
    case actionType.CLEAR_USER_SELECTED_FILTERGROUPVALUES:
      return {
        ...state,
        userSelectedfilterGroupValues: null,
      };
    case actionType.UPDATE_USER_SELECTED_FILTERGROUPVALUES:
      return {
        ...state,
        userSelectedfilterGroupValues: action.data,
      };
    case actionType.USER_FILTERS_FOR_PROJECT_AND_PORTFOLIO:
      return {
        ...state,
        userFilterGroups: action.data,
      };
    case actionType.SAVE_SUCCESS:
      return {
        ...state,
        projectData: {
          ...state.projectData,
          isSaved: true,
          newProjectId: action.data,
        },
      };
    case actionType.UPDATE_PROJECT_DETAILS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          lifecycleStage: parseInt(action.data.lifecycleStage, 10),
          isSaved: true,
        },
      };
    }
    case actionType.UPDATE_PROJECT_SAVE_STATE: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          isSaved: false,
        },
      };
    }
    case actionType.GET_PROJECT_DETAILS: {
      const {
        portfolios,
        domains,
        relevantDomains,
        stages,
        projectDetails,
        projectAdmins,
        projectViewers,
        projectOpenTextQuestions,
        isTopicReadyForArchive,
      } = action.data;

      const projectInfo = getProjectDetails(
        projectDetails,
        projectAdmins,
        projectViewers,
        projectOpenTextQuestions,
        action.isInEditMode,
        initialState,
        action.data,
      );

      const getSelectedDomains = getRelevantDomains(
        domains,
        relevantDomains,
        action.projectRightPanel,
      );

      return {
        ...state,
        portfolios: sortArrayValues(portfolios, SORT_PORTFOLIOS_ARRAY),
        domains,
        relevantDomains: getSelectedDomains,
        stages,
        projectDetails: projectInfo,
        isTopicReadyForArchive,
      };
    }
    case actionType.GET_PROJECT_DOMAINS: {
      const {
        domains,
        relevantDomains,
      } = action.data;
      const getSelectedDomains = getRelevantDomains(
        domains,
        relevantDomains,
        action.projectRightPanel,
      );

      return {
        ...state,
        domains,
        relevantDomains: getSelectedDomains,
      };
    }
    case actionType.SHOW_PROJECT_LOADING_SPINNER:
      return {
        ...state,
        isSpinnerLoading: true,
      };
    case actionType.HIDE_PROJECT_LOADING_SPINNER:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case actionType.CHECK_UNCHECK_DOMAIN: {
      const selectedDomains = checkUncheckedDomains(state.relevantDomains, action.domain);

      return {
        ...state,
        relevantDomains: selectedDomains,
      };
    }
    case actionType.UPDATE_FORM_STATE: {
      return {
        ...state,
        projectDetails: action.values,
      };
    }
    case actionType.ADD_PORTFOLIO_ADMINS_AS_PROJECT_ADMINS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectAdmins: action.data,
        },
      };
    }
    case actionType.RESENT_QUESTIONS_EMAIL_SUCCESS: {
      return {
        ...state,
        isQuestionEmailResent: true,
        resendStakeHolderFirstName: action.data.projectData.stakeHolderFirstName,
        resendStakeHolderLastName: action.data.projectData.stakeHolderLastName,
        resendStakeHolderEmail: action.data.projectData.stakeHolderEmail,
      };
    }
    case actionType.RESENT_QUESTIONS_EMAIL_TIMEOUT: {
      return {
        ...state,
        isQuestionEmailResent: false,
        resendStakeHolderFirstName: null,
        resendStakeHolderLastName: null,
        resendStakeHolderEmail: null,
      };
    }
    case actionType.AUTOMATED_QUESTION_DATA: {
      const {
        automatedQuestions, customQuestions,
      } = state.customQuestionBasket;
      const questionArray = automatedQuestions ?? [];
      const {
        result, totalQuestions,
      } = getAutomatedQuestionsObjectArray(questionArray,
        action.questionsData.questionsData, action.data);

      let totalCustomQuestions = 0;
      customQuestions.map((r) => {
        totalCustomQuestions += r.questions.length;
        return r;
      });
      return {
        ...state,
        customQuestionBasket: {
          ...state.customQuestionBasket,
          automatedQuestions: result,
          questionCount: totalCustomQuestions + totalQuestions,
          filterGroupValues: action.data.filterGroupValues,
          associatedStakeholderCountWithFilter: action.data.associatedStakeholderCountWithFilter,
          isSentToAllStakeHolders: action.data.isSentToAllStakeHolders,

        },
      };
    }
    case actionType.DISABLE_DOMAIN_ATTRIBUTE: {
      return {
        ...state,
        customQuestionBasket: {
          ...state.customQuestionBasket,
          ...state.automatedQuestions,
          ...state.questionCount,
          ...state.filterGroupValues,
          ...state.associatedStakeholderCountWithFilter,
          ...state.isSentToAllStakeHolders,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute
            .concat(action.domainattribute),
        },
      };
    }
    case actionType.SWAP_AUTOMATED_QUESTION_DATA: {
      const newAutomatedQuestions = state.customQuestionBasket.automatedQuestions;

      newAutomatedQuestions.forEach((item) => {
        if (item.id === action.value.filterGroupId) {
          item.questions.map((question) => {
            if (question.id === action.value.questionId) {
              question.id = action.questionData.questionsData.id;
              question.title = action.questionData.questionsData.title;
            }
          });
        }
      });

      return {
        ...state,
        customQuestionBasket: {
          ...state.customQuestionBasket,
          ...state.filterGroupValues,
          ...state.associatedStakeholderCountWithFilter,
          ...state.isSentToAllStakeHolders,
          automatedQuestions: newAutomatedQuestions,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute,
        },
      };
    }
    case actionType.UPDATE_PROJECT_ADMINS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectAdmins: action.values,
        },
      };
    }
    case actionType.UPDATE_PROJECT_VIEWERS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectViewers: action.values,
        },
      };
    }
    case actionType.UPDATE_NEW_SELECTED_PORTFOLIO_ID: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          selectedPortfolio: action.data,
        },
      };
    }
    case actionType.GET_OPEN_TEXT_QUESTIONS_LIST:
      return {
        ...state,
        openTextQuestions: {
          questionsList: action.questions.questionsList,
          questionsCount: action.questions.count,
          selectQuestionCount: action.questions.selectQuestionCount,
          selectAll: false,
        },
      };
    case actionType.OPEN_TEXT_QUESTIONS_FOR_FILTER:
      return {
        ...state,
        questionsForFilter: {
          questionsList: action.questions.questionsList,
          questionsCount: action.questions.count,
          selectQuestionCount: action.questions.questionsList
            .filter(item => item.isChecked === true).length,
          selectAll: false,
        },
      };
    case actionType.TOGGLE_ALL_OPEN_TYPE_QUESTIONS_CHECKBOXES: {
      const questionsList = state.openTextQuestions.questionsList.map((item) => {
        const obj = item;
        obj.isChecked = !state.openTextQuestions.selectAll;
        return obj;
      });
      const selectedCount = questionsList.filter(q => q.isChecked === true).length;
      return {
        ...state,
        openTextQuestions: {
          selectAll: !state.openTextQuestions.selectAll,
          questionsList,
          questionsCount: questionsList.length,
          selectQuestionCount: selectedCount,
        },

      };
    }
    case actionType.TOGGLE_OPEN_TYPE_CHECKBOX: {
      const questionsList = state.openTextQuestions.questionsList.map((item) => {
        const obj = item;
        if (obj.id === action.question.id) {
          obj.isChecked = !obj.isChecked;
        }
        return obj;
      });
      const selectAll = !(questionsList.filter(item => item.isChecked === false).length > 0);
      const selectedCount = questionsList.filter(q => q.isChecked === true).length;
      return {
        ...state,
        openTextQuestions: {
          selectAll,
          questionsList,
          questionsCount: questionsList.length,
          selectQuestionCount: selectedCount,
        },
      };
    }
    case actionType.UPDATE_PROJECT_OPEN_TEXT_QUESTIONS: {
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectOpenTextQuestions: action.values,
        },
      };
    }
    case actionType.TOGGLE_ALL_OPEN_TYPE_QUESTIONS_EDIT_MODE: {
      let questionsList = state.openTextQuestions.questionsList.map((item) => {
        const obj = item;
        action.openTextQuestions.find((question) => {
          if (question.id === obj.id) {
            obj.isChecked = true;
          }
        });
        return obj;
      });
      questionsList = (questionsList.filter(q => q.isChecked === true).sort(q => q.created))
        .concat(questionsList.filter(q => q.isChecked === false).sort(q => q.created));
      const selectAll = !(questionsList.filter(item => item.isChecked === false).length > 0);
      const selectedCount = questionsList.filter(q => q.isChecked === true).length;
      return {
        ...state,
        openTextQuestions: {
          selectAll,
          questionsList,
          questionsCount: questionsList.length,
          selectQuestionCount: selectedCount,
        },
      };
    }
    case actionType.SEARCH_OPEN_TEXT_QUESTION: {
      const { questionsList } = state.questionsForFilter;
      const filteredQuestions = getFilteredQuestions(questionsList, action.searchQuery);
      return {
        ...state,
        openTextQuestions: {
          questionsList: action.searchQuery ? filteredQuestions : questionsList,
          searchQuery: action.searchQuery,
          questionsCount: questionsList.length,
          selectQuestionCount: questionsList.filter(q => q.isChecked === true).length,
        },
      };
    }
    case actionType.DELETE_OPEN_TEXT_QUESTION: {
      const { projectOpenTextQuestions } = state.projectDetails;
      const questionArray = [];
      projectOpenTextQuestions.filter((item) => {
        if (item.id !== action.id) {
          questionArray.push(item);
        }
      });
      return {
        ...state,
        projectDetails: {
          ...state.projectDetails,
          projectOpenTextQuestions: questionArray,
        },
      };
    }
    case actionType.ADD_CUSTOM_QUESTION: {
      const {
        customQuestions, automatedQuestions,
      } = state.customQuestionBasket;
      const questionArray = customQuestions || [];
      const {
        result, totalQuestions,
      } = getQuestionObjectArray(questionArray, action.customQuestionModel);
      let totalAutomatedQuestions = 0;
      automatedQuestions.map((r) => {
        totalAutomatedQuestions += r.questions.length;
        return r;
      });
      return {
        ...state,
        customQuestionBasket: {
          ...state.customQuestionBasket,
          projectId: action.customQuestionModel.projectId,
          customQuestions: result,
          questionCount: totalQuestions + totalAutomatedQuestions,
          automatedQuestions: state.customQuestionBasket.automatedQuestions,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute,
          associatedStakeholderCountWithFilter:
            action.customQuestionModel.associatedStakeholderCountWithFilter,
          filterGroupValues: action.customQuestionModel.filterGroupValues,
          isSentToAllStakeHolders: action.customQuestionModel.isSentToAllStakeHolders,
        },
      };
    }
    case actionType.UPDATE_CUSTOM_QUESTION: {
      const {
        customQuestions, automatedQuestions,
      } = state.customQuestionBasket;
      const questionArray = customQuestions ?? [];
      const { result, totalQuestions } = getUpdatedQuestionObjectArray(questionArray,
        action.customQuestionModel);
      const filterNewQuestionArray = result.filter(item => item.questions.length > 0);
      let totalAutomatedQuestions = 0;
      automatedQuestions.map((r) => {
        totalAutomatedQuestions += r.questions.length;
        return r;
      });
      return {
        ...state,
        customQuestionBasket: {
          projectId: action.customQuestionModel.projectId,
          customQuestions: filterNewQuestionArray,
          automatedQuestions,
          questionCount: totalQuestions + totalAutomatedQuestions,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute,
          filterGroupValues: action.customQuestionModel.filterGroupValues,
          associatedStakeholderCountWithFilter:
            action.customQuestionModel.associatedStakeholderCountWithFilter,
        },
      };
    }
    case actionType.UPDATE_PENDING_CUSTOM_QUESTION: {
      const {
        customQuestions, automatedQuestions,
      } = action.data.customQuestionBasket;
      const questionArray = customQuestions || [];
      const {
        result, totalQuestions,
      } = getPendingQuestionObjectArray(questionArray);
      let totalAutomatedQuestions = 0;
      automatedQuestions.map((r) => {
        totalAutomatedQuestions += r.questions.length;
        return r;
      });
      return {
        ...state,
        customQuestionBasket: {
          ...state.customQuestionBasket,
          projectId: customQuestions.projectId,
          customQuestions: result,
          questionCount: totalQuestions + totalAutomatedQuestions,
          automatedQuestions: action.data.customQuestionBasket.automatedQuestions,
          disableDomainAttribute: action.data.customQuestionBasket.disableDomainAttribute,

        },
      };
    }
    case actionType.DELETE_CUSTOM_QUESTION: {
      const {
        customQuestions,
      } = state.customQuestionBasket;
      let allQuestionsDeletedFromFilterGroup = false;
      let customQuestionsCount = 0;
      let questionArray = customQuestions.map((i) => {
        if (i.id === action.data.filterGroupId) {
          i.questions = i.questions
            .filter(x => x.index !== action.data.index);
        }
        if (i.questions.length === 0) {
          allQuestionsDeletedFromFilterGroup = true;
        }
        customQuestionsCount += i.questions.length;
        return i;
      });
      if (allQuestionsDeletedFromFilterGroup) {
        questionArray = customQuestions.filter(x => x.id !== action.data.filterGroupId);
      }
      let automatedQuestionsCount = 0;
      state.customQuestionBasket.automatedQuestions.map((r) => {
        automatedQuestionsCount += r.questions.length;
        return r;
      });
      const totalQuestions = customQuestionsCount + automatedQuestionsCount;
      return {
        ...state,
        customQuestionBasket: {
          customQuestions: questionArray,
          automatedQuestions: state.customQuestionBasket.automatedQuestions,
          questionCount: totalQuestions,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute,
        },
        previewRightPannel: {
          disableSendBtn: state.previewRightPannel.disableSendBtn,
          ...state.userFilterGroupValues,
          showPanel: totalQuestions,
        },
      };
    }
    case actionType.EMPTY_CUSTOM_QUESTION_BASKET: {
      const { questionCount } = state.customQuestionBasket;
      return {
        ...state,
        customQuestionBasket: {
          projectId: 0,
          isSent: false,
          customQuestions: [],
          automatedQuestions: [],
          questionCount,
          disableDomainAttribute: [],
        },
      };
    }
    case actionType.SHOW_CUSTOM_QUESTION_RIGHTPANEL:
      return {
        ...state,
        previewRightPannel: {
          showPanel: true,
          userFilterGroupValues: action.data.data,
          disableSendBtn: action.data.disableSendBtn,
        },
      };
    case actionType.HIDE_CUSTOM_QUESTION_RIGHTPANEL:
      return {
        ...state,
        previewRightPannel: {
          ...state.userFilterGroupValues,
          showPanel: false,
          disableSendBtn: false,
        },
      };
    case actionType.DELETE_AUTOMATED_QUESTION_DATA: {
      const {
        automatedQuestions,
      } = state.customQuestionBasket;
      let allQuestionsDeletedFromFilterGroup = false;
      let totalAutomatedQuestions = 0;
      let questionArray = automatedQuestions.map((i) => {
        if (i.id === action.data.filterGroupId) {
          i.questions = i.questions
            .filter(x => x.id !== action.data.questionId);
        }
        if (i.questions.length === 0) {
          allQuestionsDeletedFromFilterGroup = true;
        }
        totalAutomatedQuestions += i.questions.length;
        return i;
      });
      if (allQuestionsDeletedFromFilterGroup) {
        questionArray = automatedQuestions.filter(x => x.id !== action.data.filterGroupId);
      }
      let customQuestionsCount = 0;
      state.customQuestionBasket.customQuestions.map((r) => {
        customQuestionsCount += r.questions.length;
        return r;
      });
      const totalQuestions = customQuestionsCount + totalAutomatedQuestions;
      return {
        ...state,
        customQuestionBasket: {
          isSent: false,
          customQuestions: state.customQuestionBasket.customQuestions,
          automatedQuestions: questionArray,
          questionCount: totalQuestions,
          disableDomainAttribute: state.customQuestionBasket.disableDomainAttribute,
        },
        previewRightPannel: {
          ...state.userFilterGroupValues,
          showPanel: totalQuestions,
          disableSendBtn: state.previewRightPannel.disableSendBtn,
        },
      };
    }
    case actionType.OPEN_DOMAIN_FOCUS_PANEL:
      return {
        ...state,
        isOpenDomainFocusPanel: action.data,
      };
    case actionType.OPEN_TEXT_PANEL:
      return {
        ...state,
        showOpenTextPanelBool: action.data,
      };
    case actionType.ASSOCIATED_PROJECT_STAKEHOLDERS:
      return {
        ...state,
        projectStakeholdersCount: action.data,
      };
    case actionType.PROJECT_STATE:
      return {
        ...state,
        isProjectActive: action.data,
      };
    case actionType.ENABLE_UNSAVED_CHANGES_FOCUS_DOMAIN:
      return {
        ...state,
        unsavedChangesFocusDomain: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES_FOCUS_DOMAIN:
      return {
        ...state,
        unsavedChangesFocusDomain: false,
      };
    case actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN:
      return {
        ...state,
        unsavedChangesModalFocusDomain: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN:
      return {
        ...state,
        unsavedChangesModalFocusDomain: false,
      };
    case actionType.ADD_CUSTOM_DOMAINS_TO_TOPIC: {
      const newCustomDomain = {
        domainId: 0,
        isInEditMode: true,
        attributes: [
          { id: 0, title: '' },
          { id: 0, title: '' },
          { id: 0, title: '' },
          { id: 0, title: '' },
          { id: 0, title: '' },
        ],
      };
      const updatedDomainWidgets = [newCustomDomain, ...state.domainWidgets];
      return {
        ...state,
        domainWidgets: updatedDomainWidgets,
      };
    }
    case actionType.UPDATE_DOMAINS_TO_TOPIC: {
      return {
        ...state,
        domainWidgets: action.data,
      };
    }
    case actionType.ENABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN:
      return {
        ...state,
        unsavedChangesCustomDomain: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN:
      return {
        ...state,
        unsavedChangesCustomDomain: false,
      };
    case actionType.ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN:
      return {
        ...state,
        unsavedChangesModalCustomDomain: true,
      };
    case actionType.DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN:
      return {
        ...state,
        unsavedChangesModalCustomDomain: false,
      };
    case actionType.DELETE_RELEVANT_DOMAINS: {
      return {
        ...state,
        relevantDomains: [],
      };
    }
    case actionType.UPDATE_LATER_QUESTION_SAVE_MESSAGE: {
      const basket = state.customQuestionBasket;
      basket.laterQuestionSaveMessage = action.message;
      return {
        ...state,
        customQuestionBasket: basket,
      };
    }
    case actionType.GET_FILTER_GROUP_DATA: {
      return {
        ...state,
        filterGroupModel: {
          projectName: action.data.projectName,
          filterGroupName1: action.data.groupFilterData.filterGroupName1,
          filterGroupName2: action.data.groupFilterData.filterGroupName2,
          filterGroupName3: action.data.groupFilterData.filterGroupName3,
          filterGroupName4: action.data.groupFilterData.filterGroupName4,
          stakeholders: action.data.groupFilterData.stakeholders,
        },
        filterUsersCount: action.data.count,
      };
    }
    case actionType.FILTER_GROUP_SUCCESS_MESSAGE: {
      return {
        ...state,
        isFilterGroupSaveSuccessfully: action.data,
      };
    }
    case actionType.ADD_FILTER_GROUP_VALUE: {
      return {
        ...state,
        isFilterGroupSaveSuccessfully: action.data,
        filterGroupValues: {
          values: action.data.filterValue,
          userId: action.data.stakeholdersId,
        },
      };
    }
    case actionType.ADD_PROJECT_SEGMENTS: {
      const {
        segments,
        projectId,
      } = action.data;
      if (projectId === state.currentlySelectedProjectId) {
        return {
          ...state,
          projectSegments: segments,
        };
      }
      return state;
    }
    case actionType.TOGGLE_PROJECT_SEGMENT_DROPDOWN:
      return {
        ...state,
        isProjectSegmentsLoaded: action.data,
      };
    case actionType.UPDATE_PROJECT_STAKEHOLDERS_STATE:
      return {
        ...state,
        isProjectStakeholderAssociated: action.data,
      };
    case actionType.UPDATE_CURRENTLY_SELECTED_PROJECT_ID:
      return {
        ...state,
        currentlySelectedProjectId: action.data,
      };
    case actionType.UPDATE_PROJECT_DRAFTED_STATUS:
      return {
        ...state,
        isProjectInDraftedState: action.data,
      };
    case actionType.UPDATE_QUESTION_SENT_TO_FUTURE_DROP:
      return {
        ...state,
        questionSentToFutureDrop: action.data.status,
        questionSentToFutureDropMessage: action.data.message,
      };
    case actionType.SAVE_OVER_ALL_WIDGET:
      return {
        ...state,
        overAllWidget: action.data,
      };
    case actionType.SAVE_SEGMENT_GROUP_DASHBOARD:
      return {
        ...state,
        segmentGroupDashboardData: action.data,
      };
    case actionType.SAVE_SEGMENT_GROUP_VALUE:
      return {
        ...state,
        segmentGroupValue: action.data,
      };
    case actionType.SAVE_SEGMENT_OVERALL:
      return {
        ...state,
        segmentGroupOverall: action.data,
      };
    case actionType.SAVE_SEGMENT_ALREADY_CREATED:
      return {
        ...state,
        duplicateSegment: action.data,
      };
    case actionType.SELECTED_SEGMENT:
      return {
        ...state,
        selectedSegment: action.data,
      };
    case actionType.QUESTION_URL_COPIED: {
      return {
        ...state,
        isQuestionUrlCopied: true,
        copiedLinkStakeHolderFirstName: action.data.projectData.stakeHolderFirstName,
        copiedLinkStakeHolderLastName: action.data.projectData.stakeHolderLastName,
        copiedLinkStakeHolderEmail: action.data.projectData.stakeHolderEmail,
      };
    }
    case actionType.QUESTION_URL_COPIED_TIMEOUT: {
      return {
        ...state,
        isQuestionUrlCopied: false,
        copiedLinkStakeHolderFirstName: null,
        copiedLinkStakeHolderLastName: null,
        copiedLinkStakeHolderEmail: null,
      };
    }
    case actionType.UPLOAD_IMPORT_STACKHOLDER_ERROR_RECORDS: {
      return {
        ...state,
        errorStackholders: action.stakeholders,
      };
    }
    case actionType.UPDATE_IMPORT_STACKHOLDER_FLAG: {
      return {
        ...state,
        importStackholdersFlag: action.importStackholdersFlag,
      };
    }
    case actionType.PROJECT_ADHOC_DATE:
      return {
        ...state,
        nextAdhocDropDate: action.data.nextFullAdhocDropDate,
        adhocDropOffset: action.data.AdhocDropOffset,
        dropTimeZone: action.data.dropTimeZone,
        isProjectHavingNextAdhocDrop: action.data.isNextAdhocDropScheduled,
        isProjectAdhoc: action.data.isProjectAdhoc,
      };
    case actionType.SEND_VALUE_DATA:
      return {
        ...state,
        isProjectCheckValue: action.data.value,
      };
    case actionType.PROJECT_UPDATED_DATA:
      return {
        ...state,
        projectUpdatedStatus: {
          selectedStage: action.data.selectedStage,
          nextDropdate: action.data.nextDropDate,
          nextFullDropDate: action.data.nextFullDropDate,
        },
      };
    default:
      return state;
  }
};
