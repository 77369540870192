import React, { Component } from 'react';
import $ from 'jquery';

class ResentMailSuccessMessage extends Component {
  componentDidMount(props) {
    this.initializeSuccessAlert(props);
  }

  initializeSuccessAlert() {
    $('.success-alert').removeClass('hide');
    $('.success-alert').removeClass('a-alert-hide');
    $('.success-alert').addClass('show');
  }

  render() {
    const { alertMesage } = this.props;
    return (
      <React.Fragment >
        {alertMesage && <div className="success-alert a-alert activation-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success"
          data-hide="3000">
          <div className="a-alert-content">
            <span className="a-content">
              {alertMesage}
            </span>
          </div>
        </div>}
      </React.Fragment>
    );
  }
}

export default ResentMailSuccessMessage;
