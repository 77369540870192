import React, { Component } from 'react';
import { CSSTransitionGroup } from 'react-transition-group';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Cryptr from 'cryptr';
import { Formik, Field } from 'formik';
import MaterialIcon from 'material-icons-react';
import AddAttribute from '../components/AddAttribute.jsx';
import {
  setHeading,
  getAttributes,
  toggleAllCheckboxes,
  toggleCheckbox,
  deleteAttributes,
  canDelete,
  canBulkDelete,
  modifyAddedAttributeStatus,
  changeAddedAttributeStatus,
} from '../actions/attribute';
import {
  getQuestionModels,
  changeSelectedQuestionModel,
} from '../actions/questionModel';
import { attributeSelector } from '../selectors/attribute';
import AlertPopup from '../../../../ui-components/AlertPopup/AlertPopup.jsx';
import { getDomains, getDomainsForFilter } from '../actions/domain';
import { domainSelector } from '../selectors/domain';
import SettingSumoSelect from '../../../../ui-components/SettingSumoSelect/SettingSumoSelect';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import RenderIf from '../../../../ui-components/Common';
import { Spinner } from '../../../../ui-components/Loader';
import { NO_OF_RECORDS, INITIAL_PAGINATED_DATA, FEEDBACK_PAGE_REF } from '../../../../Constants';
import Pagination from '../../../../ui-components/Pagination/Pagination.jsx';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';


const mapDispatchToProps = {
  getDomains,
  setHeading,
  getAttributes,
  toggleAllCheckboxes,
  toggleCheckbox,
  canDelete,
  canBulkDelete,
  deleteAttributes,
  modifyAddedAttributeStatus,
  changeAddedAttributeStatus,
  getDomainsForFilter,
  getQuestionModels,
  changeSelectedQuestionModel,
};

const mapStateToProps = state => ({
  attribute: attributeSelector(state),
  domains: domainSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const messages = {
  cannotDeleteAttributeTitle: 'Cannot Delete Attribute',
  cannotDeleteAttributeMessage: `This attribute is associated to some question which has one or more associated
    responses and it cannot be deleted.`,
  cannotDeleteMultipleAttributesTitle: 'Cannot Delete Attributes',
  cannotDeleteMultipleAttributesMessage: `One or more of the selected attributes are associated to some 
    question which has one or more associated responses and it cannot be deleted.`,
  deleteAttributeTitle: 'Delete a Attribute',
  deleteAttributeMessage: `This attribute will be permanently deleted and all associated questions 
    will be unassigned from this attribute. Are you sure you want to continue?`,
  deleteMultipleAttributesTitle: 'Delete Selected Attributes',
  deleteMultipleAttributesMessage: `The selected attributes will be permanently deleted and 
    all associated questions will be unassigned from the attributes. 
    Are you sure you want to continue?`,
};

const cryptr = new Cryptr(__CONFIG__.encryptSecret);

class Attributes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      showAddAttribute: false,
      heading: 'Attributes',
      selectedAttribute: {},
      showAlertPopup: false,
      alertPopupTitle: '',
      alertPopupMessage: '',
      showDelete: false,
      selectedAttributeIdsForDelete: [],
      deleteTitle: '',
      deleteConfirmationMessage: '',
      spinner: { isLoading: true },
      listLoading: true,
      domainFilters: {
        selectedKeyword: '',
        selectedDomains: [],
      },
      currentPage: 1,
      totalPages: 1,
      showHideDescriptionTooltip: false,
      tooltipText: '',
      isFilterResetting: false,
      isEnableResetButton: true,
    };
    this.domainsList = [];
    this.domainNotFound = false;
    this.showAddAttributePanel = this.showAddAttributePanel.bind(this);
    this.hideAddAttributePanel = this.hideAddAttributePanel.bind(this);
    this.filterAttributes = this.filterAttributes.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmBulkDelete = this.confirmBulkDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteAttributes = this.deleteAttributes.bind(this);
    this.alertPopupOkClick = this.alertPopupOkClick.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  filterAttributes(filters) {
    const attributeModel = filters;
    attributeModel.selectedKeyword = attributeModel.selectedKeyword;
    attributeModel.selectedPortfolios = attributeModel.selectedDomains.map(
      item => parseInt(item, 10),
    );
    this.setState({
      domainFilters: attributeModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (attributeModel.selectedPortfolios.length > 0 || attributeModel.selectedKeyword) {
      this.setState({ isEnableResetButton: false });
    }
    this.getAttributesList(filters, INITIAL_PAGINATED_DATA, this.props.questionModelId);
  }

  getTotalPageNum() {
    return this.props.attribute.attributesCount
      !== 0 ? Math.ceil(this.props.attribute.attributesCount / NO_OF_RECORDS)
      : 1;
  }

  getAttributesList = async (filters, paginatedData, decryptQuestionModelId) => {
    this.setState({
      listLoading: true,
    });
    await this.props.getAttributes(filters, paginatedData, decryptQuestionModelId).then(() => {
      this.setState({
        totalPages: this.getTotalPageNum(),
        listLoading: false,
      });
      this.setState(prevState => ({
        spinner: {
          ...prevState.spinner,
          isLoading: false,
        },
      }));
    });
    this.props.getQuestionModels().then((response) => {
      if (response.data && response.data.length
        && this.props.questionModelId === 0) {
        this.props.changeSelectedQuestionModel(decryptQuestionModelId);
      }
    });
  }

  componentDidMount() {
    this.props.setHeading(this.state.heading);
    const questionModelId = this.props.match.params.id;
    const decryptQuestionModelId = cryptr.decrypt(questionModelId);
    if (questionModelId || this.props.questionModelId) {
      this.props.changeSelectedQuestionModel(decryptQuestionModelId, 'attributes');
      this.getAttributesList(null, INITIAL_PAGINATED_DATA, parseInt(decryptQuestionModelId, 10));
    }

    const self = this;

    getDomainsForFilter(parseInt(decryptQuestionModelId, 10)).then((data) => {
      self.domainNotFound = data.length === 0;
      data.map((item) => {
        const obj = {
          text: item.title,
          value: item.id,
        };
        self.domainsList.push(obj);
      });

      self.showLoadingSpinner(false);
    });
  }

  showAddAttributePanel() {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddAttribute: true,
        animate: true,
        selectedAttribute: {},
      });
    }, 300);
  }

  hideAddAttributePanel = async () => {
    const { isAttributeAddedOrModified, isAttributeAdded, attributesList } = this.props.attribute;
    const { domainFilters, currentPage } = this.state;
    this.setState({
      showAddAttribute: false,
      animate: true,
      selectedAttribute: {},
    });

    if (isAttributeAddedOrModified) {
      this.getAttributesList(domainFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: currentPage - 1 }, this.props.questionModelId);
      this.props.modifyAddedAttributeStatus(false);
    } else if (isAttributeAdded) {
      await this.getAttributesList(domainFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: currentPage - 1 }, this.props.questionModelId);
      const { totalPages } = this.state;
      if (attributesList && attributesList.length === 10) {
        if (currentPage < totalPages) {
          const diff = totalPages - currentPage;
          const newPage = currentPage + diff;
          this.updateCurrentPage(newPage, true);
        } else {
          const newPage = totalPages + 1;
          this.updateCurrentPage(newPage, true);
        }
      }
    }
    this.props.changeAddedAttributeStatus(false);
  }

  showLoadingSpinner(isLoading) {
    this.setState(state => ({
      ...state,
      spinner: {
        ...state.spinner,
        isLoading,
      },
    }));
  }

  editAttribute(obj) {
    const that = this;
    setTimeout(() => {
      that.setState({
        showAddAttribute: true,
        animate: true,
        selectedAttribute: obj,
      });
    }, 300);
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  // Confirm Delete
  confirmDelete(attributeId) {
    if (this.props.attribute.attributesList.length > 0) {
      this.props.canDelete(attributeId).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedAttributeIdsForDelete: [attributeId],
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteAttributeTitle,
            deleteConfirmationMessage: messages.deleteAttributeMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteAttributeTitle,
            alertPopupMessage: messages.cannotDeleteAttributeMessage,
          });
        }
      });
    }
  }

  confirmBulkDelete() {
    if (this.props.attribute.attributesList.length > 0) {
      const selectedIds = [];
      this.props.attribute.attributesList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });

      this.props.canBulkDelete(selectedIds).then((rows) => {
        if (rows.length === 0) {
          this.setState({
            selectedAttributeIdsForDelete: selectedIds,
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: messages.deleteMultipleAttributesTitle,
            deleteConfirmationMessage: messages.deleteMultipleAttributesMessage,
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: messages.cannotDeleteMultipleAttributesTitle,
            alertPopupMessage: messages.cannotDeleteMultipleAttributesMessage,
          });
        }
      });
    }
  }

  cancelDelete() {
    this.setState({
      selectedAttributeIdsForDelete: [],
      showDelete: false,
    });
  }

  // Delete Attributes
  deleteAttributes() {
    return this.props
      .deleteAttributes({ selectedIds: this.state.selectedAttributeIdsForDelete },
        this.state.domainFilters)
      .then((response) => {
        const { currentPage } = this.state;
        this.props.getAttributes(
          this.state.domainFilters,
          INITIAL_PAGINATED_DATA,
          this.props.questionModelId,
        ).then(() => {
          if (this.state.currentPage > this.getTotalPageNum()) {
            this.goToPage(this.state.currentPage - 1);
          } else { this.goToPage(this.state.currentPage); }
          const totalNoOfPages = this.getTotalPageNum();
          this.setState({
            totalPages: totalNoOfPages,
            selectedAttributeIdsForDelete: [],
            currentPage: currentPage > totalNoOfPages ? totalNoOfPages : currentPage,
          });
        });
        return response;
      });
  }

  alertPopupOkClick() {
    this.setState({
      selectedAttributeIdsForDelete: [],
      showDelete: false,
      showAlertPopup: false,
    });
  }

  // paging code
  nextPage() {
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) {
      this.updateCurrentPage((currentPage + 1), true);
    }
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage((currentPage - 1), true);
  }

  updateCurrentPage(pageNum, status) {
    this.setState({
      currentPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getAttributesList(this.state.domainFilters,
        { noOfRecords: NO_OF_RECORDS, pageNo: (pageNum - 1) }, this.props.questionModelId);
    }
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value, true);
    } else {
      this.updateCurrentPage(1, true);
    }
  }

  pageOnChangeEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateCurrentPage(value, false);
  }

  hideTooltip() {
    this.setState({
      showHideDescriptionTooltip: false,
      tooltipText: null,
      showIndexValue: null,
      showIndexFieldValue: '',
    });
  }

  showTooltip(obj, indexNumber, fieldType) {
    this.setState({
      showHideDescriptionTooltip: true,
      tooltipText: obj,
      showIndexValue: indexNumber,
      showIndexFieldValue: fieldType,
    });
  }

  truncate(s) {
    if (s) {
      const n = s.length;
      if (n > 100) {
        const trimmedString = s.substring(0, 100);
        return `${trimmedString}...`;
      }
    }
    return s;
  }

  resetFilters() {
    const attributeModel = {
      selectedDomains: [],
      selectedKeyword: '',
    };
    this.setState({
      domainFilters: attributeModel,
      isFilterResetting: true,

    });
    this.filterAttributes(attributeModel);
    setTimeout(() => {
      this.setState({
        isFilterResetting: false,
      });
    }, 1);
  }

  render() {
    const {
      spinner,
      totalPages,
      currentPage,
      listLoading,
      isFilterResetting,
      isEnableResetButton,
    } = this.state;
    const { attribute } = this.props;
    const { domainsList, domainNotFound } = this;
    const attributeModel = {
      selectedKeyword: this.state.domainFilters.selectedKeyword,
      selectedDomains: this.state.domainFilters.selectedDomains,
    };
    return (
      <div>
        <Spinner isLoading={spinner.isLoading} />
        <RenderIf showComponent={!spinner.isLoading}>
          <div className="attributes-container">
            <div className="row attribute-header-row">
              <div className="col-md-6 col-sm-12 col-12 col-lg-3">
                <p className="a-font-sm m-0 mb-3">
                  Attributes are secondary categories for questions. Once you have defined a set of
                  attributes, you can configure a set of questions.
                </p>
              </div>
            </div>

            <div className="row a-table-wrapper attribute-row">
              <div className="a-selector-wrapper col-md-12 min-height">
                <div class="row justify-content-center align-items-center pb-1 pt-1 m-0 mb-3">
                  <div class="col">
                    {!isFilterResetting && <Formik
                      enableReinitialize={true}
                      initialValues={attributeModel}
                    >
                      {({ setFieldValue, values }) => (
                        <div class="row justify-content-center align-items-center">
                          <div class="col-md-6 col-lg-6">
                            {domainsList.length > 0 && <div class="row align-items-center justify-content-start">
                              <div className="col-auto">
                                <strong className="filter-label">Filter by:</strong>
                              </div>
                              <div class="col-auto">
                                <div class="a-dropdown">
                                  <SettingSumoSelect
                                    placeholder={domainNotFound ? 'No Focus Area' : 'Focus Area'}
                                    multiple="multiple"
                                    name="selectedDomains"
                                    selectedValues={values.selectedDomains}
                                    options={domainsList}
                                    setFieldValue={setFieldValue}
                                    onSumoSelectChange={() => this.filterAttributes(values)}
                                    captionFormatAllSelected="Domains"
                                  />
                                </div>
                              </div>
                              {!isEnableResetButton && <div class="col-auto">
                                <button href="javascript:void(0)"
                                  class="text-theme-color text-center d-block pl-2 pr-2"
                                  onClick={() => this.resetFilters()}
                                >
                                  CLEAR</button>
                              </div>}
                            </div>}
                          </div>
                          <div class="col-md-6 col-lg-3 ml-auto">
                            <div class="row align-items-center">
                              <div class="col px-0">
                                <div class="input-group-with-icon">
                                  <Field
                                    name='selectedKeyword'
                                    placeholder="Search"
                                    class="w-100 custom-input-filter-icon"
                                    autoComplete="off"
                                    onChange={(e) => {
                                      setFieldValue('selectedKeyword', e.target.value);
                                      values.selectedKeyword = e.target.value;
                                      this.filterAttributes(values);
                                    }}
                                  />
                                  <MaterialIcon className="material-icons md-24 text-theme-color" icon="search" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Formik>}
                  </div>
                </div>
              </div>
              <Spinner isLoading={listLoading} />
              <RenderIf showComponent={!listLoading}>
                <div className=" a-table-scroller domain-table col-md-12 col-sm-12 col-12 col-lg-12  a-mb-10  pr-0 ">
                  <table className="a-table data-table a-lg min-w-1024 sortable selectable setting-table">
                    <thead className="a-thead">
                      <tr>
                        {attribute.attributesList.length > 0 ? (
                          <th className="a-checkbox-cell">
                            <label className="a-checkbox black-checkbox">
                              <input
                                type="checkbox"
                                onChange={this.toggleAllCheckboxes}
                                checked={
                                  attribute.attributesList.filter(item => item.isChecked).length > 0
                                }
                              />
                              <span className="a-checkbox-mark">
                                <span
                                  className={`appkiticon   
                        ${attribute.attributesList
                                      .filter(item => item.isChecked).length > 0
                                      && !attribute.selectAll
                                      ? 'icon-minus-fill'
                                      : ''
                                    }  
                        ${attribute.attributesList
                                      .filter(item => item.isChecked).length > 0
                                      && attribute.selectAll
                                      ? 'icon-check-mark-fill'
                                      : ''
                                    }`}
                                />
                              </span>
                            </label>
                          </th>
                        ) : (
                          ''
                        )}
                        <th className="a-title-cell">Attribute Title</th>
                        <th className="a-domain-cell">Associated Focus Area</th>
                        <th className="a-domain-cell">Attribute Description</th>
                        <th className="t-cross-header a-cross">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="a-tbody">
                      {attribute.attributesList.length > 0 ? (
                        attribute.attributesList.map(
                          (obj, index) => (
                            <tr key={index}>
                              <td className="a-checkbox-cell">
                                {' '}
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={obj.isChecked}
                                    onChange={() => this.toggleCheckbox(obj)}
                                  />
                                  <span className="a-checkbox-mark">
                                    <span className="appkiticon icon-check-mark-fill" />
                                  </span>
                                </label>
                              </td>
                              <td
                                className="a-title-cell cursor-pointer"
                                onClick={this.editAttribute.bind(this, obj)}
                              >
                                {obj.title}

                              </td>
                              <td
                                className="a-domain-cell cursor-pointer"
                                onClick={this.editAttribute.bind(this, obj)}
                              >
                                {obj.domainName}
                              </td>
                              <td
                                data-for={obj.title} data-tip
                                className="a-domain-cell cursor-pointer"
                                onClick={this.editAttribute.bind(this, obj)}
                              >
                                {obj.description && this.truncate(obj.description)}
                                {(obj.description && obj.description.length > 100) && <ReactTooltip id={obj.title} className='tooltip' place="bottom"
                                  effect='solid' >
                                  {obj.description}
                                </ReactTooltip>}
                              </td>
                              <td className="t-cross-icon a-cross cursor-pointer">
                                <i
                                  className="appkiticon icon-close-fill a-font-sm"
                                  onClick={() => this.confirmDelete(obj.id)}
                                />
                              </td>
                            </tr>
                          ),
                          this,
                        )
                      ) : (
                        <tr>
                          <td colSpan="7" class="font-weight-medium">Click the button below to create your first attribute.</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-md-12 col-sm-12 col-12 pr-0 at-pagination">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    nextPageEvent={this.nextPage.bind(this)}
                    prevPageEvent={this.prevPage.bind(this)}
                    goToPageEvent={this.goToPage.bind(this)}
                    pageOnChange={this.pageOnChangeEvent.bind(this)}
                  />
                </div>
              </RenderIf>
            </div>
            <a
              href="javascript:void(0)"
              className="add-text-button mt-3 a-btn a-btn-transparent a-btn-lg a-btn-gray"
              onClick={this.showAddAttributePanel.bind(this)}
            >
              <i className="appkiticon icon-plus-fill mr-2"> </i> Create a new attribute
            </a>
          </div>
          <div>
            {this.state.showAlertPopup ? (
              <AlertPopup
                title={this.state.alertPopupTitle}
                message={this.state.alertPopupMessage}
                alertPopupOkClick={this.alertPopupOkClick}
              />
            ) : (
              ''
            )}
            {this.state.showDelete ? (
              <DeleteConfirmation
                title={this.state.deleteTitle}
                message={this.state.deleteConfirmationMessage}
                confirmationClick={this.deleteAttributes}
                cancelClick={this.cancelDelete}
              />
            ) : (
              ''
            )}
            <CSSTransitionGroup
              transitionName="pwcsidebar"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
            >
              {this.state.showAddAttribute ? (
                <AddAttribute
                  hideAddAttributePanel={this.hideAddAttributePanel}
                  selectedAttribute={this.state.selectedAttribute}
                  filters={this.state.domainFilters}
                />
              ) : (
                ''
              )}
            </CSSTransitionGroup>
          </div>
          <div className="footer-dropdowns">
            {attribute.attributesList.length > 0 ? (
              <button
                className="a-btn a-btn-primary a-btn-lg dropdown-toggle"
                data-bs-toggle="dropdown"
                disabled={attribute.attributesList.filter(item => item.isChecked).length <= 0}
              >
                ACTIONS <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
              </button>
            ) : (
              ''
            )}
            <ul className="user-infor-list dropdown-menu a-shadow-sm border-0">
              <li className="user-infor-list-item" onClick={() => this.confirmBulkDelete()}>
                <a href="javascript:void(0)">Delete Selected Items</a>
              </li>
            </ul>
          </div>
        </RenderIf>
        <Feedback pageRef={FEEDBACK_PAGE_REF.ATTRIBUTE_PAGE} isShowSupportBtn = {false}/>
      </div >
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Attributes);
