import axios from 'axios';
import {
  TITLE_EXIST, PORTFOLIOS,
  CHECK_PROJECT_EXIST,
  GET_PORTFOLIOS_STAKEHOLDERS,
  ADD_PORTFOLIOS_STAKEHOLDER,
  GET_ACCESS_LEVEL_ROLES,
  CAN_ADD_PROJECT,
  PORTFOLIO_DASHBOARD,
  GET_USER_FILTERS_FOR_PORTFOLIO,
  GET_PERTFOLIO_QUESTION_MODEL,
  QUESTION_MODEL_EXIST_IN_PROJECT,
  CAN_CHANGE_SETTINGS,
  UPDATE_SHOW_PORTFOLIO,
} from '../constants/portfolio';

class portfolioService {
  static getDashboardData(portfolioId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PORTFOLIO_DASHBOARD}`, { params: { portfolioId } })
      .then(res => res)
      .catch(err => err);
  }

  static savePortfolio(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${PORTFOLIOS}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static checkTitleExistence(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${TITLE_EXIST}`, { params: data })
      .then(res => res)
      .catch(err => err);
  }

  static checkQuestionModelExistInProject(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${QUESTION_MODEL_EXIST_IN_PROJECT}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static checkProjectExistInPortfolio(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${CHECK_PROJECT_EXIST}${data.id}`)
      .then(res => res)
      .catch(err => err);
  }

  static deletePortolioService(data) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${PORTFOLIOS}${data.id}`)
      .then(res => res)
      .catch(err => err);
  }

  static getPortfoliosList() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PORTFOLIOS}`)
      .then(res => res)
      .catch(err => err);
  }

  static getAccessLevelRoles(portfolioId, projectId) {
    const params = {
      portfolioId,
      projectId,
    };
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_ACCESS_LEVEL_ROLES}`, { params })
      .then(res => res)
      .catch(err => err);
  }


  static getPortfolioQuestionModelList(portfolioId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_PERTFOLIO_QUESTION_MODEL}${portfolioId}`)
      .then(res => res)
      .catch(err => err);
  }

  static checkIfUserCanAddProject() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${CAN_ADD_PROJECT}`)
      .then(res => res)
      .catch(err => err);
  }

  static checkIfUserCanChangeSettings() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${CAN_CHANGE_SETTINGS}`)
      .then(res => res)
      .catch(err => err);
  }

  static getPortFolioDetails(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${PORTFOLIOS}${data.portfolioId}`)
      .then(res => res)
      .catch(err => err);
  }

  // Stakeholders
  static getPortFolioStakeholders(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_PORTFOLIOS_STAKEHOLDERS}${data.id}`)
      .then(res => res)
      .catch(err => err);
  }

  // Stakeholders
  static addPortfolioStakeholders(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${ADD_PORTFOLIOS_STAKEHOLDER}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // custom tags for filters
  static getUserFiltersForPortfolio(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${GET_USER_FILTERS_FOR_PORTFOLIO}`,
        { params: data })
      .then(res => res)
      .catch(err => err);
  }

  static updateShowPortfolio(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${UPDATE_SHOW_PORTFOLIO}`, data)
      .then(res => res)
      .catch(err => err);
  }
}

export default portfolioService;
