import React from 'react';
import { connect } from 'react-redux';
import SegmentGroupOverallConfidenceCard from './SegmentGroupOverallConfidenceCard.jsx';
import SegmentGroupDomainCard from './SegmentGroupDomainCard.jsx';
import constant from '../actions/constant';

const SegmentsGroupResult = ({
  segmentGroupDashboardData, isSegmentGroupApplied,
  customDatesData, getKeyPhrases, showOpenTextNonMatchRightPanel,
  expandWidgets,
  associatedStakeholdersWithFilterValue,
  allUserFilterGroupValues,
  userSelectedFilterGroupValues,
  unsavedChangesModal,
  unsavedChanges,
  updateShowUnsavedChangesModal,
  selectedSegmentValue,
  isSegmentApplied,
  overallWidget,
  showOpenTextRightPanel,
  isShowPercentageChange,
  showHideOverlay,
  showOpenTextDropQuesRightPanel,
}) => {
  const {
    segmentGroupData1, segmentGroupData2,
    segmentGroupData3, segmentGroupData4,
  } = segmentGroupDashboardData;

  let pastDays = '';
  let previousDaysCount = '';
  const domainWidgets = segmentGroupData1?.DomainWidgets;
  const domainWidgets2 = segmentGroupData2?.DomainWidgets;
  const domainWidgets3 = segmentGroupData3?.DomainWidgets;
  const domainWidgets4 = segmentGroupData4?.DomainWidgets;
  if (segmentGroupData1) {
    if (customDatesData.fromDate && customDatesData.toDate
      && customDatesData.range !== constant.LastPeriodRange) {
      if (domainWidgets[0].periodLength
        && domainWidgets[0].periodLength > 2 && domainWidgets[0].periodLength < 30) {
        previousDaysCount = domainWidgets.length > 0
          && customDatesData.range !== constant.LastPeriodRange
          ? domainWidgets[0].periodLength : 0;
        pastDays = domainWidgets[0].periodLength > 2
          ? 'weekdays' : 'weekday';
      } else {
        pastDays = 'month';
      }
    } else {
      previousDaysCount = domainWidgets.length > 0
        ? domainWidgets[0].periodLength / 2 : 0;
      pastDays = domainWidgets[0].periodLength > 2
        ? 'weekdays' : 'weekday';
    }
  }

  return (
    <React.Fragment>
      {segmentGroupData1 && isSegmentGroupApplied && (
        <div className="data-scroller-container" >
          <div className="data-container clearfix masonry-layout" >
            {/* overall widget */}
            <SegmentGroupOverallConfidenceCard
              segmentGroupDashboardData={segmentGroupDashboardData}
              previousDaysCount={previousDaysCount}
              pastDays={pastDays}
              getKeyPhrases={getKeyPhrases}
              showOpenTextNonMatchRightPanel={showOpenTextNonMatchRightPanel}
              expandWidgets={expandWidgets}
              showOpenTextRightPanel={showOpenTextRightPanel}
              isShowPercentageChange={isShowPercentageChange}
              showHideOverlay = {showHideOverlay}
              showOpenTextDropQuesRightPanel = {showOpenTextDropQuesRightPanel}
            />
            {/* custom domain widgets */}
            <div className="widgets-grid-result">

            <SegmentGroupDomainCard
              domainWidgets1={domainWidgets}
              domainWidgets2={domainWidgets2}
              domainWidgets3={domainWidgets3}
              domainWidgets4={domainWidgets4}
              customDatesData={customDatesData}
              previousDaysCount={previousDaysCount}
              pastDays={pastDays}
              expandWidgets={expandWidgets}
              associatedStakeholdersWithFilterValue={associatedStakeholdersWithFilterValue}
              allUserFilterGroupValues={allUserFilterGroupValues}
              userSelectedFilterGroupValues={userSelectedFilterGroupValues}
              unsavedChangesModal={unsavedChangesModal}
              unsavedChanges={unsavedChanges}
              updateShowUnsavedChangesModal={updateShowUnsavedChangesModal}
              selectedSegmentValue={selectedSegmentValue}
              isSegmentApplied={isSegmentApplied}
              overallWidget={overallWidget}
              isShowPercentageChange={isShowPercentageChange}
              />
            </div>
          </div>
        </div>

      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  projectSegments: state.project.projectSegments,
});


export default connect(mapStateToProps,
  null)(SegmentsGroupResult);
