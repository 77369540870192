// General Tab
const SYSTEM_TERMINOLOGY = 'settings/general/system-terminology';
const PROJECT_LIFECYCLE_STAGE = 'settings/general/project-lifecycle-stages';
const VALIDATE_PROJECT_LIFECYCLE_STAGE_NAME = 'settings/general/validate-project-lifecycle-stage-name';
const CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER = 'settings/general/change-project-lifecycle-stages-order';
const DOMAINS = 'settings/domains';
const INSERT_DOMAIN = 'settings/domains/insert-domain';
const INSERT_CUSTOM_DOMAIN = 'settings/domains/insert-custom-domain';
const DELETE_CUSTOM_DOMAIN = 'settings/domains/delete-custom-domain';
const CAN_DELETE_DOMAINS = 'settings/domains/can-delete';
const CAN_BULK_DELETE_DOMAINS = 'settings/domains/can-bulk-delete';
const VALIDATE_DOMAIN_NAME = 'settings/domains/validate-domain-name';
const ATTRIBUTES = 'settings/attributes/';
const CAN_CHANGE_DOMAIN = 'settings/attributes/can-change-domain';
const CHECK_ATTRIBUTE_ELIGIBILITY = 'settings/attributes/check-attribute-eligibility';
const FILTERED_ATTRIBUTES = 'settings/attributes/filtered-attributes';
const FILTERED_DOMAINS_DATA = 'settings/domains/filter_domains_list_for_filter/';
const FILTERED_ATTRIBUTES_DATA = 'settings/attributes/filter_attributes_list_for_filter/';
const CAN_DELETE_ATTRIBUTES = 'settings/attributes/can-delete';
const CAN_BULK_DELETE_ATTRIBUTES = 'settings/attributes/can-bulk-delete';
const VALIDATE_ATTRIBUTE_NAME = 'settings/attributes/validate-attribute-name';
const QUESTIONS = 'settings/questions';
const CAN_DELETE_QUESTIONS = 'settings/questions/can-delete';
const CAN_BULK_DELETE_QUESTIONS = 'settings/questions/can-bulk-delete';
const FILTERED_QUESTIONS = 'settings/questions/filtered-questions';
const GET_QUESTION_STAGES = 'settings/questions/get-questions-stages';
const VALIDATE_QUESTION_NAME = 'settings/questions/validate-question-name';
const USERS = 'settings/users/';
const DELETE_MY_ACCOUNT = 'settings/users/delete-my-account';
const FILTERED_USERS = 'settings/users/filtered-users';
const GET_SYSTEM_ADMIN_USERS_COUNT = 'settings/users/get-system-admin-users-count';
const GET_ALL_PROJECT_FOR_FILTERS = 'settings/users/get-all-projects-for-filters';
const GET_ALL_PORTFOLIO_FOR_FILTERS = 'settings/users/get-all-portfolios-for-filters';
const VALIDATE_EMAIL_ADDRESS = 'settings/users/validate-email-address';
const SEND_INVITATION_EMAIL = 'settings/users/resend-invite-link';
const MEASURING_METRIC = 'settings/general/measuring-metric';
const ADD_AUTHORS = 'settings/general/add-question-model-authors';
const GET_QUESTION_MODEL_AUTHOR = 'settings/general/get-question-model-authors/';
const DELETE_QUESTION_MODEL_AUTHOR = 'settings/general/delete-question-model-author';
const ANONYMITY_RULE = 'settings/general/anonimity-rule';
const CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS = 'settings/users/check-user-able-to-change-mode-options';
const UPDATE_DEFAULT_SELECTION = 'settings/users/update-default-mode-selection';
const GET_USER_DEFAULT_MODE = 'settings/users/get-user-default-mode';
const ARCHIVE_QUESTION_MODEL = 'settings/general/archive-question-model';
const ARCHIVE_QUESTION_MODEL_LIST = 'settings/models/archived-question-model-list';
const RESTORE_QUESTION_MODEL = 'settings/models/restore-question-model';
const ARCHIVE_ORGANISATION_LIST = 'settings/models/archived-organisation-list';
const ARCHIVE_TOPIC_LIST = 'settings/models/archived-topic-list';
const RESTORE_ORGANISATION = 'settings/models/restore-organisation';
const RESTORE_TOPIC = 'settings/models/restore-topic';

export default {
  SYSTEM_TERMINOLOGY,
  PROJECT_LIFECYCLE_STAGE,
  VALIDATE_PROJECT_LIFECYCLE_STAGE_NAME,
  CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER,
  DOMAINS,
  INSERT_DOMAIN,
  INSERT_CUSTOM_DOMAIN,
  DELETE_CUSTOM_DOMAIN,
  CAN_DELETE_DOMAINS,
  CAN_BULK_DELETE_DOMAINS,
  VALIDATE_DOMAIN_NAME,
  ATTRIBUTES,
  CAN_CHANGE_DOMAIN,
  VALIDATE_ATTRIBUTE_NAME,
  CAN_DELETE_ATTRIBUTES,
  CAN_BULK_DELETE_ATTRIBUTES,
  FILTERED_ATTRIBUTES,
  QUESTIONS,
  CAN_DELETE_QUESTIONS,
  CAN_BULK_DELETE_QUESTIONS,
  FILTERED_QUESTIONS,
  GET_QUESTION_STAGES,
  GET_SYSTEM_ADMIN_USERS_COUNT,
  VALIDATE_QUESTION_NAME,
  USERS,
  DELETE_MY_ACCOUNT,
  FILTERED_USERS,
  GET_ALL_PROJECT_FOR_FILTERS,
  GET_ALL_PORTFOLIO_FOR_FILTERS,
  VALIDATE_EMAIL_ADDRESS,
  CHECK_ATTRIBUTE_ELIGIBILITY,
  FILTERED_DOMAINS_DATA,
  FILTERED_ATTRIBUTES_DATA,
  SEND_INVITATION_EMAIL,
  MEASURING_METRIC,
  ADD_AUTHORS,
  GET_QUESTION_MODEL_AUTHOR,
  DELETE_QUESTION_MODEL_AUTHOR,
  ANONYMITY_RULE,
  CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS,
  UPDATE_DEFAULT_SELECTION,
  GET_USER_DEFAULT_MODE,
  ARCHIVE_QUESTION_MODEL,
  ARCHIVE_QUESTION_MODEL_LIST,
  RESTORE_QUESTION_MODEL,
  ARCHIVE_ORGANISATION_LIST,
  ARCHIVE_TOPIC_LIST,
  RESTORE_ORGANISATION,
  RESTORE_TOPIC,
};
