import { createSelector } from 'reselect';

const systemTerminologySelector = state => state.general.systemTerminology;
const projectLifeCycleStagesListSelector = state => state.general.projectLifeCycleStagesList;
const projectLifeCycleSelector = state => state.general.projectLifeCycleStage;

// Creating selector to get system terminology
const getSystemTerminologySelector = createSelector(
  [systemTerminologySelector],
  systemTerminology => systemTerminology,
);

const getprojectLifeCycleStagesListSelector = createSelector(
  [projectLifeCycleStagesListSelector],
  projectLifeCycleStagesList => projectLifeCycleStagesList,
);

const getProjectLifeCycleSelector = createSelector(
  [projectLifeCycleSelector],
  projectLifeCycleStage => projectLifeCycleStage,
);


export {
  getSystemTerminologySelector,
  getprojectLifeCycleStagesListSelector,
  getProjectLifeCycleSelector,
};
