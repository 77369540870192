import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Header.scss';
import RenderIf from '../Common';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.jsx';
import { getSelectedProject } from '../../modules/portfolio/selectors/portfolio';
import { emptyCustomQuestionBasket, removePendingCustomQuestionData } from '../../modules/project/actions/project';
import authService from '../../redux/services/authService';
import accountService from '../../redux/services/accountService';
import constant from '../../modules/account/actions/constant';
import Authorise from '../../Authorise';
import { showAccountRightPanel, hideAccountRightPanel } from '../../modules/account/actions/login';

import { getSystemTerminology } from '../../modules/settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../modules/settings/question-model/selectors/general';
import { getQuestionModels } from '../../modules/settings/question-model/actions/questionModel';
import { isUserAbleToChangeAppModeOptions } from '../../modules/settings/user/actions/user';
import { checkIfUserCanChangeSettings } from '../../modules/portfolio/actions/portfolio';
import { showHideOverlay, updateUnsavedChanges } from '../../modules/common/overlay/actions/overlay';
import switchModeSvg from '../../../assets/images/component/svg/switch-mode.svg';

const mapStateToProps = state => ({
  isSysAdmin: state.login.isSysAdmin,
  systemTerminology: getSystemTerminologySelector(state),
  customQuestionBasket: state.project.customQuestionBasket,
  selectedProject: getSelectedProject(state),
  brandName: state.brandwrapper.brandName,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  redirectUrl: state.brandwrapper.redirectUrl,
  portfolioData: state.portfolio.portfolioData,
  isUserAbleToChangeMode: state.user.isUserAbleToChangeMode,
});
const mapDispatchToProps = {
  showAccountRightPanel,
  hideAccountRightPanel,
  getSystemTerminology,
  getQuestionModels,
  emptyCustomQuestionBasket,
  checkIfUserCanChangeSettings,
  showHideOverlay,
  updateUnsavedChanges,
  isUserAbleToChangeAppModeOptions,
};

export class Header extends Component {
  constructor(props) {
    super(props);
    const { firstName, lastName, user_id: userId } = authService.getUserInfo();
    this.state = {
      firstName: firstName.charAt(0),
      lastName: lastName.charAt(0),
      fullName: `${firstName} ${lastName}`,
      userId,
      firstQuestionModelId: 0,
      settingsUrl: '',
      showDeleteModal: false,
      showCustomQuestionDeleteModel: false,
    };
    this.signOut = this.signOut.bind(this);
    this.showAccountRightPanel = this.showAccountRightPanel.bind(this);
    this.hideAccountRightPanel = this.hideAccountRightPanel.bind(this);
    this.redirectToGettingStarted = this.redirectToGettingStarted.bind(this);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    this.setState({ settingsUrl: '/settings' });
    this.props.checkIfUserCanChangeSettings();
    this.props.isUserAbleToChangeAppModeOptions();
  }

  signOut() {
    if (this.props.customQuestionBasket.customQuestions.length > 0
      || this.props.customQuestionBasket.automatedQuestions.length > 0) {
      this.setState({
        showCustomQuestionDeleteModel: true,
      });
    } else {
      accountService.logUserSignOut(
        this.state.userId,
        constant.logSignOutMessages.manualSignOutMessage,
      ).then(() => {
        document.cookie = 'ppid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        authService.clearAllStorage();
        // eslint-disable-next-line no-undef
        window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
      });
    }
  }

  canUserChangeSettings() {
    const { canChangeSettings } = this.props.portfolioData;
    let role = '';
    if (canChangeSettings) {
      role = 'questionModelAuthor';
    }
    return role;
  }

  showAccountRightPanel() {
    setTimeout(() => {
      this.props.showAccountRightPanel();
      this.props.showHideOverlay(true);
    }, 300);
  }

  redirectToGettingStarted() {
    window.location = '/getting-started?back=true';
  }

  hideAccountRightPanel() {
    this.props.hideAccountRightPanel();
  }

  redirectToSettingPage() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.customQuestionBasket.customQuestions.length > 0
      || this.props.customQuestionBasket.automatedQuestions.length > 0) {
      this.setState({ showDeleteModal: true });
    } else window.location = this.state.settingsUrl;
  }

  emptyCustomQuestionBasket() {
    return new Promise((resolve) => {
      this.props.emptyCustomQuestionBasket();
      removePendingCustomQuestionData();
      resolve(true);
      window.location = this.state.settingsUrl;
    });
  }

  emptyCustomQuestionBasketFromSignOut() {
    return new Promise((resolve) => {
      this.props.emptyCustomQuestionBasket();
      removePendingCustomQuestionData();
      resolve(true);
      accountService.logUserSignOut(
        this.state.userId,
        constant.logSignOutMessages.manualSignOutMessage,
      ).then(() => {
        document.cookie = 'ppid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        authService.clearAllStorage();
        // eslint-disable-next-line no-undef
        window.location = `${__CONFIG__.iamSignoutUri}${this.props.redirectUrl}`;
      });
    });
  }

  render() {
    const {
      firstName, lastName, fullName, showDeleteModal, showCustomQuestionDeleteModel,
    } = this.state;
    const {
      isSysAdmin, selectedProject, brandName, showProcessingRecords, isUserAbleToChangeMode,
    } = this.props;

    return (
      <div>
        <RenderIf showComponent={showDeleteModal}>
          <DeleteConfirmation
            title='Delete your pending questions?'
            message={['Are you sure you want to ', <span className="deletemessage-color preview-pane-font">permanently delete</span>, ` the pending questions for ${selectedProject.ProjectTitle} ?`]}
            confirmationClick={() => this.emptyCustomQuestionBasket()}
            cancelClick={() => this.setState({ showDeleteModal: false })}
          />
        </RenderIf>
        <RenderIf showComponent={showCustomQuestionDeleteModel}>
          <DeleteConfirmation
            title='Delete your pending questions?'
            message={['Are you sure you want to ', <span className="deletemessage-color preview-pane-font">permanently delete</span>, ` the pending questions for ${selectedProject.ProjectTitle} ?`]}
            confirmationClick={() => this.emptyCustomQuestionBasketFromSignOut()}
            cancelClick={() => this.setState({ showCustomQuestionDeleteModel: false })}
          />
        </RenderIf>
        <div className="a-header d-flex align-items-center justify-content-between a-px-20">
          <div className="d-flex align-items-center">
            <div className="a-pwc-logo-grid a-md" />
            <span className="a-mx-10" />
            <div className="font-weight-medium a-nowrap app-title">{brandName}</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="a-logout" title={fullName}>
              <div
                aria-expanded="false"
                aria-haspopup="true"
                className="user-name a-text-white a-bg-primary size-24"
                data-bs-toggle="dropdown"
              >
                {firstName.toUpperCase()}
                {lastName.toUpperCase()}
              </div>
              {!showProcessingRecords && (<div className="dropdown-menu dropdown-menu-right a-shadow-sm border-0 a-user-setting-panel-one">
                <ul className="user-infor-list">
                  {isUserAbleToChangeMode && <li className="user-infor-list-item" onClick={this.redirectToGettingStarted}>
                    <span className='swich-mode-svg'><img src={switchModeSvg} /></span> Switch Mode
                  </li>}
                  <li className="user-infor-list-item" onClick={this.showAccountRightPanel}>Account Settings</li>
                  <li className="user-infor-list-item" onClick={this.signOut}>
                    Sign Out
                  </li>
                </ul>
              </div>)}
            </div>
            <Authorise role={isSysAdmin ? 'systemAdministrator' : this.canUserChangeSettings()}
              perform="settings"
              permit={() => (
                <span className="link-icon ml-3">
                  <button className="a-btn a-btn-secondary no-border h-settings-button p-0 setting-btn"
                    onClick={() => this.redirectToSettingPage()}>
                    <i className="appkiticon a-font-20 icon-setting-outline" />
                  </button>
                </span>)} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
