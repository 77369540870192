import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getPortfolio } from '../../portfolio/selectors/portfolio';
import {
  hideOpenTextRightPanel,
} from '../../portfolio/actions/portfolio';
import {
  getOpenTextResponses,
} from '../actions/project';
import { Spinner } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import { showHideOverlay } from '../../common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  openTextRightPanel: state.portfolio.portfolioData.openTextRightPanel,
});

const mapDispatchToProps = {
  hideOpenTextRightPanel,
  getOpenTextResponses,
  showHideOverlay,
};

class OpenTextResponsesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserId: 0,
      stakeholders: [],
      isLoading: true,
      projectTitle: '',
      keyPhrase: '',
      textResponses: [],
    };
    this.hideOpenTextRightPanel = this.hideOpenTextRightPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    const { openTextRightPanel } = this.props.portfolioData;
    this.getOpenText(openTextRightPanel);
  }

  getOpenText(openTextRightPanel) {
    this.props.getOpenTextResponses(openTextRightPanel).then((res) => {
      const {
        keyPhrase, title, textResponses, userTagFilters,
      } = res;
      if (keyPhrase) {
        this.setState({
          keyPhrase,
          projectTitle: title,
          textResponses,
          userTagFilters,
          isLoading: false,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { openTextRightPanel } = nextProps.portfolioData;
    if (this.state.keyPhrase !== openTextRightPanel.keyPhrase && openTextRightPanel.keyPhrase !== '') {
      this.setState({ isLoading: true });
      this.getOpenText(openTextRightPanel);
    }
  }

  hideOpenTextRightPanel() {
    this.props.showHideOverlay(false);
    this.props.hideOpenTextRightPanel();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  sortResponse(array) {
    array.sort((a, b) => new Date(b.response_received) - new Date(a.response_received));
  }

  render() {
    const {
      keyPhrase, projectTitle, textResponses, isLoading,
    } = this.state;
    const timzone = moment.tz.guess();
    return (
      <React.Fragment >
          <div className="right-sidebar add-project-sidebar" ref={this.setWrapperRef}>
            <Spinner isLoading={isLoading} />
            <RenderIf showComponent={!isLoading}>
              <div className="right-bar custom-animation">
                <div className="right-bar-head d-flex justify-content-between align-items-star">
                  <div className="right-bar-title">
                    <h4 className="mb-0 title">{projectTitle}</h4>
                  </div>
                  <div className="right-bar-close">
                    <button type="button" className="btn btn-sm bg-transparent close" onClick={this.hideOpenTextRightPanel}>
                      <span className="material-icons">close</span>
                    </button>
                  </div>
                </div>
                <div className="header-subline"><span className="text-uppercase phrase-color">"{keyPhrase}"</span></div>


                <div className="right-bar-body pt-4">
                  <div id="accordion" className="accordion right-bar-accordion">
                    {textResponses.map((data, index) => (
                      <div key={index} className="card border-bottom">
                        <div id={`collapsed-header-${index}`} className={textResponses.length === 1 ? 'card-header' : 'card-header collapsed'} data-bs-toggle="collapse"
                          href={`#collapsed-question-${index}`}>
                          <a className="card-title">
                            <span className="mr-2 preview-pane-font">Q: </span> <span className='preview-pane-font'> {data.QuestionTitle} </span>
                          </a>
                        </div>
                        <div id={`collapsed-question-${index}`} className={textResponses.length === 1 ? 'card-body pt-2 show' : 'card-body collapse pt-2'} >
                          {this.sortResponse(data.response)}
                          {data.response.map(res => (
                            <div className={`right-bar-item mb-3 ${res.bgColor}`}>
                              <div className="d-flex align-items-center">
                                <div className="message-avatar">
                                  <span className="material-icons">
                                    person
                                  </span>
                                </div>
                                <div className="message-box">
                                  <p dangerouslySetInnerHTML={{ __html: res.response_text }}></p>
                                </div>
                              </div>
                              <small className="message-info">{moment(res.response_received).tz(timzone).format('h:mm A, D MMMM YYYY')}</small>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
              <div className="show-title" >
                <h4 className="mb-0 title" style={{
                  color: ' #c5c5c5',
                  fontSize: '14px',
                  textAlign: 'center',
                }}>The views expressed in Perspectives are those
                  provided by topic stakeholders and are not the views of PwC. </h4>
              </div>
            </RenderIf>

          </div>

      </React.Fragment >
    );
  }
}
OpenTextResponsesPanel.propTypes = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OpenTextResponsesPanel);
