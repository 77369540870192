const TITLE_EXIST = 'portfolios/check-portfolio-title-existence';
const QUESTION_MODEL_EXIST_IN_PROJECT = 'portfolios/check-question-model-existence-in-project';
const PORTFOLIOS = 'portfolios/';
const GET_ACCESS_LEVEL_ROLES = 'portfolios/get-access-level-roles';
const CAN_ADD_PROJECT = 'portfolios/can-add-project';
const CHECK_PROJECT_EXIST = 'portfolios/check-projects/';
const GET_PORTFOLIOS_STAKEHOLDERS = 'portfolios/get-portfolio-stakeholders/';
const ADD_PORTFOLIOS_STAKEHOLDER = 'portfolios/add-portfolio-stakeholder/';
const PORTFOLIO_DASHBOARD = 'portfolios/dashboard';
const GET_USER_FILTERS_FOR_PORTFOLIO = 'portfolios/get-user-filters-for-portfolio';
const GET_PERTFOLIO_QUESTION_MODEL = 'portfolios/get-portfolio-question-model-list/';
const CAN_CHANGE_SETTINGS = 'portfolios/can-change-settings';
const UPDATE_SHOW_PORTFOLIO = 'portfolios/update-show-portfolio';

export {
  TITLE_EXIST,
  PORTFOLIOS,
  CAN_ADD_PROJECT,
  GET_ACCESS_LEVEL_ROLES,
  CHECK_PROJECT_EXIST,
  GET_PORTFOLIOS_STAKEHOLDERS,
  ADD_PORTFOLIOS_STAKEHOLDER,
  PORTFOLIO_DASHBOARD,
  GET_USER_FILTERS_FOR_PORTFOLIO,
  GET_PERTFOLIO_QUESTION_MODEL,
  QUESTION_MODEL_EXIST_IN_PROJECT,
  CAN_CHANGE_SETTINGS,
  UPDATE_SHOW_PORTFOLIO,
};
