import chunkedPromises from './utils';
import iamService from '../redux/services/iamService';

const getCorrectFilterModelStackHolder = (
    correctUsersForCsv,
    existingStackHolders,
    keyNameOfEmailColumn,
) => {
    // eslint-disable-next-line no-unused-vars
    const [column0, column1, column2, column3, column4] = Object.keys(correctUsersForCsv[0]);
    // eslint-disable-next-line max-len, consistent-return
    return existingStackHolders.filter((object1) => {
        // eslint-disable-next-line max-len
        const csvMatchRecord = correctUsersForCsv.find(object2 => (object1.email_address.trim()).toLowerCase() === (object2[keyNameOfEmailColumn].trim()).toLowerCase());
        if (csvMatchRecord) {
            object1.filterGroupName1 = column1;
            object1.filterGroupName2 = column2;
            object1.filterGroupName3 = column3;
            object1.filterGroupName4 = column4;
            object1.filterGroupValue1 = csvMatchRecord[column1];
            object1.filterGroupValue2 = csvMatchRecord[column2];
            object1.filterGroupValue3 = csvMatchRecord[column3];
            object1.filterGroupValue4 = csvMatchRecord[column4];
            object1.email_address = (object1.email_address.trim()).toLowerCase();
            return object1;
        }
    });
};

const verifyPWCUsersBeforeUpload = async (pwcUsers, keyNameOfEmailColumn) => {
    const formattedEmails = pwcUsers.map(email => email[keyNameOfEmailColumn].toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
        c => iamService.validateUserByEmail({ emails: c }).then((res) => {
            const { data } = res;
            // eslint-disable-next-line consistent-return
            const result = data.filter((item) => {
                if (item.errorMessage) {
                    return item.errorMessage;
                }
            });
            return result;
        }));
    const response = [].concat(...r);
    return response;
};

export const checkFilterColumnNames = (keyNameOfColumn) => {
    // eslint-disable-next-line no-unused-vars
    const [column0, column1, column2, column3, column4] = [...keyNameOfColumn];
    return !(column1
        && column1.length
        && column2 && column2.length && column3 && column3.length && column4 && column4.length);
};

export const checkEmptyCSV = (resultData) => {
    const checkHasColumn = Object.keys(resultData[0]).length === 0;
    if (checkHasColumn) {
        return checkHasColumn;
    }
        const keyNameOfEmailColumn = Object.keys(resultData[0])[0];
        const checkCsvHasData = resultData.filter(obj => obj[keyNameOfEmailColumn]);
        return checkCsvHasData.length === 0;
};

// eslint-disable-next-line no-unused-vars
const getCorrectImportStackHolder = (resultData, existingStackHolders, keyNameOfEmailColumn) => {

};

export const getCorrectEntries = (
    resultData,
    existingStackHolders,
    keyNameOfEmailColumn,
    csvImportType,
) => {
    if (csvImportType === 'filter') {
        return getCorrectFilterModelStackHolder(
            resultData,
            existingStackHolders,
            keyNameOfEmailColumn,
        );
    }
    return getCorrectImportStackHolder(resultData, existingStackHolders, keyNameOfEmailColumn);
};

export const generateFinalStackHolderWithPwc = async (stackHolderList,
    pwcUsers, keyNameOfEmailColumn, ERRORS) => {
    const errorPwcUsers = await verifyPWCUsersBeforeUpload(pwcUsers, keyNameOfEmailColumn);
    let invalidPwcUsers = [];
    let validStackHolderList = [];
    // eslint-disable-next-line max-len
    validStackHolderList = stackHolderList.filter(stackholder => !errorPwcUsers.find(errorUser => errorUser?.errorMessage && stackholder[keyNameOfEmailColumn].trim().toLowerCase() === errorUser.emailId));
    // eslint-disable-next-line max-len
    invalidPwcUsers = stackHolderList.filter(stackholder => errorPwcUsers.find(errorUser => errorUser?.errorMessage && stackholder[keyNameOfEmailColumn].trim().toLowerCase() === errorUser.emailId)).map(user => ({ ...user, [ERRORS.ErrorColumnName]: ERRORS.invalid }));
    return { validStackHolderList, invalidPwcUsers };
};
