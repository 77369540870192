import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper';
import { connect } from 'react-redux';
// eslint-disable-next-line
import 'swiper/swiper.scss';
// import Swiper core and required modules


// eslint-disable-next-line
import 'swiper/modules/pagination/pagination.scss';
import '../styles/Splash.scss';
import 'react-circular-progressbar/dist/styles.css';
import {
  GettingStarted, FooterContent, Splashtwo, Splashthree, Splashfour, SplashFive,
} from '../../../ui-components/PageContent';
import { showHideOverlay } from '../../../modules/common/overlay/actions/overlay';
import constant from '../../../modules/account/actions/constant';

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePoint: null,
      activePosition: null,
      text: 'Welcome to Perspectives',
      mySwiper: {},
      RoleCheck: this.props.location.state.params,
    };
  }


  setFirstTimeUserFalse(val) {
    const {
      gettingStartedPage, homePage, frontendAppMode,
      questionnaireAppMode,
    } = constant;
    const {
      isuserHasAccessToSettings,
      stakeholderHasAccessToDashboard,
      isUserStakeholder, userDefaultSelectedApp,
    } = this.state.RoleCheck;
    if (val === true) {
      if (isUserStakeholder
        && stakeholderHasAccessToDashboard) {
        if (userDefaultSelectedApp !== null) {
          switch (true) {
            case userDefaultSelectedApp === frontendAppMode:
              return this.redirectToUrl(homePage, true);
            case userDefaultSelectedApp === questionnaireAppMode:
              return window.location.replace(__CONFIG__.questionnaireRedirectUrl);
            default:
              break;
          }
        } else {
          this.props.history.push(gettingStartedPage);
        }
      } else if (!isUserStakeholder
        && stakeholderHasAccessToDashboard) {
        this.redirectToUrl(homePage, true);
      } else if (isUserStakeholder) {
        window.location.replace(__CONFIG__.questionnaireRedirectUrl);
      } else if (isuserHasAccessToSettings
        || stakeholderHasAccessToDashboard) {
        this.redirectToUrl(homePage, true);
      } else {
        this.props.push(gettingStartedPage);
      }
    }
    const swiper = this.state.mySwiper;
    swiper.slideNext();
    return '';
  }

  redirectToUrl(url) {
    window.location = url;
  }

  swiperFun(val) {
    if (val.activeIndex === 0) {
      this.setState({
        text: 'Welcome to Perspectives',
      });
    } else if (val.activeIndex === 1) {
      this.setState({
        text: 'Set up your Topic and Questionaire',
      });
    } else if (val.activeIndex === 2) {
      this.setState({
        text: 'Request stakeholders to respond to the questionnaire',
      });
    } else if (val.activeIndex === 3) {
      this.setState({
        text: 'Review responses in real time',
      });
    } else if (val.activeIndex === 4) {
      this.setState({
        text: 'Compare results across various segements',
      });
    }
  }

  handleDrawerToggle = () => {
    this.props.showHideOverlay(true);
  };


  render() {
    const { RoleCheck } = this.state;
    return (
      <React.Fragment>

        <div class="d-block">
          <div class="bg-orange-img">

            <span class="bg-title mb-resp">{this.state.text}</span>
          </div>
          <React.Fragment>
            <div className='content'>

              <Swiper
                ref={this.swiperRef}
                spaceBetween={2}
                pagination={{
                  clickable: true,
                }}
                onBeforeInit={(ev) => {
                  this.setState({ mySwiper: ev });
                }}
                navigation
                onInit={(ev) => {
                  this.setState({ mySwiper: ev });
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                onActiveIndexChange={e => this.swiperFun(e)}
                initialSlide='0'
                slidesPerView={1}
                loop={false}
              >
                <SwiperSlide >
                  <GettingStarted isTablet={true} firstSplash={true}
                    gettingStarted={() => this.setFirstTimeUserFalse(false)}>
                  </GettingStarted>
                </SwiperSlide>
                <SwiperSlide>
                  <Splashtwo isTablet={true}
                    gettingStarted={() => this.setFirstTimeUserFalse(false)} >
                  </Splashtwo>
                </SwiperSlide>
                <SwiperSlide>
                  <Splashthree isTablet={true}
                    gettingStarted={() => this.setFirstTimeUserFalse(false)} >
                  </Splashthree>
                </SwiperSlide>
                <SwiperSlide>
                  <Splashfour isTablet={true}
                    gettingStarted={() => this.setFirstTimeUserFalse(false)} >
                  </Splashfour>
                </SwiperSlide>
                <SwiperSlide>
                  <SplashFive isTablet={true} isData={RoleCheck}
                    gettingStarted={() => this.setFirstTimeUserFalse(true)} >
                  </SplashFive>
                </SwiperSlide>
              </Swiper>
            </div>
          </React.Fragment>
        </div>
        <footer class="footer-btm">
          <FooterContent></FooterContent>
        </footer>
      </React.Fragment>
    );
  }
}


const mapDispatchToProps = {
  showHideOverlay,
};
const mapStateToProps = state => ({
  redirectUrl: state.brandwrapper.redirectUrl,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplashScreen);
