// Question Model Tab
const GET_QUESTION_MODEL_LIST = 'settings/models/question-model-list';
const UPDATE_QUESTION_MODEL_LIST = 'settings/models/update-question-model-list';
const INSERT_QUESTION_MODEL = 'settings/models/insert-question-model';
const VALIDATE_QUESTION_MODEL_NAME = 'settings/models/validate-question-model-name';
const ARCHIVE_QUESTION_MODEL = 'settings/models/archive-question-model';
const LIST_ARCHIVE_QUESTION_MODEL = 'settings/models/list-archived-question-model';
const UN_ARCHIVE_QUESTION_MODEL = 'settings/models/un-archive-question-model';

export default {
  GET_QUESTION_MODEL_LIST,
  UPDATE_QUESTION_MODEL_LIST,
  INSERT_QUESTION_MODEL,
  VALIDATE_QUESTION_MODEL_NAME,
  ARCHIVE_QUESTION_MODEL,
  LIST_ARCHIVE_QUESTION_MODEL,
  UN_ARCHIVE_QUESTION_MODEL,
};
