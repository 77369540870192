import React from 'react';
import { connect } from 'react-redux';
import '../styles/pagecontent.css';

import Frame from '../../../assets/images/component/Frame3.png';

class Splashfour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: props.isTablet,
    };
    this.handleClick = this.handleClick.bind(this);
    this.submitButton = React.createRef();
  }

  componentDidMount() {
    this.submitButton.current.focus();
  }

  handleClick() {
    this.props.gettingStarted();
  }

  render() {
    return (
      <React.Fragment>
        <div class="wrapper fade-in-getting-started get-start-content" style={{ backgroundColor: '#fff' }}>
            <div class="container text-center" style={{ backgroundColor: '#fff' }}>
              <div class="get-started-img">
                <img class="img-fluid" src={Frame} />
              </div>
              <div class="page-content">
                <div class="description-container" style={{ marginBottom: '18px' }}>
                <p class="description mb-0">Track and review anonymous response data from your stakeholders to gain insights across your focus areas.</p>              </div>
                <a ref={this.submitButton} onClick={() => this.handleClick()} class="get-started-btn" >Continue</a>

              </div>
            </div>

          </div>

      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


const mapDispatchToProps = {
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Splashfour);
