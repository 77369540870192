import {
  SORT_PORTFOLIOS_ARRAY,
  SORT_DOMAINS_ARRAY,
  SORT_SEGMENT_ARRAY,
  SORT_STAKEHOLDERS_ARRAY,
  REGEX_EXRESSION,
} from '../Constants';
/* eslint-disable max-len */
export const convertStringLowerCase = (string) => {
  const words = string.split(' ');

  if (words.length > 1) {
    let str = string;
    str = str.replace(/\w+/g,
      w => w[0].toUpperCase() + w.slice(1).toLowerCase());
    str = str.replace(/And/g, 'and');
    return str;
  }
  return string;
};

export const checkObjectKeysHaveValues = object => Object.values(object).filter(item => item).length;

export const checkArrayContainsSomeValues = (array1, array2) => {
  const modifiedArray2 = [array2.join().replace(/,/g, ', ')];
  return array1.some(item => modifiedArray2.includes(item));
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const sortArrayValues = (array, arrayName) => {
  if (array.length > 0) {
    if (arrayName === SORT_STAKEHOLDERS_ARRAY) {
      array.sort((a, b) => a.email_address.localeCompare(b.email_address));
      return array;
    }
    if (arrayName === SORT_SEGMENT_ARRAY) {
      array.sort((a, b) => a.text.localeCompare(b.text));
      return array;
    }
    if (arrayName === SORT_DOMAINS_ARRAY) {
      array.sort((a, b) => a.text.localeCompare(b.text));
      array.map((item) => {
        item.attributes.sort((a, b) => a.text.localeCompare(b.text));
      });
      return array;
    }
    if (arrayName === SORT_PORTFOLIOS_ARRAY) {
      array.sort((a, b) => a.title.localeCompare(b.title));
      return array;
    }
  }
  return [];
};

export const addEveryoneToFirst = (options) => {
  const clonedOptions = sortArrayValues([...options], SORT_SEGMENT_ARRAY);
  const theOne = options.findIndex(val => val.value === '0');
  const [deleted] = clonedOptions.splice(theOne, 1);
  clonedOptions.unshift(deleted);
  return clonedOptions;
};

export const getApplicableRole = (roles, projectStakeholder) => {
  let role = '';
  if (roles) {
    if (roles.portfolioAdministrator) role = 'portfolioAdministrator';
    else if (roles.projectAdministrator) role = 'projectAdministrator';
    else if (roles.projectViewer) role = 'projectViewer';
    else if (roles.portfolioStakeholder) role = 'portfolioStakeholder'; else if (projectStakeholder && roles.projectStakeholder) role = 'projectStakeholder';
  }
  return role;
};

const isTouchDevice = () => (('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0));

export const checkDeviceType = () => window.navigator.userAgent.match(/Android/i)
  || window.navigator.userAgent.match(/webOS/i)
  || window.navigator.userAgent.match(/iPhone/i)
  || window.navigator.userAgent.match(/iPad/i)
  || window.navigator.userAgent.match(/iPod/i)
  || window.navigator.userAgent.match(/BlackBerry/i)
  || window.navigator.userAgent.match(/Windows Phone/i) || isTouchDevice();

export const isInternalPwcUser = (emailId) => {
  const RU = REGEX_EXRESSION.AU_PWC_CLOUD_REGEX;
  const RE = REGEX_EXRESSION.PWC_CLOUD_REGEX;
  const RS = REGEX_EXRESSION.SCYNE_DOMAIN_REGEX;
  const result = (RE).test(emailId);
  const resultNew = (RU).test(emailId);
  const resultScyne = (RS).test(emailId);
  return result || resultNew || resultScyne;
};
