import axios from 'axios';
import constants from '../constants/questionModel';

class questionModelService {
  // General Tab
  // Questions Tab
  static getQuestionModels() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_QUESTION_MODEL_LIST}`)
      .then(res => res)
      .catch(err => err);
  }

  static updateQuestionModalList(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.UPDATE_QUESTION_MODEL_LIST}`, data)
      .then(res => res)
      .catch(err => err);
  }

  // Users Tab
  static insertQuestionModel(questionModel) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.INSERT_QUESTION_MODEL}`, questionModel)
      .then(res => res)
      .catch(err => err);
  }

  static validateQuestionModelName(questionModel) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.VALIDATE_QUESTION_MODEL_NAME}`, questionModel)
      .then(res => res)
      .catch(err => err);
  }
}

export default questionModelService;
