import React from 'react';

class Approval extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      // isDrafted,
      // sendingTestEmail,
      projectDetails,
      orgTopicAdmins,
    } = this.props;
    return (
      <React.Fragment>

                            <div className='status_card card_bg'>
                                <div className='item'>
                                    <span className='name preview-pane-font'>Status</span>
                                    <span className='value badge_sp preview-pane-font'>{projectDetails.is_drafted ? 'Draft' : 'Launched'}</span>
                                </div>
                                <div className='item'>
                                    <span className='name preview-pane-font'>Topic Creator</span>
                                    <span className='value preview-pane-font'>{projectDetails.first_name} {projectDetails.last_name}</span>
                                </div>
                                <div className='item'>
                                    <span className='name preview-pane-font'>Topic Admins</span>
                                    {orgTopicAdmins.map(viewer => (viewer.first_name || viewer.last_name ? <span className='value preview-pane-font' key={`a_viewer_${viewer.id}`}>{viewer.first_name} {viewer.last_name}</span> : <span className='value preview-pane-font' style={{ wordWrap: 'break-word' }}>{viewer.email_address}</span>))}
                                </div>
                            </div>
                            {/* <div className='action card_bg'>
                                <span className='d-block'>Actions</span>
                                {isDrafted
                                ? <a onClick={() => sendingTestEmail()}
                                className='a-btn a-btn-secondary mr-cust1-2 q-m-button w-100'>
                                  SEND A TEST
                                </a>
                                : <a className='a-btn a-btn-secondary mr-cust1-2 q-m-button w-100'>
                                  SEND A TEST
                                </a>
                                }
                            </div> */}

      </React.Fragment>
    );
  }
}


export default Approval;
