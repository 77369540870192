import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import * as bootstrap from 'bootstrap';
import { SpinnerButton } from '../Loader';

class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      label: 'DELETE',
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.deleteWrapperRef = this.deleteWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    $('.modal-backdrop').remove();
    const modaldeleteConfirmation = new bootstrap.Modal(document.getElementById('deleteConfirmation'), {
      backdrop: 'static',
    });
    modaldeleteConfirmation.show();

    if (this.props.deleteButtomLabel !== undefined) {
      this.setState({ label: this.props.deleteButtomLabel });
    } else if (this.props.archiveclick === true) {
      this.setState({ label: 'ARCHIVE' });
    } else {
      this.setState({ label: 'DELETE' });
    }
  }

  deleteWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClick();
    }
  }

  cancelClick() {
    const modaldeleteConfirmation = new bootstrap.Modal(document.getElementById('deleteConfirmation'));
    modaldeleteConfirmation.hide();
    this.props.cancelClick();
    $('.modal-backdrop').remove();
  }

  confirmationClick = () => {
    this.showSpinner(true);
    this.props.confirmationClick().then(() => {
      this.showSpinner(false);
      const modaldeleteConfirmation = new bootstrap.Modal(document.getElementById('deleteConfirmation'));
      modaldeleteConfirmation.hide();
      this.props.cancelClick();
      $('.modal-backdrop').remove();
    });
  }

  showSpinner(isDeleting) {
    this.setState({ isDeleting });
  }

  render() {
    const { label } = this.state;
    return (
      <div aria-hidden="true" className="modal a-modal fade add-stakeholder-modal" id="deleteConfirmation"
        role="dialog" data-bs-backdrop="static"
        ref={this.deleteWrapperRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">{this.props.title}</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  data-bs-dismiss="modal"
                  onClick={this.cancelClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div className="a-text-9e9 a-p4 a-mt-10">{this.props.message}</div>
            </div>
            {!this.props.hideActions && <div className="a-modal-footer a-border-tp">
              <div className="a-flex-stretch text-left a-font-sm a-text-464" />
              <button
                aria-label="Close"
                onClick={this.cancelClick}
                data-bs-dismiss="modal"
                className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10"
              >
                CANCEL
              </button>
              <SpinnerButton
                onClick={() => this.confirmationClick()}
                isLoading={this.state.isDeleting}
                data-bs-dismiss="modal"
                label= {this.props.label ? this.props.label : label }
              />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

DeleteConfirmation.propTypes = {
  message: PropTypes.string,
  selectedId: PropTypes.number,
};

export default DeleteConfirmation;
