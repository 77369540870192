import React, { Component } from 'react';
import { connect } from 'react-redux';
import './customSelectDropDown.css';
import * as $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import customQuestionSvg from '../../../assets/images/component/svg/custom-question.svg';
import { addEveryoneToFirst } from '../../utils/helper';

const RenderDropDown = props => props.options.map((item, index) => (
  <React.Fragment key={index}>
    <li ontouchend="flag=false" id={`Segment${item.value}`} data-for={`Segment${item.value}${index}`}
      data-tip={`Segment${item.value}${index}_tooltip`}
      className={`${item.selected ? 'selected' : ''} dropdown-item-c`}
      data-value={item.value} selected={item.selected}
      style={{
        padding: (typeof item.isCustomQuestionSent === 'undefined') ? '17px 10px' : '',
        backgroundColor: parseInt(item.value, 10) === parseInt(props.selectedValue, 10) ? '#fdf5f2' : '',
      }}>
      <div className="badge-label" style={{ padding: item.value === -1 ? '11.5px 0 11.5px 0' : '' }}>
        {item.value !== -1 ? <i class="circle-badge" style={{ background: item.indicatorColor }}></i>
          : <i className='circle-badge' style={{ top: 0 }}>+</i>} <span style={{ color: 'grey' }}>{
            item.text}</span></div>

      {(typeof item.isCustomQuestionSent !== 'undefined') && item.value !== -1 && (<div style={{ opacity: '0.85', flexDirection: 'row', zIndex: '91' }}>
        <span className={item.isCustomQuestionSent ? 'visible' : 'invisible'}>
          <ReactSVG src={customQuestionSvg} wrapper="svg" width="20px" height="20px" />
        </span>
        {item.stakeholders ? <span class="fade-text">({item.stakeholders > 1 ? `${item.stakeholders} stakeholders` : `${item.stakeholders} stakeholder`})</span> : null}

        {item.text === 'Everyone' || item.text === 'Create a segment'
          ? null
          : <div>
            {props.autorisetoview
              ? <div>
                {item.isAssignedToadded || item.futureCustomQuestions || item.isAssignedToDomain
                  ? <div
                    data-tip="This segment cannot be deleted as it is currently being used to restrict a focus area to a particular audience."
                    data-multiline={true}
                    data-for='toolTip1' class="segment_overlay" data-place='bottom'>
                    <button
                      disabled
                    >
                      <mat-icon class="material-icons" style={{ fontSize: '19px' }}> delete </mat-icon>
                    </button>
                    <ReactTooltip className='tollip-show' multiline={true} effect='solid'
                      id="toolTip1"></ReactTooltip>
                  </div>
                  : <div class="segment_overlay">
                    <button
                      // className={item.isAssignedToDomain ? 'visible' : 'visible'}
                      ontouchend="flag=true;"
                      onClick={() => props.delteSegementvalue(item)}
                    >
                      <mat-icon class="material-icons" style={{ fontSize: '19px' }}> delete </mat-icon>
                    </button>
                  </div>
                }
              </div> : null}
          </div>
        }

      </div>)}
    </li>
    {item.text.length > (props.baseClass === 'projects-container' ? 24 : 24)
      && <ReactTooltip id={`Segment${item.value}${index}`} className={`Segment${item.value}${index}_tooltip`} place='right'
        effect='solid' multiline={true} backgroundColor='#6b6b6b'>
        {item.text}
      </ReactTooltip>
    }
  </React.Fragment>
));


class CustomSelectDropDown extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      showTooltip: false,
      tooltipText: null,
      showdata: false,
    };
  }


  componentWillMount() {
    this.optionsWithSelectedData = this.getOptionsWithSelectedData();
  }

  componentDidUpdate() {
    this.optionsWithSelectedData = this.getOptionsWithSelectedData();
  }

  componentDidMount() {
    this.init();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const { baseClass } = this.props;
    const selectdd = $(`.${baseClass} .dropdown-select`);
    const x = $(event.target).closest(`.${baseClass}`);
    if (!x.length) {
      selectdd.parent('.dropdown-container').removeClass('active');
    }
  }

  delteSegementvalue = (item) => {
    const {
      delteSegementvalue,
    } = this.props;
    delteSegementvalue(item);
  }

  getSelectedDropDown() {
    const { options, selectedValue } = this.props;
    const value = selectedValue;
    let returnValue = null;
    options.map((item) => {
      const processedItem = Object.assign({}, item);
      if (parseInt(value, 10) === parseInt(processedItem.value, 10)) {
        returnValue = processedItem;
      }
      return processedItem;
    });
    return returnValue;
  }

  getOptionsWithSelectedData = () => {
    const { options, selectedValue } = this.props;
    const value = selectedValue;
    const optionsData = options.map((item) => {
      const processedItem = Object.assign({}, item);
      if (parseInt(value, 10) === parseInt(processedItem.value, 10)) {
        processedItem.selected = true;
      }
      return processedItem;
    });
    return optionsData;
  };

  init() {
    const {
      onSelectDropDownChange, baseClass,
      setFieldValue, name,
    } = this.props;
    $(() => {
      const selectdd = $(`.${baseClass} .dropdown-select`);
      const selectli = $(`.${baseClass} .dropdown-select-ul li`);
      selectdd.on('click', function () {
        $(this).parent('.dropdown-container').toggleClass('active');
      });
      selectli.on('click', function () {
        const that = $(this);
        // ensure clicking group labels does not cause change
        if (!that.hasClass('optgroup')) {
          const parentUl = that.parent('ul');
          const thisdd = parentUl.siblings('.dropdown-select');
          const lihtml = that.children().eq(0).html();
          const livalue = that.attr('data-value');
          that.siblings('li').removeClass('selected');
          that.addClass('selected');
          that.val(livalue);
          thisdd.children('span').html(lihtml);
          that.closest(`.${baseClass} .dropdown-container`).toggleClass('active');
          onSelectDropDownChange(livalue);
          if (setFieldValue) {
            setFieldValue(name, livalue);
          }
        }
      });
    });
  }

  render() {
    const {
      selectedValue,
      placeholder,
      baseClass,
      name, autorisetoview,
    } = this.props;
    const selectedData = this.getSelectedDropDown();
    return (
      <div className={`cstm-slct-drp-dwn ${baseClass}`}>
        {selectedValue !== undefined && (
          <div class="dropdown-container badge-dropdown sgm-custom-width"><div data-for={`${name} segment-dd-label`} data-tip={'segment-dd-label_tooltip'} class="dropdown-select entypo-down-open-big lightgrey">
            <span className="cstm-sgmntdd">{selectedData ? (<React.Fragment><i class="circle-badge" style={{ background: selectedData.indicatorColor }}></i> <span>{selectedData.text}</span></React.Fragment>) : placeholder}</span><i class="arrow-badge"></i></div>
            {selectedData?.text.length > (baseClass === 'projects-container' ? 21 : 24) && <ReactTooltip id={`${name} segment-dd-label`} className={'segment-dd-label_tooltip'} place='right'
              effect='solid' multiline={true} backgroundColor='#6b6b6b'>
              {selectedData.text}
            </ReactTooltip>}
            <ul class="dropdown-select-ul dd-body data-scroller-container">

              <RenderDropDown options={this.optionsWithSelectedData}
                selectedValue={selectedValue} baseClass={baseClass}
                delteSegementvalue={this.delteSegementvalue} autorisetoview={autorisetoview}
              />
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  options: ownProps.showCreate ? [{
    value: -1, text: 'Create a segment', isCustomQuestionSent: false, indicatorColor: '',
  },
  ...addEveryoneToFirst(state.project.projectSegments)]
    : addEveryoneToFirst(state.project.projectSegments),
});

export default connect(
  mapStateToProps,
  null,
)(CustomSelectDropDown);
