import { actionType } from '../../../redux/modules/csrftokenwrapper';
import accountService from '../../../redux/services/accountService';

// Show Unsaved Changes Modal
export function setCSRFToken(token) {
  return dispatch => dispatch({ type: actionType.SET_CSRF_TOKEN, data: token });
}

export function fetchCsrfToken() {
  return () => accountService
    .fetchCsrfToken()
    .then(response => response)
    .catch(error => error);
}
