import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  notificationData: { showError: false, errorMessage: '' },
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SHOW_NOTIFICATION:
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          showError: true,
          errorMessage: action.errorMessage,
        },
      };
    case actionType.HIDE_NOTIFICATION:
      return {
        ...state,
        notificationData: {
          ...state.notificationData,
          showError: false,
          errorMessage: '',
        },
      };
    default:
      return state;
  }
};
