import React from 'react';
import { useCSVDownloader } from 'react-papaparse';

export default function CSVDownloaderUI(props) {
  const { CSVDownloader, Type } = useCSVDownloader();
  const { title, data, filename } = props;
  return (
    <CSVDownloader
      type={Type.Link}
      filename={filename}
      className="csv-downloader"
      bom={true}
      config={{
        delimiter: ',',
      }}
      data={data}
    >
      {title}
    </CSVDownloader>
  );
}
