
import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';

class AlertPopup extends Component {
  constructor(props) {
    super(props);

    this.okClick = this.okClick.bind(this);
    this.alertPopupWrapperRef = this.alertPopupWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#alertPopup').modal('show');
  }

  alertPopupWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.alertPopupOkClick();
    }
  }

  okClick() {
    $('#alertPopup').modal('hide');
    this.props.alertPopupOkClick();
  }

  render() {
    return (
      <div aria-hidden="true" className="modal a-modal fade" id="alertPopup"
        role="dialog" ref={this.alertPopupWrapperRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">{this.props.title}</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  onClick={this.okClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div className="a-text-9e9 a-p4 a-mt-10">{this.props.message}</div>
            </div>
            <div className="a-modal-footer a-border-tp">
              <div className="a-flex-stretch text-left a-font-sm a-text-464" />
              <button aria-label="Close" onClick={this.okClick}
                className="a-btn a-btn-default a-btn-lg a-btn-gray">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AlertPopup.propTypes = {
  message: PropTypes.string,
};

export default AlertPopup;
