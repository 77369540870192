import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';
import archiveLockSvg from '../../../../assets/images/component/svg/archive-lock-icon-2.svg';
import { getPortfolio } from '../../portfolio/selectors/portfolio';
import {
  hideCustomQuestionsDetailRightPanel,
} from '../../portfolio/actions/portfolio';
import {
  getCustomQuestionResponses,
} from '../actions/project';
import MultiColorProgressBar from '../../../ui-components/MultiColorProgressBar';
import { Spinner } from '../../../ui-components/Loader';
import RenderIf from '../../../ui-components/Common';
import { YESNO_QUESTION, OPENTYPE_QUESTION, INITIAL_HTML_RENDER } from '../../../Constants';
import customDomainSvg from '../../../../assets/images/component/svg/custom-domain.svg';
import { showHideOverlay } from '../../common/overlay/actions/overlay';
import SegmentToolTipViewAll from '../../../ui-components/SegmentToolTip/SegmetToolTipViewAll.jsx';
import ViewAllRightFlyPanProgressDecision from './ViewAllRightFlyPanProgressDecision';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  selectedSegment: state.project.selectedSegment,
  projectSegments: state.project.projectSegments,
  domainWidgets: state.project.domainWidgets,
  projectData: state.project.overAllWidget.project,
  projectStakeholdersCount: state.project.projectStakeholdersCount,
});

const mapDispatchToProps = {
  hideCustomQuestionsDetailRightPanel,
  getCustomQuestionResponses,
  showHideOverlay,
};

class customQuestionsResponsePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedInUserId: 0,
      isLoading: true,
      ProjectTitle: '',
      commentResponses: [],
      isOptionalCommentAnonymity: false,
      isProjectAnonymityMet: false,
      isAttributeAnonymityPass: false,
    };
    this.hideCustomQuestionsDetailRightPanel = this.hideCustomQuestionsDetailRightPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    const { customQuestionsDetailRightPanel } = this.props.portfolioData;
    this.getCustomQuestionData(customQuestionsDetailRightPanel);
  }

  getCustomQuestionData(customQuestionsDetailRightPanel) {
    this.props.getCustomQuestionResponses(customQuestionsDetailRightPanel).then((res) => {
      const {
        projectTitle,
        commentResponses,
        isOptionalCommentAnonymity,
        isProjectAnonymityMet,
        isAttributeAnonymityPass,
      } = res;
      this.setState({
        projectTitle,
        commentResponses,
        isLoading: false,
        isOptionalCommentAnonymity,
        isProjectAnonymityMet,
        isAttributeAnonymityPass,
      });
    });
  }

  hideCustomQuestionsDetailRightPanel() {
    this.props.hideCustomQuestionsDetailRightPanel();
    this.props.showHideOverlay(false);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  isDisplayProgressBar(commentData, element, domainId) {
    const { type, response, progressBarData } = commentData;
    const { domainWidgets, projectData, projectStakeholdersCount } = this.props;
    const {
      // eslint-disable-next-line camelcase
      minimum_stakeholder_percentage,
      // eslint-disable-next-line camelcase
      minimum_stakeholder,
      // eslint-disable-next-line camelcase
      maximum_stakeholder,
    } = projectData;
    const domainDetils = domainWidgets.find(domain => domain.domainId === parseInt(domainId, 10));
    const viewAllRightFlyPan = new ViewAllRightFlyPanProgressDecision(
      projectStakeholdersCount,
      minimum_stakeholder_percentage,
      minimum_stakeholder,
      maximum_stakeholder,
    );
    switch (element) {
      case INITIAL_HTML_RENDER.progressBarSection:
        return viewAllRightFlyPan.progressBarSection(domainDetils
          && (domainDetils.showScore || false),
          domainDetils && (domainDetils.isEnforceAnonymityRule || false), progressBarData);
      case INITIAL_HTML_RENDER.yesNoSection:
        return viewAllRightFlyPan.yesNoSection(domainDetils && (domainDetils.showScore || false),
          domainDetils && (domainDetils.isEnforceAnonymityRule || false), progressBarData,
          response, type, YESNO_QUESTION);
      case INITIAL_HTML_RENDER.commentSection:
        return viewAllRightFlyPan.commentSection(domainDetils && (domainDetils.showScore || false),
          domainDetils && (domainDetils.isEnforceAnonymityRule || false), progressBarData,
          response);
      case INITIAL_HTML_RENDER.openTextQuestionSection:
        return viewAllRightFlyPan.openTextQuestionSection(domainDetils
          && (domainDetils.showScore || false),
          domainDetils && (domainDetils.isEnforceAnonymityRule || false), progressBarData,
          response, type, OPENTYPE_QUESTION);
      case INITIAL_HTML_RENDER.openTextSection:
        return viewAllRightFlyPan.openTextSection(domainDetils && (domainDetils.showScore || false),
          domainDetils && (domainDetils.isEnforceAnonymityRule || false), progressBarData,
          response, type, OPENTYPE_QUESTION);
      default:
        return false;
    }
  }


  render() {
    const {
      projectTitle,
      commentResponses,
      isLoading,
      isAttributeAnonymityPass,
    } = this.state;
    const timzone = moment.tz.guess();
    const { projectSegments } = this.props;
    return (
      <React.Fragment >
        <div className="right-sidebar add-project-sidebar progress-reset" ref={this.setWrapperRef}>
          <Spinner isLoading={isLoading} />
          <RenderIf showComponent={!isLoading}>
            <div className="right-bar custom-animation">
              <div className="right-bar-head d-flex justify-content-between align-items-center">
                <div className="right-bar-title">
                  <h4 className="mb-0 title domain-title-e">{projectTitle}</h4>
                </div>
                <div className="right-bar-close">
                  <button type="button" className="btn btn-sm bg-transparent w-h-a close p-0" onClick={this.hideCustomQuestionsDetailRightPanel}>
                    <span className="material-icons">close</span>
                  </button>
                </div>
              </div>
              <div className="header-subline">
                <span className="text-uppercase phrase-color">Custom Questions</span>
              </div>
              <div className="rb-head-description py-2">
                <small className="phrase-color d-font-15">Custom questions do not contribute to the  overall score.</small>
              </div>
              {commentResponses && commentResponses.length > 0
                && commentResponses && commentResponses.length > 0
                && commentResponses.map(data => (
                  <React.Fragment >
                    <div className="comments-heading-wrap1 p-28">
                      <h6>{data.domainTitle}</h6>
                    </div>
                    <div className="right-bar-body" style={{ marginBottom: '40px' }}>
                      <div id="accordion" className="accordion right-bar-accordion project-ques-sidebar">
                        {/* eslint-disable */}
                        {data.response && data.response.length > 0
                          && data.response.map(questionData => questionData.map((item, index) => (<React.Fragment>
                            <div key={index} id={`collapsed-header-3${item.questionId}`} style={{ overflow: 'unset' }} className={item.response.length > 0 ? "card card-open border-bottom" : "card border-bottom"}>
                              <div id={`collapsed-header-1${item.questionId}`} className={item.response.length > 0 ? "card-header " : "card-header collapsed"} data-bs-toggle="collapse"
                                href={`#collapsed-question-1${item.questionId}`}>
                                <a className="card-title custom-question-wrapper">
                                  <div className="custom-question-container">

                                    <span className="custom-question"> {item.QuestionTitle} </span>
                                    {
                                      item.isActive === false ?
                                        <div
                                          data-tip="This question will not be sent in the future question cycle."
                                          data-for='toolTip1' data-place='left'>
                                          <button
                                            disabled
                                            style={{ background: 'transparent' }}
                                          >
                                            <mat-icon class="material-icons" style={{ fontSize: '19px' }}> delete </mat-icon>
                                          </button>
                                          <ReactTooltip className='next-cycle-tooltip' place='top' effect='solid'
                                            id="toolTip1"></ReactTooltip>
                                        </div>
                                        :
                                        <div className='cq-header-action'>
                                          <SegmentToolTipViewAll
                                            projectSegments={projectSegments}
                                            selectedSegments={item.segmentIds}
                                            tooltipIdPostfix={index}
                                            toggleDropDown={() => { }}
                                          />
                                          {item.customQuestion && item.isArchived && (
                                            <ReactSVG className="mr-2" id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' wrapper="svg" width="12px" height="12px" src={archiveLockSvg} />
                                          )}
                                          <ReactTooltip id='stakeHoldersCanView' className='archive_lck_tooltip' place='left'
                                            effect='solid'>
                                            This question is locked and cannot be edited/deleted as it relates
                                            to prior topics/organisations. If you would like to re-use this question
                                            but make changes, please create  a new question instead.
                                          </ReactTooltip>
                                          {item.customQuestion && (<span className="custom-question-icon">
                                            <ReactSVG src={customDomainSvg} />
                                          </span>)}
                                          {isAttributeAnonymityPass && item.icon && item.type === YESNO_QUESTION && item.response && item.response.length > 0 && (<span className={`fa fa-commenting ml-2 cm-color orange-color
                                       ${item.iconColor}`}>
                                          </span>)}
                                        </div>
                                    }
                                  </div>
                                </a>
                              </div>
                              <div id={`collapsed-question-1${item.questionId}`} className={item.response.length > 0 ? "card-body show pt-2" : "card-body collapse pt-2"} >
                                {item.type === YESNO_QUESTION && (
                                  <React.Fragment >
                                    {this.isDisplayProgressBar(item, INITIAL_HTML_RENDER.progressBarSection, data.domainId)
                                      && (<MultiColorProgressBar
                                        showIcon={true}
                                        progressBarData={item.progressBarData} />)}
                                    {this.isDisplayProgressBar(item, INITIAL_HTML_RENDER.commentSection, data.domainId) && (
                                      <React.Fragment >
                                        <div id={`collapsed-header-2${item.questionId}`} className={item.response.length > 0 ? "card-body card-open text-toggle" : "card-body text-toggle"} data-bs-toggle="collapse"
                                          href={`#collapsed-question-2${item.questionId}`}>
                                          <a className="nested-card-title">
                                            <span className="close-text-acc"> View Additional Comments</span>
                                            <span className="open-text-acc"> Collapse Additional Comments</span>
                                          </a>
                                        </div>
                                        <div id={`collapsed-question-2${item.questionId}`} className={item.response.length > 0 ? "card-body show pt-2" : "card-body collapse pt-2"}>
                                          {item.response.map(res => (
                                            <div className={`right-bar-item mb-3 ${item.type && item.type === YESNO_QUESTION ? res.bgColor : ''}`}>
                                              <div className="d-flex align-items-center">
                                                {item.type && item.type === YESNO_QUESTION && (<div className={`message-avatar ${res.bgColor.iconColor}`}>
                                                  <span className={`material-icons dot ${res.bgColor.iconBorderColor}`}>
                                                    {res.bgColor.icon}
                                                  </span>
                                                </div>)}
                                                <div className={`message-box ${item.type && item.type === YESNO_QUESTION ? res.bgColor.color : ''}`}>
                                                  <p dangerouslySetInnerHTML={{
                                                    __html: res.response_text,
                                                  }}></p>
                                                </div>
                                              </div>
                                              <small className="message-info">{moment(res.response_received).tz(timzone).format('h:mm A, D MMMM YYYY')}</small>
                                            </div>
                                          ))}
                                        </div>
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                )}
                                {this.isDisplayProgressBar(item, INITIAL_HTML_RENDER.openTextQuestionSection, data.domainId)
                                  && item.response.map(res => (
                                    <div className={`right-bar-item mb-3 ${item.type && item.type === YESNO_QUESTION
                                      ? res.bgColor : ''}`}>
                                      <div className="d-flex align-items-center">
                                        {item.type && item.type === YESNO_QUESTION && (<div className={`message-avatar ${res.bgColor.iconColor}`}>
                                          <span className="material-icons">
                                            {res.bgColor.icon}
                                          </span>
                                        </div>)}
                                        <div className={`message-box ${item.type && item.type === YESNO_QUESTION ? res.bgColor.color : ''}`}>
                                          <p dangerouslySetInnerHTML={{
                                            __html: res.response_text,
                                          }}></p>
                                        </div>
                                      </div>
                                      <small className="message-info">{moment(res.response_received).tz(timzone).format('h:mm A, D MMMM YYYY')}</small>
                                    </div>
                                  ))}
                                {this.isDisplayProgressBar(item, INITIAL_HTML_RENDER.yesNoSection, data.domainId) && (
                                  <div className="mt-3">
                                    <div className="no-comment-text">
                                      <small>Too few stakeholder responses to display results
                                        for this question.</small>
                                    </div>
                                  </div>)}
                                {this.isDisplayProgressBar(item, INITIAL_HTML_RENDER.openTextSection, data.domainId)
                                  && (
                                    <div className="mt-3">
                                      <div className="no-comment-text">
                                        <small>Too few stakeholder responses to display results
                                          for this question.</small>
                                      </div>
                                    </div>)}
                              </div>
                            </div>

                          </React.Fragment>)))}

                        {(data.moreCustomQuestion && data.moreCustomQuestion.length > 0)
                          && data.moreCustomQuestion.map((data, index) => {
                            index = `moreCustomQuestion-${data.questionId}`;
                            if (data.customQuestion) {
                              return (
                                <React.Fragment>
                                  <div key={index} id={`collapsed-header-3${index}`} className="right-bar-accordion card border-bottom" style={{ overflow: 'unset' }}>
                                    <div id={`collapsed-header-1${index}`} className="card-header collapsed" data-toggle="collapse"
                                      href={`#collapsed-question-1${index}`}>
                                      <a className="card-title custom-question-wrapper">
                                        <div className="custom-question-container">
                                          <span className="custom-question"> {data.QuestionTitle} </span>
                                          <div className='cq-header-action'>
                                            {data.customQuestion && (<span className="custom-question-icon">
                                              <ReactSVG src={customDomainSvg} />
                                            </span>)}
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                    <div id={`collapsed-question-1${index}`} className="card-body collapse pt-2">
                                      <div className="mt-3">
                                        <div className="no-comment-text">
                                          <small>Too few stakeholder responses to display results
                                            for this question.</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                          })}
                        {(data.futureDropCustomQuestions
                          && data.futureDropCustomQuestions.length > 0)
                          && data.futureDropCustomQuestions.map((item, index) => {
                            index = `futureDropQuestion-${item.questionId}`;
                            if (item.customQuestion) {
                              return (
                                <React.Fragment>
                                  <div key={index} id={`collapsed-header-3${index}`} className="right-bar-accordion card border-bottom" style={{ overflow: 'unset' }}>
                                    <div>
                                      <a className="card-title custom-question-wrapper">
                                        <div className="custom-question-container">
                                          <span className="custom-question fq-font"> {item.QuestionTitle} </span>

                                          <div className='cq-header-action'>
                                            <SegmentToolTipViewAll
                                              projectSegments={projectSegments}
                                              selectedSegments={item.segmentIds}
                                              tooltipIdPostfix={index}
                                              toggleDropDown={() => { }}
                                            />
                                            {item.customQuestion && item.isArchived && (
                                              <ReactSVG className="mr-2" id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' wrapper="svg" width="12px" height="12px" src={archiveLockSvg} />
                                            )}
                                            <ReactTooltip id='stakeHoldersCanView' className='archive_lck_tooltip' place='left'
                                              effect='solid'>
                                              This question is locked and cannot be edited/deleted as it relates
                                              to prior topics/organisations. If you would like to re-use this question
                                              but make changes, please create  a new question instead.
                                            </ReactTooltip>
                                            {item.customQuestion && (<span className="custom-question-icon">
                                              <ReactSVG src={customDomainSvg} />
                                            </span>)}
                                            {item.customQuestion && (<span data-for={index} data-tip='tooltip' className="material-icons custom-question-icon cm-color orange-color ml-2 custom-question-icon">
                                              schedule_send
                                            </span>)}
                                          </div>

                                        </div>
                                      </a>

                                    </div>

                                    <div id={`collapsed-question-1${index}`} className="card-body collapse pt-2">
                                      <div className="mt-3">
                                        <div className="no-comment-text">
                                          <small>Too few stakeholder responses to display results
                                            for this question.</small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item.customQuestion && <ReactTooltip id={index} className='next-cycle-tooltip' place='top'
                                    effect='solid'>
                                    Question will be sent in the next question cycle.
                                  </ReactTooltip>}
                                </React.Fragment>
                              );
                            }
                            return index;
                          })}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className="show-title" >
              <h4 className="mb-0 title" style={{
                color: ' #c5c5c5',
                fontSize: '14px',
                textAlign: "center",
              }}>The views expressed in Perspectives are those provided by
                topic stakeholders and are not the views of PwC. </h4>
            </div>
          </RenderIf>
        </div>
      </React.Fragment >

    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(customQuestionsResponsePanel);
