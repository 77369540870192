import React from 'react';
import { NavLink } from 'react-router-dom';

const PwcLink = (props) => {
  const { to, label, ...rest } = props;
  return (
    <NavLink
      exact
      to={{ pathname: to, state: { layout: 'settings' } }}
      activeClassName="active"
      {...rest}
    >
      <div className={'a-font-md'}>{label}</div>
    </NavLink>
  );
};

export default PwcLink;
