import React, { Component } from 'react';

class FilterDropDown extends Component {
  render() {
    const {
      enteredvalues, value, isSelected, changeText, fieldName, setFieldValue,
      filterGroup,
    } = this.props;
    const selectVal = filterGroup[0];
    return <ul className='dropdown-box'>
      {value === selectVal ? <li
      ><p style={{ color: '#C0C0C0' }}>

          <span style={{ color: '#C0C0C0' }} className='link'>+ New Value</span>

          "{value}"</p></li> : <li onClick={() => {
            enteredvalues(
              value,
              isSelected,
              changeText,
              fieldName,
              setFieldValue,
            );
          }}><p>

          <span className='link'>+ New Value</span>

          "{value}"</p></li>}
      {filterGroup.map(tag => tag && <li style={{ backgroundColor: '#fff' }}
        onClick={() => {
          enteredvalues(
            tag,
            isSelected,
            changeText,
            fieldName,
            setFieldValue,
          );
        }}>
        <p>{tag}</p>
      </li>)}
    </ul>;
  }
}
export default FilterDropDown;
