import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

class SpinnerButton extends Component {
  render() {
    const {
      type, isLoading, label, isCustomQuestionPane, isShowTooltip, disableSubmitButton, ...restProps
    } = this.props;
    const buttonType = type || 'primary';
    const buttonText = label || 'Submit';
    const iconClass = buttonType === 'primary' ? 'a-loading-one' : 'a-loading-one a-primary';
    const submitType = restProps.onClick ? 'button' : 'submit';
    return (
      <React.Fragment>
        {isLoading && (
          <button disabled className={`a-btn a-btn-lg a-btn-${buttonType} a-no-interaction loader-button`}>
            <i className={iconClass} />
          </button>
        )}
        {!isLoading && !disableSubmitButton && !isCustomQuestionPane && (
          <button type={submitType} className={`a-btn a-btn-${buttonType} a-btn-lg`} {...restProps}>
            {buttonText}
          </button>
        )}
        {disableSubmitButton && (
          <button disabled className={`a-btn a-btn-lg a-btn-${buttonType} a-no-interaction loader-button`}>
            {buttonText}
          </button>
        )}
        {!isLoading && !disableSubmitButton && isCustomQuestionPane && (
          <>
            <span {...restProps}><button type={submitType} className={`a-btn a-btn-${buttonType} a-btn-lg`} {...restProps}>
              {buttonText}
            </button></span>
            {isShowTooltip && <ReactTooltip id="create-segment" className='create-seg-tooltip' place='bottom'
              effect='solid'>
              The filters that you have applied would result in a segment with fewer
              than 3 stakeholders, which is below the anonymity threshold.
              Please adjust your filter selections to create a segment.
            </ReactTooltip>}
          </>
        )}
      </React.Fragment>
    );
  }
}

export default SpinnerButton;
