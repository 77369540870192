const SAVE_SUCCESS = 'SAVE_SUCCESS';
const USER_FILTERS_FOR_PROJECT_AND_PORTFOLIO = 'USER_FILTER_FOR_PROJECT_AND_PORTFOLIO';
const GET_PROJECT_DETAILS = 'GET_PROJECT_DETAILS';
const SHOW_PROJECT_LOADING_SPINNER = 'SHOW_PROJECT_LOADING_SPINNER';
const HIDE_PROJECT_LOADING_SPINNER = 'HIDE_PROJECT_LOADING_SPINNER';
const CHECK_UNCHECK_DOMAIN = 'CHECK_UNCHECK_DOMAIN';
const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';
const ADD_PORTFOLIO_ADMINS_AS_PROJECT_ADMINS = 'ADD_PORTFOLIO_ADMINS_AS_PROJECT_ADMINS';
const UPDATE_PROJECT_ADMINS = 'UPDATE_PROJECT_ADMINS';
const UPDATE_NEW_SELECTED_PORTFOLIO_ID = 'UPDATE_NEW_SELECTED_PORTFOLIO_ID';
const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
const UPDATE_PROJECT_SAVE_STATE = 'UPDATE_PROJECT_SAVE_STATE';
const GET_OPEN_TEXT_QUESTIONS_LIST = 'GET_OPEN_TEXT_QUESTIONS_LIST';
const TOGGLE_ALL_OPEN_TYPE_QUESTIONS_CHECKBOXES = 'TOGGLE_ALL_OPEN_TYPE_QUESTIONS_CHECKBOXES';
const TOGGLE_OPEN_TYPE_CHECKBOX = 'TOGGLE_OPEN_TYPE_CHECKBOX';
const UPDATE_PROJECT_OPEN_TEXT_QUESTIONS = 'UPDATE_PROJECT_OPEN_TEXT_QUESTIONS';
const TOGGLE_ALL_OPEN_TYPE_QUESTIONS_EDIT_MODE = 'TOGGLE_ALL_OPEN_TYPE_QUESTIONS_EDIT_MODE';
const SEARCH_OPEN_TEXT_QUESTION = 'SEARCH_OPEN_TEXT_QUESTION';
const OPEN_TEXT_QUESTIONS_FOR_FILTER = 'OPEN_TEXT_QUESTIONS_FOR_FILTER';
const DELETE_OPEN_TEXT_QUESTION = 'DELETE_OPEN_TEXT_QUESTION';
const ADD_CUSTOM_QUESTION = 'ADD_CUSTOM_QUESTION';
const UPDATE_CUSTOM_QUESTION = 'UPDATE_CUSTOM_QUESTION';
const DELETE_CUSTOM_QUESTION = 'DELETE_CUSTOM_QUESTION';
const EMPTY_CUSTOM_QUESTION_BASKET = 'EMPTY_CUSTOM_QUESTION_BASKET';
const SHOW_CUSTOM_QUESTION_RIGHTPANEL = 'SHOW_CUSTOM_QUESTION_RIGHTPANEL';
const HIDE_CUSTOM_QUESTION_RIGHTPANEL = 'HIDE_CUSTOM_QUESTION_RIGHTPANEL';
const AUTOMATED_QUESTION_DATA = 'AUTOMATED_QUESTION_DATA';
const DELETE_AUTOMATED_QUESTION_DATA = 'DELETE_AUTOMATED_QUESTION_DATA';
const SWAP_AUTOMATED_QUESTION_DATA = 'SWAP_AUTOMATED_QUESTION_DATA';
const GET_PROJECT_DOMAINS = 'GET_PROJECT_DOMAINS';
const OPEN_DOMAIN_FOCUS_PANEL = 'OPEN_DOMAIN_FOCUS_PANEL';
const OPEN_TEXT_PANEL = 'OPEN_TEXT_PANEL';
const ENABLE_UNSAVED_CHANGES_FOCUS_DOMAIN = 'ENABLE_UNSAVED_CHANGES_FOCUS_DOMAIN';
const DISABLE_UNSAVED_CHANGES_FOCUS_DOMAIN = 'DISABLE_UNSAVED_CHANGES_FOCUS_DOMAIN';
const ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN = 'ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN';
const DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN = 'DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN';
const ADD_CUSTOM_DOMAINS_TO_TOPIC = 'ADD_CUSTOM_DOMAINS_TO_TOPIC';
const UPDATE_DOMAINS_TO_TOPIC = 'UPDATE_DOMAINS_TO_TOPIC';
const ENABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN = 'ENABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN';
const DISABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN = 'DISABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN';
const ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN = 'ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN';
const DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN = 'DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN';
const DELETE_RELEVANT_DOMAINS = 'DELETE_RELEVANT_DOMAINS';
const ASSOCIATED_PROJECT_STAKEHOLDERS = 'ASSOCIATED_PROJECT_STAKEHOLDERS';
const UPDATE_LATER_QUESTION_SAVE_MESSAGE = 'UPDATE_LATER_QUESTION_SAVE_MESSAGE';
const DISABLE_DOMAIN_ATTRIBUTE = 'DISABLE_DOMAIN_ATTRIBUTE';
const UPDATE_PROJECT_VIEWERS = 'UPDATE_PROJECT_VIEWERS';
const GET_FILTER_GROUP_DATA = 'GET_FILTER_GROUP_DATA';
const FILTER_GROUP_SUCCESS_MESSAGE = 'FILTER_GROUP_SUCCESS_MESSAGE';
const ADD_FILTER_GROUP_VALUE = 'ADD_FILTER_GROUP_VALUE';
const ADD_PROJECT_SEGMENTS = 'ADD_PROJECT_SEGMENTS';
const TOGGLE_PROJECT_SEGMENT_DROPDOWN = 'TOGGLE_PROJECT_SEGMENT_DROPDOWN';
const UPDATE_PROJECT_STAKEHOLDERS_STATE = 'UPDATE_PROJECT_STAKEHOLDERS_STATE';
const UPDATE_CURRENTLY_SELECTED_PROJECT_ID = 'UPDATE_CURRENTLY_SELECTED_PROJECT_ID';
const UPDATE_PENDING_CUSTOM_QUESTION = 'UPDATE_PENDING_CUSTOM_QUESTION';
const UPDATE_PROJECT_DRAFTED_STATUS = 'UPDATE_PROJECT_DRAFTED_STATUS';
const UPDATE_QUESTION_SENT_TO_FUTURE_DROP = 'UPDATE_QUESTION_SENT_TO_FUTURE_DROP';
const SAVE_OVER_ALL_WIDGET = 'SAVE_OVER_ALL_WIDGET';
const SAVE_SEGMENT_GROUP_DASHBOARD = 'SAVE_SEGMENT_GROUP_DASHBOARD';
const SAVE_SEGMENT_GROUP_VALUE = 'SAVE_SEGMENT_GROUP_VALUE';
const SAVE_SEGMENT_OVERALL = 'SAVE_SEGMENT_OVERALL';
const SELECTED_SEGMENT = 'SELECTED_SEGMENT';
const RESENT_QUESTIONS_EMAIL_SUCCESS = 'RESENT_QUESTIONS_EMAIL_SUCCESS';
const RESENT_QUESTIONS_EMAIL_TIMEOUT = 'RESENT_QUESTIONS_EMAIL_TIMEOUT';
const SAVE_SEGMENT_ALREADY_CREATED = 'SAVE_SEGMENT_ALREADY_CREATED';
const UPDATE_USER_SELECTED_FILTERGROUPVALUES = 'UPDATE_USER_SELECTED_FILTERGROUPVALUES';
const CLEAR_USER_SELECTED_FILTERGROUPVALUES = 'CLEAR_USER_SELECTED_FILTERGROUPVALUES';
const QUESTION_URL_COPIED = 'QUESTION_URL_COPIED';
const QUESTION_URL_COPIED_TIMEOUT = 'QUESTION_URL_COPIED_TIMEOUT';
const PROJECT_STATE = 'PROJECT_STATE';
const UPDATE_PROJECT_STAKEHOLDERS_LIST = 'UPDATE_PROJECT_STAKEHOLDERS_LIST';
const UPLOAD_IMPORT_STACKHOLDER_ERROR_RECORDS = 'UPLOAD_IMPORT_STACKHOLDER_ERROR_RECORDS';
const UPDATE_IMPORT_STACKHOLDER_FLAG = 'UPDATE_IMPORT_STACKHOLDER_FLAG';
const PROJECT_ADHOC_DATE = 'PROJECT_ADHOC_DATE';
const SEND_VALUE_DATA = 'SEND_VALUE_DATA';
const PROJECT_UPDATED_DATA = 'PROJECT_UPDATED_DATA';

export default {
  SEND_VALUE_DATA,
  SAVE_SUCCESS,
  USER_FILTERS_FOR_PROJECT_AND_PORTFOLIO,
  GET_PROJECT_DETAILS,
  SHOW_PROJECT_LOADING_SPINNER,
  HIDE_PROJECT_LOADING_SPINNER,
  CHECK_UNCHECK_DOMAIN,
  UPDATE_FORM_STATE,
  ADD_PORTFOLIO_ADMINS_AS_PROJECT_ADMINS,
  UPDATE_PROJECT_ADMINS,
  UPDATE_NEW_SELECTED_PORTFOLIO_ID,
  UPDATE_PROJECT_DETAILS,
  UPDATE_PROJECT_SAVE_STATE,
  GET_OPEN_TEXT_QUESTIONS_LIST,
  TOGGLE_ALL_OPEN_TYPE_QUESTIONS_CHECKBOXES,
  TOGGLE_OPEN_TYPE_CHECKBOX,
  UPDATE_PROJECT_OPEN_TEXT_QUESTIONS,
  TOGGLE_ALL_OPEN_TYPE_QUESTIONS_EDIT_MODE,
  SEARCH_OPEN_TEXT_QUESTION,
  OPEN_TEXT_QUESTIONS_FOR_FILTER,
  DELETE_OPEN_TEXT_QUESTION,
  ADD_CUSTOM_QUESTION,
  UPDATE_CUSTOM_QUESTION,
  DELETE_CUSTOM_QUESTION,
  EMPTY_CUSTOM_QUESTION_BASKET,
  SHOW_CUSTOM_QUESTION_RIGHTPANEL,
  HIDE_CUSTOM_QUESTION_RIGHTPANEL,
  AUTOMATED_QUESTION_DATA,
  DELETE_AUTOMATED_QUESTION_DATA,
  SWAP_AUTOMATED_QUESTION_DATA,
  GET_PROJECT_DOMAINS,
  OPEN_DOMAIN_FOCUS_PANEL,
  OPEN_TEXT_PANEL,
  ENABLE_UNSAVED_CHANGES_FOCUS_DOMAIN,
  DISABLE_UNSAVED_CHANGES_FOCUS_DOMAIN,
  ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN,
  DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOCUS_DOMAIN,
  ADD_CUSTOM_DOMAINS_TO_TOPIC,
  UPDATE_DOMAINS_TO_TOPIC,
  ENABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN,
  DISABLE_UNSAVED_CHANGES_FOR_CUSTOM_DOMAIN,
  ENABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN,
  DISABLE_UNSAVED_CHANGES_CONFIRMATION_MODAL_FOR_CUSTOM_DOMAIN,
  DELETE_RELEVANT_DOMAINS,
  ASSOCIATED_PROJECT_STAKEHOLDERS,
  UPDATE_LATER_QUESTION_SAVE_MESSAGE,
  DISABLE_DOMAIN_ATTRIBUTE,
  UPDATE_PROJECT_VIEWERS,
  GET_FILTER_GROUP_DATA,
  FILTER_GROUP_SUCCESS_MESSAGE,
  ADD_FILTER_GROUP_VALUE,
  ADD_PROJECT_SEGMENTS,
  TOGGLE_PROJECT_SEGMENT_DROPDOWN,
  UPDATE_PROJECT_STAKEHOLDERS_STATE,
  UPDATE_CURRENTLY_SELECTED_PROJECT_ID,
  UPDATE_PENDING_CUSTOM_QUESTION,
  UPDATE_PROJECT_DRAFTED_STATUS,
  UPDATE_QUESTION_SENT_TO_FUTURE_DROP,
  SAVE_OVER_ALL_WIDGET,
  SAVE_SEGMENT_GROUP_DASHBOARD,
  SAVE_SEGMENT_GROUP_VALUE,
  SAVE_SEGMENT_OVERALL,
  SAVE_SEGMENT_ALREADY_CREATED,
  SELECTED_SEGMENT,
  RESENT_QUESTIONS_EMAIL_SUCCESS,
  RESENT_QUESTIONS_EMAIL_TIMEOUT,
  UPDATE_USER_SELECTED_FILTERGROUPVALUES,
  CLEAR_USER_SELECTED_FILTERGROUPVALUES,
  QUESTION_URL_COPIED,
  QUESTION_URL_COPIED_TIMEOUT,
  PROJECT_STATE,
  UPDATE_PROJECT_STAKEHOLDERS_LIST,
  UPLOAD_IMPORT_STACKHOLDER_ERROR_RECORDS,
  UPDATE_IMPORT_STACKHOLDER_FLAG,
  PROJECT_ADHOC_DATE,
  PROJECT_UPDATED_DATA,
};
