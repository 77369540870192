const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOAD_USERS = 'LOAD_USERS';
const IS_LOGGED_IN_USER_ADMIN = 'IS_LOGGED_IN_USER_ADMIN';
const SHOW_ACCOUNT_RIGHTPANEL = 'SHOW_ACCOUNT_RIGHTPANEL';
const HIDE_ACCOUNT_RIGHTPANEL = 'HIDE_ACCOUNT_RIGHTPANEL';
const SHOW_CHANGE_PHONE_NUMBER_RIGHTPANEL = 'SHOW_CHANGE_PHONE_NUMBER_RIGHTPANEL';
const HIDE_CHANGE_PHONE_NUMBER_RIGHTPANEL = 'HIDE_CHANGE_PHONE_NUMBER_RIGHTPANEL';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const IS_LOGGED_IN_USER_INTERNAL = 'IS_LOGGED_IN_USER_INTERNAL';
const USER_ACCESSIBLE_ROLE = 'USER_ACCESSIBLE_ROLE';
const IS_LOGIN_DATA_AVAILABLE = 'IS_LOGIN_DATA_AVAILABLE';

export default {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOAD_USERS,
  IS_LOGGED_IN_USER_ADMIN,
  SHOW_ACCOUNT_RIGHTPANEL,
  HIDE_ACCOUNT_RIGHTPANEL,
  SHOW_CHANGE_PHONE_NUMBER_RIGHTPANEL,
  HIDE_CHANGE_PHONE_NUMBER_RIGHTPANEL,
  SET_USER_EMAIL,
  IS_LOGGED_IN_USER_INTERNAL,
  IS_LOGIN_DATA_AVAILABLE,
  USER_ACCESSIBLE_ROLE,
};
