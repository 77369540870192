import { createSelector } from 'reselect';

const ArchiveQuestionSelector = state => state.archiveQuestionModel;

const archiveQuestionSelector = createSelector(
  [ArchiveQuestionSelector],
  archiveQuestionModel => archiveQuestionModel,
);

export default archiveQuestionSelector;
