import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import '../styles/Home.scss';
import $ from 'jquery';
import HomeHeader from '../../../ui-components/Header/HomeHeader';
import HomeLeftPanel from '../../../ui-components/LeftPanel/HomeLeftPanel';
import HomeContent from '../../../ui-components/Content/HomeContent';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Home',
      animate: false,
      showAddProject: false,
      showAddPortfolio: false,
      isPortfolioInEditMode: false,
    };
    // bounded methods
    this.closeProjectRightPanel = this.closeProjectRightPanel.bind(this);
    this.closePortfolioRightPanel = this.closePortfolioRightPanel.bind(this);
    this.showAddPortfolioRightPanel = this.showAddPortfolioRightPanel.bind(this);
    this.showModifyPortfolioRightPanel = this.showModifyPortfolioRightPanel.bind(this);
  }

  showAddProjectRightPanel() {
    // show project panel
    this.setState({
      showAddProject: true,
      showAddPortfolio: false,
      isPortfolioInEditMode: false,
      animate: true,
    });
  }

  closePortfolioRightPanel() {
    // Hide right panel
    this.setState({
      showAddProject: !this.state.isPortfolioInEditMode,
      showAddPortfolio: false,
      isPortfolioInEditMode: false,
      animate: true,
    });
  }

  showAddPortfolioRightPanel() {
    // show portfolio panel
    this.setState({
      showAddProject: false,
      showAddPortfolio: true,
      isPortfolioInEditMode: false,
      animate: true,
    });
  }

  closeProjectRightPanel() {
    // close project panel
    this.setState({
      showAddProject: false,
      showAddPortfolio: false,
      animate: true,
    });
  }

  showModifyPortfolioRightPanel() {
    // show portfolio panel for modification
    this.setState({
      showAddProject: false,
      showAddPortfolio: true,
      isPortfolioInEditMode: true,
      animate: true,
    });
  }

  render() {
    return (
      <div>
        <HomeHeader />
        <HomeLeftPanel showAddProjectRightPanelEvent={this.showAddProjectRightPanel.bind(this)}/>
        <HomeContent rightSidePanelData={this.state}
        showModifyPortfolioRightPanel = {this.showModifyPortfolioRightPanel}
        closePortfolioRightPanel={this.closePortfolioRightPanel}
        showAddPortfolioRightPanel={this.showAddPortfolioRightPanel}
        closeProjectRightPanel={this.closeProjectRightPanel} {...this.props} />
        </div>
    );
  }
}

export default Home;
