import React from 'react';
import ReactTooltip from 'react-tooltip';

const InformationOutline = () => (
    <span
    id="informmation_outline" data-for="informmation_outline"
    data-tip="result_informmation_outline"
    class="Appkit4-icon icon-information-outline">
    <ReactTooltip id='informmation_outline' className='result_informmation_outline' place='bottom'
      effect='solid'>
      <ul style={{ listStyleType: 'disc' }}>
        <li>* 1 Question from each subcategory will be included in
             the question set sent to your stakeholders.</li>
        <li>* Where a Sub-Category has more than 1 question available,
             Perspectives will randomly select a question for that Sub-Category.</li>
      </ul>
    </ReactTooltip>
  </span>
);

export default InformationOutline;
