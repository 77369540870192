import { createSelector } from 'reselect';

const UserSelector = state => state.user;
const AddUserSelector = state => state.addUser;

const userSelector = createSelector(
  [UserSelector],
  user => user,
);

const addUserSelector = createSelector(
  [AddUserSelector],
  addUser => addUser,
);

export { userSelector, addUserSelector };
