import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { SpinnerButton } from '../Loader';

class ClearConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };

    this.cancelClear = this.cancelClear.bind(this);
    this.confirmationClick = this.confirmationClick.bind(this);
    this.clearWrapperRef = this.clearWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#clearConfirmation').modal('show');
  }

  clearWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClear();
    }
  }

  cancelClear() {
    $('#clearConfirmation').modal('hide');
    this.props.cancelClear();
  }

  confirmationClick() {
    this.showSpinner(true);
    this.props.confirmationClick();
    this.showSpinner(false);
    $('#clearConfirmation').modal('hide');
  }

  showSpinner(isDeleting) {
    this.setState({ isDeleting });
  }

  render() {
    return (
      <div aria-hidden="true" className="modal a-modal fade add-stakeholder-modal show-on-top" id="clearConfirmation"
        role="dialog" data-backdrop={this.props.onClickOutsideHide === undefined ? true : this.props.onClickOutsideHide}
        ref={this.clearWrapperRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">Clear all values for this filter group ('{this.props.filterGroupName}')?</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  onClick={this.cancelClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div className="a-text-9e9 a-p4 a-mt-10">All the associated topic stakeholders will unassign from this filter group ('{this.props.filterGroupName}').
        We can't undo this action once you clear all values for this filter group.</div>
              <div className="a-text-9e9 a-p4 a-mt-10">Are you sure you want to clear all values for this filter group ('{this.props.filterGroupName}')?</div>
            </div>
            {!this.props.hideActions && <div className="a-modal-footer a-border-tp">
              <div className="a-flex-stretch text-left a-font-sm a-text-464" />
              <button
                aria-label="Close"
                onClick={this.cancelClear}
                className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10"
              >
                CANCEL
              </button>
              <SpinnerButton
                onClick={() => this.confirmationClick()}
                isLoading={this.state.isDeleting}
                label={this.props.deleteButtomLabel !== undefined ? this.props.deleteButtomLabel : 'CLEAR'}
              />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

ClearConfirmation.propTypes = {
  confirmationClick: PropTypes.func.isRequired,
};

export default ClearConfirmation;
