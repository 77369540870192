import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Formik, Form, ErrorMessage,
} from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import authService from '../../../redux/services/authService';
import { SpinnerButton } from '../../../ui-components/Loader';
import {
  showAccountRightPanel,
  hideChangePhoneNumberRightPanel,
  changeUserMobileNumber,
} from '../actions/login';
import { showHideOverlay, updatePhoneRightPanelFlag } from '../../common/overlay/actions/overlay';

class UserPhoneNumberUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPhoneNumber: '',
      isSubmitting: false,
      userEmail: authService.getUserInfo().email,
    };
    this.showAccountRightPanel = this.showAccountRightPanel.bind(this);
    this.hideChangePhoneNumberPanel = this.hideChangePhoneNumberPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.props.updatePhoneRightPanelFlag(true);
  }

  showAccountRightPanel() {
    this.props.showAccountRightPanel();
  }

  hideChangePhoneNumberPanel() {
    this.props.hideChangePhoneNumberRightPanel();
    this.props.showHideOverlay(true);
    this.props.updatePhoneRightPanelFlag(false);
    this.showAccountRightPanel();
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }


  updatePhoneNumber(model) {
    this.setState({
      isSubmitting: true,
    });
    const userModel = {
      email: model.userEmail,
      mobileNumber: `+${model.newPhoneNumber}`,
    };
    // update number
    this.props.changeUserMobileNumber(userModel).then(() => {
      this.setState({
        isSubmitting: false,
      });
      this.hideChangePhoneNumberPanel();
    });
  }

  render() {
    const {
      isSubmitting, userEmail,
    } = this.state;
    const model = {
      newPhoneNumber: '', userEmail,
    };
    return (
            <React.Fragment>
                <div className="right-sidebar add-project-sidebar" ref={this.setWrapperRef}>

                    <div className="r-s-header">
                        <div className="r-s-title">
                            <span>
                                {'Change Phone Number'}
                                <a>
                                    <i
                                        className="appkiticon icon-close-outline"
                                        onClick={this.hideChangePhoneNumberPanel}
                                    />
                                </a>{' '}
                            </span>
                        </div>
                    </div>
                    <div className="app">
                        <Formik
                            initialValues={model}
                            enableReinitialize
                            validateOnBlur={true}

                            onSubmit={(values) => {
                              this.updatePhoneNumber(values);
                            }}
                        >
                            {() => (
                                <Form>
                                    <div className="r-s-body mt-3">
                                        <div className="form-group">
                                            <div className="a-form-label">New Phone Number</div>
                                            <div>
                                            <div className="phoneInputFlag">
                                            <PhoneInput
                                              country={'au'}
                                              enableAreaCodes={false}
                                              countryCodeEditable={false}
                                              autoFormat={false}
                                              inputProps={{
                                                name: 'newPhoneNumber',
                                              }}
                                              onChange={(phone) => {
                                                model.newPhoneNumber = phone;
                                              }}
                                            />
                                            </div>
                                                {/* <Field
                                                    name="newPhoneNumber"
                                                    autoComplete="off"
                                                    setFieldValue={model.newPhoneNumber}
                                                    className={
                                                        errors.newPhoneNumber
                                                            && touched.newPhoneNumber
                                                          ? 'error a-text-input' : 'a-text-input'
                                                    }
                                                /> */}
                                                <div className="error">
                                                    {' '}
                                                    <ErrorMessage name="newPhoneNumber" />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="r-s-actions mt-4">
                                        <div className="mt-3 mb-4"
                                        >
                                            <SpinnerButton
                                                isLoading={isSubmitting}
                                                label='SAVE'
                                            />
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>

                    </div>
                </div>
            </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userProp: state.login,
});

const mapDispatchToProps = {
  showAccountRightPanel,
  hideChangePhoneNumberRightPanel,
  changeUserMobileNumber,
  showHideOverlay,
  updatePhoneRightPanelFlag,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserPhoneNumberUpdate);
