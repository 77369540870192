import axios from 'axios';
import constants from '../constants/settings';

class settingsService {
  // General Tab
  static getSystemTerminology() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.SYSTEM_TERMINOLOGY}`)
      .then(res => res)
      .catch(err => err);
  }

  static updateSystemTerminology(level) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.SYSTEM_TERMINOLOGY}`, level)
      .then(res => res)
      .catch(err => err);
  }

  static updateMeasuringMetric(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.MEASURING_METRIC}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static updateAnonymityRule(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.ANONYMITY_RULE}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getProjectLifeCycleStagesList(questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.PROJECT_LIFECYCLE_STAGE}`, {
        params: { QuestionModelId: questionModelId },
      })
      .then(res => res)
      .catch(err => err);
  }

  static validateProjectLifecycleStageName(projectLifeCycleStage, questionModelId) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.VALIDATE_PROJECT_LIFECYCLE_STAGE_NAME}`, projectLifeCycleStage, questionModelId)
      .then(res => res)
      .catch(err => err);
  }

  static getProjectLifeCycleStage(projectLifeCycleStage) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.PROJECT_LIFECYCLE_STAGE}`, {
        params: projectLifeCycleStage,
      })
      .then(res => res)
      .catch(err => err);
  }

  static insertProjectLifeCycleStage(projectLifeCycleStage) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.PROJECT_LIFECYCLE_STAGE}`, projectLifeCycleStage)
      .then(res => res)
      .catch(err => err);
  }

  static updateProjectLifeCycleStage(projectLifeCycleStage) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.PROJECT_LIFECYCLE_STAGE}`, projectLifeCycleStage)
      .then(res => res)
      .catch(err => err);
  }

  static deleteProjectLifeCycleStages(projectLifeCycleStage) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.PROJECT_LIFECYCLE_STAGE}`, { params: projectLifeCycleStage })
      .then(res => res)
      .catch(err => err);
  }

  static deleteQuestionModelAuthor(author) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.DELETE_QUESTION_MODEL_AUTHOR}`, { params: author })
      .then(res => res)
      .catch(err => err);
  }

  static changeProjectLifeCycleStagesOrder(sortOrder) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.CHANGE_PROJECT_LIFECYCLE_STAGES_ORDER}`, { data: sortOrder })
      .then(res => res)
      .catch(err => err);
  }

  static addQuestionModelAuthors(authorsData) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.ADD_AUTHORS}`, authorsData)
      .then(res => res)
      .catch(err => err);
  }

  // Domain Tab
  static getDomainsList(searchValue, paginatedData, questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.DOMAINS}`, { params: { Filters: searchValue, params: paginatedData, QuestionModelId: questionModelId } })
      .then(res => res)
      .catch(err => err);
  }

  static getDomainsListForFilter(questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.FILTERED_DOMAINS_DATA}${questionModelId}`)
      .then(res => res)
      .catch(err => err);
  }

  static validateDomainName(domain) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.VALIDATE_DOMAIN_NAME}`, domain)
      .then(res => res)
      .catch(err => err);
  }

  static insertDomain(domain) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.INSERT_DOMAIN}`, domain)
      .then(res => res)
      .catch(err => err);
  }

  static insertCustomDomain(data) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.INSERT_CUSTOM_DOMAIN}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static updateDomain(domain) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.DOMAINS}`, domain)
      .then(res => res)
      .catch(err => err);
  }

  static canBulkDeleteDomains(selectedIds) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_BULK_DELETE_DOMAINS}`,
        { params: { selectedIds } })
      .then(res => res)
      .catch(err => err);
  }

  static canDeleteDomains(id) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_DELETE_DOMAINS}`,
        { params: { id } })
      .then(res => res)
      .catch(err => err);
  }

  static deleteDomains(selectedIds) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.DOMAINS}`, { data: selectedIds })
      .then(res => res)
      .catch(err => err);
  }

  // Attribute Tab
  static insertAttribute(attribute) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.ATTRIBUTES}`, attribute)
      .then(res => res)
      .catch(err => err);
  }

  static validateAttributeName(attribute) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.VALIDATE_ATTRIBUTE_NAME}`, attribute)
      .then(res => res)
      .catch(err => err);
  }

  static getAttributesList(domains, paginatedData, questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.FILTERED_ATTRIBUTES}`, { params: { Filters: domains, PaginatedData: paginatedData, QuestionModelId: questionModelId } })
      .then(res => res)
      .catch(err => err);
  }

  static getAttributesListForFilter(questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.FILTERED_ATTRIBUTES_DATA}${questionModelId}`)
      .then(res => res)
      .catch(err => err);
  }

  static updateAttribute(attribute) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.ATTRIBUTES}`, attribute)
      .then(res => res)
      .catch(err => err);
  }

  static canBulkDeleteAttributes(selectedIds) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_BULK_DELETE_ATTRIBUTES}`, {
        params: { selectedIds },
      })
      .then(res => res)
      .catch(err => err);
  }

  static canDeleteAttributes(id) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_DELETE_ATTRIBUTES}`, {
        params: { id },
      })
      .then(res => res)
      .catch(err => err);
  }

  static deleteAttributes(selectedIds) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.ATTRIBUTES}`, { data: selectedIds })
      .then(res => res)
      .catch(err => err);
  }

  // Questions Tab
  static getQuestionsList(filters, paginatedData, questionModelId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.FILTERED_QUESTIONS}`, {
        params: { filters, paginatedData, questionModelId },
      })
      .then(res => res)
      .catch(err => err);
  }

  // Questions Tab
  static getQuestionStages(id) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_QUESTION_STAGES}`, {
        params: { id },
      })
      .then(res => res)
      .catch(err => err);
  }

  static getSystemAdminUsersCount() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_SYSTEM_ADMIN_USERS_COUNT}`)
      .then(res => res)
      .catch(err => err);
  }

  static checkAttributeEligibility(id) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CHECK_ATTRIBUTE_ELIGIBILITY}`, {
        params: { id },
      })
      .then(res => res)
      .catch(err => err);
  }

  static insertQuestion(question) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.QUESTIONS}`, question)
      .then(res => res)
      .catch(err => err);
  }

  static updateQuestion(question) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.QUESTIONS}`, question)
      .then(res => res)
      .catch(err => err);
  }

  static canChangeDomain(id, domainId) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_CHANGE_DOMAIN}`, {
        params: { id, domainId },
      })
      .then(res => res)
      .catch(err => err);
  }

  static canBulkDeleteQuestions(selectedIds) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_BULK_DELETE_QUESTIONS}`, {
        params: { selectedIds },
      })
      .then(res => res)
      .catch(err => err);
  }

  static canDeleteQuestion(id) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CAN_DELETE_QUESTIONS}`, {
        params: { id },
      })
      .then(res => res)
      .catch(err => err);
  }

  static deleteQuestions(selectedIds) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.QUESTIONS}`, { data: selectedIds })
      .then(res => res)
      .catch(err => err);
  }


  // Users Tab
  static insertUser(user) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.USERS}`, user)
      .then(res => res)
      .catch(err => err);
  }

  static validateEmailAddress(user) {
    return axios
      .post(`${__CONFIG__.apiEndPoint}${constants.VALIDATE_EMAIL_ADDRESS}`, user)
      .then(res => res)
      .catch(err => err);
  }

  static getUsersList(filters, paginationData) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.FILTERED_USERS}`, { params: { Filters: filters, PaginatedData: paginationData } })
      .then(res => res)
      .catch(err => err);
  }

  static getAllProjectsForFilters() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_ALL_PROJECT_FOR_FILTERS}`)
      .then(res => res)
      .catch(err => err);
  }

  static getAllPortfoliosForFilters() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_ALL_PORTFOLIO_FOR_FILTERS}`)
      .then(res => res)
      .catch(err => err);
  }

  static deleteUsers(selectedIds) {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.USERS}`, { data: selectedIds })
      .then(res => res)
      .catch(err => err);
  }

  static deleteMyAccount() {
    return axios
      .delete(`${__CONFIG__.apiEndPoint}${constants.DELETE_MY_ACCOUNT}`)
      .then(res => res)
      .catch(err => err);
  }

  static updateUser(user) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.USERS}`, user)
      .then(res => res)
      .catch(err => err);
  }

  static resendInviations(emails) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.SEND_INVITATION_EMAIL}`, emails)
      .then(res => res)
      .catch(err => err);
  }

  static getQuestionModelAuthors(data) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_QUESTION_MODEL_AUTHOR}${data.id}`)
      .then(res => res)
      .catch(err => err);
  }

  static checkUserAbleToChangeModeOptions() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.CHECK_USER_ABLE_TO_CHANGE_MODE_OPTIONS}`)
      .then(res => res)
      .catch(err => err);
  }

  static archiveQuestionModel(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.ARCHIVE_QUESTION_MODEL}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static setUserDefaultMode(selection) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.UPDATE_DEFAULT_SELECTION}`, selection)
      .then(res => res)
      .catch(err => err);
  }

  static getArchiveQuestionModelList(filters, paginationData) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.ARCHIVE_QUESTION_MODEL_LIST}`, { params: { Filters: filters, PaginatedData: paginationData } })
      .then(res => res)
      .catch(err => err);
  }

  static getUserDefaultMode() {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.GET_USER_DEFAULT_MODE}`)
      .then(res => res)
      .catch(err => err);
  }

  static restoreArchiveQuestionModel(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.RESTORE_QUESTION_MODEL}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static restoreArchiveOrg(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.RESTORE_ORGANISATION}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static restoreArchiveTopic(data) {
    return axios
      .put(`${__CONFIG__.apiEndPoint}${constants.RESTORE_TOPIC}`, data)
      .then(res => res)
      .catch(err => err);
  }

  static getArchiveOrganisationList(filters, paginationData) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.ARCHIVE_ORGANISATION_LIST}`, { params: { Filters: filters, PaginatedData: paginationData } })
      .then(res => res)
      .catch(err => err);
  }

  static getArchiveTopicList(filters, paginationData) {
    return axios
      .get(`${__CONFIG__.apiEndPoint}${constants.ARCHIVE_TOPIC_LIST}`, { params: { Filters: filters, PaginatedData: paginationData } })
      .then(res => res)
      .catch(err => err);
  }
}

export default settingsService;
