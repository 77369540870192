import accountService from '../../redux/services/accountService';
import { actionType } from '../../redux/modules/account';


// Creating thunk action creaters which will be access from component
// save project action
export default function isLoggedInUserSystemAdministrator() {
  return dispatch => accountService
    .isLoggedInUserSystemAdministrator()
    .then((res) => {
      dispatch({ type: actionType.IS_LOGGED_IN_USER_ADMIN, data: res.data.isSysAdmin });
      dispatch({ type: actionType.IS_LOGGED_IN_USER_INTERNAL, data: res.data.isExternalUser });
      dispatch({ type: actionType.IS_LOGIN_DATA_AVAILABLE, data: res.data });
      return res.data;
    })
    .catch({});
}
