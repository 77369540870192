const GET_USERS = 'user/getUsers';
const SIGN_IN = 'auth/sign-in';
const IS_LOGGED_IN_USER_SYSTEM_ADMINISTRATOR = 'auth/is-logged-in-user-system-administrator';
const GET_LOGGED_IN_USER_DETAILS = 'settings/users/get-logged-in-user-details';
const TOGGLE_EMAIL_NOTIFICATION = 'settings/users/toggle-email-notification';
const LOG_USER_SIGN_OUT = 'auth/is-user-sign-out';
const RESET_PASSWORD = '/user/external/reset-password';
const FETCH_CSRF_TOKEN = 'initiate-csrf-token';

export default {
  GET_USERS,
  SIGN_IN,
  IS_LOGGED_IN_USER_SYSTEM_ADMINISTRATOR,
  GET_LOGGED_IN_USER_DETAILS,
  TOGGLE_EMAIL_NOTIFICATION,
  LOG_USER_SIGN_OUT,
  RESET_PASSWORD,
  FETCH_CSRF_TOKEN,
};
