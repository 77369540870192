import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import appleQRCode from '../../../assets/images/component/apple-store-qr-code.png';

class PreviewWelcomeMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.confirmationClick = this.confirmationClick.bind(this);
    this.previewWrapperRef = this.previewWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#previewWelcomeMessage').modal('show');
  }

  previewWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClick();
    }
  }

  cancelClick() {
    $('#previewWelcomeMessage').modal('hide');
    this.props.cancelClick();
  }

  confirmationClick() {
    this.showSpinner(true);
    this.props.confirmationClick().then(() => {
      this.showSpinner(false);
      $('#previewWelcomeMessage').modal('hide');
    });
  }

  showSpinner(isDeleting) {
    this.setState({ isDeleting });
  }

  checkWhiteSpace(welcomeMessage) {
    return /\S/.test(welcomeMessage);
  }

  render() {
    return (
      <React.Fragment>

        <div
          aria-hidden="true"
          className="modal custom-tags-modal a-modal fade add-stakeholder-modal preview-modal"
          id="previewWelcomeMessage"
          role="dialog"
          ref={this.previewWrapperRef}
          data-backdrop="static"
        >
          <div className="modal-dialog " role="document">
            <div className="modal-content">
              <div className="a-modal-header pr-1 mb-1">
                <div className="a-flex-stretch d-flex align-items-center">
                  <div className="a-h3">Preview welcome message</div>
                </div>
                <span className="icon-wrapper a-mr-10" />
                <span className="icon-wrapper">
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    onClick={this.cancelClick}
                  />
                </span>
              </div>
              <div className="app">
                <div className='r-s-body mt-0 a-table-scroller'>
                  <div class="a-pwc-border-box p-3 ml-3 mr-3 mt-0">

                    <div class="row mb-3 mt-0 align-items-center">
                      <div class="col">
                        <div class="a-pwc-logo-grid"></div>
                      </div>
                      <div class="col text-right">
                        <h6 class="info-gray-headline">Perspectives</h6>
                      </div>
                    </div>
                    <div class="row mb-1">
                      <div class="col">
                        <h2 className='text-head'>Share your insights on <span class="text-primary">{this.props.title}</span></h2>
                      </div>
                    </div>

                    <div class="row preview-message">
                      <div class="col">
                        {this.checkWhiteSpace(this.props.message)
                        && <div><p style={{
                          whiteSpace: 'pre-wrap', padding: '0 8PX', display: 'inline-block', backgroundColor: '#F2F2F2',
                          }}><em>{this.props.message}</em></p></div>}

                        <p style={{ whiteSpace: 'pre-wrap' }}>Scan the QR code on your phone or click the button below to start answering questions</p>
                      </div>
                    </div>

                    <div class="row justify-content-center align-items-center mb-3 mt-3">
                      <div class="col-auto">
                        <img width="150" src={appleQRCode} alt="app-store" class="CToWUd"></img>
                      </div>
                      <div class="col-auto">
                        <h><strong>OR</strong></h>
                      </div>
                      <div class="col-auto">
                        <button class="a-btn ml-2 a-btn-primary a-btn-lg custom-btn">Share my perspectives now</button>
                      </div>
                    </div>

                    <div class="row mb-1">
                      <div class="col">
                        <p class="text-info-sm">Note: This email contains your unique feedback link. Please don't share it with others.</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col preview-message">
                        <p class="m-0 mb-1"><strong>What is Perspectives?</strong></p>
                        <p class="m-0">Feedback is the lifeblood of organisations as it forms the centre of a healthy, continuous improvement culture. Perspectives is a human-centered, easy to use app that helps to digitise the "water cooler" conversation by enabling you to share feedback with your team in a quick and simple way that fits in with your busy working life.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>);
  }
}

PreviewWelcomeMessage.propTypes = {
  message: PropTypes.string,
  selectedId: PropTypes.number,
};

export default PreviewWelcomeMessage;
