import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from '../../ui-components/Error';
import {
  General, Domain, Attributes, Questions,
} from './question-model/index.jsx';
import { Users, ArchiveQuestionModels } from './user/index.jsx';

export default () => (
  <Switch>
    <Route exact path="/settings" component={General} />
    <Route exact path="/settings/:id/stage" component={General} />
    <Route exact path="/settings/:id/stage/:emailId" component={General} />
    <Route exact path="/settings/:id/domains" component={Domain} />
    <Route exact path="/settings/users" component={Users} />
    <Route exact path="/settings/:id/attributes" component={Attributes} />
    <Route exact path="/settings/:id/questions" component={Questions} />
    <Route exact path="/settings/archive-question-models" component={ArchiveQuestionModels} />
    <Route component={NotFound} />
  </Switch>
);
