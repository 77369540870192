import actionType from './actionType';

// Configured initial state for axios
const initialState = {
  showOverlay: false,
  unsavedChanges: false,
  isPhoneRightPanelActive: false,
};

// Creating axios reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SHOW_OVERLAY_FLAG:
      return {
        ...state,
        showOverlay: action.overlayFlag,
      };
    case actionType.UNSAVED_CHANGES_FLAG:
      return {
        ...state,
        unsavedChanges: action.unsavedChangesFlag,
      };
    case actionType.PHONE_RIGHT_PANEL_FLAG:
      return {
        ...state,
        isPhoneRightPanelActive: action.isPhoneRightPanelActive,
      };
    default:
      return state;
  }
};
