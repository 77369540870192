import React from 'react';
import { connect } from 'react-redux';
import '../styles/pagecontent.css';

import footerImage from '../../../assets/images/component/group-7.svg';

const FooterContent = ({ brandName }) => (
  <React.Fragment>
    <div class="footer-wrapper">
      <div class="footer-img">
        <img class="img-fluid" src={footerImage} />
        <span className="ml-2 logo-text">{brandName}</span>
      </div>
      <div class="footer-links-container d-block">
        <a target="_blank" href='/information-collection-statement' class="footer-link">Information Collection Statement</a>
        <a target="_blank" href='/terms-of-use' class="footer-link">Terms of Use</a>
      </div>
    </div>
  </React.Fragment>
);

const mapStateToProps = state => ({
  baseUrl: state.brandwrapper.baseUrl,
  brandName: state.brandwrapper.brandName,
});

export default connect(
  mapStateToProps,
  null,
)(FooterContent);
