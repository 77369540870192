import React from 'react';
import { push } from 'connected-react-router';
import './LeftPanel.scss';
import { connect } from 'react-redux';
import $ from 'jquery';
import Cryptr from 'cryptr';
import RenderIf from '../Common';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.jsx';
import { getPortfolio, isSearchingOn, getSelectedProject } from '../../modules/portfolio/selectors/portfolio';
import {
  getPortfoliosData,
  searchProjects,
  changeSelectedPortfolioAndProject,
  showProjectRightPanel,
  hidePortfolioRightPanel,
  hideProjectRightPanel,
  checkIfUserCanAddProject,
} from '../../modules/portfolio/actions/portfolio';
import {
  emptyCustomQuestionBasket, removePendingCustomQuestionData, getProjectDetailsAction,
  saveSegmentGroupDashboard, projectAdhocDropDate,
} from '../../modules/project/actions/project';
import Authorise from '../../Authorise';

import { getSystemTerminology } from '../../modules/settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../modules/settings/question-model/selectors/general';
import { levelOneOrganizations, levelTwoOrganizations } from '../../modules/settings/question-model/actions/levelconstants';

import {
  disableUnsavedChanges, disableUnsavedChangesModal,
} from '../../modules/general/actions/general';
import constant from '../../modules/account/actions/constant';
import PortfolioSetting from './PortfolioSetting/PortfolioSetting.jsx';
import { getApplicableRole } from '../../utils/helper';
import { showHideOverlay } from '../../modules/common/overlay/actions/overlay';

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  location: state.router.location,
  isSearchingOn: isSearchingOn(state),
  isSysAdmin: state.login.isSysAdmin,
  systemTerminology: getSystemTerminologySelector(state),
  unsavedChanges: state.general.unsavedChanges,
  customQuestionBasket: state.project.customQuestionBasket,
  selectedProject: getSelectedProject(state),
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  roles: state.portfolio.portfolioData.roles,
});

const mapDispatchToProps = {
  checkIfUserCanAddProject,
  getPortfoliosData,
  searchProjects,
  push,
  changeSelectedPortfolioAndProject,
  showProjectRightPanel,
  hidePortfolioRightPanel,
  hideProjectRightPanel,
  getSystemTerminology,
  disableUnsavedChanges,
  disableUnsavedChangesModal,
  emptyCustomQuestionBasket,
  getProjectDetailsAction,
  saveSegmentGroupDashboard,
  projectAdhocDropDate,
  showHideOverlay,
};
const cryptr = new Cryptr(__CONFIG__.encryptSecret);

export class LeftPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialLoad: true,
      isAnyUnsavedChanges: false,
      showDeleteModal: false,
      projectId: 0,
      portfolioId: 0,
      portfolioModel: {},
    };
    this.searchProject = this.searchProject.bind(this);
    this.navigate = this.navigate.bind(this);
    this.showProjectRightPanel = this.showProjectRightPanel.bind(this);
    this.hideProjectAndPortfolioRightPanel = this.hideProjectAndPortfolioRightPanel.bind(this);
    this.canUserAddProject = this.canUserAddProject.bind(this);
  }

  componentDidMount() {
    this.props.getPortfoliosData();
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.props.getSystemTerminology();

    this.props.checkIfUserCanAddProject();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ isAnyUnsavedChanges: nextProps.unsavedChanges });
  }

  changeSelectedPortfolio(portfolio) {
    this.props.saveSegmentGroupDashboard({
      segmentGroupDashboardData: {
        segmentGroupData1: null,
        segmentGroupData2: null,
        segmentGroupData3: null,
        segmentGroupData4: null,
      },
    });
    const adhocData = {
      nextFullAdhocDropDate: '',
      AdhocDropOffset: '',
      projectDropTimezone: '',
      isNextAdhocDropScheduled: false,
      isProjectAdhoc: true,
    };
    this.props.projectAdhocDropDate(adhocData);
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.customQuestionBasket.customQuestions.length > 0
      || this.props.customQuestionBasket.automatedQuestions.length > 0) {
      this.setState({
        showDeleteModal: true,
        projectId: 0,
        portfolioModel: portfolio,
      });
    } else if (!this.state.isAnyUnsavedChanges) {
      if (this.props.isSysAdmin || (portfolio.PortfolioAdmin || portfolio.PortfolioStakeholder)) {
        this.props.changeSelectedPortfolioAndProject(portfolio.PortfolioId, null);
        this.hideProjectAndPortfolioRightPanel();
        this.navigate(`/portfolio-dashboard/${cryptr.encrypt(portfolio.PortfolioId)}`);
      } else if (portfolio.items.length > 0) {
        const projectId = portfolio.items[0].ProjectId;
        this.changeProject(projectId, portfolio.PortfolioId);
      } else {
        this.props.changeSelectedPortfolioAndProject(portfolio.PortfolioId, null);
        this.hideProjectAndPortfolioRightPanel();
        this.navigate(`/portfolio-dashboard/${cryptr.encrypt(portfolio.PortfolioId)}`);
      }
      this.setState({ initialLoad: false });
    }
  }

  componentDidUpdate() {
    // set toggle icon after rendering
    const openPortfolios = $('.show.project-list');
    for (let i = 0; i < openPortfolios.length; i += 1) {
      $(`.${openPortfolios[i].id}`).removeClass('collapsed');
    }
    this.expandSelectedPortfolio();
  }

  expandSelectedPortfolio() {
    const { selectedPortfolio, fromPortfolioDashboard } = this.props.portfolioData;
    const { initialLoad } = this.state;
    if (initialLoad || fromPortfolioDashboard) {
      $(`.portfolio${selectedPortfolio} .portfolio-title`).removeClass('collapsed');
      $(`.portfolio${selectedPortfolio} .project-list`).addClass('show');
    }
  }

  changeProject(selectedProject, selectedPortfolio) {
    this.props.saveSegmentGroupDashboard({
      segmentGroupDashboardData: {
        segmentGroupData1: null,
        segmentGroupData2: null,
        segmentGroupData3: null,
        segmentGroupData4: null,
      },
    });
    const adhocData = {
      nextFullAdhocDropDate: '',
      AdhocDropOffset: '',
      projectDropTimezone: '',
      isNextAdhocDropScheduled: false,
      isProjectAdhoc: true,
    };
    this.props.projectAdhocDropDate(adhocData);
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.customQuestionBasket.customQuestions.length > 0
      || this.props.customQuestionBasket.automatedQuestions.length > 0) {
      this.setState({
        showDeleteModal: true,
        projectId: selectedProject,
        portfolioId: selectedPortfolio,
      });
    } else if (!this.state.isAnyUnsavedChanges
      && (this.props.customQuestionBasket.customQuestions.length === 0
        || this.props.customQuestionBasket.automatedQuestions.length > 0)) {
      this.props.changeSelectedPortfolioAndProject(selectedPortfolio, selectedProject);
      this.hideProjectAndPortfolioRightPanel();
      this.navigate(`/project-dashboard/${cryptr.encrypt(selectedPortfolio)}/${cryptr.encrypt(selectedProject)}`);
      this.emptySearchBox();
      this.props.getProjectDetailsAction(selectedProject, true);
    }
  }


  emptySearchBox() {
    $('#searchProject').val('');
    this.searchProject('');
  }

  navigate(path) {
    const { location } = this.props;
    if (location.pathname !== path) this.props.push(path);
  }

  searchProject(value) {
    this.props.searchProjects(value).then(() => {
      if (value !== '') {
        $('.portfolio-title').removeClass('collapsed');
        $('.project-list').addClass('show');
      }
    });
  }

  showProjectRightPanel() {
    setTimeout(() => {
      this.props.showProjectRightPanel();
      this.props.showHideOverlay(true);
    }, 300);
  }

  hideProjectAndPortfolioRightPanel() {
    this.props.hidePortfolioRightPanel();
    this.props.hideProjectRightPanel();
  }

  canUserAddProject() {
    const { canAddProject } = this.props.portfolioData;
    let role = '';
    if (canAddProject) {
      role = 'portfolioAdministrator';
    }
    return role;
  }

  deleteQuestionBasket() {
    return new Promise((resolve) => {
      this.props.emptyCustomQuestionBasket();
      setTimeout(() => {
        this.setState({ showDeleteModal: false });
        removePendingCustomQuestionData();
        if (this.state.projectId > 0) {
          this.changeProject(this.state.projectId, this.state.portfolioId);
        } else this.changeSelectedPortfolio(this.state.portfolioModel);
      }, 100);
      resolve(true);
    });
  }

  render() {
    const {
      selectedPortfolio,
      selectedProjectId,
      portfoliosData,
      portfoliosDataContainer,
    } = this.props.portfolioData;
    const {
      isSysAdmin, systemTerminology, selectedProject, showProcessingRecords,
      roles,
    } = this.props;
    const projectReplace = levelTwoOrganizations.find(o => o.value === systemTerminology.level_two);
    const projectReplaceText = projectReplace && projectReplace.text;
    const removeActivePortfoliosData = [...portfoliosDataContainer];
    const filterOutHidePortfolio = portfoliosData
      .filter(port => port.show_portfolio);

    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;


    return (
      <div className="d-flex">
        <RenderIf showComponent={this.state.showDeleteModal}>
          <DeleteConfirmation
            title='Delete your pending questions?'
            message={['Are you sure you want to ', <span className="deletemessage-color preview-pane-font">permanently delete</span>, ` the pending questions for ${selectedProject.ProjectTitle} ?`]}
            confirmationClick={() => this.deleteQuestionBasket()}
            cancelClick={() => this.setState({ showDeleteModal: false })}
          />
        </RenderIf>
        <div className="text-left a-bg-gray d-flex">
          <div className="a-navigation">
            <Authorise role={isSysAdmin ? 'systemAdministrator' : getApplicableRole(roles)}
              perform="portfolio:manage-portfolio"
              permit={() => <PortfolioSetting
                removeActivePortfoliosData={removeActivePortfoliosData}
                selectedPortfolio={selectedPortfolio}
                actualPortfolio={portfoliosDataContainer}
                portfolioReplaceText={portfolioReplaceText}
              />
              }
            />
            <div className="d-inline-block text-icon">
              <i className="appkiticon icon-search-outline" />
              <input
                disabled={showProcessingRecords}
                id="searchProject"
                className="a-text-input"
                onChange={e => this.searchProject(e.target.value)}
                placeholder='Search...'
              />{' '}
            </div>
            <div className="a-accordion-wrapper icon-right">
              <ul className="a-list">
                {filterOutHidePortfolio.length > 0
                  && filterOutHidePortfolio.map((portfolio, index) => (
                    <li className={`portfolio${portfolio.PortfolioId}`} key={portfolio.PortfolioId}>
                      <div className="a-list-control d-flex align-items-center justify-content-between">
                        <div>
                          <div
                            aria-expanded="false"
                            data-bs-toggle="collapse"
                            href={`#collapsed${index}`}
                            className={`a-h6 portfolio-title collapsed collapsed${index}
                              ${parseInt(selectedPortfolio, 10) === portfolio.PortfolioId ? ' active ' : ' '}
                              ${!portfolio.items[0].protfolioActive && 'in-badge'} `}
                            onClick={() => {
                              this.changeSelectedPortfolio(portfolio);
                            }}
                          >
                            <i className="appkiticon icon-down-chevron-fill  a-control-icon" />
                            <span className="p-title">
                              {portfolio.items[0].PortfolioTitle}
                            </span>

                            {!portfolio.items[0].protfolioActive && <span className="in-active-badge">inactive</span>}

                          </div>
                        </div>
                      </div>
                      <ul
                        className={'project-list collapse'}
                        id={`collapsed${index}`}
                      >
                        {!this.props.isSearchingOn
                          && (isSysAdmin || (!isSysAdmin
                            && (portfolio.PortfolioAdmin || portfolio.PortfolioStakeholder))) && (
                            <li className="a-list-item d-flex align-items-center justify-content-between">
                              <div
                                onClick={() => {
                                  this.changeSelectedPortfolio(portfolio);
                                }}
                                className={`cursor-pointer a-font-md ${selectedPortfolio === portfolio.PortfolioId && !selectedProjectId
                                  ? 'active'
                                  : ''
                                  }`}
                              >
                                All {projectReplaceText}s
                              </div>
                            </li>
                          )}

                        {portfolio.items.length > 0
                          && portfolio.items.map(
                            project => project.ProjectId && (
                              <li
                                key={project.ProjectId}
                                className="a-list-item d-flex align-items-center justify-content-between"
                              >
                                <div
                                  onClick={this.changeProject.bind(
                                    this,
                                    project.ProjectId,
                                    portfolio.PortfolioId,
                                  )}
                                  className={`cursor-pointer a-font-md ${selectedProjectId === project.ProjectId ? 'active' : ''
                                    }
                                    ${!project.projectActive && 'in-badge'}
                                    `}
                                >
                                  <span className="p-title">{project.ProjectTitle}</span>
                                  {!project.projectActive && <span className="in-active-badge">inactive</span>}

                                </div>
                              </li>
                            ),
                          )}
                      </ul>
                    </li>
                  ))}

                {filterOutHidePortfolio.length <= 0 && <div>No projects found</div>}
              </ul>
            </div>
            <div className="a-footer">
              <Authorise role={isSysAdmin ? 'systemAdministrator' : this.canUserAddProject()}
                perform="project:create"
                permit={() => (<a className="add-project-button mb-5"
                  href="javascript:void(0)" onClick={this.showProjectRightPanel}>
                  <i className="appkiticon icon-circle-plus-outline mr-2" /> Add a new {projectReplaceText}
                </a>
                )} />
              <div className="mt-4">
                <a href="/terms-of-use" target="_blank">Terms of Use</a>
              </div>
              <div>
                <a href="/information-collection-statement" target="_blank">Information Collection Statement</a>
              </div>
              <div className="copyright-text mt-3">
                © {constant.currentYear} PwC. All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftPanel);
