import actionType from '../../../../redux/modules/settings/actionType';
import settingsService from '../../../../redux/services/settingsService';

// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

export const getArchiveQuestionModelList = (filters, paginatedData) => dispatch => settingsService
  .getArchiveQuestionModelList(filters, paginatedData)
  .then((res) => {
    const archiveModels = {
      archiveModelList: [],
      count: 0,
    };
    archiveModels.count = res.data.count;
    res.data.archiveModels.map((item) => {
      const data = item;
      data.isChecked = false;
      archiveModels.archiveModelList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_ARCHIVED_QUESTION_MODEL_LIST, archiveModels });
  })
  .catch(() => {
  });

export const getArchiveOrganisationList = (filters, paginatedData) => dispatch => settingsService
  .getArchiveOrganisationList(filters, paginatedData)
  .then((res) => {
    const archiveOrganisations = {
      archiveOrganisationList: [],
      count: 0,
    };
    archiveOrganisations.count = res.data.count;
    res.data.archiveOrganisations.map((item) => {
      const data = item;
      data.isChecked = false;
      archiveOrganisations.archiveOrganisationList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_ARCHIVED_ORGANISATION_LIST, archiveOrganisations });
  })
  .catch(() => {
  });

export const getArchiveTopicList = (filters, paginatedData) => dispatch => settingsService
  .getArchiveTopicList(filters, paginatedData)
  .then((res) => {
    const archiveTopics = {
      archiveTopicList: [],
      count: 0,
    };
    archiveTopics.count = res.data.count;
    res.data.archiveTopics.map((item) => {
      const data = item;
      data.isChecked = false;
      archiveTopics.archiveTopicList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_ARCHIVED_TOPIC_LIST, archiveTopics });
  })
  .catch(() => {
  });

export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_MODEL_CHECKBOXES });
};

export const toggleAllOrgCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_ORG_CHECKBOXES });
};

export const toggleAllTopicCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_TOPIC_CHECKBOXES });
};


export const toggleCheckbox = user => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_MODEL_CHECKBOX, user });
};

export const toggleOrgCheckbox = user => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ORG_CHECKBOX, user });
};

export const toggleTopicCheckbox = user => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_TOPIC_CHECKBOX, user });
};

export const restoreArchiveQuestionModel = data => settingsService
  .restoreArchiveQuestionModel(data)
  .then(res => res.data)
  .catch(error => error);

export const restoreArchiveOrg = data => settingsService
  .restoreArchiveOrg(data)
  .then(res => res.data)
  .catch(error => error);

export const restoreArchiveTopic = data => settingsService
  .restoreArchiveTopic(data)
  .then(res => res.data)
  .catch(error => error);
