import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '@pwc/appkit-react/style/appkit-react.default.css';
import $ from 'jquery';
import '../styles/Settings.scss';
import SettingsHeader from '../../../ui-components/Header/SettingsHeader';
import SettingsLeftPanel from '../../../ui-components/LeftPanel/SettingsLeftPanel';
import SettingsContent from '../../../ui-components/Content/SettingsContent';

window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Home',
      animate: false,
    };
  }

  render() {
    return (
      <div>
        <SettingsHeader />
        <SettingsLeftPanel />
        <SettingsContent state={this.state} />
      </div>
    );
  }
}

export default Settings;
