import React, { Component } from 'react';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';
import MaterialIcon from 'material-icons-react';
import { uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { getPortfolio, getAllQuestionModels } from '../selectors/portfolio';
import SumoSelect from '../../../ui-components/SumoSelect/SumoSelect';
import { SpinnerButton, Spinner } from '../../../ui-components/Loader';
import DeleteConfirmation from '../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import RenderIf from '../../../ui-components/Common';
import constant from '../actions/constant';
import {
  validateUserByEmail,
  createNewUser,
} from '../../account/actions/iam';
import {
  savePortfolio,
  checkPortfolioTitle,
  getPortFolioDetails,
  isProjectAssociatedWithPortfolio,
  deletePortfolio,
  getPortfoliosData,
  hidePortfolioRightPanel,
  showProjectRightPanel,
  changeSelectedPortfolioAndProject,
  getPortfolioQuestionModelList,
  setSelectedQuestionModelId,
  checkQuestionModelExistInProject,
} from '../actions/portfolio';
import { RegisterEmail } from '../../../ui-components/Account';
import authService from '../../../redux/services/authService';
import Authorise from '../../../Authorise';

import { getSystemTerminology } from '../../settings/question-model/actions/general';
import { getSystemTerminologySelector } from '../../settings/question-model/selectors/general';
import { levelOneOrganizations } from '../../settings/question-model/actions/levelconstants';
import {
  getQuestionModels,
} from '../../settings/question-model/actions/questionModel';

import {
  disableUnsavedChangesModal, disableUnsavedChanges,
  enableUnsavedChanges, enableUnsavedChangesModal,
} from '../../general/actions/general';
import UnsavedChangesConfirmation from '../../../ui-components/UnsavedChangesConfirmation/UnsavedChangesConfirmation.jsx';
import chunkedPromises from '../../../utils/utils';
import {
  showRecordProcessing,
  hideRecordProcessing,
} from '../../project/actions/project';
import { showHideOverlay, updateUnsavedChanges } from '../../common/overlay/actions/overlay';

function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isPortfolioExist(values, actions, props) {
  return new Promise((resolve) => {
    const { systemTerminology } = props;
    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;
    const errorMessage = `${portfolioReplaceText} with the same name already exists`;
    if (values) {
      checkPortfolioTitle(values.name.trim().replace(/  +/g, ' '), values.id).then((val) => {
        if (val) {
          setError(actions, 'name', errorMessage);
          resolve(true);
        }
        resolve(false);
      });
    } else {
      resolve(false);
    }
  });
}

function isQuestionModelExistInProject(values, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'This question set cannot be removed from this organisation as it is associated to one or more active topics.';

    const questionModeldata = {
      questionModelIds: values.selectedQuestionSets,
      PortfolioId: values.id,
    };
    if (values) {
      checkQuestionModelExistInProject(questionModeldata).then((val) => {
        if (val) {
          setError(actions, 'selectedQuestionSets', errorMessage);
          resolve(true);
        }
        resolve(false);
      });
    } else {
      resolve(false);
    }
  });
}

const mapStateToProps = state => ({
  portfolioData: getPortfolio(state),
  portfolioRightPanel: state.portfolio.portfolioData.portfolioRightPanel,
  isSysAdmin: state.login.isSysAdmin,
  roles: state.portfolio.portfolioData.roles,
  systemTerminology: getSystemTerminologySelector(state),
  unsavedChangesModal: state.general.unsavedChangesModal,
  unsavedChanges: state.general.unsavedChanges,
  questionModels: getAllQuestionModels(state),
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  isProcessingRecords: state.processingReducer.isProcessingRecords,
  passedRecords: state.processingReducer.passedRecords,
  failedRecords: state.processingReducer.failedRecords,
});

const mapDispatchToProps = {
  savePortfolio,
  checkPortfolioTitle,
  checkQuestionModelExistInProject,
  getPortFolioDetails,
  isProjectAssociatedWithPortfolio,
  deletePortfolio,
  getPortfoliosData,
  hidePortfolioRightPanel,
  showProjectRightPanel,
  changeSelectedPortfolioAndProject,
  validateUserByEmail,
  createNewUser,
  getSystemTerminology,
  disableUnsavedChangesModal,
  disableUnsavedChanges,
  enableUnsavedChanges,
  enableUnsavedChangesModal,
  getPortfolioQuestionModelList,
  setSelectedQuestionModelId,
  showRecordProcessing,
  hideRecordProcessing,
  getQuestionModels,
  showHideOverlay,
  updateUnsavedChanges,
};

class AddPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      portfolioId: 0,
      id: 0,
      name: '',
      ref_code: '',
      portfolioAdmins: [],
      adminsToBeRemoved: [],
      isActive: true,
      isLoading: true,
      isSubmitting: false,
      selectedIndustries: [],
      selectedQuestionSets: [],
      showRegisterPortfolioAdministratorModel: false,
      RegisteringUserEmail: '',
      showRegisterPortfolioAdministratorStep2Model: false,
      showAdministratorDelete: false,
      showUnsavedChangesModal: false,
      selectedAdministratorEmailIdForDelete: '',
      deleteAdministratorTitle: 'Remove #portfolioReplaceText# Administrator',
      deleteAdministratorConfirmationMessage: `Are you sure you want to remove this user 
        from #portfolioReplaceText# Administrators?`,
      admins: [],
      totalEmails: 0,
      isRestrictedUser: false,
    };
    this.modalState = {
      title: 'Archive #portfolioReplaceText#',
      showDeleteModal: false,
      message: '',
      isProjectAssociated: false,
    };
    this.validateAndSavePortfolio = this.validateAndSavePortfolio.bind(this);
    this.hidePortfolioRightPanel = this.hidePortfolioRightPanel.bind(this);
    this.confirmAdministratorDelete = this.confirmAdministratorDelete.bind(this);
    this.cancelAdministratorDelete = this.cancelAdministratorDelete.bind(this);
    this.removePortfolioAdministrator = this.removePortfolioAdministrator.bind(this);
    this.getApplicableRole = this.getApplicableRole.bind(this);
    this.toggleUnsavedChanges = this.toggleUnsavedChanges.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }

  componentDidMount() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    this.props.getSystemTerminology();
    this.props.getQuestionModels();
    const { portfolioRightPanel, portfolioData } = this.props;
    const userInfo = authService.getUserInfo();
    if (portfolioRightPanel.isInEditMode) {
      getPortFolioDetails({
        portfolioId: portfolioData.selectedPortfolio,
      }).then((res) => {
        this.setState({
          id: res.id,
          name: res.title,
          ref_code: res.ref_code,
          isActive: res.is_active,
          isLoading: false,
          selectedIndustries: res.industries,
          selectedQuestionSets: res.selectedQuestionSets,
          portfolioAdmins: res.portfolioAdmins,
          isRestrictedUser: res.is_restricted_user,
        });
      });
    } else {
      const admin = {
        first_name: userInfo.firstName,
        last_name: userInfo.lastName,
        email_address: userInfo.email,
        canDelete: false,
      };
      this.setState({
        isLoading: false,
        portfolioAdmins: [admin],
      });
    }
  }

  toggleUnsavedChanges() {
    if (!this.props.unsavedChanges) {
      this.props.enableUnsavedChanges();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unsavedChanges && nextProps.unsavedChangesModal && !this.state.isSubmitting) {
      this.setState({ showUnsavedChangesModal: true });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  retainUnsavedChanges() {
    this.props.disableUnsavedChangesModal();
    this.setState({ showUnsavedChangesModal: false });
  }

  cancelUnsavedChanges() {
    this.props.hidePortfolioRightPanel();
  }

  portfolioAdminsCount(adminsToBeRemoved, admins) {
    if (admins.length > 0) {
      this.setState({ totalEmails: admins.length - adminsToBeRemoved.length });
    } else {
      this.setState({ totalEmails: 0 });
    }
  }

  validateAndSavePortfolio(values, actions) {
    if (this.props.showProcessingRecords) {
      return;
    }

    const { adminsToBeRemoved, admins } = this.state;
    this.portfolioAdminsCount(adminsToBeRemoved, admins);
    const self = this;
    self.showSpinner(true);
    values.portfolioId = values.id;
    const uniqPortfolioAdmins = uniqBy(values.portfolioAdmins, 'email_address');
    isPortfolioExist(values, actions, this.props).then(async (isExist) => {
      if (isExist) {
        self.showSpinner(false);
        return;
      }
      if (values.portfolioId !== 0) {
        isQuestionModelExistInProject(
          values,
          actions,
          this.props,
        ).then(async (isQuestionModelExist) => {
          if (isQuestionModelExist) {
            self.showSpinner(false);
            return;
          }
          values.name = values.name.trim().replace(/  +/g, ' ');
          const selectedQuestionModelId = values.selectedQuestionSets.length === 1
            ? values.selectedQuestionSets[0] : null;
          self.setState({ isSubmitted: true });
          this.props.showRecordProcessing(0, 0);
          const newlyAddedAdmins = admins.length > 0 ? admins : uniqPortfolioAdmins;
          if (newlyAddedAdmins.length === 0) {
            values.adminsToBeAdded = [];
            values.adminsToBeRemoved = [];
            await this.props.savePortfolio(values).then(() => {
              this.props.disableUnsavedChanges();
              this.props.disableUnsavedChangesModal();
              self.showSpinner(false);
              if (values.portfolioId === 0) {
                self.props.getPortfolioQuestionModelList(this.props.portfolioData.newPortfolioId);
              }
              self.props.setSelectedQuestionModelId(selectedQuestionModelId);
              self.props.getPortfoliosData();
              this.props.hideRecordProcessing();
              self.setState({ isSubmitted: false });
              this.hidePortfolioRightPanel();
            });
          } else {
            await chunkedPromises(newlyAddedAdmins, 10,
              async (c) => {
                values.adminsToBeAdded = c;
                values.adminsToBeRemoved = adminsToBeRemoved;
                await this.props.savePortfolio(values).then((res) => {
                  const newAddedUsersCount = admins.length > 0 ? c.length : 0;
                  this.props.showRecordProcessing(newAddedUsersCount, res);
                });
                return c;
              }).then(() => {
                this.props.disableUnsavedChanges();
                this.props.disableUnsavedChangesModal();
                self.showSpinner(false);
                if (values.portfolioId === 0) {
                  self.props.getPortfolioQuestionModelList(this.props.portfolioData.newPortfolioId);
                }
                self.props.setSelectedQuestionModelId(selectedQuestionModelId);
                self.props.getPortfoliosData();
                this.props.hideRecordProcessing();
                self.setState({ isSubmitted: false });
                this.hidePortfolioRightPanel();
              });
          }
        });
      } else {
        values.name = values.name.trim().replace(/  +/g, ' ');
        const selectedQuestionModelId = values.selectedQuestionSets.length === 1
          ? values.selectedQuestionSets[0] : null;
        this.props.showRecordProcessing(0, 0);
        self.setState({ isSubmitted: true });
        await chunkedPromises(uniqPortfolioAdmins, 10,
          async (c) => {
            values.adminsToBeAdded = c;
            values.adminsToBeRemoved = adminsToBeRemoved;
            await this.props.savePortfolio(values).then((res) => {
              values.id = res.portfolioId;
              this.props.showRecordProcessing(c.length, res.failedRecords);
            });
          }).then(() => {
            this.props.disableUnsavedChanges();
            this.props.disableUnsavedChangesModal();
            self.showSpinner(false);
            if (values.portfolioId === 0) {
              self.props.getPortfolioQuestionModelList(this.props.portfolioData.newPortfolioId);
            }
            self.props.setSelectedQuestionModelId(selectedQuestionModelId);
            self.props.getPortfoliosData();
            this.props.hideRecordProcessing();
            self.setState({ isSubmitted: false });
            this.hidePortfolioRightPanel();
          });
      }
    });
  }

  deleteModal() {
    const self = this;
    this.props.isProjectAssociatedWithPortfolio({ id: this.state.id }).then((resp) => {
      self.modalState.isProjectAssociated = resp;
      self.modalState.showDeleteModal = true;
      self.modalState.message = self.modalState.isProjectAssociated
        ? 'This #portfolioReplaceText# has one or more associated projects and it cannot be archived.'
        : 'This #portfolioReplaceText# will be permanently archived. Are you sure you want to continue?';
      self.setState({});
    });
  }

  deletePortfolio() {
    this.props.disableUnsavedChangesModal();
    this.props.disableUnsavedChanges();
    const self = this;
    return this.props.deletePortfolio({ id: this.state.id }).then((resp) => {
      if (resp) {
        self.props.getPortfoliosData(true).then((response) => {
          if (response.data && response.data.length) {
            this.props.changeSelectedPortfolioAndProject(response.data[0].PortfolioId, null);
          }
        });
        this.props.showHideOverlay(false);
        this.hidePortfolioRightPanel();
      }
      return resp;
    });
  }

  hidePortfolioRightPanel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.props.unsavedChanges && !this.props.unsavedChangesModal) {
      this.props.enableUnsavedChangesModal();
      this.setState({ showUnsavedChangesModal: true });
    } else if (!this.props.unsavedChangesModal) {
      const { portfolioRightPanel } = this.props;
      this.props.hidePortfolioRightPanel();
      if (!portfolioRightPanel.isInEditMode) {
        this.props.showHideOverlay(true);
        this.props.showProjectRightPanel();
      }
    }
  }

  cancelDelete() {
    this.modalState.showDeleteModal = false;
    this.setState({});
  }

  showSpinner(value) {
    this.setState({ isSubmitting: value });
  }

  openRegisterPortfolioAdministratorModel() {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.setState({ showRegisterPortfolioAdministratorModel: true });
  }

  async registerPortfolioAdministrator(emails) {
    this.toggleUnsavedChanges();
    const formattedEmails = emails.map(email => email.toLowerCase());
    const r = await chunkedPromises(formattedEmails, 10,
      (c) => {
        this.props.showRecordProcessing(0, 0);
        return this.props.validateUserByEmail({ emails: c }).then((res) => {
          const { data } = res;
          const result = data.filter((item) => {
            if (!item.errorMessage) {
              this.pushNewAdminToList(item);
            }
            return item.errorMessage;
          });
          const passedRecords = (data.length - result.length);
          const failedRecords = result.length;
          this.props.showRecordProcessing(passedRecords, failedRecords);
          return result;
        });
      });
    const response = [].concat(...r);
    return response;
  }

  pushNewAdminToList(userData) {
    const { portfolioAdmins, adminsToBeRemoved, admins } = this.state;
    const {
      id, FirstName, LastName, emailId, isRegistered,
    } = userData;
    const user = {
      id,
      first_name: FirstName,
      last_name: LastName,
      email_address: emailId,
      is_user_registered: isRegistered,
      canDelete: true,
    };
    const filteredAdmins = adminsToBeRemoved.filter(i => i.id !== user.id);
    admins.push(user);
    portfolioAdmins.push(user);
    this.setState({
      portfolioAdmins,
      adminsToBeRemoved: filteredAdmins,
      admins,
    });
  }

  registerPortfolioAdministratorStep2(formData) {
    const userModel = {
      email: this.state.RegisteringUserEmail,
      firstName: formData.firstName,
      lastName: formData.lastName,
      portfolioId: this.state.id,
      organisation: 'external', // This was required field So adding data as mentioned in wiki
      territory: 'AU', // This was required field So adding data as mentioned in wiki
      assuranceLevel: '', // This was required field So adding data as mentioned in wiki
    };


    return this.props.createNewUser(userModel).then((response) => {
      const { status, userData } = response.data;
      if (status === constant.Success || status === constant.UserAlreadyOnboarded) {
        this.pushNewAdminToList(userData);
      }
      return response;
    });
  }

  // Confirm Delete
  confirmAdministratorDelete(emailId) {
    if (this.props.showProcessingRecords) {
      return;
    }
    if (this.state.portfolioAdmins.length > 1) {
      this.setState({
        selectedAdministratorEmailIdForDelete: emailId,
        showAdministratorDelete: true,
      });
    }
  }

  cancelAdministratorDelete() {
    this.setState({
      selectedAdministratorEmailIdForDelete: '',
      showAdministratorDelete: false,
    });
  }

  // Delete Project Life Cycle Stages
  removePortfolioAdministrator() {
    this.toggleUnsavedChanges();
    const that = this;
    return new Promise(((resolve) => {
      let index = 0;
      let adminsTobeAddedIndex = 0;
      that.state.portfolioAdmins.map((item, idx) => {
        if (item.email_address === that.state.selectedAdministratorEmailIdForDelete) {
          index = idx;
        }
        return item;
      });
      const { portfolioAdmins, adminsToBeRemoved, admins } = that.state;
      if (admins.length > 0) {
        admins.map((item, idx) => {
          if (item.email_address === that.state.selectedAdministratorEmailIdForDelete) {
            adminsTobeAddedIndex = idx;
          }
          return item;
        });
        admins.splice(adminsTobeAddedIndex, 1);
      }
      adminsToBeRemoved.push(portfolioAdmins[index]);
      portfolioAdmins.splice(index, 1);
      that.setState({
        portfolioAdmins,
        adminsToBeRemoved,
      });
      resolve(true);
    }));
  }

  getApplicableRole() {
    const { roles } = this.props;
    let role = '';
    if (roles) {
      if (roles.portfolioAdministrator) role = 'portfolioAdministrator';
      else if (roles.portfolioStakeholder) {
        role = 'portfolioStakeholder';
      }
    }
    return role;
  }

  setFieldValueOnBlur(field, e, setFieldValue) {
    this.toggleUnsavedChanges();
    setFieldValue(field, e.target.value);
  }

  changeRestrictedUserSetting(values, setFieldValue) {
    if (this.props.showProcessingRecords) {
      return;
    }
    this.toggleUnsavedChanges();
    const formData = { ...values };
    formData.isRestrictedUser = !values.isRestrictedUser;
    setFieldValue('isRestrictedUser', formData.isRestrictedUser);
  }

  handleFormOnChange = () => {
    this.props.updateUnsavedChanges(true);
  };

  render() {
    const {
      isLoading,
      showRegisterPortfolioAdministratorModel,
      portfolioAdmins,
      showAdministratorDelete,
      deleteAdministratorConfirmationMessage,
      deleteAdministratorTitle,
      showUnsavedChangesModal,
      name,
      isSubmitted,
      totalEmails,
    } = this.state;
    const {
      portfolioRightPanel, isSysAdmin, systemTerminology, questionModels,
      showProcessingRecords, isProcessingRecords, passedRecords, failedRecords,
    } = this.props;
    const portfolioReplace = levelOneOrganizations.find(
      o => o.value === systemTerminology.level_one,
    );
    const portfolioReplaceText = portfolioReplace && portfolioReplace.text;

    const newDeleteAdministratorTitle = deleteAdministratorTitle
      .replace(
        /#portfolioReplaceText#/g, portfolioReplaceText,
      );
    const newDeleteAdministratorConfirmationMessage = deleteAdministratorConfirmationMessage
      .replace(
        /#portfolioReplaceText#/g, portfolioReplaceText,
      );

    const { title, message, placeholder } = constant.registerEmailAdministratorModel;
    const replaceWithPrefix = text => text.replace(/#portfolioReplaceTextWithPreFix#/g,
      ('aeiou'.indexOf(portfolioReplaceText[0].toLowerCase()) !== -1 ? 'an ' : 'a ')
      + portfolioReplaceText);
    const newTitle = replaceWithPrefix(title);
    let newMessage = replaceWithPrefix(message);
    newMessage = newMessage.replace(/#portfolioNameReplaceText#/g, name);
    newMessage = `${newMessage[0].toUpperCase()}${newMessage.slice(1)}`;

    const newModalStateTitle = this.modalState.title.replace(/#portfolioReplaceText#/g, portfolioReplaceText);
    const newModalStateMessage = this.modalState.message.replace(/#portfolioReplaceText#/g, portfolioReplaceText);

    const self = this;
    const industries = [
      { text: 'Agribusiness', value: 'Agribusiness' },
      { text: 'Asset & Wealth Management', value: 'Asset & Wealth Management' },
      { text: 'Banking and Capital Markets', value: 'Banking and Capital Markets' },
      { text: 'Construction & Transportation', value: 'Construction & Transportation' },
      { text: 'Defence', value: 'Defence' },
      { text: 'Education', value: 'Education' },
      { text: 'Energy (Oil & Gas)', value: 'Energy (Oil & Gas)' },
      { text: 'Entertainment & Media', value: 'Entertainment & Media' },
      { text: 'Financial Services', value: 'Financial Services' },
      { text: 'Government', value: 'Government' },
      { text: 'Healthcare', value: 'Healthcare' },
      { text: 'Infrastructure', value: 'Infrastructure' },
      { text: 'Insurance', value: 'Insurance' },
      { text: 'Mining', value: 'Mining' },
      { text: 'Power & Utilities', value: 'Power & Utilities' },
      { text: 'Real Estate', value: 'Real Estate' },
      { text: 'Retail & Consumer', value: 'Retail & Consumer' },
      { text: 'Superannuation', value: 'Superannuation' },
      { text: 'Technology', value: 'Technology' },
    ];

    return (
      <React.Fragment>
        <RenderIf showComponent={showUnsavedChangesModal}>
          <UnsavedChangesConfirmation
            confirmationClick={this.cancelUnsavedChanges.bind(this)}
            cancelClick={this.retainUnsavedChanges.bind(this)}
          />
        </RenderIf>
        {this.modalState.showDeleteModal && (
          <div>
            <DeleteConfirmation
              title={newModalStateTitle}
              message={newModalStateMessage}
              hideActions={this.modalState.isProjectAssociated}
              confirmationClick={this.deletePortfolio.bind(this)}
              cancelClick={this.cancelDelete.bind(this)}
              archiveclick={true}
            />
          </div>
        )}

        {showAdministratorDelete && (
          <div>
            <DeleteConfirmation
              title={newDeleteAdministratorTitle}
              message={newDeleteAdministratorConfirmationMessage}
              hideActions={false}
              confirmationClick={this.removePortfolioAdministrator.bind(this)}
              cancelClick={this.cancelAdministratorDelete.bind(this)}
            />
          </div>
        )}

        <RenderIf showComponent={showRegisterPortfolioAdministratorModel}>
          <RegisterEmail
            title={newTitle}
            message={newMessage}
            placeholder={placeholder}
            availableEmails={portfolioAdmins.map(item => item.email_address)}
            onContinueButtonClick={this.registerPortfolioAdministrator.bind(this)}
            cancelClick={() => this.setState({ showRegisterPortfolioAdministratorModel: false })}
            isRestrictedUser={this.state.isRestrictedUser}
            isStakeholders={false}
            isTestEmail={false}
          />
        </RenderIf>

        <div className="right-sidebar add-stakeholder-sidebar" ref={this.setWrapperRef}>
          <Spinner isLoading={isLoading} />
          <RenderIf showComponent={!isLoading}>
            <div className="r-s-header">
              <div className="r-s-title">
                <span>
                  {portfolioRightPanel.isInEditMode
                    ? `${portfolioReplaceText} Settings`
                    : `Create a new ${portfolioReplaceText}`}
                  <a>
                    <i
                      className="appkiticon icon-close-outline"
                      onClick={this.hidePortfolioRightPanel}
                    />
                  </a>{' '}
                </span>
              </div>
              <div className="indicate_val">
                <span className="mandatory-icon">* </span>
                <span className="in_text preview-pane-font"> indicates required field</span>
              </div>
            </div>
            <div className="app">
              <Formik
                initialValues={this.state}
                validateOnBlur={true}
                onSubmit={(values, actions) => {
                  this.validateAndSavePortfolio(values, actions);
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string()
                    .trim()
                    .required(`${portfolioReplaceText} Name is required.`)
                    .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
                  selectedIndustries: Yup.array().min(1, 'At least one industry needs to be selected.'),
                  selectedQuestionSets: Yup.array().min(1, 'At least one question set needs to be selected.'),
                })}
              >
                {({
                  errors, touched, setFieldValue, values,
                }) => (
                  <Form onChange={this.handleFormOnChange}>
                    <div className="r-s-body mt-3">
                      <div className="form-group">
                        <div className="a-form-label">{portfolioReplaceText} Name<span className="mandatory-icon">*</span></div>
                        <Field
                          disabled={showProcessingRecords}
                          name="name"
                          autoFocus={!portfolioRightPanel.isInEditMode}
                          autoComplete="off"
                          className={
                            errors.name && touched.name ? 'error a-text-input' : 'a-text-input'
                          }
                          onChange={e => this.setFieldValueOnBlur('name', e, setFieldValue)}
                        />

                        <div className="error">
                          {' '}
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="a-form-label">Reference Code</div>
                        <Field name="ref_code" autoComplete="off" className="a-text-input"
                          onChange={e => this.setFieldValueOnBlur('ref_code', e, setFieldValue)}
                          disabled={showProcessingRecords}
                        />
                      </div>

                      {isSysAdmin && <div className="model-selector mt-3">
                        <p className="section-title m-0 mb-1">
                          Question Sets<span className="mandatory-icon">*</span>
                        </p>
                        <p className="a-font-sm m-0">
                          Select one or more questions sets that are relevant for
                          this {portfolioReplaceText}:
                        </p>
                        <div className="">
                          {questionModels.length > 0 && <SumoSelect
                            disabled={showProcessingRecords}
                            name="selectedQuestionSets"
                            placeholder="Select one or more question sets"
                            selectedValues={values.selectedQuestionSets}
                            multiple="multiple"
                            options={questionModels}
                            setFieldValue={setFieldValue}
                            captionFormatAllSelected="All Question Sets"
                            onSumoSelectChange={() => this.toggleUnsavedChanges()}
                          />}

                          <div className="error">
                            {' '}
                            <ErrorMessage name="selectedQuestionSets" />
                          </div>
                        </div>
                      </div>}
                      <div className="industry-selector mt-3">
                        <p className="section-title m-0 mb-1">
                          {portfolioReplaceText} Industries<span className="mandatory-icon">*</span>
                        </p>
                        <p className="a-font-sm m-0">
                          Select one or more industries within
                          which this {portfolioReplaceText} is
                          being delivered:
                        </p>
                        <div className="">
                          <SumoSelect
                            disabled={showProcessingRecords}
                            name="selectedIndustries"
                            placeholder="Select one or more industries"
                            selectedValues={values.selectedIndustries}
                            multiple="multiple"
                            options={industries}
                            setFieldValue={setFieldValue}
                            captionFormatAllSelected="All Industries"
                            onSumoSelectChange={() => this.toggleUnsavedChanges()}
                          />

                          <div className="error">
                            {' '}
                            <ErrorMessage name="selectedIndustries" />
                          </div>
                        </div>
                      </div>
                      <div className="portfolio-admins mt-4">
                        <p className="section-title m-0 mb-1">
                          {portfolioReplaceText} Administrators
                        </p>
                        <p className="a-font-sm m-0">
                          Specify the users who will have full,
                          unrestricted access to this {' '}
                          {portfolioReplaceText}. There must be at
                          least one {portfolioReplaceText} Administrator.
                        </p>
                        <div className="mt-3 mb-4">
                          <ul className="a-list cross-list portfolio-stakeholder">
                            {portfolioAdmins && portfolioAdmins.map((item, index) => (
                              <li className="a-list-item d-flex align-items-center" key={index}>
                                {item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.first_name} {item.last_name}</div>}
                                {!item.is_user_registered && <div className="a-font-md font-weight-medium stakeholder-name">{item.email_address}</div>}
                                {portfolioAdmins.length !== 1 && item.canDelete ? (
                                  <i className="appkiticon icon-close-fill a-font-xs" onClick={() => this.confirmAdministratorDelete(item.email_address)} />
                                ) : ''}
                                {item.id > 0 && item.is_user_registered !== undefined
                                  && !item.is_user_registered
                                  && <div className={'a-badge a-badge-secondary'}>Pending Registration</div>}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <Authorise role={isSysAdmin ? 'systemAdministrator' : this.getApplicableRole()}
                          perform="portfolio:add-administrator"
                          permit={() => (
                            <a className="add-admin-button mt-2 a-btn a-btn-transparent a-btn-lg a-btn-gray" href="javascript:void(0)"
                              onClick={this.openRegisterPortfolioAdministratorModel.bind(this)}>
                              <i className="appkiticon icon-plus-fill mr-2"> </i> Add {'aeiou'.indexOf(portfolioReplaceText[0].toLowerCase()) !== -1 ? 'an' : 'a'} {portfolioReplaceText} {' '}
                              Administrator{' '}
                            </a>
                          )} />
                        <div className="form-group mt-3">
                          <label className="a-toggle a-m-5 font-normal">
                            <input
                              disabled={showProcessingRecords || !isSysAdmin}
                              type="checkbox"
                              name="toggle1"
                              checked={values.isRestrictedUser}
                              onChange={() => {
                                values.isRestrictedUser = !values.isRestrictedUser;
                                self.setState({ isRestrictedUser: values.isRestrictedUser });
                              }}
                            />
                            <div className="a-toggle-mark">
                              <span className="on preview-pane-font">Yes</span>
                              <span className="off preview-pane-font">No</span>
                              <span className="switch preview-pane-font" />
                            </div>
                            <span className="a-toggle-text preview-pane-font">Prevent registration of user accounts</span>
                            <span class="pop-out-help mt-2 help-icon-align">
                              <MaterialIcon id='stakeHoldersCanView' data-for='stakeHoldersCanView' data-tip='dash_tooltip' icon="help"
                              />
                              <ReactTooltip id='stakeHoldersCanView' className='prevent_user_tooltip' place='top'
                                effect='solid'>
                                This will prevent external users from accessing
                                the dashboard or being able to log into the mobile app.
                              </ReactTooltip>
                            </span>
                          </label>
                        </div>

                      </div>

                      {portfolioRightPanel.isInEditMode && (
                        <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                          perform="portfolio:delete"
                          permit={() => (
                            <div className="form-group mt-4">
                              <label className="a-toggle a-m-5 font-normal">
                                <input
                                  disabled={showProcessingRecords}
                                  type="checkbox"
                                  name="toggle"
                                  checked={values.isActive}
                                  onChange={() => {
                                    values.isActive = !values.isActive;
                                    self.setState({});
                                  }}
                                />
                                <div className="a-toggle-mark">
                                  <span className="on preview-pane-font" />
                                  <span className="off preview-pane-font" />
                                  <span className="switch preview-pane-font" />
                                </div>
                                <span className="a-toggle-text preview-pane-font">Active</span>
                              </label>
                            </div>
                          )} />
                      )}
                    </div>
                    <div className="r-s-actions mt-4">
                      <div className="mt-3 mb-4">
                        {isProcessingRecords && isSubmitted && (
                          <span style={{
                            position: 'relative',
                            float: 'right',
                            fontSize: 10,
                            top: 10,
                            color: failedRecords > 0 ? 'red' : 'rgba(0, 0, 0, 0.6)',
                          }}>{passedRecords} of{' '}
                            {totalEmails}
                            {' '}users assigned successfully</span>)}
                        <SpinnerButton
                          isLoading={this.state.isSubmitting}
                          label={portfolioRightPanel.isInEditMode ? 'SAVE CHANGES' : 'CREATE'}
                        />
                      </div>
                      {portfolioRightPanel.isInEditMode && isSysAdmin
                        && <Authorise role={isSysAdmin ? 'systemAdministrator' : ''}
                          perform="portfolio:delete"
                          permit={() => (
                            <a href="javascript:void(0)" className="a-btn a-btn-transparent a-btn-lg a-btn-gray">
                              <span className='preview-pane-font' onClick={this.deleteModal.bind(this)}>
                                ARCHIVE {portfolioReplaceText.toUpperCase()}
                              </span>
                            </a>
                          )} />
                      }
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </RenderIf>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPortfolio);
