import React, { Component } from 'react';
import { connect } from 'react-redux';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {
  getOpenTextQuestions,
  toggleAllCheckboxes,
  toggleCheckbox,
  updateProjectOpenTextQuestions,
  getOpenTextQuestionsInEditMode,
  searchQuestions,
} from '../actions/project';
import { openTextQuestionSelector } from '../selectors/project';
import SkeltonLoadingDropDown from '../../../ui-components/CustomSelectDropDown/SkeltonLoadingDropDown';

const mapStateToProps = state => ({
  question: openTextQuestionSelector(state),
  openTextQuestions: state.project.ProjectDetails,
});

const mapDispatchToProps = {
  getOpenTextQuestions,
  toggleAllCheckboxes,
  toggleCheckbox,
  updateProjectOpenTextQuestions,
  getOpenTextQuestionsInEditMode,
  searchQuestions,
};

class ProjectOpenTextQuestionsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openTextQuestionList: [],
      isDisabled: false,
      isQuestionLoaded: false,
    };
    this.searchQuestion = this.searchQuestion.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.openTextQuestionsPanelWrapperRef = this.openTextQuestionsPanelWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#addOpenTextTypeQuestionsModal').modal('show');
    this.getOpenTextQuestionList(this.props.questionModelId);
  }

  getOpenTextQuestionList = async (questionModelId) => {
    this.setState({ isQuestionLoaded: true });
    const { stageId } = this.props;
    const data = {
      id: questionModelId,
      stageId,
    };
    await this.props.getOpenTextQuestions(data).then((response) => {
      if (response.data.count === 0) {
        this.setState({ isDisabled: true });
      }
      this.setState({ isQuestionLoaded: false });
    });
    if (this.props.isInEditMode) {
      await this.props.getOpenTextQuestionsInEditMode(this.props.updateQuestionsList);
    }
  }

  async onAddOpenTextQuestions() {
    this.setState({ isLoading: true });
    if (this.props.question.questionsList.length > 0) {
      const openTextValues = [];
      this.props.question.questionsList.filter((item) => {
        if (item.isChecked === true) openTextValues.push(item);
        return item;
      });
      this.props.updateProjectOpenTextQuestions(openTextValues);
      this.props.confirmationClick(openTextValues).then(() => {
        this.setState({ isLoading: false });
        this.hideModal();
        this.props.confirmationSuccess();
      });
    }
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  openTextQuestionsPanelWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.cancelClick();
    }
  }

  cancelClick() {
    this.hideModal();
    this.props.cancelClick();
  }

  hideModal() {
    $('#addOpenTextTypeQuestionsModal').modal('hide');
  }

  searchQuestion(value) {
    this.props.searchQuestions(value);
  }

  render() {
    const { isLoading, isDisabled, isQuestionLoaded } = this.state;
    const { title, question } = this.props;
    return (
      <div
        aria-hidden="true"
        className="modal a-modal fade add-stakeholder-modal"
        id="addOpenTextTypeQuestionsModal"
        role="dialog"
        ref={this.openTextQuestionsPanelWrapperRef}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header ">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h4 modal-title">{title}</div>
              </div>
              <span className="icon-wrapper">
                <a className=''>
                  <i
                    aria-label="Close"
                    className="appkiticon icon-close-fill a-close-btn"
                    data-bs-dismiss="modal"
                  />
                </a>
              </span>
            </div>
            <div className="t-modal-body pl-3 pr-3">
              {!isQuestionLoaded && <div className="t-table-header">
                <span className='preview-pane-font'>
                  {question.selectQuestionCount}/{question.questionsCount} selected</span>
                <span>
                  <div class="form-group mb-0">
                    <div class=" text-icon icon-left mb-0">
                      <i class="material-icons md-24 md-dark search">search</i>
                      <input
                        name="searchQuestion"
                        class="a-text-input"
                        onChange={e => this.searchQuestion(e.target.value)}
                        placeholder="Search Question" />
                    </div>
                  </div>
                </span>
              </div>}
              <div className="a-table-wrapper  row domain-row">
                <div className=" a-table-scroller domain-table col-md-12 col-sm-12 col-12 col-lg-12 a-mb-10 ">
                  <table className="a-table data-table a-lg min-w-1024 sortable selectable setting-table">
                    {!isQuestionLoaded && <thead className="a-thead modal-table-head">
                      <tr>
                        {question.questionsList.length > 0 ? (
                          <th className="a-checkbox-cell">
                            <label className="a-checkbox orange-checkbox">
                              <input
                                type="checkbox"
                                onChange={this.toggleAllCheckboxes}
                                checked={
                                  question.questionsList.filter(item => item.isChecked).length > 0
                                }
                              />
                              <span className="a-checkbox-mark">
                                <span
                                  className={`appkiticon   
                        ${question.questionsList
                                      .filter(item => item.isChecked).length > 0
                                      && !question.selectAll
                                      ? 'icon-minus-fill'
                                      : ''
                                    }  
                        ${question.questionsList
                                      .filter(item => item.isChecked).length > 0
                                      && question.selectAll
                                      ? 'icon-check-mark-fill'
                                      : ''
                                    }`}
                                />
                              </span>
                            </label>
                          </th>
                        ) : (
                          ''
                        )}
                        <th className="a-title-cell">QUESTION(S)</th>
                      </tr>
                    </thead>}
                    {isQuestionLoaded ? <SkeltonLoadingDropDown /> : <tbody className="a-tbody">

                      {question.questionsList.length > 0 ? (
                        question.questionsList.map(
                          (obj, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bkgd-color-grey' : ''}>
                              <td className="a-checkbox-cell">
                                {' '}
                                <label className="a-checkbox orange-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={obj.isChecked}
                                    onChange={() => this.toggleCheckbox(obj)}
                                  />
                                  <span className="a-checkbox-mark">
                                    <span className="appkiticon icon-check-mark-fill" />
                                  </span>
                                </label>
                              </td>
                              <td
                                className="a-title-cell"
                              >
                                {obj.title}
                              </td>
                            </tr>
                          ),
                          this,
                        )
                      ) : (
                        <tr>
                          <td colSpan="3" class="font-weight-medium">No questions available.</td>
                        </tr>
                      )}
                    </tbody>}
                  </table>
                </div>
              </div>


            </div>
            <div className="a-modal-footer a-border-tp justify-content-end c-question-ask">
              <button onClick={this.cancelClick} className='btn c-question-cancel'>
                Cancel                            </button>
              {isLoading && (
                <button disabled className={'a-btn a-btn-lg a-btn-transparent a-no-interaction loader-button'}>
                  <i className='a-loading-one a-primary' />
                </button>
              )}

              {!isLoading && (
                <button type='submit' disabled={isDisabled} onClick={this.onAddOpenTextQuestions.bind(this)} className={'ottq-submit-btn a-btn a-btn-transparent a-btn-lg'}>
                  Add to future question cycles
                </button>
              )}
            </div>
          </div>

        </div>
      </div>
    );
  }
}

ProjectOpenTextQuestionsPanel.propTypes = {
  isInEditMode: PropTypes.bool.isRequired,
  updateQuestionsList: PropTypes.array.isRequired,
  title: PropTypes.string,
  cancelClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectOpenTextQuestionsPanel);
