import React from 'react';

class ArchiveNotificationBanner extends React.Component {
  render() {
    const {
 roles, isSysAdmin, projectDraftedStatus, isInternalUser,
} = this.props;
    return (
      <React.Fragment>
        <div className="a-notification a-transition-500ms warning custom-warning-notification"
          style={{ top: projectDraftedStatus ? '128px' : '' }}
        >
          <span className="notification-content">
            <span class="Appkit4-icon icon-information-outline" style={{ marginRight: '5px' }} />
            <span className="text-left archive-notification-text">
              <strong>Your topic will be auto-archived soon due to 90 days of inactivity. </strong>
            </span>
            {(roles.projectAdministrator || isSysAdmin || roles.portfolioAdministrator)
              && (<>
                {(!projectDraftedStatus && !isInternalUser) && (<button
                  data-for="launch-label-icon"
                  data-tip="launch-label_tooltip"
                  className="btn-preview custom-excel-export  btn-notice a-btn ml-3 btn-preview a-btn-transparent a-btn-secondary btn-notice"
                  style={{
                    opacity: 1, width: '155px',
                  }}
                  onClick={() => this.props.downloadExcelData()}
                >
                  EXCEL DATA EXPORT
                </button>)}
                <button className="bg-transparent  request-support  btn-notice a-btn ml-3 a-btn-transparent a-btn-secondary btn-notice"
                  style={{ width: '155px' }}
                  onClick={() => this.props.openSupportLink()}
                >
                  REQUEST SUPPORT
                </button>
              </>)}
          </span>
        </div>
      </React.Fragment>
    );
  }
}

export default ArchiveNotificationBanner;
