import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';

class AppInsights {
    static reactPlugin = new ReactPlugin()

    static initialize = (instrumentationKey) => {
      if (instrumentationKey && !AppInsights.client) {
        const browserHistory = createBrowserHistory({ basename: '' });
        AppInsights.client = new ApplicationInsights({
          config: {
            instrumentationKey,
            maxBatchInterval: 0,
            disableFetchTracking: false,
            enableAutoRouteTracking: true,
            extensions: [AppInsights.reactPlugin],
            extensionConfig: {
              [AppInsights.reactPlugin.identifier]: { history: browserHistory },
            },
          },
        });
        AppInsights.client.loadAppInsights();
        // Add telemetry initializer
        const telemetryInitializer = (envelope) => {
          // Replace page view name with generalised relative url
          if (envelope && envelope.baseType === 'PageviewData' && envelope.baseData && envelope.baseData.uri) {
            envelope.baseData.name = envelope.baseData.uri.replace(/^.*\/\/[^\\/]+/, '').replace(/[0-9]/g, '');
          }
          // the need to add role name and role instance?
          // envelope.tags["ai.cloud.role"] = "your role name";
        };
        AppInsights.client.addTelemetryInitializer(telemetryInitializer);
      }
    }

    static withAppInsights = Component => withAITracking(AppInsights.reactPlugin, Component)
}
export default AppInsights;
