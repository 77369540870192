import { createSelector } from 'reselect';

const QuestionSelector = state => state.question;
const AddQuestionSelector = state => state.addQuestion;

const questionSelector = createSelector(
  [QuestionSelector],
  question => question,
);

const addQuestionSelector = createSelector(
  [AddQuestionSelector],
  addQuestion => addQuestion,
);

export { questionSelector, addQuestionSelector };
