const TITLE_EXIST = 'projects/check-project-title-existence';
const GET_USER_FILTERS_FOR_PROJECT = 'projects/get-user-filters-for-project';
const PROJECTS = 'projects/';
const SEND_CUSTOM_QUESTION_DATA = 'projects/send-custom-question-data';
const PROJECT_DETAILS = 'projects/getdetails';
const PROJECT_DOMAINS = 'projects/get-domains';
const PROJECT_DASHBOARD = 'projects/dashboard/';
const PROJECT_DASHBOARD_TOOLBAR = 'projects/dashboard-toolbar/';
const GET_PERTFOLIO_QUESTION_MODEL = 'projects/get-portfolio-question-model-list/';
const UPDATE_STAGE = 'projects/save-stage';
const GET_SELECTED_PORTFOLIO_ADMINS = 'projects/get-selected-portfolio-admins';
const GET_PROJECT_STAKEHOLDER = 'projects/get-project-stakeholders/';
const ADD_PROJECT_STAKEHOLDER = 'projects/add-project-stakeholder/';
const PAUSE_RESUME_STAKEHOLDER = 'projects/pause-or-resume-project-stakeholder/';
const OPEN_TEXT_RESPONSES = 'projects/open-text-responses';
const OPTIONAL_COMMENT_TEXT_RESPONSES = 'projects/optional-comment-text-responses';
const CUSTOM_QUESTION_RESPONSE_DATA = 'projects/custom_question_response_data';
const OPEN_TEXT_NON_MATCH_RESPONSES = 'projects/open-text-non-match-responses';
const IS_DROP_EXECUTABLE = 'projects/is-drop-executable';
const GET_OPEN_TEXT_QUESTIONS = 'projects/get-open-text-questions-list';
const APPLY_USER_TAG_FILTERS = 'projects/get-user-tag-filters-data';
const AUTOMATED_QUESTIONS = 'projects/get-automated-questions-data';
const SWAP_AUTOMATED_QUESTIONS = 'projects/get-swap-question-data';
const SAVE_OPEN_TEXT_TYPE_QUESTIONS_FOR_TOPIC = 'projects/save-open-text-type-questions-for-topic';
const SAVE_PROJECT_DOMAINS = 'projects/save-project-domains';
const UPDATE_CUSTOM_QUESTION_DATA = 'projects/update-custom-question-data';
const DELETE_CUSTOM_QUESTION_DATA = 'projects/delete-custom-question-data';
const DELETE_SEGEMENT_DATA = 'projects/delete-segement-data/';
const GET_PROJECT_STAKEHOLDER_FILTERS = 'projects/get-project-stakeholders-filters';
const SAVE_FILTER_GROUPS = 'projects/save-filter-groups/';
const GET_FILETR_STAKEHOLDER_COUNT = 'projects/get-filter-stakeholders-count/';
const CREATE_NEW_SEGMENT = 'projects/create-new-segment/';
const CHECK_FILTERS_EXISTS_IN_ALREADY_CREATED_SEGMENT = 'projects/check-filters-exists-in-already-created-segment';
const APPLY_USER_SEGMENT_FILTERS = 'projects/get-dashboard-data-with-segments';
const GET_SEGMENTS_FOR_PROJECT = 'projects/get-segments-for-project';
const ASSIGN_SEGMENTS_TO_DOMAIN = 'projects/assign-segments-to-domain';
const LAUNCH_PROJECT_TO_FIRST_QUESTION_CYCLE = 'projects/launch-project-to-first-question-cycle';
const TESTED_QUESTION_FOR_USER = 'projects/tested-question-cycle';
const CANCEL_NEXT_ADHOC_DROP = 'projects/cancel-future-adhoc-drop';
const GET_SEGMENTS_STAKEHOLDER_COUNT = 'projects/get-segments-stakeholders-count/';
const SEND_AGED_QUESTION_REMINDER_NOTIFICATION = 'projects/send-aged-question-reminder-notification';
const RESEND_QUESTION_EMAIL_TO_STAKEHOLDER = 'projects/resend-question-email-stakeholder';
const GENERATE_REPORTING = 'projects/generate-reporting/';
const OPEN_TEXT_DROP_QUES_RESPONSES = 'projects/open-text-drop-ques-response/';
const SUBMIT_USER_SUGGESTION = 'projects/submit-user-suggestion-feedback';
const PROJECT_PREVIEW_PANE_DETAILS = 'projects/project-preview-pane-details/';
const GENERATE_EXCEL_REPORT = 'projects/generate-excel-report/';
const SUBMIT_USER_REQUEST_SUPPORT = 'projects/submit-user-request-support-feedback';

export {
  TITLE_EXIST, DELETE_SEGEMENT_DATA,
  CANCEL_NEXT_ADHOC_DROP,
  GET_USER_FILTERS_FOR_PROJECT,
  PROJECTS,
  PROJECT_DETAILS,
  GET_SELECTED_PORTFOLIO_ADMINS,
  GET_PROJECT_STAKEHOLDER,
  ADD_PROJECT_STAKEHOLDER,
  PROJECT_DASHBOARD,
  UPDATE_STAGE,
  OPEN_TEXT_RESPONSES,
  OPEN_TEXT_NON_MATCH_RESPONSES,
  IS_DROP_EXECUTABLE,
  GET_OPEN_TEXT_QUESTIONS,
  APPLY_USER_TAG_FILTERS,
  GET_PERTFOLIO_QUESTION_MODEL,
  PAUSE_RESUME_STAKEHOLDER,
  OPTIONAL_COMMENT_TEXT_RESPONSES,
  AUTOMATED_QUESTIONS,
  SEND_CUSTOM_QUESTION_DATA,
  SWAP_AUTOMATED_QUESTIONS,
  SAVE_OPEN_TEXT_TYPE_QUESTIONS_FOR_TOPIC,
  PROJECT_DOMAINS,
  SAVE_PROJECT_DOMAINS,
  CUSTOM_QUESTION_RESPONSE_DATA,
  UPDATE_CUSTOM_QUESTION_DATA,
  DELETE_CUSTOM_QUESTION_DATA,
  GET_PROJECT_STAKEHOLDER_FILTERS,
  SAVE_FILTER_GROUPS,
  GET_FILETR_STAKEHOLDER_COUNT,
  CREATE_NEW_SEGMENT,
  CHECK_FILTERS_EXISTS_IN_ALREADY_CREATED_SEGMENT,
  APPLY_USER_SEGMENT_FILTERS,
  ASSIGN_SEGMENTS_TO_DOMAIN,
  GET_SEGMENTS_FOR_PROJECT,
  LAUNCH_PROJECT_TO_FIRST_QUESTION_CYCLE,
  TESTED_QUESTION_FOR_USER,
  PROJECT_DASHBOARD_TOOLBAR,
  GET_SEGMENTS_STAKEHOLDER_COUNT,
  SEND_AGED_QUESTION_REMINDER_NOTIFICATION,
  RESEND_QUESTION_EMAIL_TO_STAKEHOLDER,
  GENERATE_REPORTING,
  OPEN_TEXT_DROP_QUES_RESPONSES,
  SUBMIT_USER_SUGGESTION,
  PROJECT_PREVIEW_PANE_DETAILS,
  GENERATE_EXCEL_REPORT,
  SUBMIT_USER_REQUEST_SUPPORT,
};
