import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  showOptionalCommentRightPanel,
} from '../../portfolio/actions/portfolio';
import { enableUnsavedChangesModalFocusDomain } from '../actions/project';
import { getSelectedProject } from '../../portfolio/selectors/portfolio';
import { showHideOverlay } from '../../common/overlay/actions/overlay';

const SegmentGroupDomainAttribute = (props) => {
  const {
    index, domainWidget, domainWidgets1, domainWidgets2, domainWidgets3,
    domainWidgets4,
    associatedStakeholdersWithFilterValue,
    allUserFilterGroupValues,
    roles, isSysAdmin, overAllWidget: overallWidget,
    userSelectedFilterGroupValues,
    showProcessingRecords,
    unsavedChangesModal,
    unsavedChanges,
    updateShowUnsavedChangesModal,
    tooltipPosition,
  } = props;
  const showCustomQuestionOption = !!(roles.projectAdministrator
    || roles.portfolioAdministrator || isSysAdmin || roles.projectViewer
    || roles.portfolioStakeholder || roles.projectStakeholder);

  // Capitalize all words and replace the word 'And' with 'and'

  const onClickShowOptionalCommentRightPanel = (attr, widget, userFilterGroupValue) => {
    props.showHideOverlay(true);
    const {
      // eslint-disable-next-line camelcase
      id, title, description, isShowCommentBox, isPassed, is_custom_attribute, score,
      isDomainAnonymityOverride,
    } = attr;
    const {
      domainName, domainId, isCustomDomain, domainSegments,
    } = widget;
    const { isOptionalCommentAnonymity, projectDraftedStatus } = overallWidget;
    if (showProcessingRecords) {
      return;
    }
    if (unsavedChanges && !unsavedChangesModal) {
      props.enableUnsavedChangesModalFocusDomain();
      updateShowUnsavedChangesModal();
    } else if (!unsavedChangesModal) {
      if (isPassed || showCustomQuestionOption) {
        const { selectedProject } = props;
        const {
          selectedSegmentValue, isSegmentApplied, customDatesData, segmentGroupValue,
        } = props;
        const { segmentGroup1 = '' } = segmentGroupValue;
        const optionalCommentModel = {
          projectId: selectedProject.ProjectId,
          attributeId: id,
          attributeTitle: title,
          domainTitle: domainName,
          attributeDescription: description,
          userTagFilters: userSelectedFilterGroupValues,
          isShowCommentPanelData: isShowCommentBox,
          domainid: domainId,
          showCustomQuestionOption,
          isCustomAttribute: is_custom_attribute,
          isCustomDomain,
          attributeScore: score,
          customDates: customDatesData,
          isProjectAnonymityMet: isOptionalCommentAnonymity,
          associatedStakeholdersWithFilterValue,
          allUserFilterGroupValues,
          userFilterGroupValue,
          projectDraftedStatus,
          domainAssignedSegments: domainSegments,
          isDomainAnonymityOverride,
        };
        if (isSegmentApplied) {
          optionalCommentModel.selectedSegment = selectedSegmentValue;
        }
        if (segmentGroup1) {
          optionalCommentModel.selectedSegment = segmentGroup1;
        }
        if (props.segmentGroupOverall.segmentGroup1) {
          // eslint-disable-next-line max-len
          optionalCommentModel.isProjectAnonymityMet = props.segmentGroupOverall.segmentGroup1.isOptionalCommentAnonymity;
        }
        if (props.customDatesData.range === 'Current Period') {
          optionalCommentModel.customDates.fromDate = '';
          optionalCommentModel.customDates.toDate = '';
        }
        props.showOptionalCommentRightPanel(optionalCommentModel);
      }
    }
  };

  return (

    <React.Fragment>
      <div class="cc-timeline-wrap mt-3">
        {domainWidget.attributes.map((attr) => {
          const domainAttr1 = domainWidgets1
            && domainWidgets1[index].attributes.filter(x => x.id === attr.id);
          const domainAttr2 = domainWidgets2
            && domainWidgets2[index].attributes.filter(x => x.id === attr.id);
          const domainAttr3 = domainWidgets3
            && domainWidgets3[index].attributes.filter(x => x.id === attr.id);
          const domainAttr4 = domainWidgets4
            && domainWidgets4[index].attributes.filter(x => x.id === attr.id);

          return (
            <React.Fragment>
              {domainAttr1?.[0].isAttrAssociatedWithQuestions && <div class="cct-list pt-3" onClick={() => {
                onClickShowOptionalCommentRightPanel(
                  attr, domainWidget, userSelectedFilterGroupValues,
                );
              }}>
                <div className={'cct-list-item a-tag-d2e attribute-pill'} key={domainAttr1[0].id}
                  id={domainAttr1[0].title}
                  data-for={domainAttr1[0].title}>
                  <div class={'ccti-element first-ccti'}>
                    <span style={{ 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }}>
                      {domainAttr1[0].title}</span>
                  </div>
                  <div>
                    <span className='txt-grey'>A</span>
                    <div className={`ccti-element center-element ${domainAttr1[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr1[0].color}`}>
                      <span className={'c-right-icon'}>{`${domainAttr1[0].score === '' ? '' : `${domainAttr1[0].score}%`}`}</span>
                    </div>
                  </div>
                  {domainAttr2?.[0].isAttrAssociatedWithQuestions
                    && <div>
                      <span className='txt-grey'>B</span>
                      <div className={`ccti-element center-element ${domainAttr2[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr2[0].color}`}>
                        <span className={'c-right-icon'}>{`${domainAttr2[0].score === '' ? '' : `${domainAttr2[0].score}%`}`}</span>
                      </div>
                    </div>}
                  {domainAttr3?.[0].isAttrAssociatedWithQuestions
                    && <div>
                      <span className='txt-grey'>C</span>
                      <div className={`ccti-element center-element ${domainAttr3[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr3[0].color}`}>
                        <span className={'c-right-icon'}>{`${domainAttr3[0].score === '' ? '' : `${domainAttr3[0].score}%`}`}</span>
                      </div>
                    </div>}
                  {domainAttr4?.[0].isAttrAssociatedWithQuestions
                    && <div>
                      <span className='txt-grey'>D</span>
                      <div className={`ccti-element center-element ${domainAttr4[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr4[0].color}`}>
                        <span className={'c-right-icon'}>{`${domainAttr4[0].score === '' ? '' : `${domainAttr4[0].score}%`}`}</span>
                      </div>
                    </div>}
                  {(domainAttr1?.[0].title && domainAttr1?.[0].title.length > 25)
                    && <ReactTooltip id={domainAttr1[0].title} className='tooltip' place={tooltipPosition}
                      effect='solid' >
                      {domainAttr1[0].title}
                    </ReactTooltip>}
                </div>
              </div>}
              {domainAttr2
                && domainAttr1?.[0].id === domainAttr2?.[0].id
                && domainAttr2?.[0].isAttrAssociatedWithQuestions
                && !domainAttr1?.[0].isAttrAssociatedWithQuestions && <div class="cct-list pt-3" onClick={() => {
                  onClickShowOptionalCommentRightPanel(
                    attr, domainWidget, userSelectedFilterGroupValues,
                  );
                }}>
                  <div className={'cct-list-item a-tag-d2e attribute-pill'} key={domainAttr2[0].id}
                    id={domainAttr2[0].title}
                    data-for={domainAttr2[0].title}>
                    <div class={'ccti-element first-ccti'}>
                      <span style={{ 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }}>
                        {domainAttr2[0].title}</span>
                    </div>
                    <div className={`ccti-element center-element ${domainAttr1[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr1[0].color}`}>
                      <span>{`${domainAttr1[0].score === '' ? '' : `${domainAttr1[0].score}%`}`}</span>
                    </div>
                    {domainAttr2?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>B</span>
                        <div className={`ccti-element center-element ${domainAttr2[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr2[0].color}`}>
                          <span>{`${domainAttr2[0].score === '' ? '' : `${domainAttr2[0].score}%`}`}</span>
                        </div>
                      </div>}
                    {domainAttr3?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>C</span>
                        <div className={`ccti-element center-element ${domainAttr3[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr3[0].color}`}>
                          <span>{`${domainAttr3[0].score === '' ? '' : `${domainAttr3[0].score}%`}`}</span>
                        </div>
                      </div>
                    }
                    {domainAttr4?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>D</span>
                        <div className={`ccti-element center-element ${domainAttr4[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr4[0].color}`}>
                          <span>{`${domainAttr4[0].score === '' ? '' : `${domainAttr4[0].score}%`}`}</span>
                        </div>
                      </div>
                    }
                    {(domainAttr2[0].title && domainAttr2[0].title.length > 25)
                      && <ReactTooltip id={domainAttr2[0].title} className='tooltip' place={tooltipPosition}
                        effect='solid' >
                        {domainAttr2[0].title}
                      </ReactTooltip>}
                  </div>
                </div>}
              {domainAttr3?.[0].isAttrAssociatedWithQuestions
                && !domainAttr1?.[0].isAttrAssociatedWithQuestions
                && !domainAttr2?.[0].isAttrAssociatedWithQuestions
                && <div class="cct-list pt-3" onClick={() => {
                  onClickShowOptionalCommentRightPanel(
                    attr, domainWidget, userSelectedFilterGroupValues,
                  );
                }}>
                  <div className={'cct-list-item a-tag-d2e attribute-pill'} key={domainAttr3[0].id}
                    id={domainAttr3[0].title}
                    data-for={domainAttr3[0].title}>
                    <div class={'ccti-element first-ccti'}>
                      <span style={{ 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }}>
                        {domainAttr3[0].title}</span>
                    </div>
                    <div className={`ccti-element center-element ${domainAttr1[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr1[0].color}`}>
                      <span>{`${domainAttr1[0].score === '' ? '' : `${domainAttr1[0].score}%`}`}</span>
                    </div>
                    {domainAttr2?.[0].isAttrAssociatedWithQuestions && <div className={`ccti-element center-element ${domainAttr2[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr2[0].color}`}>
                      <span>{`${domainAttr2[0].score === '' ? '' : `${domainAttr2[0].score}%`}`}</span>
                    </div>}
                    {domainAttr3?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>C</span>
                        <div className={`ccti-element center-element ${domainAttr3[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr3[0].color}`}>
                          <span>{`${domainAttr3[0].score === '' ? '' : `${domainAttr3[0].score}%`}`}</span>
                        </div>
                      </div>}
                    {domainAttr4?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>D</span>
                        <div className={`ccti-element center-element ${domainAttr4[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr4[0].color}`}>
                          <span>{`${domainAttr4[0].score === '' ? '' : `${domainAttr4[0].score}%`}`}</span>
                        </div>
                      </div>}
                    {(domainAttr3[0].title && domainAttr3[0].title.length > 25)
                      && <ReactTooltip id={domainAttr3[0].title} className='tooltip' place={tooltipPosition}
                        effect='solid' >
                        {domainAttr3[0].title}
                      </ReactTooltip>}
                  </div>
                </div>}
              {domainAttr4?.[0].isAttrAssociatedWithQuestions
                && !domainAttr1?.[0].isAttrAssociatedWithQuestions
                && !domainAttr2?.[0].isAttrAssociatedWithQuestions
                && !domainAttr3?.[0].isAttrAssociatedWithQuestions && <div class="cct-list pt-3" onClick={() => {
                  onClickShowOptionalCommentRightPanel(
                    attr, domainWidget, userSelectedFilterGroupValues,
                  );
                }}>
                  <div className={'cct-list-item a-tag-d2e attribute-pill'} key={domainAttr4[0].id}
                    id={domainAttr4[0].title}
                    data-for={domainAttr4[0].title}>
                    <div class={'ccti-element first-ccti'}>
                      <span style={{ 'white-space': 'nowrap', overflow: 'hidden', 'text-overflow': 'ellipsis' }}>
                        {domainAttr4[0].title}</span>
                    </div>
                    <div className={`ccti-element center-element ${domainAttr1[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr1[0].color}`}>
                      <span>{`${domainAttr1[0].score === '' ? '' : `${domainAttr1[0].score}%`}`}</span>
                    </div>
                    {domainAttr2?.[0].isAttrAssociatedWithQuestions && <div className={`ccti-element center-element ${domainAttr2[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr2[0].color}`}>
                      <span>{`${domainAttr2[0].score === '' ? '' : `${domainAttr2[0].score}%`}`}</span>
                    </div>}
                    {domainAttr3?.[0].isAttrAssociatedWithQuestions && <div className={`ccti-element center-element ${domainAttr3[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr3[0].color}`}>
                      <span>{`${domainAttr3[0].score === '' ? '' : `${domainAttr3[0].score}%`}`}</span>
                    </div>}
                    {domainAttr4?.[0].isAttrAssociatedWithQuestions
                      && <div>
                        <span className='txt-grey'>D</span>
                        <div className={`ccti-element center-element ${domainAttr4[0].isAttrAssociatedWithQuestions ? '' : 'd-none'} ${domainAttr4[0].color}`}>
                          <span>{`${domainAttr4[0].score === '' ? '' : `${domainAttr4[0].score}%`}`}</span>
                        </div>
                      </div>}
                    {(domainAttr4[0].title && domainAttr4[0].title.length > 25) && <ReactTooltip id={domainAttr4[0].title} className='tooltip' place={tooltipPosition}
                      effect='solid' >
                      {domainAttr4[0].title}
                    </ReactTooltip>}
                  </div>
                </div>}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  projectSegments: state.project.projectSegments,
  roles: state.portfolio.portfolioData.roles,
  isSysAdmin: state.login.isSysAdmin,
  overAllWidget: state.project.overAllWidget,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  selectedProject: getSelectedProject(state),
  segmentGroupValue: state.project.segmentGroupValue,
  segmentGroupOverall: state.project.segmentGroupOverall,
});

const mapDispatchToProps = {
  showOptionalCommentRightPanel,
  enableUnsavedChangesModalFocusDomain,
  showHideOverlay,
};

export default connect(mapStateToProps,
  mapDispatchToProps)(SegmentGroupDomainAttribute);
