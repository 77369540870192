import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { difference } from 'lodash';
import * as bootstrap from 'bootstrap';
import { SpinnerButton } from '../Loader';
import {
  addCustomQuestion, updateCustomQuestion, deleteCustomQuestion,
  updateCustomQuestionsData, deleteCustomQuestionsData, sendCustomQuestionsData,
  updateLaterQuestionSaveMessage, getSegmentGroupStakeholderCounts,
  setCustomQuestionsBasket, updatePendingCustomQuestion,
  updateQuestionSentToFutureDrop, getAutomatedQuestions,
  getFilterGroupStakeholderCounts, checkFiltersExistsInAlreadyCreatedSegment,
  isChecking,
  saveSegmentAlreadyCreated,
} from '../../modules/project/actions/project';
import { getSelectedProject } from '../../modules/portfolio/selectors/portfolio';
import { hideOptionalCommentRightPanel } from '../../modules/portfolio/actions/portfolio';
import { YESNO_QUESTION, OPENTYPE_QUESTION } from '../../Constants';
import RenderIf from '../Common';
import DeleteConfirmation from '../DeleteConfirmation/DeleteConfirmation.jsx';
import './addCustomQuestion.css';
import SumoSelectSegmentDropDown from '../Segment/SumoSelectSegmentDropDown.jsx';
import { showHideOverlay } from '../../modules/common/overlay/actions/overlay';
import projectConstant from '../../modules/project/actions/constant';

const mapDispatchToProps = {
  addCustomQuestion,
  updateCustomQuestion,
  deleteCustomQuestion,
  updateCustomQuestionsData,
  deleteCustomQuestionsData,
  sendCustomQuestionsData,
  updateLaterQuestionSaveMessage,
  getSegmentGroupStakeholderCounts,
  updatePendingCustomQuestion,
  updateQuestionSentToFutureDrop,
  hideOptionalCommentRightPanel,
  getAutomatedQuestions,
  checkFiltersExistsInAlreadyCreatedSegment,
  saveSegmentAlreadyCreated,
  isChecking,
  showHideOverlay,
};

const mapStateToProps = state => ({
  selectedProject: getSelectedProject(state),
  projectSegments: state.project.projectSegments,
  customQuestionBasket: state.project.customQuestionBasket,
  isProjectInDraftedState: state.project.isProjectInDraftedState,
});

class AddCustomQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: YESNO_QUESTION,
      question: '',
      showDeleteModal: false,
      stakeholderFilterGroupValue: [],
      sendNow: false,
      selectedSegmentValue: '0',
      createSegment: 'yes',
      isModalLoaded: false,
      segments: [],
      check: '0',
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
    this.modalWrapperRef = this.modalWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.toggleCreateSegment = this.toggleCreateSegment.bind(this);
    this.updateAssignedSegments = this.updateAssignedSegments.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    const {
      filterGroupValues, createSegment,
      selectedSegmentValue, dSegments,
      model,
    } = this.props;
    const segementval = model?.segments.every(x => dSegments.includes(x));
    const domainAssignedSegments = segementval || dSegments[0] === 0
     ? model?.segments || dSegments : dSegments;
    this.setState({
      stakeholderFilterGroupValue: filterGroupValues,
      createSegment,
      selectedSegmentValue: selectedSegmentValue && parseInt(selectedSegmentValue, 10) >= 0 ? selectedSegmentValue : '0',
      isModalLoaded: true,
      segments: domainAssignedSegments,
    });
    $(document).on('shown.bs.modal', '.modal', () => {
      $('input[name="question"]').focus();
    });
    document.addEventListener('mousedown', this.handleClickOutside);
    const customQuestionModal = new bootstrap.Modal(document.getElementById('customQuestionModal'), {
      backdrop: 'static',
    });
    customQuestionModal.show();
    $('.modal-backdrop').remove();
    if (this.props.model !== undefined) {
      this.setState({
        question: this.props.model.title !== undefined
          ? this.props.model.title : this.props.model.QuestionTitle,
        type: this.props.model.type !== undefined
          ? this.props.model.type : YESNO_QUESTION,
      });
    }
  }

  modalWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside() { // event
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.cancelClick();
    }
  }

  cancelClick() {
    this.hideModal();
    this.props.cancelClick();
  }

  hideModal() {
    const customQuestionModal = new bootstrap.Modal(document.getElementById('customQuestionModal'));
    customQuestionModal.hide();
    $('.modal-backdrop').remove();
  }

  isSegmentPresent(a, b) {
    const result = b.filter(item => !a?.includes(item));
    return result.length === 0;
  }

  getQuestionObjectArray(qa, cqo) {
    const {
      attributeId, attributeName, type, question,
      domainId, domainName, sendQuestionNow, isCustomDomain,
      associatedStakeholdersCount, filterGroupValues,
      createSegment, selectedSegmentValue,
      userFilterGroupValues, segmentFiltersGroup,
      segments, dSegments,
    } = cqo;
    let result = [];
    let isQuestionInserted = false;
    const customQuestionModel = {
      attributeId,
      attributeName,
      type,
      title: question,
      domainId,
      domainName,
      sendQuestionImmediately: isCustomDomain ? sendQuestionNow : true,
      isCustomDomain,
      index: 1,
      segmentFiltersGroup,
      segments,
      dSegments,
    };
    if (qa.length > 0) {
      result = qa.map((so) => {
        if (createSegment === 'yes') {
          const {
            selectedGroupValue1, selectedGroupValue2,
            selectedGroupValue3, selectedGroupValue4,
          } = userFilterGroupValues;
          if (so.userFilterGroupValues) {
            const s1 = difference(so.userFilterGroupValues.selectedGroupValue1,
              selectedGroupValue1);
            const s2 = difference(so.userFilterGroupValues.selectedGroupValue2,
              selectedGroupValue2);
            const s3 = difference(so.userFilterGroupValues.selectedGroupValue3,
              selectedGroupValue3);
            const s4 = difference(so.userFilterGroupValues.selectedGroupValue4,
              selectedGroupValue4);
            if (s1.length === 0 && s2.length === 0 && s3.length === 0 && s4.length === 0) {
              customQuestionModel.index = so.questions.length + 1;
              so.questions.push(customQuestionModel);
              isQuestionInserted = true;
            }
          }
        } else if (so.createSegment === createSegment
          && this.isSegmentPresent(so.segments, segments)) {
          customQuestionModel.index = so.questions.length + 1;
          so.questions.push(customQuestionModel);
          isQuestionInserted = true;
        }
        return so;
      });
    } else {
      result.push({
        createSegment,
        selectedSegmentValue,
        filterGroupValues,
        userFilterGroupValues,
        associatedStakeholdersCount,
        segmentFiltersGroup,
        segments,
        dSegments,
        questions: [customQuestionModel],
      });
    }

    if (qa.length > 0 && !isQuestionInserted) {
      result.push({
        createSegment,
        selectedSegmentValue,
        filterGroupValues,
        userFilterGroupValues,
        associatedStakeholdersCount,
        segmentFiltersGroup,
        segments,
        dSegments,
        questions: [customQuestionModel],
      });
    }
    return result;
  }

  sendCustomDomainQuestions(questions) {
    const { projectId } = this.props;
    const customQuestionsData = {
      projectId,
      customQuestions: questions,
      automatedQuestions: [],
    };
    this.props.sendCustomQuestionsData(customQuestionsData).then((result) => {
      if (result.statusText === 'OK' || result.status === 200) {
        this.props.hideOptionalCommentRightPanel();
        this.props.showHideOverlay(false);
        this.props.updateQuestionSentToFutureDrop({
          status: true,
          message: `1 question(s)
          have been added to the next question drop`,
        });
        setTimeout(() => {
          this.props.updateQuestionSentToFutureDrop({
            status: false,
            message: null,
          });
        }, 2000);
      }
      this.showSpinner(false);
      this.cancelClick();
    });
  }

  sendQuestionWithCheck(formData) {
    this.isChecked();
    if (this.props.model === undefined) {
      if (!formData.sendQuestionNow && formData.isCustomDomain) {
        const questions = this.getQuestionObjectArray([], formData);
        if (this.props.fromOptionalCommentSection) {
          this.sendCustomDomainQuestions(questions);
        } else {
          this.props.sendCustomQuestions(questions);
        }
      } else {
        this.props.addCustomQuestion(formData);
      }
    } else {
      if (this.props.filterGroupId) {
        if (this.props.createSegment === 'yes' && this.state.createSegment === 'no') {
          formData.isExchangeCreateToExist = true;
          formData.isExchangeExistToCreate = false;
        } else if (this.props.createSegment === 'no' && this.state.createSegment === 'yes') {
          formData.isExchangeCreateToExist = false;
          formData.isExchangeExistToCreate = true;
        } else {
          formData.isExchangeCreateToExist = false;
          formData.isExchangeExistToCreate = false;
        }
        formData.id = this.props.filterGroupId;
      }
      formData.index = this.props.model.index;
      this.props.updateCustomQuestion(formData);
    }
  }

  addAutomatedQuestions(formData) {
    const {
      projectId, attributeId, domainId,
      projectSegments, dSegments, filterGroupValues,
      userFilterGroupValue, associatedStakeholdersCount,
    } = this.props;
    const item = {
      projectId,
      attributeId,
      domainId,
    };
    const {
      segments,
    } = this.state;
    const segmentFiltersGroup = [];
    segments.forEach((x) => {
      const selectedSegment = projectSegments
        .find(s => parseInt(s.value, 10) === parseInt(x, 10));
      segmentFiltersGroup.push(selectedSegment.text.split(','));
    });
    this.props.getSegmentGroupStakeholderCounts(
      { ProjectId: projectId, segments },
    ).then((d) => {
      const userFilterData = {
        filterGroupValues: formData.createSegment === 'yes'
          ? filterGroupValues : segmentFiltersGroup,
        createSegment: formData.createSegment,
        userFilterGroupValues: formData.createSegment === 'yes' ? { ...userFilterGroupValue } : {},
        associatedStakeholdersCount: formData.createSegment === 'yes'
          ? associatedStakeholdersCount : d ?? null,
        segments: formData.createSegment === 'yes' ? null : segments,
        dSegments,
        segmentFiltersGroup: formData.createSegment === 'yes' ? null : segmentFiltersGroup,
      };
      if (projectId) {
        this.props.getAutomatedQuestions(item, userFilterData);
      }
    });
  }

  onContinueButtonClick(formData) {
    const {
      filterGroupValues, userFilterGroupValue,
      projectSegments,
      isCustomDomain, model,
      dSegments, isAutomatedQuestion,
      projectId, userSelectedFilterGroupValues,
    } = this.props;
    const {
      segments,
    } = this.state;
    if (isAutomatedQuestion) {
      if (formData.createSegment === 'yes') {
        this.props.checkFiltersExistsInAlreadyCreatedSegment({
          projectId,
          filterGroupValues: userSelectedFilterGroupValues,
          checkingExistingSegment: true,
        })
          .then((segmentRes) => {
            if (segmentRes) {
              this.props.saveSegmentAlreadyCreated({
                isSegmentAlreadyCreated: true,
                duplicateMessage: 'This segment already exists and a duplicate segment has not been created',
              });
              setTimeout(() => this.props.saveSegmentAlreadyCreated({
                isSegmentAlreadyCreated: false,
                duplicateMessage: '',
              }), 5000);
            }
            this.addAutomatedQuestions(formData);
            this.showSpinner(false);
            this.cancelClick();
            this.isChecked();
          });
      } else {
        this.addAutomatedQuestions(formData);
        this.showSpinner(false);
        this.isChecked();
        this.cancelClick();
      }
    } else if (isCustomDomain && model && model?.questionId) {
      this.showSpinner(true);
      const segmentFiltersGroup = [];
      segments.forEach((x) => {
        const selectedSegment = projectSegments
          .find(s => parseInt(s.value, 10) === parseInt(x, 10));
        segmentFiltersGroup.push(selectedSegment.text.split(','));
      });
      formData.segmentFiltersGroup = segmentFiltersGroup;
      formData.segments = segments;
      formData.dSegments = dSegments;
      this.props.getSegmentGroupStakeholderCounts(
        { ProjectId: this.props.projectId, segments },
      ).then((d) => {
        formData.associatedStakeholdersCount = d;
        this.sendQuestionWithCheck(formData);
      });
      this.updateCustomQuestion(formData);
      this.showSpinner(false);
    } else {
      if (formData.createSegment === 'yes') {
        this.props.checkFiltersExistsInAlreadyCreatedSegment({
          projectId,
          filterGroupValues: userSelectedFilterGroupValues,
          checkingExistingSegment: true,
        })
          .then((segmentRes) => {
            if (segmentRes) {
              this.props.saveSegmentAlreadyCreated({
                isSegmentAlreadyCreated: true,
                duplicateMessage: 'This segment already exists and a duplicate segment has not been created',
              });
              setTimeout(() => this.props.saveSegmentAlreadyCreated({
                isSegmentAlreadyCreated: false,
                duplicateMessage: '',
              }), 5000);
            }
            formData.dSegments = dSegments;
            formData.filterGroupValues = filterGroupValues;
            formData.userFilterGroupValues = Object.assign({}, userFilterGroupValue);
            formData.selectedSegmentValue = '-1';
            formData.type = this.state.type;
            formData.attributeId = this.props.attributeId;
            formData.attributeName = this.props.attributeName;
            formData.projectId = this.props.projectId;
            formData.domainId = this.props.domainId;
            formData.domainName = this.props.domainName;
            formData.sendQuestionNow = formData.sendNow;
            formData.isCustomDomain = this.props.isCustomDomain;
            const data = {
              projectId: this.props.projectId,
              filterValues: Object.assign({}, userFilterGroupValue),
            };
            getFilterGroupStakeholderCounts(data).then((res) => {
              formData.associatedStakeholdersCount = res;
              this.sendQuestionWithCheck(formData);
            });
          });
      } else {
        this.showSpinner(true);
        const segmentFiltersGroup = [];
        segments.forEach((x) => {
          const selectedSegment = projectSegments
            .find(s => parseInt(s.value, 10) === parseInt(x, 10));
          segmentFiltersGroup.push(selectedSegment.text.split(','));
        });
        formData.segmentFiltersGroup = segmentFiltersGroup;
        formData.segments = segments;
        formData.dSegments = dSegments;
        this.props.getSegmentGroupStakeholderCounts(
          { ProjectId: this.props.projectId, segments },
        ).then((d) => {
          formData.associatedStakeholdersCount = d;
          formData.type = this.state.type;
          formData.attributeId = this.props.attributeId;
          formData.attributeName = this.props.attributeName;
          formData.projectId = this.props.projectId;
          formData.domainId = this.props.domainId;
          formData.domainName = this.props.domainName;
          formData.sendQuestionNow = formData.sendNow;
          formData.isCustomDomain = this.props.isCustomDomain;
          this.sendQuestionWithCheck(formData);
        });
      }
      this.showSpinner(false);
      this.cancelClick();
      this.isChecked();
    }
  }

  isChecked() {
    const valCheck = {
      value: true,
    };
    this.props.isChecking(valCheck);
  }

  showSpinner(isCreating) {
    this.setState({ isCreating });
  }

  selectType(questionType, id) {
    const text = $('.ques-text').text();
    this.setState({ type: questionType, question: text });
    $('.ques-option').removeClass('active');
    $(`#${id}`).addClass('active');
  }

  deleteCustomQuestion(qModel) {
    const {
      filterGroupId, isCustomDomain, model,
    } = this.props;
    if (isCustomDomain && model && model?.questionId) {
      this.setState({ showDeleteModal: true });
      this.hideModal();
    } else {
      this.props.deleteCustomQuestion(qModel.index, filterGroupId);
    }
    const modifiedCustomQuestionBasket = {
      projectId: this.props.selectedProject.ProjectId,
      customQuestionBasket: this.props.customQuestionBasket,
    };
    setCustomQuestionsBasket(modifiedCustomQuestionBasket);
  }

  deleteCustomQuestionFromDatabase(model) {
    const customQuestionsData = {
      questionId: model.questionId,
    };
    return new Promise((resolve) => {
      this.props.deleteCustomQuestionsData(customQuestionsData).then((result) => {
        if (result.status === 200) {
          const modifiedCustomQuestionBasket = {
            projectId: this.props.selectedProject.ProjectId,
            customQuestionBasket: this.props.customQuestionBasket,
          };
          setCustomQuestionsBasket(modifiedCustomQuestionBasket);
          this.setState({ showDeleteModal: false });
          this.hideModal();
          this.props.hideOptionalCommentRightPanel();
          this.props.cancelClick();
          this.isChecked();
        }
      });
      resolve(true);
    });
  }

  updateCustomQuestion(formData) {
    const customQuestionsData = {
      type: this.state.type,
      title: formData.question,
      questionId: this.props.model.questionId,
      segments: formData.segments,
    };

    this.props.updateCustomQuestionsData(customQuestionsData).then((result) => {
      if (result.data > 0 && result.status === 200) {
        this.hideModal();
        this.props.cancelClick();
      this.isChecked();
      }
    });
  }

  checkedVal(value) {
    this.setState({
      check: value,
    });
  }

  clearFilterValue() {
    this.setState({ stakeholderFilterGroupValue: [] });
  }

  toggleCreateSegment(createSegment) {
    const text = $('.ques-text').text();
    this.setState({ createSegment, question: text });
  }

  setSegmentValue(v) {
    const text = $('.ques-text').text();
    this.setState({ selectedSegmentValue: v, question: text });
  }

  updateAssignedSegments(segments) {
    const text = $('.ques-text').text();
    this.setState({ segments, question: text });
  }

  render() {
    const {
      isCreating, showDeleteModal, stakeholderFilterGroupValue,
      createSegment, isModalLoaded,
      segments,
    } = this.state;
    const {
      model, isCustomDomain, projectSegments,
      isProjectInDraftedState, dSegments,
      showWarningMessage, isAutomatedQuestion, associatedStakeholdersCount,
    } = this.props;
    const showSegmentOptions = ((model && model.questionId && model.isFutureDropQuestion)
      || !(model && model.questionId));
    const das = dSegments?.includes(0)
      && projectSegments.length > 0
      ? Array.from(projectSegments, segment => parseInt(segment.value, 10))
      : dSegments;
    const isSegmentApplied = stakeholderFilterGroupValue.length > 0;
    return (
      <React.Fragment>
        <RenderIf showComponent={!showDeleteModal}>
          <div
            aria-hidden="true"
            className="modal a-modal fade add-stakeholder-modal"
            id="customQuestionModal"
            data-bs-backdrop="static"
            ref={this.modalWrapperRef}
            tabindex="-1"
          >
            <div className="modal-dialog" >
              <div className="modal-content add-cstm-qstn-mdl">
                <div className="a-modal-header " style={{ height: 'auto', marginTop: '20px' }}>
                  <div className="a-flex-stretch flex-column d-flex align-items-start">
                    <div className="a-h4 ml-1 drk-gry-clr" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                      {// eslint-disable-next-line
                        model !== undefined ? 'Edit a custom question'
                          : !isCustomDomain ? 'Ask a new question' : 'Ask a new question'}
                    </div>
                    {/* eslint-disable no-nested-ternary */}
                    {showSegmentOptions && (showWarningMessage || !das?.includes(0)) && <div style={{ backgroundColor: '#faf2d8', padding: '5px' }} className="a-text-caption mt-1 ml-1" >
                      {showWarningMessage ? `The applied filter groups are not part of the audience
                      associated with this focus area. To send question(s) to the applied filter group. Please create a new segment and include
                      that segment in the audience for this focus area` : !das?.includes(0) ? `The associated focus area is configured for the particular segment(s).
                      You can only send questions to the configured segment(s)` : ''}
                    </div>}
                    {showSegmentOptions && <div className="a-text-caption mt-1 ml-1 drk-gry-clr" style={{ fontSize: '12px' }}>
                      {// eslint-disable-next-line
                        stakeholderFilterGroupValue.length > 0 ? 'Select the groups that will receive this question:'
                          : 'Select the stakeholders that will receive the question:'}
                    </div>}

                  </div>
                  <span className="icon-wrapper" style={{ position: 'absolute', top: '5px', right: '5px' }}>
                    <a href="javascript:;" onClick={() => this.cancelClick()}>
                      <i
                        aria-label="Close"
                        className="appkiticon icon-close-fill a-close-btn"
                        data-bs-dismiss="modal"
                      />
                    </a>
                  </span>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={this.state}
                  validateOnBlur={true}
                  // eslint-disable-next-line
                  onSubmit={(values, actions) => {
                    // eslint-disable-next-line
                    this.onContinueButtonClick(values, actions);
                  }}
                  validationSchema={Yup.object().shape({
                    question: !isAutomatedQuestion ? Yup.string()
                      .trim()
                      .required('Question is required.')
                      .max(100, 'You have exceeded the maximum character limit') : Yup.string(),
                  })}
                >
                  {({
                    errors, touched, values,
                  }) => (
                    <Form>
                      <div className="a-modal-body py-0">
                        <div className="form-group">
                          <div className='filter-segment-group mb-3' role="group" aria-labelledby="my-radio-group">
                            {stakeholderFilterGroupValue.length > 0 && <label className="flex-column" style={{ border: createSegment && createSegment === 'yes' ? '2.4px solid #eb590b' : '', borderRadius: '5px' }}>
                              <div className="d-flex mb-1">
                                <div class="a-radio a-mx-10">
                                  <Field onClick={() => this.toggleCreateSegment('yes')} type="radio" name="createSegment" value="yes" checked={createSegment && createSegment === 'yes'} />
                                  <span class="a-radio-mark">
                                    <span class="a-radio-inner" />
                                  </span>
                                </div>

                                <span className="radio-btn-title preview-pane-font" style={{ fontWeight: 'bold', color: '#7D7D7D' }}>Create a new segment:</span>
                              </div>
                              <div className="filters-wrapper ml-0">
                                <div className="custom-question-filter">
                                  <div className="selected-filter-wrap">
                                    <div className="selected-filter-tags">
                                      <div className="d-flex flex-wrap align-items-start">
                                        {stakeholderFilterGroupValue && stakeholderFilterGroupValue.map((item, index) => (<div key={index} class="a-tag a-f2 selected-filter-tag">
                                          <span style={{ lineHeight: '130%', fontSize: '12px' }}>{item}</span>
                                        </div>))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </label>}
                            <label className={stakeholderFilterGroupValue.length === 0 ? 'sgmnt-only-optn mt-0' : ''} style={{ border: createSegment && createSegment === 'no' ? '2.4px solid #eb590b' : '', flexDirection: 'column', borderRadius: '5px' }}>
                              <div className="d-flex mb-1">
                                <div class="a-radio a-mx-10">
                                  <Field onClick={() => this.toggleCreateSegment('no')} type="radio" name="createSegment" value="no" checked={createSegment && createSegment === 'no'} />
                                  <span class="a-radio-mark">
                                    <span class="a-radio-inner" />
                                  </span>
                                </div>
                                <span className="radio-btn-title preview-pane-font" style={{ fontWeight: 'bold', color: '#7D7D7D' }}>Send to an existing segment:</span>
                              </div>
                              {projectSegments && projectSegments.length > 0
                                && <div className="filters-wrapper">
                                  <div className="d-inline-block g-timeing-wrap mr-3">
                                    <div className={isSegmentApplied ? 'a-dropdown acq-modal acc_data' : 'a-dropdown acq-modal'}>
                                      {isModalLoaded && <SumoSelectSegmentDropDown
                                        projectSegments={projectSegments}
                                        selectedSegments={segments}
                                        domainSegments={das}
                                        checked={value => this.checkedVal(value)}
                                        val={this.state.check}
                                        updateAssignedSegments={
                                          s => this.updateAssignedSegments(s)}
                                      />}
                                    </div>
                                  </div></div>}
                            </label>
                          </div>
                          {!isAutomatedQuestion && (
                            <React.Fragment>
                              <div data-for='yesnoques'
                                data-tip='tooltip'
                                class={`mb-2 a-btn-group d-flex align-items-center toggle-btn-wrap ${(this.props.questionDeployed !== undefined
                                  && this.props.questionDeployed) ? 'disable-yesno' : ''}`}>
                                <button
                                  // eslint-disable-next-line no-undef
                                  className={`ques-option toggle-btn toggle-l d-block w-50 pointer ${model === undefined || (model !== undefined && model.type === 1) ? 'active' : ''}`}
                                  toggle-active="active"
                                  type="button"
                                  value='Yes/No'
                                  id="yesno"
                                  disabled={!!(this.props.questionDeployed !== undefined
                                    && this.props.questionDeployed)}
                                  onClick={() => this.selectType(YESNO_QUESTION, 'yesno')}>
                                  <span class="a-ellipsis preview-pane-font">Yes/No Question</span>
                                </button>
                                <button
                                  className={`ques-option toggle-btn toggle-r d-block w-50 pointer ${model !== undefined && model.type === 2 ? 'active' : ''}`}
                                  toggle-active="active"
                                  type="button"
                                  value='Open Text'
                                  id="opentext"
                                  disabled={!!(this.props.questionDeployed !== undefined
                                    && this.props.questionDeployed)}
                                  onClick={() => this.selectType(OPENTYPE_QUESTION, 'opentext')}>
                                  <span class="a-ellipsis preview-pane-font">Open Text Question</span>
                                </button>
                              </div>
                              {(!!(this.props.questionDeployed !== undefined
                                && this.props.questionDeployed))
                                && <ReactTooltip id='yesnoques' className='completion_tooltip' place='top'
                                  effect='solid'>
                                  Question type cannot be changed as this
                                  question has already been sent to topic stakeholders.
                                </ReactTooltip>
                              }
                              <Field
                                component="textarea"
                                autoComplete="off"
                                name="question"
                                style={{ maxHeight: '105px' }}
                                placeholder={this.state.type === 2 ? `Type a statement that stakeholder will agree or disagree to.
E.g. What is the one thing that you would change about this project right now?`
                                  : `Type a statement that stakeholder will agree or disagree to.
E.g. We have the means to get extra resource if required.`}
                                className={
                                  `${errors.question && touched.question ? 'error a-text-input ques-text' : 'ques-text a-text-input'} placeholder12`
                                }
                              />
                              <div className="d-flex align-items-center justify-content-between">

                                <div className="error">
                                  <ErrorMessage name="question" />
                                </div>
                                {values.question.length > 0
                                  && <div className="limit-wrap text-right">
                                    <small style={{ color: values.question.length > 100 ? '#FF0000' : '#000' }}>{values.question.length}/100 Characters</small>
                                  </div>
                                }
                              </div>
                              <div>
                                {!isCustomDomain
                                  ? <small className="modal-h-tagline mdl-info align-items-center" style={{ fontSize: '12px' }}><i id="completionMessage" data-for="completionMessage"
                                    data-tip="completion_tooltip" style={{ marginRight: '5px', backgroundColor: 'white', color: 'rgba(0, 0, 0, 1)' }}
                                    class="material-icons md-dark" currentitem="false">info</i>Custom question does not contribute to the overall confidence score</small>
                                  : <div class="cstm-dmn-info">
                                    <ul className="custom-q-header" style={{ fontSize: '12px' }}>
                                      <li style={{ fontSize: '12px' }}>This question does not
                                        contribute to overall confidence score </li>
                                      <li style={{ fontSize: '12px' }}>This question will be added to all future cycles. </li>
                                    </ul>
                                  </div>}
                              </div>
                            </React.Fragment>
                          )}

                        </div>
                        {!isAutomatedQuestion && isCustomDomain && model === undefined
                          && !isProjectInDraftedState && <div>
                            <label class="a-checkbox a-mx-10">
                              <Field type="checkbox"
                                name='sendNow'
                                onChange={() => {
                                  this.setState({
                                    sendNow: !values.sendNow,
                                    question: values.question,
                                  });
                                }}
                              />
                              <span class="a-checkbox-mark">
                                <span class="appkiticon icon-check-mark-fill"></span>
                              </span>
                              <span class="a-checkbox-text preview-pane-font" style={{ fontSize: '12px' }}>  Send question now (optional)</span>
                            </label>
                          </div>
                        }
                      </div>
                      <div className="a-modal-footer a-border-tp justify-content-end c-question-ask">
                        {model !== undefined
                          && <button style={{ fontSize: '0.875rem' }} className="btn c-question-cancel mr-auto" onClick={() => this.deleteCustomQuestion(model)}>Delete Question</button>}
                        <button style={{ fontSize: '0.875rem' }} className="btn c-question-cancel" onClick={() => this.cancelClick()}>Cancel</button>
                        <SpinnerButton isCustomQuestionPane={true} data-tip-disable={false} data-for='create-segment'
                          isShowTooltip={(createSegment === 'yes' && associatedStakeholdersCount < projectConstant.MINIMUMSEGMENTUSERS)}
                          data-tip='create-seg-tooltip'
                          isLoading={isCreating}
                          disabled={(createSegment === 'yes' && associatedStakeholdersCount < projectConstant.MINIMUMSEGMENTUSERS)
                            || (createSegment === 'no' && segments.length === 0)
                            || (!isAutomatedQuestion && (!(values.question.length > 0)
                              || values.question.length > 100))}
                          type="transparent"
                          label={model === undefined ? 'Ask' : 'Save'} />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div >
        </RenderIf>
        <RenderIf showComponent={showDeleteModal}>
          <DeleteConfirmation
            title={'Delete this question?'}
            message={[<p>You will lose all responses associated with this question.
              We can't recover them once you delete it.</p>, <p class="mb-0">Are you sure you want to <span className="deletemessage-color preview-pane-font">permanently delete</span> this question?</p>]}
            confirmationClick={() => this.deleteCustomQuestionFromDatabase(model)}
            cancelClick={() => this.setState({ showDeleteModal: false })}
            deleteButtomLabel={'Yes, delete it'}
          />
        </RenderIf>
      </React.Fragment>

    );
  }
}

AddCustomQuestion.propTypes = {
  projectId: PropTypes.number.isRequired,
  domainId: PropTypes.number.isRequired,
  domainName: PropTypes.string.isRequired,
  attributeId: PropTypes.number.isRequired,
  attributeName: PropTypes.string.isRequired,
  isCustomDomain: PropTypes.bool.isRequired,
  questionDeployed: PropTypes.bool,
};

AddCustomQuestion.defaultProps = {
  userSelectedFilterGroupValues: {
    selectedGroupValue1: [],
    selectedGroupValue2: [],
    selectedGroupValue3: [],
    selectedGroupValue4: [],
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCustomQuestion);
