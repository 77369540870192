import React, { useEffect, useState } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import { connect } from 'react-redux';
import { CSV_CONSTANTS } from '../../Constants';

function CSVFilterDownloaderUI(props) {
  const [csvName, setCsvName] = useState('stackholders');
  const { CSVDownloader, Type } = useCSVDownloader();
  const { SAMPLE_FILTER_NAME } = CSV_CONSTANTS;
  let data = [];
  const {
    title,
    projectStakeholders, userFilterGroups, errorDetect,
    errorUsersCsv,
  } = props;
  useEffect(() => {
    const csvColumnName = errorDetect ? 'CSV_with_errors' : 'stakeholder_listing';
    setCsvName(csvColumnName);
  }, [errorDetect]);
  const getFilterNames = (object, error) => {
    if (error) {
      return Object.keys(object);
    }
    const {
 filterGroupName1, filterGroupName2, filterGroupName3, filterGroupName4,
} = object;
    return [
              filterGroupName1 || SAMPLE_FILTER_NAME.filter_1,
              filterGroupName2 || SAMPLE_FILTER_NAME.filter_2,
              filterGroupName3 || SAMPLE_FILTER_NAME.filter_3,
              filterGroupName4 || SAMPLE_FILTER_NAME.filter_4,
          ];
  };
if (errorDetect) {
  data = errorUsersCsv;
} else {
  const [columnName1,
    columnName2, columnName3, columnName4] = getFilterNames(userFilterGroups, false);
  data = projectStakeholders.map(record => ({
                                                  Email: record.email_address,
                                                  [columnName1]: record.filterGroupValue1,
                                                  [columnName2]: record.filterGroupValue2,
                                                  [columnName3]: record.filterGroupValue3,
                                                  [columnName4]: record.filterGroupValue4,
                                                }));
}

  return (
    <CSVDownloader
      type={Type.Link}
      filename={csvName}
      className="csv-downloader"
      bom={true}
      config={{
        delimiter: ',',
      }}
      data={data}
    >
      {title}
    </CSVDownloader>
  );
}
const mapStateToProps = state => ({
  projectStakeholders: state.project.projectStakeholders,
  userFilterGroups: state.project.userFilterGroups,
  errorStackholders: state.project.errorStackholders,
});
export default connect(mapStateToProps, null)(CSVFilterDownloaderUI);
