import React, { Component } from 'react';

class Spinner extends Component {
  render() {
    const { isLoading, showLoadingMessage, loadingMessage } = this.props;

    return (
      <React.Fragment>
      {/* this class with condition */}
        {isLoading && (
          <button className={`a-btn a-btn-transparent a-btn-lg a-no-interaction theme-loader ${showLoadingMessage ? 'flex-column' : ''}`}>
            <i className="a-loading-one a-primary" />
            {showLoadingMessage && (<div className="loader-text">
            {loadingMessage}
            </div>)}
          </button>
        )}
      </React.Fragment>
    );
  }
}

export default Spinner;
