import React from 'react';
import { connect } from 'react-redux';
import '../styles/pagecontent.css';


import Frame from '../../../assets/images/component/Frame4.png';

class SplashFive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: props.isTablet,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
  }

  handleClick() {
    this.props.gettingStarted();
  }

  openInNewTab(url) {
    window.open(url, '_blank', 'noreferrer');
  }

  render() {
    const { isData } = this.props;
    return (
      <React.Fragment>
        <div class="wrapper fade-in-getting-started get-start-content" style={{ backgroundColor: '#fff' }}>
          <div class="container text-center" style={{ backgroundColor: '#fff' }}>
            <div class="get-started-img">
              <img class="img-fluid" src={Frame} />
            </div>
            <div class="page-content">
              <div class="description-container">
                <p class="description mb-0">Filter your results data and compare across stakeholder teams to identify insights. </p>              </div>
              {isData.stakeholderHasAccessToDashboard || isData.isUserStakeholder
                ? <a onClick={() => this.handleClick()} class="get-started-btn button-create-topic">Continue</a>
                : <a role="link" class="get-started-btn button-create-topic"
                  onClick={() => this.openInNewTab('https://sites.google.com/pwc.com/project-perspectives-au/support')}>Continue</a>}
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


const mapDispatchToProps = {

};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SplashFive);
