import React from 'react';
import * as $ from 'jquery';
import './SkeltonLoadingDropDown.css';
import './customSelectDropDown.css';

class SkeltonLoadingDropDown extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.state = {};
      }

    componentDidMount() {
        this.init();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    init() {
        $(() => {
          const selectdd = $('.projects-container .dropdown-select');
          selectdd.on('click', function () {
            $(this).parent('.dropdown-container').toggleClass('active');
          });
        });
      }

    handleClickOutside() {
        $('.dropdown-container').removeClass('active');
    }

    render() {
        return (
            <React.Fragment>
                <div className="d-inline-block g-timeing-wrap custom-select-sgmnt-dropdown custom-select-skelton-dropdown mr-3" style={{ marginTop: '0.9rem' }}>
                    <div className={'row'}>
                        <div className="a-dropdown">
                            <div className={'cstm-slct-drp-dwn projects-container'}>
                                <div class="dropdown-container badge-dropdown">
                                    <div data-for={'selectedSegmentValue segment-dd-label'} data-tip={'segment-dd-label_tooltip'} className="dropdown-select entypo-down-open-big lightgrey">
                                        <span class="cstm-sgmntdd skeltons-li" style={{ padding: '11.5px 0px', height: '100%' }}>
                                            <span className="skelton skelton-bg" style={{ height: '100%' }}></span>
                                        </span>
                                    </div>
                                    <ul class="dropdown-select-ul dd-body data-scroller-container">
                                        <li id="Segment-1" data-for="Segment-10" data-tip="Segment-10_tooltip" className=" dropdown-item-c" data-value="-1">
                                            <div class="badge-label skeltons-li" style={{ padding: '11.5px 0px' }}>
                                                <div className="skelton skelton-1"></div>
                                            </div>
                                        </li>
                                        <li id="Segment-1" data-for="Segment-11" data-tip="Segment-11_tooltip" className=" dropdown-item-c" data-value="-1">
                                            <div class="badge-label skeltons-li" style={{ padding: '11.5px 0px' }}>
                                                <div className="skelton skelton-1"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


export default SkeltonLoadingDropDown;
