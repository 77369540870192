import React, { Component } from 'react';
import '../styles/settings.scss';
import '@pwc/appkit-react/style/appkit-react.default.css';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import moment from 'moment-timezone';
import { Formik, Field } from 'formik';
import $ from 'jquery';
import {
  setHeading,
  toggleAllCheckboxes,
  toggleCheckbox,
  getArchiveQuestionModelList,
  restoreArchiveQuestionModel,
  getArchiveOrganisationList,
  getArchiveTopicList,
  toggleAllOrgCheckboxes,
  toggleAllTopicCheckboxes,
  toggleOrgCheckbox,
  toggleTopicCheckbox,
  restoreArchiveOrg,
  restoreArchiveTopic,
} from '../actions/archiveQuestionModel';
import {
  getQuestionModels,
} from '../../question-model/actions/questionModel';
import archiveQuestionSelector from '../selectors/archiveQuestionModel';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import RenderIf from '../../../../ui-components/Common';
import { Spinner } from '../../../../ui-components/Loader';
import Pagination from '../../../../ui-components/Pagination/Pagination.jsx';
import {
  NO_OF_RECORDS, INITIAL_PAGINATED_DATA, FEEDBACK_PAGE_REF,
} from '../../../../Constants';


import { getSystemTerminology } from '../../question-model/actions/general';
import { getSystemTerminologySelector } from '../../question-model/selectors/general';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';


const mapDispatchToProps = {
  setHeading,
  toggleAllCheckboxes,
  toggleCheckbox,
  getSystemTerminology,
  getArchiveQuestionModelList,
  getQuestionModels,
  getArchiveOrganisationList,
  getArchiveTopicList,
  toggleAllOrgCheckboxes,
  toggleAllTopicCheckboxes,
  toggleOrgCheckbox,
  toggleTopicCheckbox,
};

const mapStateToProps = state => ({
  archiveQuestionModel: archiveQuestionSelector(state),
  systemTerminology: getSystemTerminologySelector(state),
});

const messages = {
  restoreModelTitle: 'Restore Question Model',
  restoreModelConfirmationMessage: 'By continuing, You will be restoring this Question Model. Do you wish to Restore?',
  bulkRestoreModelTitle: 'Restore Question Models',
  bulkRestoreModelConfirmationMessage: 'By continuing, You will be restoring these Question Models. Do you wish to Restore?',
  restoreOrgTitle: 'Restore Organisation',
  restoreOrgConfirmationMessage: 'By continuing, You will be restoring this Organisation and any Org Admins and Org Viewers. Please ensure access is appropriate before sharing with others. Do you wish to Restore?',
  bulkRestoreOrgTitle: 'Restore Organisations',
  bulkRestoreOrgConfirmationMessage: 'By continuing, You will be restoring these Organisations and any Org Admins and Org Viewers. Please ensure access is appropriate before sharing with others. Do you wish to Restore?',
  restoreTopicTitle: 'Restore Topic',
  restoreTopicConfirmationMessage: 'By continuing, You will be restoring this Topic and any Topic Admins and Topic Viewers. Please ensure access is appropriate before sharing with others. Do you wish to Restore?',
  bulkRestoreTopicTitle: 'Restore Topics',
  bulkRestoreTopicConfirmationMessage: 'By continuing, You will be restoring these Topics and any Topics Admins and Topics Viewers. Please ensure access is appropriate before sharing with others. Do you wish to Restore?',
};

class ArchiveQuestionModels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      heading: 'Archive',
      showRestoreModel: false,
      selectedIdsForRestore: [],
      showAlertPopup: false,
      restoreConfirmationMessage: '',
      isLoading: true,
      isFilterResetting: false,
      listLoading: true,
      currentPage: 1,
      totalPages: 1,
      currentOrganisationPage: 1,
      totalOrganisationPages: 1,
      currentTopicPage: 1,
      totalTopicPages: 1,
      questionFilters: {
        selectedKeyword: '',
      },
      successfullRestoredMessage: '',
      isEnableResetButton: true,
      filteredProjectList: [],
      isModelGroup: false,
      isOrganisationGroup: false,
      isTopicGroup: false,
      showRestoreOrgModel: false,
      selectedOrgIdsForRestore: [],
      restoreOrgTitle: '',
      restoreOrgConfirmationMessage: '',
      showRestoreTopicModel: false,
      selectedTopicIdsForRestore: [],
      restoreTopicTitle: '',
      restoreTopicConfirmationMessage: '',
    };

    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.confirmBulkRestoreModel = this.confirmBulkRestoreModel.bind(this);
    this.cancelRestoreModel = this.cancelRestoreModel.bind(this);
    this.cancelOrgRestoreModel = this.cancelOrgRestoreModel.bind(this);
    this.cancelTopicRestoreModel = this.cancelTopicRestoreModel.bind(this);
    this.restoreQuestionModel = this.restoreQuestionModel.bind(this);
    this.restoreArchiveOrg = this.restoreArchiveOrg.bind(this);
    this.restoreArchiveTopic = this.restoreArchiveTopic.bind(this);
    this.toggleALLOrgCheckboxes = this.toggleALLOrgCheckboxes.bind(this);
    this.toggleALLTopicCheckboxes = this.toggleALLTopicCheckboxes.bind(this);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    this.props.setHeading(this.state.heading);
    this.setState({ isLoading: true });
    this.getArchiveModelRecords(null, INITIAL_PAGINATED_DATA);
  }

  filterQuestionModels(filters) {
    const questionModel = filters;
    questionModel.selectedKeyword = questionModel.selectedKeyword;
    this.setState({
      questionFilters: questionModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (questionModel.selectedKeyword) {
      this.setState({ isEnableResetButton: false });
    }
    this.getArchiveModelRecords(filters, { noOfRecords: NO_OF_RECORDS, pageNo: 0 });
  }

  filterArchiveOrgs(filters) {
    const questionModel = filters;
    questionModel.selectedKeyword = questionModel.selectedKeyword;
    this.setState({
      questionFilters: questionModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (questionModel.selectedKeyword) {
      this.setState({ isEnableResetButton: false });
    }
    this.getArchiveOrganisationRecords(filters, { noOfRecords: NO_OF_RECORDS, pageNo: 0 });
  }

  filterArchiveTopics(filters) {
    const questionModel = filters;
    questionModel.selectedKeyword = questionModel.selectedKeyword;
    this.setState({
      questionFilters: questionModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (questionModel.selectedKeyword) {
      this.setState({ isEnableResetButton: false });
    }
    this.getArchiveTopicRecords(filters, { noOfRecords: NO_OF_RECORDS, pageNo: 0 });
  }

  getTotalNumOfPages() {
    return this.props.archiveQuestionModel.archiveModelCount !== 0
      ? Math.ceil(this.props.archiveQuestionModel.archiveModelCount / NO_OF_RECORDS)
      : 1;
  }

  getTotalNumOfOrgPages() {
    return this.props.archiveQuestionModel.archiveOrganisationCount !== 0
      ? Math.ceil(this.props.archiveQuestionModel.archiveOrganisationCount / NO_OF_RECORDS)
      : 1;
  }

  getTotalNumOfTopicPages() {
    return this.props.archiveQuestionModel.archiveTopicCount !== 0
      ? Math.ceil(this.props.archiveQuestionModel.archiveTopicCount / NO_OF_RECORDS)
      : 1;
  }

  getArchiveModelRecords(filters, PaginatedData) {
    this.setState({
      isModelGroup: true,
      listLoading: true,
      isOrganisationGroup: false,
      isTopicGroup: false,
    });
    this.props.getArchiveQuestionModelList(filters, PaginatedData).then(() => {
      this.setState({
        listLoading: false,
        totalPages: this.getTotalNumOfPages(),
      });
    });
    this.setState({
      isLoading: false,
    });
  }

  getArchiveOrganisationRecords(filters, PaginatedData) {
    this.setState({
      isModelGroup: false,
      listLoading: true,
      isOrganisationGroup: true,
      isTopicGroup: false,
    });
    this.props.getArchiveOrganisationList(filters, PaginatedData).then(() => {
      this.setState({
        listLoading: false,
        totalOrganisationPages: this.getTotalNumOfOrgPages(),
      });
    });
    this.setState({
      isLoading: false,
    });
  }

  getArchiveTopicRecords(filters, PaginatedData) {
    this.setState({
      isModelGroup: false,
      listLoading: true,
      isOrganisationGroup: false,
      isTopicGroup: true,
    });
    this.props.getArchiveTopicList(filters, PaginatedData).then(() => {
      this.setState({
        listLoading: false,
        totalTopicPages: this.getTotalNumOfTopicPages(),
      });
    });
    this.setState({
      isLoading: false,
    });
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleALLOrgCheckboxes() {
    this.props.toggleAllOrgCheckboxes();
  }

  toggleALLTopicCheckboxes() {
    this.props.toggleAllTopicCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  toggleOrgCheckbox(item) {
    this.props.toggleOrgCheckbox(item);
  }

  toggleTopicCheckbox(item) {
    this.props.toggleTopicCheckbox(item);
  }

  confirmBulkRestoreModel() {
    if (this.props.archiveQuestionModel.archiveModelList.length > 0) {
      const selectedIds = [];
      this.props.archiveQuestionModel.archiveModelList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });
      this.setState({
        selectedIdsForRestore: selectedIds,
        showRestoreModel: true,
        restoreModelTitle: messages.bulkRestoreModelTitle,
        restoreConfirmationMessage: messages.bulkRestoreModelConfirmationMessage,
      });
    }
  }

  confirmBulkRestoreTopics() {
    if (this.props.archiveQuestionModel.archiveTopicList.length > 0) {
      const selectedIds = [];
      this.props.archiveQuestionModel.archiveTopicList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });
      this.setState({
        selectedTopicIdsForRestore: selectedIds,
        showRestoreTopicModel: true,
        restoreTopicTitle: messages.bulkRestoreTopicTitle,
        restoreTopicConfirmationMessage: messages.bulkRestoreTopicConfirmationMessage,
      });
    }
  }

  confirmBulkRestoreOrgs() {
    if (this.props.archiveQuestionModel.archiveOrganisationList.length > 0) {
      const selectedIds = [];
      this.props.archiveQuestionModel.archiveOrganisationList.filter((item) => {
        if (item.isChecked === true) selectedIds.push(item.id);
        return item;
      });
      this.setState({
        selectedOrgIdsForRestore: selectedIds,
        showRestoreOrgModel: true,
        restoreOrgTitle: messages.bulkRestoreOrgTitle,
        restoreOrgConfirmationMessage: messages.bulkRestoreOrgConfirmationMessage,
      });
    }
  }

  cancelRestoreModel() {
    this.setState({
      selectedIdsForRestore: [],
      showRestoreModel: false,
    });
  }

  cancelOrgRestoreModel() {
    this.setState({
      selectedOrgIdsForRestore: [],
      showRestoreOrgModel: false,
    });
  }

  cancelTopicRestoreModel() {
    this.setState({
      selectedTopicIdsForRestore: [],
      showRestoreTopicModel: false,
    });
  }

  // Restore question models
  restoreQuestionModel() {
    const data = {
      questionModelIds: this.state.selectedIdsForRestore,
      isArchive: false,
    };
    return restoreArchiveQuestionModel(data)
      .then((response) => {
        if (response && response.length > 1) {
          this.setState({ successfullRestoredMessage: 'Selected Question Models have been successfully restored.' });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        } else {
          this.setState({ successfullRestoredMessage: `${response[0].title} has been successfully restored.` });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        }
        this.props.getArchiveQuestionModelList(
          this.state.questionFilters,
          INITIAL_PAGINATED_DATA,
        ).then(() => {
          this.setState({
            totalPages: this.getTotalNumOfPages(),
            selectedIdsForRestore: [],
            currentPage: 1,
          });
        });
        this.props.getQuestionModels();
        return response;
      });
  }

  // Restore Organisation
  restoreArchiveOrg() {
    const data = {
      orgIds: this.state.selectedOrgIdsForRestore,
      isArchive: false,
    };
    return restoreArchiveOrg(data)
      .then((response) => {
        if (response && response.length > 1) {
          this.setState({ successfullRestoredMessage: 'Selected Organisations have been successfully restored.' });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        } else {
          this.setState({ successfullRestoredMessage: 'Organisation has been successfully restored.' });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        }
        this.props.getArchiveOrganisationList(
          this.state.questionFilters,
          INITIAL_PAGINATED_DATA,
        ).then(() => {
          this.setState({
            totalOrgPages: this.getTotalNumOfOrgPages(),
            selectedOrgIdsForRestore: [],
            currentOrgPage: 1,
          });
        });
        return response;
      });
  }

  // Restore question models
  restoreArchiveTopic() {
    const data = {
      topicIds: this.state.selectedTopicIdsForRestore,
      isArchive: false,
    };
    return restoreArchiveTopic(data)
      .then((response) => {
        if (response && response.length > 1) {
          this.setState({ successfullRestoredMessage: 'Selected Topics have been successfully restored.' });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        } else {
          this.setState({ successfullRestoredMessage: 'Topic has been successfully restored.' });
          $('.a-alert').removeClass('a-alert-hide');
          $('.a-alert').addClass('show');
          setTimeout(() => $('.a-alert').addClass('a-alert-hide'), 5000);
        }
        this.props.getArchiveTopicList(
          this.state.questionFilters,
          INITIAL_PAGINATED_DATA,
        ).then(() => {
          this.setState({
            totalTopicPages: this.getTotalNumOfTopicPages(),
            selectedTopicIdsForRestore: [],
            currentTopicPage: 1,
          });
        });
        return response;
      });
  }

  // paging code
  nextPage() {
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) this.updateCurrentPage(currentPage + 1, true);
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage(currentPage - 1, true);
  }

  // paging code
  nextOrganisationPage() {
    const { currentOrganisationPage } = this.state;
    if (currentOrganisationPage < this.state.totalOrganisationPages) {
      this.updateOrgCurrentPage(currentOrganisationPage + 1, true);
    }
  }

  prevOrganisationPage() {
    const { currentOrganisationPage } = this.state;
    if (currentOrganisationPage > 1) this.updateOrgCurrentPage(currentOrganisationPage - 1, true);
  }

  // paging code
  nextTopicPage() {
    const { currentTopicPage } = this.state;
    if (currentTopicPage < this.state.totalTopicPages) {
      this.updateTopicCurrentPage(currentTopicPage + 1, true);
    }
  }

  prevTopicPage() {
    const { currentTopicPage } = this.state;
    if (currentTopicPage > 1) this.updateTopicCurrentPage(currentTopicPage - 1, true);
  }

  updateCurrentPage(pageNum, status) {
    this.setState({
      currentPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getArchiveModelRecords(this.state.questionFilters, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: pageNum - 1,
      });
    }
  }

  updateOrgCurrentPage(pageNum, status) {
    this.setState({
      currentOrganisationPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getArchiveOrganisationRecords(this.state.questionFilters, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: pageNum - 1,
      });
    }
  }

  updateTopicCurrentPage(pageNum, status) {
    this.setState({
      currentTopicPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getArchiveTopicRecords(this.state.questionFilters, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: pageNum - 1,
      });
    }
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value, true);
    } else {
      this.updateCurrentPage(1, true);
    }
  }

  goToOrgPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalOrganisationPages, 10)) {
      this.updateOrgCurrentPage(value, true);
    } else {
      this.updateOrgCurrentPage(1, true);
    }
  }

  goToTopicPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalTopicPages, 10)) {
      this.updateTopicCurrentPage(value, true);
    } else {
      this.updateTopicCurrentPage(1, true);
    }
  }

  pageOnChangeEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateCurrentPage(value, false);
  }

  pageOnChangeOrgEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateOrgCurrentPage(value, false);
  }

  pageOnChangeTopicEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateTopicCurrentPage(value, false);
  }

  openRestoreQuestionModel(item) {
    this.setState({
      selectedIdsForRestore: [item.id],
      showRestoreModel: true,
      restoreModelTitle: messages.restoreModelTitle,
      restoreConfirmationMessage: messages.restoreModelConfirmationMessage,
    });
  }

  openRestoreOrgModel(item) {
    this.setState({
      selectedOrgIdsForRestore: [item.id],
      showRestoreOrgModel: true,
      restoreOrgTitle: messages.restoreOrgTitle,
      restoreOrgConfirmationMessage: messages.restoreOrgConfirmationMessage,
    });
  }

  openRestoreTopicModel(item) {
    this.setState({
      selectedTopicIdsForRestore: [item.id],
      showRestoreTopicModel: true,
      restoreTopicTitle: messages.restoreTopicTitle,
      restoreTopicConfirmationMessage: messages.restoreTopicConfirmationMessage,
    });
  }

  onClickGroup(group) {
    switch (true) {
      case group === 'ModelGroup':
        return this.setState({
          isModelGroup: true,
          isOrganisationGroup: false,
          isTopicGroup: false,
        });
      case group === 'OrganisationGroup':
        return this.getArchiveOrganisationRecords(null, INITIAL_PAGINATED_DATA);
      case group === 'TopicGroup':
        return this.getArchiveTopicRecords(null, INITIAL_PAGINATED_DATA);
      default:
        return '';
    }
  }

  render() {
    const {
      isLoading, totalPages, currentPage, listLoading, isFilterResetting,
      isModelGroup, isOrganisationGroup, isTopicGroup, totalOrganisationPages,
      totalTopicPages, currentOrganisationPage, currentTopicPage,
    } = this.state;
    const { archiveQuestionModel } = this.props;
    const questionModel = {
      selectedKeyword: this.state.questionFilters.selectedKeyword,
    };

    return (
      <div>
        <Spinner isLoading={isLoading} />
        <RenderIf showComponent={!isLoading}>
          <div className="users-container">
            <div class="row justify-content-between align-items-center pb-1 pt-1 m-0 mb-3">
              <div class="col">
                {!isFilterResetting && <Formik
                  enableReinitialize={true}
                  initialValues={questionModel}>
                  {({ setFieldValue, values }) => (
                    <div class="row justify-content-center align-items-center">
                      <div class="col-md-6 col-lg-3 ml-auto">
                        <div class="row justify-content-start">
                          <div class="col px-0">
                            <div class="input-group-with-icon">
                              {isModelGroup && <Field
                                name='selectedKeyword'
                                placeholder="Search"
                                class="w-100 custom-input-filter-icon"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue('selectedKeyword', e.target.value);
                                  values.selectedKeyword = e.target.value;
                                  this.filterQuestionModels(values);
                                }}
                              />}
                              {isOrganisationGroup && <Field
                                name='selectedKeyword'
                                placeholder="Search"
                                class="w-100 custom-input-filter-icon"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue('selectedKeyword', e.target.value);
                                  values.selectedKeyword = e.target.value;
                                  this.filterArchiveOrgs(values);
                                }}
                              />}
                              {isTopicGroup && <Field
                                name='selectedKeyword'
                                placeholder="Search"
                                class="w-100 custom-input-filter-icon"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue('selectedKeyword', e.target.value);
                                  values.selectedKeyword = e.target.value;
                                  this.filterArchiveTopics(values);
                                }}
                              />}
                              <MaterialIcon className="material-icons md-24 text-theme-color " icon="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>}
              </div>
            </div>

            <div className="row a-table-wrapper">
              <div className="col-md-12">
                <div className="a-table-wrapper row">
                  <div className="col-md-12">
                    <div className="archive-multi-group-line">
                      <div className="header">
                        <span onClick={() => this.onClickGroup('ModelGroup')} className={`group-number ${isModelGroup ? 'active' : ''}`}>Question Model</span>
                        <span onClick={() => this.onClickGroup('OrganisationGroup')} className={`group-number ${isOrganisationGroup ? 'active' : ''}`}>Organisation</span>
                        <span onClick={() => this.onClickGroup('TopicGroup')} className={`group-number ${isTopicGroup ? 'active' : ''}`}>Topic</span>
                      </div>
                    </div>
                  </div>
                  <Spinner isLoading={listLoading} />
                  {isModelGroup && <div className="a-table-scroller  users-table a-mb-10  col-md-12  pr-0 ">
                    <RenderIf showComponent={!listLoading}>
                      <table className="a-table data-table a-lg sortable selectable setting-table archive-table">
                        <thead className="a-thead">
                          <tr>
                            {archiveQuestionModel.archiveModelList.length > 0 ? (
                              <th className="checkbox-cell">
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={this.toggleAllCheckboxes}
                                    checked={
                                      archiveQuestionModel.archiveModelList
                                        .filter(item => item.isChecked).length > 0
                                    }
                                  />
                                  <span className="a-checkbox-mark">
                                    <span
                                      className={`appkiticon   
                                      ${archiveQuestionModel.archiveModelList.filter(item => item.isChecked).length > 0
                                          && !archiveQuestionModel.selectAllModels
                                          ? 'icon-minus-fill'
                                          : ''
                                        }  
                                      ${archiveQuestionModel.archiveModelList.filter(item => item.isChecked).length > 0
                                          && archiveQuestionModel.selectAllModels
                                          ? 'icon-check-mark-fill'
                                          : ''
                                        }`}
                                    />
                                  </span>
                                </label>
                              </th>
                            ) : (
                              ''
                            )}
                            <th className="action-cell">Question Model</th>
                            <th className="email-cell">Archived By</th>
                            <th className="name-cell">Archived On</th>
                            <th className="name-cell">Action</th>
                          </tr>
                        </thead>
                        <tbody className="a-tbody">
                          {archiveQuestionModel.archiveModelList.length > 0 ? (
                            archiveQuestionModel.archiveModelList.map(
                              (obj, index) => (
                                <tr key={index}>
                                  <td className="checkbox-cell">
                                    {' '}
                                    <label className="a-checkbox black-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={obj.isChecked}
                                        onChange={() => this.toggleCheckbox(obj)}
                                      />
                                      <span className="a-checkbox-mark">
                                        <span className="appkiticon icon-check-mark-fill" />
                                      </span>
                                    </label>
                                  </td>
                                  <td
                                    className="email-cell"
                                  >
                                    {obj.title}
                                  </td>
                                  <td
                                    className="name-cell"
                                  >
                                    {obj.first_name} {obj.last_name}
                                  </td>
                                  <td
                                    className="type-cell"
                                  >
                                    {moment(obj.archived_on).format('DD MMM YYYY')}
                                  </td>
                                  <td
                                    className="type-cell cursor-pointer view-action"
                                    onClick={this.openRestoreQuestionModel.bind(this, obj)}
                                  >
                                    Restore
                                  </td>
                                </tr>
                              ),
                              this,
                            )
                          ) : (
                            <tr>
                              <td colSpan="7" className="font-weight-medium">
                                There are no archived question models available to show.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </RenderIf>
                  </div>}
                  {isOrganisationGroup && <div className="a-table-scroller  users-table a-mb-10  col-md-12  pr-0 ">
                    <RenderIf showComponent={!listLoading}>
                      <table className="a-table data-table a-lg sortable selectable setting-table archive-table">
                        <thead className="a-thead">
                          <tr>
                            {archiveQuestionModel.archiveOrganisationList.length > 0 ? (
                              <th className="checkbox-cell">
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={this.toggleALLOrgCheckboxes}
                                    checked={
                                      archiveQuestionModel.archiveOrganisationList
                                        .filter(item => item.isChecked).length > 0
                                    }
                                  />
                                  <span className="a-checkbox-mark">
                                    <span
                                      className={`appkiticon   
                                      ${archiveQuestionModel.archiveOrganisationList.filter(item => item.isChecked).length > 0
                                          && !archiveQuestionModel.selectAllOrganisations
                                          ? 'icon-minus-fill'
                                          : ''
                                        }  
                                      ${archiveQuestionModel.archiveOrganisationList.filter(item => item.isChecked).length > 0
                                          && archiveQuestionModel.selectAllOrganisations
                                          ? 'icon-check-mark-fill'
                                          : ''
                                        }`}
                                    />
                                  </span>
                                </label>
                              </th>
                            ) : (
                              ''
                            )}
                            <th className="action-cell">Organisation</th>
                            <th className="email-cell">Archived By</th>
                            <th className="name-cell">Archived On</th>
                            <th className="name-cell">Action</th>
                          </tr>
                        </thead>
                        <tbody className="a-tbody">
                          {archiveQuestionModel.archiveOrganisationList.length > 0 ? (
                            archiveQuestionModel.archiveOrganisationList.map(
                              (obj, index) => (
                                <tr key={index}>
                                  <td className="checkbox-cell">
                                    {' '}
                                    <label className="a-checkbox black-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={obj.isChecked}
                                        onChange={() => this.toggleOrgCheckbox(obj)}
                                      />
                                      <span className="a-checkbox-mark">
                                        <span className="appkiticon icon-check-mark-fill" />
                                      </span>
                                    </label>
                                  </td>
                                  <td
                                    className="email-cell"
                                  >
                                    {obj.title}
                                  </td>
                                  <td
                                    className="name-cell"
                                  >
                                    {obj.first_name} {obj.last_name}
                                  </td>
                                  <td
                                    className="type-cell"
                                  >
                                    {moment(obj.updated).format('DD MMM YYYY')}
                                  </td>
                                  <td
                                    className="type-cell cursor-pointer view-action"
                                    onClick={this.openRestoreOrgModel.bind(this, obj)}
                                  >
                                    Restore
                                  </td>
                                </tr>
                              ),
                              this,
                            )
                          ) : (
                            <tr>
                              <td colSpan="7" className="font-weight-medium">
                                Click the button below to create your first user.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </RenderIf>
                  </div>}
                  {isTopicGroup && <div className="a-table-scroller  users-table a-mb-10  col-md-12  pr-0 ">
                    <RenderIf showComponent={!listLoading}>
                      <table className="a-table data-table a-lg sortable selectable setting-table archive-table">
                        <thead className="a-thead">
                          <tr>
                            {archiveQuestionModel.archiveTopicList.length > 0 ? (
                              <th className="checkbox-cell">
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={this.toggleALLTopicCheckboxes}
                                    checked={
                                      archiveQuestionModel.archiveTopicList
                                        .filter(item => item.isChecked).length > 0
                                    }
                                  />
                                  <span className="a-checkbox-mark">
                                    <span
                                      className={`appkiticon   
                                      ${archiveQuestionModel.archiveTopicList.filter(item => item.isChecked).length > 0
                                          && !archiveQuestionModel.selectAllTopics
                                          ? 'icon-minus-fill'
                                          : ''
                                        }  
                                      ${archiveQuestionModel.archiveTopicList.filter(item => item.isChecked).length > 0
                                          && archiveQuestionModel.selectAllTopics
                                          ? 'icon-check-mark-fill'
                                          : ''
                                        }`}
                                    />
                                  </span>
                                </label>
                              </th>
                            ) : (
                              ''
                            )}
                            <th className="action-cell">Topic</th>
                            <th className="action-cell">Organisation</th>
                            <th className="email-cell">Archived By</th>
                            <th className="name-cell">Archived On</th>
                            <th className="name-cell">Action</th>
                          </tr>
                        </thead>
                        <tbody className="a-tbody">
                          {archiveQuestionModel.archiveTopicList.length > 0 ? (
                            archiveQuestionModel.archiveTopicList.map(
                              (obj, index) => (
                                <tr key={index}>
                                  <td className="checkbox-cell">
                                    {' '}
                                    <label className="a-checkbox black-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={obj.isChecked}
                                        onChange={() => this.toggleTopicCheckbox(obj)}
                                      />
                                      <span className="a-checkbox-mark">
                                        <span className="appkiticon icon-check-mark-fill" />
                                      </span>
                                    </label>
                                  </td>
                                  <td
                                    className="email-cell"
                                  >
                                    {obj.title}
                                  </td>
                                  <td
                                    className="email-cell"
                                  >
                                    {obj.portfolio_title}
                                  </td>
                                  <td
                                    className="name-cell"
                                  >
                                    {obj.first_name} {obj.last_name}
                                  </td>
                                  <td
                                    className="type-cell"
                                  >
                                    {moment(obj.updated).format('DD MMM YYYY')}
                                  </td>
                                  <td
                                    className="type-cell cursor-pointer view-action"
                                    onClick={this.openRestoreTopicModel.bind(this, obj)}
                                  >
                                    Restore
                                  </td>
                                </tr>
                              ),
                              this,
                            )
                          ) : (
                            <tr>
                              <td colSpan="7" className="font-weight-medium">
                                Click the button below to create your first user.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </RenderIf>
                  </div>}
                </div>
              </div>
              {isModelGroup && <div className='col-md-6'>
                <div className="footer-dropdowns">
                  {archiveQuestionModel.archiveModelList.length > 0 ? (
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="a-btn a-btn-primary a-btn-lg"
                      data-bs-toggle="dropdown"
                      disabled={
                        archiveQuestionModel.archiveModelList
                          .filter(item => item.isChecked).length <= 0
                      }
                    >
                      ACTIONS
                      <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
                    </button>
                  ) : (
                    ''
                  )}
                  <div className="dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one">
                    <ul className="user-infor-list">
                      <li className="user-infor-list-item" onClick={() => this.confirmBulkRestoreModel()}>
                        <a href="javascript:void(0)">Restore Models</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>}
              {isModelGroup && <div className="col-md-6  pr-0  at-pagination">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  nextPageEvent={this.nextPage.bind(this)}
                  prevPageEvent={this.prevPage.bind(this)}
                  goToPageEvent={this.goToPage.bind(this)}
                  pageOnChange={this.pageOnChangeEvent.bind(this)}
                />
              </div>}
              {isOrganisationGroup && <div className='col-md-6'>
                <div className="footer-dropdowns">
                  {archiveQuestionModel.archiveOrganisationList.length > 0 ? (
                    <button
                      className="a-btn a-btn-primary a-btn-lg dropdown-toggle"
                      data-bs-toggle="dropdown"
                      disabled={
                        archiveQuestionModel.archiveOrganisationList
                          .filter(item => item.isChecked).length <= 0
                      }
                    >
                      ACTIONS
                      <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
                    </button>
                  ) : (
                    ''
                  )}
                  <ul className="user-infor-list dropdown-menu a-shadow-sm border-0">
                    <li className="user-infor-list-item" onClick={() => this.confirmBulkRestoreOrgs()}>
                      <a href="javascript:void(0)">Restore Organisations</a>
                    </li>
                  </ul>
                </div>
              </div>}
              {isOrganisationGroup && <div className="col-md-6  pr-0  at-pagination">
                <Pagination
                  currentPage={currentOrganisationPage}
                  totalPages={totalOrganisationPages}
                  nextPageEvent={this.nextOrganisationPage.bind(this)}
                  prevPageEvent={this.prevOrganisationPage.bind(this)}
                  goToPageEvent={this.goToOrgPage.bind(this)}
                  pageOnChange={this.pageOnChangeOrgEvent.bind(this)}
                />
              </div>}
              {isTopicGroup && <div className='col-md-6'>
                <div className="footer-dropdowns">
                  {archiveQuestionModel.archiveTopicList.length > 0 ? (
                    <button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="a-btn a-btn-primary a-btn-lg"
                      data-bs-toggle="dropdown"
                      disabled={
                        archiveQuestionModel.archiveTopicList
                          .filter(item => item.isChecked).length <= 0
                      }
                    >
                      ACTIONS
                      <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
                    </button>
                  ) : (
                    ''
                  )}
                  <div className="dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one">
                    <ul className="user-infor-list">
                      <li className="user-infor-list-item" onClick={() => this.confirmBulkRestoreTopics()}>
                        <a href="javascript:void(0)">Restore Topics</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>}
              {isTopicGroup && <div className="col-md-6  pr-0  at-pagination">
                <Pagination
                  currentPage={currentTopicPage}
                  totalPages={totalTopicPages}
                  nextPageEvent={this.nextTopicPage.bind(this)}
                  prevPageEvent={this.prevTopicPage.bind(this)}
                  goToPageEvent={this.goToTopicPage.bind(this)}
                  pageOnChange={this.pageOnChangeTopicEvent.bind(this)}
                />
              </div>}
            </div>
          </div>
          <div>
            {this.state.showRestoreModel && this.state.isModelGroup ? (
              <DeleteConfirmation
                title={this.state.restoreModelTitle}
                message={this.state.restoreConfirmationMessage}
                confirmationClick={this.restoreQuestionModel}
                cancelClick={this.cancelRestoreModel}
                label='RESTORE'
              />
            ) : (
              ''
            )}
            {this.state.showRestoreOrgModel && this.state.isOrganisationGroup ? (
              <DeleteConfirmation
                title={this.state.restoreOrgTitle}
                message={this.state.restoreOrgConfirmationMessage}
                confirmationClick={this.restoreArchiveOrg}
                cancelClick={this.cancelOrgRestoreModel}
                label='RESTORE'
              />
            ) : (
              ''
            )}
            {this.state.showRestoreTopicModel && this.state.isTopicGroup ? (
              <DeleteConfirmation
                title={this.state.restoreTopicTitle}
                message={this.state.restoreTopicConfirmationMessage}
                confirmationClick={this.restoreArchiveTopic}
                cancelClick={this.cancelTopicRestoreModel}
                label='RESTORE'
              />
            ) : (
              ''
            )}
          </div>

          <div className="alert alert-success success-alert-with-timer  a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success"
            style={{
              left: '50%',
              top: '100px',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
              padding: 0,
            }}
            data-hide="10">
            <div className="a-alert-content">
              <div className="a-alert-badge success">
                <span className="appkiticon icon-confirmed-fill me-0"></span>
              </div>
              <span className="a-content me-0 preview-pane-font" style={{ flexBasis: 'unset' }}>
                {this.state.successfullRestoredMessage}
              </span>
              <div className="">
                <svg width="23" height="23" class="chart">
                  <circle r="12.5" cx="12.5" cy="12.5" class="pie"/>
                </svg>
              </div>
            </div>
          </div>
        </RenderIf >
        <Feedback pageRef={FEEDBACK_PAGE_REF.USER_PAGE} isShowSupportBtn = {false}/>
      </div >
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchiveQuestionModels);
