import actionType from '../../../../redux/modules/settings/actionType';
import settingsService from '../../../../redux/services/settingsService';
import { INITIAL_PAGINATED_DATA } from '../../../../Constants';

// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

// change status of added/updated domain
export const modifyAddedDomainStatus = status => (dispatch) => {
  dispatch({ type: actionType.ADDED_DOMAIN_STATUS, data: status });
};

export const getAddedDomainStatus = status => (dispatch) => {
  dispatch({ type: actionType.NEW_ADDED_DOMAIN_STATUS, data: status });
};

export const changeAddedDomainStatus = status => (dispatch) => {
  dispatch({ type: actionType.CHANGE_ADDED_DOMAIN_STATUS, data: status });
};

export const getDomains = (
  searchValue,
  paginatedData,
  questionModelId,
) => dispatch => settingsService
    .getDomainsList(searchValue, paginatedData, questionModelId)
    .then((res) => {
      const domains = {
        domainsList: [],
        count: 0,
      };
      domains.count = res.data.count;
      res.data.domains.map((item) => {
        const data = item;
        data.isChecked = false;
        domains.domainsList.push(data);
        return item;
      });
      dispatch({ type: actionType.GET_DOMAINS_LIST, domains });
      return res;
    })
    .catch(error => error);


export const getDomainsForFilter = questionModelId => settingsService
  .getDomainsListForFilter(questionModelId)
  .then(res => res.data.domains)
  .catch(() => { });

// action method for title exist in portfolio
export function validateDomainName(domain) {
  return settingsService
    .validateDomainName(domain)
    .then(res => res.data)
    .catch({});
}


export const insertDomain = domain => dispatch => settingsService
  .insertDomain(domain)
  .then((res) => {
    dispatch(getAddedDomainStatus(true));
    return res;
  })
  .catch(error => error);

export const updateDomain = domain => dispatch => settingsService
  .updateDomain(domain)
  .then(() => {
    dispatch(modifyAddedDomainStatus(true));
  })
  .catch(() => { });


export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_CHECKBOXES });
};

export const toggleCheckbox = domain => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_CHECKBOX, domain });
};

export const canBulkDelete = selectedIds => () => settingsService
  .canBulkDeleteDomains(selectedIds)
  .then(res => res.data)
  .catch(() => { });

export const canDelete = id => () => settingsService
  .canDeleteDomains(id)
  .then(res => res.data)
  .catch(() => { });


export const deleteDomains = selectedIds => dispatch => settingsService
  .deleteDomains(selectedIds)
  .then((response) => {
    dispatch(getDomains(INITIAL_PAGINATED_DATA));

    return response;
  })
  .catch(error => error);
