import React, { useState, useEffect, useRef } from 'react';
import * as $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import SegmentIcon from './SegmentIcon';
import './segment.css';
import { addEveryoneToFirst } from '../../utils/helper';

const SegmentDropDown = ({
  projectSegments, selectedSegments, assignSegments, tooltipIdPostfix,
  isComparisonView, projectViewer,
}) => {
  const [segments, setSegment] = useState([]);
  const [isButtonActive, toggleSubmitButton] = useState(true);
  const [isDropDownActive, toggleDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const segmentOptions = addEveryoneToFirst(projectSegments);
  const toggleSegment = (id, e) => {
    e.stopPropagation();
    const EVERYONE = 0;
    let filteredSegments = [];
    if (id === EVERYONE) {
      filteredSegments.push(EVERYONE);
    } else if (segments.includes(id)) {
      filteredSegments = segments.filter(s => (s !== id && s !== EVERYONE));
    } else {
      filteredSegments = segments.filter(s => (s !== EVERYONE));
      filteredSegments.push(id);
    }
    if (filteredSegments.length === 0) {
      filteredSegments.push(EVERYONE);
    }
    setSegment(filteredSegments);
    toggleSubmitButton(filteredSegments.length === 0);
  };

  const applySegments = () => {
    if (segments.length > 0) {
      assignSegments(segments);
    }
  };

  const handleClick = (e) => {
    $(e.target).closest('.show').removeClass('show');
    setSegment(selectedSegments);
  };
  useEffect(() => {
    if (segments.length === 0 && selectedSegments.length > 0) {
      setSegment(selectedSegments);
    }
  }, []);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (isDropDownActive && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSegment(selectedSegments);
        toggleDropDown(false);
      }
    }
    document.addEventListener('click', handleOutsideClick);
  }, [isDropDownActive]);

  return (<div ref={dropdownRef}>
    <div class="badge-dropdown">
      <div class="dropdown">
        <SegmentIcon projectSegments={segmentOptions}
          selectedSegments={selectedSegments} tooltipIdPostfix={tooltipIdPostfix}
          toggleDropDown={toggleDropDown} />
        {!isComparisonView && projectViewer && <div class="dropdown-menu p-3" style={{ overflow: 'inherit' }} aria-labelledby="dropdownMenuButton" onClick={e => e.stopPropagation()}>
          <div class="dd-header mb-2">
            <div className="cstm-chk-drpdwn">
              <p class="mb-0">Select relevant segments</p>
              <div className="pro-reset-button reset-button ">
                <button onClick={e => handleClick(e)} type="button" class="close">&times;</button>
              </div>
            </div>
            <span className='preview-pane-font'>{segments.length} segment{segments.length > 1 ? 's' : ''} selected</span>
          </div>
          <div class="dd-body data-scroller-container">
            {segmentOptions && segmentOptions.length > 0
              && segmentOptions.map((s, index) => (<React.Fragment>
                <div data-for={`SegmentChk${s.value}${index}`} data-tip={`SegmentChk${s.value}${index}_tooltip`} onClick={e => toggleSegment(parseInt(s.value, 10), e)} key={index} className="body-input-container">
                  <label class="a-checkbox" ><input type="checkbox" checked={segments.includes(parseInt(s.value, 10))} /><span class="a-checkbox-mark"><span class="appkiticon icon-check-mark-fill"></span></span></label>
                  <a class="dropdown-item" href="#">
                    <div className="chkbx-sgmnt"><i class="circle-badge" style={{ background: s.indicatorColor }}></i> <span className='preview-pane-font'>{s.text}</span></div>
                    {(typeof s.stakeholders !== 'undefined')
                      && <span className='preview-pane-font'>({s.stakeholders > 1 ? `${s.stakeholders} stakeholders` : `${s.stakeholders} stakeholder`})</span>}
                  </a>
                </div>
                {s.text.length > 20 && <ReactTooltip id={`SegmentChk${s.value}${index}`}
                  className={`SegmentChk${s.value}${index}_tooltip`} place='right'
                  effect='solid' multiline={true} backgroundColor='#6b6b6b'>
                  {s.text}
                </ReactTooltip>}
              </React.Fragment>
              ))}
          </div>
          <div class="dd-footer">
            <small class="clearfix">
              <span class="Appkit4-icon icon-information-fill"
                id="completionMessage" data-for="completionMessage"
                data-tip="completion_tooltip"
                currentitem="false">
              </span>
              <span className='preview-pane-font'>Any changes you make will only take
                effect for future question cycles </span>
            </small>
            <button onClick={applySegments} disabled={isButtonActive} class="a-btn ml-2 a-btn-primary a-btn-lg custom-btn float-right">Apply</button>
          </div>
        </div>}
      </div>
    </div>
  </div >);
};
export default SegmentDropDown;
