import React, { Component } from 'react';
import '../styles/settings.scss';
import { CSSTransitionGroup } from 'react-transition-group';
import '@pwc/appkit-react/style/appkit-react.default.css';
import { connect } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Formik, Field } from 'formik';
import SettingSumoSelect from '../../../../ui-components/SettingSumoSelect/SettingSumoSelect';
import AddUser from '../components/AddUser.jsx';
import AlertPopup from '../../../../ui-components/AlertPopup/AlertPopup.jsx';
import {
  setHeading,
  getUsers,
  toggleAllCheckboxes,
  toggleCheckbox,
  deleteUsers,
  getAllProjectsForFilters,
  getAllPortfoliosForFilters,
  modifyAddedUserStatus,
  getSystemAdminUsersCount,
} from '../actions/user';
import { userSelector } from '../selectors/user';
import DeleteConfirmation from '../../../../ui-components/DeleteConfirmation/DeleteConfirmation.jsx';
import RenderIf from '../../../../ui-components/Common';
import { Spinner } from '../../../../ui-components/Loader';
import Pagination from '../../../../ui-components/Pagination/Pagination.jsx';
import {
  NO_OF_RECORDS, INITIAL_PAGINATED_DATA, NO_OF_MINIMUM_ADMIN, FEEDBACK_PAGE_REF,
} from '../../../../Constants';


import { getSystemTerminology } from '../../question-model/actions/general';
import { getSystemTerminologySelector } from '../../question-model/selectors/general';
import Feedback from '../../../../ui-components/Feedback/Feedback.jsx';


const mapDispatchToProps = {
  setHeading,
  getAllProjectsForFilters,
  getAllPortfoliosForFilters,
  getUsers,
  toggleAllCheckboxes,
  toggleCheckbox,
  deleteUsers,
  modifyAddedUserStatus,
  getSystemTerminology,
};

const mapStateToProps = state => ({
  user: userSelector(state),
  systemTerminology: getSystemTerminologySelector(state),
});

const unparentedProject = 'UNPARENTED PROJECTS';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animate: false,
      showAddUser: false,
      heading: 'Users',
      selectedUser: {},
      showDelete: false,
      selectedUserIdsForDelete: [],
      showAlertPopup: false,
      alertPopupTitle: '',
      alertPopupMessage: '',
      deleteTitle: '',
      deleteConfirmationMessage: '',
      isLoading: true,
      isFilterResetting: false,
      listLoading: true,
      currentPage: 1,
      totalPages: 1,
      usersFilters: {
        selectedKeyword: '',
        selectedPortfolios: [],
        selectedProjects: [],
        selectedTypes: [],
      },
      emailNotificationMessage: '',
      isEnableResetButton: true,
      filteredProjectList: [],
    };

    this.portfoliosNotFound = false;
    this.projectsNotFound = false;
    this.portfoliosList = [];
    this.projectsList = [];
    this.projectsPortList = [];
    this.typesList = [{ text: 'PwC User', value: false }, { text: 'External User', value: true }];
    this.showAddUserPanel = this.showAddUserPanel.bind(this);
    this.hideAddUserPanel = this.hideAddUserPanel.bind(this);
    this.toggleAllCheckboxes = this.toggleAllCheckboxes.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.confirmBulkDelete = this.confirmBulkDelete.bind(this);
    this.cancelDelete = this.cancelDelete.bind(this);
    this.deleteUsers = this.deleteUsers.bind(this);
    this.alertPopupOkClick = this.alertPopupOkClick.bind(this);
    this.minimumSystemAdminCountAlert = this.minimumSystemAdminCountAlert.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.filterProjectsBasedOnOrganisations = this.filterProjectsBasedOnOrganisations.bind(this);
  }

  componentDidMount() {
    this.props.getSystemTerminology();
    this.props.setHeading(this.state.heading);
    this.setState({ isLoading: true });
    this.getUsersRecords(null, INITIAL_PAGINATED_DATA);
    const self = this;

    Promise.all([
      new Promise((resolve) => {
        this.props.getAllPortfoliosForFilters().then((res) => {
          self.portfoliosNotFound = res.data.length === 0;
          res.data.map((data) => {
            const obj = {
              text: data.title,
              value: data.id,
            };
            self.portfoliosList.push(obj);
          });

          resolve(true);
        });
      }),
      new Promise((resolve) => {
        this.props.getAllProjectsForFilters().then((res) => {
          self.projectsNotFound = res.data.length === 0;
          res.data.reverse()
            .reduce((data, { portfolioName, title, id }) => {
              const portfolioText = portfolioName != null ? portfolioName : unparentedProject;

              if (!self.projectsPortList[portfolioText]) {
                self.projectsPortList.push(
                  {
                    text: portfolioText,
                    value: portfolioText,
                    projects: [],
                  },
                );
                self.projectsPortList[portfolioText] = [];
              }
              const obj = {
                text: title,
                value: id,
              };

              self.projectsPortList[portfolioText].push(obj);
            }, 0);

          res.data.map((data) => {
            const obj = {
              text: data.title,
              value: data.id,
            };
            self.projectsList.push(obj);
          });
          this.setState({
            filteredProjectList: self.projectsList,
          });
          resolve(true);
        });
      }),
    ]).then(() => {
      self.setState({ isLoading: false });
    });
  }

  filterUsers(filters) {
    const userModel = filters;
    userModel.selectedKeyword = userModel.selectedKeyword;
    userModel.selectedPortfolios = userModel.selectedPortfolios.map(item => parseInt(item, 10));
    userModel.selectedProjects = userModel.selectedProjects.map(item => parseInt(item, 10));
    userModel.selectedTypes = userModel.selectedTypes.map(item => item.toString() === 'true');
    this.setState({
      usersFilters: userModel,
      currentPage: 1,
      isEnableResetButton: true,
    });
    if (userModel.selectedPortfolios.length || userModel.selectedProjects.length
      || userModel.selectedTypes.length || userModel.selectedKeyword) {
      this.setState({ isEnableResetButton: false });
    }
    this.getUsersRecords(filters, { noOfRecords: NO_OF_RECORDS, pageNo: 0 });
  }

  getTotalNumOfPages() {
    return this.props.user.usersCount !== 0
      ? Math.ceil(this.props.user.usersCount / NO_OF_RECORDS)
      : 1;
  }

  getUsersRecords(filters, PaginatedData) {
    this.setState({
      listLoading: true,
    });
    this.props.getUsers(filters, PaginatedData).then(() => {
      this.setState({
        listLoading: false,
        totalPages: this.getTotalNumOfPages(),
      });
    });
    this.setState({
      isLoading: false,
    });
  }

  showAddUserPanel() {
    this.setState({
      showAddUser: true,
      animate: true,
      selectedUser: {},
    });
  }

  hideAddUserPanel() {
    const { isUserAddedOrModified } = this.props.user;
    const { usersFilters, currentPage } = this.state;
    this.setState({
      showAddUser: false,
      animate: true,
      selectedUser: {},
    });
    if (isUserAddedOrModified) {
      this.getUsersRecords(usersFilters, { noOfRecords: NO_OF_RECORDS, pageNo: currentPage - 1 });
      this.props.modifyAddedUserStatus(false);
    }
  }

  editUser(obj) {
    this.setState({
      showAddUser: true,
      animate: true,
      selectedUser: obj,
    });
  }

  toggleAllCheckboxes() {
    this.props.toggleAllCheckboxes();
  }

  toggleCheckbox(item) {
    this.props.toggleCheckbox(item);
  }

  minimumSystemAdminCountAlert(title, message) {
    this.setState({
      showDelete: false,
      showAlertPopup: true,
      alertPopupTitle: title || 'Cannot Delete User',
      alertPopupMessage: message || 'Cannot delete selected System Admin as minimum two System Administrators should present in the system.',
    });
  }

  // Confirm Delete
  confirmDelete(userId) {
    if (this.props.user.usersList.length > 0) {
      getSystemAdminUsersCount().then((response) => {
        const overallAdmins = parseInt(response.data, 10);
        const selectedUser = this.props.user.usersList.filter(item => item.id === userId);
        if (overallAdmins > NO_OF_MINIMUM_ADMIN && selectedUser[0].is_admin === true) {
          this.setState({
            selectedUserIdsForDelete: [userId],
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: 'Delete an User',
            deleteConfirmationMessage: 'Are you sure you want to delete this account?',
          });
        } else if (selectedUser[0].is_admin === false) {
          this.setState({
            selectedUserIdsForDelete: [userId],
            showDelete: true,
            showAlertPopup: false,
            deleteTitle: 'Delete an User',
            deleteConfirmationMessage: 'Are you sure you want to delete this account?',
          });
        } else {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: 'Cannot Delete User',
            alertPopupMessage: 'Cannot delete selected System Admin as minimum two System Administrators should present in the system.',
          });
        }
      });
    }
  }

  confirmBulkDelete() {
    if (this.props.user.usersList.length > 0) {
      const selectedIds = [];
      getSystemAdminUsersCount().then((response) => {
        const overallAdmins = parseInt(response.data, 10);

        const selectedAdmins = this.props.user.usersList
          .filter(item => item.isChecked === true && item.is_admin === true);

        if (selectedAdmins.length > 0
          && overallAdmins - selectedAdmins.length < NO_OF_MINIMUM_ADMIN) {
          this.setState({
            showDelete: false,
            showAlertPopup: true,
            alertPopupTitle: 'Cannot Delete User',
            alertPopupMessage: 'There must be minimum of 2 System Admins in the system, one or more user selected has System Admin role which cannot be deleted',
          });
        } else {
          this.props.user.usersList.filter((item) => {
            if (item.isChecked === true) selectedIds.push(item.id);
            return item;
          });
          this.setState({
            selectedUserIdsForDelete: selectedIds,
            showDelete: true,
            deleteTitle: 'Delete selected users',
            deleteConfirmationMessage: 'Are you sure you want to delete the selected users?',
          });
        }
      });
    }
  }

  cancelDelete() {
    this.setState({
      selectedUserIdsForDelete: [],
      showDelete: false,
    });
  }

  alertPopupOkClick() {
    this.setState({
      selectedUserIdsForDelete: [],
      showDelete: false,
      showAlertPopup: false,
    });
  }

  // Delete Users
  deleteUsers() {
    return this.props
      .deleteUsers({ selectedIds: this.state.selectedUserIdsForDelete })
      .then((response) => {
        this.props.getUsers(this.state.usersFilters, INITIAL_PAGINATED_DATA).then(() => {
          this.setState({
            totalPages: this.getTotalNumOfPages(),
            selectedUserIdsForDelete: [],
            currentPage: 1,
          });
        });
        return response;
      });
  }

  // paging code
  nextPage() {
    const { currentPage } = this.state;
    if (currentPage < this.state.totalPages) this.updateCurrentPage(currentPage + 1, true);
  }

  prevPage() {
    const { currentPage } = this.state;
    if (currentPage > 1) this.updateCurrentPage(currentPage - 1, true);
  }

  updateCurrentPage(pageNum, status) {
    this.setState({
      currentPage: parseInt(pageNum, 10),
      isLoading: false,
    });
    if (status) {
      this.getUsersRecords(this.state.usersFilters, {
        noOfRecords: NO_OF_RECORDS,
        pageNo: pageNum - 1,
      });
    }
  }

  goToPage(value) {
    if (!isNaN(value) && parseInt(value, 10) <= parseInt(this.state.totalPages, 10)) {
      this.updateCurrentPage(value, true);
    } else {
      this.updateCurrentPage(1, true);
    }
  }

  pageOnChangeEvent(value) {
    if (!isNaN(value) && parseInt(value, 10)) this.updateCurrentPage(value, false);
  }

  filterProjectsBasedOnOrganisations = (values, setFieldValue) => {
    if (values.selectedPortfolios?.length > 0) {
      const filteredProjectList = this.projectsList.filter((project) => {
        let isProjectInPortfolio = false;
        values.selectedPortfolios.forEach((selectedPortfolio) => {
          // eslint-disable-next-line
          const projectsInInSelectedPortfolio = this.projectsPortList[this.portfoliosList.find(portfolio => portfolio.value == selectedPortfolio)?.text] || [];
          // eslint-disable-next-line
          if ((projectsInInSelectedPortfolio?.findIndex(projectsInInSelectedPortfolio => projectsInInSelectedPortfolio.value == project.value)) != -1) {
            isProjectInPortfolio = true;
          }
        });
        return isProjectInPortfolio;
      });
      // eslint-disable-next-line
      const updatedselectedProjects = values.selectedProjects.filter(selectedProject => (filteredProjectList?.findIndex(filteredProject => filteredProject.value == selectedProject)) != -1);
      values.selectedProjects = updatedselectedProjects;
      setFieldValue('selectedProjects', updatedselectedProjects);
      this.filterUsers(values);
      this.setState({ filteredProjectList });
    } else {
      this.setState({ filteredProjectList: this.projectsList });
    }
  }

  resetFilters() {
    const userModel = {
      selectedPortfolios: [],
      selectedProjects: [],
      selectedTypes: [],
      selectedKeyword: '',
    };
    this.setState({
      usersFilters: userModel,
      isFilterResetting: true,
    });

    this.filterUsers(userModel);
    setTimeout(() => {
      this.setState({
        isFilterResetting: false,
      });
    }, 1);
  }

  render() {
    const {
      isLoading, totalPages, currentPage, listLoading, isFilterResetting, isEnableResetButton,
    } = this.state;
    const { user } = this.props;
    const {
      portfoliosNotFound, portfoliosList, projectsNotFound, typesList,
    } = this;
    const userModel = {
      selectedKeyword: this.state.usersFilters.selectedKeyword,
      selectedPortfolios: this.state.usersFilters.selectedPortfolios,
      selectedProjects: this.state.usersFilters.selectedProjects,
      selectedTypes: this.state.usersFilters.selectedTypes,
    };

    return (
      <div>
        <Spinner isLoading={isLoading} />
        <RenderIf showComponent={!isLoading}>
          <div className="users-container">
            <div className="row">
              <div className="col-md-6 col-sm-12 col-12 col-lg-8">
                <p className="a-font-sm m-0 mb-3">
                  Manage the users who have access to one or more portfolios and projects.
                </p>
              </div>
            </div>

            <div class="row justify-content-between align-items-center pb-1 pt-1 m-0 mb-3">
              <div class="col">
                {!isFilterResetting && <Formik
                  enableReinitialize={true}
                  initialValues={userModel}>
                  {({ setFieldValue, values }) => (
                    <div class="row justify-content-center align-items-center">
                      <div class="col-md-6 col-lg-9 mr-auto">
                        {(portfoliosList.length || this.state.filteredProjectList.length || typesList.length) && <div class="row">
                          <div className="col-auto">
                            <strong className="filter-label">Filter by:</strong>
                          </div>
                          <div class="col-auto">
                            <div class="a-dropdown">
                              {(portfoliosList.length > 0 || portfoliosNotFound) && (
                                <SettingSumoSelect
                                  multiple="multiple"
                                  placeholder={'Organisations'}
                                  name="selectedPortfolios"
                                  selectedValues={values.selectedPortfolios}
                                  options={portfoliosList}
                                  setFieldValue={setFieldValue}
                                  onSumoSelectChange={() => {
                                    this.filterUsers(values);
                                    this.filterProjectsBasedOnOrganisations(
                                      values,
                                      setFieldValue,
                                    );
                                  }}
                                  captionFormatAllSelected={'Organisations'}
                                />
                              )}
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="a-dropdown">
                              <SettingSumoSelect
                                multiple="multiple"
                                placeholder={(this.state?.filteredProjectList?.length === 0
                                  || projectsNotFound)
                                  ? 'No Topics'
                                  : 'Topics'}
                                name="selectedProjects"
                                selectedValues={values.selectedProjects}
                                options={this.state.filteredProjectList}
                                setFieldValue={setFieldValue}
                                onSumoSelectChange={() => this.filterUsers(values)}
                                captionFormatAllSelected={'Topics'}
                              />
                            </div>
                          </div>
                          <div class="col-auto">
                            <div class="a-dropdown">
                              {typesList.length > 0 && (
                                <SettingSumoSelect
                                  multiple="multiple"
                                  placeholder="Types"
                                  name="selectedTypes"
                                  selectedValues={values.selectedTypes}
                                  options={typesList}
                                  setFieldValue={setFieldValue}
                                  onSumoSelectChange={() => this.filterUsers(values)}
                                  captionFormatAllSelected="Types"
                                />
                              )}
                            </div>
                          </div>

                          {!isEnableResetButton && <div class="col-auto">
                            <button href="javascript:void(0)"
                              class="text-theme-color text-center d-block pl-2 pr-2"
                              onClick={() => this.resetFilters()}
                            >
                              CLEAR</button>
                          </div>}

                        </div>}
                      </div>
                      <div class="col-md-6 col-lg-3 ml-auto">
                        <div class="row justify-content-start">
                          <div class="col px-0">
                            <div class="input-group-with-icon">
                              <Field
                                name='selectedKeyword'
                                placeholder="Search"
                                class="w-100 custom-input-filter-icon"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldValue('selectedKeyword', e.target.value);
                                  values.selectedKeyword = e.target.value;
                                  this.filterUsers(values);
                                }}
                              />
                              <MaterialIcon className="material-icons md-24 text-theme-color " icon="search" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Formik>}
              </div>
            </div>

            <div className="row a-table-wrapper">
              <div className="col-md-12">
                <div className="a-table-wrapper row">
                  <Spinner isLoading={listLoading} />

                  <div className="a-table-scroller  users-table a-mb-10  col-md-12  pr-0 ">
                    <RenderIf showComponent={!listLoading}>
                      <table className="a-table data-table a-lg min-w-2050 sortable selectable setting-table user-table">
                        <thead className="a-thead">
                          <tr>
                            {user.usersList.length > 0 ? (
                              <th className="checkbox-cell">
                                <label className="a-checkbox black-checkbox">
                                  <input
                                    type="checkbox"
                                    onChange={this.toggleAllCheckboxes}
                                    checked={
                                      user.usersList.filter(item => item.isChecked).length > 0
                                    }
                                  />
                                  <span className="a-checkbox-mark">
                                    <span
                                      className={`appkiticon   
                                      ${user.usersList.filter(item => item.isChecked).length > 0
                                          && !user.selectAll
                                          ? 'icon-minus-fill'
                                          : ''
                                        }  
                                      ${user.usersList.filter(item => item.isChecked).length > 0
                                          && user.selectAll
                                          ? 'icon-check-mark-fill'
                                          : ''
                                        }`}
                                    />
                                  </span>
                                </label>
                              </th>
                            ) : (
                              ''
                            )}
                            <th className="action-cell">Action</th>
                            <th className="email-cell">Email Address</th>
                            <th className="name-cell">First Name</th>
                            <th className="name-cell">Last Name</th>
                            <th className="type-cell">Type</th>
                            <th className="created-cell">Created</th>
                            <th className="last-active-cell">Last Active</th>
                            <th className="system-admin-cell">System Admin</th>
                            <th className="system-admin-cell">Registered</th>
                            <th className="t-cross-header">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody className="a-tbody">
                          {user.usersList.length > 0 ? (
                            user.usersList.map(
                              (obj, index) => (
                                <tr key={index}>
                                  <td className="checkbox-cell">
                                    {' '}
                                    <label className="a-checkbox black-checkbox">
                                      <input
                                        type="checkbox"
                                        checked={obj.isChecked}
                                        onChange={() => this.toggleCheckbox(obj)}
                                      />
                                      <span className="a-checkbox-mark">
                                        <span className="appkiticon icon-check-mark-fill" />
                                      </span>
                                    </label>
                                  </td>
                                  <td className="email-cell cursor-pointer">
                                    <div class="table-dropdown" >
                                      <div aria-expanded="true" aria-haspopup="true" class="table-dropdown-toggle" data-bs-toggle="dropdown">
                                        <MaterialIcon icon=" more_horiz" />
                                      </div>
                                      <div class="dropdown-menu dropdown-menu-left a-shadow-sm border-0 a-user-setting-panel-one " x-placement="bottom-start">
                                        <ul class="user-infor-list">
                                          <li class="user-infor-list-item" onClick={() => this.confirmDelete(obj.id)}>Remove</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className="email-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.email_address}
                                  </td>
                                  <td
                                    className="name-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.first_name}
                                  </td>
                                  <td
                                    className="name-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.last_name}
                                  </td>
                                  <td
                                    className="type-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.is_external ? 'External User' : 'PwC User'}
                                  </td>
                                  <td
                                    className="created-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.created
                                      ? new Intl.DateTimeFormat('en-GB', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                      }).format(new Date(obj.created.toString()))
                                      : ''}
                                  </td>

                                  <td
                                    className={`created-cell cursor-pointer ${obj.last_active ? '' : 'text-center'
                                      }`}
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.last_active
                                      ? new Intl.DateTimeFormat('en-GB', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                      }).format(new Date(obj.last_active.toString()))
                                      : '-'}
                                  </td>
                                  <td
                                    className="system-admin-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.is_admin ? 'Yes' : 'No'}
                                  </td>

                                  <td
                                    className="created-cell cursor-pointer"
                                    onClick={this.editUser.bind(this, obj)}
                                  >
                                    {obj.is_user_registered ? 'Yes' : 'No'}
                                  </td>
                                  <td className="t-cross-icon cursor-pointer">
                                    <i
                                      className="appkiticon icon-close-fill a-font-sm"
                                      onClick={() => this.confirmDelete(obj.id)}
                                    />
                                  </td>
                                </tr>
                              ),
                              this,
                            )
                          ) : (
                            <tr>
                              <td colSpan="7" className="font-weight-medium">
                                Click the button below to create your first user.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </RenderIf>
                  </div>
                  <div className="col-md-12  pr-0  at-pagination">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      nextPageEvent={this.nextPage.bind(this)}
                      prevPageEvent={this.prevPage.bind(this)}
                      goToPageEvent={this.goToPage.bind(this)}
                      pageOnChange={this.pageOnChangeEvent.bind(this)}
                    />
                  </div>
                </div>
                <a
                  href="javascript:void(0)"
                  className="add-text-button mt-3  a-btn a-btn-transparent a-btn-lg a-btn-gray"
                  onClick={this.showAddUserPanel.bind(this)}
                >
                  <i className="appkiticon icon-plus-fill mr-2"> </i> Create a new user
                </a>
              </div>
            </div>
          </div>
          <div>
            {this.state.showAlertPopup ? (
              <AlertPopup
                title={this.state.alertPopupTitle}
                message={this.state.alertPopupMessage}
                alertPopupOkClick={this.alertPopupOkClick}
              />
            ) : (
              ''
            )}
            {this.state.showDelete ? (
              <DeleteConfirmation
                title={this.state.deleteTitle}
                message={this.state.deleteConfirmationMessage}
                confirmationClick={this.deleteUsers}
                cancelClick={this.cancelDelete}
              />
            ) : (
              ''
            )}
            <CSSTransitionGroup
              transitionName="pwcsidebar"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
            >
              {this.state.showAddUser ? (
                <AddUser
                  hideAddUserPanel={this.hideAddUserPanel}
                  selectedUser={this.state.selectedUser}
                  confirmDelete={this.confirmDelete}
                  minimumSystemAdminCountAlert={this.minimumSystemAdminCountAlert}
                />
              ) : (
                ''
              )}
            </CSSTransitionGroup>
          </div>
          <div className="footer-dropdowns">
            {user.usersList.length > 0 ? (
              <button
                className="a-btn a-btn-primary a-btn-lg dropdown-toggle"
                data-bs-toggle="dropdown"
                disabled={user.usersList.filter(item => item.isChecked).length <= 0}
              >
                ACTIONS
                <i className="appkiticon a-font-xs ml-3 icon-down-chevron-fill" />
              </button>
            ) : (
              ''
            )}
            <ul className="user-infor-list dropdown-menu a-shadow-sm border-0">
              <li className="user-infor-list-item" onClick={() => this.confirmBulkDelete()} >
                <a href="javascript:void(0)">Delete User</a>
              </li>
            </ul>
          </div>
          <div className="alert alert-success success-alert-with-timer  a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success"
          style={{
            left: '50%',
            top: '100px',
            transform: 'translate(-50%, -50%)',
            zIndex: 10,
            padding: 0,
          }}
            data-hide="10">
            <div className="a-alert-content">
              <div className="a-alert-badge success">
                <span className="appkiticon icon-confirmed-fill me-0"></span>
              </div>
              <span className="a-content  me-0 preview-pane-font" style={{ flexBasis: 'unset' }}>
                {this.state.emailNotificationMessage}
              </span>
              <div className="">
                <svg width="23" height="23" class="chart">
                  <circle r="12.5" cx="12.5" cy="12.5" class="pie"/>
                </svg>
              </div>
            </div>
          </div>
        </RenderIf>
        <Feedback pageRef={FEEDBACK_PAGE_REF.USER_PAGE} isShowSupportBtn = {false}/>
      </div >
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);
