class RightFlyPanProgressDecision {
  constructor(projectStakeholdersCount, minimumStakeHolderPer, minStakeholder, maxStakeholder) {
    this.projectStakeholdersCount = projectStakeholdersCount;
    this.minimumStakeHolderPer = minimumStakeHolderPer;
    this.minStakeholder = minStakeholder;
    this.maxStakeholder = maxStakeholder;
  }

  getMinimumStakeHolderValue() {
    let minStakHolder = this.minStakeholder;
    if (this.projectStakeholdersCount >= this.maxStakeholder) {
      minStakHolder = Math.ceil((this.projectStakeholdersCount * this.minimumStakeHolderPer) / 100);
    }
    return minStakHolder;
  }

  progressBarSection(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return isProjectAnonymityMet && progressBarData && progressBarData.total >= minStakHolder;
    }
    return (isProjectAnonymityMet || !isDomainAnonymityOverride)
      && progressBarData && progressBarData.total > 0;
  }

  yesNoSection(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData,
    response, type, YESNO_QUESTION) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return ((!isProjectAnonymityMet) || (progressBarData.total < minStakHolder))
        && type === YESNO_QUESTION;
    }
    return ((!isProjectAnonymityMet && isDomainAnonymityOverride) || (response.length === 0
      && progressBarData.total <= 0)) && type === YESNO_QUESTION;
  }

  commentSection(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData,
    response) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return (isProjectAnonymityMet || !isDomainAnonymityOverride)
        && response && response.length > 0 && progressBarData.total >= minStakHolder;
    }
    return (isProjectAnonymityMet || !isDomainAnonymityOverride)
      && response && response.length > 0;
  }

  icon(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData,
    response, type, YESNO_QUESTION) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return (isProjectAnonymityMet || !isDomainAnonymityOverride)
        && type === YESNO_QUESTION && response.length > 0 && progressBarData.total >= minStakHolder;
    }
    return (isProjectAnonymityMet || !isDomainAnonymityOverride)
      && type === YESNO_QUESTION && response.length > 0;
  }

  openTextSection(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData,
    response, type, OPENTYPE_QUESTION) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return ((response.length === 0 || progressBarData.total < minStakHolder)
        && type === OPENTYPE_QUESTION);
    }
    return ((isProjectAnonymityMet || !isDomainAnonymityOverride)
      && (response.length === 0) && type === OPENTYPE_QUESTION);
  }

  openTextQuestionSection(isProjectAnonymityMet,
    isDomainAnonymityOverride, progressBarData,
    response, type, OPENTYPE_QUESTION) {
    if (isDomainAnonymityOverride) {
      const minStakHolder = this.getMinimumStakeHolderValue();
      return ((isProjectAnonymityMet)
        && response.length > 0 && type === OPENTYPE_QUESTION)
        && progressBarData.total >= minStakHolder;
    }
    return (isProjectAnonymityMet || !isDomainAnonymityOverride)
      && type === OPENTYPE_QUESTION && response.length > 0;
  }
}

export default RightFlyPanProgressDecision;
