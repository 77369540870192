import settingsService from '../../../../redux/services/settingsService';
import actionType from '../../../../redux/modules/settings/actionType';
import { INITIAL_PAGINATED_DATA } from '../../../../Constants';

// Set heading
export const setHeading = heading => (dispatch) => {
  dispatch({ type: actionType.SET_HEADING, heading });
};

// change status of added/updated attributes
export const modifyAddedAttributeStatus = status => (dispatch) => {
  dispatch({ type: actionType.ADDED_ATTRIBUTE_STATUS, data: status });
};
export const changeAddedAttributeStatus = status => (dispatch) => {
  dispatch({ type: actionType.CHANGE_ADDED_ATTRIBUTE_STATUS, data: status });
};

export const getAddedAttributeStatus = status => (dispatch) => {
  dispatch({ type: actionType.NEW_ADDED_ATTRIBUTE_STATUS, data: status });
};

export const getAttributes = (domains, paginatedData,
  questionModelId) => dispatch => settingsService
  .getAttributesList(domains, paginatedData, questionModelId)
  .then((res) => {
    const attributes = {
      attributesList: [],
      count: 0,
    };
    attributes.count = res.data.count;
    res.data.attributes.map((item) => {
      const data = item;
      data.isChecked = false;
      attributes.attributesList.push(data);
      return item;
    });
    dispatch({ type: actionType.GET_ATTRIBUTES_LIST, attributes });
  })
  .catch(() => { });

export const getAttributesForFilter = questionModelId => settingsService
  .getAttributesListForFilter(questionModelId)
  .then(res => res.data.attributes)
  .catch(() => { });


export const insertAttribute = attribute => dispatch => settingsService
  .insertAttribute(attribute)
  .then((res) => {
    dispatch(getAddedAttributeStatus(true));
    return res;
  })
  .catch(error => error);

export function validateAttributeName(attribute) {
  return settingsService
    .validateAttributeName(attribute)
    .then(res => res.data)
    .catch({});
}

export const updateAttribute = attribute => dispatch => settingsService
  .updateAttribute(attribute)
  .then(() => {
    dispatch(modifyAddedAttributeStatus(true));
  })
  .catch(() => { });

export const toggleAllCheckboxes = () => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ALL_ATTRIBUTE_CHECKBOXES });
};

export const toggleCheckbox = attribute => (dispatch) => {
  dispatch({ type: actionType.TOGGLE_ATTRIBUTE_CHECKBOX, attribute });
};

export const canBulkDelete = selectedIds => () => settingsService
  .canBulkDeleteAttributes(selectedIds)
  .then(res => res.data)
  .catch(() => { });

export const canDelete = id => () => settingsService
  .canDeleteAttributes(id)
  .then(res => res.data)
  .catch(() => { });

export const canChangeDomain = (id, domainId) => () => settingsService
  .canChangeDomain(id, domainId)
  .then(res => res.data)
  .catch(() => { });

export const deleteAttributes = (selectedIds, filters) => dispatch => settingsService
  .deleteAttributes(selectedIds)
  .then((response) => {
    dispatch(getAttributes(filters, INITIAL_PAGINATED_DATA));
    return response;
  })
  .catch(error => error);
