import { actionType } from '../../../redux/modules/portfolio';
import portfolioService from '../../../redux/services/portfolioService';


// action method for get dashboard all project widgets
export const getPortfolioDashbordProjects = portfolioId => () => portfolioService
  .getDashboardData(portfolioId)
  .then(res => res.data)
  .catch({});

// Creating thunk action creaters which will be access from component
export function savePortfolio(portfolioData) {
  return dispatch => portfolioService
    .savePortfolio(portfolioData)
    .then((res) => {
      if (res.data !== undefined) {
        const { portfolioId } = res.data;
        dispatch({ type: actionType.SAVE_SUCCESS, data: portfolioId });
        return res.data;
      }
      return res;
    })
    .catch({});
}

export function updatePortfolioData(portfolioData) {
  return dispatch => dispatch({ type: actionType.UPDATE_PORTFOLIO_DATA, data: portfolioData });
}
// action method for title exist in portfolio
export const checkPortfolioTitle = (protfolioName, portfolioId) => portfolioService
  .checkTitleExistence({ title: protfolioName, id: portfolioId, portfolioId })
  .then(res => res.data)
  .catch({});

// action method for title exist in portfolio
export const checkQuestionModelExistInProject = data => portfolioService
  .checkQuestionModelExistInProject(data)
  .then(res => res.data)
  .catch({});

// action method to Check project associated with portfolio
export const isProjectAssociatedWithPortfolio = portfolioId => () => portfolioService
  .checkProjectExistInPortfolio(portfolioId)
  .then(res => res.data)
  .catch({});

// delete Portfolio
export const deletePortfolio = portfolioId => () => portfolioService
  .deletePortolioService(portfolioId)
  .then(res => res.data)
  .catch({});

// action method for fetching portfolio list
export function getPortfoliosData(callingFromDeleteAction) {
  return dispatch => portfolioService
    .getPortfoliosList()
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.PORTFOLIOS_DATA, data: res.data, callingFromDeleteAction });
      }
      return res;
    })
    .catch({});
}

// action method for get details of portfolio
export const getPortFolioDetails = portfolioData => portfolioService
  .getPortFolioDetails(portfolioData)
  .then(res => (res.data ? res.data : {}))
  .catch({});


export const removePortfolioAdministrator = (portfolioId, userId) => () => portfolioService
  .removePortfolioAdministrator(portfolioId, userId)
  .then(res => res.data)
  .catch({});

export function searchProjects(searchQuery) {
  return dispatch => new Promise((resolve) => {
    dispatch({ type: actionType.SEARCH_PROJECTS, searchQuery });
    resolve(true);
  });
}

export const changeSelectedPortfolioAndProject = (portfolioId,
  projectId, fromPortfolioDashboard) => (dispatch) => {
    dispatch({
      type: actionType.CHANGE_PROJECTID_PORTFOLIOID,
      portfolioId,
      projectId,
      fromPortfolioDashboard,
    });

    return portfolioService
      .getAccessLevelRoles(portfolioId, projectId)
      .then((res) => {
        const roles = res.data;
        dispatch({ type: actionType.SET_ACCESS_LEVEL_ROLES, roles });
        return res;
      });
  };

export const checkIfUserCanAddProject = () => dispatch => portfolioService
  .checkIfUserCanAddProject()
  .then((res) => {
    dispatch({ type: actionType.CAN_ADD_PROJECT, data: res.data });
  });

export const checkIfUserCanChangeSettings = () => dispatch => portfolioService
  .checkIfUserCanChangeSettings()
  .then((res) => {
    dispatch({ type: actionType.CAN_CHANGE_SETTINGS, data: res.data });
  });

export function showPortfolioRightPanel(isInEditMode) {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_PORTFOLIO_RIGHTPANEL, isInEditMode: isInEditMode || false });
  };
}

export function hidePortfolioRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_PORTFOLIO_RIGHTPANEL });
  };
}

export function showProjectRightPanel(isInEditMode) {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_PROJECT_RIGHTPANEL, isInEditMode: isInEditMode || false });
  };
}

export function hideProjectRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_PROJECT_RIGHTPANEL });
  };
}

// Stakeholders
export function showPortfolioStakeholderRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.SHOW_PORTFOLIO_STAKEHOLDER_RIGHTPANEL });
  };
}
// Stakeholders
export function hidePortfolioStakeholderRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_PORTFOLIO_STAKEHOLDER_RIGHTPANEL });
  };
}
// Stakeholders
export const getPortFolioStakeholders = portfolioData => portfolioService
  .getPortFolioStakeholders(portfolioData)
  .then(res => (res.data ? res.data : {}))
  .catch({});
// Stakeholders
export function addPortfolioStakeholders(portfolioData) {
  return dispatch => portfolioService
    .addPortfolioStakeholders(portfolioData)
    .then((res) => {
      if (res.data !== undefined) {
        const { portfolioId, failedRecords } = res.data;
        dispatch({ type: actionType.SAVE_SUCCESS, data: portfolioId });
        return failedRecords;
      }
      return res;
    })
    .catch({});
}

export function getPortfolioQuestionModelList(portfolioId) {
  return dispatch => portfolioService
    .getPortfolioQuestionModelList(portfolioId)
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.GET_QUESTION_MODELS, data: res.data });
      }
      return res;
    })
    .catch({});
}

export function setSelectedQuestionModelId(modelId) {
  return (dispatch) => {
    dispatch({
      type: actionType.GET_SELECTED_MODEL_ID, data: modelId,
    });
  };
}


// Stakeholders
export function showProjectStakeholderRightPanel(isInEditMode, selectedProject = {}) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_PROJECT_STAKEHOLDER_RIGHTPANEL,
      isInEditMode: isInEditMode || false,
      selectedProject,
    });
  };
}
// Stakeholders
export function hideProjectStakeholderRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_PROJECT_STAKEHOLDER_RIGHTPANEL });
  };
}

// show open text panel
export function showOpenTextRightPanel(openTextModel) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_OPEN_TEXT_RIGHTPANEL, data: openTextModel,
    });
  };
}

// hide open text panel
export function hideOpenTextRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_OPEN_TEXT_RIGHTPANEL });
  };
}


// show open text non match panel
export function showOpenTextNonMatchRightPanel(openTextModel) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_OPEN_TEXT_NON_MATCH_RIGHTPANEL, data: openTextModel,
    });
  };
}

export function showOptionalCommentRightPanel(openOptionalCommentModel) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_OPTIONAL_COMMENT_RIGHTPANEL, data: openOptionalCommentModel,
    });
  };
}

export function updateOptionalCommentRightPanel(openOptionalCommentModel) {
  return (dispatch) => {
    dispatch({
      type: actionType.UPDATE_OPTIONAL_COMMENT_RIGHT_PANEL, data: openOptionalCommentModel,
    });
  };
}

// show open optional comment panel
export function hideOptionalCommentRightPanel() {
  return (dispatch) => {
    dispatch({
      type: actionType.HIDE_OPTIONAL_COMMENT_RIGHTPANEL,
    });
  };
}

// show open custom question panel
export function showCustomQuestionsDetailRightPanel(openOptionalCommentModel) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_CUSTOM_QUESTION_DETAIL_PANEL, data: openOptionalCommentModel,
    });
  };
}

// hide open custom question panel
export function hideCustomQuestionsDetailRightPanel() {
  return (dispatch) => {
    dispatch({
      type: actionType.HIDE_CUSTOM_QUESTION_DETAIL_PANEL,
    });
  };
}

// hide open text non match panel
export function hideOpenTextNonMatchRightPanel() {
  return (dispatch) => {
    dispatch({ type: actionType.HIDE_OPEN_TEXT_NON_MATCH_RIGHTPANEL });
  };
}

// fetech custom tag filters for Organisation
export function getUserFiltersForPortfolio(portfolioId) {
  const data = {
    portfolioId,
  };
  return dispatch => portfolioService
    .getUserFiltersForPortfolio(data)
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.USER_FILTERS_FOR_PORTFOLIO, data: res.data });
      }
    })
    .catch({});
}

// action method for updating portfolio list
export function updateShowPortfolio(portfolioData, allPortfolio) {
  return dispatch => portfolioService
    .updateShowPortfolio(portfolioData)
    .then((res) => {
      if (res.data !== undefined) {
        dispatch({ type: actionType.PORTFOLIOS_DATA, data: allPortfolio });
      }
      return res;
    })
    .catch({});
}

// action method for showing the open text drop question
export function showOpenTextDropQuesRightPanel(selectedQuestions = {},
  projectId = '', customDatesData = {}) {
  return (dispatch) => {
    dispatch({
      type: actionType.SHOW_OPEN_TEXT_DROP_QUES_RIGHTPANEL,
      selectedQuestions,
      projectId,
      customDatesData,
    });
  };
}

// action method for hiding the open text drop question
export function hideOpenTextDropQuesRightPanel(selectedQuestions = {},
  projectId = '', customDatesData = {}) {
  return (dispatch) => {
    dispatch({
      type: actionType.HIDE_OPEN_TEXT_DROP_QUES_RIGHTPANEL,
      selectedQuestions,
      projectId,
      customDatesData,
    });
  };
}
