import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from './redux/services/authService';
import constant from './modules/account/actions/constant';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (authService.isAuthenticated() ? (
        <Component {...props} />
    ) : (
        <Redirect
          to={{ pathname: constant.signInPage, state: { from: props.location } }}
        />
    ))
    }
  />
);

export default PrivateRoute;
