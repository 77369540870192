import React from 'react';
import { connect } from 'react-redux';
import '../styles/pagecontent.css';

import frame from '../../../assets/images/component/Frame.png';


class Splashtwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: props.isTablet,
    };
    this.handleClick = this.handleClick.bind(this);
    this.submitButton = React.createRef();
  }

  componentDidMount() {
    this.submitButton.current.focus();
  }

  handleClick() {
    this.props.gettingStarted();
  }

  render() {
    return (
      <React.Fragment>

        <div class="wrapper fade-in-getting-started get-start-content" style={{ backgroundColor: '#fff' }}>
            <div class="container text-center" style={{ backgroundColor: '#fff' }}>
              <div class="get-started-img">
                <img class="img-fluid" src={frame} />
              </div>
              <div class="page-content">
                <div class="description-container">
                  <p class="description mb-0">Set up your topic and question settings, then send to your stakeholders.</p>  </div>
                  <a ref={this.submitButton} onClick={() => this.handleClick()} class="get-started-btn" >Continue</a>
              </div>
            </div>

          </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  brandName: state.brandwrapper.brandName,
});


const mapDispatchToProps = {
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Splashtwo);
