import actionType from './actionType';

// Configured initial state for login
const initialState = {
  loginData: { isLogging: 'true', other: 'other data ' },
  isSysAdmin: false,
  users: [],
  accountRightPanel: { showPanel: false },
  changePhoneNumberRightPanel: { showPanel: false },
  userEmail: '',
  isExternalUser: false,
};

// Creating login reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loginData: {
          ...state.loginData,
          isLogging: 'false',
        },
      };
    case actionType.LOAD_USERS:
      return {
        ...state,
        users: action.users,
      };
    case actionType.IS_LOGGED_IN_USER_ADMIN:
      return {
        ...state,
        isSysAdmin: action.data,
      };
    case actionType.SHOW_ACCOUNT_RIGHTPANEL:
      return {
        ...state,
        accountRightPanel: {
          ...state.accountRightPanel,
          showPanel: true,
        },
      };
    case actionType.HIDE_ACCOUNT_RIGHTPANEL:
      return {
        ...state,
        accountRightPanel: {
          ...state.accountRightPanel,
          showPanel: false,
        },
      };
    case actionType.SHOW_CHANGE_PHONE_NUMBER_RIGHTPANEL:
      return {
        ...state,
        changePhoneNumberRightPanel: {
          ...state.changePhoneNumberRightPanel,
          showPanel: true,
        },
      };
    case actionType.HIDE_CHANGE_PHONE_NUMBER_RIGHTPANEL:
      return {
        ...state,
        changePhoneNumberRightPanel: {
          ...state.changePhoneNumberRightPanels,
          showPanel: false,
        },
      };

    case actionType.SET_USER_EMAIL:
      return {
        ...state,
        userEmail: action.data,
      };
    case actionType.IS_LOGGED_IN_USER_INTERNAL:
      return {
        ...state,
        isExternalUser: action.data,
      };
    default:
      return state;
  }
};
