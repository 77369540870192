import actionType from './actionType';

function getFilteredProjects(portfolioData, searchQuery) {
  const filteredPortfolios = portfolioData.reduce((filtered, processedRow) => {
    const filteredData = processedRow.items.filter(
      s => (s.PortfolioTitle && s.PortfolioTitle.toLowerCase().includes(searchQuery.toLowerCase()))
        || (s.ProjectTitle && s.ProjectTitle.toLowerCase().includes(searchQuery.toLowerCase())),
    );
    if (filteredData.length) {
      filtered.push({ ...processedRow, items: filteredData });
    }

    return filtered;
  }, []);

  return filteredPortfolios;
}
function formattedQuestionModels(questionSets) {
  const questionSetList = questionSets.map(set => ({ text: set.title, value: set.id }));
  return questionSetList;
}
function getSelectedPortfolio(data, selectedPortfolio, callingFromDeleteAction) {
  if (selectedPortfolio > 0 && !callingFromDeleteAction) {
    return selectedPortfolio;
  }
  return data.length > 0 ? data[0].PortfolioId : 0;
}

function getPortfolios(portfolioData, searchQuery) {
  if (searchQuery) {
    return getFilteredProjects(portfolioData, searchQuery);
  }
  return portfolioData;
}

function portfolioArrayData(portfolioData, actionData) {
  const portfolioArray = [...portfolioData.portfoliosData];
  const { portfolioId, projectId } = actionData;
  const portfolioIndex = portfolioArray
    .findIndex(val => val.PortfolioId === parseInt(portfolioId, 10));
  const noOfProjects = portfolioArray[portfolioIndex].items.length;
  if (noOfProjects !== 1) {
    // eslint-disable-next-line max-len
    portfolioArray[portfolioIndex].items = portfolioArray[portfolioIndex].items.filter(val => val.ProjectId !== projectId);
  } else {
    const theOne = portfolioArray[portfolioIndex].items.find(val => val.ProjectId === projectId);
    portfolioArray[portfolioIndex].items = [{
      ...theOne,
      ProjectId: null,
      ProjectTitle: null,
      projectActive: null,
    }];
  }
  return portfolioArray;
}

// Configured initial state for portfolioResponse
const initialState = {
  portfolioData: {
    selectedPortfolio: 0,
    selectedProjectId: null,
    selectedQuestionSetId: null,
    isSaved: false,
    newPortfolioId: '',
    test: 'test data',
    isTitleExist: false,
    canAddProject: false,
    portfolioRightPanel: { showPanel: false, isInEditMode: false },
    portfolioStakeholderRightPanel: { showPanel: false },
    openTextRightPanel: {
      showPanel: false,
      projectId: 0,
      keyPhrase: '',
      projectTitle: '',
      userTagFilters: {},
    },
    optionalCommentRightPanel: {
      showPanel: false,
      projectId: 0,
      attributeId: 0,
      attributeTitle: '',
      domainTitle: '',
      attributeDescription: '',
      userTagFilters: {},
      isShowCommentPanelData: false,
      isCustomAttribute: false,
      selectedSegment: null,
      projectDraftedStatus: false,
      isProjectPortfolioActive: true,
      isDomainAnonymityOverride: false,
    },
    customQuestionsDetailRightPanel: {
      showPanel: false,
      projectId: 0,
      userTagFilters: {},
      selectedSegment: null,
      isProjectAnonymityMet: false,
    },
    openTextNonMatchRightPanel: {
      showPanel: false,
      projectId: 0,
      keyPhrases: [],
      projectTitle: '',
      userTagFilters: {},
    },
    projectRightPanel: { showPanel: false, isInEditMode: false },
    openTextDropQuesRightPanel: {
      showPanel: false,
      selectedQuestions: {},
      projectId: '',
      customDatesData: {},
    },
    projectStakeholderRightPanel: { showPanel: false, selectedProject: {} },
    roles: [],
    portfoliosData: [],
    portfoliosDataContainer: [],
    questionModels: [],
    searchQuery: '',
    fromPortfolioDashboard: false,
    userFiltersForPortfolio: {
      filterGroupName1: '',
      filterGroupName2: '',
      filterGroupName3: '',
      filterGroupName4: '',
      filterGroupValue1: [],
      filterGroupValue2: [],
      filterGroupValue3: [],
      filterGroupValue4: [],
    },
    canChangeSettings: false,
  },
};

// Creating portfolio reducer with initial state
export default (state = initialState, action) => {
  switch (action.type) {
    case actionType.SAVE_SUCCESS:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          isSaved: true,
          newPortfolioId: action.data,
        },
      };
    case actionType.UPDATE_PORTFOLIO_DATA: {
      const portfolioArray = portfolioArrayData(state.portfolioData, action.data);
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfolioData: portfolioArray,
        },
      };
    }
    case actionType.TITLE_EXIST:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          isTitleExist: action.data,
        },
      };
    case actionType.GET_QUESTION_MODELS: {
      const questionSets = formattedQuestionModels(action.data);
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          questionModels: questionSets,
        },
      };
    }
    case actionType.GET_SELECTED_MODEL_ID: {
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          selectedQuestionSetId: action.data,
        },
      };
    }
    case actionType.PORTFOLIOS_DATA: {
      const { searchQuery } = state.portfolioData;
      const selectedPortfolio = getSelectedPortfolio(
        action.data.filter(item => item.show_portfolio),
        state.portfolioData.selectedPortfolio,
        action.callingFromDeleteAction,
      );

      const portfolios = getPortfolios(action.data, searchQuery);

      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfoliosData: portfolios,
          portfoliosDataContainer: action.data,
          selectedPortfolio,
        },
      };
    }
    case actionType.SEARCH_PROJECTS: {
      const { portfoliosDataContainer } = state.portfolioData;
      const filteredProjects = getFilteredProjects(portfoliosDataContainer, action.searchQuery);
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfoliosData: action.searchQuery ? filteredProjects : portfoliosDataContainer,
          portfoliosDataContainer,
          searchQuery: action.searchQuery,
        },
      };
    }
    case actionType.CHANGE_PROJECTID_PORTFOLIOID:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          selectedPortfolio: action.portfolioId,
          selectedProjectId: action.projectId,
          newPortfolioId: '',
          fromPortfolioDashboard: action.fromPortfolioDashboard
            ? action.fromPortfolioDashboard : false,
        },
      };
    case actionType.SET_ACCESS_LEVEL_ROLES:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          roles: action.roles,
        },
      };
    case actionType.CAN_ADD_PROJECT:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          canAddProject: action.data,
        },
      };
    case actionType.CAN_CHANGE_SETTINGS:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          canChangeSettings: action.data,
        },
      };
    case actionType.SHOW_PORTFOLIO_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfolioRightPanel: {
            ...state.portfolioData.portfolioRightPanel,
            showPanel: true,
            isInEditMode: action.isInEditMode,
          },
        },
      };
    case actionType.HIDE_PORTFOLIO_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfolioRightPanel: {
            ...state.portfolioData.portfolioRightPanel,
            showPanel: false,
            isInEditMode: false,
          },
        },
      };

    case actionType.SHOW_PORTFOLIO_STAKEHOLDER_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfolioStakeholderRightPanel: {
            ...state.portfolioData.portfolioStakeholderRightPanel,
            showPanel: true,
          },
        },
      };
    case actionType.HIDE_PORTFOLIO_STAKEHOLDER_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          portfolioStakeholderRightPanel: {
            ...state.portfolioData.portfolioStakeholderRightPanel,
            showPanel: false,
          },
        },
      };
    case actionType.SHOW_PROJECT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          projectRightPanel: {
            ...state.portfolioData.projectRightPanel,
            showPanel: true,
            isInEditMode: action.isInEditMode,
          },
        },
      };
    case actionType.HIDE_PROJECT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          projectRightPanel: {
            ...state.portfolioData.projectRightPanel,
            showPanel: false,
            isInEditMode: false,
          },
        },
      };
    case actionType.SHOW_PROJECT_STAKEHOLDER_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          projectStakeholderRightPanel: {
            ...state.portfolioData.projectStakeholderRightPanel,
            showPanel: true,
            selectedProject: action.selectedProject || {},
          },
        },
      };
    case actionType.HIDE_PROJECT_STAKEHOLDER_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          projectStakeholderRightPanel: {
            ...state.portfolioData.projectStakeholderRightPanel,
            showPanel: false,
            selectedProject: {},
          },
        },
      };

    case actionType.SHOW_OPEN_TEXT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          openTextRightPanel: {
            ...state.portfolioData.openTextRightPanel,
            showPanel: true,
            projectId: action.data.projectId,
            keyPhrase: action.data.keyPhrase,
            projectTitle: action.data.projectName,
            userTagFilters: action.data.userTagFilters,
            customDates: action.data.customDates,
            selectedSegment: action.data.selectedSegment,
          },
        },
      };
    case actionType.HIDE_OPEN_TEXT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          openTextRightPanel: {
            ...state.portfolioData.openTextRightPanel,
            showPanel: false,
          },
        },
      };

    case actionType.SHOW_OPTIONAL_COMMENT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          optionalCommentRightPanel: {
            ...state.portfolioData.optionalCommentRightPanel,
            showPanel: true,
            projectId: action.data.projectId,
            attributeId: action.data.attributeId,
            attributeTitle: action.data.attributeTitle,
            domainTitle: action.data.domainTitle,
            attributeDescription: action.data.attributeDescription,
            userTagFilters: action.data.userTagFilters,
            isShowCommentPanelData: action.data.isShowCommentPanelData,
            domainID: action.data.domainid,
            showCustomQuestionOption: action.data.showCustomQuestionOption,
            isCustomAttribute: action.data.isCustomAttribute,
            isCustomDomain: action.data.isCustomDomain,
            attributeScore: action.data.attributeScore,
            customDates: action.data.customDates,
            isProjectAnonymityMet: action.data.isProjectAnonymityMet,
            skipCommentAnonymity: action.data.skipCommentAnonymity,
            associatedStakeholdersCount: action.data.associatedStakeholdersWithFilterValue,
            filterGroupValues: action.data.allUserFilterGroupValues,
            userFilterGroupValue: action.data.userFilterGroupValue,
            selectedSegment: action.data.selectedSegment,
            projectDraftedStatus: action.data.projectDraftedStatus,
            dSegments: action.data.domainAssignedSegments,
            isProjectPortfolioActive: action.data.isProjectPortfolioActive,
            isDomainAnonymityOverride: action.data.isDomainAnonymityOverride,
          },
        },
      };
    case actionType.HIDE_OPTIONAL_COMMENT_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          optionalCommentRightPanel: {
            ...state.portfolioData.optionalCommentRightPanel,
            showPanel: false,
          },
        },
      };
    case actionType.SHOW_CUSTOM_QUESTION_DETAIL_PANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          customQuestionsDetailRightPanel: {
            ...state.portfolioData.customQuestionsDetailRightPanel,
            showPanel: true,
            projectId: action.data.projectId,
            userTagFilters: action.data.userTagFilters,
            customDates: action.data.customDates,
            selectedSegment: action.data.selectedSegment,
            isProjectAnonymityMet: action.data.isProjectAnonymityMet,
          },
        },
      };
    case actionType.HIDE_CUSTOM_QUESTION_DETAIL_PANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          customQuestionsDetailRightPanel: {
            ...state.portfolioData.customQuestionsDetailRightPanel,
            showPanel: false,
          },
        },
      };
    // non match open text type question reducer
    case actionType.SHOW_OPEN_TEXT_NON_MATCH_RIGHTPANEL:

      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          openTextNonMatchRightPanel: {
            ...state.portfolioData.openTextNonMatchRightPanel,
            showPanel: true,
            projectId: action.data.projectId,
            projectTitle: action.data.projectName,
            keyPhrases: action.data.keyPhrases,
            userTagFilters: action.data.userTagFilters,
            customDates: action.data.customDates,
            selectedSegment: action.data.selectedSegment,
          },
        },
      };
    case actionType.HIDE_OPEN_TEXT_NON_MATCH_RIGHTPANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          openTextNonMatchRightPanel: {
            ...state.portfolioData.openTextNonMatchRightPanel,
            showPanel: false,
          },
        },
      };

    case actionType.EMPTY_PRE_SELECTED_NEW_PORTFOLIO_ID: {
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          newPortfolioId: '',
        },
      };
    }
    case actionType.USER_FILTERS_FOR_PORTFOLIO:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          userFiltersForPortfolio: action.data,
        },
      };

    case actionType.UPDATE_OPTIONAL_COMMENT_RIGHT_PANEL:
      return {
        ...state,
        portfolioData: {
          ...state.portfolioData,
          optionalCommentRightPanel: {
            ...state.portfolioData.optionalCommentRightPanel,
            selectedSegment: action.data.selectedSegment,
            isProjectAnonymityMet: action.data.isProjectAnonymityMet,
          },
        },
      };

      case actionType.SHOW_OPEN_TEXT_DROP_QUES_RIGHTPANEL:
        return {
          ...state,
          portfolioData: {
            ...state.portfolioData,
            openTextDropQuesRightPanel: {
              ...state.portfolioData.openTextDropQuesRightPanel,
              showPanel: true,
              selectedQuestions: action.selectedQuestions || {},
              projectId: action.projectId,
              customDatesData: action.customDatesData,
            },
          },
        };
      case actionType.HIDE_OPEN_TEXT_DROP_QUES_RIGHTPANEL:
        return {
          ...state,
          portfolioData: {
            ...state.portfolioData,
            openTextDropQuesRightPanel: {
              ...state.portfolioData.openTextDropQuesRightPanel,
              showPanel: false,
              selectedQuestions: {},
              projectId: '',
              customDatesData: {},
            },
          },
        };

    default:
      return state;
  }
};
