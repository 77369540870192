
import React, { Component } from 'react';

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.prevEvent = this.prevEvent.bind(this);
    this.nextEvent = this.nextEvent.bind(this);
    this.goToSpecificPage = this.goToSpecificPage.bind(this);
    this.checkKeyIsNum = this.checkKeyIsNum.bind(this);
  }

  prevEvent() {
    this.props.prevPageEvent();
  }

  nextEvent() {
    this.props.nextPageEvent();
  }

  goToSpecificPage(e) {
    this.props.goToPageEvent(e.currentTarget.value);
  }

  checkKeyIsNum(e) {
    this.props.pageOnChange(e.currentTarget.value);
  }

  render() {
    return (
      <nav aria-label="Page navigation" >
        <ul class="a-pagination">
          <li class="a-page a-page-link" data-control="prev" onMouseDown={this.prevEvent}>
            <a aria-label="Previous" >
              <i class="appkiticon icon-left-chevron-fill"></i>
            </a>
          </li>
          <li class="a-page a-page-number">
            <input class="a-current-page w-43" data-step="5" max="520" min="1" type="number"
              value={this.props.currentPage}
              onChange={this.checkKeyIsNum}
              onBlur={this.goToSpecificPage}
              onKeyDown= {(e) => {
                if (e.key === 'Enter') this.goToSpecificPage(e);
              }}
            />
            <span>of {this.props.totalPages} </span>
          </li>
          <li class="a-page a-page-link" data-control="next" onMouseDown={this.nextEvent}>
            <a aria-label="Next">
              <i class="appkiticon icon-right-chevron-fill"></i>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Pagination;
