const SHOW_PROCESSING = 'SHOW_PROCESSING';
const SHOW_PROCESSING_RECORDS = 'SHOW_PROCESSING_RECORDS';
const HIDE_PROCESSING = 'HIDE_PROCESSING';
const HIDE_PROCESSING_RECORDS = 'HIDE_PROCESSING_RECORDS';

export default {
  SHOW_PROCESSING,
  HIDE_PROCESSING,
  SHOW_PROCESSING_RECORDS,
  HIDE_PROCESSING_RECORDS,
};
