import React, { Component } from 'react';
import $ from 'jquery';

const SumoSelectError = ({ error }) => (error ? <div className="error">{error}</div> : null);

const RenderOptions = props => props.options.map(item => (
  <option className="sumoOption" key={item.value} value={item.value} selected={item.selected}>
    {item.text}
  </option>
));

const RenderGroupOptions = props => props.options.map((item, index) => (
  <optgroup label={item.text} key={index}>
    {item.attributes.map((itemAtt, idx) => (
      <option className="sumoOption" key={idx} value={itemAtt.value} selected={itemAtt.selected}>
        {itemAtt.text}
      </option>
    ))}
  </optgroup>
));

class SettingSumoSelect extends Component {
  componentWillMount() {
    this.optionsWithSelectedData = this.getOptionsWithSelectedData();
  }

  checkOptionTextLength(item) {
    const textLength = item.text.length;
    if (textLength >= 20) {
      const trimmedString = item.text.substring(0, 20);
      return `${trimmedString}...`;
    }
    return item.text;
  }

  componentDidUpdate() {
    $('.CaptionCont').removeAttr('title');
    if (this.props.name === 'selectedAttributes' || this.props.name === 'selectedProjects') {
      this.optionsWithSelectedData = this.getOptionsWithSelectedData();
      // eslint-disable-next-line no-unused-expressions
      $(`#${this.props.name}`)[0].sumo?.reload();
      const selectedOptions = this.props.options?.filter(
        singleOption => this.props.selectedValues.includes(singleOption.value),
      );

      if (selectedOptions.length === 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.checkOptionTextLength(selectedOptions[0]));
      else if (selectedOptions.length > 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(`${this.checkOptionTextLength(selectedOptions[0])} (+${selectedOptions.length - 1})`);
      else if (selectedOptions.length === 0) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(`${this.props.placeholder}`);
      else $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.props.captionFormatAllSelected ? this.props.captionFormatAllSelected : '-- ALL --');
    }
  }

  componentDidMount() {
    this.init();
    // show placeholer for single select
    if (!this.isMultiSelect()) {
      this.showHidePlaceHolderSingleSelect();
    }
  }

  showHidePlaceHolderSingleSelect() {
    const {
      name, placeholder, selectedValues, grouping,
    } = this.props;
    if (selectedValues === '') {
      $(`.sumo_${name} >  p:eq(0)`)[0].title = '';
      $(`.sumo_${name} >  p:eq(0)`).addClass('placeholder-text');
      $(`.sumo_${name} >  p:eq(0) > span:eq(0)`)[0].innerText = placeholder;
      if (grouping) {
        $(`.sumo_${name} >  div:eq(0) > ul:eq(0) > li:eq(0) >ul:eq(0) > li:eq(0) `).removeClass('selected');
      } else {
        $(`.sumo_${name} >  div:eq(0) > ul:eq(0) > li:eq(0) `).removeClass('selected');
      }
    } else {
      $(`.sumo_${name} >  p:eq(0)`).removeClass('placeholder-text');
    }
  }

  init() {
    const { name, captionFormatAllSelected, searchfield } = this.props;
    const $sumoSelector = $(`#${name}`);
    $sumoSelector.SumoSelect({
      captionFormatAllSelected,
      csvDispCount: 1,
      search: !!searchfield,
    });
    $sumoSelector.on('change', () => {
      const selectedOptions = $(`#${name} option:selected`);
      const selectedOptionsValues = [];
      selectedOptions.each((index, item) => {
        selectedOptionsValues.push($(item).val());
      });
      const value = this.isMultiSelect() ? selectedOptionsValues : selectedOptionsValues[0];
      const valueForClear = value.filter(item => item === 'Clear');
      if (valueForClear.length > 0) {
        $(`.sumo_${name} .clear-button`).removeClass('active');
        $(`.sumo_${name} .clear-button`).addClass('disable-clear-button');
        const num = $('option').length;
        for (let i = 0; i < num; i += 1) {
          $(`#${name}`)[0].sumo.unSelectItem(i);
        }
      }
      if (value.length > 0) {
        $(`.sumo_${name} .clear-button`).addClass('active');
        $(`.sumo_${name} .clear-button`).removeClass('disable-clear-button');
      } else {
        $(`.sumo_${name} .clear-button`).removeClass('active');
        $(`.sumo_${name} .clear-button`).addClass('disable-clear-button');
      }
      this.setValue(value);

      if (this.hasOnSumoSelectChangeEvent()) {
        this.props.onSumoSelectChange(value);
      }
    });
  }

  getOptionsWithSelectedData = () => {
    const { options, selectedValues } = this.props;
    const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    let optionsData = options.map((item) => {
      const processedItem = Object.assign({}, item);
      if (values.indexOf(processedItem.value) >= 0) {
        processedItem.selected = true;
      }
      return processedItem;
    });
    if (this.props.grouping) {
      const optionsDataContainingValue = optionsData.filter(o => o.value);
      optionsData = optionsDataContainingValue;
      optionsData.map((item) => {
        item.attributes = options[item.value] || [];
      });
    }
    return optionsData;
  };

  setValue = (value) => {
    const { options } = this.props;
    if (this.props.multiple) {
      const itemValue = this.props.name === 'selectedIndustries'
        || this.props.name === 'selectedGroupValue1'
        || this.props.name === 'selectedGroupValue2'
        || this.props.name === 'selectedGroupValue3'
        || this.props.name === 'selectedGroupValue4'
        || this.props.name === 'selectedTypes'
        ? value[0] : parseInt(value[0], 10);

      const optionText = this.props.name === 'selectedTypes'
        ? options.filter(item => item.value.toString() === itemValue)
        : options.filter(item => item.value === itemValue);

      if (value.length === 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.checkOptionTextLength(optionText[0]));
      else if (value.length > 1) $(`.sumo_${this.props.name}>.CaptionCont>span`).text(`${this.checkOptionTextLength(optionText[0])} (+${value.length - 1})`);
      else $(`.sumo_${this.props.name}>.CaptionCont>span`).text(this.props.captionFormatAllSelected ? this.props.captionFormatAllSelected : '-- ALL --');
    }
    this.props.setFieldValue(this.props.name, value);
    this.showHidePlaceHolderSingleSelect();
  };

  isMultiSelect() {
    return this.props.multiple;
  }

  hasOnSumoSelectChangeEvent() {
    return this.props.onSumoSelectChange;
  }

  clearValues() {
    this.props.setFieldValue(this.props.name, []);
  }

  render() {
    const {
      options,
      setFieldValue,
      selectedValues,
      error,
      name,
      onSumoSelectChange,
      captionFormatAllSelected,
      ...props
    } = this.props;
    return (
      <div>
        {this.props.multiple !== 'multiple'
          && this.props.grouping !== undefined
          && selectedValues !== undefined && (
            <div>
              <select
                id={name}
                name={name}
                defaultValue={selectedValues}
                className="a-selector3 a-select"
                {...props}
              >
                <RenderGroupOptions options={this.optionsWithSelectedData} />
              </select>
            </div>
          )}
        {this.props.multiple !== 'multiple'
          && this.props.grouping === undefined
          && selectedValues !== undefined && (
            <div>
              <select
                id={name}
                name={name}
                defaultValue={selectedValues}
                className="a-selector3 a-select"
                {...props}
              >

                <RenderOptions options={this.optionsWithSelectedData} />
              </select>
            </div>
          )}
        {this.props.multiple !== 'multiple' && selectedValues === undefined && (
          <div>
            <select
              id={name}
              name={name}
              defaultValue={''}
              className="a-selector3 a-select"
              {...props}
            >
              <RenderOptions options={this.optionsWithSelectedData} />
            </select>
          </div>
        )}
        {this.props.multiple && (
          <div className="SettingSumoSelect">
            <select id={name} name={name} className="a-selector3 a-select custom-select-filter" {...props}>
              <RenderOptions options={this.optionsWithSelectedData} />
              <option className='clear-button disable-clear-button' value='Clear'>
                Clear
              </option>

            </select>
          </div>
        )}
        <SumoSelectError error={error} />
      </div>
    );
  }
}

export default SettingSumoSelect;
