import React, { Component } from 'react';
import '../styles/settings.scss';
import { connect } from 'react-redux';
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import {
  insertQuestionModel,
  validateQuestionModelName,
  hideQuestionModelRightPanel,
  getQuestionModels,
  changeSelectedQuestionModel,
} from '../actions/questionModel';
import { addQuestionModelSelector } from '../selectors/questionModel';
import { SpinnerButton } from '../../../../ui-components/Loader';
import { defaultStagesForQuestionModel } from '../actions/levelconstants';

const mapStateToProps = state => ({
  addQuestionModel: addQuestionModelSelector(state),
  questionModelId: state.questionModel.questionModelData.selectedQuestionModel,
});

const mapDispatchToProps = {
  insertQuestionModel,
  hideQuestionModelRightPanel,
  getQuestionModels,
  changeSelectedQuestionModel,
};


function setError(actions, fieldName, error) {
  actions.setFieldTouched(fieldName, true);
  window.setTimeout(() => {
    actions.setFieldError(fieldName, error);
  }, 100);
}

function isValidateQuestionModelName(value, actions) {
  return new Promise((resolve) => {
    const errorMessage = 'A question model with the same name already exists.';
    const emptyStringErrorMessage = 'Please enter a valid title for question model.';
    const title = value.title.trim().toLowerCase().replace(/  +/g, ' ');
    if (title && title !== '' && title !== value.initialTitle.toLowerCase()) {
      validateQuestionModelName({ questionModel: title }).then((val) => {
        if (val) {
          setError(actions, 'title', errorMessage);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    } else if (title === '') {
      setError(actions, 'title', emptyStringErrorMessage);
      resolve(true);
    } else {
      resolve(false);
    }
  });
}


const BasicForm = ({
  values, touched, errors, isSubmitting,
}) => (
    <Form>
      <div className="r-s-body mt-3">
        <div className="r-s-body mt-3">
          <div className="form-group">
            <div className="a-form-label">Question Model Name<span className="mandatory-icon">*</span></div>
            <Field
              name="title"
              autoFocus={!values.id}
              autoComplete="off"
              className={errors.title && touched.title ? 'error a-text-input' : 'a-text-input'}
            />
            <div className="error">
              <ErrorMessage name="title" />
            </div>
          </div>
        </div>
      </div>
      <div className="r-s-actions mt-4">
        <div className="mt-3">
          <SpinnerButton
            isLoading={isSubmitting}
            label={'Add'}
          />
        </div>
      </div>
    </Form>
);

class AddQuestionModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'AddDomain',
      Title: '',
      isSubmitting: false,
    };
    this.saveQuestionModel = this.saveQuestionModel.bind(this);
    this.hideQuestionModelRightPanel = this.hideQuestionModelRightPanel.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideQuestionModelRightPanel();
    }
  }


  saveQuestionModel(model) {
    if (model.title) {
      const data = {
        title: model.title.trim().replace(/  +/g, ' '),
        defaultStagesForQuestionModel,
      };
      insertQuestionModel(data).then((id) => {
        this.showSpinner(false);
        this.props.getQuestionModels().then((response) => {
          if (response.data && response.data.length
            && this.props.questionModelId !== 0) {
            this.props.changeSelectedQuestionModel(id[0], 'stage');
          }
        });
        this.props.hideQuestionModelRightPanel();
      });
    }
  }

  hideQuestionModelRightPanel() {
    this.props.hideQuestionModelRightPanel();
  }

  isValidateQuestionModelName(values, actions) {
    this.showSpinner(true);
    isValidateQuestionModelName(values, actions).then((isExist) => {
      if (!isExist) {
        this.saveQuestionModel(values);
        this.props.getQuestionModels();
      } else {
        this.showSpinner(false);
      }
    });
  }

  showSpinner(isSubmitting) {
    this.setState(prevState => ({
      ...prevState,
      isSubmitting,
    }));
  }

  render() {
    let questionModel = {};
    questionModel = {
      id: 0,
      title: this.state.Title,
      initialTitle: this.state.Title,
    };

    return (
      <div className="right-sidebar" ref={this.setWrapperRef}>
        <div className="r-s-header">
          <div className="r-s-title">
            <span>
              Add a Question Model
                            <i className="appkiticon icon-close-outline" onClick={this.hideQuestionModelRightPanel} />
            </span>
          </div>
          <div className="indicate_val">
            <span className="mandatory-icon">* </span>
            <span className="in_text preview-pane-font"> indicates required field</span>
          </div>
        </div>
        <div className="app">
          <Formik
            enableReinitialize
            initialValues={questionModel}
            onSubmit={(values, actions) => this.isValidateQuestionModelName(values, actions)}
            validationSchema={Yup.object().shape({
              title: Yup.string().trim().required('Question model is required.')
                .matches(/^[^<>][a-zA-Z0-9\W][^<>]*$/, 'Please enter a valid value'),
            })}
            render={formikProps => (
              <BasicForm {...formikProps} isSubmitting={this.state.isSubmitting} />
            )}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddQuestionModel);
