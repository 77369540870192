import React, { Component } from 'react';

class DeleteAlertWithTimer extends Component {
  componentDidMount(props) {
    this.initializeDeleteAlert(props);
  }

  initializeDeleteAlert() {
    // $('.success-alert').alert('show');
  }

  render() {
    return (
      <div className="delete-alert a-alert custom-alert alert-timer a-alert-fade a-alert-hide col-12 a-sm-fit no-gutters success">
          <div className="a-alert-content">
            <div className="a-alert-badge success">
              <span className="appkiticon icon-confirmed-fill"></span>
            </div>
            <span className="a-content preview-pane-font">
              {this.props.customQuestionBasket.questionCount}  pending questions for
              {` ${this.props.selectedProject.ProjectTitle}`}  are deleted.
            </span>
            <span class="close-btn a-countdown-wrapper" data-hide="a-alert">
              <span class="appkiticon icon-close-fill"></span>
            </span>
          </div>
        </div>
    );
  }
}

export default DeleteAlertWithTimer;
