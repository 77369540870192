
export const levelOneOrganizations = [
  { text: 'Client', value: 1 },
  { text: 'Portfolio', value: 2 },
  { text: 'Organisation', value: 3 },
  { text: 'Engagement', value: 4 },
  { text: 'Program', value: 5 },
];

export const levelTwoOrganizations = [
  { text: 'Engagement', value: 1 },
  { text: 'Project', value: 2 },
  { text: 'Team', value: 3 },
  { text: 'Business Unit', value: 4 },
  { text: 'Division', value: 5 },
  { text: 'Stream', value: 6 },
  { text: 'Program', value: 7 },
  { text: 'Topic', value: 8 },
];


export const defaultStagesForQuestionModel = [
  { title: 'Initiation', sort_order: 1 },
  { title: 'Planning', sort_order: 2 },
  { title: 'Execution', sort_order: 3 },
  { title: 'Closure', sort_order: 4 },
];

export const registerEmailAuthorModel = {
  title: 'Add Question Model Authors',
  message: 'A Question Model Author will have full, unrestricted access to this Question Model. Add Question Model Authors by email address, seperated with comma.',
  placeholder: 'author1@example.com, author2@example.com',
};
