import { createSelector } from 'reselect';

const portfolioSelector = state => state.portfolio.portfolioData;

const portfolioIdSelector = state => state.portfolio.portfolioData.selectedPortfolio;

const projectIdSelector = state => state.portfolio.portfolioData.selectedProjectId;

const searchQuerySelector = state => state.portfolio.portfolioData.searchQuery;

const questionModelsSelector = state => state.questionModel.questionModelData.questionModelsData;

// Created selector to save portfolio
const getPortfolio = createSelector(
  [portfolioSelector],
  portfolio => portfolio,
);

// Selector to check whether any portfolio exist
const isAnyPortfolioExist = createSelector(
  [portfolioSelector],
  portfolio => portfolio.portfoliosDataContainer.length > 0,
);

const getSelectedProject = createSelector(
  [projectIdSelector, portfolioSelector],
  (projectId, portfolio) => {
    const data = portfolio.portfoliosDataContainer
      .find(p => p.items.some(s => s.ProjectId && s.ProjectId === projectId)) || { items: [] };
    const filteredProject = data.items.find(p => p.ProjectId && p.ProjectId === projectId);
    return filteredProject || { ProjectTitle: 'All #projectReplaceText#s' };
  },
);

const getSelectedPortfolio = createSelector(
  [portfolioIdSelector, portfolioSelector],
  (portfolioId, portfolio) => {
    const data = portfolio.portfoliosDataContainer.filter(
      p => p.PortfolioId && p.PortfolioId === parseInt(portfolioId, 10),
    );
    return data.length ? data[0].items[0] : {};
  },
);

const isSearchingOn = createSelector(
  [searchQuerySelector],
  searchQuery => searchQuery !== '',
);

const getAllQuestionModels = createSelector(
  [questionModelsSelector],
  questionModels => questionModels.map(set => ({ text: set.title, value: set.id })),
);

export {
  getPortfolio,
  isAnyPortfolioExist,
  getSelectedProject,
  getSelectedPortfolio,
  isSearchingOn,
  getAllQuestionModels,
};
