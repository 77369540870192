import React, { Component } from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import { SpinnerButton } from '../Loader';

class AgedQuestionReminderConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLaunching: false,
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.confirmationClick = this.confirmationClick.bind(this);
    this.deleteWrapperRef = this.deleteWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#launchConfirmation').modal('show');
  }

  deleteWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClick();
    }
  }

  cancelClick() {
    $('#launchConfirmation').modal('hide');
    this.props.cancelClick();
  }

  confirmationClick() {
    this.showSpinner(true);
    this.props.confirmationClick().then(() => {
      this.showSpinner(false);
      $('#launchConfirmation').modal('hide');
    });
  }

  showSpinner(isLaunching) {
    this.setState({ isLaunching });
  }

  render() {
    const { unsavedChangesMessage, unsavedChangesTitle, label } = this.state;
    const {
 modalMessage, modalTitle, submitButtonLabel, disabled,
} = this.props;
    return (
      <div aria-hidden="true" className="modal a-modal fade" id="launchConfirmation"
        role="dialog" ref={this.deleteWrapperRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">{modalTitle || unsavedChangesTitle}</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  onClick={this.cancelClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div className="a-mt-10">{modalMessage || unsavedChangesMessage}</div>
            </div>
            {!this.props.hideActions && <div className="a-modal-footer a-border-tp">
              <div className="a-flex-stretch text-left a-font-sm a-text-464" />
              <button
                aria-label="Close"
                onClick={this.cancelClick}
                className="a-btn a-btn-default a-btn-lg a-btn-gray a-mr-10"
              >
                CANCEL
              </button>
              <SpinnerButton
                onClick={() => this.confirmationClick()}
                label={submitButtonLabel || label}
                isLoading={this.state.isLaunching}
                disabled={disabled}
              />
            </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

AgedQuestionReminderConfirmation.propTypes = {
  message: PropTypes.string,
  selectedId: PropTypes.number,
};

export default AgedQuestionReminderConfirmation;
