import React, { Component } from 'react';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { SpinnerButton } from '../../Loader';
import authService from '../../../redux/services/authService';
import { UpdateQuestionModels } from '../../../modules/settings/question-model/actions/questionModel';

class ManageQuestionModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLaunching: false,
      portfoliosData: [],
    };

    this.cancelClick = this.cancelClick.bind(this);
    this.deleteWrapperRef = this.deleteWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    $('#managePortfolio').modal('show');
    this.setState({
      portfoliosData: this.props.removeActivePortfoliosData.map(port => ({
        PortfolioTitle: port.title,
        PortfolioId: port.id,
        showDashboard: port.showQuestionModal,
      })),
    });
  }

  c = (show) => {
    const { portfoliosData } = this.state;
    this.setState({
      portfoliosData: portfoliosData.map(port => ({
        ...port,
        showDashboard: show !== 'hide-all' || parseInt(this.props.selectedPortfolio, 10) === port.id,
      })),
    });
  };

  onClickApply = () => {
    this.showSpinner(true);
    const { user_id: userId } = authService.getUserInfo();
    const { portfoliosData = [] } = this.state;
    const { actualPortfolio = [] } = this.props;
    const allPortfolio = actualPortfolio.map((port) => {
      const newPort = { ...port };
      portfoliosData.forEach((item) => {
        if (item.PortfolioId === port.id) {
          newPort.showQuestionModal = item.showDashboard;
        }
      });
      return newPort;
    });
    const sendOnlyChangedPort = allPortfolio
      .filter(port => port.showQuestionModal === false || port.userModelCheck != null);
    const payload = {
      portfolios: sendOnlyChangedPort,
      userId,
    };
    if (!sendOnlyChangedPort.length) {
      this.props.UpdateQuestionModels(payload, allPortfolio).then(() => {
        this.cancelClick();
        this.showSpinner(false);
      });
    } else {
      this.props.UpdateQuestionModels(payload, allPortfolio).then((res) => {
        if (res.data === true) {
          this.cancelClick();
          this.showSpinner(false);
        } else {
          this.props.UpdateQuestionModels(payload, allPortfolio).then(() => {
            this.cancelClick();
            this.showSpinner(false);
          });
        }
      });
    }
  };

  onChangeShowDashboard = (port) => {
    const { portfoliosData } = this.state;
    const updatedPortfoliosData = portfoliosData.map((item) => {
      if (item.PortfolioId === port.PortfolioId) {
        return {
          ...item,
          showDashboard: !item.showDashboard,
        };
      }
      return item;
    });
    this.setState({
      portfoliosData: updatedPortfoliosData,
    });
  };


  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  showSpinner(isLaunching) {
    this.setState({ isLaunching });
  }

  onClickShowHideAll = (show) => {
    const { portfoliosData } = this.state;
    this.setState({
      portfoliosData: portfoliosData.map(port => ({
        ...port,
        showDashboard: show !== 'hide-all' || parseInt(this.props.selectedPortfolio, 10) === port.PortfolioId,
      })),
    });
  };

  deleteWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.cancelClick();
    }
  }

  cancelClick() {
    $('#managePortfolio').modal('hide');
    this.props.cancelClick();
  }


  renderManagePortfolioTable = () => {
    const { portfolioReplaceText } = this.props;
    const selectedPortfolio = parseInt(this.props.selectedPortfolio, 10);
    const { portfoliosData = [] } = this.state;
    const activePortfolio = portfoliosData.filter(x => x.PortfolioId === selectedPortfolio);
    const removeActivePortfoliosData = portfoliosData
      .filter(port => port.PortfolioId !== selectedPortfolio);
    const allPortfolio = activePortfolio.concat(removeActivePortfoliosData);
    const allPortTicked = removeActivePortfoliosData.every(port => port.showDashboard);
    const allPortUnTicked = removeActivePortfoliosData.every(port => !port.showDashboard);

    const addClass = (state) => {
      if (state === 'show') {
        if (allPortTicked) {
          return 'disabled';
        }
      } else if (allPortUnTicked) {
        return 'disabled';
      }
      return '';
    };

    return (
      <div>
        <div className="show-hide-section">
          <div>
            <span
              className={`show-all ${addClass('show')}`}
              onClick={() => this.onClickShowHideAll('show-all')}
            >
              SHOW ALL
            </span>
            <span
              className={`hide-all ${addClass('hide')}`}
              onClick={() => this.onClickShowHideAll('hide-all')}
            >
              HIDE ALL
            </span>
          </div>
        </div>
        <div>
          <table class="table table-striped table-borderless manage-portfolio-table">
            <thead className="table-secondary">
              <tr>
                <th scope="col">{portfolioReplaceText}</th>
                <th scope="col" className="show-in-dashboard">
                  Show in dashboard
                </th>
              </tr>
            </thead>
            <tbody>
              {allPortfolio.map(port => (<>
                <tr data-for={`${port.PortfolioId === selectedPortfolio ? 'port_text' : null}`}
                  data-tip={`${port.PortfolioId === selectedPortfolio ? 'port-tooltip' : null}`}>
                  <th scope="row" className={`${port.PortfolioId === selectedPortfolio ? 'port_active_name' : null}`}>
                    {port.PortfolioTitle}{port.PortfolioId === selectedPortfolio
                      && <span className="port_active"> (Currently viewing)</span>}</th>
                  <td className={`${port.PortfolioId === selectedPortfolio ? 'port_active_text' : null} port-checkbox`} >
                    <label class="a-checkbox a-mx-10">
                      <input
                        class="ios_toggle"
                        type="checkbox"
                        checked={port.showDashboard}
                        onChange={() => this.onChangeShowDashboard(port)}
                        disabled={port.PortfolioId === selectedPortfolio}
                      />
                      <span class="a-checkbox-mark">
                        <span class="appkiticon icon-check-mark-fill center-tick-mark"></span>
                      </span>
                    </label>
                  </td>
                </tr>
                <ReactTooltip id='port_text' className='port-tooltip' place='bottom'
                  effect='solid' multiline={false} backgroundColor='#222'>
                  You can't hide this {portfolioReplaceText} as you are currently viewing it.
                </ReactTooltip> </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };


  render() {
    const { modalTitle } = this.props;
    const { isLaunching } = this.state;

    return (
      <div
        aria-hidden="true"
        id="managePortfolio"
        role="dialog"
        className="modal a-modal fade add-stakeholder-modal"
        ref={this.deleteWrapperRef}
        data-backdrop="false"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content add-cstm-qstn-mdl">
            <div className="a-modal-header">
              <div className="a-flex-stretch d-flex align-items-center">
                <div className="a-h3">{modalTitle}</div>
              </div>
              <span className="icon-wrapper a-mr-10" />
              <span className="icon-wrapper">
                <i
                  aria-label="Close"
                  className="appkiticon icon-close-fill a-close-btn"
                  onClick={this.cancelClick}
                />
              </span>
            </div>
            <div className="a-modal-body pt-0">
              <div>{this.renderManagePortfolioTable()}</div>
            </div>
            <div className="a-modal-footer a-border-tp justify-content-end manage-port-apply">
              <SpinnerButton isLoading={isLaunching} label="Apply" onClick={() => this.onClickApply()} />
            </div>
          </div>
        </div>
      </div>
    );
  }
} const mapDispatchToProps = {
  UpdateQuestionModels,
};

export default connect(null, mapDispatchToProps)(ManageQuestionModal);
