import React, { useState } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { ReactSVG } from 'react-svg';
import constant from '../actions/constant';
import {
  showOptionalCommentRightPanel,
} from '../../portfolio/actions/portfolio';
import { enableUnsavedChangesModalFocusDomain } from '../actions/project';
import { getSelectedProject } from '../../portfolio/selectors/portfolio';
import customDomainEditSvg from '../../../../assets/images/component/svg/custom-domain-edit.svg';
import SegmentGroupDomainAttribute from './SegmentGroupDomainAttribute.jsx';
import SegmentDropDown from '../../../ui-components/Segment/SegmentDropDown';

const SegmentGroupDomainCard = (props) => {
  const {
    domainWidgets1, domainWidgets2, domainWidgets3,
    domainWidgets4, previousDaysCount,
    pastDays, expandWidgets,
    associatedStakeholdersWithFilterValue,
    allUserFilterGroupValues, overAllWidget: overallWidget,
    userSelectedFilterGroupValues,
    unsavedChangesModal,
    unsavedChanges,
    updateShowUnsavedChangesModal,
    isShowPercentageChange,
    selectedSegmentValue, isSegmentApplied, customDatesData,
    projectSegments,
  } = props;

  const [tooltipPosition, updateTooltipPosition] = useState('right');
  // Capitalize all words and replace the word 'And' with 'and'

  const setPostionandShowTooltipOnHover = (elId, element) => {
    const el = document.getElementById(elId);
    const winWidth = window.innerWidth;
    if (element === constant.tooltipArea.attribute) {
      const elLeftDistance = el.getBoundingClientRect().left + 240;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 300
        ? updateTooltipPosition('left')
        : updateTooltipPosition('right');
    }
    if (element === constant.tooltipArea.domain) {
      const elLeftDistance = el.getBoundingClientRect().left + 18;
      const elPosition = Math.ceil(winWidth - elLeftDistance);
      // eslint-disable-next-line
      elPosition < 200
        ? updateTooltipPosition('left')
        : updateTooltipPosition('right');
    }
  };
  const handleMouseEnter = (widget) => {
    if (widget.description) {
      setPostionandShowTooltipOnHover(
        `${widget.domainName}`,
        constant.tooltipArea.domain,
      );
}
};

  return (

    <React.Fragment>
      {domainWidgets1 && domainWidgets1.length > 0 && domainWidgets1.map((widget, index) => (

        <div key={index} className={'col-t1 confidence-compare-card'}>
          <div className="data-item-box project-box">
            <div className="a-panel a-shadow-sm text-center">
              {widget.isCustomDomain && <div class="custom-tag left-0">

                Custom</div>}


              <div className="project-title d-flex justify-content-center align-items-center ">

                <p className="m-0"
                data-for={widget.domainName} data-tip
                onMouseEnter={() => handleMouseEnter(widget)}

                >
                  {widget.isCustomDomain && (<div className="main-icon main-icon-cstm-domain">
                    <ReactSVG src={customDomainEditSvg} />
                  </div>)}
                  {!widget.isCustomDomain && (<i
                    className={`appkiticon mx-2 
                    ${widget.icon} `} style={{ fontSize: '20px' }}
                  >
                  </i>)}

                  {widget.domainName}</p>
                <div className="dropdown-icon">
                  {projectSegments && projectSegments.length > 0
                  && <SegmentDropDown
                  projectSegments={projectSegments}
                  selectedSegments={widget.domainSegments}
                  assignSegments={s => this.assignSegmentsToDomain(
                    s,
                     widget.domainId,
                     widget.domainName,
)
                    }
                  key={index} tooltipIdPostfix={index}
                  isComparisonView={true} />}
                </div>
                {widget.description && <ReactTooltip id={widget.domainName} className='tooltip' place={tooltipPosition}
                  effect='solid'>
                  {widget.description}
                </ReactTooltip>}
              </div>

              <div className="project-percentage project-page">
                {/* add domain icon here */}
                <div className="confidence-compare">
                  {widget && widget.isDomainAttrAssociatedWithQuestion
                    && <div className="d-flex align-items-center">
                      <div className="project-percentage cg-content">
                        <span class="cc-group-number">Group A</span>
                        <h3 className={`a-dropdown-item ${widget.showScore ? '' : 'no-oc-data'} `} >
                          <span style={widget.scoreColor || 'color: rgb(222, 180, 80)'}>
                            {widget.overallScore}%</span>
                        </h3>
                        {isShowPercentageChange && <span class="cc-growth">
                          <span class="material-icons">
                            {/* eslint-disable no-nested-ternary */}
                            {widget.percentageChange === 0 || widget.percentageChange === null
                              ? ''
                              : widget.percentageChange < 0
                                ? 'arrow_downward' : ' arrow_upward'}
                          </span>
                          {Math.abs(widget.percentageChange)}%{' '}
                          {widget.percentageChange ? '' : 'change'}
                        </span>}
                      </div>
                    </div>}

                  {domainWidgets2?.[index]?.isDomainAttrAssociatedWithQuestion && <div className="d-flex align-items-center">
                    {widget.isDomainAttrAssociatedWithQuestion && <div class="cg-center px-4">
                      <span class="cgc-text">VS</span>
                    </div>}
                    <div className="project-percentage cg-content">
                      <span class="cc-group-number">Group B</span>
                      <h3 className={`a-dropdown-item ${domainWidgets2[index].showScore ? '' : 'no-oc-data'} `} >
                        <span style={domainWidgets2[index].scoreColor || 'color: rgb(222, 180, 80)'}>
                          {domainWidgets2[index].overallScore}%</span>
                      </h3>
                      {isShowPercentageChange && <span class="cc-growth">
                        <span class="material-icons">
                          {/* eslint-disable no-nested-ternary */}
                          {domainWidgets2[index].percentageChange === 0
                            || domainWidgets2[index].percentageChange === null
                            ? ''
                            : domainWidgets2[index].percentageChange < 0
                              ? 'arrow_downward' : ' arrow_upward'}
                        </span>
                        {Math.abs(domainWidgets2[index].percentageChange)}%{' '}
                        {domainWidgets2[index].percentageChange ? '' : 'change'}
                      </span>}
                    </div>
                  </div>}


                  {domainWidgets3?.[index]?.isDomainAttrAssociatedWithQuestion && <div className="d-flex align-items-center">
                    {(domainWidgets2?.[index]?.isDomainAttrAssociatedWithQuestion
                      || widget.isDomainAttrAssociatedWithQuestion) && <div class="cg-center px-4">
                        <span class="cgc-text">VS</span>
                      </div>}
                    <div className="project-percentage cg-content">
                      <span class="cc-group-number">Group C</span>
                      <h3 className={`a-dropdown-item ${domainWidgets3[index].showScore ? '' : 'no-oc-data'} `} >
                        <span style={domainWidgets3[index].scoreColor || 'color: rgb(222, 180, 80)'}>
                          {domainWidgets3[index].overallScore}%</span>
                      </h3>
                      {isShowPercentageChange && <span class="cc-growth">
                        <span class="material-icons">
                          {/* eslint-disable no-nested-ternary */}
                          {domainWidgets3[index].percentageChange === 0
                            || domainWidgets3[index].percentageChange === null
                            ? ''
                            : domainWidgets3[index].percentageChange < 0
                              ? 'arrow_downward' : ' arrow_upward'}
                        </span>
                        {Math.abs(domainWidgets3[index].percentageChange)}%{' '}
                        {domainWidgets3[index].percentageChange ? '' : 'change'}
                      </span>}
                    </div>
                  </div>}


                  {domainWidgets4?.[index]?.isDomainAttrAssociatedWithQuestion && <div className="d-flex align-items-center">
                    {(domainWidgets3?.[index]?.isDomainAttrAssociatedWithQuestion
                      || domainWidgets2?.[index]?.isDomainAttrAssociatedWithQuestion
                      || widget.isDomainAttrAssociatedWithQuestion) && <div class="cg-center px-4">
                        <span class="cgc-text">VS</span>
                      </div>}
                    <div className="project-percentage cg-content">
                      <span class="cc-group-number">Group D</span>
                      <h3 className={`a-dropdown-item ${domainWidgets4[index].showScore ? '' : 'no-oc-data'} `} >
                        <span style={domainWidgets4[index].scoreColor || 'color: rgb(222, 180, 80)'}>
                          {domainWidgets4[index].overallScore}%</span>
                      </h3>
                      {isShowPercentageChange && <span class="cc-growth">
                        <span class="material-icons">
                          {/* eslint-disable no-nested-ternary */}
                          {domainWidgets4[index].percentageChange === 0
                            || domainWidgets4[index].percentageChange === null
                            ? ''
                            : domainWidgets4[index].percentageChange < 0
                              ? 'arrow_downward' : ' arrow_upward'}
                        </span>
                        {Math.abs(domainWidgets4[index].percentageChange)}%{' '}
                        {domainWidgets4[index].percentageChange ? '' : 'change'}
                      </span>}
                    </div>
                  </div>}
                </div>
                {isShowPercentageChange && <p className={'m-0 ccd-label-text mb-4'} >in past {previousDaysCount} {pastDays}</p>}


              </div>
              <div className="a-accordion-wrapper ">
                <div className="a-accordion">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      expandWidgets(index, widget);
                    }}
                    className="a-accordion-title bg-transparent panel-expander" >
                    <i className={`appkiticon
                                    ${widget.isExpanded ? ' icon-up-chevron-circle-outline'
                        : ' icon-down-chevron-circle-outline'}
                                    `}
                    ></i></div>
                  {widget.isExpanded && <div className="a-accordion-content bg-transparent show d-block">
                    <div className="tag-container">
                      <SegmentGroupDomainAttribute
                        index={index}
                        domainWidget={widget}
                        domainWidgets1={domainWidgets1}
                        domainWidgets2={domainWidgets2}
                        domainWidgets3={domainWidgets3}
                        domainWidgets4={domainWidgets4}
                        customDatesData={customDatesData}
                        expandWidgets={expandWidgets}
                        associatedStakeholdersWithFilterValue={
                          associatedStakeholdersWithFilterValue
                        }
                        allUserFilterGroupValues={allUserFilterGroupValues}
                        userSelectedFilterGroupValues={userSelectedFilterGroupValues}
                        unsavedChangesModal={unsavedChangesModal}
                        unsavedChanges={unsavedChanges}
                        updateShowUnsavedChangesModal={updateShowUnsavedChangesModal}
                        selectedSegmentValue={selectedSegmentValue}
                        isSegmentApplied={isSegmentApplied}
                        overallWidget={overallWidget}
                        tooltipPosition={tooltipPosition}
                      />
                    </div>
                  </div>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  projectSegments: state.project.projectSegments,
  roles: state.portfolio.portfolioData.roles,
  isSysAdmin: state.login.isSysAdmin,
  overAllWidget: state.project.overAllWidget,
  showProcessingRecords: state.processingReducer.showProcessingRecords,
  selectedProject: getSelectedProject(state),
  segmentGroupValue: state.project.segmentGroupValue,
  segmentGroupOverall: state.project.segmentGroupOverall,
});

const mapDispatchToProps = {
  showOptionalCommentRightPanel,
  enableUnsavedChangesModalFocusDomain,
};

export default connect(mapStateToProps,
  mapDispatchToProps)(SegmentGroupDomainCard);
